import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { EntryModel } from "@web/models";
import { isMedia, media } from "@web/styles/utils";

interface IProps {
  entry: EntryModel;
}

const MAX_DOCS = isMedia("compact", "noTouch") ? 3 : 8;

export const MoreDocsText: FC<IProps> = observer((p) => {
  const intl = useIntl();
  const { totalMatchingDocuments, documentCount } = p.entry;

  if (!totalMatchingDocuments || totalMatchingDocuments === 0) {
    if (documentCount <= MAX_DOCS) {
      return null;
    }
    const moreCount = documentCount - MAX_DOCS + 1;
    return (
      <_wrap>
        {intl.formatMessage(texts.moreDocuments, { count: moreCount })}
      </_wrap>
    );
  }

  const moreMatchesCount = totalMatchingDocuments - MAX_DOCS;
  const otherCount = documentCount - totalMatchingDocuments;

  return (
    <_wrap>
      {moreMatchesCount > 0 && (
        <div>
          {intl.formatMessage(texts.matchingDocuments, {
            count: moreMatchesCount,
          })}
        </div>
      )}
      {otherCount > 0 && (
        <div>
          {intl.formatMessage(texts.otherDocuments, { count: otherCount })}
        </div>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  padding: 0 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  align-self: center;
  opacity: 0.6;
  padding: 1rem 2rem;
  ${media("compact", "touch")} {
    flex: 0 0 calc(var(--RecordCard-colWidth) - 1.5rem);
    scroll-snap-align: start;
  }
`;

export const texts = defineMessages({
  moreDocuments: {
    id: "entry.documents.moredocuments",
    defaultMessage:
      "+ {count} more {count, plural, one {document} other {documents}}",
  },
  otherDocuments: {
    id: "entry.documents.otherdocuments",
    defaultMessage:
      "+ {count} other {count, plural, one {document} other {documents}}",
  },
  matchingDocuments: {
    id: "entry.documents.matchingdocuments",
    defaultMessage:
      "+ {count} matching {count, plural, one {document} other {documents}}",
  },
});
