import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { media, useMedia } from "@web/styles/utils";
import { vars } from "@web/styles";
import { DocumentModel, UploadRequest, UploadRequestType } from "@web/models";
import { useTabPanels } from "@web/utils/hooks/useTabPanels";
import { CloseButton } from "@web/elements/Button";
import { SkeletonText } from "@web/elements";
import { HistoryPane } from "@web/components/Entry/HistoryPane";
import { AttributeList, Overlay } from "@web/components";
import { RemindersDialog } from "@web/components/Entry/Reminders/RemindersDialog";
import { DocumentGrid } from "@web/components/Entry/DocumentGrid";
import { Preview } from "@web/components/Document/Preview";
import { DocumentUploadDropzone } from "@web/components/Upload/DocumentUploadDropzone";
import { VersionUploadDropzone } from "@web/components/Upload/VersionUploadDropzone";
import { TagLock } from "@web/components/TagLock";
import { Tab, Tabs } from "@web/elements/Tabs";
import { useStores } from "@web/stores/context";
import { generateDocumentUrl, generateEntryUrl } from "@web/utils/URLHelpers";
import { commonTexts } from "@web/translations";
import { Checklist } from "@web/components/Entry/Checklist";
import { EntryStatusEdit } from "@web/components/EntryStatus";
import { KeyboardNavigation } from "@web/components/KeyboardNavigation";
import { DocumentSidebar } from "@web/pages/DocumentPage/DocumentSidebar";
import { EntryUpdateBanner } from "@web/components/Entry/EntryUpdateBanner";
import { DocumentUpdateBanner } from "@web/components/Document/DocumentUpdateBanner";
import { ErrorPage } from "@web/pages/ErrorPage";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";
import { useConfig } from "@web/config/context";
import { LinksPane } from "@web/components/Entry/Links";
import { useEntryNavigationHandler } from "./handler";
import { EntryTagEdit } from "./EntryTagEdit";
import { EntryToolbar } from "./EntryToolbar";
import { EntryTitle } from "./EntryTitle";

type Panels =
  | "content"
  | "activity"
  | "reminders"
  | "document-info"
  | "links"
  | null;

export const EntryPage = observer(() => {
  const config = useConfig();

  useEntryNavigationHandler();

  const {
    currentSearchURL,
    documentStore,
    keyboardStore,
    recordStore,
    searchStore,
    uploadStore,
    multiSelectStore,
  } = useStores();
  const intl = useIntl();
  const navigate = useNavigate();
  const { isCompact, isDesktop } = useMedia();
  const { getPanelProps, getTabProps, setPanel, currentPanel } =
    useTabPanels<Panels>(null);
  const [activeChecklist, setActiveChecklist] = useState<UUID | false>(false);
  const getConfirmation = useDuplicateUploadConfirmDialog();
  const entry = recordStore.entry;
  const isDraft = entry?.isDraft;
  const checklists = entry?.checklists;
  const singleDocument = entry?.isSingleDocumentEntry
    ? entry.documents[0]
    : undefined;

  useEffect(() => {
    if (!checklists?.length) {
      setActiveChecklist(false);
    }
  }, [checklists?.length]);

  useEffect(() => {
    if (!entry) {
      return;
    }

    const defaultTabOnCompactScreen = "content";
    const defaultSidebarTab = singleDocument ? "document-info" : "activity";
    setPanel(isCompact ? defaultTabOnCompactScreen : defaultSidebarTab);
  }, [isCompact, singleDocument, entry]);

  const handleClose = () => {
    if (isDraft) {
      recordStore.clearEntry();
    } else {
      navigate(currentSearchURL());
    }
  };

  const handleDocumentClick = (document: DocumentModel) => {
    documentStore.setDocument(document);
    document.reload();
    navigate(generateDocumentUrl(document.id));
  };

  const handleUploadRequest = async (request: UploadRequest) => {
    if (!recordStore.entry) {
      return;
    }

    if (isDraft) {
      const entry = await recordStore.saveDraftEntry();
      navigate(generateEntryUrl(entry.id));
    }

    if (request.type === UploadRequestType.document) {
      request.entryId = recordStore.entry.id;
      request.entryUuid = recordStore.entry.uuid;
    }

    return uploadStore.addRequest(request);
  };

  const handleArrowLeft = () => {
    recordStore.entry?.selectPreviousDocument();
  };

  const handleArrowRight = () => {
    recordStore.entry?.selectNextDocument();
  };

  const handleEnter = () => {
    if (recordStore.entry?.focusedDocument) {
      handleDocumentClick(recordStore.entry.focusedDocument);
    }
  };

  const isNavigationEnabled = () => {
    return recordStore.hasEntry && !documentStore.hasDocument;
  };

  if (recordStore.error) {
    return <ErrorPage error={recordStore.error} />;
  }

  if (!entry) {
    return <SkeletonEntry />;
  }

  return (
    <Overlay>
      <_wrap>
        <Helmet>
          <title>
            {entry?.title ??
              `${intl.formatMessage(commonTexts.untitledEntry)} - ${
                config.instance
              }`}
          </title>
        </Helmet>

        <_header>
          <_header_row>
            <_close onClick={handleClose} />

            <_title>
              <EntryTitle entry={entry} document={singleDocument} />
            </_title>

            {!isDraft && (
              <_tools>
                {entry.isProcessingDocuments && (
                  <_info>{intl.formatMessage(texts.processing)}</_info>
                )}
                <EntryToolbar
                  entry={entry}
                  document={singleDocument}
                  onUpload={handleUploadRequest}
                  getConfirmation={getConfirmation}
                />
              </_tools>
            )}
          </_header_row>

          <_header_tags>
            <TagLock locked={!entry?.tags.isEmpty && entry.canUpdate}>
              <_header_row>
                <EntryTagEdit entry={entry} />
                <EntryStatusEdit
                  entry={entry}
                  statusOptions={recordStore.entryStatuses}
                />
                {entry.tags.loadingInfo.hasMore && (
                  <small>{intl.formatMessage(texts.loadingMoreTags)}</small>
                )}
              </_header_row>
              <_header_row>
                <AttributeList entry={entry} />
              </_header_row>
            </TagLock>
          </_header_tags>
        </_header>

        {isCompact && !isDraft && (
          <_tabs>
            <Tab {...getTabProps("content")}>
              {intl.formatMessage(
                singleDocument ? texts.preview : texts.documents
              )}
            </Tab>
            {singleDocument && (
              <Tab {...getTabProps("document-info")}>
                {intl.formatMessage(texts.documentInfo)}
              </Tab>
            )}
            <Tab {...getTabProps("activity")}>
              {intl.formatMessage(texts.activity)}
            </Tab>
            <Tab {...getTabProps("reminders")}>
              {intl.formatMessage(texts.reminders)}
            </Tab>
            <Tab {...getTabProps("links")}>
              {intl.formatMessage(texts.links)}
            </Tab>
          </_tabs>
        )}

        <_content {...getPanelProps("content", isCompact)}>
          <_content_inner>
            {entry.hasUpdate && <EntryUpdateBanner entry={entry} />}
            {singleDocument?.hasUpdate && (
              <DocumentUpdateBanner document={singleDocument} />
            )}

            {checklists && (
              <_content_checklists>
                {checklists.map((pipeline) => (
                  <Checklist
                    key={pipeline.uuid}
                    entry={entry}
                    pipeline={pipeline}
                    isExpanded={activeChecklist === pipeline.uuid}
                    onExpand={(expand) =>
                      setActiveChecklist(expand && pipeline.uuid)
                    }
                    onDocumentClick={handleDocumentClick}
                    onUpload={handleUploadRequest}
                    getConfirmation={getConfirmation}
                  />
                ))}
              </_content_checklists>
            )}

            {!activeChecklist && (
              <_content_documents>
                {singleDocument ? (
                  <VersionUploadDropzone
                    document={singleDocument}
                    onUpload={handleUploadRequest}
                    getConfirmation={getConfirmation}
                  >
                    <Preview
                      document={singleDocument}
                      searchQuery={searchStore.getSearchQueryComponents().query}
                    />
                  </VersionUploadDropzone>
                ) : (
                  <DocumentUploadDropzone
                    entry={entry}
                    onUpload={handleUploadRequest}
                    getConfirmation={getConfirmation}
                  >
                    <DocumentGrid
                      entry={entry}
                      onDocumentClick={handleDocumentClick}
                      onUploadRequest={handleUploadRequest}
                      documentSelection={multiSelectStore.documents}
                    />
                  </DocumentUploadDropzone>
                )}
              </_content_documents>
            )}
          </_content_inner>
        </_content>

        <_aside>
          {isDesktop && !isDraft && (
            <Tabs>
              {singleDocument && (
                <Tab {...getTabProps("document-info")}>
                  {intl.formatMessage(texts.documentInfo)}
                </Tab>
              )}
              <Tab {...getTabProps("activity")}>
                {intl.formatMessage(texts.activity)}
              </Tab>
              <Tab {...getTabProps("links")}>
                {intl.formatMessage(texts.links)}
              </Tab>
            </Tabs>
          )}

          {singleDocument && (
            <div {...getPanelProps("document-info")}>
              <DocumentSidebar document={singleDocument} />
            </div>
          )}

          <div {...getPanelProps("activity")}>
            <HistoryPane entry={entry} />
          </div>

          <div {...getPanelProps("links")}>
            <LinksPane entry={entry} isTabSelected={currentPanel === "links"} />
          </div>
        </_aside>

        {isCompact && !isDraft && (
          <_reminders {...getPanelProps("reminders")}>
            <RemindersDialog entry={entry} />
          </_reminders>
        )}

        <KeyboardNavigation
          identifier="Entry"
          keyboardStore={keyboardStore}
          enabled={isNavigationEnabled}
          onArrowLeft={handleArrowLeft}
          onArrowRight={handleArrowRight}
          onEnter={handleEnter}
        />
      </_wrap>
    </Overlay>
  );
});

const SkeletonEntry = () => (
  <Overlay>
    <_wrap>
      <_header>
        <_header_row>
          <_title>
            <SkeletonText />
          </_title>
        </_header_row>
        <_header_row>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
        </_header_row>
      </_header>
      <_content />
      <_aside />
    </_wrap>
  </Overlay>
);

const _wrap = styled.div`
  background: ${vars.content};
  color: ${vars.contentFg};
  display: grid;
  width: 100%;
  height: 100%;

  ${media("desktop")} {
    grid-template:
      "header header" auto
      "content aside" 1fr
      / 1fr 320px;
  }

  ${media("compact")} {
    grid-template:
      "header" auto
      "tabs" auto
      "panel" 1fr;
  }
`;

const _header = styled.header`
  grid-area: header;
  border-bottom: 2px solid ${vars.dark05};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem 1rem 0.5rem;
  overflow: hidden;
`;

const _header_row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  overflow: auto;

  :empty {
    display: none;
  }

  ${media("desktop")} {
    flex-wrap: wrap;
  }
`;

const _header_tags = styled.div`
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  position: relative;

  ${media("desktop")} {
    align-items: flex-start;
    width: fit-content;
  }
`;

const _close = styled(CloseButton)`
  color: ${vars.dark55};

  :hover {
    color: ${vars.contentFg};
  }
`;

const _title = styled.div`
  flex: 1;
  overflow: hidden;
`;

const _tools = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const _tabs = styled(Tabs)`
  grid-area: tabs;
`;

const _content = styled.div`
  overflow: hidden;
  position: relative;

  ${media("desktop")} {
    grid-area: content;
  }

  ${media("compact")} {
    grid-area: panel;
  }
`;

const _content_inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const _content_checklists = styled.div`
  flex: 0 1 auto;
  overflow: auto;
`;

const _content_documents = styled.div`
  flex: 1;
  overflow: hidden;
`;

const _aside = styled.aside`
  overflow: auto;

  ${media("desktop")} {
    border-left: 2px solid ${vars.dark05};
    grid-area: aside;
  }

  ${media("compact")} {
    display: contents;

    > * {
      grid-area: panel;
      overflow: auto;
    }
  }
`;

const _reminders = styled.div`
  grid-area: panel;
`;

const _info = styled.span`
  align-self: center;
  color: ${vars.dark55};
  padding-right: 5px;
`;

const texts = defineMessages({
  preview: {
    id: "entry.preview",
    defaultMessage: "Preview",
  },
  documents: {
    id: "entry.documents",
    defaultMessage: "Documents",
  },
  documentInfo: {
    id: "entry.documentInfo",
    defaultMessage: "Document info",
  },
  activity: {
    id: "entry.activity",
    defaultMessage: "Activity",
  },
  links: {
    id: "entry.links",
    defaultMessage: "Links",
  },
  reminders: {
    id: "entry.reminders",
    defaultMessage: "Reminders",
  },
  noTags: {
    id: "entry.tags.notags",
    defaultMessage: "No tags…",
  },
  loadingMoreTags: {
    id: "entry.tags.loadingMore",
    defaultMessage: "loading more tags…",
  },
  processing: {
    id: "entry.processing",
    defaultMessage: "Processing…",
  },
});
