import React, { forwardRef } from "react";
import { DropzoneRef } from "react-dropzone";
import { UploadDropzone } from "./UploadDropzone";
import { VersionUploadProps } from "./types";
import { handleVersionUpload } from "./handlers";
import { useConfig } from "@config/context";
import { useStores } from "@web/stores/context";

export const VersionUploadDropzone = forwardRef<
  DropzoneRef,
  VersionUploadProps
>((p, ref) => {
  const { canUploadDocuments } = useConfig();
  const documentVersionStore = useStores().documentVersionStore;

  const isDisabled =
    !p.document.canAddVersion ||
    p.document.isLockedForCurrentUser ||
    !canUploadDocuments;

  const handleUpload = (files: File[]) => {
    handleVersionUpload(documentVersionStore, files[0], p);
  };

  return (
    <UploadDropzone
      ref={ref}
      type="version"
      multiple={false}
      disabled={isDisabled}
      overlayPosition="absolute"
      onDrop={handleUpload}
    >
      {p.children}
    </UploadDropzone>
  );
});
