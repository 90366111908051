import React from "react";
import filesize from "filesize";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { DescriptionList, DateTime } from "@web/elements";
import { CreatedBy } from "./CreatedBy";
import { DocumentModel, DocumentVersionModel } from "@web/models";
import { SkeletonText } from "@web/elements/Skeleton";
import { texts } from "./texts";
import { SignatureDetails } from "./SignatureDetails";

export const Details = observer(
  ({
    currentVersion,
    document,
  }: {
    currentVersion: DocumentVersionModel;
    document: DocumentModel;
  }) => {
    return (
      <DescriptionList>
        <dt>
          <FormattedMessage {...texts.detailsFileType} />
        </dt>
        <dd>{currentVersion?.fileExt}</dd>
        <dt>
          <FormattedMessage {...texts.detailsFileSize} />
        </dt>
        <dd>{filesize(currentVersion?.fileSize || 0)}</dd>

        <dt>
          <FormattedMessage {...texts.detailsCreated} />
        </dt>
        <dd>
          <DateTime datetime={document.createdDate} />
        </dd>

        <dt>
          <FormattedMessage {...texts.detailsModified} />
        </dt>
        <dd>
          <DateTime datetime={document.mostRecentUpdatedDate} />
        </dd>

        <SignatureDetails
          document={document}
          version={document.currentVersion}
        />

        {document.versions.length === 1 && (
          <>
            <dt>
              <FormattedMessage {...texts.detailsFileName} />
            </dt>
            <dd>{currentVersion.fileName}</dd>
            <dt>
              <FormattedMessage {...texts.detailsChecksum} />
            </dt>
            <dd>{currentVersion?.checksum}</dd>
            <dt>
              <FormattedMessage {...texts.detailsUploaded} />
            </dt>
            <dd>
              <CreatedBy
                datetime={currentVersion.createdDate}
                userName={currentVersion.createdBy}
              />
            </dd>
          </>
        )}
      </DescriptionList>
    );
  }
);

export const DetailsSkeleton = () => (
  <DescriptionList>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
    <dt>
      <SkeletonText length={5} />
    </dt>
    <dd>
      <SkeletonText />
    </dd>
  </DescriptionList>
);
