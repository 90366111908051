import React, { FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { PipelineCreateFields, PipelineModel, PipelineType } from "@web/models";
import { ActionMenu, Input, Select } from "@web/elements";
import { TargetName } from "@web/api/Integration/types";
import { commonTexts } from "@web/translations";

interface IProps {
  type: PipelineType;
  pipeline?: PipelineModel;
  isCreatingPipeline?: boolean;
  addPipeline: (fields: PipelineCreateFields) => void;
}

export const EditPipelineDetails: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const [name, setName] = useState(p.pipeline?.name || "");
  const [description, setDescription] = useState(p.pipeline?.description || "");
  const [target, setTarget] = useState<TargetName>("Entry");

  const handleSave = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (p.pipeline) {
      p.pipeline.update({ name, description });
    } else {
      p.addPipeline({
        name,
        description,
        targetName: target,
        pipelineType: p.type,
      });
    }
  };

  const canSave = () => {
    if (p.pipeline) {
      if (p.pipeline.saving.details) {
        return false;
      }

      return (
        (p.pipeline.name !== name && name.length > 0) ||
        (p.pipeline.description || "") !== description
      );
    }
    return !p.isCreatingPipeline && name.length > 0;
  };

  return (
    <_edit>
      <h3>
        <FormattedMessage {...commonTexts.nameRequired} />
      </h3>
      <Input
        placeholder={
          p.pipeline?.name || intl.formatMessage(texts.namePlaceholder)
        }
        value={name}
        onChange={(evt) => setName(evt.target.value)}
      />
      <h3>
        <FormattedMessage {...commonTexts.description} />
      </h3>
      <Input
        placeholder={
          p.pipeline?.description ||
          intl.formatMessage(texts.descriptionPlaceholder)
        }
        value={description}
        onChange={(evt) => setDescription(evt.target.value)}
      />

      {p.type === "notification" && !p.pipeline && (
        <>
          <h3>
            <FormattedMessage {...texts.target} />
          </h3>
          <Select
            value={target}
            onChange={(evt) => setTarget(evt.target.value as TargetName)}
          >
            {["Entry", "Tag"].map((name) => (
              <option key={name} value={name}>
                {name === "Entry"
                  ? intl.formatMessage(texts.entryOption)
                  : intl.formatMessage(texts.tagOption)}
              </option>
            ))}
          </Select>
        </>
      )}

      <ActionMenu
        onApply={handleSave}
        applyIsDisabled={!canSave()}
        applyText={p.pipeline ? texts.save : texts.add}
        direction="horizontal"
      />
    </_edit>
  );
});

const _edit = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  h3 {
    margin: 0px 0px 8px 0px;
    :not(:first-child) {
      margin-top: 15px;
    }
  }

  input {
    font-size: 14px;
  }

  select {
    width: 200px;
  }

  > div:last-of-type {
    margin-top: 10px;
  }
`;

const texts = defineMessages({
  add: {
    id: "flow.settings.pipeline.add",
    defaultMessage: "Add",
  },
  namePlaceholder: {
    id: "flow.settings.pipeline.name.placeholder",
    defaultMessage: "Add name…",
  },
  save: {
    id: "flow.settings.pipeline.update",
    defaultMessage: "Update details",
  },
  descriptionPlaceholder: {
    id: "flow.settings.pipeline.description.placeholder",
    defaultMessage: "Add description…",
  },
  target: {
    id: "flow.settings.pipeline.target",
    defaultMessage: "Target",
  },
  entryOption: {
    id: "flow.settings.pipeline.target.options.entry",
    defaultMessage: "Entry",
  },
  tagOption: {
    id: "flow.settings.pipeline.target.options.tag",
    defaultMessage: "Tag",
  },
});
