import React, { useEffect, useRef } from "react";
import { EntryCard } from "@web/components/EntryCard";
import { observer } from "mobx-react";
import { EntryModel, UploadGroup } from "@web/models";

export interface Props {
  isSelected: boolean;
  isFocused: boolean;
  entry: EntryModel;
  colSpan: number;
  colWidth: number;
  uploadGroup?: UploadGroup;
  isGlobalSearch?: boolean;
  isSelectable?: boolean;
  onEntryClick: (entry: EntryModel, expand?: boolean) => void;
  onSelect: (entry: EntryModel, select: boolean) => void;
  scrollToItem: (element: HTMLAnchorElement) => void;
}

export const ResultsGridItem = observer((p: Props) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const handleFocus = () => {
    if (ref.current) {
      p.scrollToItem(ref.current);
    }
  };

  useEffect(() => {
    if (p.isFocused) {
      handleFocus();
    }
  }, [p.isFocused]);

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    p.onSelect(p.entry, e.target.checked);
  };

  return (
    <EntryCard
      ref={ref}
      entry={p.entry}
      colSpan={p.colSpan}
      colWidth={p.colWidth}
      isFocused={p.isFocused}
      isSelected={p.isSelected}
      uploadGroup={p.uploadGroup}
      onClick={() => p.onEntryClick(p.entry)}
      onSelect={handleSelect}
      isGlobalSearch={p.isGlobalSearch}
      isSelectable={p.isSelectable}
    />
  );
});
