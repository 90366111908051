import React, { useState, useContext, forwardRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";
import { enGB, nb } from "date-fns/locale";
import { Locale } from "date-fns";
import { useIntl } from "react-intl";
import { IconButton } from "@web/elements/Button";
import { ActionMenu, Input } from "@web/elements";
import { vars } from "@web/styles";
import { commonTexts, SupportedLocale } from "@web/translations";
import { getDateWithoutTime, MONTHS, YEARS } from "@web/utils/dates";
import { GlobalConfig } from "@config/context";
import { DatePickerWrap } from "./style";

interface IProps
  extends Omit<
    ReactDatePickerProps,
    | "onChange"
    | "locale"
    | "onChange"
    | "selected"
    | "popperModifiers"
    | "popperPlacement"
  > {
  initialDate: string | Date | undefined | null;
  onDateSelect: (date: Date, id?: string) => void;
  onClose?: () => void;
  onChanged?: (date: Date) => void;
  renderBeforeButtons?: () => JSX.Element | undefined;
}

function dateFnsLocaleForLocale(locale: SupportedLocale): Locale {
  switch (locale) {
    case SupportedLocale.NB:
      return nb;
    case SupportedLocale.EN:
      return enGB;
  }
}

/**
 * Because we want react-datepicker to use *our* fancy smanchy `<input />` which looks like other
 * `<input />`s in our app;
 *
 * react-datepicker needs to be able to get a React.js ref from the custom input we provide,
 * where the string representation of the currently selected date is shown.
 *
 * Without the forwaring ref trickery below, we'd get some rather nasty errors logged by React.js,
 * telling us ref's cannot be extracted from functional components. The current alternative would be
 * to write `<Input />` as class component, but that's not what the cool kids do.
 *
 * https://github.com/Hacker0x01/react-datepicker/issues/862
 */
const InputWithRef = forwardRef((props, ref) => (
  <Input {...props} inputRef={ref as any} />
));

export const DMDatePicker: React.FC<IProps> = (props) => {
  const intl = useIntl();
  const config = useContext(GlobalConfig);
  const { initialDate, onDateSelect, onClose, ...rest } = props;
  const [date, setDate] = useState(
    initialDate !== null ? getDateWithoutTime(initialDate) : null
  );
  const [changed, setChanged] = useState(false);

  React.useEffect(() => {
    return () => {
      props.onClose?.();
    };
  }, []);

  function handleChange(date: Date) {
    props.onChanged?.(date);
    setChanged(true);
    setDate(date);
    if (!props.inline) {
      props.onDateSelect(date, props.id);
    }
  }

  function handleDateSelect() {
    if (date) {
      props.onDateSelect(date, props.id);
    }
    props.onClose?.();
  }

  function closeDatepicker() {
    props.onClose?.();
  }

  const locale = dateFnsLocaleForLocale(config.locale);

  const canSave = () => {
    if (props.initialDate) {
      const initial = getDateWithoutTime(props.initialDate);
      return date?.getTime() !== initial.getTime();
    }
    return true;
  };

  return (
    <DatePickerWrap inline={props.inline}>
      <DatePicker
        inline={props.inline}
        shouldCloseOnSelect={!props.inline}
        locale={locale}
        // TODO: dive into react-datepicker to see if there are any tricks to make it choose date format
        //       from the .locale provided to it. It did not when writing this, and since we want consistent
        //       locale thoughout, we hard code this to be en-GB/nb formatting for now
        dateFormat="dd/MM/yyyy"
        selected={date}
        onChange={handleChange}
        popperPlacement="bottom-end"
        popperModifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
          },
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          changeYear,
          changeMonth,
        }) => {
          return (
            <>
              <MonthSelector>
                <IconButton
                  text={commonTexts.previous}
                  icon="CaretLeftIcon"
                  onClick={decreaseMonth}
                />
                <MonthContainer>
                  <select
                    aria-label={intl.formatMessage(commonTexts.month)}
                    value={date.getMonth()}
                    onChange={({ target: { value } }) =>
                      changeMonth(Number(value))
                    }
                  >
                    {MONTHS.map((option, i) => (
                      <option key={i} value={i}>
                        {intl.formatDate(option, { month: "long" })}
                      </option>
                    ))}
                  </select>
                  <select
                    aria-label={intl.formatMessage(commonTexts.year)}
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) =>
                      changeYear(Number(value))
                    }
                  >
                    {YEARS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </MonthContainer>
                <IconButton
                  text={commonTexts.next}
                  icon="CaretRightIcon"
                  onClick={increaseMonth}
                />
              </MonthSelector>
            </>
          );
        }}
        customInput={<InputWithRef />}
        {...rest}
      >
        {props.inline && (
          <_footer>
            {changed && props?.renderBeforeButtons?.()}
            <ActionMenu
              direction={"vertical"}
              applyIsDisabled={!canSave()}
              applyText={commonTexts.apply}
              onApply={handleDateSelect}
              onCancel={closeDatepicker}
            />
          </_footer>
        )}
      </DatePicker>
    </DatePickerWrap>
  );
};

const _footer = styled.div`
  margin: 0 0 10px 0;
  border-top: 1px solid ${vars.dark05};
`;

const MonthSelector = styled.div`
  margin-top: 10px;
`;

const MonthContainer = styled.div`
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  line-height: 16px;
  > * {
    margin: 0 5px;
  }
  select {
    border-color: ${vars.dark05};
  }
`;
