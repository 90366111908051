import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { ColorStatusModel, TagStatusColor } from "@web/models";
import { CheckMark, StatusColor } from "@web/elements";
import { Button } from "@web/elements/Button";
import { commonComponentTexts } from "./texts";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

interface Props {
  initialStatus: ColorStatusModel | undefined;
  options: ColorStatusModel[];
  onChange: (status: ColorStatusModel | undefined) => void;
  onCancel?: () => void;
}

export const TagStatusSelect: React.FC<Props> = observer((p) => {
  const intl = useIntl();
  const [selectedStatus, selectStatus] = useState(p.initialStatus);

  const handleChange = (status?: ColorStatusModel) => {
    if (selectedStatus?.uuid === status?.uuid) {
      selectStatus(undefined);
      p.onChange(undefined);
    } else {
      selectStatus(status);
      p.onChange(status);
    }
  };

  return (
    <_wrap>
      <h2>{intl.formatMessage(commonTexts.status)}</h2>
      <_content>
        {p.options.map((st) => (
          <_option key={st.uuid}>
            <CheckMark
              isSelected={selectedStatus?.uuid === st.uuid}
              onSelect={() => handleChange(st)}
            />
            {st.color !== "noColor" && (
              <StatusColor color={TagStatusColor[st.color]} />
            )}
            <_name>
              {st.name}
              {st.isArchived && (
                <div>
                  {intl.formatMessage(commonComponentTexts.statusIsArchived)}
                </div>
              )}
            </_name>
          </_option>
        ))}
        <_option>
          <CheckMark
            isSelected={!selectedStatus}
            onSelect={() => handleChange(undefined)}
          />
          <_name>{intl.formatMessage(texts.noStatus)}</_name>
        </_option>
      </_content>
      {p.onCancel && (
        <_cancel>
          <Button text={commonTexts.cancel} onClick={p.onCancel} />
        </_cancel>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  width: 250px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  h2 {
    margin: 0;
    padding: 0 15px 15px 15px;
    border-bottom: 1px solid ${vars.dark05};
  }
`;

const _content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  flex-grow: 1;
  overflow-y: auto;
`;

const _option = styled.label`
  font-size: 14px;
  display: flex;
  margin: 10px 0;
  align-items: center;
  > * {
    margin-right: 8px;
  }

  svg {
    flex-shrink: 0;
  }

  input {
    appearance: none;
    position: absolute;
  }
`;
const _name = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  > div {
    opacity: 0.8;
    font-size: 0.8em;
  }
`;

const _cancel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: ${vars.primaryLight10};
  border-top: 1px solid ${vars.dark05};
  padding-top: 10px;
`;

const texts = defineMessages({
  noStatus: {
    id: "classification.status.nostatus",
    defaultMessage: "No status",
  },
});
