import { Theme } from "react-select";
import { vars } from "@web/styles";

export const selectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: vars.primary,
    primary25: vars.dark05,
    primary50: vars.primary,
  },
});
