import { observable, action, computed } from "mobx";
import { FlowStore } from "@web/stores";
import { sleep } from "@web/utils/helpers";
import {
  UUID,
  IPipelineNode,
  Permission,
  IConditionNode,
  IRuleNode,
  IResultNode,
  EntryHasTagFromClassification,
  EntryHasExactTag,
  ILoadingStatus,
  ITagConditionNode,
  ISectionConditionNode,
  DocumentTitleStartsWith,
  IEventNode,
  IOutcomeNode,
  ICommentOutcomeNode,
  IStatusOutcomeNode,
  OutcomeType,
  INotificationOutcomeNode,
  ITargetNode,
  TargetName,
  IUserConditionNode,
  ConditionType,
  EntryHasAttributeValue,
  ITagNode,
} from "@web/api/Integration/types";
import { PermissionModel, WritableFields, EntryModel } from "@web/models";
import PubSub, { DeleteEvent } from "@web/stores/PubSub";

type EntryUUID = UUID;
export type FlowEntityType =
  | "details"
  | "events"
  | "rules"
  | "conditions"
  | "outcomes";

export type OutcomeEventType = "pipelineSuccess" | "pipelineFailure";
export type PipelineType = "validation" | "notification" | "reminder";
export type PipelineCreateFields = Pick<
  PipelineModel,
  "name" | "description" | "pipelineType"
> & {
  targetName: TargetName;
};

export class PipelineModel implements PermissionModel {
  @observable uuid: UUID;
  @observable permissions: Set<Permission>;
  @observable name: string;
  @observable createdBy: string;
  @observable createdDate: string;
  @observable pipelineType?: PipelineType;
  @observable description?: string;
  @observable isEnabled: boolean;
  @observable target: ITargetNode;
  @observable conditions: IConditionNode[];
  @observable events: IEventNode[];
  @observable rules: IRuleNode[];
  @observable successOutcomes: IOutcomeNode[];
  @observable failureOutcomes: IOutcomeNode[];
  @observable ruleLoadingStatus: ILoadingStatus;
  @observable tagConditionsExpanded: ITagNode[];

  // Results per entry, for the latest execution of this pipeline
  @observable results: Map<EntryUUID, IResultNode[]> = new Map();

  @observable saving: Record<FlowEntityType, boolean> = {
    details: false,
    events: false,
    rules: false,
    conditions: false,
    outcomes: false,
  };

  private pollingForEntries = new Set<EntryUUID>();

  @computed
  get outcomeCount() {
    return this.successOutcomes.length + this.failureOutcomes.length;
  }

  @computed
  get tagConditions() {
    return this.conditions.filter(isTagCondition);
  }

  @computed
  get sectionConditions() {
    return this.conditions.filter(isSectionCondition);
  }

  @computed
  get classificationRules() {
    return this.rules.filter(isClassificationRule);
  }

  @computed
  get documentRules() {
    return this.rules.filter(isDocumentRule);
  }

  @computed
  get attributeRules() {
    return this.rules.filter(isAttributeRule);
  }

  @computed
  get isActive() {
    return this.isEnabled && this.rules.length > 0;
  }

  @computed
  get isValid() {
    if (this.pipelineType === "validation") {
      return (
        this.successOutcomes.length > 0 &&
        this.rules.length > 0 &&
        this.conditions.length > 0
      );
    }
    return this.successOutcomes.length > 0;
  }

  @computed
  get eventIds() {
    return this.events.map((event) => event.id);
  }

  @computed
  get supportedEvents() {
    // Only a subset of the events can be applied to different pipeline types
    if (!this.pipelineType) {
      return [];
    }

    return this.store.getSupportedEvents(
      this.pipelineType,
      this.target.name as TargetName
    );
  }

  constructor(private store: FlowStore, node: IPipelineNode) {
    this.uuid = node.id;
    this.permissions = new Set(node.effectivePermissions);
    this.name = node.name;
    this.createdBy = node.createdBy;
    this.createdDate = node.createdDate;
    this.pipelineType = node.pipelineType;
    this.description = node.description;
    this.isEnabled = node.isEnabled;
    this.target = node.target;
    this.conditions = node.conditions;
    this.events = node.events;
    this.rules = node.rules;
    this.successOutcomes = node.successOutcomes;
    this.failureOutcomes = node.failureOutcomes;
    this.ruleLoadingStatus = node.resources.rules;
    this.tagConditionsExpanded = [];

    PubSub.getInstance().subscribe(this);
  }

  @action.bound
  setSavingStatus(entity: FlowEntityType, saving: boolean) {
    this.saving[entity] = saving;
  }

  @action.bound
  async loadAllRules(): Promise<void> {
    if (this.ruleLoadingStatus.hasMore) {
      const rules = await this.store.loadRulesForPipeline(this.uuid);
      this.rules = rules.data.data;
      this.ruleLoadingStatus.hasMore = rules.data.hasMore;
    }
  }

  entryMatchesConditions(entry: EntryModel): boolean {
    const entryTags = entry.tags.allTagIds;
    const entrySection = entry.sectionUuid;
    if (!this.isActive) {
      return false;
    }

    return (
      this.tagsMatchesConditions(entryTags) &&
      this.sectionMatchesConditions(entrySection)
    );
  }

  tagsMatchesConditions(tagIds: UUID[] = []): boolean {
    if (this.tagConditions.length === 0) {
      return true;
    }
    return this.tagConditions
      .flatMap((cond) => cond.entryTagId)
      .every((uuid) => tagIds.includes(uuid));
  }

  sectionMatchesConditions(sectionId: UUID): boolean {
    if (this.sectionConditions.length === 0) {
      return true;
    }
    return this.sectionConditions
      .flatMap((cond) => cond.entrySectionId)
      .every((uuid) => uuid === sectionId);
  }

  @action.bound
  async loadTagsExpandedForConditions(): Promise<void> {
    const tags: ITagNode[] = await this.store.findTagsWithAttributeDefinitions(
      this.tagConditions.map((c) => c.entryTagId)
    );
    this.tagConditionsExpanded = tags;
  }

  documentProgressForEntry(entryId: UUID) {
    const documentRuleIds = this.documentRules.map((rule) => rule.id);
    if (documentRuleIds.length === 0) {
      return 0;
    }

    const latestResults = this.results.get(entryId);
    if (!latestResults) {
      return 0;
    }

    const documentSuccessCount =
      latestResults?.filter(
        (res) => res.isSuccessful && documentRuleIds.includes(res.componentId)
      )?.length || 0;

    return Math.floor((documentSuccessCount / documentRuleIds.length) * 100);
  }

  conditionDescriptionForEntry(entry: EntryModel) {
    if (this.sectionConditions.length > 0) {
      return entry.sectionTitle;
    }

    if (this.tagConditions.length > 0) {
      return entry.tags.allTags
        .filter((tag) =>
          this.tagConditions
            .flatMap((cond) => cond.entryTagId)
            .some((id) => id === tag.uuid)
        )
        .map((tag) => tag.title)
        .join(", ");
    }

    return "";
  }

  /**
   * Returns all required classificatins for an entry,
   * minus the ones that has already been validated by the flow results.
   * @param entryId UUID of the entry
   */
  requiredClassifications(entryId: UUID): UUID[] {
    const results = this.results.get(entryId);

    if (!results) {
      return [];
    }

    return this.classificationRules
      .filter((rule) =>
        results.some((res) => res.componentId === rule.id && !res.isSuccessful)
      )
      .map((rule) => rule.entryTagClassificationId);
  }

  /**
   * Returns all required attributes for an entry,
   * minus the ones that has already been validated by the flow results.
   * @param entryId UUID of the entry
   */
  requiredAttributes(entryId: UUID): UUID[] {
    const results = this.results.get(entryId);

    if (!results) {
      return [];
    }

    return this.attributeRules
      .filter((rule) =>
        results.some((res) => res.componentId === rule.id && !res.isSuccessful)
      )
      .map((rule) => rule.definitionId);
  }

  validatedDocuments(entryId: UUID): UUID[] {
    const results = this.results.get(entryId);

    if (!results) {
      return [];
    }

    return this.documentRules
      .filter((rule) =>
        results.some(
          (result) => result.componentId === rule.id && result.isSuccessful
        )
      )
      .map((rule) => rule.id);
  }

  /**
   * Update fields on this pipeline object.
   */
  @action.bound
  updateState(fields: Partial<PipelineModel>) {
    Object.assign(this, fields);
  }

  @action.bound
  async refreshResults(entryId: UUID) {
    try {
      const { data } = await this.store.loadResultsForEntry(this.uuid, entryId);

      const currentResults = this.results.get(entryId);
      const currentResultsTime = currentResults?.length
        ? currentResults[0].createdDate
        : undefined;
      const fetchedResulstsTime = data.data?.[0]?.createdDate || new Date();

      this.results.set(entryId, data.data);

      if (!currentResultsTime) {
        return data.data.length > 0;
      }

      return (
        new Date(currentResultsTime).getTime() <
        new Date(fetchedResulstsTime).getTime()
      );
    } catch (e) {
      console.error(`Failed to load flow results for entry #${entryId}`, e);
    }
  }

  shouldPollForResults(entryId: UUID): boolean {
    if (!this.isActive) {
      return false;
    }

    if (this.pollingForEntries.has(entryId)) {
      return false;
    }

    return true;
  }

  /**
   * Poll for fresh results for an entry. Sends 5 requests with increasing delay.
   * @param entryId UUID of the entry
   */
  async pollResults(entryId: UUID) {
    if (!this.shouldPollForResults(entryId)) {
      return;
    }

    this.pollingForEntries.add(entryId);

    for (let attemptNumber = 1; attemptNumber < 6; attemptNumber++) {
      const delay = 250 * attemptNumber + 500 * (attemptNumber - 1);
      await sleep(delay);
      const hasNewResults = await this.refreshResults(entryId);
      if (hasNewResults) {
        break;
      }
    }

    this.pollingForEntries.delete(entryId);
  }

  delete() {
    this.store.deletePipeline(this.uuid, this.pipelineType);
  }

  update(fields: WritableFields<PipelineModel>) {
    this.store.updatePipeline(this, fields);
  }

  updateRule(rule: IRuleNode, fields: Partial<IRuleNode>) {
    this.store.updateRule(this, rule, fields);
  }

  addRule(fields: Partial<IRuleNode>) {
    this.store.addRule(this, fields);
  }

  deleteRule(ruleId: UUID) {
    this.store.deleteRule(this, ruleId);
  }

  addCondition(type: ConditionType, fields: Partial<IConditionNode>) {
    this.store.addCondition(this, type, fields);
  }

  deleteCondition(conditionId: UUID) {
    this.store.deleteCondition(this, conditionId);
  }

  updateEvents(eventId: UUID, selected: boolean) {
    this.store.updatePipelineEvents(this, eventId, selected);
  }

  createOutcome(
    type: OutcomeType,
    eventType: OutcomeEventType,
    fields: Partial<IOutcomeNode>
  ) {
    this.store.createOutcome(this, type, eventType, fields);
  }

  updateOutcome(outcome: IOutcomeNode, fields: Partial<IOutcomeNode>) {
    this.store.updateOutcome(this, outcome, fields);
  }

  deleteOutcome(outcomeId: UUID) {
    this.store.deleteOutcome(this, outcomeId);
  }

  /** Subscriber interface */
  onDataDeleted(event: DeleteEvent): void {
    if (event.type === "Entry") {
      this.results.delete(event.uuid);
      this.pollingForEntries.delete(event.uuid);
    }
  }
}

export function isClassificationRule(
  rule: IRuleNode
): rule is EntryHasTagFromClassification {
  return rule.type === "EntryHasTagFromClassification";
}

export function isDocumentRule(
  rule: IRuleNode
): rule is DocumentTitleStartsWith {
  return rule.type === "DocumentTitleStartsWith";
}

// NOTE: not needed yet
function isTagRule(rule: IRuleNode): rule is EntryHasExactTag {
  return rule.type === "EntryHasExactTag";
}

export function isAttributeRule(
  rule: IRuleNode
): rule is EntryHasAttributeValue {
  return rule.type === "EntryHasAttributeValue";
}

function isTagCondition(
  condition: IConditionNode
): condition is ITagConditionNode {
  return condition.type === "EntryHasTag";
}

function isSectionCondition(
  condition: IConditionNode
): condition is ISectionConditionNode {
  return condition.type === "EntryHasSection";
}

function isUserCondition(
  condition: IConditionNode
): condition is IUserConditionNode {
  return condition.type === "UserBelongsToGroup";
}

export function isCommentOutcome(
  outcome: IOutcomeNode
): outcome is ICommentOutcomeNode {
  return outcome.type === "AddEntryComment";
}

export function isStatusOutcome(
  outcome: IOutcomeNode
): outcome is IStatusOutcomeNode {
  return outcome.type === "ChangeEntryStatus";
}

export function isNotificationOutcome(
  outcome: IOutcomeNode
): outcome is INotificationOutcomeNode {
  return (
    outcome.type === "SendSlackNotification" ||
    outcome.type === "SendTeamsNotification"
  );
}
