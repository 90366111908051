import { action, computed } from "mobx";
import { IAccessGroupNode } from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import { QueryLoadingModel } from "../DataLoadingModel";
import { AccessGroupListModel } from "./AccessGroupListModel";
import { AccessGroupModel } from "./AccessGroupModel";

export class AccessGroupMatrixModel {
  constructor(
    private store: AccessControlStore,
    private accessGroupList: AccessGroupListModel
  ) {}

  @computed
  get accessGroups(): AccessGroupModel[] {
    return this.accessGroupList.accessGroups;
  }

  @computed
  get loadingStatus(): QueryLoadingModel {
    return this.accessGroupList.loadingStatus;
  }

  @action.bound
  async loadNextPage(): Promise<void> {
    return this.accessGroupList.loadNextPage();
  }

  @action.bound
  filterGroups(query: string) {
    this.accessGroupList.filter(query);
  }

  reloadAccessGroups() {
    this.accessGroupList.reload();
  }

  async createAccessGroup(
    fields: Pick<IAccessGroupNode, "name" | "claims" | "description">
  ) {
    return this.store.createAccessGroup(fields);
  }
}
