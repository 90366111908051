import React from "react";

export const FullscreenEnterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 14.6667H4V20H9.33333V18H6V14.6667ZM4 9.33333H6V6H9.33333V4H4V9.33333ZM18 18H14.6667V20H20V14.6667H18V18ZM14.6667 4V6H18V9.33333H20V4H14.6667Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
