import React, { SyntheticEvent, useState } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { ActionMenu } from "@web/elements";
import { BackButton, IconButton } from "@web/elements/Button";
import { ClockIcon } from "@web/elements/Icons";
import { Input } from "@web/elements/Input";
import { commonTexts } from "@web/translations";
import { DMDatePicker } from "@web/components";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { ReminderModel } from "@web/models";
import { oneMonthFromNow } from "@web/utils/dates";
import { vars } from "@web/styles";

interface ReminderFormProps {
  reminder?: ReminderModel;
  onClose: () => void;
  onDeleteClick?: () => Promise<void>;
  onSave: (title: string, date: Date) => Promise<void>;
}

export const ReminderForm = (p: ReminderFormProps) => {
  const initialTitle = p.reminder?.title ?? "";
  const initialDate = p.reminder?.date ?? oneMonthFromNow();

  const intl = useIntl();
  const [title, setTitle] = useState(initialTitle);
  const [date, setDate] = useState(initialDate);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [getConfirmation] = useConfirmationDialog();

  async function onSubmit(evt: SyntheticEvent) {
    evt.preventDefault();
    setIsSavingChanges(true);

    try {
      await p.onSave(title, date);
    } finally {
      setIsSavingChanges(false);
    }

    p.onClose();
  }

  async function handleDeleteClick() {
    const wasConfirmed = await getConfirmation({
      title: texts.deleteReminderTitle,
      message: texts.deleteReminderQuestion,
      confirmText: commonTexts.delete,
    });

    if (wasConfirmed) {
      await p.onDeleteClick?.();
      p.onClose();
    }
  }

  const canSave = () => {
    if (isSavingChanges || title.trim().length === 0) {
      return false;
    }
    if (p.reminder) {
      return title.trim() !== initialTitle || date !== initialDate;
    }

    return title.trim() !== "";
  };

  return (
    <_wrap>
      <_header>
        <BackButton onClick={p.onClose} />

        {p.onDeleteClick && p.reminder?.canDelete && (
          <IconButton
            icon="DeleteIcon"
            text={commonTexts.delete}
            onClick={handleDeleteClick}
          />
        )}
      </_header>

      <_form onSubmit={onSubmit} autoComplete="off">
        <_row>
          <Input
            placeholder={
              p.reminder?.title || intl.formatMessage(texts.titlePlaceholder)
            }
            width="100%"
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
            autoFocus
          />
        </_row>

        {p.reminder?.description !== undefined && (
          <_row>
            <_autoAdded>
              {intl.formatMessage(texts.autoAddedDescription)}
              {" " /* needed for sensible tests */}
              <_autoAddedText>
                <em>{p.reminder.description}</em>
              </_autoAddedText>
            </_autoAdded>
          </_row>
        )}

        <_row>
          <ClockIcon />
          <DMDatePicker
            initialDate={date}
            minDate={new Date()}
            onDateSelect={setDate}
            title={intl.formatMessage(texts.dateHoverText)}
          />
        </_row>

        <ActionMenu
          direction="vertical"
          applyIsDisabled={!canSave()}
          onApply={onSubmit}
          onCancel={p.onClose}
          applyText={commonTexts.save}
        />
      </_form>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

const _header = styled.header`
  display: flex;
  justify-content: space-between;
`;

const _form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const _row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const _autoAdded = styled.small`
  background: ${vars.note};
  color: ${vars.noteFg};
  padding: 3px;
  border-radius: 2px;
`;

const _autoAddedText = styled.p`
  margin-bottom: 0;
`;

const texts = defineMessages({
  autoAddedDescription: {
    id: "entry.reminders.form.automatically-added",
    defaultMessage:
      "We found a future date in the document so we'll remind you 4 weeks before.",
  },
  titlePlaceholder: {
    id: "entry.reminders.form.titleplaceholder",
    defaultMessage: "Title of reminder",
  },
  dateHoverText: {
    id: "entry.reminders.form.datehover",
    defaultMessage: "Date",
  },
  deleteReminderTitle: {
    id: "entry.reminders.form.confirmdelete.title",
    defaultMessage: "Delete reminder",
  },
  deleteReminderQuestion: {
    id: "entry.reminders.form.confirmdelete.question",
    defaultMessage: "Are you sure you want to delete this reminder?",
  },
});
