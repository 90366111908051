import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { DMFormattedDate, ProfileImage, DateTime } from "@web/elements";
import { vars } from "@web/styles";
import { texts } from "./texts";
import { LockModel } from "@web/models";
import { LockIcon } from "@web/elements/Icons";

export const LockedBy: React.FC<{
  lock: LockModel;
}> = (p) => (
  <div>
    <_wrap>
      <_icon>
        <LockIcon />
      </_icon>
      <_lockedText>
        <span className="locked">
          <FormattedMessage
            {...texts.lockedSince}
            values={{
              formattedDate: <DMFormattedDate value={p.lock.createdDate} />,
            }}
          />
        </span>
        <span className="unlocks">
          <FormattedMessage
            {...texts.lockExpires}
            values={{
              formattedDate: <DateTime datetime={p.lock.expiryDate} />,
            }}
          />
        </span>
      </_lockedText>
      <_icon>
        <ProfileImage name={p.lock.createdBy} size="small" />
      </_icon>
      <_userText>{p.lock.createdBy}</_userText>
    </_wrap>
  </div>
);

const _wrap = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
`;

const _icon = styled.div`
  justify-self: center;
  color: ${vars.contentFg};
`;

const _lockedText = styled.div`
  color: ${vars.contentFg};
  > span.unlocks {
    color: ${vars.dark55};
    display: block;
    margin-top: 0.1rem;
  }
`;

const _userText = styled.div`
  color: ${vars.dark55};
`;
