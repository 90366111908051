import React from "react";
import { useIntl, defineMessages } from "react-intl";
import { SectionModel } from "@web/models";
import { MoreButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";

interface SectionMenuProps {
  section: SectionModel;
  onDelete: () => void;
  onRename: () => void;
}

export const SectionMenu: React.FC<SectionMenuProps> = ({
  section,
  onDelete,
  onRename,
}) => {
  const intl = useIntl();

  if (section.isReadOnly) {
    return null;
  }

  return (
    <Dropdown toggle={(ref) => <MoreButton text={texts.title} ref={ref} />}>
      <Menu>
        {section.canUpdate && (
          <MenuItem onClick={onRename}>
            {intl.formatMessage(texts.rename)}
          </MenuItem>
        )}
        {section.canDelete && (
          <MenuItem onClick={onDelete}>
            {intl.formatMessage(commonTexts.delete)}
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

const texts = defineMessages({
  title: {
    id: "settings.section.menu.title",
    defaultMessage: "Edit section",
  },
  rename: {
    id: "settings.section.menu.rename",
    defaultMessage: "Rename",
  },
});
