import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { Outlet } from "react-router-dom";
import { vars, ZIndex } from "@web/styles";
import { media, useMedia } from "@web/styles/utils";
import { getScrollElement } from "@web/utils/helpers";
import { useSearchNavigationHandler } from "@web/pages/SearchPage/handler";
import { TopbarContainer } from "@web/pages/SearchPage/containers/TopbarContainer";
import { EntryUploadDropzone } from "@web/components/Upload/EntryUploadDropzone";
import { ErrorPage } from "@web/pages/ErrorPage";
import { useStores } from "@web/stores/context";
import { useConfig } from "@config/context";
import { EntryPage } from "../EntryPage";
import { BucketContainer } from "./containers/BucketContainer";
import UploadContainer from "./containers/UploadContainer";
import ToolbarContainer from "./containers/ToolbarContainer";
import ResultsContainer from "./containers/ResultsContainer";
import { UploadRequest } from "@web/models";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";

function useScroll(callback: (args: [number, number]) => void) {
  const [, setScrollPos] = useState(0);
  let prevScrollTop = 0;

  function handleScroll() {
    const currScrollTop = getScrollElement().scrollTop;

    setScrollPos((prevPos: number) => {
      prevScrollTop = prevPos;
      return currScrollTop;
    });

    callback([prevScrollTop, currScrollTop]);
  }

  const handleScrollThrottled = throttle(handleScroll, 250);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollThrottled);
    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  });
}

export const SearchPage = observer(() => {
  useSearchNavigationHandler();
  const stores = useStores();
  const config = useConfig();

  const getConfirmation = useDuplicateUploadConfirmDialog();
  const [isScrolled, setScrolled] = useState(false);
  const { isCompact } = useMedia();
  const [isCollapsed, setCollapsed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(
    undefined
  );
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.getBoundingClientRect().height;
      if (headerHeight !== height) {
        setHeaderHeight(height);
      }
    }
  }, [isCompact]);

  useScroll(([prev, curr]) => {
    const MINIMUM = 100;
    const THRESHOLD = 25;

    const isScrolledDown = prev < curr;
    const isWithinThreshold = Math.abs(prev - curr) > THRESHOLD;
    const isMinimumScrolledDown = curr > MINIMUM;
    const shouldCollapse = isScrolledDown && isMinimumScrolledDown;

    setScrolled(curr > 2);

    if (isWithinThreshold) {
      setCollapsed(shouldCollapse);
    }

    if (headerRef.current) {
      const height = headerRef.current.getBoundingClientRect().height;
      if (headerHeight !== height) {
        setHeaderHeight(height);
      }
    }
  });

  if (stores.sectionStore.error) {
    return <ErrorPage error={stores.sectionStore.error} />;
  }

  const {
    recordStore,
    sectionStore,
    filterStore,
    uploadStore,
    multiSelectStore,
  } = stores;

  const isMovingEntries = multiSelectStore.entries.action === "Move";
  const isCopyingEntries = multiSelectStore.entries.action === "Copy";
  const isUploadDisabled =
    !sectionStore.canWriteInSelectedSection || !config.canUploadDocuments;

  const onUpload = async (request: UploadRequest) => {
    return await uploadStore.addRequest(
      request,
      filterStore.haveToSelectFromMandatoryClass
    );
  };

  return (
    <EntryUploadDropzone
      isDisabled={isUploadDisabled}
      sectionId={sectionStore.selectedSection?.uuid}
      tags={filterStore.selectedTags}
      onUpload={onUpload}
      getConfirmation={getConfirmation}
    >
      <_wrap
        headerHeight={headerHeight}
        isCollapsed={!isMovingEntries && !isCopyingEntries && isCollapsed}
        viewType={stores.resultStore.viewType}
      >
        <_header ref={headerRef}>
          {config.hasFeature("search") && <TopbarContainer />}
        </_header>

        <_toolbar isScrolled={isScrolled}>
          <ToolbarContainer />
        </_toolbar>

        <_results>
          <ResultsContainer />
        </_results>
      </_wrap>

      <_upload>
        <UploadContainer />
      </_upload>

      <>{recordStore.entry?.isDraft && <EntryPage />}</>

      <BucketContainer />

      <Outlet />
    </EntryUploadDropzone>
  );
});

const _wrap = styled.div<{
  headerHeight: number | undefined;
  isCollapsed: boolean;
  viewType: string;
}>`
  --search-header-height: ${(p) => p.headerHeight && p.headerHeight + "px"};
  --search-header-top: ${(p) =>
    p.isCollapsed ? "calc(var(--search-header-height) * -1)" : "0px"};
  --search-toolbar-top: ${(p) =>
    p.isCollapsed ? "0px" : "var(--search-header-height)"};

  ${media("desktop")} {
    --search-toolbar-height: ${(p) => p.viewType === "list" && "4rem"};
    --search-toolbar-height: ${(p) => p.viewType === "grid" && "2rem"};
    --search-header-transition-duration: 300ms;
    --search-safe-area-top: calc(
      var(--search-header-height) + var(--search-header-top)
    );
  }

  ${media("compact")} {
    --search-toolbar-height: 60px;
    --search-header-transition-duration: 200ms;
    --search-safe-area-top: calc(
      calc(var(--search-toolbar-height) / 2)
        ${(p) => !p.isCollapsed && "+ var(--search-header-height)"}
    );
  }
`;

const _header = styled.header`
  position: sticky;
  top: var(--search-header-top);
  transition: top var(--search-header-transition-duration);
  z-index: ${ZIndex.header};
`;

const _toolbar = styled.div<{ isScrolled: boolean }>`
  box-shadow: ${(p) => (p.isScrolled ? vars.shadow.z2 : "none")};
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  position: sticky;
  top: var(--search-toolbar-top);
  transition: top var(--search-header-transition-duration), box-shadow 200ms;
  height: var(--search-toolbar-height);

  ${media("desktop")} {
    z-index: ${ZIndex.resultsToolbar + 1};
  }

  ${media("compact")} {
    background: ${vars.body};
    z-index: ${ZIndex.resultsToolbar - 1};
  }

  pointer-events: none;

  > * {
    pointer-events: initial;
  }
`;

const _results = styled.div`
  padding: 0 1rem 1rem;
`;

const _upload = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: ${ZIndex.overlay - 1};

  ${media("compact")} {
    bottom: calc(var(--layout-safe-area-bottom, 0px) + 1rem);
  }
`;
