import { Permission } from "@web/api/Integration/types";
import { SectionModel } from ".";

export interface PermissionModel {
  permissions: Set<Permission>;
}

export function canDelete<T extends PermissionModel>({ permissions }: T) {
  return permissions.has("Delete");
}

export function canUpdate<T extends PermissionModel>({ permissions }: T) {
  return permissions.has("Update");
}

export function canCreateChildren<T extends PermissionModel>({
  permissions,
}: T) {
  return permissions.has("CreateChildren");
}

export function isReadOnly<T extends PermissionModel>({ permissions }: T) {
  return (
    !permissions.has("CreateChildren") &&
    !permissions.has("Update") &&
    !permissions.has("Delete")
  );
}

export function canUpload({ permissions }: SectionModel) {
  return permissions.has("CreateChildren");
}
