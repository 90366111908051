import React from "react";

export const ImageIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <path
      d="M36.2309 53.692C28.9859 53.692 21.7634 53.692 14.5184 53.692C14.2034 53.692 13.8884 53.715 13.5734 53.669C11.6384 53.4853 10.5359 51.7856 11.1884 49.9021C14.1134 41.5185 17.0609 33.1578 20.0084 24.7971C20.4134 23.6716 21.2009 22.9366 22.3709 22.8217C23.6084 22.6839 24.5309 23.3041 25.0934 24.4066C26.9384 27.9208 28.7609 31.4351 30.5834 34.9493C30.7184 35.202 30.8309 35.4776 30.9884 35.7303C31.5284 36.6261 32.0684 36.718 32.8109 35.96C33.9809 34.7656 35.1059 33.5253 36.2309 32.3079C38.0309 30.4015 39.6509 30.4704 41.2484 32.5376C43.5434 35.5236 45.8384 38.4866 48.1334 41.4725C49.1684 42.8047 49.6859 42.7818 50.6309 41.3806C50.9234 40.9213 51.2159 40.4619 51.5309 40.0025C52.7684 38.2798 54.9284 38.3028 56.0309 40.1174C57.8759 43.1493 59.6759 46.2041 61.4084 49.282C62.5784 51.3492 61.4309 53.4853 59.0684 53.692C58.6634 53.7379 58.2359 53.715 57.8309 53.715C50.6309 53.692 43.4309 53.692 36.2309 53.692Z"
      fill="currentColor"
    />
    <path
      d="M46.332 23.1222C46.332 20.2741 48.582 17.9772 51.3495 18.0002C54.072 18.0231 56.2995 20.32 56.2995 23.0993C56.2995 25.9015 54.0045 28.2443 51.2595 28.2214C48.537 28.1984 46.3545 25.9245 46.332 23.1222Z"
      fill="currentColor"
    />
  </svg>
);
