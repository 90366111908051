import React from "react";

export const MoveIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM11.5536 8.16421C11.5536 7.27331 12.6307 6.82714 13.2607 7.45711L17.2215 11.4179C17.612 11.8084 17.612 12.4416 17.2215 12.8321L13.2607 16.7929C12.6307 17.4229 11.5536 16.9767 11.5536 16.0858V14.244C11.5536 13.9679 11.3297 13.744 11.0536 13.744H7.5C7.22386 13.744 7 13.5202 7 13.244V11.006C7 10.7298 7.22386 10.506 7.5 10.506H11.0536C11.3297 10.506 11.5536 10.2821 11.5536 10.006V8.16421Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
