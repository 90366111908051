import { BffPaths } from "@web/api/BFF/types";
import {
  getCLS,
  getFID,
  getLCP,
  getFCP,
  getTTFB,
  ReportHandler,
} from "web-vitals";

export const reportWebVitals = (onMetricReadyCallback: ReportHandler) => {
  getCLS(onMetricReadyCallback);
  getFID(onMetricReadyCallback);
  getFCP(onMetricReadyCallback);
  getLCP(onMetricReadyCallback);
  getTTFB(onMetricReadyCallback);
};

export const sendToBff: ReportHandler = (metric) => {
  const body = JSON.stringify({ name: metric.name, value: metric.value });
  const url = "/bff/" + BffPaths.metricsGui;

  // We don't want to pollute metrics exposed from our dev containers with results from running locally
  if (process.env.NODE_ENV === "development") {
    return;
  }

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  if (navigator.sendBeacon) {
    const headers = {
      type: "application/json",
    };
    const blob = new Blob([body], headers);
    navigator.sendBeacon(url, blob);
  } else {
    const options = {
      body,
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      keepalive: true,
    };

    fetch(url, options).catch(() => {
      // it's okey for this request to have failed, the end-user experience won't be affected and
      // we don't want this to be logged as an error as usual if `Promise`s reject
    });
  }
};
