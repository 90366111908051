import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  AccessGroupMatrixModel,
  AccessGroupModel,
} from "@web/models/AccessControlModel";
import { LazyList } from "../LazyList";
import {
  GlobalPermissionsCell,
  ServicePermissionsCell,
} from "./PermissionCell";
import { EditAccessGroupBox } from "./EditAccessGroupBox";
import { Button } from "@web/elements/Button";
import { defineMessages, FormattedMessage } from "react-intl";

interface Props {
  matrix: AccessGroupMatrixModel;
}

interface RowProps {
  accessGroup: AccessGroupModel;
}

const ROW_HEIGHT = 32;
const ROW_SPACING = 8;

export const AccessGroupMatrix = observer(
  ({
    matrix: { accessGroups, loadNextPage, loadingStatus, filterGroups },
  }: Props) => {
    const renderLoadingItem = useCallback(() => null, []);

    const renderRow = useCallback(
      (index: number) => <Row accessGroup={accessGroups[index]} />,
      [accessGroups]
    );

    return (
      <_matrix>
        <_content>
          <LazyList
            itemSize={ROW_HEIGHT + ROW_SPACING}
            loadingStatus={loadingStatus}
            loadNextPage={loadNextPage}
            renderItem={renderRow}
            renderLoadingItem={renderLoadingItem}
            renderTitle={() => <TitleRow />}
            onQueryChange={filterGroups}
          />
        </_content>
      </_matrix>
    );
  }
);

const TitleRow = () => (
  <_row height={36}>
    <_columnTitle></_columnTitle>
    <_columnTitle>
      <FormattedMessage {...texts.servicePermissions} />
    </_columnTitle>
    <_columnTitle>
      <FormattedMessage {...texts.globalPermissions} />
    </_columnTitle>
  </_row>
);

const Row = observer(({ accessGroup }: RowProps) => {
  const [isEditing, setEditing] = useState(false);

  return (
    <>
      {isEditing && (
        <EditAccessGroupBox
          isCreating={false}
          accessGroup={accessGroup}
          onCancel={() => setEditing(false)}
          onDone={() => setEditing(false)}
        />
      )}
      <_row height={ROW_HEIGHT}>
        <_rowTitle
          title={accessGroup.description}
          text={accessGroup.name}
          onClick={() => setEditing(true)}
        />
        <ServicePermissionsCell accessGroup={accessGroup} />
        <GlobalPermissionsCell accessGroup={accessGroup} />
      </_row>
    </>
  );
});

const _matrix = styled.div`
  height: 100%;
  font-size: 0.825rem;
  background: white;
  padding: 2px 10px 10px 10px;
  border-radius: 3px;
  position: relative;
`;

const _content = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
`;

const _row = styled.div<{ height: number }>`
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 300px 150px;
  height: ${(p) => p.height}px;
`;

const _rowTitle = styled(Button).attrs({ variant: "blank" })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 9px 5px;
  justify-content: left;
`;

const _columnTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px;
  font-weight: 600;
  margin-top: 5px;
`;

const texts = defineMessages({
  servicePermissions: {
    id: "accessControl.groupMatrix.servicePermissions",
    defaultMessage: "Service permissions",
  },
  globalPermissions: {
    id: "accessControl.groupMatrix.globalPermissions",
    defaultMessage: "Global permissions",
  },
});
