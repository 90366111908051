import React, { createRef, Fragment, RefObject, useRef } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import isEmpty from "lodash/isEmpty";
import { ClassModel, SelectedTagsMap } from "@web/models";
import { AsteriskIcon } from "@web/elements/Icons";
import { Label, LabelVariant } from "@web/elements/Label";

interface IProps extends Events {
  labels: ClassModel[];
  selectedTags: SelectedTagsMap;
  editable?: boolean;
  truncate?: boolean;
  emptyMessage?: MessageDescriptor;
  variant?: LabelVariant;
  requiredClassifications?: string[];
}

interface Events {
  onLabelClick?: (
    label: ClassModel,
    ref: React.RefObject<HTMLButtonElement>
  ) => void;
}

export const LabelList = observer(
  ({
    labels,
    editable,
    truncate = true,
    emptyMessage,
    selectedTags,
    variant,
    requiredClassifications,
    ...events
  }: IProps) => {
    const labelRefs = useRef<Record<string, RefObject<HTMLButtonElement>>>({});

    const createOrReturnRef = (id: string) => {
      if (!labelRefs.current[id]) {
        labelRefs.current[id] = createRef();
      }
      return labelRefs.current[id];
    };

    if (labels.length === 0) {
      return (
        <div>{emptyMessage && <FormattedMessage {...emptyMessage} />}</div>
      );
    }

    return (
      <>
        {labels.map((label) => {
          const values = selectedTags.getTagTitles(label.uuid);
          const isRequired = requiredClassifications?.includes(label.uuid);

          return (
            <Fragment key={label.id}>
              {isRequired && (
                <_required>
                  <AsteriskIcon />
                </_required>
              )}
              <Label
                ref={createOrReturnRef(label.uuid)}
                disabled={!editable}
                toggled={!isEmpty(values)}
                variant={variant}
                title={label.title}
                values={truncate ? values.slice(0, 1) : values}
                overflowing={truncate && values.splice(1)}
                onClick={() =>
                  events.onLabelClick?.(label, labelRefs.current[label.uuid])
                }
              />
            </Fragment>
          );
        })}
      </>
    );
  }
);

const _required = styled.div`
  position: relative;
  top: -10px;
  right: -7px;
`;
