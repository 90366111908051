import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { canDelete, canUpdate, isReadOnly, TagModel } from "@web/models";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";
import { commonTexts } from "@web/translations";

interface Props {
  tag: TagModel;
  parentHasAttributes?: boolean;
  onDelete: () => void;
  onRename: () => void;
  onAttributesEdit: () => void;
  onAttributesSet: () => void;
}

export const TagMenu: React.FC<Props> = ({
  tag,
  parentHasAttributes,
  onDelete,
  onRename,
  onAttributesEdit,
  onAttributesSet,
}) => {
  const intl = useIntl();

  if (isReadOnly(tag)) {
    return <div>({intl.formatMessage(texts.noAccess)})</div>;
  }

  return (
    <Dropdown variation="light">
      <Menu>
        {canUpdate(tag) && (
          <>
            <MenuItem onClick={onRename}>
              {intl.formatMessage(texts.rename)}
            </MenuItem>
            <MenuItem onClick={onAttributesEdit}>
              {intl.formatMessage(texts.editAttributes)}
            </MenuItem>
            {parentHasAttributes && (
              <MenuItem onClick={onAttributesSet}>
                {intl.formatMessage(texts.setAttributes)}
              </MenuItem>
            )}
          </>
        )}
        {canDelete(tag) && (
          <MenuItem danger onClick={onDelete}>
            {intl.formatMessage(commonTexts.delete)}
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

const texts = defineMessages({
  rename: {
    id: "settings.tag.menu.rename",
    defaultMessage: "Rename tag",
  },
  noAccess: {
    id: "settings.tag.menu.noaccess",
    defaultMessage: "No access",
  },
  editAttributes: {
    id: "settings.tag.menu.editattributes",
    defaultMessage: "Edit properties",
  },
  setAttributes: {
    id: "settings.tag.menu.setattributes",
    defaultMessage: "Set property values",
  },
});
