import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";

import { ChangeLogEvent } from "@web/components/Entry/HistoryPane";
import { vars } from "@web/styles";
import { ChangelogModModel } from "@web/models";
import { ChangedRelatedEntryStatusEntity } from "@web/api/Integration/types";
import { StatusColor } from "@web/elements";

import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";
import { RelatedComment } from "./RelatedComment";

export const EntryStatusChangedEvent: React.FC<{
  event: ChangelogModModel<ChangedRelatedEntryStatusEntity>;
  canUpdateEntry: boolean;
}> = ({ event, canUpdateEntry }) => {
  const colorName = event.changed.EntryStatus.fields.name;

  const text =
    colorName === null
      ? textByEvent.Entry_MOD_entryStatusCleared
      : textByEvent.Entry_MOD_entryStatusSet;

  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.eventTime}
    >
      <FormattedMessage {...text} />{" "}
      {colorName && (
        <StatusColor
          role="status"
          aria-label={ENTRY_STATUS_COLORS[colorName]}
          color={ENTRY_STATUS_COLORS[colorName]}
        />
      )}{" "}
      {event.relatedComment && (
        <RelatedComment
          commentEvent={event.relatedComment}
          canUpdateEntry={canUpdateEntry}
        />
      )}
    </ChangeLogEvent>
  );
};

const textByEvent = defineMessages({
  Entry_MOD_entryStatusSet: {
    id: "entry.historypane.event.entry.modified.entrystatus.set",
    defaultMessage: "changed status to",
  },
  Entry_MOD_entryStatusCleared: {
    id: "entry.historypane.event.entry.modified.entrystatus.cleared",
    defaultMessage: "cleared status",
  },
});

const _commentDelete = styled.div`
  button {
    color: ${vars.secondary};
  }
  button:hover {
    color: ${vars.primary};
  }
`;

const _eventComment = styled.blockquote`
  color: ${vars.greyLight10};
  word-break: break-word;
  margin: 0;
  padding-left: 0.5rem;
`;
