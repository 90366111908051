import { action, computed, observable, reaction } from "mobx";
import { Api } from "@web/api";
import { ITagNodeExpanded } from "@web/api/Integration/types";
import {
  AutoCompleteSuggestionModel,
  DocumentSuggestionModel,
  FilterSuggestionModel,
  FREE_TEXT_FILE_TYPE_SUGGESTIONS,
  FREETEXT_REGEX,
  FreetextSuggestionModel,
  HistorySuggestionModel,
  SearchDescriptionModel,
  SearchSuggestionModel,
  SearchSuggestionType,
} from "@web/models/SearchModel";
import {
  fallbackOrderFrom,
  parseResultOrderOrDefault,
  ResultOrderKey,
} from "@web/models";
import { SearchParams } from "@web/pages/SearchPage/handler";
import { getSearchHistoryFromLocalStore } from "@web/utils/helpers";
import { ILocalStorage } from "./types";
import { parseTagNode } from "./ResponseParser";
import { RootStore } from ".";

export const LAST_USED_ORDER_STORAGE_KEY = "lastUsedOrderKey";
export const HISTORY_STORAGE_KEY = "searchHistoryKey";

export const FILE_TYPES: FreetextSuggestionModel[] =
  FREE_TEXT_FILE_TYPE_SUGGESTIONS.map((value) => ({
    type: "freetext",
    name: "type",
    value,
  }));

export class SearchStore {
  @observable
  query = "";

  @observable
  suggestions: SearchSuggestionModel;

  @observable
  private resultOrder: ResultOrderKey = this.defaultResultOrder;

  constructor(
    private api: Api,
    private rootStore: RootStore,
    private localStorage: ILocalStorage
  ) {
    this.suggestions = this.getInitialSuggestions();
  }

  @computed
  private get suggestionQuery() {
    // Remove any freetext search suggestions from the query string
    return this.query.replace(FREETEXT_REGEX, "").trim();
  }

  get defaultResultOrder(): ResultOrderKey {
    return parseResultOrderOrDefault(
      this.localStorage.getItem(LAST_USED_ORDER_STORAGE_KEY)
    );
  }

  private searchQueryReaction = reaction(
    () => this.suggestionQuery,
    (newQuery) => {
      let searchFor = newQuery;
      const words = newQuery.trim().split(" ");

      if (words.length > 0) {
        searchFor = words[words.length - 1];
      }

      if (newQuery.length > 1) {
        this.loadSuggestions(searchFor);
      } else {
        this.clearSuggestions();
      }
    },
    {
      delay: 400,
    }
  );

  @computed
  get searchParameters(): SearchParams {
    return {
      query: this.query,
      order: this.resultOrder,
    };
  }

  @computed
  get order() {
    return this.resultOrder;
  }

  @action
  setSearchQuery = (query = "") => {
    this.query = query;
    if (query === "") {
      this.clearSuggestions();
    }
  };

  @action
  setResultOrder = (order: ResultOrderKey) => {
    this.resultOrder = order;
    this.localStorage.setItem(
      LAST_USED_ORDER_STORAGE_KEY,
      fallbackOrderFrom(order)
    );
  };

  @action
  addFilterToQuery = (suggestion: SearchSuggestionType) => {
    let newFilter = "";
    const { freetextFilters, words, query } = this.getSearchQueryComponents();

    words.pop();

    const newQuery = query.endsWith("type:") ? words.join(" ") : query;
    const newFilters = freetextFilters.filter((f) => !f.startsWith("type:"));

    switch (suggestion.type) {
      case "freetext":
        newFilter = `${suggestion.name}:${suggestion.value}`;
        this.setSearchQuery(
          `${newFilters.join(" ")} ${newFilter} ${newQuery}`.trim() + " "
        );
        break;
      case "autocomplete":
        newFilter = `${suggestion.name}:"${suggestion.value}"`;
        this.setSearchQuery(
          `${freetextFilters.join(" ")} ${newFilter} ${words.join(" ")}`
        );
    }
  };

  @action
  clearSearchQuery = () => {
    const { words } = this.getSearchQueryComponents();
    words.pop();
    // Remove everything but the freetext suggestions from query
    const filtersInQuery = this.query.match(FREETEXT_REGEX);
    if (filtersInQuery !== null) {
      this.setSearchQuery(`${filtersInQuery.join(" ")} ${words.join(" ")}`);
    } else {
      this.setSearchQuery(words.join(" "));
    }

    this.setResultOrder(fallbackOrderFrom(this.order));
    this.clearSuggestions();
  };

  @action
  getSearchQueryComponents = (): SearchDescriptionModel => {
    const filtersInQuery = this.query.match(FREETEXT_REGEX);
    const query = this.query.replace(FREETEXT_REGEX, "").trim();

    return {
      query,
      // TODO: better to extract words into a re-usable function, writing tests for components using this model
      //       get weird because .query and .words has to be duplicated, would be better to only pass .query
      //       and for those component(s) to compute the words out of the query with a function instead
      words: query
        .replace(/"/g, "")
        .split(" ")
        .filter((word) => word !== ""),
      freetextFilters: filtersInQuery !== null ? filtersInQuery : [],
    };
  };

  @action
  loadSuggestions = async (query: string) => {
    const [history, documents, filters, users] = await Promise.all([
      this.loadHistorySuggestions(query),
      this.loadDocumentSuggestions(query),
      this.loadFilterSuggestions(query),
      this.loadUserSuggestions(query),
    ]);

    this.suggestions = {
      history,
      documents,
      filters,
      users,
    };
  };

  @action
  clearSuggestions = () => {
    this.suggestions = this.getInitialSuggestions();
  };

  loadDocumentSuggestions = async (
    query: string
  ): Promise<DocumentSuggestionModel[]> => {
    const { documentFromJson } = this.rootStore.documentStore;
    const { isGlobalSearch, selectedSection } = this.rootStore.sectionStore;

    try {
      const { data } = await this.api.findSuggestedDocuments(
        query,
        isGlobalSearch || !selectedSection ? undefined : selectedSection.id
      );
      return data.data.map((document) => ({
        type: "document",
        document: documentFromJson(document),
        isSingleDocumentEntry: document.entry.isSingleDocumentEntry,
      }));
    } catch (error) {
      console.error("Failed to get document suggestions", error);
      return [];
    }
  };

  loadUserSuggestions = async (
    query: string
  ): Promise<AutoCompleteSuggestionModel[]> => {
    try {
      const { data } = await this.api.findUsers(query);
      return data.data.createdBy.suggestions.map((value) => ({
        type: "autocomplete",
        name: "user",
        value,
      }));
    } catch (error) {
      console.error("Failed to autocomplete on users", error);
      return [];
    }
  };

  loadFilterSuggestions = async (
    query: string
  ): Promise<FilterSuggestionModel[]> => {
    const { classFilters, selectedTags } = this.rootStore.filterStore;
    const { selectedSection, isGlobalSearch } = this.rootStore.sectionStore;

    if (classFilters.length === 0 || !selectedSection) {
      return [];
    }

    let tagData: ITagNodeExpanded[];

    try {
      if (isGlobalSearch) {
        const { data } = await this.api.findTagsByTitle(query);
        tagData = data.data;
      } else {
        const { data } = await this.api.findTagsInSection(
          selectedSection.uuid,
          query
        );
        tagData = data.data;
      }

      if (query !== this.suggestionQuery) {
        return [];
      }

      return tagData
        .filter((tag) => !selectedTags.has(tag.classification.id, tag.title))
        .map<FilterSuggestionModel>((tag) => ({
          type: "filter",
          classification: tag.classification,
          tag: parseTagNode(tag, this.rootStore.attributeStore),
        }));
    } catch (e) {
      console.error("Error while fetching filter suggestions: " + e);
      return [];
    }
  };

  loadHistorySuggestions(query?: string): HistorySuggestionModel[] {
    const history = getSearchHistoryFromLocalStore();
    let out;

    if (query) {
      out = history.filter(
        (item) => item.toLowerCase().search(query.toLocaleLowerCase()) > -1
      );
    } else {
      out = history;
    }

    return out.map((item) => ({
      type: "history",
      value: item,
    }));
  }

  addQueryToHistory() {
    if (this.query.trim() === "") {
      return;
    }

    const history = getSearchHistoryFromLocalStore();

    history.unshift(this.query);
    history.splice(100); // Arbitrary history limit

    this.localStorage.setItem(
      HISTORY_STORAGE_KEY,
      JSON.stringify([...new Set(history)])
    );
  }

  getInitialSuggestions = (): SearchSuggestionModel => ({
    history: getSearchHistoryFromLocalStore().map((value) => ({
      type: "history",
      value,
    })),
    documents: [],
    filters: [],
    users: [],
  });
}
