import { observable, action, computed } from "mobx";
import { IntlShape } from "react-intl";
import { format } from "date-fns";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { IAttributeValueNode, Permission } from "@web/api/Integration/types";
import { AttributeReminder, AttributeStore } from "@web/stores";
import { INTL_DATE_FORMAT, DATE_ONLY_FORMAT } from "@web/utils/dates";
import { PermissionModel } from ".";

export type AttributeValuePrimitive = string | number | Date;

export class AttributeValueModel implements PermissionModel {
  readonly entryId?: UUID;
  readonly tagId?: UUID;
  readonly uuid: UUID;
  readonly permissions: Set<Permission>;
  readonly definitionId: UUID;

  @observable
  updatedDate: Date;

  @observable
  value: AttributeValuePrimitive;

  constructor(private store: AttributeStore, node: IAttributeValueNode) {
    this.entryId = node.entry?.id;
    this.tagId = node.tag?.id;
    this.uuid = node.id;
    this.permissions = new Set(node.effectivePermissions);
    this.definitionId = node.definition.id;
    this.updatedDate = node.updatedDate;
    this.value = parseAttributeValue(node);
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get rawValue(): string | number {
    if (this.value instanceof Date) {
      return format(this.value, DATE_ONLY_FORMAT);
    }

    return this.value;
  }

  @computed
  get lastUpdatedFormattedDate(): string {
    return formatDistanceToNowStrict(new Date(this.updatedDate), {
      roundingMethod: "ceil",
    });
  }

  formattedValue(intl: IntlShape): string {
    return formatAttributeValue(this.value, intl);
  }

  isRecentlyUpdated(numberOfDays = 14): boolean {
    return (
      !!this.updatedDate &&
      differenceInCalendarDays(Date.now(), new Date(this.updatedDate)) <=
        numberOfDays
    );
  }

  @action.bound
  updateFromJson(json: IAttributeValueNode): void {
    this.value = parseAttributeValue(json);
    this.updatedDate = json.updatedDate;
  }

  update(
    newValue: string | number | Date,
    comment?: string,
    reminder?: AttributeReminder
  ) {
    return this.store.updateAttributeValue(this, newValue, comment, reminder);
  }

  delete() {
    return this.store.deleteAttributeValue(this);
  }
}

export const formatAttributeValue = (
  value: AttributeValuePrimitive,
  intl: IntlShape
): string => {
  if (value === undefined) {
    return "";
  }

  if (value instanceof Date) {
    return intl.formatDate(value, INTL_DATE_FORMAT);
  }

  if (typeof value === "number") {
    return intl.formatNumber(value);
  }

  return value;
};

export const parseAttributeValue = (
  json: Partial<
    Pick<
      IAttributeValueNode,
      "valueString" | "valueDouble" | "valueDate" | "valueDateTime"
    >
  >
): AttributeValuePrimitive => {
  const dateValue = json.valueDate || json.valueDateTime;
  if (dateValue) {
    return new Date(dateValue);
  }

  if (json.valueDouble !== undefined) {
    return Number(json.valueDouble);
  }

  return json.valueString ?? "";
};
