import {
  darken,
  getLuminance,
  lighten,
  setLightness,
  transparentize,
} from "polished";
import { DEFAULT_THEME, palette } from "@web/styles/themes/default";

export type FullTheme = typeof DEFAULT_THEME;

export type SimpleTheme = {
  body: string;
  content: string;
  primary: string;
  secondary: string;
};

const isLight = (baseColor: string, threshold = 0.345) =>
  getLuminance(baseColor) > threshold;

const fg = (baseColor: string, returnIfLight?: string, returnIfDark?: string) =>
  isLight(baseColor)
    ? returnIfLight ?? darken(0.75, baseColor)
    : returnIfDark ?? lighten(0.75, baseColor);

/**
 * Generates theme overrides based on BaseTheme
 */
export const createSimpleTheme = (
  theme: SimpleTheme
): Partial<typeof palette> => {
  const { body, content, primary, secondary } = theme;

  const isContentLight = getLuminance(content) > 0.325;
  const secondaryAlt = setLightness(isContentLight ? 0.85 : 0.3, secondary);
  const light = isContentLight ? "white" : "black";
  const dark = isContentLight ? "black" : "white";

  return {
    body,
    bodyFg: fg(body),

    content,
    contentFg: fg(content),

    primary,
    primaryFg: fg(primary),
    primaryLight10: lighten(0.1, primary),
    primaryDark10: darken(0.1, primary),
    primaryDark20: darken(0.2, primary),

    secondary,
    secondaryFg: fg(secondary),
    secondaryLight10: lighten(0.1, secondary),
    secondaryDark10: darken(0.1, secondary),

    secondaryAlt,
    secondaryAltFg: fg(secondaryAlt),
    secondaryAltLight10: lighten(0.05, secondaryAlt),
    secondaryAltLight20: lighten(0.1, secondaryAlt),
    secondaryAltDark10: darken(0.1, secondaryAlt),
    secondaryAltDark20: darken(0.2, secondaryAlt),

    grey: setLightness(0.5, dark),
    greyLight10: setLightness(isContentLight ? 0.6 : 0.4, dark),
    greyLight20: setLightness(isContentLight ? 0.7 : 0.3, dark),
    greyLight30: setLightness(isContentLight ? 0.8 : 0.2, dark),

    light95: transparentize(0.05, light),
    light55: transparentize(0.45, light),
    light25: transparentize(0.75, light),

    dark85: transparentize(0.15, dark),
    dark75: transparentize(0.25, dark),
    dark55: transparentize(0.45, dark),
    dark25: transparentize(0.75, dark),
    dark05: transparentize(0.95, dark),
  };
};
