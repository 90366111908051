import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { DocumentModel } from "@web/models";
import { VersionItem } from "./VersionItem";
import { texts } from "./texts";
import { StyledButton } from "@web/elements/Button/styles";

interface IVersionListProps {
  document: DocumentModel;
  isLoadingInitialVersions?: boolean;
}

export const VersionList: React.FC<IVersionListProps> = observer((p) => {
  const { versions, selectedVersion } = p.document;
  const intl = useIntl();
  const [isExpanded, setExpanded] = useState(false);
  const currentVersion = p.document.currentVersion;

  const handleScrollEvent = (event: React.UIEvent) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    const scrollThreshold = clientHeight / 2;
    const scrollBottom = Math.max(0, scrollHeight - clientHeight - scrollTop);

    if (scrollBottom < scrollThreshold) {
      p.document.loadVersions();
    }
  };

  const handleClickSelect = (versionIdx: number) => {
    p.document.selectVersion(versionIdx);
  };

  return (
    <_list onScroll={handleScrollEvent} role="feed">
      {isExpanded ? (
        versions.map((version, index) => (
          <VersionItem
            key={version.uuid}
            document={p.document}
            version={version}
            documentTitle={p.document.title}
            isFocus={index === selectedVersion}
            isSelected={index === selectedVersion}
            onClick={() => handleClickSelect(index)}
            onDelete={version.delete}
          />
        ))
      ) : (
        <VersionItem
          document={p.document}
          version={currentVersion}
          documentTitle={p.document.title}
          onDelete={currentVersion.delete}
          isSelected
        />
      )}

      <_expandButton onClick={() => setExpanded(!isExpanded)}>
        {intl.formatMessage(
          isExpanded ? texts.showSelectedVersion : texts.showAllVersions
        )}
      </_expandButton>
    </_list>
  );
});

const _list = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`;

const _expandButton = styled(StyledButton).attrs({
  variant: "secondaryInverted",
})`
  padding: 0;
  margin: 1rem;
`;
