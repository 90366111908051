import styled, { css, keyframes } from "styled-components";
import { vars } from "@web/styles";

const pendingAnim = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 1rem 0; }
`;

const pendingCss = css`
  animation: ${pendingAnim} 1s linear infinite;
  background-size: 1rem 1rem;
  background-image: linear-gradient(
    45deg,
    ${vars.info} 25%,
    ${vars.infoFg} 25%,
    ${vars.infoFg} 50%,
    ${vars.info} 50%,
    ${vars.info} 75%,
    ${vars.infoFg} 75%,
    ${vars.infoFg}
  );
`;

const progressCss = css<{ completedPercent: number }>`
  background-image: linear-gradient(
    to left,
    ${vars.successFg} ${(p) => 100 - Math.round(p.completedPercent)}%,
    ${vars.success} ${(p) => 100 - Math.round(p.completedPercent)}%
  );
`;

export const ProgressBar = styled.div<{
  completedPercent: number;
}>`
  ${(p) => (p.completedPercent === 0 ? pendingCss : progressCss)};
  border-radius: 4px;
  height: 4px;
`;
