import React, { createRef, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TagFacetCountsModel, TagModel } from "@web/models";
import { Checkbox, CheckboxVariant } from "@web/elements";
import { ClockIcon, LockIcon } from "@web/elements/Icons";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import { A11yText } from "@web/elements/A11yText";

interface IEvents {
  onChange: (tag: TagModel, selected: boolean, index?: number) => void;
  onHeight?: (height: number) => void;
}

interface IProps extends IEvents {
  tag: TagModel;
  index: number;
  selected: boolean;
  isIndeterminate?: boolean;
  isRecent?: boolean;
  isNew?: boolean;
  isLocked?: boolean;
  isPipelineCondition?: boolean;
  checkboxVariant?: CheckboxVariant;
  facetCounts?: TagFacetCountsModel;
}

const RecentIcon = <ClockIcon width="13px" height="13px" />;
const LockedIcon = <LockIcon width="18px" height="18px" />;

export const TagRow: React.FC<IProps> = observer((p) => {
  const intl = useIntl();
  const labelRef = createRef<HTMLDivElement>();
  const facetCount = p.facetCounts?.getCountForTag(p.tag.id);

  useLayoutEffect(() => {
    if (labelRef.current && p.onHeight) {
      const { height } = labelRef.current.getBoundingClientRect();
      p.onHeight(height);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    p.onChange(p.tag, !p.selected, p.index);

    if (e.currentTarget.checked) {
      setTimeout(() => e.target.scrollIntoView({ block: "nearest" }), 100);
    }
  };

  let icon: JSX.Element | undefined = undefined;
  let labelStyle: React.CSSProperties | undefined = undefined;
  if (p.isRecent) {
    icon = RecentIcon;
  } else if (p.isNew) {
    icon = (
      <_new>
        <FormattedMessage {...commonTexts.new} />
      </_new>
    );
  } else if (p.isPipelineCondition) {
    icon = (
      <_checklist>
        (<FormattedMessage {...texts.checklist} />)
      </_checklist>
    );
  } else if (p.isLocked) {
    icon = LockedIcon;
  } else if (facetCount !== undefined) {
    icon = (
      <_facetCount
        dimmed={facetCount === 0}
        title={`${facetCount} ${intl.formatMessage(texts.results)}`}
      >
        {facetCount}
        <A11yText>
          {" " /* ..valuable for sensible tests */}
          <FormattedMessage {...texts.results} />
        </A11yText>
      </_facetCount>
    );
  }

  if (p.tag.status?.isArchived) {
    labelStyle = {
      opacity: 0.8,
      textDecoration: "line-through",
    };
  } else if (facetCount === 0) {
    labelStyle = {
      opacity: 0.5,
    };
  }

  return (
    <_wrap variant={p.checkboxVariant}>
      <StyledCheckbox
        label={p.tag.title}
        labelStyle={labelStyle}
        labelMultiline={true}
        labelRef={labelRef}
        checked={p.selected && !p.isIndeterminate}
        indeterminate={p.isIndeterminate}
        variant={p.checkboxVariant}
        disabled={p.isLocked}
        iconAfter={icon}
        onChange={handleChange}
      />
    </_wrap>
  );
});

const highlightVariant: Record<CheckboxVariant, string> = {
  default: vars.secondaryAltLight20,
  alt: vars.primaryDark10,
  form: vars.secondaryAltLight30,
};

const StyledCheckbox = styled(Checkbox)`
  :focus-within {
    outline: none !important;
  }
`;

const _wrap = styled.div<{ variant?: CheckboxVariant }>`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5rem 0.875rem 0.5rem 0.5rem;

  :focus-within {
    background-color: ${(p) => highlightVariant[p.variant ?? "default"]};
    border-radius: 8px;
  }
`;

const _new = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 3px;
`;

const _checklist = styled.span`
  font-size: 0.9em;
  color: ${vars.dark55};
`;

const _facetCount = styled.span<{ dimmed: boolean }>`
  opacity: ${(p) => (p.dimmed ? 0.3 : 0.6)};
  font-weight: 500;
`;

const texts = defineMessages({
  checklist: {
    id: "tagrow.checklist",
    defaultMessage: "checklist",
  },
  results: {
    id: "tagrow.results",
    defaultMessage: "results",
  },
});
