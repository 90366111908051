import React from "react";

export const RemoveIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="var(--svg-fill-bg, #E5E8FD)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12643 11.5656C6.12643 11.2894 6.35029 11.0656 6.62643 11.0656L17.3753 11.0656C17.6514 11.0656 17.8753 11.2894 17.8753 11.5656L17.8753 12.5656C17.8753 12.8417 17.6514 13.0656 17.3753 13.0656L6.62643 13.0656C6.35029 13.0656 6.12643 12.8417 6.12643 12.5656L6.12643 11.5656Z"
      fill="var(--svg-fill-fg, #88134A)"
    />
  </svg>
);

RemoveIcon.displayName = "RemoveIcon";
