import React, { forwardRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useSearchParams } from "react-router-dom";
import { SectionLabel, Label } from "@web/elements";
import {
  DocumentCard,
  SkeletonDocumentCard,
} from "@web/components/DocumentCard";
import { HighlightText, CheckMark } from "@web/elements";
import { EntryModel, UploadGroup, UploadJobStatus } from "@web/models";
import { vars } from "@web/styles";
import { isMedia, media } from "@web/styles/utils";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { commonTexts } from "@web/translations";
import { EntryStatus } from "../EntryStatus";
import { CardCreatedBy } from "./CardCreatedBy";
import { MoreDocsText } from "./MoreDocsText";

interface Props {
  entry: EntryModel;
  colWidth?: number;
  colSpan?: number;
  isFocused?: boolean;
  isSelected: boolean;
  isSelectable?: boolean;
  uploadGroup?: UploadGroup;
  isGlobalSearch?: boolean;
  onClick: () => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MAX_DOCS = isMedia("compact", "noTouch") ? 3 : 8;
const MAX_SPAN = 4;

const getColSpan = (docs: number, maxCols: number) => {
  let span = docs;

  // We want a different span in these cases
  if ([3, 4].includes(docs)) span = 2;
  if ([5, 6].includes(docs)) span = 3;

  // Clamp span to maximum columns or MAX_SPAN
  return Math.min(span, Math.min(maxCols, MAX_SPAN));
};

export const EntryCard = observer(
  forwardRef((p: Props, ref: React.Ref<HTMLAnchorElement>) => {
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const { documents, totalMatchingDocuments } = p.entry;

    const visibleDocs = totalMatchingDocuments
      ? documents.slice(0, Math.min(totalMatchingDocuments, MAX_DOCS - 1))
      : documents.slice(0, MAX_DOCS - 1);

    const isUploading = p.uploadGroup?.isInProgress;

    const showFlap = !p.entry.showAsDocument;

    // Determine card span in parent grid
    const colSpan = isUploading
      ? 1
      : p.colSpan && getColSpan(visibleDocs.length, p.colSpan);

    const handleClick = (e: React.MouseEvent) => {
      if (e.metaKey || e.ctrlKey || e.shiftKey) {
        return;
      }
      e.preventDefault();
      p.onClick();
    };

    return (
      <_wrap
        to={generateEntryUrl(p.entry.id) + `?${searchParams.toString()}`}
        ref={ref}
        role="article"
        $colSpan={colSpan}
        $colWidth={p.colWidth}
        $isFocused={p.isFocused}
        onClick={handleClick}
      >
        <_flap>
          {showFlap && (
            <h1>
              <HighlightText
                fallback={
                  p.entry.title || intl.formatMessage(commonTexts.untitled)
                }
                highlights={p.entry.searchHighlights.title}
              />
            </h1>
          )}
        </_flap>
        <_cardBox>
          <_content>
            {!p.isGlobalSearch && (p.isSelectable || p.isSelected) && (
              <_multiSelect>
                <CheckMark
                  isSelected={p.isSelected}
                  onSelect={p.onSelect}
                  disabled={!p.isSelectable}
                />
              </_multiSelect>
            )}

            {p.uploadGroup?.status === UploadJobStatus.uploading ? (
              <_progressText>
                {Math.round(p.uploadGroup.progress.completedPercent)}%
              </_progressText>
            ) : (
              <>
                {documents.length > 0 ? (
                  <_grid>
                    {visibleDocs.map((doc, i) => (
                      <_gridItem key={doc.id}>
                        <DocumentCard document={doc} />
                      </_gridItem>
                    ))}
                    <MoreDocsText entry={p.entry} />
                  </_grid>
                ) : (
                  <_emptyDocsText>
                    <FormattedMessage
                      id="entry.documents.nodocuments"
                      defaultMessage="No documents"
                    />
                  </_emptyDocsText>
                )}
              </>
            )}
            <_footer>
              <EntryStatus size={20} entry={p.entry} />
              {p.isGlobalSearch && (
                <SectionLabel title={p.entry.sectionTitle} />
              )}
              {p.entry.tags.map((classification, tagTitles) => (
                <Label
                  toggled
                  values={tagTitles.slice(0, 1)}
                  overflowing={tagTitles.splice(1)}
                  key={p.entry.id + classification}
                />
              ))}
              <CardCreatedBy>{p.entry.createdBy}</CardCreatedBy>
            </_footer>
          </_content>
        </_cardBox>
      </_wrap>
    );
  })
);

export const SkeletonEntryCard = (p: { width: number }) => (
  <_wrap
    as="div"
    $colSpan={1}
    $colWidth={p.width}
    style={{ pointerEvents: "none" }}
  >
    <_flap />
    <_cardBox>
      <_grid>
        <_gridItem>
          <SkeletonDocumentCard />
        </_gridItem>
      </_grid>
      <_footer />
    </_cardBox>
  </_wrap>
);

interface IWrapProps {
  $colSpan?: number;
  $colWidth?: number;
  $isFocused?: boolean;
}

const _wrap = styled(Link).attrs<IWrapProps>((p) => ({
  className: `${p.$isFocused ? "--highlight" : ""}`,
  style: {
    // Define dynamic CSS variable for col count here to avoid
    // generating multiple versions of the component styles.
    "--RecordCard-colSpan": p.$colSpan ?? "",
    "--RecordCard-colWidth": p.$colWidth ? p.$colWidth + "px" : "200px",
  },
}))<IWrapProps>`
  --RecordCard-bg: ${vars.content};
  --RecordCard-border-color: ${vars.transparent};
  --RecordCard-border-width: 2px;
  --RecordCard-color: ${vars.contentFg};
  --RecordCard-radius: 1rem;
  --RecordCard-shadow: ${vars.shadow.z1};

  ${media("desktop")} {
    grid-column: span var(--RecordCard-colSpan);
  }

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--RecordCard-color);
  cursor: pointer;

  :hover {
    --RecordCard-border-color: ${vars.transparent};
    --RecordCard-shadow: ${vars.shadow.z3};
  }

  &.--highlight {
    --RecordCard-border-color: ${vars.primary};
    --RecordCard-shadow: ${vars.shadow.z2};
    outline: none;
  }

  :not(:hover) ${CheckMark} input:not(:checked) + svg {
    opacity: 0.2;
  }

  /*
    Due to the unconventional shape of the card when the title is present,
    we'd want only the children of this wrapper (eg. flap, cardBox) to be clickable.
  */
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

const _flap = styled.div`
  display: flex;
  border-radius: 0.5rem 0.5rem 0 0;
  box-sizing: content-box;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  padding: 0.5rem 0.5rem var(--RecordCard-border-width);
  margin-bottom: calc(var(--RecordCard-border-width) * -1);
  align-self: flex-start;
  max-width: calc(100% - 2rem);
  line-height: 1;
  height: 1em;

  h1 {
    margin: 0;
    font: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*
    Some CSS conjuring incoming:
    We want the flap shadow to be below the card so that it doesn't overlap,
    and the flap background to be above so that the card shadow doesn't overlap the flap.
    The flap contents need to be above the flap background for obvious reasons.
    ✨ Flippetyflap floopetyfloop! ✨
  */

  > * {
    position: relative;
    z-index: 3;
  }

  ::before,
  ::after {
    border-radius: inherit;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ::before {
    box-shadow: var(--RecordCard-shadow);
    transition: box-shadow 100ms;
    z-index: -1;
  }

  ::after {
    background: var(--RecordCard-bg);
    border: var(--RecordCard-border-width) solid var(--RecordCard-border-color);
    border-bottom: none;
  }

  /* If flap is empty, hide the trickery */

  :empty::before,
  :empty::after {
    display: none;
  }
`;

const _cardBox = styled.div`
  background: var(--RecordCard-bg);
  border: var(--RecordCard-border-width) solid var(--RecordCard-border-color);
  border-radius: var(--RecordCard-radius);
  box-shadow: var(--RecordCard-shadow);
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: box-shadow 100ms;
  width: 100%;

  /*
    This beauty basically means:
    If the preceding _flap is not empty, do this to me
  */

  ${_flap}:not(:empty) + & {
    border-top-left-radius: 0;
  }
`;

const _content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const _multiSelect = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`;

const _grid = styled.div`
  display: grid;
  flex: 1;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  margin: 1rem 0;

  ${media("desktop")} {
    grid-template-columns: repeat(
      var(--RecordCard-colSpan),
      var(--RecordCard-colWidth)
    );
  }

  ${media("compact", "noTouch")} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  ${media("compact", "touch")} {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
`;

const _gridItem = styled.div`
  padding: 0 1.5rem;

  ${media("compact", "touch")} {
    flex: 0 0 calc(var(--RecordCard-colWidth) - 1.5rem);
    scroll-snap-align: start;
  }
`;

const _footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0.75rem 0.35rem;
  min-height: 34px;
  gap: 0.25rem;
`;

const _emptyDocsText = styled.div`
  color: ${vars.dark55};
  margin: 1rem 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 80px;
`;

const _progressText = styled.div`
  color: ${vars.secondaryAltLight10};
  margin: 1rem 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 80px;
`;
