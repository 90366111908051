import axios from "axios";
import { observable, action, autorun } from "mobx";
import { defineMessages } from "react-intl";
import sortedIndexBy from "lodash/sortedIndexBy";
import debounce from "lodash/debounce";
import { Api } from "@web/api";
import {
  TagModel,
  ClassModel,
  PageLoadingModel,
  INITIAL_LOADING_STATUS,
  DataLoadingModel,
  ColorStatusModel,
  TagSearchModel,
  INITIAL_SEARCH_LOADING_STATUS,
  AttributeModel,
  WritableFields,
} from "@web/models";
import { parseTagResponse, parseTagNode } from "@web/stores/ResponseParser";
import { RootStore } from ".";

export class TagStore {
  @observable
  tags = new Map<UUID, TagModel[]>();
  @observable
  loadingStatus = new Map<UUID, PageLoadingModel>();

  @observable
  searchLoadingStatus = INITIAL_SEARCH_LOADING_STATUS;

  search = observable<TagSearchModel>(
    {
      classificationId: undefined,
      query: "",
      tagStatuses: new Set<number>(),
      matches: undefined,
      exclude: [],

      get isActive() {
        return this.tagStatuses.size > 0 || this.query !== "";
      },

      setActiveClassification(classificationId) {
        this.classificationId = classificationId;
      },
      setQuery(newQuery) {
        this.query = newQuery;
      },
      clear() {
        this.classificationId = undefined;
        this.query = "";
        this.tagStatuses.clear();
      },
    },
    { setQuery: action.bound, setActiveClassification: action }
  );

  constructor(private api: Api, private rootStore: RootStore) {}

  /**
   * This function reacts when any of the variables it references has changed.
   * In this case this means `search.tagStatuses` OR `search.query`.
   * https://mobx.js.org/refguide/autorun.html
   */
  private searchReaction = autorun(() => {
    const { tagStatuses, query } = this.search;
    this.searchLoadingStatus = INITIAL_SEARCH_LOADING_STATUS;

    // The user has removed all search conditions.
    // Clear the search matches to move back to paged loading.
    if (tagStatuses.size === 0 && query.trim() === "") {
      this.search.matches = undefined;
      return;
    }
    this.tagSearchDebounced(this.search);
  });

  initTags = (classificationId: UUID) => {
    if (!this.tags.has(classificationId)) {
      this.tags.set(classificationId, []);
    }
    return this.tags.get(classificationId)!;
  };

  /**
   * Reset search. Used when a classification select box closes.
   */
  @action.bound
  resetSearch() {
    this.search.tagStatuses.clear();
    this.search.query = "";
    this.search.classificationId = undefined;
  }

  getLoadingStatus = (classificationId: UUID): DataLoadingModel => {
    const currentStatus = this.loadingStatus.get(classificationId);
    if (this.search.isActive) {
      return {
        ...this.searchLoadingStatus,
        query: this.search.query,
        // Always keep the search field around when doing search in a classification with > 12 tags.
        showSearch: currentStatus ? currentStatus.itemCount > 12 : false,
        // Until we have a first match returned, keep showing the existing rows
        itemCount:
          this.search.matches?.length ?? (currentStatus?.itemCount || 0),
      };
    }

    if (!currentStatus) {
      return INITIAL_LOADING_STATUS;
    }

    return currentStatus;
  };

  @action.bound
  private updateLoadingStatus(
    classificationId: UUID,
    fields: Partial<PageLoadingModel>
  ) {
    const status = this.loadingStatus.get(classificationId);
    if (status) {
      this.loadingStatus.set(classificationId, {
        ...status,
        ...fields,
      });
    } else {
      this.loadingStatus.set(classificationId, {
        ...INITIAL_LOADING_STATUS,
        ...fields,
      });
    }
  }

  /**
   * Insert a tag in the data, sorted by title
   */
  @action.bound
  private insertTag(classificationId: UUID, tag: TagModel) {
    if (!this.tags.has(classificationId)) {
      this.initTags(classificationId);
    }

    this.removeTags(classificationId, [tag.id]);
    const currentTags = this.tags.get(classificationId)!;

    const insertAt = sortedIndexBy(currentTags, tag, (t) =>
      t.title.toLowerCase()
    );

    currentTags.splice(insertAt, 0, tag);

    this.updateLoadingStatus(classificationId, {
      itemCount: currentTags.length,
    });
  }

  @action.bound
  private addTags(classificationId: UUID, tags: TagModel[]) {
    if (!this.tags.has(classificationId)) {
      this.initTags(classificationId);
    }

    const newLength = this.tags.get(classificationId)!.push(...tags);

    this.updateLoadingStatus(classificationId, {
      itemCount: newLength,
    });
  }

  removeTags = (classificationId: UUID, tags: number[]) => {
    if (!this.tags.has(classificationId)) {
      return;
    }

    const currentTags = this.tags.get(classificationId)!;
    for (const id of tags) {
      const index = currentTags.findIndex((t) => t.id === id);
      if (index > -1) {
        currentTags.splice(index, 1);
      }
    }

    this.updateLoadingStatus(classificationId, {
      itemCount: currentTags.length,
    });
  };

  // NOTE: Temp solution until we have one source of truth for tags
  updateTagData = async (classificationId: UUID, tag: TagModel) => {
    if (!this.tags.has(classificationId)) {
      return;
    }

    const currentTags = this.tags.get(classificationId)!;
    const tagData = currentTags.find((t) => t.id === tag.id);
    if (tagData) {
      tagData.title = tag.title;
    }

    if (this.search.isActive) {
      const tagFromSearch = this.search.matches?.find((t) => t.id === tag.id);
      if (tagFromSearch) {
        tagFromSearch.title = tag.title;
      }
    }

    this.updateLoadingStatus(classificationId, {
      itemCount: currentTags.length,
    });
  };

  /**
   * Load some tagz
   *
   * @classificationId the _external (uuid)_ classificationId
   */
  loadTags = async (classificationId: UUID, page = 1) => {
    if (this.search.isActive) {
      this.searchTags(this.search);
      return;
    }

    if (!this.shouldLoadPage(classificationId, page)) {
      return;
    }

    this.updateLoadingStatus(classificationId, { pageLoading: true });
    try {
      const { data } = await this.api.tags(classificationId, page);
      const tags = parseTagResponse(data, this.rootStore.attributeStore);

      this.updateLoadingStatus(classificationId, {
        lastPageLoaded: data.page,
        hasMore: data.hasMore,
        pageLoading: false,
      });

      this.addTags(classificationId, tags);
    } catch (e) {
      this.updateLoadingStatus(classificationId, { pageLoading: false });
      console.error("Could not load tags page " + page, e);
    }
  };

  private shouldLoadPage(classificationId: UUID, pageNumber: number) {
    const currentStatus = this.loadingStatus.get(classificationId);
    if (!currentStatus) {
      return true; // Initial page load
    }
    const { hasMore, lastPageLoaded, pageLoading } = currentStatus;
    if (!hasMore || pageNumber <= lastPageLoaded || pageLoading) {
      return false;
    }
    return true;
  }

  @action.bound
  async findTags(query: string) {
    try {
      const { data } = await this.api.findTagsByTitle(query);
      return data.data;
    } catch (e) {
      console.error("Failed to find tags", e);
      return [];
    }
  }

  private searchTags = async (params: TagSearchModel) => {
    if (!params.classificationId || this.searchLoadingStatus.pageLoading) {
      return;
    }

    const pageToLoad = this.searchLoadingStatus.lastPageLoaded + 1;
    this.searchLoadingStatus.pageLoading = true;

    try {
      const { data } = await this.api.findTagsInClassification(
        params.classificationId,
        params.query,
        params.exclude,
        Array.from(params.tagStatuses),
        pageToLoad
      );

      // Are we still searching?
      if (!this.search.isActive) {
        return;
      }
      this.searchLoadingStatus.lastPageLoaded = data.page;
      this.searchLoadingStatus.hasMore = data.hasMore;

      if (this.search.matches && pageToLoad > 1) {
        this.search.matches.push(
          ...parseTagResponse(data, this.rootStore.attributeStore)
        );
      } else {
        this.search.matches = parseTagResponse(
          data,
          this.rootStore.attributeStore
        );
      }
    } catch (e) {
      console.error("Error doing tag search", e);
    } finally {
      this.searchLoadingStatus.pageLoading = false;
    }
  };

  private tagSearchDebounced = debounce(this.searchTags, 300, {
    maxWait: 400,
  });

  /** Tag CRUD */
  createTag = async (classification: ClassModel, title: string) => {
    this.resetSearch();
    const { messageStore } = this.rootStore;
    classification.createTagState = { saving: true };
    try {
      const { data } = await this.api.createTag(classification.uuid, title);
      const tag = parseTagNode(data.data, this.rootStore.attributeStore);
      this.insertTag(classification.uuid, tag);

      classification.createTagState = { saving: false, tag };

      messageStore.addMessage({
        type: "success",
        title: texts.createTagSuccess,
      });

      return tag;
    } catch (e) {
      let isDuplicateError = false;
      if (axios.isAxiosError(e)) {
        isDuplicateError = e.response?.status === 409;
      }

      const errorText = isDuplicateError
        ? texts.createTagFailedDueToDuplicate
        : texts.createTagFailed;

      classification.createTagState = {
        saving: false,
        error: errorText,
      };

      messageStore.addMessage({
        type: "failure",
        title: errorText,
      });

      // Show error message in UI for two seconds
      setTimeout(() => {
        classification.createTagState = undefined;
      }, 2000);
    }
  };

  /**
   * Delete a tag.
   *
   * @classification the classification to delete the tag in
   * @tag the tag to delete
   */
  deleteTag = async (classification: ClassModel, tag: TagModel) => {
    const { messageStore } = this.rootStore;
    try {
      classification.saving = true;
      await this.api.deleteTag(tag.uuid);
      this.removeTags(classification.uuid, [tag.id]);

      messageStore.addMessage({
        type: "success",
        title: texts.deleteTagSuccess,
      });
    } catch (e) {
      console.error("Failed to delete tag", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.deleteTagFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  /**
   * Update a tag's title and replace updated tags in the data.
   *
   * @classification the classification to update the tags in
   * @tag the tag
   */
  updateTag = async (
    classification: ClassModel,
    tag: TagModel,
    newTitle: string
  ) => {
    const { messageStore } = this.rootStore;
    try {
      classification.saving = true;
      const { data } = await this.api.updateTag(tag.uuid, newTitle);
      const updatedTag = parseTagNode(data.data, this.rootStore.attributeStore);
      this.updateTagData(classification.uuid, updatedTag);

      messageStore.addMessage({
        type: "success",
        title: texts.updateTagSuccess,
      });
    } catch (e) {
      console.error("Failed to update tag", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.updateTagFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  setTagStatus = async (
    tag: TagModel,
    status: ColorStatusModel | undefined
  ) => {
    try {
      tag.saving = true;
      await this.api.setTagStatus(tag.uuid, status?.uuid);
      tag.status = status;
    } catch (e) {
      console.error("Failed to set tag status", e);
    } finally {
      tag.saving = false;
    }
  };

  /** Tag attribute CRUD */
  createAttribute = async (
    tag: TagModel,
    fields: WritableFields<AttributeModel>
  ) => {
    const { messageStore, attributeStore } = this.rootStore;
    try {
      tag.saving = true;
      const { data } = await this.api.createAttribute(fields, {
        tag: { id: tag.uuid },
      });

      const attribute = attributeStore.createAttribute(data.data);
      tag.attributes.push(attribute);

      messageStore.addMessage({
        type: "success",
        title: texts.createTagAttributeSuccess,
      });
    } catch (e) {
      console.error("Could not create tag attribute", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.createTagAttributeFailed,
      });
    } finally {
      tag.saving = false;
    }
  };

  /* Helper functions **/
  getSimilarTags = async (
    classificationId: UUID,
    title: string,
    exclude: string[] = []
  ) => {
    try {
      const { data } = await this.api.findTagsInClassification(
        classificationId,
        title,
        exclude
      );
      return parseTagResponse(data, this.rootStore.attributeStore);
    } catch (e) {
      console.error("Error while getting similar tags: ", e);
      // Fallback: the user gets an error from backend for duplicate values
      return [];
    }
  };
}

const texts = defineMessages({
  createTagFailed: {
    id: "request.tag.create.failed",
    defaultMessage: "Could not add tag.",
  },
  createTagFailedDueToDuplicate: {
    id: "request.tag.create.failed.duplicate",
    defaultMessage:
      "Could not add tag, another tag with this title already exists.",
  },
  createTagSuccess: {
    id: "request.tag.create.success",
    defaultMessage: "New tag added",
  },
  deleteTagFailed: {
    id: "request.tag.delete.failed",
    defaultMessage: "Could not delete tag.",
  },
  deleteTagSuccess: {
    id: "request.tag.delete.success",
    defaultMessage: "Tag was deleted.",
  },
  updateTagFailed: {
    id: "request.tag.update.failed",
    defaultMessage: "Could not update tag.",
  },
  updateTagSuccess: {
    id: "request.tag.update.success",
    defaultMessage: "Updated tag",
  },
  createTagAttributeFailed: {
    id: "request.tag.attribute.create.failed",
    defaultMessage: "Could not add property.",
  },
  createTagAttributeSuccess: {
    id: "request.tag.attribute.create.success",
    defaultMessage: "Property added.",
  },
});
