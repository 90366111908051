import React from "react";

export const FileAddIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.9868 16.3141L43.2607 15.0401C43.7686 14.5321 44.583 14.5321 45.0828 15.0401L53.5326 23.4904C54.0405 23.9984 54.0405 24.8128 53.5326 25.3127L52.4441 26.4013C49.7108 29.1347 45.2602 29.4653 42.3496 26.9254C39.1568 24.1355 39.0358 19.2572 41.9868 16.3141Z"
      fill="currentColor"
    />
    <path
      d="M39.8983 28.2236C36.52 24.7967 36.2782 19.5395 38.6405 15.5965C39.0517 14.9031 38.5922 14.0242 37.7778 14.0242L19.1933 14C18.5321 14 18 14.5322 18 15.1934V55.1066C18 55.7678 18.5321 56.3 19.1933 56.3H52.8148C53.4759 56.3 54.0081 55.7678 54.0081 55.1066L54 30.8523C54 30.0782 53.1695 29.5783 52.4923 29.9572C48.5415 32.1343 43.2202 31.5941 39.8983 28.2236Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.665 41.6665C34.9421 41.6665 35.1668 41.8896 35.1668 42.1647V46.3951C35.1668 46.6702 35.3915 46.8933 35.6686 46.8933H36.6723C36.9494 46.8933 37.1741 46.6702 37.1741 46.3951V42.1647C37.1741 41.8896 37.3987 41.6665 37.6759 41.6665L41.9402 41.6665C42.2173 41.6665 42.442 41.4435 42.442 41.1684L42.442 40.1705C42.442 39.8954 42.2173 39.6724 41.9402 39.6724L37.6759 39.6724C37.3987 39.6724 37.1741 39.4493 37.1741 39.1742L37.1741 34.9314C37.1741 34.6562 36.9494 34.4332 36.6723 34.4332L35.6686 34.4332C35.3915 34.4332 35.1668 34.6562 35.1668 34.9314L35.1668 39.1742C35.1668 39.4493 34.9421 39.6724 34.665 39.6724L30.4019 39.6724C30.1247 39.6724 29.9001 39.8954 29.9001 40.1705L29.9001 41.1684C29.9001 41.4435 30.1247 41.6665 30.4019 41.6665L34.665 41.6665Z"
      fill="white"
    />
  </svg>
);
