import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { vars } from "@web/styles";
import {
  AccessGroupListModel,
  AccessGroupModel,
  ALL_GLOBAL_PERMISSION,
  ALL_SERVICE_PERMISSIONS,
  findMatchingPermissionSet,
  NamedPermissionSet,
  NAMED_GLOBAL_PERMISSION_SETS,
  NAMED_SERVICE_PERMISSION_SETS,
  ObjectPermissionsModel,
  ObjectWithAccessControlModel,
  permissionSetNames,
} from "@web/models/AccessControlModel";
import { SelectPermissionsBox } from "./SelectPermissionsBox";
import { SelectAccessGroupBox } from "./SelectAccessGroupBox";
import {
  IObjectWithAccessControl,
  Permission,
  ServicePermission,
} from "@web/api/Integration/types";
import { Tooltip } from "@web/elements/Tooltip";
import { FormattedMessage, defineMessages } from "react-intl";
import { ObjectPermissionListModel } from "@web/models/AccessControlModel/ObjectPermissionListModel";
import { AllObjectPermissionsBox } from "./AllObjectPermissionsBox";
import { StyledButton } from "@web/elements/Button/styles";

interface GroupCellProps {
  accessGroup: AccessGroupModel;
  onClose?: () => void;
}

interface ObjectCellProps {
  showGroupName: boolean;
  objectPermissions: ObjectPermissionsModel;
  availablePermissions: Permission[];
  namedPermissionSets: NamedPermissionSet<Permission>[];
  canModifyPermissions: boolean;
  onClose?: () => void;
}

interface AddCellProps {
  accessGroups: AccessGroupListModel;
  initialSelectedAccessGroup?: AccessGroupModel;
  object: ObjectWithAccessControlModel<any>;
  availablePermissions: Permission[];
  namedPermissionSets: NamedPermissionSet<Permission>[];
  onClose?: () => void;
}

interface AllObjectPermissionsCellProps<
  ObjectType extends IObjectWithAccessControl
> {
  children: React.ReactNode;
  permissionList: ObjectPermissionListModel<ObjectType>;
  availablePermissions: Permission[];
  namedPermissionSets: NamedPermissionSet<Permission>[];
}

interface CellTooltipProps {
  permissions: Set<Permission> | Set<ServicePermission>;
  children: React.ReactElement<any>;
}

export const ServicePermissionsCell = observer(
  ({ accessGroup, onClose }: GroupCellProps) => {
    const [isSelecting, setSelecting] = useState(false);
    const matchingPermissionSet = findMatchingPermissionSet(
      accessGroup.servicePermissions,
      NAMED_SERVICE_PERMISSION_SETS
    );
    const title = matchingPermissionSet?.name ?? permissionSetNames.custom;

    const handleSelectDone = useCallback(
      (permissions: Set<ServicePermission>) => {
        accessGroup.updateServicePermissions(permissions);
        setSelecting(false);
        onClose?.();
      },
      [accessGroup.uuid]
    );

    const handleSelectCancel = useCallback(() => {
      setSelecting(false);
      onClose?.();
    }, [accessGroup.uuid]);

    return (
      <>
        <PermissionsTooltip permissions={accessGroup.servicePermissions}>
          <_cellButton
            border={
              accessGroup.isServicePermissionsRecentlyChanged
                ? "highlight"
                : "normal"
            }
            onClick={() => setSelecting(true)}
          >
            <_cellPermissionTitle>
              <FormattedMessage {...title} />
            </_cellPermissionTitle>
          </_cellButton>
        </PermissionsTooltip>
        {isSelecting && (
          <SelectPermissionsBox
            accessGroupName={accessGroup.name}
            availablePermissions={ALL_SERVICE_PERMISSIONS}
            initialPermissions={accessGroup.servicePermissions}
            namedPermissionSets={NAMED_SERVICE_PERMISSION_SETS}
            onDone={handleSelectDone}
            onCancel={handleSelectCancel}
          />
        )}
      </>
    );
  }
);

export const GlobalPermissionsCell = observer(
  ({ accessGroup, onClose }: GroupCellProps) => {
    const [isSelecting, setSelecting] = useState(false);
    const matchingPermissionSet = findMatchingPermissionSet(
      accessGroup.globalPermissions,
      NAMED_GLOBAL_PERMISSION_SETS
    );
    const title = matchingPermissionSet?.name ?? permissionSetNames.custom;

    const handleSelectDone = useCallback(
      (permissions: Set<Permission>) => {
        accessGroup.updateGlobalPermissions(permissions);
        setSelecting(false);
        onClose?.();
      },
      [accessGroup.uuid]
    );

    const handleSelectCancel = useCallback(() => {
      setSelecting(false);
      onClose?.();
    }, [accessGroup.uuid]);

    return (
      <>
        <PermissionsTooltip permissions={accessGroup.globalPermissions}>
          <_cellButton
            border={
              accessGroup.isGlobalPermissionsRecentlyChanged
                ? "highlight"
                : "normal"
            }
            onClick={() => setSelecting(true)}
          >
            <_cellPermissionTitle>
              <FormattedMessage {...title} />
            </_cellPermissionTitle>
          </_cellButton>
        </PermissionsTooltip>
        {isSelecting && (
          <SelectPermissionsBox
            accessGroupName={accessGroup.name}
            availablePermissions={ALL_GLOBAL_PERMISSION}
            initialPermissions={accessGroup.globalPermissions}
            namedPermissionSets={NAMED_GLOBAL_PERMISSION_SETS}
            onDone={handleSelectDone}
            onCancel={handleSelectCancel}
          />
        )}
      </>
    );
  }
);

export const ObjectPermissionsCell = observer(
  ({
    showGroupName,
    canModifyPermissions,
    objectPermissions,
    availablePermissions,
    namedPermissionSets,
    onClose,
  }: ObjectCellProps) => {
    const [isSelecting, setSelecting] = useState(false);
    const matchingPermissionSet = findMatchingPermissionSet(
      objectPermissions.permissions,
      namedPermissionSets
    );
    const title = matchingPermissionSet?.name ?? permissionSetNames.custom;

    const handleSelectDone = useCallback(
      (permissions: Set<Permission>) => {
        objectPermissions.updatePermissions(permissions);
        setSelecting(false);
      },
      [objectPermissions.uuid]
    );

    const handleSelectCancel = useCallback(() => {
      setSelecting(false);
      onClose?.();
    }, [objectPermissions.uuid]);

    return (
      <>
        <PermissionsTooltip permissions={objectPermissions.permissions}>
          <_cellButton
            border={
              objectPermissions.isRecentlyChanged ? "highlight" : "normal"
            }
            disabled={!canModifyPermissions}
            onClick={() => setSelecting(true)}
          >
            {showGroupName && (
              <_cellGroupTitle>{objectPermissions.group.name}</_cellGroupTitle>
            )}
            <_cellPermissionTitle>
              <FormattedMessage {...title} />
            </_cellPermissionTitle>
          </_cellButton>
        </PermissionsTooltip>
        {isSelecting && (
          <SelectPermissionsBox
            accessGroupName={objectPermissions.group.name}
            availablePermissions={availablePermissions}
            initialPermissions={objectPermissions.permissions}
            namedPermissionSets={namedPermissionSets}
            onDone={handleSelectDone}
            onCancel={handleSelectCancel}
          />
        )}
      </>
    );
  }
);

export const AllObjectPermissionsCell = observer(
  <ObjectType extends IObjectWithAccessControl>({
    children,
    permissionList: permissions,
    availablePermissions,
    namedPermissionSets,
  }: AllObjectPermissionsCellProps<ObjectType>) => {
    const [isOpen, setOpen] = useState(false);

    return (
      <>
        <_cellButton border="none" dimmed={true} onClick={() => setOpen(true)}>
          {children}
        </_cellButton>
        {isOpen && (
          <AllObjectPermissionsBox
            permissionList={permissions}
            availablePermissions={availablePermissions}
            namedPermissionSets={namedPermissionSets}
            onClose={() => setOpen(false)}
          />
        )}
      </>
    );
  }
);

export const AddPermissionsCell = observer(
  ({
    accessGroups,
    initialSelectedAccessGroup,
    object,
    availablePermissions,
    namedPermissionSets,
  }: AddCellProps) => {
    const [isSelecting, setSelecting] = useState(false);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState<
      AccessGroupModel | undefined
    >(initialSelectedAccessGroup);

    const handleAddClicked = useCallback(() => {
      setSelecting(true);
    }, []);

    const handleAccessGroupSelected = useCallback(
      (selected: AccessGroupModel) => {
        setSelectedAccessGroup(selected);
      },
      [object.uuid]
    );

    const handlePermissionsSelected = useCallback(
      (permissions: Set<Permission>) => {
        if (selectedAccessGroup) {
          object.addPermissions(selectedAccessGroup, permissions);
        }
        setSelecting(false);
        setSelectedAccessGroup(initialSelectedAccessGroup);
      },
      [object.uuid, selectedAccessGroup?.uuid, initialSelectedAccessGroup]
    );

    const handleCancel = useCallback(() => {
      setSelectedAccessGroup(initialSelectedAccessGroup);
      setSelecting(false);
    }, [initialSelectedAccessGroup]);

    const showAccessGroupSelect = isSelecting && !selectedAccessGroup;
    const showPermissionSelect = isSelecting && selectedAccessGroup;

    const grantedGroupIds = new Set(
      object.objectPermissions.map((x) => x.group.uuid)
    );

    return (
      <>
        <_cellButton onClick={handleAddClicked} dimmed={true}>
          <_cellPermissionTitle>
            <FormattedMessage {...texts.addPermissions} />
          </_cellPermissionTitle>
        </_cellButton>
        {showAccessGroupSelect && (
          <SelectAccessGroupBox
            accessGroups={accessGroups}
            disableGroups={grantedGroupIds}
            onCancel={handleCancel}
            onDone={handleAccessGroupSelected}
          />
        )}
        {showPermissionSelect && (
          <SelectPermissionsBox
            accessGroupName={selectedAccessGroup?.name}
            availablePermissions={availablePermissions}
            initialPermissions={new Set<Permission>()}
            namedPermissionSets={namedPermissionSets}
            onDone={handlePermissionsSelected}
            onCancel={handleCancel}
          />
        )}
      </>
    );
  }
);

const PermissionsTooltip: React.FC<CellTooltipProps> = (p) => (
  <Tooltip
    disabled={p.permissions.size === 0}
    textNode={<>{Array.from(p.permissions).join(", ")}</>}
  >
    {p.children}
  </Tooltip>
);

const cellButtonBorders = {
  none: "none",
  normal: `1px solid ${vars.dark05}`,
  highlight: `1px solid ${vars.warning}`,
};

const _cellButton = styled(StyledButton).attrs({ variant: "blank" })<{
  dimmed?: boolean;
  border?: keyof typeof cellButtonBorders;
}>`
  color: ${vars.primaryLight10};
  background: ${vars.transparent};
  border: ${(p) => cellButtonBorders[p.border ?? "normal"]};
  border-radius: 4px;
  min-width: 100px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;

  opacity: ${(p) => (p.dimmed ? 0.7 : 1.0)};
  :hover {
    opacity: 1;
  }
  :disabled {
    opacity: 0.5;
  }
`;

const _cellGroupTitle = styled.span`
  display: block;
  color: ${vars.contentFg};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const _cellPermissionTitle = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const texts = defineMessages({
  addPermissions: {
    id: "accessControl.permissionCell.addPermissions",
    defaultMessage: "+ Add permissions",
  },
});
