import React, { Component, ErrorInfo } from "react";
import { commonTexts } from "@web/translations";
import { injectIntl } from "react-intl";
import { UnhandledErrorPage } from "@web/pages/UnhandledErrorPage";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}
interface ErrorBoundaryProps {
  error?: Error;
  intl: any;
}
class ErrorBoundaryComponent extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log(error, errorInfo);
  }
  /*
   */
  render() {
    const { intl } = this.props;
    if (this.state.hasError) {
      return (
        <UnhandledErrorPage
          retryLinkText={intl.formatMessage(commonTexts.retry)}
          unhandledErrorMessage={intl.formatMessage(
            commonTexts.unhandledErrorMessage
          )}
          unhandledErrorTitle={intl.formatMessage(
            commonTexts.unhandledErrorTitle
          )}
        />
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = injectIntl(ErrorBoundaryComponent);
export { ErrorBoundary };
