import React, { FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  IClassificationNodeExtended,
  IRuleNode,
} from "@web/api/Integration/types";
import { PipelineModel } from "@web/models";
import { SelectClassificationBox } from "@web/components/Classification/SelectClassificationBox";
import { ActionMenu, Input } from "@web/elements";
import { Button } from "@web/elements/Button";
import { flowEntityTexts } from "../texts";

interface Props {
  pipeline: PipelineModel;
  rule?: IRuleNode;
  findClassifications: (
    query: string
  ) => Promise<IClassificationNodeExtended[]>;
  onClose: () => void;
}

export const EditClassificationRule: FC<Props> = observer((p) => {
  const intl = useIntl();

  const [name, setName] = useState(p.rule?.name || "");
  const [description, setDescription] = useState(p.rule?.description || "");
  const [showSelect, setShowSelect] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState<
    IClassificationNodeExtended | undefined
  >(undefined);

  const handleSaveRule = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (p.rule) {
      p.pipeline.updateRule(p.rule, {
        name,
        description,
      });
    } else if (selectedClassification) {
      p.pipeline.addRule({
        name,
        description,
        type: "EntryHasTagFromClassification",
        entryTagClassificationId: selectedClassification.id,
      });
    }
    p.onClose();
  };

  const handleSelectClassification = (
    classification: IClassificationNodeExtended
  ) => {
    setSelectedClassification(classification);
    setShowSelect(false);
  };

  const canSave = () => {
    if (p.rule) {
      return name !== p.rule.name || description !== (p.rule.description || "");
    }
    return name.length && selectedClassification;
  };

  return (
    <>
      <_wrap>
        <_edit onSubmit={handleSaveRule}>
          <_input>
            <Input
              placeholder={intl.formatMessage(flowEntityTexts.rules.name)}
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </_input>
          <_input>
            <Input
              placeholder={intl.formatMessage(
                flowEntityTexts.rules.description
              )}
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </_input>

          <_footer>
            <Button
              text={intl.formatMessage(texts.selectClassification)}
              onClick={() => setShowSelect(true)}
            />

            {selectedClassification && (
              <b>
                <FormattedMessage
                  {...texts.selected}
                  values={{ title: selectedClassification.title }}
                />
              </b>
            )}

            <ActionMenu
              onApply={handleSaveRule}
              onCancel={p.onClose}
              applyIsDisabled={!canSave()}
              applyText={p.rule ? texts.save : texts.add}
              direction="horizontal"
            />
          </_footer>
        </_edit>
      </_wrap>

      {showSelect && (
        <SelectClassificationBox
          findClassifications={p.findClassifications}
          onSelect={handleSelectClassification}
          onCancel={() => setShowSelect(false)}
        />
      )}
    </>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f1f2f3;
  margin-bottom: 5px;
  border-radius: 3px;
  background: #e5e8fd;
  svg {
    visibility: hidden;
    margin-left: 5px;
  }
  > {
    margin-bottom: 10px;
  }
`;

const _edit = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  margin: 10px 5px;
  h3:not(:first-child) {
    margin-top: 15px;
  }
`;

const _footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const _input = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-grow: 1;
  input {
    font-size: 14px;
  }
  label {
    width: 100%;
  }
`;

const texts = defineMessages({
  add: {
    id: "flow.settings.rule.add",
    defaultMessage: "Add rule",
  },
  save: {
    id: "flow.settings.rule.save",
    defaultMessage: "Save rule",
  },
  selectClassification: {
    id: "flow.settings.rule.select.classification",
    defaultMessage: "Select classification",
  },
  selected: {
    id: "flow.settings.rule.selected",
    defaultMessage: "Selected: <em>{title}</em>",
  },
});
