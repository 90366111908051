import React from "react";

export const FileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || "124"}
    height={props.height || "160"}
    viewBox="0 0 124 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 160H119C121.761 160 124 157.784 124 155.022V32L89 0H5C2.23858 0 0 2.23858 0 5V155C0 157.761 2.23858 160 5 160Z"
      fill="#ECF2F5"
    />
    <path d="M89 27V0L124 32H94C91.2386 32 89 29.7614 89 27Z" fill="#D4E3E8" />
  </svg>
);
