import React, { useState, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";
import { useIntl, MessageDescriptor } from "react-intl";
import { ColorStatusModel, TagStatusColor } from "@web/models";
import { PopoverBox, StatusColor, ErrorMessage } from "@web/elements";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import { TagStatusSelect } from "./TagStatusSelect";

interface Props {
  status: ColorStatusModel | undefined;
  options: ColorStatusModel[];
  error?: MessageDescriptor;
  disableSave?: boolean;
  onSave: (status: ColorStatusModel | undefined) => void;
}

export const SetTagStatus = observer((p: Props) => {
  const intl = useIntl();
  const color = p.status?.color || "noColor";

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isChangingStatus, changeStatus] = useState(false);

  const handleStatusChange = debounce(p.onSave, 400);

  return (
    <>
      <_button
        ref={buttonRef}
        empty={!p.status}
        onClick={p.disableSave ? undefined : () => changeStatus(true)}
      >
        <span>{intl.formatMessage(commonTexts.status)}</span>
        {color !== "noColor" && <StatusColor color={TagStatusColor[color]} />}
        {p.status && <span>{p.status?.name}</span>}
      </_button>

      {p.error && <ErrorMessage message={intl.formatMessage(p.error)} />}

      {isChangingStatus && (
        <PopoverBox
          triggerRef={buttonRef}
          onClickOutside={() => changeStatus(false)}
          alignX="start-right-of-trigger"
          margin={-15}
        >
          <TagStatusSelect
            options={p.options}
            initialStatus={p.status}
            onChange={handleStatusChange}
          />
        </PopoverBox>
      )}
    </>
  );
});

const _button = styled.button.attrs({ type: "button" })<{ empty?: boolean }>`
  background: ${(p) => (p.empty ? vars.light95 : vars.secondaryAltLight20)};
  color: ${vars.secondary};
  border: 1px solid ${(p) => (p.empty ? vars.secondaryAlt : vars.transparent)};
  padding: 8px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;
  > span:first-of-type {
    margin-right: 4px;
    :not(:only-child) {
      ::after {
        content: ":";
      }
    }
  }
  > span:last-of-type {
    margin-left: 4px;
  }
`;
