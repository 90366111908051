import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";
import { ResultViewProps } from "../types";
import { ResultsGridItem } from "./ResultsGridItem";
import { media } from "@web/styles/utils";
import { SkeletonEntryCard } from "@web/components/EntryCard";
import { RecentEntries } from "@web/components/Results/RecentEntries";
import { UploadRequestType } from "@web/models";
import { HeaderItem } from "./HeaderItem";
import { vars } from "@web/styles";

const COL_WIDTH = 325;
const COL_GAP = 16;

export const ResultsGrid = observer((p: ResultViewProps) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const [cols, setCols] = useState(1);

  const calculateCols = debounce(() => {
    if (wrapRef.current) {
      const newCols = Math.floor(
        wrapRef.current.getBoundingClientRect().width / (COL_WIDTH + COL_GAP)
      );
      setCols(Math.max(newCols, 2));
    }
  });

  useEffect(() => {
    calculateCols();
    window.addEventListener("resize", calculateCols);
    return () => {
      window.removeEventListener("resize", calculateCols);
    };
  }, []);

  useLayoutEffect(() => {
    if (p.resultState.count > 1 && !p.resultState.isLoading) {
      p.onLoadComplete();
    }
  }, [p.resultState.isLoading]);

  const { isLoading } = p.resultState;

  return (
    <_wrap ref={wrapRef} cols={cols}>
      <RecentEntries
        entries={p.recentEntries}
        onClose={p.onClearRecentEntries}
        viewType={"grid"}
        colWidth={COL_WIDTH}
        colSpan={cols}
        {...p}
      />

      {Object.entries(p.groupedEntries).map(([header, entries]) => (
        <React.Fragment key={header}>
          <HeaderItem
            key={`headNode_${p.resultOrder}_${header}`}
            title={header}
            order={p.resultState.order.selected}
          />

          <_grid onClick={p.onBackgroundClick}>
            {entries.map((entry) => (
              <ResultsGridItem
                key={`entryNode_${p.resultOrder}_${entry.id}`}
                entry={entry}
                uploadGroup={p.uploads?.find(
                  (u) =>
                    u.entryId === entry.id &&
                    u.requestType === UploadRequestType.entry
                )}
                colSpan={cols}
                colWidth={COL_WIDTH}
                scrollToItem={p.scrollToItem}
                isFocused={entry.id === p.focusedEntryId}
                isSelected={p.selectedEntries.items.some(
                  (e) => e.id === entry.id
                )}
                onEntryClick={p.onEntryClick}
                onSelect={p.onSelect}
                isGlobalSearch={p.isGlobalSearch}
                isSelectable={p.selectedEntries.canAddToSelection}
              />
            ))}
          </_grid>
        </React.Fragment>
      ))}

      {isLoading && <LoadingResults amount={20} />}
    </_wrap>
  );
});

const LoadingResults = (p: { amount: number }) => (
  <>
    <HeaderItem title="" order="newest" />
    <_grid>
      {Array.from({ length: p.amount }).map((v, i) => (
        <SkeletonEntryCard width={COL_WIDTH} key={"loading-" + i} />
      ))}
    </_grid>
  </>
);

const _wrap = styled.div<{ cols: number }>`
  --ResultGrid-cols: ${(p) => p.cols};
  --ResultGrid-colWidth: ${COL_WIDTH}px;
`;

const _grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 0;

  ${media("desktop")} {
    grid-template-columns: repeat(
      var(--ResultGrid-cols),
      var(--ResultGrid-colWidth)
    );
  }

  ${media("compact")} {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;
