import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { defineMessages } from "react-intl";
import { commonTexts } from "@web/translations";
import { UploadJobResult } from "@web/models";
import { useStores } from "@web/stores/context";

export const useDuplicateUploadConfirmDialog = () => {
  const { uploadStore } = useStores();
  const [getConfirmation] = useConfirmationDialog();

  return async (results: UploadJobResult[], onConfirm: () => Promise<void>) => {
    const names = results.map((res) => res.fileName);
    const confirm = await getConfirmation({
      title: uploadStore.allowDuplication ? texts.title : texts.canNotContinue,
      message: texts.message,
      confirmText: commonTexts.create,
      disableConfirm: !uploadStore.allowDuplication,
      values: {
        names: names.slice(0, 3).join(", "),
      },
    });

    const onCancel = () => {
      uploadStore.cancelAllJobs();
    };

    if (confirm) {
      return await onConfirm();
    } else {
      return onCancel();
    }
  };
};

const texts = defineMessages({
  title: {
    id: "dialog.confirm.duplicate.upload.heading",
    defaultMessage:
      "Duplicate files found during upload. Do you want to proceed?",
  },
  canNotContinue: {
    id: "dialog.confirm.duplicate.upload.aborted",
    defaultMessage: "Duplicate files found during upload. Can not proceed.",
  },
  message: {
    id: "dialog.confirm.duplicate.upload.files",
    defaultMessage: "The following files have duplicates: {names}.",
  },
});
