import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { UpdateBanner } from "@web/elements/UpdateBanner";
import { DocumentModel } from "@web/models";

interface Props {
  document: DocumentModel;
}

export const DocumentUpdateBanner = (p: Props) => {
  const intl = useIntl();

  return (
    <UpdateBanner onClick={p.document.reload}>
      {intl.formatMessage(texts.updatesHasNewVersion)}
      <strong>{intl.formatMessage(texts.updatesShowNow)}</strong>
    </UpdateBanner>
  );
};

const texts = defineMessages({
  updatesHasNewVersion: {
    id: "document.updates.hasNewVersion",
    defaultMessage: "There is a new version available",
  },
  updatesShowNow: {
    id: "document.updates.showNow",
    defaultMessage: "Show now",
  },
});
