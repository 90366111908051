import { action, observable } from "mobx";
import {
  ClaimsMode,
  IAccessGroupNode,
  Permission,
  ServicePermission,
} from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";

export class AccessGroupModel {
  @observable uuid: string;
  @observable name: string;
  @observable description: string;
  @observable claims: string[];
  @observable claimsMode: ClaimsMode;
  @observable globalPermissions: Set<Permission>;
  @observable servicePermissions: Set<ServicePermission>;
  @observable isGlobalPermissionsRecentlyChanged = false;
  @observable isServicePermissionsRecentlyChanged = false;

  constructor(private store: AccessControlStore, json: IAccessGroupNode) {
    this.uuid = json.id;
    this.name = json.name;
    this.description = json.description;
    this.claims = json.claims;
    this.claimsMode = json.claimsMode;
    this.globalPermissions = new Set(json.globalPermissions);
    this.servicePermissions = new Set(json.servicePermissions);
  }

  @action.bound
  updateFromJson(
    json: IAccessGroupNode,
    options?: {
      markGlobalPermissionsRecentlyChanged?: boolean;
      markServicePermissionsRecentlyChanged?: boolean;
    }
  ) {
    this.uuid = json.id;
    this.name = json.name;
    this.description = json.description;
    this.claims = json.claims;
    this.claimsMode = json.claimsMode;
    this.globalPermissions = new Set(json.globalPermissions);
    this.servicePermissions = new Set(json.servicePermissions);
    if (options?.markGlobalPermissionsRecentlyChanged) {
      this.isGlobalPermissionsRecentlyChanged = true;
    }
    if (options?.markServicePermissionsRecentlyChanged) {
      this.isServicePermissionsRecentlyChanged = true;
    }
  }

  @action.bound
  updateGlobalPermissions(globalPermissions: Set<Permission>) {
    const fields = { globalPermissions: Array.from(globalPermissions) };
    this.store.saveAccessGroup(this, fields);
  }

  @action.bound
  updateServicePermissions(servicePermissions: Set<ServicePermission>) {
    const fields = { servicePermissions: Array.from(servicePermissions) };
    this.store.saveAccessGroup(this, fields);
  }

  @action.bound
  updateFields(
    fields: Pick<
      IAccessGroupNode,
      "name" | "description" | "claims" | "claimsMode"
    >
  ) {
    this.store.saveAccessGroup(this, fields);
  }

  delete = () => {
    this.store.deleteAccessGroup(this);
  };

  toJson(): IAccessGroupNode {
    return {
      id: this.uuid,
      name: this.name,
      description: this.description,
      claims: this.claims,
      claimsMode: this.claimsMode,
      globalPermissions: Array.from(this.globalPermissions),
      servicePermissions: Array.from(this.servicePermissions),
    };
  }
}
