import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  EntryStatusModel,
  PipelineCreateFields,
  PipelineModel,
  PipelineType,
} from "@web/models";
import {
  IClassificationNodeExtended,
  ISectionNode,
  ITagNodeExpanded,
} from "@web/api/Integration/types";
import { Accordion, AccordionSection, DateTime } from "@web/elements";
import { Button } from "@web/elements/Button";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import { ConditionList } from "../Condition/ConditionList";
import { pipelineTexts } from "../texts";
import { OutcomeList } from "../Outcome/OutcomeList";
import { RuleList } from "../Rule/RuleList";
import { EditPipelineDetails } from "./EditPipelineDetails";
import { EditPipelineEvents } from "./EditPipelineEvents";

interface IProps {
  type: PipelineType;
  pipeline?: PipelineModel;
  entryStatuses: EntryStatusModel[];
  isCreatingPipeline?: boolean;
  addPipeline: (fields: PipelineCreateFields) => void;
  findTags: (query: string) => Promise<ITagNodeExpanded[]>;
  findSections: (query: string) => Promise<ISectionNode[]>;
  findClassifications: (
    query: string
  ) => Promise<IClassificationNodeExtended[]>;
  onCancel: () => void;
}

export const EditPipeline: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const title = p.pipeline
    ? intl.formatMessage(pipelineTexts[p.type].edit) + `: ${p.pipeline.name}`
    : intl.formatMessage(pipelineTexts[p.type].create);

  const formatCount = (number: number | undefined) =>
    number ? `(${number})` : "";

  return (
    <_wrap>
      <_header>
        <h1>{title}</h1>
        <Button
          text={
            p.pipeline
              ? intl.formatMessage(commonTexts.done)
              : intl.formatMessage(commonTexts.cancel)
          }
          onClick={p.onCancel}
          variant="secondary"
        />
        {p.isCreatingPipeline && (
          <_saving>
            <FormattedMessage {...commonTexts.saving} />
          </_saving>
        )}
      </_header>
      {p.pipeline && (
        <_info>
          <div>
            <b>
              <FormattedMessage {...commonTexts.status} />
            </b>
            {` ${
              p.pipeline.isEnabled
                ? intl.formatMessage(pipelineTexts.validation.enabled)
                : intl.formatMessage(pipelineTexts.validation.disabled)
            }`}
          </div>
          {p.pipeline.pipelineType === "notification" && (
            <div>
              <b>
                <FormattedMessage {...pipelineTexts.validation.target} />
              </b>
              {` ${p.pipeline.target.name}`}
            </div>
          )}
          <div>
            <b>
              <FormattedMessage {...texts.createdBy} />
            </b>
            {` ${p.pipeline.createdBy}`}
          </div>
          <div>
            <b>
              <FormattedMessage {...texts.createdTime} />
            </b>
            &nbsp;
            <DateTime datetime={p.pipeline.createdDate} />
          </div>
          {!p.pipeline.pipelineType && (
            <div>
              <_warning />
              {` ${intl.formatMessage(texts.untyped)}`}
            </div>
          )}
          {p.pipeline.successOutcomes.length === 0 && (
            <div>
              <_warning />
              {` ${intl.formatMessage(texts.missingOutcome)}`}
            </div>
          )}
          {p.pipeline.pipelineType === "validation" &&
            p.pipeline.rules.length === 0 && (
              <div>
                <_warning />
                {` ${intl.formatMessage(texts.missingRules)}`}
              </div>
            )}
        </_info>
      )}
      <Accordion defaultOpen={0}>
        <AccordionSection
          title={intl.formatMessage(texts.basicDetails)}
          loading={p.pipeline?.saving.details}
        >
          <EditPipelineDetails
            type={p.type}
            pipeline={p.pipeline}
            isCreatingPipeline={p.isCreatingPipeline}
            addPipeline={p.addPipeline}
          />
        </AccordionSection>
        <AccordionSection
          title={intl.formatMessage(
            { ...texts.conditions },
            {
              count: formatCount(p.pipeline?.conditions.length),
            }
          )}
          disabled={!p.pipeline}
          loading={p.pipeline?.saving.conditions}
        >
          {p.pipeline && (
            <ConditionList
              pipeline={p.pipeline}
              findTags={p.findTags}
              findSections={p.findSections}
            />
          )}
        </AccordionSection>
        <AccordionSection
          title={intl.formatMessage(
            { ...texts.events },
            {
              count: formatCount(p.pipeline?.events.length),
            }
          )}
          disabled={!p.pipeline}
          loading={p.pipeline?.saving.events}
        >
          {p.pipeline && <EditPipelineEvents pipeline={p.pipeline} />}
        </AccordionSection>
        {p.type === "validation" && (
          <AccordionSection
            title={intl.formatMessage(
              { ...texts.rules },
              {
                count: formatCount(p.pipeline?.ruleLoadingStatus.total),
              }
            )}
            disabled={!p.pipeline}
            loading={p.pipeline?.saving.rules}
          >
            {p.pipeline && (
              <RuleList
                pipeline={p.pipeline}
                findClassifications={p.findClassifications}
              />
            )}
          </AccordionSection>
        )}
        <AccordionSection
          title={intl.formatMessage(
            { ...texts.outcomes },
            {
              count: formatCount(p.pipeline?.outcomeCount),
            }
          )}
          disabled={!p.pipeline}
          loading={p.pipeline?.saving.outcomes}
        >
          {p.pipeline && (
            <OutcomeList
              pipeline={p.pipeline}
              entryStatuses={p.entryStatuses}
            />
          )}
        </AccordionSection>
      </Accordion>
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  max-width: 900px;
  flex-direction: column;
  padding: 5px 20px;
  border-radius: 3px;
  font-size: 13px;
  margin-bottom: 50px;
  h3 {
    font-weight: bold;
    color: ${vars.primaryDark10};
  }
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 25px;
  }
`;

const _saving = styled.div`
  margin-left: 25px;
  font-size: 1.2em;
  color: ${vars.dark55};
`;

const _info = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px;
  margin-bottom: 5px;
  color: ${vars.dark55};
  > div {
    background: ${vars.content};
    padding: 4px;
    border-radius: 3px;
    white-space: nowrap;
    margin: 5px 20px 5px 0px;

    > :first-child {
      text-transform: uppercase;
      margin-right: 3px;
    }
  }
`;

const _warning = styled.span`
  color: ${vars.danger};
  &:after {
    content: "⚠️";
  }
`;

const texts = defineMessages({
  createdBy: {
    id: "flow.settings.pipeline.created.by",
    defaultMessage: "Created by",
  },
  createdTime: {
    id: "flow.settings.pipeline.created.time",
    defaultMessage: "Created time",
  },
  untyped: {
    id: "flow.settings.pipeline.untyped",
    defaultMessage: "Untyped",
  },
  missingOutcome: {
    id: "flow.settings.pipeline.missing.outcome",
    defaultMessage: "Missing outcome",
  },
  missingRules: {
    id: "flow.settings.pipeline.missing.rules",
    defaultMessage: "Missing rules",
  },
  basicDetails: {
    id: "flow.settings.pipeline.section.title.basic.detail",
    defaultMessage: "Basic details",
  },
  conditions: {
    id: "flow.settings.pipeline.section.title.conditions",
    defaultMessage: "Conditions {count}",
  },
  events: {
    id: "flow.settings.pipeline.section.title.events",
    defaultMessage: "Events {count}",
  },
  rules: {
    id: "flow.settings.pipeline.section.title.rules",
    defaultMessage: "Rules {count}",
  },
  outcomes: {
    id: "flow.settings.pipeline.section.title.outcomes",
    defaultMessage: "Outcomes {count}",
  },
});
