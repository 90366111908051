import { observable, action, computed } from "mobx";
import { MessageModel, IMessageModel } from "@web/models";

export class MessageStore {
  @observable messages: MessageModel[] = [];

  @action.bound
  createMessage(
    message: IMessageModel,
    showAfterDelay?: number,
    condition?: () => boolean
  ) {
    const id = Date.now();
    const newMessage: MessageModel = {
      ...message,
      status: "initial",
      id,
    };
    this.messages.push(newMessage);

    if (showAfterDelay && condition) {
      setTimeout(() => {
        if (condition()) {
          this.showMessage(id);
        }
      }, showAfterDelay);
    }

    return id;
  }

  @action.bound
  addMessage(message: IMessageModel) {
    const id = Date.now();
    const newMessage: MessageModel = {
      ...message,
      status: "show",
      id,
    };
    this.messages.push(newMessage);
    return id;
  }

  @action.bound
  addMissingMandatoryTagMessage() {
    this.addMessage({
      type: "failure",
      title: {
        id: "upload.have.to.select.tag",
        defaultMessage: "Please select a tag from filters marked with (*)",
      },
    });
  }

  @action.bound
  updateMessage(id: number, updates: Partial<IMessageModel>) {
    const messageToUpdate = this.messages.find((m) => m.id === id);
    if (messageToUpdate) {
      Object.assign(messageToUpdate, updates, { status: "show" });
    }
  }

  @action.bound
  private showMessage(id: number) {
    const messageToUpdate = this.messages.find((m) => m.id === id);
    if (messageToUpdate) {
      Object.assign(messageToUpdate, { status: "show" });
    }
  }

  @action.bound
  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

  @action.bound
  hideMessage(message: MessageModel) {
    message.status = "remove";
  }

  @computed
  get hasMandatoryClassMessage() {
    return this.messages.some(
      (next) => next.title.id === "upload.have.to.select.tag"
    );
  }
}
