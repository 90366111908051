import React from "react";

export const CheckmarkCheckedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="var(--fill-color, #3B46C3)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70925 17.835L18.4147 9.12961L16.3001 7L9.70812 13.5919L7.12937 11.0232L5 13.1375L9.70925 17.835Z"
        fill="var(--check-color, white)"
      />
    </svg>
  );
};

export const CheckmarkBlankIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="var(--fill-color, #F9F7FA)" />
    </svg>
  );
};
