import React, { useRef } from "react";
import styled from "styled-components";
import { CheckmarkBlankIcon, CheckmarkCheckedIcon } from "@web/elements/Icons";
import { vars } from "@web/styles";

const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

const _CheckMark = (p: {
  isSelected: boolean;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();

    /**
     * A bug in Firefox stops input events from labels when holding modifier
     * keys (shift, alt, ctrl), so we'll click it programmatically instead
     * https://bugzilla.mozilla.org/show_bug.cgi?id=559506
     * */
    if (isFirefox && e.shiftKey) {
      inputRef.current?.click();
    }
  };

  return (
    <_wrap className={p.className} onClick={handleLabelClick}>
      <input
        ref={inputRef}
        type="checkbox"
        disabled={p.disabled}
        checked={p.isSelected}
        onChange={p.onSelect}
      />
      {p.isSelected ? <CheckmarkCheckedIcon /> : <CheckmarkBlankIcon />}
    </_wrap>
  );
};

export const CheckMark = styled(_CheckMark)``;

const _wrap = styled.label`
  position: relative;
  cursor: pointer;

  input {
    appearance: none;
    position: absolute;
  }

  svg {
    --fill-color: ${vars.secondaryAlt};
    --check-color: ${vars.secondaryAltFg};
  }

  :hover svg {
    --fill-color: ${vars.secondaryAltDark10};
    --check-color: ${vars.secondaryAltFg};
  }

  input:checked + svg {
    --fill-color: ${vars.secondary};
    --check-color: ${vars.secondaryFg};
  }
`;
