import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { PipelineModel } from "@web/models";
import {
  ConditionType,
  ISectionNode,
  ITagNodeExpanded,
} from "@web/api/Integration/types";
import { ActionMenu, Input, Label } from "@web/elements";
import { Select } from "@web/elements/Select";
import { SelectTagBox } from "@web/components/Tag/SelectTagBox";
import { SelectSectionBox } from "@web/components/Section/SelectSectionBox";
import { Button } from "@web/elements/Button";
import { getConditionOptions } from "@web/components/Flow/helpers";

interface IProps {
  pipeline: PipelineModel;
  findTags: (query: string) => Promise<ITagNodeExpanded[]>;
  findSections: (query: string) => Promise<ISectionNode[]>;
  onClose: () => void;
}

export const AddCondition: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const conditionOptions = getConditionOptions(p.pipeline.target.name);
  const defaultOption = Object.keys(conditionOptions)[0] as ConditionType;
  const [option, setOption] = useState<ConditionType>(defaultOption);
  const [input, setInput] = useState("");
  const [selectObject, setSelectObject] = useState<"tag" | "section" | false>(
    false
  );
  const [selectedTag, setSelectedTag] = useState<ITagNodeExpanded | undefined>(
    undefined
  );
  const [selectedSection, setSelectedSection] = useState<
    ISectionNode | undefined
  >(undefined);

  const handleOptionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setOption(e.target.value as ConditionType);
  };

  const handleTagSelected = (tag: ITagNodeExpanded) => {
    setSelectedTag(tag);
    setSelectObject(false);
  };

  const handleSectionSelected = (section: ISectionNode) => {
    setSelectedSection(section);
    setSelectObject(false);
  };

  const handleSaveCondition = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (option === "EntryHasTag") {
      p.pipeline.addCondition(option, {
        name: intl.formatMessage(texts.entryHasTag, {
          selectedTag: selectedTag?.title,
          classification: selectedTag?.classification.title,
        }),
        entryTagId: selectedTag?.id,
      });
    } else if (option === "UserBelongsToGroup") {
      p.pipeline.addCondition(option, {
        name: intl.formatMessage(texts.userBelongsToGroup, {
          groupId: input,
        }),
        groupId: input,
      });
    } else if (option === "EntryHasSection") {
      p.pipeline.addCondition(option, {
        name: intl.formatMessage(texts.entryHasSection, {
          section: selectedSection?.title,
        }),
        entrySectionId: selectedSection?.id,
      });
    }
    p.onClose();
  };

  const canSave = () => {
    if (option === "EntryHasTag") {
      return !!selectedTag;
    }

    if (option === "EntryHasSection") {
      return !!selectedSection;
    }

    if (option === "UserBelongsToGroup") {
      return input.length > 0;
    }
  };

  return (
    <_wrap>
      <_edit>
        <Select value={option} onChange={handleOptionSelect}>
          {Object.entries(conditionOptions).map(([key, text]) => (
            <option key={key} value={key}>
              {intl.formatMessage(text)}
            </option>
          ))}
        </Select>

        {option === "EntryHasTag" && (
          <>
            {selectedTag && (
              <Label
                title={selectedTag.classification.title}
                values={[selectedTag.title]}
              />
            )}
            <Button
              text={intl.formatMessage(texts.selectTag)}
              onClick={() => setSelectObject("tag")}
            />
          </>
        )}

        {option === "EntryHasSection" && (
          <>
            {selectedSection && <b>{selectedSection.title}</b>}
            <Button
              text={intl.formatMessage(texts.selectSection)}
              onClick={() => setSelectObject("section")}
            />
          </>
        )}

        {option === "UserBelongsToGroup" && (
          <Input
            value={input}
            placeholder={intl.formatMessage(texts.accessGroupId)}
            onChange={({ target }) => setInput(target.value)}
          />
        )}

        <ActionMenu
          onApply={handleSaveCondition}
          onCancel={p.onClose}
          applyIsDisabled={!canSave()}
          applyText={texts.add}
          direction="horizontal"
        />
      </_edit>

      {selectObject === "tag" && (
        <SelectTagBox
          findTags={p.findTags}
          onAddCancel={() => setSelectObject(false)}
          onAddDone={handleTagSelected}
        />
      )}

      {selectObject === "section" && (
        <SelectSectionBox
          findSections={p.findSections}
          onAddCancel={() => setSelectObject(false)}
          onAddDone={handleSectionSelected}
        />
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px 0;
  border-radius: 3px;
  background: #e5e8fd;

  svg {
    visibility: hidden;
    margin-left: 5px;
  }

  > {
    margin-bottom: 10px;
  }
`;

const _edit = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 10px 5px;

  button {
    margin-left: 10px;
  }

  select {
    margin-right: 10px;
  }
`;

const texts = defineMessages({
  add: {
    id: "flow.settings.condition.add",
    defaultMessage: "Add condition",
  },
  selectTag: {
    id: "flow.settings.condition.select.tag",
    defaultMessage: "Select tag",
  },
  selectSection: {
    id: "flow.settings.condition.select.section",
    defaultMessage: "Select section",
  },
  accessGroupId: {
    id: "flow.settings.condition.access.group.id",
    defaultMessage: "Access Group ID",
  },
  entryHasTag: {
    id: "flow.settings.condition.list.entry.has.tag",
    defaultMessage:
      "Folders with the tag {selectedTag} from list {classification}",
  },
  userBelongsToGroup: {
    id: "flow.settings.condition.list.user.belong.to.group",
    defaultMessage: "User belongs to group with ID {groupId}",
  },
  entryHasSection: {
    id: "flow.settings.condition.list.entry.has.section",
    defaultMessage: "Folders in the section {section}",
  },
});
