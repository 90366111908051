import React from "react";

export const ClockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9906 2.25C6.60938 2.25 2.25 6.61875 2.25 12C2.25 17.3813 6.60938 21.75 11.9906 21.75C17.3813 21.75 21.75 17.3813 21.75 12C21.75 6.61875 17.3813 2.25 11.9906 2.25ZM12 19.8C7.69219 19.8 4.2 16.3078 4.2 12C4.2 7.69219 7.69219 4.2 12 4.2C16.3078 4.2 19.8 7.69219 19.8 12C19.8 16.3078 16.3078 19.8 12 19.8Z"
      fill="var(--svg-fill, currentColor)"
    />
    <path
      d="M12.4876 7.125H11.0251V12.975L16.1438 16.0453L16.8751 14.8453L12.4876 12.2438V7.125Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
