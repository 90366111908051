import React, { FC } from "react";
import styled from "styled-components";
import { IconButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";

interface IProps {
  onClick: () => void;
  disabled?: boolean;
  children?: never;
}

export const RemoveButton: FC<IProps> = (p) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    p.onClick();
  };

  return (
    <_wrap
      icon="RemoveIcon"
      text={commonTexts.remove}
      onClick={handleClick}
      disabled={p.disabled}
    />
  );
};

const _wrap = styled(IconButton).attrs({ variant: "blank" })`
  font-size: 14px;
  padding: 5px;
  svg:not(:only-child) {
    margin-right: 10px;
  }
`;
