import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { canUpdate, ClassModel, ColorStatusModel, TagModel } from "@web/models";
import { SetTagStatus } from "@web/components/TagStatus/SetTagStatus";
import { Header } from "../Header";
import { vars } from "@web/styles";
import { SettingsBox } from "../Box";
import { TagAttributeList } from "@web/components/Tag/TagAttributeList";

interface Events {
  onBackClick: () => void;
}

interface Props extends Events {
  classification: ClassModel;
  tag: TagModel;
}

export const SetTagAttributes: FC<Props> = observer((p) => {
  const intl = useIntl();

  const { classification, tag } = p;

  const handleSaveStatus = (status: ColorStatusModel | undefined) => {
    classification.setStatusForTag(tag, status);
  };

  return (
    <SettingsBox>
      <Header
        showButton="back"
        title={tag.title}
        subtitle={intl.formatMessage(texts.title)}
        showSpinner={tag.saving}
        onButtonClick={p.onBackClick}
      />
      <_content>
        {classification.tagStatuses.length > 0 && (
          <SetTagStatus
            status={tag.status}
            options={classification.tagStatuses}
            disableSave={!canUpdate(tag)}
            onSave={handleSaveStatus}
          />
        )}

        <TagAttributeList classification={classification} tag={tag} />
      </_content>
    </SettingsBox>
  );
});

const _content = styled.div`
  padding: 0 15px;
`;

const texts = defineMessages({
  title: {
    id: "settings.tag.attributevalues.title",
    defaultMessage: "set properties",
  },
});
