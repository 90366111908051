import { useStores } from "@web/stores/context";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const useEntryNavigationHandler = () => {
  const {
    documentStore,
    recordStore,
    sectionStore,
    multiSelectStore,
    resultStore,
  } = useStores();

  if (recordStore.entry?.isDraft) {
    return;
  }

  const params = useParams();

  /* Change */
  useEffect(() => {
    const entry = recordStore.entry;
    if (Number(params.entryId) !== entry?.id) {
      recordStore.loadEntry(Number(params.entryId));
    } else if (params.entryId) {
      multiSelectStore.entries.clearSelection();
      resultStore.focus(Number(params.entryId));
    }
  }, [params]);

  useEffect(() => {
    /* Mount */
    recordStore.loadEntryStatuses();
    const entry = recordStore.entry;

    if (entry && !entry.section) {
      sectionStore.loadSection(entry.sectionId);
    }

    /* Unmount */
    return () => {
      if (!documentStore.document) {
        recordStore.clearEntry();
      }

      const docWasOpened =
        documentStore.document?.entryId === Number(params.entryId);

      if (multiSelectStore.documents.action !== "Move" && !docWasOpened) {
        multiSelectStore.documents.clearSelection();
      }
    };
  }, []);
};
