import React from "react";

export const CommentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9979 4.00191H18.8031C19.6964 4.00191 20.3857 4.36502 20.7793 5.15225C20.9209 5.43704 20.9885 5.78489 20.9896 6.10426C20.999 9.13623 20.9993 12.1687 20.9997 15.2009V15.201V15.2107C20.9999 16.5552 21.0001 17.8998 21.001 19.2442C21.001 19.5768 20.8688 19.8229 20.5554 19.948C20.2462 20.0721 19.9859 19.9622 19.7589 19.7385C19.4165 19.4028 19.0731 19.0683 18.7297 18.7338L18.729 18.7331C18.2268 18.2438 17.7246 17.7546 17.2257 17.2618C17.0872 17.1256 16.9498 17.0666 16.7509 17.0666C14.7146 17.0693 12.6783 17.0697 10.6419 17.0701C8.86025 17.0704 7.07855 17.0708 5.29686 17.0727C4.69713 17.0737 4.15363 16.9486 3.69239 16.5438C3.29882 16.198 3.09891 15.7626 3.0177 15.2663C3.00066 15.1609 3.00084 15.0521 3.00101 14.9445L3.00104 14.9123L3.00117 12.7238C3.00137 10.5357 3.00156 8.34793 3 6.1602C2.99896 5.27634 3.3665 4.59488 4.19216 4.21754C4.50139 4.07616 4.87413 4.01412 5.21981 4.01107C6.93068 3.99797 8.64275 3.99946 10.3547 4.00096C10.9024 4.00143 11.4502 4.00191 11.9979 4.00191ZM6 8C6 7.44772 6.44772 7 7 7H11C11.5523 7 12 7.44772 12 8C12 8.55229 11.5523 9 11 9H7C6.44772 9 6 8.55229 6 8ZM7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H7Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

CommentIcon.displayName = "CommentIcon";
