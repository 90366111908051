import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { media, useMedia } from "@web/styles/utils";
import { Button } from "@web/elements/Button";

interface Props {
  onClearGlobalSearch: () => void;
}

export const GlobalSearchHeader: FC<Props> = observer((p) => {
  const intl = useIntl();
  const { isCompact } = useMedia();

  return (
    <_wrap>
      <Button
        variant="blank"
        icon="CrossIcon"
        onClick={p.onClearGlobalSearch}
        text={intl.formatMessage(texts.clearTitle)}
        hideText={isCompact}
      />
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex: 1;
  h1,
  button {
    flex-shrink: 0;
    color: white;
  }

  ${media("desktop")} {
    margin-right: 20px;
    > * {
      margin-right: 5px;
    }
  }

  ${media("compact")} {
    justify-content: space-between;
  }
`;

const texts = defineMessages({
  title: {
    id: "searchpage.globalsearch.title",
    defaultMessage: "All sections",
  },
  clearTitle: {
    id: "searchpage.globalsearch.clear",
    defaultMessage: "End search in all sections",
  },
});
