import { IExternalIdNode } from "@web/api/Integration/types";

export interface ExternalIdModel {
  uuid: UUID;
  createdDate: string;
  externalId: string;
}

export function parseExternalIdNode(node: IExternalIdNode): ExternalIdModel {
  return {
    uuid: node.id,
    externalId: node.externalId,
    createdDate: node.createdDate,
  };
}
