import React, { FC } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { SectionModel } from "@web/models";
import { useStores } from "@web/stores/context";
import { SectionSelector } from "@web/components";
import { GlobalSearchHeader } from "@web/components/GlobalSearchHeader";

const SectionContainer: FC = observer(() => {
  const navigate = useNavigate();
  const { sectionStore, filterStore, searchStore, currentSearchURL } =
    useStores();

  const handleSelectSectionClick = (section: SectionModel) => {
    filterStore.resetSelectedFilterValues();
    sectionStore.selectSection(section);
    navigate(currentSearchURL());
  };

  const handleClearGlobalSearch = () => {
    sectionStore.isGlobalSearch = false;
    searchStore.clearSearchQuery();
    filterStore.resetSelectedFilterValues();
    navigate(currentSearchURL());
  };

  if (sectionStore.isGlobalSearch) {
    return <GlobalSearchHeader onClearGlobalSearch={handleClearGlobalSearch} />;
  }

  return (
    <SectionSelector
      sections={sectionStore.activeSectionList}
      selected={sectionStore.selectedSection}
      loadingStatus={sectionStore.loadingStatus}
      onSectionPageNeeded={sectionStore.loadNextPage}
      onSelectSectionClick={handleSelectSectionClick}
      onQueryChange={sectionStore.setAutocompleteQuery}
    />
  );
});

export default SectionContainer;
