import React, { useState } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ellipsis } from "polished";
import { FileType } from "@web/elements";
import { DocumentSignatureBadge } from "@web/components/Document/DocumentSignatureBadge";
import { VersionTag } from "@web/elements/VersionTag";
import { DocumentModel, EntryModel } from "@web/models";
import { EditTitleDialog } from "@web/components/Dialogs/EditTitleDialog";
import { useMedia } from "@web/styles/utils";
import { commonTexts } from "@web/translations";

interface TitleProps {
  entry: EntryModel;
  document?: DocumentModel;
}

export const EntryTitle = observer((p: TitleProps) => {
  const intl = useIntl();
  const { isDesktop } = useMedia();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const title =
    p.document?.title ||
    p.entry.title ||
    intl.formatMessage(commonTexts.untitledEntry);

  const handleSave = (title?: string) => {
    if (p.document) {
      p.document.update({ title });
    } else {
      p.entry.update({ title });
    }
  };

  return (
    <_wrap>
      <_title>
        {p.entry.canUpdate ? (
          <button
            title={intl.formatMessage(commonTexts.edit)}
            onClick={() => setIsEditMode(true)}
          >
            {title}
          </button>
        ) : (
          <h1>{title ?? intl.formatMessage(commonTexts.untitledEntry)}</h1>
        )}
      </_title>

      {isDesktop && p.document && (
        <FileType type={p.document.currentVersion?.fileExt} />
      )}

      {isDesktop && p.document && p.document.versions.length > 1 && (
        <VersionTag
          shorthand
          versionNumber={p.document.currentVersion?.versionNumber}
        />
      )}

      {isDesktop && p.document && (
        <_signature>
          <DocumentSignatureBadge document={p.document} />
        </_signature>
      )}

      {isEditMode && (
        <EditTitleDialog
          allowEmpty={!p.document}
          defaultValue={p.document?.title ?? p.entry.title}
          onSave={handleSave}
          onClose={() => setIsEditMode(false)}
        />
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  > * {
    margin: 0;
  }
`;

const _signature = styled.span`
  font-size: 1rem;
  display: flex;
`;

const _title = styled.div`
  margin: 0;
  overflow: hidden;

  h1,
  button {
    ${ellipsis()};
    background: none;
    border: none;
    color: inherit;
    display: inline-block;
    font: inherit;
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    word-break: break-all;
  }

  button {
    cursor: pointer;
  }
`;
