import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { darken } from "polished";
import * as murmurHash3 from "murmurhash3js";
import { isSystemAccount, SystemAccountUUID } from "@web/models/CommonModel";
import { vars } from "@web/styles";
import { Logo } from "./Logo";

type Size = "small" | "medium";

interface IProfileImageProps {
  name: string;
  /**
   * TODO: ideally the profile/user ID would be required, but since we don't always have
   *       that ID as of writing this, we'll have to wait until we are done with the integration
   *       API migration efforts.
   *
   *       The added benefit being reliably determine if the user account provided here is
   *       actually documaster itself.
   */
  id?: string | SystemAccountUUID;
  size: Size;
}

export const ProfileImage = ({ id, name, size }: IProfileImageProps) => {
  const initials = initialsOfName(name);
  const color = colorForText(initials);

  if (isSystemAccount(id)) {
    return (
      <_wrap
        backgroundColor={vars.secondaryAltLight10}
        color={vars.primary}
        size={size}
      >
        <StyledLogo height="20px" width="20px" />
      </_wrap>
    );
  }

  return (
    <_wrap backgroundColor={color} size={size}>
      {initials}
    </_wrap>
  );
};

const BACKGROUND_COLORS = [
  "#FAE7E7",
  "#DAECFD",
  "#F6E5F9",
  "#F9EBDF",
  "#E9E1F7",
  "#D8F7EA",
  "#F6EFD7",
];

function initialsOfName(text: string): string {
  const words = text.split(" ");
  const characters = words.map((word) => word.charAt(0).toUpperCase());
  const first = characters[0];
  const last = characters.length > 1 ? characters[characters.length - 1] : "";

  return `${first}${last}`.toUpperCase();
}

// picks color based on a string so the users have a consistent color
function colorForText(input: string): string {
  const index = murmurHash3.x86.hash32(input) % BACKGROUND_COLORS.length;
  return BACKGROUND_COLORS[index];
}

function sizeStyling(size: Size): FlattenSimpleInterpolation {
  switch (size) {
    case "medium":
      return css`
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
      `;
    case "small":
      return css`
        font-size: 0.7rem;
        width: 1.6rem;
        height: 1.6rem;
      `;
  }
}

const StyledLogo = styled(Logo)``;

const _wrap = styled.div<{
  size: Size;
  backgroundColor: string;
  color?: string;
}>`
  background: ${(p) => p.backgroundColor};
  color: ${(p) => p.color ?? darken(0.7, p.backgroundColor)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: 500;
  line-height: normal;

  // To not get shrink and loose its round appearance when put into a
  // flex container with too little space to fit all the flex items
  flex-shrink: 0;

  ${(p) => sizeStyling(p.size)}

  ${StyledLogo} {
    transform: translate(1px, 0.5px);
  }
`;
