import { ellipsis } from "polished";
import styled from "styled-components";
import { media } from "@web/styles/utils";

export const _headerWrap = styled.div`
  display: flex;
  height: 40px;
  padding: 0.5rem 0.5rem 0 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const _title = styled.div.attrs({ role: "heading" })`
  flex: 1;
  text-align: center;
  line-height: 24px;
  :nth-child(1) {
    text-align: left;
  }
  font-size: 1rem;
  ${media("desktop")} {
    ${ellipsis()};
  }
`;

export const _headerLink = styled.div.attrs({ role: "button" })`
  cursor: pointer;
  font-size: 0.9em;
  :nth-child(1) {
    padding-right: 5px;
  }
  :nth-child(2),
  :nth-child(3) {
    padding-left: 5px;
  }
`;

export const _action = styled.div`
  display: grid;
  margin: 0.5rem;
`;
