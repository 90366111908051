import React from "react";

export const FailureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#DD4624" />
    <rect x="4.80005" y="11.2002" width="14.4" height="2.4" fill="white" />
  </svg>
);
