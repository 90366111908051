import React from "react";
import styled from "styled-components";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { commonTexts } from "@web/translations";
import { ActionMenu } from "@web/elements";
import { Dialog } from "./Dialog";

interface ConfirmProps {
  message: MessageDescriptor;
  title?: MessageDescriptor;
  confirmText?: MessageDescriptor;
  dismissText?: MessageDescriptor;
  values?: Record<string, string | number>;
  onConfirm: () => void;
  onClose: (result: { wasConfirmed: boolean }) => void;
}

export const ConfirmDialog: React.FC<ConfirmProps> = (p) => {
  const intl = useIntl();
  const confirmText = p.confirmText ?? commonTexts.apply;
  const dismissText = p.dismissText ?? commonTexts.cancel;

  return (
    <Dialog
      title={p.title}
      values={p.values}
      onClickOutside={() => p.onClose({ wasConfirmed: false })}
      onEscape={() => p.onClose({ wasConfirmed: false })}
    >
      <_message>
        <FormattedMessage {...p.message} values={p.values} />
      </_message>
      <ActionMenu
        applyText={intl.formatMessage(confirmText, p.values)}
        cancelText={intl.formatMessage(dismissText, p.values)}
        onApply={() => {
          p.onConfirm();
          p.onClose({ wasConfirmed: true });
        }}
        onCancel={() => p.onClose({ wasConfirmed: false })}
      />
    </Dialog>
  );
};

const _message = styled.div`
  font-size: 14px;
  margin: 0.5rem 0;
`;
