import React from "react";
import styled, { keyframes } from "styled-components";

export const NotificationsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.88606 1.21065C7.32201 0.871578 7.95028 0.950112 8.28935 1.38606C8.62842 1.82201 8.54989 2.45028 8.11394 2.78935L3.61394 6.28935C3.17799 6.62842 2.54972 6.54989 2.21065 6.11394C1.87158 5.67799 1.95011 5.04972 2.38606 4.71065L6.88606 1.21065ZM16.3122 1.38606C16.6513 0.950112 17.2796 0.871578 17.7155 1.21065L22.2155 4.71065C22.6515 5.04972 22.73 5.67799 22.3909 6.11394C22.0518 6.54989 21.4236 6.62842 20.9876 6.28935L16.4876 2.78935C16.0517 2.45028 15.9731 1.82201 16.3122 1.38606ZM12.3008 21.5C17.2713 21.5 21.3008 17.4706 21.3008 12.5C21.3008 7.52944 17.2713 3.5 12.3008 3.5C7.33022 3.5 3.30078 7.52944 3.30078 12.5C3.30078 17.4706 7.33022 21.5 12.3008 21.5ZM13.3008 7.5C13.3008 6.94772 12.8531 6.5 12.3008 6.5C11.7485 6.5 11.3008 6.94772 11.3008 7.5V12.5V13L11.7008 13.3L15.7008 16.3C16.1426 16.6314 16.7694 16.5418 17.1008 16.1C17.4322 15.6582 17.3426 15.0314 16.9008 14.7L13.3008 12V7.5Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

export const AnimatedNotificationsIcon = () => (
  <_animatedWrap>
    <svg
      width="29"
      height="8"
      viewBox="0 0 29 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.76598 6.50759C3.13023 5.65175 3.71725 4.87676 4.51355 4.27691C5.30984 3.67705 6.21598 3.32723 7.1378 3.21426"
        stroke="var(--svg-fill, currentColor)"
      />
      <path
        d="M0.609192 5.17166C1.05682 4.02886 1.81676 2.99148 2.86908 2.19877C3.9214 1.40606 5.12718 0.962681 6.34735 0.849135"
        stroke="var(--svg-fill, currentColor)"
      />
      <path
        d="M25.9237 6.55842C25.5746 5.69635 25.0013 4.91124 24.2157 4.2976C23.43 3.68395 22.5301 3.31841 21.6102 3.18941"
        stroke="var(--svg-fill, currentColor)"
      />
      <path
        d="M28.1034 5.26033C27.676 4.10987 26.9345 3.0594 25.8962 2.24847C24.8579 1.43755 23.66 0.973235 22.4419 0.838466"
        stroke="var(--svg-fill, currentColor)"
      />
    </svg>
    <NotificationsIcon />
  </_animatedWrap>
);

const signalAnim = keyframes`
  from { opacity: 0.0001 }
  50% { opacity: 1 }
  to { opacity: 0.0001 }
`;

const _animatedWrap = styled.span`
  display: inline-block;
  position: relative;

  svg {
    vertical-align: middle;
  }

  svg:first-child {
    pointer-events: none;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;

    path {
      animation: ${signalAnim} 1s infinite;
    }

    path:nth-child(1),
    path:nth-child(3) {
      animation-delay: 0ms;
    }

    path:nth-child(2),
    path:nth-child(4) {
      animation-delay: 150ms;
    }
  }
`;
