import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { LOGOUT_PATH } from "@web/utils/paths";
import { Button } from "@web/elements/Button";
import { Label } from "@web/elements";
import { useConfig } from "@web/config/context";
import { Dialog } from "../Dialog";
import LanguageSwitcher from "./LanguageSwitcher";
import { vars } from "@web/styles";

interface UserProfileDialogProps {
  userFullName: string;
  onClose: (result: { wasConfirmed: boolean }) => void;
}

export const UserProfileDialog: React.FC<UserProfileDialogProps> = ({
  userFullName,
  onClose,
}) => {
  const config = useConfig();
  const intl = useIntl();

  const handleCloseDialog = () => onClose({ wasConfirmed: false });
  const handleLogOut = () => (window.location.href = LOGOUT_PATH);

  return (
    <Dialog
      title={{ defaultMessage: userFullName }}
      onClickOutside={handleCloseDialog}
      onEscape={handleCloseDialog}
    >
      <_div>
        <_section>
          <_h2>{intl.formatMessage(texts.userGroups)}</_h2>
          <_ul>
            {config.userGroups.map((userGroup) => (
              <_li key={`user-profile-group-${userGroup}`}>
                <Label title={userGroup} />
              </_li>
            ))}
          </_ul>
        </_section>
        <_section>
          <LanguageSwitcher />
        </_section>
        <_section>
          <_button
            text={intl.formatMessage(texts.logOut)}
            variant="primary"
            onClick={handleLogOut}
          />
        </_section>
      </_div>
    </Dialog>
  );
};

const _div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 8px;
`;

const _section = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:last-of-type {
    margin-top: 12px;
  }
`;

export const _h2 = styled.h2`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: 12px;
  color: ${vars.grey};

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

const _ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const _li = styled.li`
  & > div {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    & > span {
      width: 100%;
      white-space: normal;
    }
  }
`;

const _button = styled(Button)`
  width: fit-content;
`;

const texts = defineMessages({
  userGroups: {
    id: "user.profile.menu.groups",
    defaultMessage: "User groups",
  },
  logOut: {
    id: "user.profile.menu.logout",
    defaultMessage: "Log out",
  },
});
