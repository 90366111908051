import { defineMessages } from "react-intl";

const commonCardTexts = defineMessages({
  tapToAdd: {
    id: "entry.taptoadd",
    defaultMessage: "Tap to add documents",
  },
});

export default commonCardTexts;
