import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
import { RemoteData, renderResult, SectionModel } from "@web/models";
import { MissingClassificationsResponse } from "@web/api/Integration/types";
import { commonTexts } from "@web/translations";
import { Label } from "@web/elements/Label";

export const TagsToBeRemovedText: React.FC<{
  missingTags: RemoteData<MissingClassificationsResponse>;
  selectedSection: SectionModel;
  selectedCardsCount: number;
}> = ({ missingTags, selectedSection, selectedCardsCount }) =>
  renderResult(missingTags, {
    NOT_REQUESTED: () => null,

    LOADING: () => (
      <p aria-busy="true">
        <FormattedMessage {...texts.missingTagsLoading} />
      </p>
    ),

    SUCCESS: (results) => {
      const missingTagsCount = results.data.flatMap(
        (classification) => classification.tags
      ).length;

      if (missingTagsCount === 0) {
        return null;
      }

      return (
        <div role="alert">
          <FormattedMessage
            {...texts.missingTagsMessage}
            values={{
              cardsCount: selectedCardsCount,
              sectionTitle: selectedSection.title,
              tagsCount: missingTagsCount,
            }}
          />
          : {" " /* ..valuable for sensible tests */}
          <_tagList>
            <JoinChildren separator=", ">
              {results.data.map((classification) => {
                const titles = classification.tags.map((tag) => tag.title);

                return (
                  <li key={classification.id}>
                    <Label
                      title={classification.title}
                      values={titles.slice(30)}
                      overflowing={classification.hasMore || titles.splice(30)}
                    />
                  </li>
                );
              })}

              {results.hasMore && (
                <li>
                  <span>
                    + <FormattedMessage {...commonTexts.more} />
                  </span>
                </li>
              )}
            </JoinChildren>
          </_tagList>
        </div>
      );
    },

    ERROR: () => (
      <p role="alert">
        <FormattedMessage
          {...texts.missingTagsFailed}
          values={{
            sectionTitle: selectedSection.title,
          }}
        />
      </p>
    ),
  });

const JoinChildren: React.FC<{
  separator: string;
  children: React.ReactNode[];
}> = ({ separator, children }) => (
  <>
    {React.Children.map(children, (child, index) => (
      <>
        {child}
        {index < children.length - 1 && (
          <span style={{ display: "none" }}>{separator}</span>
        )}
      </>
    ))}
  </>
);

const _tagList = styled.ul`
  list-style: none;
  padding-inline-start: 0;

  > li {
    margin-bottom: 0.25rem;

    span {
      margin-left: 0.25rem;
    }
  }
`;

export const texts = defineMessages({
  title: {
    id: "selection.dialog.move.title",
    defaultMessage: "Move {cardsCount, plural, one {item} other {items}}",
  },

  subTitle: {
    id: "selection.dialog.move.subtitle",
    defaultMessage: "Select section to move to:",
  },

  accessWarning: {
    id: "selection.dialog.move.accesswarning",
    defaultMessage:
      "<strong>Note:</strong> Anyone who has access to this section will be able to see both the {cardsCount, plural, one {item} other {items}} and the entire history log of {cardsCount, plural, one {the item} other {these items}}.",
  },

  privilegesWarning: {
    id: "selection.dialog.move.privilegeswarning",
    defaultMessage:
      "You do not have sufficient privileges in <strong>{sectionTitle}</strong> to move the {cardsCount, plural, one {item} other {items}} into that section.",
  },

  missingTagsLoading: {
    id: "selection.dialog.move.missingtags.loading",
    defaultMessage: "Checking if any tags will have to be removed…",
  },

  missingTagsFailed: {
    id: "selection.dialog.move.missingtags.failed",
    defaultMessage:
      "Error verifying the existence of tags! There might be tags that has to be removed because they do not exist in <strong>{sectionTitle}</strong>. Proceed with caution.",
  },

  missingTagsMessage: {
    id: "selection.dialog.move.missingtags",
    defaultMessage:
      "The following {tagsCount, plural, one {tag} other {tags}} will be removed from the {cardsCount, plural, one {item} other {items}} because {tagsCount, plural, one {it does} other {they do}} not exist in <strong>{sectionTitle}</strong>",
  },

  buttonMoveEntries: {
    id: "selection.dialog.move.button.moveentries",
    defaultMessage: "Move {cardsCount, plural, one {item} other {items}}",
  },

  buttonRemoveTagsAndMoveEntries: {
    id: "selection.dialog.move.button.removetagsandentries",
    defaultMessage:
      "Remove {tagsCount, plural, one {tag} other {tags}} and move {cardsCount, plural, one {item} other {items}}",
  },
});
