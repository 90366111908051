import { observable, action, when, computed, reaction } from "mobx";
import debounce from "lodash/debounce";
import { defineMessages } from "react-intl";
import { Api, getRequestError } from "@web/api";
import { ISectionNode } from "@web/api/Integration/types";
import { SectionModel, DataLoadingModel, RequiredTagModel } from "@web/models";
import { SearchParams } from "@web/pages/SearchPage/handler";
import { ILocalStorage } from "./types";
import commonStoreTexts from "./texts";
import { RootStore } from ".";

type SectionID = number;

const PAGE_SIZE = 20;
export const LAST_USED_SECTION_STORAGE_KEY = "lastUsedSectionId";

const INITIAL_AUTOCOMPLETE = {
  query: "",
  pageLoading: false,
  hasMore: true,
  lastPageLoaded: 0,
  itemCount: 0,
  matches: [] as SectionModel[],
};

export class SectionStore {
  @observable
  sectionCache = new Map<SectionID, SectionModel>();
  @observable
  selectedSection: SectionModel | undefined;
  @observable
  isGlobalSearch = false;

  @observable
  loading = false;
  @observable
  error?: Error;
  @observable
  hasMore = true;
  @observable
  lastPageLoaded = 0;

  @observable
  autocomplete = INITIAL_AUTOCOMPLETE;

  @observable
  requiredTags: Map<SectionID, RequiredTagModel[]> = new Map();

  constructor(
    private api: Api,
    public rootStore: RootStore,
    private localStorage: ILocalStorage
  ) {}

  private sectionSelectionReaction = reaction(
    () => this.selectedSection?.id,
    (newSectionId) => {
      if (newSectionId) {
        this.loadRequiredTags(newSectionId);
      }
    }
  );

  @computed
  get sections(): SectionModel[] {
    return Array.from(this.sectionCache.values());
  }

  @computed
  get loadingStatus(): DataLoadingModel {
    if (this.isAutocompleting) {
      return this.autocomplete;
    }
    return {
      hasMore: this.hasMore,
      lastPageLoaded: this.lastPageLoaded,
      pageLoading: this.loading,
      itemCount: this.sections.length,
    };
  }

  @computed
  get isDoingInitialLoad(): boolean {
    return this.loading && this.lastPageLoaded === 0;
  }

  @computed
  get activeSectionList(): SectionModel[] {
    if (this.isAutocompleting) {
      return this.autocomplete.matches;
    }

    // The section selector has enough space to fit 11 sections (without scrolling), if there are more
    // we want to display the currently selected section at the top
    if (this.selectedSection && (this.sections.length > 11 || this.hasMore)) {
      return [
        this.selectedSection,
        ...this.sections.filter((s) => s !== this.selectedSection),
      ];
    }

    return this.sections;
  }

  @computed
  get isEmptyHierarchy(): boolean {
    return (
      this.lastPageLoaded === 1 && this.sections.length === 0 && !this.hasMore
    );
  }

  @computed
  get requiredTagsForCurrentSection() {
    if (!this.selectedSection) return undefined;
    return this.requiredTags.get(this.selectedSection.id);
  }

  get isAutocompleting() {
    return this.autocomplete.query !== "";
  }

  async sectionsLoaded() {
    await when(() => this.sectionCache.size > 0);
  }

  sectionFromJson = (json: ISectionNode) => {
    const existing = this.sectionCache.get(json.internalIdentifier);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    } else {
      const section = new SectionModel(this, json);
      this.sectionCache.set(section.id, section);
      return section;
    }
  };

  @action
  async loadRequiredTags(sectionId: number) {
    if (this.requiredTags.has(sectionId)) {
      return;
    }
    try {
      const { data } = await this.api.getRequiredTags(sectionId);
      const formattedData = data.data.reduce<RequiredTagModel[]>((res, cl) => {
        cl.tags.forEach((tag) => {
          res.push({
            uuid: tag.id,
            title: tag.title,
            classification: {
              uuid: cl.id,
              title: cl.title,
            },
          });
        });
        return res;
      }, []);

      this.requiredTags.set(sectionId, formattedData);
    } catch (err) {
      console.error("Failed to load required tags for section", err);
    }
  }

  @action.bound
  selectSection(selectedSection: SectionModel) {
    this.selectedSection = selectedSection;
    this.autocomplete = INITIAL_AUTOCOMPLETE;

    try {
      this.localStorage.setItem(
        LAST_USED_SECTION_STORAGE_KEY,
        selectedSection.id.toString()
      );
    } catch (err) {
      console.error(
        "Error while setting last used section ID into localStorage. No biggie, but won't as convenient for the end-user:",
        err
      );
    }
  }

  @action.bound
  async loadNextPage() {
    if (this.isAutocompleting) {
      this.autocompleteSections(
        this.autocomplete.query,
        this.autocomplete.lastPageLoaded + 1,
        false
      );
    } else {
      this.loadSectionPage(this.loadingStatus.lastPageLoaded + 1);
    }
  }

  @action.bound
  async loadSectionPage(page = 1) {
    if (!this.hasMore || page <= this.lastPageLoaded) {
      return;
    }

    this.loading = true;
    try {
      const { data } = await this.api.sections(page, PAGE_SIZE);
      this.hasMore = data.hasMore;
      this.lastPageLoaded = data.page;
      data.data.forEach(this.sectionFromJson);
    } catch (e) {
      console.error("Error while loading sections:", e);

      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.loadSectionsFailed,
      });
    } finally {
      this.loading = false;
    }
  }

  /**
   * Load the minimum of sections we need to start displaying the results:
   * 1. the section given by params
   * 2. the default section
   * Then proceed to loading the first page of sections.
   * If neither 1 or 2 is found, first section on page will be selected.
   */
  async loadInitialSections(sectionIdParam: string | undefined) {
    let sectionToLoad;
    if (sectionIdParam && sectionIdParam !== "all") {
      sectionToLoad = Number(sectionIdParam);
    } else {
      sectionToLoad = this.lastUsedSection;
    }

    if (sectionToLoad) {
      await this.loadAndSelectSection(sectionToLoad);
    }

    const firstPageLoaded = this.loadSectionPage(1);

    if (!sectionIdParam && !this.selectedSection) {
      await firstPageLoaded;
      this.selectDefaultSection();
    }
  }

  selectDefaultSection() {
    if (this.sections.length > 0) {
      this.loadAndSelectSection(this.lastUsedSection || this.sections[0].id);
    }
  }

  @action.bound
  async loadAndSelectSection(sectionId: number) {
    const alreadyLoadedSection = this.sections.find((s) => s.id === sectionId);
    const found = alreadyLoadedSection ?? (await this.loadSection(sectionId));

    if (found) {
      this.selectSection(found);
    }

    if (!found && sectionId === this.lastUsedSection) {
      try {
        this.localStorage.removeItem(LAST_USED_SECTION_STORAGE_KEY);
      } catch (e) {
        console.error("Error clearing last used section in local storage");
      }
    }
  }

  /**
   * Load single section with (internal) id
   */
  async loadSection(sectionId: number) {
    try {
      const sectionAsJson = await this.api.getSection(sectionId);
      return this.sectionFromJson(sectionAsJson);
    } catch (e) {
      this.error = getRequestError(e);
      console.error("Error retrieving section", e);
    }
  }

  @action.bound
  setAutocompleteQuery(newQuery: string) {
    if (newQuery) {
      this.autocomplete.query = newQuery;
      this.autocompleteSectionsDebounced(newQuery, 1, true);
    } else {
      this.autocomplete = INITIAL_AUTOCOMPLETE;
    }
  }

  autocompleteSectionsDebounced = debounce(this.autocompleteSections, 300);

  @action.bound
  private async autocompleteSections(
    titleQuery: string,
    page: number,
    queryHasChanged: boolean
  ) {
    if (!queryHasChanged && !this.autocomplete.hasMore) {
      return;
    }

    try {
      this.autocomplete.pageLoading = true;
      const { data } = await this.api.findSections(titleQuery, undefined, page);

      if (this.autocomplete.query === titleQuery) {
        const newSections = data.data.map(this.sectionFromJson);
        const newMatches = queryHasChanged
          ? newSections
          : this.autocomplete.matches.concat(newSections);

        // setting the entire .autocomplete field at once instead of per child field to avoid components
        // re-rendering as a result of setting one field at the time
        this.autocomplete = {
          query: titleQuery,
          matches: newMatches,
          itemCount: newMatches.length,
          hasMore: data.hasMore,
          lastPageLoaded: data.page,
          pageLoading: false,
        };
      }
    } catch (e) {
      this.autocomplete.pageLoading = false;
      console.log("Error autocompleting sections", e);
    }
  }

  @computed
  get canWriteInSelectedSection(): boolean {
    if (this.isGlobalSearch) {
      return false;
    }
    return this.selectedSection?.canUpload ?? false;
  }

  @computed
  get searchParameters(): SearchParams {
    return {
      sectionId: this.isGlobalSearch
        ? "all"
        : this.selectedSection?.id.toString() ??
          this.defaultSection?.id.toString(),
    };
  }

  @computed
  get defaultSection(): SectionModel | undefined {
    if (this.isEmptyHierarchy) {
      return undefined;
    }

    const firstSection = this.sections[0];
    const lastUsedSectionId = this.lastUsedSection;
    const lastUsedIfLoaded = this.sections.find(
      (s) => s.id === lastUsedSectionId
    );

    return lastUsedIfLoaded ?? firstSection;
  }

  /** Section CRUD */
  renameSection = async (section: SectionModel, newTitle: string) => {
    try {
      const { data } = await this.api.updateSection(section.uuid, newTitle);
      section.updateFromJson(data.data);

      this.rootStore.messageStore.addMessage({
        type: "success",
        title: commonStoreTexts.updateComplete,
      });
    } catch (e) {
      console.log("Error while renaming section:", e);

      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.updatedFailedWithTitle,
        values: {
          title: section.title,
        },
      });
    }
  };

  @action.bound
  createSection = async (title: string) => {
    const { messageStore } = this.rootStore;
    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.createSectionInprogress,
      text: `"${title}"`,
    });

    try {
      const { data } = await this.api.createSection(title);
      this.sectionFromJson(data.data);

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.createSectionSuccess,
      });
    } catch (e) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.createSectionFailed,
      });
    }
  };

  @action.bound
  deleteSection = async (section: SectionModel) => {
    const { messageStore } = this.rootStore;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.deleteSectionInprogress,
      text: `"${section.title}"`,
    });

    try {
      // Delete any classifications that does not belong to multiple sections
      for (const cl of section.classifications) {
        await cl.loadSectionCount();
        if (cl.sectionCount && cl.sectionCount === 1) {
          await this.api.deleteClassification(cl.uuid);
        }
      }

      // NB! Deleting a section will also delete the entries
      await this.api.deleteSection(section.uuid);
      this.sectionCache.delete(section.id);

      if (this.lastUsedSection === section.id) {
        try {
          this.localStorage.removeItem(LAST_USED_SECTION_STORAGE_KEY);
        } catch (e) {
          console.error("Error updating last used section in local storage");
        }
      }

      if (this.selectedSection?.id === section.id) {
        this.selectedSection = undefined;
      }

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deleteSectionSuccess,
      });
    } catch (e) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.deleteSectionFailed,
      });
    }
  };

  /* Helper functions **/
  getSimilarSections = async (newTitle: string, currentValue?: string) => {
    try {
      const exclude = currentValue ? [currentValue] : [];
      const { data } = await this.api.findSections(newTitle, exclude);
      return data.data.map(this.sectionFromJson);
    } catch (e) {
      console.error("Error while getting similar sections: ", e);
      // Fallback: the user gets an error from backend for duplicate values
      return [];
    }
  };

  findSections = async (query: string) => {
    try {
      const { data } = await this.api.findSections(query);
      return data.data;
    } catch (e) {
      console.error("Failed to find sections", e);
      return [];
    }
  };

  @computed
  get lastUsedSection() {
    try {
      const storedId = Number(
        this.localStorage.getItem(LAST_USED_SECTION_STORAGE_KEY) || -1
      );
      return storedId > -1 ? storedId : undefined;
    } catch (e) {
      this.localStorage.removeItem(LAST_USED_SECTION_STORAGE_KEY);
    }
  }
}

const texts = defineMessages({
  createSectionInprogress: {
    id: "request.section.create.inprogress",
    defaultMessage: "Adding section",
  },
  createSectionSuccess: {
    id: "request.section.create.success",
    defaultMessage: "Section added",
  },
  createSectionFailed: {
    id: "request.section.create.failed",
    defaultMessage: "Failed to add section",
  },
  deleteSectionInprogress: {
    id: "request.section.delete.inprogress",
    defaultMessage: "Deleting section",
  },
  deleteSectionSuccess: {
    id: "request.section.delete.success",
    defaultMessage: "Section deleted",
  },
  deleteSectionFailed: {
    id: "request.section.delete.failed",
    defaultMessage: "Failed to delete section",
  },
  loadSectionsFailed: {
    id: "request.sections.load.failed",
    defaultMessage: "Failed to load sections",
  },
});
