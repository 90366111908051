import { IAttributeValueNode } from "@web/api/Integration/types";
import { AttributeReminder, AttributeStore } from "@web/stores";
import { action, observable, computed } from "mobx";
import {
  AttributeValueModel,
  AttributeValuePrimitive,
} from "./AttributeValueModel";

type DefinitionId = UUID;
type ValueId = UUID;
export type AttributeValuesParent = { objectType: "entry" | "tag"; uuid: UUID };

type UnsavedChanges = Record<
  ValueId,
  {
    selected: boolean;
    value: AttributeValueModel;
  }
>;

interface GetValueOptions {
  definitionId: DefinitionId;
  includeChanges: boolean;
}

interface UpdateFromJsonOptions {
  clearFirst: boolean;
}

export class AttributeValuesMapModel {
  @observable
  values = new Map<DefinitionId, AttributeValueModel[]>();
  @observable
  private changes: UnsavedChanges = {};

  constructor(
    private store: AttributeStore,
    public readonly parent: AttributeValuesParent,
    node?: IAttributeValueNode[]
  ) {
    if (node) {
      this.updateFromJson(node, { clearFirst: false });
    }
  }

  @computed
  get allValues() {
    return [...this.values.values()].flat().map((v) => v.rawValue);
  }

  loadValues() {
    return this.store.loadAttributeValues(this);
  }

  getSingleValueObject(
    options: GetValueOptions
  ): AttributeValueModel | undefined {
    const match = this.getValueObjects(options);
    if (match.length > 0) {
      return match[0];
    }
  }

  getSingleValue(
    options: GetValueOptions
  ): AttributeValuePrimitive | undefined {
    return this.getSingleValueObject(options)?.value;
  }

  getValues(options: GetValueOptions): AttributeValuePrimitive[] {
    return this.getValueObjects(options).map((x) => x.value);
  }

  getValueObjects({
    definitionId,
    includeChanges,
  }: GetValueOptions): AttributeValueModel[] {
    let values = [...(this.values.get(definitionId) ?? [])];
    if (includeChanges) {
      for (const change of Object.values(this.changes)) {
        if (change.selected) {
          values.push(change.value);
        } else {
          values = values.filter((x) => x.uuid !== change.value.uuid);
        }
      }
    }
    return values;
  }

  hasValue({
    valueId,
    definitionId,
    includeChanges,
  }: GetValueOptions & { valueId: ValueId }): boolean {
    const values = this.getValueObjects({ definitionId, includeChanges });
    return values.some((x) => x.uuid === valueId);
  }

  async clearValues(definitionId: UUID) {
    const existing = this.values.get(definitionId)?.[0];
    if (existing) {
      const success = await existing.delete();
      success && this.values.delete(definitionId);
    }
  }

  setChange(value: AttributeValueModel, selected: boolean) {
    this.changes[value.uuid] = { value, selected };
  }

  hasChanges(definitionId: DefinitionId): boolean {
    for (const { value, selected } of Object.values(this.changes)) {
      if (value.definitionId === definitionId) {
        const hasValue = this.hasValue({
          valueId: value.uuid,
          definitionId: value.definitionId,
          includeChanges: false,
        });
        if ((selected && !hasValue) || (!selected && hasValue)) {
          return true;
        }
      }
    }
    return false;
  }

  @action.bound
  clearChanges() {
    this.changes = {};
  }

  @action.bound
  async saveChanges(comment?: string) {
    const add: UUID[] = [];
    const remove: UUID[] = [];

    for (const { selected, value } of Object.values(this.changes)) {
      const hasValue = this.hasValue({
        valueId: value.uuid,
        definitionId: value.definitionId,
        includeChanges: false,
      });
      if (selected && !hasValue) {
        add.push(value.uuid);
      } else if (!selected && hasValue) {
        remove.push(value.uuid);
      }
    }

    await this.store.updateAttributeValues(this, { add, remove }, comment);
    this.clearChanges();
  }

  updateSingleValue(
    definitionId: UUID,
    value: AttributeValuePrimitive,
    comment?: string,
    reminder?: AttributeReminder
  ) {
    const existing = this.values.get(definitionId)?.[0];
    if (existing) {
      return this.store.updateAttributeValue(
        existing,
        value,
        comment,
        reminder
      );
    } else {
      return this.store.createAttributeValue(
        this.parent,
        definitionId,
        value,
        comment,
        reminder
      );
    }
  }

  @action.bound
  updateFromJson(nodes: IAttributeValueNode[], options: UpdateFromJsonOptions) {
    if (options.clearFirst) {
      this.values.clear();
    }
    for (const node of nodes) {
      const values = this.getValueObjects({
        definitionId: node.definition.id,
        includeChanges: false,
      });
      const valueExists = values.some((x) => x.uuid === node.id);
      if (!valueExists) {
        const newValue = this.store.attributeValueFromJson(node);
        values.push(newValue);
        this.values.set(node.definition.id, values);
      }
    }
  }

  /**
   * Purges attribute values except for the definition ids provided.
   * This method does *not* interact with the stores or backend, it
   * is only for cleaning up internal state.
   */
  @action.bound
  purgeValues(definitionIdsToKeep: UUID[]) {
    for (const definitionId of this.values.keys()) {
      if (!definitionIdsToKeep.includes(definitionId)) {
        this.values.delete(definitionId);
      }
    }
  }
}
