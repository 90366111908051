import groupBy from "lodash/groupBy";
import { FileWithPath } from "react-dropzone";
import {
  fileExtension,
  getRootDir,
  pathWithoutExtension,
} from "@web/utils/helpers";
import {
  DocumentUploadRequest,
  EntryUploadRequest,
  UploadJob,
  UploadRequestType,
  VersionUploadRequest,
} from "@web/models";
import {
  ConfirmationHandler,
  DocumentUploadProps,
  EntryUploadProps,
  VersionUploadProps,
} from "./types";
import {
  DocumentStore,
  DocumentVersionStore,
  RecordStore,
  UploadStore,
} from "@web/stores";
import { createEntries } from "@web/components/Upload/creators/createEntries";
import { createDocuments } from "@web/components/Upload/creators/createDocuments";
import { createVersion } from "@web/components/Upload/creators/createVersion";

export const UNTITLED_ENTRY = "";

export const handleEntryUpload = async (
  recordStore: RecordStore,
  files: FileWithPath[],
  props: EntryUploadProps,
  opts: {
    multipleEntries: boolean;
  }
): Promise<void> => {
  const { onUpload, getConfirmation, sectionId, tags } = props;

  if (typeof sectionId === "undefined") {
    return;
  }

  const entryGroups = groupBy(files, (file) => {
    const fileName = file.name.normalize();
    const rootDirectoryName = getRootDir(file.path)?.normalize();

    if (opts.multipleEntries) {
      return rootDirectoryName ?? fileName;
    }

    if (allFilesAreInsideSameRootDirectory(files)) {
      return rootDirectoryName;
    }

    return UNTITLED_ENTRY;
  });

  const requests = Object.entries(entryGroups).map(
    ([groupName, files]): EntryUploadRequest => {
      const isSingleDocumentEntry = files.length === 1 && !isDir(files[0].path);

      const entryTitle = isSingleDocumentEntry
        ? UNTITLED_ENTRY
        : formatEntryName(groupName);

      const data = files.map((file) => {
        const fileFormat = fileExtension(file.name);
        const fileName = pathWithoutExtension(file.name.normalize());

        let filePath = file.path?.normalize().replace(/^\//, "");

        if (opts.multipleEntries || allFilesAreInsideSameRootDirectory(files)) {
          filePath = withoutRootDirectory(filePath);
        }

        const title = pathWithoutExtension(filePath).slice(0, 255);

        return {
          file,
          fileName,
          fileFormat,
          title,
        };
      });

      return {
        type: UploadRequestType.entry,
        sectionId,
        tags: tags.tagIdsForNewEntry,
        title: entryTitle,
        files: data,
        isSingleDocumentEntry,
      };
    }
  );

  const uploadPromises = requests.map((request) => onUpload(request));

  const uploadJobsResults = await Promise.allSettled(uploadPromises);

  const requestJobsMap: Map<EntryUploadRequest, UploadJob[]> = new Map();
  const allJobs: UploadJob[] = [];

  uploadJobsResults.forEach((settledResult, index) => {
    if (settledResult.status === "fulfilled" && settledResult.value) {
      settledResult.value.jobs.forEach((job) => {
        const jobs = requestJobsMap.get(requests[index]) ?? [];
        jobs.push(job);
        requestJobsMap.set(requests[index], jobs);

        allJobs.push(job);
      });
    }
  });

  const createEntities = async () => {
    await createEntries(requestJobsMap, allJobs, recordStore);
  };

  await handleUploadHelper(
    recordStore.rootStore.uploadStore,
    [createEntities],
    allJobs,
    getConfirmation
  );

  tags.selectedRequiredTag = undefined;
};

export const handleDocumentUpload = async (
  documentStore: DocumentStore,
  filesToUpload: Array<
    | FileWithPath
    | { file: FileWithPath; newFileName: string; newFilePath: string }
  >,
  props: DocumentUploadProps
) => {
  const { onUpload, getConfirmation, entry } = props;
  const files = filesToUpload.map((fileOrMetaInfo) => {
    const { file, name, path } =
      "newFileName" in fileOrMetaInfo
        ? {
            file: fileOrMetaInfo.file,
            name: fileOrMetaInfo.newFileName,
            path: fileOrMetaInfo.newFilePath,
          }
        : {
            file: fileOrMetaInfo,
            name: fileOrMetaInfo.name,
            path: fileOrMetaInfo.path,
          };

    const fileName = pathWithoutExtension(name.normalize());
    const fileFormat = fileExtension(name.normalize());
    const filePath = pathWithoutExtension(path?.normalize().replace(/^\//, ""));
    const title = (isDir(filePath) ? filePath : fileName).slice(0, 255);

    return {
      file,
      fileName,
      fileFormat,
      title,
    };
  });

  const documentsUpload: DocumentUploadRequest = {
    type: UploadRequestType.document,
    entryId: entry.id,
    entryUuid: entry.uuid,
    files,
  };

  const afterUploadResult = await onUpload(documentsUpload);

  if (!afterUploadResult) {
    return;
  }

  const allJobs: UploadJob[] = [];

  afterUploadResult.jobs.forEach((job) => allJobs.push(job));

  const createEntities = async () => {
    await createDocuments(documentStore, documentsUpload, allJobs);
  };

  await handleUploadHelper(
    documentStore.rootStore.uploadStore,
    [createEntities],
    allJobs,
    getConfirmation
  );
};

export const handleVersionUpload = async (
  documentVersionStore: DocumentVersionStore,
  file: File,
  props: VersionUploadProps
) => {
  const { onUpload, getConfirmation, document } = props;
  const fileFormat = fileExtension(file.name);
  const fileName = pathWithoutExtension(file.name.normalize());
  const request: VersionUploadRequest = {
    type: UploadRequestType.version,
    documentId: document.id,
    documentUuid: document.uuid,
    file: {
      file,
      fileName,
      fileFormat,
      title: document.title,
    },
  };

  const uploadResult = await onUpload(request);

  if (!uploadResult) {
    return;
  }

  const allJobs: UploadJob[] = [];

  uploadResult.jobs.forEach((job) => allJobs.push(job));

  const createEntities = async () => {
    await createVersion(documentVersionStore, allJobs);
  };

  await handleUploadHelper(
    documentVersionStore.rootStore.uploadStore,
    [createEntities],
    allJobs,
    getConfirmation
  );
};

function getDuplicates(jobs: UploadJob[]) {
  const checkSums = new Map<string, Set<string>>();

  jobs.forEach((job) => {
    if (!checkSums.has(job.result.checkSum)) {
      checkSums.set(job.result.checkSum, new Set());
    }

    const fileIds = checkSums.get(job.result.checkSum);

    if (fileIds && job.result.fileId) {
      fileIds.add(job.result.fileId);
    } else {
      throw new Error("Invalid file id or checksums");
    }
  });

  // Job result is marked as duplicated after we have done a lookup by the checksum of the uploaded file
  // If there is such checksum already in the system, we set the isDuplicated flag to true
  return jobs.filter(
    (job) =>
      job.result?.isDuplicated ||
      Array.from(checkSums.get(job.result.checkSum)?.values() || []).length > 1
  );
}

async function handleUploadHelper(
  uploadStore: UploadStore,
  entityCreators: (() => Promise<void>)[],
  allJobs: UploadJob[],
  getConfirmation: ConfirmationHandler
) {
  await uploadStore.markDuplicates(allJobs);

  const duplicates = getDuplicates(allJobs).map((job) => job.result);

  const createAll = async () => {
    await Promise.all(entityCreators.map((creator) => creator()));
  };

  if (duplicates.length > 0) {
    await getConfirmation(duplicates, createAll);
  } else {
    await createAll();
  }
}

/**
 * Take a entry name and return a formatted/shortened version.
 *
 * Background:
 *  - Entry names can be max 255 characters, and input must
 *    be shortened to this length.
 *  - Entries can be created from nested folder structures,
 *    and the output should include the most important
 *    parts of the path.
 *
 * Split the input entry name on '/' and
 * apply these rules:
 *  1. If the last (or only) part is > 255 characters,
 *     truncate to 255 and return it.
 *  2. For each part from right to left, add it in front
 *     of the result with a ' / ' separator
 *     until the result would exceed 255 characters.
 */
const formatEntryName = (name: string) => {
  const path = name.startsWith("/")
    ? name.slice(1).split("/")
    : name.split("/");
  let result = path[path.length - 1];
  if (result.length > 255) {
    return result.substr(0, 255);
  }
  for (let i = path.length - 2; i >= 0; i--) {
    const prepended = `${path[i]} / ${result}`;
    if (prepended.length > 255) {
      return result;
    }
    result = prepended;
  }
  return result;
};

function isDir(path: string | undefined) {
  if (typeof path === "undefined") {
    return false;
  }
  return path.split("/").length > 1;
}

function rootDirectoryNamesOfFiles(files: FileWithPath[]) {
  const directoryNames = files.map((file) => getRootDir(file.path));
  const rootDirectories = directoryNames.filter((dir): dir is string => !!dir);
  const uniqueNames = Array.from(new Set(rootDirectories));
  return uniqueNames;
}

function allFilesAreInsideSameRootDirectory(files: FileWithPath[]): boolean {
  const directories = rootDirectoryNamesOfFiles(files);

  if (directories.length !== 1) {
    return false;
  }

  return files.every((file) => file.path?.startsWith("/" + directories[0]));
}

function withoutRootDirectory(path = "") {
  if (!isDir(path)) {
    return path;
  }
  return path.split("/").slice(1).join("/");
}
