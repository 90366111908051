import { useEffect } from "react";
import { useMedia } from "@web/styles/utils";

export function useScrollBlocker() {
  const { isCompact } = useMedia();

  useEffect(() => {
    const { scrollX, scrollY } = window;

    if (document.documentElement.style.overflow !== "hidden") {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.width = "100%";
      document.documentElement.style.height = "100vh";
      document.documentElement.style.top = -scrollY + "px";
      document.documentElement.style.left = -scrollX + "px";

      if (isCompact) {
        document.documentElement.style.position = "fixed";
      }

      return () => {
        document.documentElement.style.overflow = "";
        document.documentElement.style.width = "";
        document.documentElement.style.height = "";
        document.documentElement.style.top = "";
        document.documentElement.style.left = "";
        document.documentElement.style.position = "";
        setTimeout(() => scrollTo(scrollX, scrollY));
      };
    }
  }, []);
}
