import styled from "styled-components";
import { vars } from "@web/styles";

export const IconBadge = styled.span`
  background: ${vars.secondary};
  border-radius: 5rem;
  color: ${vars.secondaryFg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0;
  margin-left: 0.125rem;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
`;
