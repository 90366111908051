import {
  ILoadData,
  IObjectWithAccessControl,
  Permission,
} from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import debounce from "lodash/debounce";
import { action, observable } from "mobx";
import {
  INITIAL_SEARCH_LOADING_STATUS,
  QueryLoadingModel,
} from "../DataLoadingModel";
import { AccessGroupListModel } from "./AccessGroupListModel";
import { NamedPermissionSet } from "./NamedPermissionSets";
import { ObjectPermissionListModel } from "./ObjectPermissionListModel";
import {
  ObjectTypeString,
  ObjectWithAccessControlModel,
} from "./ObjectWithAccessControlModel";

export class ObjectPermissionsMatrixModel<
  ObjectType extends IObjectWithAccessControl
> {
  pageSize = 100;

  @observable
  loadingStatus: QueryLoadingModel;

  @observable
  objects: ObjectWithAccessControlModel<ObjectType>[] = [];

  constructor(
    private store: AccessControlStore,
    private objectType: ObjectTypeString,
    public availablePermissions: Permission[],
    public namedPermissionSets: NamedPermissionSet<Permission>[],
    private loadPage: (page: number, pageSize: number, search: string) => void,
    public accessGroups: AccessGroupListModel,
    private options: { showSearch: boolean }
  ) {
    this.loadingStatus = {
      ...INITIAL_SEARCH_LOADING_STATUS,
      showSearch: this.options.showSearch,
    };
  }

  permissionListForObject(
    object: ObjectWithAccessControlModel<ObjectType>
  ): ObjectPermissionListModel<ObjectType> {
    return this.store.permissionListForObject(object.uuid);
  }

  @action.bound
  reloadAccessGroups() {
    this.accessGroups.reload();
  }

  @action.bound
  filterObjects(query: string) {
    this.loadingStatus.query = query;
    this.reloadDebounced();
  }

  private reloadDebounced = debounce(this.reload, 300);

  @action.bound
  reload() {
    this.objects = [];
    this.loadingStatus = {
      ...INITIAL_SEARCH_LOADING_STATUS,
      showSearch: this.options.showSearch,
      query: this.loadingStatus.query,
    };
    this.loadNextPage();
  }

  @action.bound
  async loadNextPage(): Promise<void> {
    if (this.loadingStatus.pageLoading) {
      return;
    }
    this.loadingStatus.pageLoading = true;
    this.loadPage(
      this.loadingStatus.lastPageLoaded + 1,
      this.pageSize,
      this.loadingStatus.query
    );
  }

  @action.bound
  updateFromJson({ data, hasMore, page }: ILoadData<ObjectType>) {
    this.objects.push(
      ...data.map((json) =>
        this.store.objectWithAccessControlFromJson(json, this.objectType)
      )
    );

    this.loadingStatus = {
      ...this.loadingStatus,
      lastPageLoaded: page,
      hasMore: hasMore,
      itemCount: this.objects.length,
      pageLoading: false,
    };
  }
}
