import { observable, action, computed } from "mobx";
import axios, { CancelTokenSource } from "axios";
import uniqueId from "lodash/uniqueId";
import random from "lodash/random";
import {
  UploadJobProgress,
  UploadJobResult,
  UploadJobStatus,
  UploadJobType,
  UploadRequest,
  UploadRequestType,
  UploadData,
} from "./UploadModel";

/**
 * This class represents a singe upload job.
 * A job is responsible for
 * - Keeping track of the upload progress/status for a _single_ file upload
 * - Storing the results from other API operations needed in order to
 *   execute the original `UploadRequest`, such as:
 *   - Creating an entry
 *   - Creating a document
 *   - Creating a document version
 */
export class UploadJob {
  readonly jobId = uniqueId();
  readonly createdAt = Date.now();
  readonly cancelToken: CancelTokenSource = axios.CancelToken.source();
  readonly requestType: UploadRequestType;
  readonly data: UploadData;
  @observable type = UploadJobType.active;
  @observable status = UploadJobStatus.created;
  @observable result: UploadJobResult;

  // This will be filled in when the actual file upload starts.
  @observable progress: UploadJobProgress = {
    totalBytes: -1,
    completedBytes: 0,
    completedPercent: 0,
  };

  constructor(request: UploadRequest, data: UploadData) {
    this.requestType = request.type;
    this.data = data;
    this.result = {
      isDuplicated: false,
      checkSum: "",
      fileName: data.fileName,
      fileFormat: data.fileFormat,
      documentTitle: data.title,
      fileId: undefined,
      documentId: undefined,
      documentUuid: undefined,
      entryId: undefined,
      entryUuid: undefined,
      error: undefined,
    };
    if (request.type === UploadRequestType.version) {
      this.result.documentUuid = request.documentUuid;
      this.result.documentId = request.documentId;
    }

    if (request.type === UploadRequestType.document) {
      this.result.entryId = request.entryId;
      this.result.entryUuid = request.entryUuid;
    }
  }

  @action
  setProgress = (progress: Partial<UploadJobProgress>) => {
    if (this.type === UploadJobType.active) {
      Object.assign(this.progress, progress);
    }
  };

  @action
  setStatus = (status: UploadJobStatus) => {
    if (this.type === UploadJobType.active) {
      this.status = status;
    }
  };

  @action
  setResult = (result: Partial<UploadJobResult>) => {
    if (this.type === UploadJobType.active) {
      Object.assign(this.result, result);
    }
  };

  @action
  cancel = () => {
    this.cancelToken.cancel();
    this.status = UploadJobStatus.cancelled;
  };

  startProgressUpdate = (
    callback: (time: number) => Partial<UploadJobProgress>
  ) => {
    const startTime = Date.now();
    const update = () => {
      if (this.status === UploadJobStatus.failed) {
        return;
      }
      if (this.status === UploadJobStatus.completed) {
        this.setProgress({ completedPercent: 100 });
        return;
      }
      const time = Date.now() - startTime;
      const progress = callback(time);
      this.setProgress(progress);
      setTimeout(update, random(50, 200, false));
    };
    update();
  };
}

/**
 * This class represents a group of upload jobs, such as a set of files uploaded when creating an entry.
 * An UploadGroup is responsible for
 * - Keeping track of the upload progress/status for all the jobs combined.
 */
export class UploadGroup {
  readonly groupId = uniqueId();
  readonly requestType: UploadRequestType;
  @observable jobs: UploadJob[];
  @observable title: string | undefined;

  constructor(
    jobs: UploadJob[],
    requestType: UploadRequestType,
    title?: string
  ) {
    this.jobs = jobs;
    this.requestType = requestType;
    this.title = title;
  }

  @computed
  get isEntryGroup() {
    return this.jobs.length > 1 && this.requestType === UploadRequestType.entry;
  }

  @computed
  get entryId() {
    return this.jobs.reduce<number | undefined>(
      (id, job) => id || job.result.entryId,
      undefined
    );
  }

  @computed
  get isInProgress() {
    return [
      UploadJobStatus.created,
      UploadJobStatus.waitingForEntry,
      UploadJobStatus.readyForUpload,
      UploadJobStatus.uploading,
    ].includes(this.status);
  }

  @computed
  get progress() {
    const completedItems = this.jobs.reduce(
      (sum, job) => sum + (job.status === UploadJobStatus.completed ? 1 : 0),
      0
    );
    const completedPercent =
      this.jobs.reduce((sum, job) => sum + job.progress.completedPercent, 0) /
      this.jobs.length;
    return {
      totalItems: this.jobs.length,
      completedItems,
      completedPercent,
    };
  }

  @computed
  get isActive() {
    return ![
      UploadJobStatus.completed,
      UploadJobStatus.cancelled,
      UploadJobStatus.failed,
    ].includes(this.status);
  }

  @computed
  get status() {
    const { status } = this.jobs.reduce<{
      completed: number;
      failed: number;
      cancelled: number;
      uploading: number;
      status: UploadJobStatus;
    }>(
      (result, job) => {
        if (job.status === UploadJobStatus.failed) {
          result.failed += 1;
        }
        if (job.status === UploadJobStatus.completed) {
          result.completed += 1;
        }
        if (job.status === UploadJobStatus.cancelled) {
          result.cancelled += 1;
        }
        if (job.status === UploadJobStatus.uploading) {
          result.uploading += 1;
        }
        if (result.completed === this.jobs.length) {
          result.status = UploadJobStatus.completed;
        }
        if (result.cancelled > 0) {
          result.status = UploadJobStatus.cancelled;
        }
        if (result.failed > 0) {
          result.status = UploadJobStatus.failed;
        }
        if (result.uploading > 0) {
          result.status = UploadJobStatus.uploading;
        }
        return result;
      },
      {
        completed: 0,
        failed: 0,
        cancelled: 0,
        uploading: 0,
        status: UploadJobStatus.created,
      }
    );
    return status;
  }
}
