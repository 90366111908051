import pick from "lodash/pick";
import flatMap from "lodash/flatMap";
import { MessageDescriptor } from "react-intl";
import { commonTexts } from "@web/translations";
import { ChangeTagEvent, ClassModel, entryStatusColorTexts } from ".";

export interface CustomFilterModel {
  uuid: string;
  title: MessageDescriptor;
  values: { label: MessageDescriptor; value: string }[];
}

export type FilterModel = CustomFilterModel | ClassModel;

interface CustomFilterChangeEvent {
  selected: boolean;
  filterId: string;
  value: string;
}

export interface SelectedCustomFilterValues {
  [label: string]: string[];
}

export function customFilterValuesFromString(
  values: string
): SelectedCustomFilterValues | undefined {
  try {
    return JSON.parse(values);
  } catch {
    return undefined;
  }
}

export function customFilterValuesToString(
  values: SelectedCustomFilterValues
): string {
  if (Object.keys(values).length === 0) {
    return "";
  }
  return JSON.stringify(values);
}

export type SelectedTagStatusMap = Map<UUID, Set<number>>;

export type FilterChangeEvent = CustomFilterChangeEvent | ChangeTagEvent;

export function isCustomFilterChange(
  filter: FilterChangeEvent
): filter is CustomFilterChangeEvent {
  return filter.hasOwnProperty("filterId");
}

export function isClassFilterChange(
  filter: FilterChangeEvent
): filter is ChangeTagEvent {
  return filter.hasOwnProperty("classification");
}

export const ENTRY_STATUS_FILTER: CustomFilterModel = {
  uuid: "Entry Status",
  title: commonTexts.color,
  values: [
    { label: entryStatusColorTexts.RED, value: "RED" },
    { label: entryStatusColorTexts.ORANGE, value: "ORANGE" },
    { label: entryStatusColorTexts.GREEN, value: "GREEN" },
  ],
};

export function isClassFilter(filter: FilterModel): filter is ClassModel {
  return filter.hasOwnProperty("tags");
}

export function isCustomFilter(
  filter: FilterModel
): filter is CustomFilterModel {
  return filter.hasOwnProperty("values");
}

// Get selected values for a set of custom filters
export function combineSelectedValues(
  selectedCustomValues: SelectedCustomFilterValues,
  filters: CustomFilterModel[] | undefined
): string[] {
  if (!filters) return [];
  return flatMap(
    pick(
      selectedCustomValues,
      filters.map((f) => f.uuid)
    ),
    (values) => values
  );
}

// Temporary helper function
export function applyChangeToSelectedFilters(
  change: FilterChangeEvent,
  existing: SelectedCustomFilterValues
) {
  const cpy = { ...existing };
  const identifier = isClassFilterChange(change)
    ? change.classificationId
    : change.filterId;
  const value = isClassFilterChange(change) ? change.tag.title : change.value;
  const { selected } = change;
  const updatedValues = cpy[identifier]?.slice() ?? [];
  if (selected && !updatedValues.includes(value)) {
    updatedValues.unshift(value);
  } else if (!selected && updatedValues.includes(value)) {
    updatedValues.splice(updatedValues.indexOf(value), 1);
  }
  if (updatedValues.length === 0) {
    delete cpy[identifier];
  } else {
    cpy[identifier] = updatedValues;
  }
  return cpy;
}
