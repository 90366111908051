import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { vars } from "@web/styles";
import { DMFormattedDate, Separator, ProfileImage } from "@web/elements";
import { MessageWithValues } from "@web/translations";
import {
  EntryModel,
  ChangelogEventModel,
  CollapsedEventsModel,
  isSystemAccount,
  isEntryStatusChanged,
  isEntryTagsChanged,
  isEntryAttributeListValuesChanged,
  canUpdate,
} from "@web/models";

import { SingleEvent } from "./SingleEvent";
import { TagsChangedEvent } from "./TagsChangedEvent";
import { AttributeValueEvent } from "./AttributeValueEvent";
import { EntryStatusChangedEvent } from "./EntryStatusChangedEvent";
import { CommentEvent } from "./CommentEvent";
import { ListAttributeValueEvent } from "./ListAttributeValueEvent";

export const HistoryPane: React.FC<{
  entry: EntryModel;
}> = observer(({ entry }) => {
  if (entry === undefined) {
    return null;
  }

  if (entry.isDraft) {
    return null;
  }
  return (
    <>
      <div>
        <ChangeLogEvent
          authorId={
            entry.createdBy /* TODO: entry.createdByUserId after having migrated entry data retrieval */
          }
          authorName={entry.createdBy}
          date={new Date(entry.createdDate)}
          text={texts.createdEntry}
        />
      </div>

      <Separator />

      <ChangelogEventList entry={entry} />
    </>
  );
});

const ChangelogEventList: React.FC<{ entry: EntryModel }> = observer((p) => {
  const { changelog } = p.entry;
  if (p.entry.changelog.status === "NOT_REQUESTED") {
    return null;
  }
  return (
    <_eventListWrap>
      {changelog.status === "LOADING" && (
        <p>
          <FormattedMessage
            id="entry.historypane.loading"
            defaultMessage="Loading history…"
          />
        </p>
      )}
      {changelog.status === "ERROR" && (
        <p>
          <em>
            <FormattedMessage
              id="entry.historypane.loading.error"
              defaultMessage="Could not retrieve history."
            />
          </em>
        </p>
      )}
      {changelog.status === "SUCCESS" &&
        changelog.result.map((event, _, changelogResults) => {
          if (event.isCollapsed) {
            return <CollapsedEvents key={event.id} event={event} />;
          } else if (event.type === "Comment") {
            return (
              <CommentEvent
                key={event.id}
                event={event}
                allEvents={changelogResults}
                canUpdateEntry={p.entry.canUpdate}
              />
            );
          } else if (event.type === "AttributeValue") {
            return (
              <AttributeValueEvent
                key={event.id}
                event={event}
                canUpdateEntry={p.entry.canUpdate}
              />
            );
          } else if (isEntryStatusChanged(event)) {
            return (
              <EntryStatusChangedEvent
                key={event.id}
                event={event}
                canUpdateEntry={p.entry.canUpdate}
              />
            );
          } else if (isEntryTagsChanged(event)) {
            return <TagsChangedEvent key={event.id} event={event} />;
          } else if (isEntryAttributeListValuesChanged(event)) {
            return (
              <ListAttributeValueEvent
                key={event.id}
                event={event}
                canUpdateEntry={p.entry.canUpdate}
              />
            );
          } else {
            return <SingleEvent key={event.id} event={event} />;
          }
        })}
    </_eventListWrap>
  );
});

const CollapsedEvents: React.FC<{
  event: CollapsedEventsModel;
}> = ({ event }) => {
  const textWithCollapsedCount = {
    ...textForEvent(event),
    values: {
      collapsedCount: event.events.length,
    },
  };

  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.newestEventTime}
      text={textWithCollapsedCount}
    />
  );
};

export const ChangeLogEvent: React.FC<{
  authorName: string;
  authorId: string;
  date: Date;
  text?: MessageWithValues;
}> = (p) => {
  const intl = useIntl();
  const fullDateText = intl.formatDate(p.date, {
    year: "numeric",
    month: "2-digit",
    weekday: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const authorName = isSystemAccount(p.authorId) ? "Docubot" : p.authorName;
  return (
    <_eventWrap>
      <_eventImage role="presentation">
        <ProfileImage name={p.authorName} id={p.authorId} size="medium" />
      </_eventImage>
      <_eventBody>
        <_eventDate dateTime={fullDateText}>
          <DMFormattedDate value={p.date} />
        </_eventDate>
        <_eventAuthor>{authorName}</_eventAuthor>
        {p.text && (
          <_eventText>
            {" " /* needed for testing */}
            <FormattedMessage {...p.text} />
          </_eventText>
        )}
        {" " /* needed for testing */}
        <div>{p.children}</div>
      </_eventBody>
    </_eventWrap>
  );
};

export function textForEvent(
  event: ChangelogEventModel
): MessageWithValues | undefined {
  if (event.isCollapsed) {
    const name =
      `${event.type}_${event.eventType}_collapsed` as TextNameForEvent;
    return textByEvent[name];
  } else if (event.eventType === "MOD") {
    if (
      event.type === "Entry" &&
      "title" in event.changed &&
      !Array.isArray(event.changed.title)
    ) {
      const newValue = event.changed.title.newValue;

      const text =
        newValue === null
          ? textByEvent.Entry_MOD_titleCleared
          : textByEvent.Entry_MOD_titleSet;

      return {
        ...text,
        values: { newValue: newValue ?? "" },
      };
    }

    return undefined;
  }

  const name = `${event.type}_${event.eventType}` as TextNameForEvent;
  return textByEvent[name];
}

/**
 * The texts definition below gives us fine grained control of what types of
 * events we want to render to the end-user.
 *
 * It's thought to be more important for us to give a good and tailored description
 * to what has happened rather than aiming for as few re-usable translations texts.
 *
 * Also meant to give us a way to avoid rendering certain changelog events if there
 * are events that makes little sense to end-users. It also gracefully handles new
 * types of changelog events added by the backend in the future, as a proactive
 * step to avoid react-intl explosions when told to render un-mapped event types,
 * or weird results if we had used generic re-usable texts.
 */
const textByEvent = defineMessages({
  Comment_ADD: {
    id: "entry.historypane.event.comment.added",
    defaultMessage: "commented",
  },
  Document_ADD: {
    id: "entry.historypane.event.document.added",
    defaultMessage: "added <em>{title}</em>",
  },
  Document_ADD_collapsed: {
    id: "entry.historypane.event.document.added.collapsed",
    defaultMessage: "added {collapsedCount} documents",
  },
  Document_DEL: {
    id: "entry.historypane.event.document.deleted",
    defaultMessage: "deleted <em>{title}</em>",
  },
  Document_DEL_collapsed: {
    id: "entry.historypane.event.document.deleted.collapsed",
    defaultMessage: "deleted {collapsedCount} documents",
  },
  Entry_MOD_titleSet: {
    id: "entry.historypane.event.entry.modified.title.set",
    defaultMessage: "changed title to <em>{newValue}</em>",
  },
  Entry_MOD_titleCleared: {
    id: "entry.historypane.event.entry.modified.title.cleared",
    defaultMessage: "cleared title",
  },
});

type TextNameForEvent = keyof typeof textByEvent;

const texts = defineMessages({
  createdEntry: {
    id: "entry.historypane.createdentry",
    defaultMessage: "created folder",
  },
});

const _eventListWrap = styled.div`
  position: relative;
`;

const _eventWrap = styled.article`
  display: grid;
  grid-template-columns: 3rem auto;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.44rem;
  position: relative;

  :not(:last-child)::after {
    display: block;
    content: "";
    border-left: 4px dotted ${vars.secondaryAltLight10};
    position: absolute;
    top: 3rem;
    bottom: -3rem;
    left: 1.875rem;
  }
`;

const _eventImage = styled.div`
  margin-top: 1.25rem;
  z-index: 1;
`;

const _eventBody = styled.div`
  overflow: hidden;
`;

const _eventDate = styled.time`
  display: block;
  color: ${vars.dark55};
  text-transform: capitalize;
`;

const _eventAuthor = styled.div`
  align-items: center;
  font-weight: 600;
`;

const _eventText = styled.div`
  word-break: break-word;
`;
