import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { vars } from "@web/styles";

export const DatePickerWrap = styled.div<{ inline?: boolean }>`
  display: ${(p) => (p.inline ? "flex" : "inline-block")};
  flex-direction: column;
  flex: 1;
  align-items: center;
  z-index: 3;

  > div {
    flex: 1;
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${vars.content};
    border: 0;
    height: 100%;
    ${(p) => !p.inline && `box-shadow: ${vars.shadow.z3};`}
  }

  .react-datepicker-wrapper {
    width: 125px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    padding-top: 0;
    background: transparent;
    border-bottom: solid 1px ${vars.dark05};
  }
  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background: ${vars.primary};
    color: ${vars.primaryFg};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    background: ${vars.primary};
    color: ${vars.primaryFg};
  }

  .react-datepicker__day,
  .react-datepicker__day:hover,
  .react-datepicker__month-text,
  .react-datepicker__month-text:hover {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    font-weight: 400;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    background: ${vars.primary};
    color: ${vars.primaryFg};
  }

  .react-datepicker__day--outside-month {
    opacity: 0.5;
  }

  .react-datepicker__day-name {
    opacity: 0.5;
  }

  /******************TIME PICKER STYLES*****************/
  .react-datepicker__time-container {
    width: auto;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 100px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: auto;
    height: 100px;
  }
`;
