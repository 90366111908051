import React, {
  createRef,
  ForwardedRef,
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ellipsis from "polished/lib/mixins/ellipsis";
import random from "lodash/random";
import {
  AttributeModel,
  AttributeValueModel,
  ClassModel,
  DocumentModel,
  EntryModel,
  TagModel,
  UploadGroup,
} from "@web/models";
import { VersionTag } from "@web/elements/VersionTag";
import { vars } from "@web/styles";
import { observer } from "mobx-react";
import { useStores } from "@web/stores/context";
import { DocumentThumbnail } from "@web/components/DocumentCard/DocumentThumbnail";
import {
  DMFormattedDate,
  Label,
  ProfileImage,
  SkeletonBox,
  SkeletonText,
} from "@web/elements";
import { DocumentSignatureBadge } from "@web/components/Document/DocumentSignatureBadge";
import { Position } from "./AttributeTooltip";
import { generateEntryUrl, generateDocumentUrl } from "@web/utils/URLHelpers";
import { EntryStatus } from "@web/components/EntryStatus";
import { HighlightText, CheckMark } from "@web/elements";
import { commonTexts } from "@web/translations";
import { NotificationsIcon } from "@web/elements/Icons";

interface ResultListItemRef {
  readonly columnWidths: Array<number | undefined>;
}

export interface AttributeHover {
  title: string;
  value: string;
  position: Position;
}

export interface Props {
  isSelected: boolean;
  entry: EntryModel;
  uploadGroup?: UploadGroup;
  isGlobalSearch?: boolean;
  isSelectable: boolean;
  onEntryClick: (entry: EntryModel, expand?: boolean) => void;
  onDocumentClick: (document: DocumentModel, entry: EntryModel) => void;
  onSelect: (entry: EntryModel, select: boolean) => void;
  onAttributeHover: (attribute: AttributeHover | undefined) => void;
}

const THUMBNAIL_SIZE = 36;

export const ResultsListItem = observer(
  forwardRef((p: Props, ref: ForwardedRef<ResultListItemRef>) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { filterStore, currentSearchURL } = useStores();
    const firstDoc =
      p.entry.documentCount >= 1 ? p.entry.documents[0] : undefined;

    // Only show documents if using free-text search
    const visibleDocs = p.entry.documents.slice(
      0,
      p.entry.totalMatchingDocuments ?? 0
    );

    useEffect(() => {
      if (p.entry.documentCount === 1) {
        p.entry.documents[0].pollForPreviewUpdates();
      }
    }, [p.entry.documentCount]);

    const classifications = filterStore.classFilters.map((classification) => {
      const tags = p.entry.tags.get(classification.uuid);
      const selectedTagTitles = tags.filter((tag) =>
        filterStore.selectedTags.has(classification.uuid, tag.title)
      );
      return {
        classification,
        tags,
        selectedTagTitles,
      };
    });

    const columnRefs = useRef<RefObject<HTMLDivElement>[]>(
      Array.from({ length: classifications.length + 1 }).map(() => createRef())
    );

    useImperativeHandle(ref, () => ({
      get columnWidths() {
        return columnRefs.current?.map(
          (col) => col.current?.getBoundingClientRect().width
        );
      },
    }));

    const handleEntryClick = (e: React.MouseEvent) => {
      if (e.metaKey || e.ctrlKey || e.shiftKey) {
        return;
      }

      e.preventDefault();
      p.onEntryClick(p.entry);
    };

    const handleDocumentClick = (
      e: React.MouseEvent,
      document: DocumentModel
    ) => {
      if (e.metaKey || e.ctrlKey || e.shiftKey) {
        return;
      }

      e.preventDefault();
      p.onDocumentClick(document, p.entry);
    };

    const handleTagClick = (
      e: React.MouseEvent,
      classification: ClassModel,
      tag: TagModel
    ) => {
      e.preventDefault();
      e.stopPropagation();

      const isSelected = filterStore.selectedTags.has(
        classification.uuid,
        tag.title
      );

      filterStore.selectedTags.update({
        tag,
        selected: !isSelected,
        classificationId: classification.uuid,
      });

      navigate(currentSearchURL());
    };

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
      p.onSelect(p.entry, e.target.checked);
    };

    const handleAttributeMouseOver = (
      event: React.MouseEvent,
      title: string,
      value: string
    ) => {
      const element = event.currentTarget;
      const bounds = element.getBoundingClientRect();
      const scroll = document.scrollingElement?.scrollTop ?? 0;
      const position: Position = {
        x: bounds.x,
        y: bounds.y + bounds.height + scroll,
      };
      p.onAttributeHover({ title, position, value });
    };

    const attributesForTag = (
      tag: TagModel
    ): Array<{
      definition: AttributeModel;
      values: AttributeValueModel[];
    }> =>
      tag.attributes.flatMap((definition) => {
        const values = p.entry.attributeValues.getValueObjects({
          definitionId: definition.uuid,
          includeChanges: false,
        });
        if (values.length === 0) {
          return [];
        }
        return { definition, values };
      });

    return (
      <_entry classificationCount={classifications.length}>
        <_select data-selected={p.isSelected}>
          {!p.isGlobalSearch && (p.isSelectable || p.isSelected) && (
            <CheckMark
              disabled={!p.isSelectable}
              isSelected={p.isSelected}
              onSelect={handleSelect}
            />
          )}
        </_select>
        <Link to={generateEntryUrl(p.entry.id)} onClick={handleEntryClick}>
          <_thumbColumn>
            {!p.entry.showAsDocument ? (
              <EntrySvg />
            ) : (
              firstDoc && (
                <DocumentThumbnail
                  documentVersion={firstDoc.currentVersion}
                  size={THUMBNAIL_SIZE}
                />
              )
            )}
          </_thumbColumn>

          <_titleColumn ref={columnRefs.current[0]}>
            <span>
              {p.entry.showAsDocument ? (
                <HighlightText
                  fallback={firstDoc?.title}
                  highlights={firstDoc?.searchHighlights?.title}
                />
              ) : (
                <HighlightText
                  fallback={
                    p.entry.title ?? intl.formatMessage(commonTexts.untitled)
                  }
                  highlights={p.entry.searchHighlights.title}
                />
              )}
            </span>
            {p.entry.showAsDocument &&
              firstDoc &&
              firstDoc.currentVersion.versionNumber > 1 && (
                <VersionTag
                  shorthand
                  versionNumber={firstDoc.currentVersion.versionNumber}
                />
              )}
            {p.entry.showAsDocument && firstDoc && (
              <_signature>
                <DocumentSignatureBadge document={firstDoc} />
              </_signature>
            )}
            <_extras>
              <span>
                <RemindersIcon entry={p.entry} />
              </span>
              <span>
                <EntryStatus size={20} entry={p.entry} />
              </span>
            </_extras>
          </_titleColumn>

          {p.entry.showAsDocument &&
            (firstDoc?.searchHighlights?.fileContent ?? []).length > 0 && (
              <_contentHighlights>
                <HighlightText
                  highlights={firstDoc?.searchHighlights?.fileContent}
                />
              </_contentHighlights>
            )}

          {classifications.map(({ classification, tags }, index) => {
            return (
              <_classificationColumn
                key={p.entry.id + classification.id}
                ref={columnRefs.current[index + 1]}
              >
                {tags.map((tag) => (
                  <Label
                    key={tag.uuid}
                    title={intl.formatMessage(texts.tagToggle, {
                      tag: tag.title,
                    })}
                    toggled={
                      !filterStore.selectedTags.has(
                        classification.uuid,
                        tag.title
                      )
                    }
                    onClick={(e) => handleTagClick(e, classification, tag)}
                  >
                    <_tagTitle>{tag.title}</_tagTitle>
                    {attributesForTag(tag).map(({ definition, values }) => {
                      const value = values
                        .map((x) => x.formattedValue(intl))
                        .join(", ");
                      return (
                        <_attributeValues
                          key={definition.uuid}
                          onMouseOver={(event) =>
                            handleAttributeMouseOver(
                              event,
                              definition.name,
                              value
                            )
                          }
                        >
                          {value}
                        </_attributeValues>
                      );
                    })}
                  </Label>
                ))}
              </_classificationColumn>
            );
          })}

          <_dateColumn>
            <DMFormattedDate value={p.entry.createdDate} />
          </_dateColumn>

          <_userColumn>
            <ProfileImage name={p.entry.createdBy} size="small" />
          </_userColumn>
        </Link>

        {!p.entry.showAsDocument &&
          visibleDocs.map((document) => (
            <_document key={document.id}>
              <Link
                to={generateDocumentUrl(document.id)}
                onClick={(e) => handleDocumentClick(e, document)}
              >
                <_thumbColumn>
                  <DocumentThumbnail
                    documentVersion={document.currentVersion}
                    size={THUMBNAIL_SIZE}
                  />
                </_thumbColumn>

                <_titleColumn>
                  <span>
                    <HighlightText
                      fallback={document.title}
                      highlights={document.searchHighlights?.title}
                    />
                  </span>
                  {document.currentVersion.versionNumber > 1 && (
                    <VersionTag
                      shorthand
                      versionNumber={document.currentVersion.versionNumber}
                    />
                  )}
                </_titleColumn>

                {(document.searchHighlights?.fileContent ?? []).length > 0 && (
                  <_contentHighlights>
                    <HighlightText
                      highlights={document.searchHighlights?.fileContent}
                    />
                  </_contentHighlights>
                )}
              </Link>
            </_document>
          ))}
      </_entry>
    );
  })
);

export const ResultsListItemSkeleton = () => {
  return (
    <_entry classificationCount={0}>
      <a>
        <_thumbColumn>
          <SkeletonBox size={2} />
        </_thumbColumn>
        <_titleColumn>
          <SkeletonText length={random(5, 20)} />
        </_titleColumn>
      </a>
    </_entry>
  );
};

const RemindersIcon = (p: { entry: EntryModel }) => {
  const { reminders } = p.entry;

  if (reminders.status !== "SUCCESS" || reminders.result.length < 1) {
    return null;
  }

  const hasOverdue = reminders.result.some((r) => r.isOverdue);

  return (
    <NotificationsIcon
      style={{ width: 20, color: hasOverdue ? vars.danger : vars.contentFg }}
    />
  );
};

const EntrySvg = () => (
  <svg
    width="36"
    height="27"
    viewBox="0 0 36 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3C0 1.34314 1.34315 0 3 0H10.0909C11.7478 0 13.0909 1.34315 13.0909 3V3.51337H33C34.6569 3.51337 36 4.85651 36 6.51337V23.1818C36 24.8387 34.6569 26.1818 33 26.1818H3C1.34315 26.1818 0 24.8387 0 23.1818V3Z"
      fill="#D5D9FE"
    />
  </svg>
);

const _thumbColumn = styled.div`
  color: ${vars.secondaryAltDark20};
  display: grid;
  place-content: center;
  width: 36px;
  height: 36px;

  img {
    border: 1px solid ${vars.secondaryAltLight10};
    border-radius: 4px;
  }
`;

const _titleColumn = styled.h1`
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1em;

  > *:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > *:nth-child(2) {
    flex-shrink: 0;
  }
`;

const _signature = styled.span`
  display: flex;
  font-size: 1rem;
`;

const _extras = styled.span`
  display: grid;
  grid-template-columns: 1.5rem 1.5rem;
  place-content: center;
  gap: 0.5rem;
  margin-left: auto;
`;

const _classificationColumn = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  gap: 0.5rem;

  &::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const _tagTitle = styled.div`
  flex-shrink: 0;
`;

const _attributeValues = styled.div`
  flex-shrink: 0;
  background: ${vars.light95};
  color: ${vars.secondary};
  border-radius: 2rem;
  margin-left: 0.3rem;
  padding: 0.1rem 0.6rem;
`;

const _dateColumn = styled.time`
  color: ${vars.dark55};
  ${ellipsis()}
`;

const _userColumn = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const _entry = styled.article<{ classificationCount: number }>`
  background: ${vars.content};
  border-radius: 8px;
  border: 2px solid transparent;
  color: ${vars.contentFg};
  display: grid;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  overflow: hidden;
  position: relative;

  > a {
    color: inherit;
    display: grid;
    align-items: center;
    padding: 0.25rem 1rem 0.25rem 3rem;
    min-height: 3rem;
    column-gap: 1rem;
    row-gap: 3px;
    grid-template-columns:
      36px
      ${(p) =>
        Array.from({ length: p.classificationCount + 1 }).map(
          (x, i) => `var(--ResultsList-column-${i}, 1fr)`
        )}
      1fr
      30px;
  }

  :hover {
    border-color: ${vars.dark05};
  }
`;

const _select = styled.div`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  visibility: hidden;

  ${_entry}:hover &,
  ${_entry}:focus-within &,
  &[data-selected=true] {
    visibility: visible;
  }
`;

const _document = styled.article`
  padding-left: 2.25rem;
  margin: 0 0.25rem;
  border-radius: 4px;

  > a {
    color: inherit;
    display: grid;
    align-items: center;
    grid-template-columns: 36px 1fr;
    padding: 0.25rem 1rem 0.25rem 3.7rem;
    column-gap: 1rem;
    row-gap: 0;
  }

  :hover {
    background: ${vars.secondaryAltLight20};
  }

  :last-child {
    margin-bottom: 0.25rem;
  }

  & + & {
    border-top: 1px solid ${vars.secondaryAltLight10};
  }

  ${_entry} & {
    border: none;
  }
`;

const _contentHighlights = styled.div`
  font-size: 0.75rem;
  color: ${vars.greyLight20};
  opacity: 0.75;
  grid-row: 2 / 3;
  grid-column: 2 / -1;
  padding-bottom: 5px;
  ${ellipsis()}
`;

const texts = defineMessages({
  tagToggle: {
    id: "searchpage.results.tagToggle",
    defaultMessage: "Filter on {tag}",
  },
});
