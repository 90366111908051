import isNumber from "lodash/isNumber";
import { observable, computed, action, ObservableMap } from "mobx";
import { format, isValid } from "date-fns";
import { APIParameters } from "@web/api/Integration/types";
import { DATE_ONLY_FORMAT } from "@web/utils/dates";
import {
  FilterType,
  ATTRIBUTE_FILTER_OPTIONS,
  AttributeFilterParam,
} from "./AttributeFilterModel";

type AttributeID = number;
const ATTRIBUTE_REGEX = new RegExp(
  `((?:\\s|^)(\\d+)_(\\D{2})(?:\\[([^\\[\\]]+)\\])?)`,
  "g"
);

export class AttributeFilterMap {
  private map: ObservableMap<AttributeID, FilterType>;

  constructor() {
    this.map = observable.map();
  }

  @action.bound
  initFromURLparameter(param?: string) {
    this.clearAll(); // Just in case

    if (!param) {
      return;
    }

    const matches = param.matchAll(ATTRIBUTE_REGEX);
    if (matches === null) {
      return;
    }

    const filterOptions = Object.values(ATTRIBUTE_FILTER_OPTIONS).flat();

    for (const [_match, _group, attributeId, filterId, value] of matches) {
      const filterTemplate = filterOptions.find(
        (option) => option.id === filterId
      );
      if (!filterTemplate) {
        continue;
      }

      if (filterTemplate.type !== "relative" && !value) {
        continue;
      }

      const filter = { ...filterTemplate };

      if (filterTemplate.type === "relative") {
        this.map.set(Number(attributeId), filter);
      }

      if (filter.type === "range") {
        if (!value.includes("~")) {
          continue;
        }

        const [start, end] = value.split("~");
        if (!start && !end) {
          continue;
        }

        if (filterTemplate.attributeType === "Date") {
          if (!isValid(new Date(start)) || !isValid(new Date(end))) {
            continue;
          }
        }

        filter.value = start;
        filter.valueEnd = end;

        this.map.set(Number(attributeId), filter);
      }

      if (filter.type === "equals") {
        if (filterTemplate.attributeType === "Date") {
          if (!isValid(new Date(value))) {
            continue;
          }
          filter.value = new Date(value);
        } else {
          filter.value = value;
        }
        this.map.set(Number(attributeId), filter);
      }

      if (filter.type === "list") {
        const values = value.split("~");
        if (values.length === 0) {
          continue;
        }
        if (filterTemplate.attributeType === "DateList") {
          filter.value = values
            .map((v) => new Date(v))
            .filter(isValid)
            .map((d) => format(d, DATE_ONLY_FORMAT));
        } else if (filterTemplate.attributeType === "NumericList") {
          filter.value = values.map((v) => Number(v)).filter(isNumber);
        } else {
          filter.value = values;
        }

        this.map.set(Number(attributeId), filter);
      }
    }
  }

  @computed
  get isEmpty() {
    return this.map.size === 0;
  }

  @computed
  get size() {
    return this.map.size;
  }

  @computed
  get allAttributeIds(): number[] {
    return Array.from(this.map.keys());
  }

  /**
   * Used for the `attributes` URL query parameter.
   * - Format for 'equals': `attributeId_filterId[value]`
   * - Format for 'range': `attributeId_filterId[value1~value2]`
   * - Format for 'relative': `attributeId_filterId`
   * - Format for 'list': `attributeId_filterId[val1~val2~val3...]`
   */
  @computed
  get asURLParam(): string {
    if (this.isEmpty) {
      return "";
    }

    const params: String[] = [];
    for (const [attributeId, filter] of this.map) {
      const prefix = `${attributeId}_${filter.id}`;

      switch (filter.type) {
        case "equals":
          if (filter.attributeType === "Date") {
            params.push(
              `${prefix}[${format(new Date(filter.value), DATE_ONLY_FORMAT)}]`
            );
          } else {
            params.push(`${prefix}[${filter.value}]`);
          }
          break;
        case "range":
          if (filter.attributeType === "Date") {
            params.push(
              `${prefix}[${format(
                new Date(filter.value),
                DATE_ONLY_FORMAT
              )}~${format(new Date(filter.valueEnd), DATE_ONLY_FORMAT)}]`
            );
          } else {
            params.push(`${prefix}[${filter.value}~${filter.valueEnd}]`);
          }
          break;
        case "list":
          params.push(`${prefix}[${filter.value.join("~")}]`);
          break;
        case "relative":
          params.push(`${prefix}`);
      }
    }
    return params.join(" ");
  }

  @computed
  get asAPIParams(): AttributeFilterParam | undefined {
    if (this.isEmpty) {
      return undefined;
    }

    const query: string[] = [];
    const params: APIParameters = {};
    for (const [attributeId, filter] of this.map) {
      const prefix = `attributeValues.definition.${attributeId}=`;
      if (filter.type === "relative" || filter.type === "range") {
        query.push(`${prefix}[@${attributeId}S:@${attributeId}E]`);
        if (filter.attributeType === "Date") {
          params[`@${attributeId}S`] = format(
            new Date(filter.value),
            DATE_ONLY_FORMAT
          );
          params[`@${attributeId}E`] = format(
            new Date(filter.valueEnd),
            DATE_ONLY_FORMAT
          );
        } else {
          params[`@${attributeId}S`] = valueOrAsterixWhenEmpty(filter.value);
          params[`@${attributeId}E`] = valueOrAsterixWhenEmpty(filter.valueEnd);
        }
      } else if (filter.type === "list") {
        query.push(`${prefix}@${attributeId}`);
        params[`@${attributeId}`] = filter.value;
      } else {
        query.push(`${prefix}@${attributeId}`);
        if (filter.attributeType === "Date") {
          params[`@${attributeId}`] = format(
            new Date(filter.value),
            DATE_ONLY_FORMAT
          );
        } else {
          params[`@${attributeId}`] = String(filter.value);
        }
      }
    }

    return {
      query,
      params,
    };
  }

  get(attributeId: number): FilterType | undefined {
    return this.map.get(attributeId);
  }

  @action.bound
  set(attributeId: number, filter: FilterType) {
    this.map.set(attributeId, filter);
  }

  @action.bound
  delete(attributeId: number) {
    this.map.delete(attributeId);
  }

  @action.bound
  clearAll() {
    this.map.clear();
  }

  @action.bound
  clearSubset(attributeIds: number[]) {
    attributeIds.forEach((id) => this.map.delete(id));
  }
}

function valueOrAsterixWhenEmpty(value: string | Date) {
  return value === "" ? "*" : String(value);
}
