import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { AttributeModel, AttributeValueModel } from "@web/models";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";
import { commonTexts } from "@web/translations";

interface Props {
  attribute: AttributeModel;
  onDelete: () => void;
  onRename: () => void;
  onOptionsEdit: () => void;
}

export const AttributeMenu: React.FC<Props> = ({
  attribute,
  onDelete,
  onRename,
  onOptionsEdit,
}) => {
  const intl = useIntl();

  return (
    <Dropdown variation="light">
      <Menu>
        {attribute.canUpdate && (
          <>
            <MenuItem onClick={onRename}>
              {intl.formatMessage(texts.rename)}
            </MenuItem>
            {attribute.isListAttribute && (
              <MenuItem onClick={onOptionsEdit}>
                {intl.formatMessage(texts.editOptions)}
              </MenuItem>
            )}
          </>
        )}
        {attribute.canDelete && (
          <MenuItem danger onClick={onDelete}>
            {intl.formatMessage(commonTexts.delete)}
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

interface OptionProps {
  attributeOption: AttributeValueModel;
  onDelete: () => void;
  onRename: () => void;
}

export const AttributeOptionMenu: React.FC<OptionProps> = ({
  attributeOption,
  onDelete,
  onRename,
}) => {
  const intl = useIntl();

  return (
    <Dropdown variation="light">
      <Menu>
        {attributeOption.canUpdate && (
          <>
            <MenuItem onClick={onRename}>
              {intl.formatMessage(commonTexts.edit)}
            </MenuItem>
          </>
        )}
        {attributeOption.canDelete && (
          <MenuItem danger onClick={onDelete}>
            {intl.formatMessage(commonTexts.delete)}
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

const texts = defineMessages({
  rename: {
    id: "settings.attribute.menu.rename",
    defaultMessage: "Rename",
  },
  editOptions: {
    id: "settings.attribute.menu.editoptions",
    defaultMessage: "Edit list values",
  },
});
