import { useEffect } from "react";
import { IKeyboardStore, KeyboardEventHandler } from "@web/stores/types";

interface IProps {
  identifier: string;
  enabled: () => boolean;
  keyboardStore: IKeyboardStore;
  onArrowUp?: KeyboardEventHandler;
  onArrowDown?: KeyboardEventHandler;
  onArrowLeft?: KeyboardEventHandler;
  onArrowRight?: KeyboardEventHandler;
  onEnter?: KeyboardEventHandler;
  onSpacebar?: KeyboardEventHandler;
}

export function KeyboardNavigation({
  keyboardStore,
  onArrowUp,
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onEnter,
  onSpacebar,
  enabled,
  identifier,
}: IProps) {
  useEffect(() => {
    keyboardStore.installClient(identifier, enabled, {
      ArrowUp: onArrowUp,
      ArrowDown: onArrowDown,
      ArrowLeft: onArrowLeft,
      ArrowRight: onArrowRight,
      Enter: onEnter,
      " ": onSpacebar,
    });

    // Cleanup
    return () => keyboardStore.removeClient(identifier);
  }, []);

  return null;
}
