import React from "react";
import styled from "styled-components";
import { useFocusManager, useKeyboard } from "react-aria";
import { SearchSuggestionModel, SearchSuggestionType } from "@web/models";
import { FILE_TYPES } from "@web/stores";
import { vars } from "@web/styles";
import { Label } from "@web/elements";
import { ClockIcon } from "@web/elements/Icons";
import { SearchSuggestionButton } from "./SearchSuggestionButton";
import { SuggestedDocument } from "./SuggestedDocument";
import { SuggestedType } from "./SuggestedType";
import { SuggestedUser } from "./SuggestedUser";

interface Props {
  suggestions: SearchSuggestionModel;
  onClickSuggestion: (suggestion: SearchSuggestionType) => void;
  onEscapeFocus: () => void;
}

export const SearchSuggestions = (p: Props) => {
  const focusManager = useFocusManager();
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === "Escape" || /^[a-z]$/.test(e.key)) {
        p.onEscapeFocus();
      }
      if (e.key === "ArrowUp") {
        focusManager.focusPrevious({ wrap: true });
      }
      if (e.key === "ArrowDown") {
        focusManager.focusNext({ wrap: true });
      }
    },
  });

  return (
    <_wrap {...keyboardProps} role="list">
      <_group>
        {p.suggestions.history.slice(0, 3).map((item) => (
          <SearchSuggestionButton
            key={item.value}
            onClick={() => p.onClickSuggestion(item)}
          >
            <ClockIcon />
            {item.value}
          </SearchSuggestionButton>
        ))}
      </_group>

      <_group>
        {p.suggestions.documents.map((item) => (
          <SuggestedDocument
            key={item.document.id}
            data={item}
            onClick={() => p.onClickSuggestion(item)}
          />
        ))}
      </_group>

      <_group>
        {p.suggestions.users.map((item) => (
          <SuggestedUser
            key={item.value}
            data={item}
            onClick={() => p.onClickSuggestion(item)}
          />
        ))}
      </_group>

      <_group>
        {p.suggestions.filters.map((item) => (
          <SearchSuggestionButton
            key={item.tag.uuid}
            onClick={() => p.onClickSuggestion(item)}
          >
            <Label
              title={item.classification.title}
              values={[item.tag.title]}
            />
          </SearchSuggestionButton>
        ))}
      </_group>

      <_group>
        {FILE_TYPES.map((item) => (
          <SuggestedType
            key={item.value}
            data={item}
            onClick={() => p.onClickSuggestion(item)}
          />
        ))}
      </_group>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const _group = styled.div`
  border-top: 1px solid ${vars.dark05};
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;

  :empty {
    display: none;
  }
`;
