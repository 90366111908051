import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";
import { DocumentModel, DocumentVersionModel } from "@web/models";
import { vars } from "@web/styles";
import { FilePreview } from "@web/components/FilePreview";

interface IDocumentPreviewProps {
  document: DocumentModel;
  prevDocument?: DocumentVersionModel;
  nextDocument?: DocumentVersionModel;
  searchQuery?: string;
}

export const Preview: React.FC<IDocumentPreviewProps> = observer((p) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [[previewWidth, previewHeight], setPreviewSize] = useState([0, 0]);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      const width = previewRef.current?.getBoundingClientRect().width ?? 0;
      const height = previewRef.current?.getBoundingClientRect().height ?? 0;
      setPreviewSize([width, height]);
    }, 100);
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const version = p.document.currentVersion;

  return (
    <_wrap ref={previewRef}>
      {p.prevDocument && (
        <FilePreview
          version={p.prevDocument}
          maxWidth={previewWidth}
          maxHeight={previewHeight}
          preload
        />
      )}
      <FilePreview
        version={version}
        maxWidth={previewWidth}
        maxHeight={previewHeight}
        searchQuery={p.searchQuery}
      />
      {p.nextDocument && (
        <FilePreview
          version={p.nextDocument}
          maxWidth={previewWidth}
          maxHeight={previewHeight}
          preload
        />
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  background: ${vars.body};
  color: ${vars.bodyFg};
  display: grid;
  place-items: center center;
  position: relative;
  width: 100%;
  height: 100%;
`;
