import {
  readableColor,
  setLightness,
  shade,
  tint,
  transparentize,
} from "polished";
import { createCssVars } from "@web/styles/utils";

const color = {
  transparent: "transparent",
  white: "#ffffff",
  black: "#000000",
  darkWine: "#2b061f",
  wine: "#88134a",
  maroon: "#53153f",
  lightLavender: "#f6f4fb",
  lavender: "#d5d9fe",
  darkLavender: "#3b46c3",
  lightPurple: "#e5e8fd",
  darkGrey: "#7d7d7d",
  lightGrey: "#e1e1e1",
  lighterGrey: "#f1f2f3",
  whisper: "#f9f7fa",
  strawberry: "#DF0353",
  teal: "#00B8BD",
  tangerine: "#FFBD59",
  greenApple: "#d0f9e9",
  cream: "#F6EFD7",
};

export const palette = createCssVars({
  /* Body */
  body: color.whisper,
  bodyFg: color.darkWine,

  /* Content */
  content: color.white,
  contentFg: color.darkWine,

  /* Overlay */
  overlay: transparentize(0.3, color.black),
  overlayFg: color.white,

  /* Primary */
  primary: color.wine,
  primaryFg: color.white,
  primaryLight10: tint(0.1, color.wine),
  primaryDark10: color.maroon,
  primaryDark20: color.darkWine,

  /* Secondary */
  secondary: color.darkLavender,
  secondaryFg: color.white,
  secondaryLight10: tint(0.1, color.darkLavender),
  secondaryDark10: shade(0.1, color.darkLavender),

  secondaryAlt: color.lavender,
  secondaryAltFg: color.darkLavender,
  secondaryAltLight10: color.lightPurple,
  secondaryAltLight20: color.lightLavender,
  secondaryAltLight30: tint(0.5, color.lightLavender),
  secondaryAltDark10: shade(0.05, color.lavender),
  secondaryAltDark20: shade(0.1, color.lavender),

  /* Neutral grey */
  grey: setLightness(0.5, color.black),
  greyLight10: setLightness(0.6, color.black),
  greyLight20: setLightness(0.7, color.black),
  greyLight30: setLightness(0.8, color.black),

  /* Neutral light */
  light95: transparentize(0.05, color.white),
  light85: transparentize(0.15, color.white),
  light55: transparentize(0.45, color.white),
  light25: transparentize(0.75, color.white),
  light15: transparentize(0.85, color.white),

  /* Neutral dark */
  dark85: transparentize(0.15, color.black),
  dark75: transparentize(0.25, color.black),
  dark55: transparentize(0.45, color.black),
  dark35: transparentize(0.65, color.black),
  dark25: transparentize(0.75, color.black),
  dark15: transparentize(0.85, color.black),
  dark10: transparentize(0.9, color.black),
  dark05: transparentize(0.95, color.black),

  /* Other */
  note: color.cream,
  noteFg: readableColor(color.cream),

  info: color.lightLavender,
  infoFg: color.darkLavender,

  danger: color.strawberry,
  dangerFg: readableColor(color.strawberry),

  warning: color.tangerine,
  warningFg: color.darkWine,

  success: color.teal,
  successFg: readableColor(color.teal),

  highlight: color.greenApple,
  highlightFg: readableColor(color.greenApple),

  transparent: color.transparent,
});

export const DEFAULT_THEME = {
  ...palette,

  shadow: {
    z1: "0px 2px 4px rgba(0, 0, 0, 0.03)",
    z2: "0px 5px 15px rgba(0, 0, 0, 0.1)",
    z3: "0px 5px 24px rgba(0, 0, 0, 0.1)",
  },
};
