import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { EntryModel } from "@web/models";
import { Button, IconButton } from "@web/elements/Button";
import { vars } from "@web/styles";
import { IEntryNode } from "@web/api/Integration/types";
import { commonTexts } from "@web/translations";
import { Link } from "@web/elements";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { useStores } from "@web/stores/context";
import { LinkIcon } from "@web/elements/Icons";
import { CreateLinkDialog } from "./CreateLinkDialog";

export const LinksPane: React.FC<{
  entry: EntryModel;
  isTabSelected: boolean;
}> = observer(({ entry, isTabSelected }) => {
  const intl = useIntl();
  const { recordStore } = useStores();
  const [isCreateSelected, setIsCreateSelected] = useState(false);
  const [isDeleteSelected, setIsDeleteSelected] = useState(false);

  useEffect(() => {
    if (!isTabSelected) {
      setIsDeleteSelected(false);
    }
  }, [isTabSelected]);

  useEffect(() => {
    if (!entry.currentToLinks.length && !entry.currentFromLinks.length) {
      setIsDeleteSelected(false);
    }
  }, [entry.currentFromLinks.length, entry.currentToLinks.length]);

  if (entry === undefined || entry.isDraft) {
    return null;
  }

  const onCreateClick = () => setIsCreateSelected(true);
  const onCreateDialogClose = () => setIsCreateSelected(false);
  const onDeleteClick = () => setIsDeleteSelected(true);
  const onCancelDeleteClick = () => setIsDeleteSelected(false);

  const onDeleteAllClick = async () => {
    if (entry.currentToLinks.length || entry.currentFromLinks.length) {
      await recordStore.unlinkEntries(
        entry.uuid,
        entry.currentToLinks.map((linkedEntry) => linkedEntry.id),
        entry.currentFromLinks.map((linkedEntry) => linkedEntry.id)
      );
    }

    setIsDeleteSelected(false);
  };

  const getLinkedEntryTitle = (linkedEntry: IEntryNode) => {
    if (linkedEntry.title) {
      return linkedEntry.title;
    } else if (
      linkedEntry.isSingleDocumentEntry &&
      linkedEntry.documents.length
    ) {
      return linkedEntry.documents[0].title;
    } else {
      return intl.formatMessage(commonTexts.untitled);
    }
  };
  const renderEntry = (linkedEntry: IEntryNode, toEntry: boolean) => {
    const onDeleteEntryClick = async () => {
      if (toEntry) {
        await recordStore.unlinkEntries(entry.uuid, [linkedEntry.id], []);
      } else {
        await recordStore.unlinkEntries(entry.uuid, [], [linkedEntry.id]);
      }
      await entry.reloadLinks();
    };

    return (
      <_linkWrapper key={linkedEntry.id}>
        <_linkTitleAndIcon key={linkedEntry.id}>
          <LinkIcon />
          <Link
            label={getLinkedEntryTitle(linkedEntry)}
            to={generateEntryUrl(linkedEntry.internalIdentifier)}
          />
        </_linkTitleAndIcon>
        {isDeleteSelected && (
          <IconButton
            icon="DeleteLinkIcon"
            text={commonTexts.delete}
            onClick={onDeleteEntryClick}
            className="delete-link-btn"
            variant="default"
          />
        )}
      </_linkWrapper>
    );
  };

  const toLinks = entry.currentToLinks.map((linkedEntry) => {
    return renderEntry(linkedEntry, true);
  });

  const fromLinks = entry.currentFromLinks.map((linkedEntry) => {
    return renderEntry(linkedEntry, false);
  });

  return (
    <>
      <_buttonsWrapper>
        <Button
          variant="secondary"
          text={intl.formatMessage(texts.newLink)}
          onClick={onCreateClick}
        />
        {!isDeleteSelected && toLinks.length + fromLinks.length > 0 && (
          <Button
            variant="secondary"
            text={intl.formatMessage(texts.deleteLinks)}
            onClick={onDeleteClick}
          />
        )}
        <_buttonsOnTheRight>
          {isDeleteSelected && (
            <>
              <Button
                variant="default"
                text={intl.formatMessage(commonTexts.cancel)}
                onClick={onCancelDeleteClick}
              />
              <Button
                variant="primary"
                text={intl.formatMessage(texts.deleteAll)}
                onClick={onDeleteAllClick}
              />
            </>
          )}
        </_buttonsOnTheRight>
      </_buttonsWrapper>
      <_linkList>
        {toLinks}
        {fromLinks}
      </_linkList>
      {isCreateSelected && (
        <CreateLinkDialog sourceEntry={entry} onClose={onCreateDialogClose} />
      )}
    </>
  );
});

const _buttonsWrapper = styled.div`
  padding: 0.75rem;
  display: flex;
  gap: 8px;
  border-bottom: 2px solid ${vars.dark05};
`;

const _buttonsOnTheRight = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const _linkList = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const _linkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;

  button.delete-link-btn {
    background-color: transparent;
    svg {
      width: 14px;
      height: 16px;
    }
  }
`;

const _linkTitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    color: ${vars.secondary};

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    flex-shrink: 0;
  }
`;

const texts = defineMessages({
  newLink: {
    id: "entry.links.new",
    defaultMessage: "New link",
  },
  deleteLinks: {
    id: "entry.links.delete",
    defaultMessage: "Delete link(s)",
  },
  deleteAll: {
    id: "entry.links.delete.all",
    defaultMessage: "Delete all",
  },
});
