import React from "react";

export const SigningDocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6.96891 4.72462C7.35301 2.99079 9.34361 2.16626 10.8412 3.12066V3.12066C11.6798 3.6551 12.7522 3.6551 13.5908 3.12066V3.12066C15.0884 2.16626 17.079 2.99079 17.4631 4.72462V4.72462C17.6782 5.69552 18.4364 6.45378 19.4073 6.66886V6.66886C21.1412 7.05296 21.9657 9.04357 21.0113 10.5412V10.5412C20.4769 11.3798 20.4769 12.4521 21.0113 13.2907V13.2907C21.9657 14.7883 21.1412 16.7789 19.4073 17.163V17.163C18.4364 17.3781 17.6782 18.1364 17.4631 19.1073V19.1073C17.079 20.8411 15.0884 21.6657 13.5908 20.7112V20.7112C12.7522 20.1768 11.6798 20.1768 10.8412 20.7112V20.7112C9.34361 21.6656 7.35301 20.8411 6.96891 19.1073V19.1073C6.75382 18.1364 5.99556 17.3781 5.02467 17.163V17.163C3.29084 16.7789 2.4663 14.7883 3.42071 13.2907V13.2907C3.95514 12.4521 3.95515 11.3798 3.42071 10.5412V10.5412C2.4663 9.04357 3.29084 7.05296 5.02467 6.66886V6.66886C5.99556 6.45378 6.75382 5.69552 6.96891 4.72462V4.72462Z"
      fill="#3B46C3"
    />
    <circle cx="7.53646" cy="12" r="1.71029" fill="white" />
    <ellipse cx="12.2591" cy="12" rx="1.71029" ry="1.71029" fill="white" />
    <circle cx="17.0497" cy="12" r="1.71029" fill="white" />
  </svg>
);

SigningDocumentIcon.displayName = "SigningDocumentIcon";
