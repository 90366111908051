import { AttributeFilterParam, SelectedCustomFilterValues } from "@web/models";

export interface ResultParamModel {
  section: number | "all" | undefined;
  tags: number[][] | undefined;
  attributes: AttributeFilterParam | undefined;
  tagStatus: number[] | undefined;
  customFilters: SelectedCustomFilterValues | undefined;
  query: string;
  order: ResultOrderKey;
}

/**
 * Note: The "relevance-*" options mean:
 *  "sort by relevance, fall back to * when query is cleared"
 */
export type ResultOrderKey =
  | "newest"
  | "newest-document"
  | "oldest"
  | "alphabetical"
  | "relevance-newest"
  | "relevance-newest-document"
  | "relevance-oldest"
  | "relevance-alphabetical";

export interface ResultOrder {
  selected: ResultOrderKey;
  options: ResultOrderKey[];
}

export interface ResultModel {
  hasMore: boolean;
  isLoading: boolean;
  count: number;
  totalCount: number;
  totalCountType: "documents" | "entries";
  order: ResultOrder;
}

export interface ResultHighlightPart {
  id: string;
  text: string;
  isHighlighted: boolean;
}

export function parseResultOrderOrDefault(raw: string | null): ResultOrderKey {
  switch (raw) {
    case "newest":
      return "newest";
    case "newest-document":
      return "newest-document";
    case "oldest":
      return "oldest";
    case "alphabetical":
      return "alphabetical";
    case "relevance-alphabetical":
      return "relevance-alphabetical";
    case "relevance-newest":
      return "relevance-newest";
    case "relevance-newest-document":
      return "relevance-newest-document";
    case "relevance-oldest":
      return "relevance-oldest";
    default:
      return "newest";
  }
}

export function orderWithOptions(
  selected: ResultOrderKey,
  relevance: boolean
): ResultOrder {
  const options: ResultOrderKey[] = [
    "newest",
    "oldest",
    "alphabetical",
    "newest-document",
  ];
  if (relevance) {
    options.push(relevanceOrderFrom(selected));
  }
  return {
    selected,
    options,
  };
}

export function relevanceOrderFrom(order: ResultOrderKey): ResultOrderKey {
  switch (order) {
    case "newest":
      return "relevance-newest";
    case "newest-document":
      return "relevance-newest-document";
    case "oldest":
      return "relevance-oldest";
    case "alphabetical":
      return "relevance-alphabetical";
    case "relevance-newest":
    case "relevance-newest-document":
    case "relevance-oldest":
    case "relevance-alphabetical":
      return order;
    default: {
      let value: never;
      // Typescript should complain here if case is missed
      value = order; // eslint-disable-line prefer-const
      throw new Error("Invalid relevanceOrderFrom case. Value: " + value);
    }
  }
}

export function fallbackOrderFrom(order: ResultOrderKey): ResultOrderKey {
  switch (order) {
    case "relevance-newest":
      return "newest";
    case "relevance-newest-document":
      return "newest-document";
    case "relevance-oldest":
      return "oldest";
    case "relevance-alphabetical":
      return "alphabetical";
    case "newest":
    case "newest-document":
    case "oldest":
    case "alphabetical":
      return order;
    default: {
      let value: never;
      // Typescript should complain here if case is missed
      value = order; // eslint-disable-line prefer-const
      throw new Error("Invalid fallbackOrderFrom case. Value: " + value);
    }
  }
}
