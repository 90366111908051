import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { defineMessages } from "react-intl";
import {
  ClassModel,
  TagModel,
  TagSearchModel,
  SelectedTagsMap,
} from "@web/models";
import { AddValueBox, LazyList } from "@web/components";
import { useRowHeights } from "@web/components/LazyList/useRowHeights";
import { ActionMenu, FlexBox } from "@web/elements";
import { media } from "@web/styles/utils";
import { vars } from "@web/styles";
import { Button } from "@web/elements/Button";
import { TagRow } from "./TagRow";
import { Header } from "./Header";

interface Events {
  onDone: (shouldApply: boolean) => void;
}

interface Props extends Events {
  classification: ClassModel;
  selectedTags: SelectedTagsMap;
  tagSearch: TagSearchModel;
  checklistTags?: UUID[];
}

export const TagSelect = observer(
  ({
    classification,
    selectedTags,
    tagSearch,
    checklistTags,
    onDone,
  }: Props) => {
    const [addTag, setAddTag] = useState(false);
    const [scrollToIndex, setScrollTo] = useState<number>();
    const { getRowHeight, getRowHeightHandler, refreshRowSizes } =
      useRowHeights({
        spacing: 14,
        initialHeight: 30,
      });

    const selected = selectedTags.getTagTitles(classification.uuid, true);
    const indeterminate = selectedTags.getIndeterminateTagTitles(
      classification.uuid
    );
    const locked = selectedTags.lockedTags;

    useEffect(() => {
      tagSearch.setActiveClassification(classification.uuid);
      return () => {
        tagSearch.clear();
        classification.createTagState = undefined;
      };
    }, []);

    useEffect(() => {
      if (classification.createTagState?.tag) {
        const createdTag = classification.createTagState.tag.uuid;
        setScrollTo(
          classification.tags.findIndex((t) => t.uuid === createdTag)
        );
      } else {
        setScrollTo(undefined);
      }
    }, [classification.createTagState?.tag?.uuid]);

    const handleCancel = () => {
      selectedTags.clearUnsavedChanges();
      onDone(false);
    };

    const handleApply = () => {
      onDone(true);
    };

    const handleCheckboxChange = (tag: TagModel, selected: boolean) => {
      selectedTags.saveChange({
        classificationId: classification.uuid,
        tag,
        selected,
      });
    };

    const handleClearAllTags = () => {
      selectedTags.saveChangeToClearAll(classification.uuid, true);
    };

    const handleSelectTagOption = (option: TagModel) => {
      selectedTags.saveChange({
        classificationId: classification.uuid,
        selected: true,
        tag: option,
      });
      setScrollTo(classification.tags.findIndex((t) => t.uuid === option.uuid));
      setAddTag(false);
    };

    const handleCreateTag = async (doSave: boolean, title: string) => {
      if (doSave) {
        const createdTag = await classification.createTag(title);
        if (createdTag) {
          selectedTags.saveChange({
            classificationId: classification.uuid,
            tag: createdTag,
            selected: true,
          });
        }
      }

      setAddTag(false);
    };

    const renderTagRow = (index: number) => {
      const tags = tagSearch.matches || classification.tags;
      const tag = tags[index];

      if (!tag) {
        return <></>;
      }

      return (
        <TagRow
          tag={tag}
          key={tag.id}
          index={index}
          selected={selected.includes(tag.title)}
          isNew={tag.uuid === classification.createTagState?.tag?.uuid}
          isLocked={locked.includes(tag.uuid)}
          isIndeterminate={indeterminate?.includes(tag.title)}
          isPipelineCondition={checklistTags?.includes(tag.uuid)}
          onChange={handleCheckboxChange}
          onHeight={getRowHeightHandler(index)}
        />
      );
    };

    return (
      <>
        <_wrap>
          <Header
            title={classification.title}
            onClearAllClick={handleClearAllTags}
            onCloseClick={() => onDone(false)}
          />
          <_listWrap>
            <LazyList
              loadingStatus={classification.tagLoadingStatus}
              loadNextPage={classification.loadMoreTags}
              renderItem={renderTagRow}
              onQueryChange={tagSearch.setQuery}
              itemSize={getRowHeight}
              refreshRowSizes={refreshRowSizes}
              scrollTo={scrollToIndex}
            />
          </_listWrap>

          {classification.canCreateTags && (
            <_addLink>
              <Button
                variant="blank"
                icon="AddIcon"
                text={texts.addNewTag}
                onClick={() => setAddTag(true)}
              />
            </_addLink>
          )}

          <_actions>
            <ActionMenu
              direction="vertical"
              applyIsDisabled={!selectedTags.hasChanges}
              onApply={handleApply}
              onCancel={handleCancel}
            />
          </_actions>
        </_wrap>

        {addTag && (
          <_overlay>
            <AddValueBox
              type="tag"
              suggestedValue={tagSearch.query}
              saveText={texts.saveAndSelect}
              getSimilarValues={classification.verifyNewTagTitle}
              onOptionSelect={handleSelectTagOption}
              onValueSave={handleCreateTag}
              isSaving={classification.createTagState?.saving}
            />
          </_overlay>
        )}
      </>
    );
  }
);

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;

  ${media("desktop")} {
    width: 287px;
    height: 440px;
    border-radius: 3px;
  }

  ${media("compact")} {
    height: 100%;
  }
`;

const _listWrap = styled(FlexBox)`
  padding: 0px 11px 5px 11px;
  flex-direction: column;
  flex: 1;
`;

const _addLink = styled.div`
  border-bottom: 1px solid ${vars.dark05};
  border-top: 1px solid ${vars.dark05};
  padding: 0px 5px;
`;

const _actions = styled.div`
  padding: 10px;
`;

const _overlay = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const texts = defineMessages({
  addNewTag: {
    id: "tagselect.addnewtag",
    defaultMessage: "Add new tag",
  },
  saveAndSelect: {
    id: "tagselect.saveandselect",
    defaultMessage: "Save and select",
  },
});
