import type { FileWithPath } from "react-dropzone";

export type UploadRequest =
  | EntryUploadRequest
  | DocumentUploadRequest
  | VersionUploadRequest;

export enum UploadRequestType {
  entry = "entry",
  document = "document",
  version = "version",
}

export interface EntryUploadRequest {
  type: UploadRequestType.entry;
  files: UploadData[];
  sectionId: UUID;
  title: string | undefined;
  tags: UUID[];
  isSingleDocumentEntry?: boolean;
}

export interface DocumentUploadRequest {
  type: UploadRequestType.document;
  files: UploadData[];
  entryId: number;
  entryUuid: UUID;
}

export interface VersionUploadRequest {
  type: UploadRequestType.version;
  file: UploadData;
  documentId: number;
  documentUuid: UUID;
}

export interface UploadData {
  fileName: string;
  fileFormat: string;
  title: string;
  file: FileWithPath;
}

export enum UploadJobType {
  active = "active",
  stored = "stored",
}

export interface UploadGroupProgress {
  completedPercent: number;
  completedItems: number;
  totalItems: number;
}

// TODO: consider adding one more status after uploading
// Status is not changed to "completed" after the files are uploaded
export enum UploadJobStatus {
  created = "created",
  waitingForEntry = "waitingForEntry",
  readyForUpload = "readyForUpload",
  uploading = "uploading",
  completed = "completed",
  failed = "failed",
  cancelled = "cancelled",
}

export interface UploadJobProgress {
  totalBytes: number;
  completedBytes: number;
  completedPercent: number;
}

export interface UploadJobResult {
  isDuplicated: boolean;
  checkSum: string;
  documentTitle: string;
  fileName: string;
  fileFormat: string;
  entryId: number | undefined;
  entryUuid: UUID | undefined;
  fileId: UUID | undefined;
  documentId: number | undefined;
  documentUuid: UUID | undefined;
  error: string | undefined;
}
