import React, { ChangeEvent, FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { commonTexts } from "@web/translations";
import { AttributeModel, AttributeValuesMapModel, TagModel } from "@web/models";
import { DMDatePicker } from "@web/components/DatePicker";
import { vars } from "@web/styles";
import { ActionMenu, Input } from "@web/elements";
import { AttributeReminder } from "@web/stores";
import { SetReminder } from "./SetReminder";

interface Events {
  onDone: () => void;
}

interface Props extends Events {
  tag?: TagModel;
  attribute: AttributeModel;
  attributeValues: AttributeValuesMapModel;
  disableAdditionalFields?: boolean;
}

export const SetAttribute: FC<Props> = observer(
  ({ attribute, attributeValues, disableAdditionalFields, tag, onDone }) => {
    const intl = useIntl();

    const { type, name } = attribute;
    const value = attributeValues.getSingleValue({
      definitionId: attribute.uuid,
      includeChanges: false,
    });

    const valueObject = attributeValues.getSingleValueObject({
      definitionId: attribute.uuid,
      includeChanges: false,
    });

    const [input, setInput] = useState(value?.toString() || "");
    const [comment, setComment] = useState("");
    const [reminder, setReminder] = useState<AttributeReminder>();

    const handleDateSelect = (date: Date) => {
      attributeValues.updateSingleValue(
        attribute.uuid,
        date,
        comment,
        reminder
      );
      onDone();
    };

    const handleAddedComment = (event: ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    };

    const handleAddedReminder = (date: Date | undefined) => {
      if (date) {
        const title = `${tag?.title}: ${name}`;
        setReminder({ title, date });
      } else {
        setReminder(undefined);
      }
    };

    const handleInputSave = (e?: React.FormEvent) => {
      e?.preventDefault();
      const inputValue = type === "Numeric" ? Number(input) : input.trim();
      attributeValues.updateSingleValue(attribute.uuid, inputValue, comment);
      onDone();
    };

    const renderPlaceholder = () => {
      switch (type) {
        case "String":
          return intl.formatMessage(texts.stringPlaceholder);
        case "Numeric":
          return intl.formatMessage(texts.numericPlaceholder);
      }
    };

    const canSave = () => {
      const trimmed = input.trim();
      switch (type) {
        case "String":
          return trimmed.length > 0 && (value ? trimmed !== value : true);
        case "Numeric":
          return trimmed.length > 0 && (value ? Number(input) !== value : true);
        case "Date":
          return trimmed && trimmed.length > 0;
      }
    };

    const additionalFields =
      canSave() && !disableAdditionalFields ? (
        <div>
          <_commentField
            aria-label={intl.formatMessage(texts.addCommentPlaceholder)}
            value={comment}
            placeholder={intl.formatMessage(texts.addCommentPlaceholder)}
            onChange={handleAddedComment}
          />

          {type === "Date" && (
            <SetReminder
              referenceDate={new Date(input)}
              options={[1, 3, 6, 12]}
              defaultOption={3}
              onChange={handleAddedReminder}
            />
          )}
        </div>
      ) : undefined;

    return (
      <_wrap>
        <_header>
          <h3>{name}</h3>
          {valueObject && valueObject.updatedDate && (
            <_updatedDate>
              {intl.formatMessage(commonTexts.lastUpdated, {
                dateString: valueObject.lastUpdatedFormattedDate,
              })}
            </_updatedDate>
          )}
        </_header>
        {attribute.type === "Date" ? (
          <DMDatePicker
            inline
            initialDate={value?.toString()}
            onDateSelect={handleDateSelect}
            onClose={onDone}
            onChanged={(date: Date) => {
              setInput(date.toString());
            }}
            renderBeforeButtons={() => additionalFields}
          />
        ) : (
          <_form onSubmit={handleInputSave}>
            <Input
              aria-label={renderPlaceholder()}
              autoFocus
              type={type === "Numeric" ? "number" : "text"}
              value={input}
              placeholder={value?.toString() ?? renderPlaceholder()}
              onChange={(e) => setInput(e.target.value)}
            />
            {additionalFields}
            <ActionMenu
              direction="vertical"
              applyIsDisabled={!canSave()}
              onApply={handleInputSave}
              onCancel={onDone}
            />
          </_form>
        )}
      </_wrap>
    );
  }
);

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0.5rem;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  border-radius: 3px;
  min-width: 260px;
  min-height: 220px;
`;

const _header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px 15px 5px;
  border-bottom: 1px solid ${vars.dark05};

  h3 {
    padding: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    flex: 1;
  }
`;

const _updatedDate = styled.div`
  padding-top: 5px;
  font-style: italic;
  font-size: 0.9em;
  color: ${vars.secondaryAltFg};
  opacity: 0.8;
`;

const _commentField = styled.input`
  padding: 8px;
  margin: 10px 0 10px 0;
  width: 100%;
  border-radius: 3px;
  color: ${vars.secondaryAltFg};
  border-color: ${vars.transparent};
  background-color: ${vars.secondaryAltLight20};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${vars.dark55};
  }
`;

const _form = styled.form`
  display: flex;
  padding: 15px 10px 5px 10px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const texts = defineMessages({
  stringPlaceholder: {
    id: "entry.attributes.placeholder.string",
    defaultMessage: "Add text…",
  },
  numericPlaceholder: {
    id: "entry.attributes.placeholder.numeric",
    defaultMessage: "Add number…",
  },
  addCommentPlaceholder: {
    id: "entry.attributes.comment.placeholder",
    defaultMessage: "Add comment (optional)",
  },
});
