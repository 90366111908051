import { ConfirmDialog } from "@web/components/ConfirmDialog";
import { Button } from "@web/elements/Button";
import { SingleEventModel } from "@web/models";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { texts } from "./CommentEvent";

interface Props {
  commentEvent: SingleEventModel;
  canUpdateEntry: boolean;
}

export const RelatedComment = observer(
  ({ commentEvent, canUpdateEntry }: Props) => {
    const intl = useIntl();
    const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);

    const deleteButton = commentEvent.comment
      ? commentEvent.comment.isCreatedByCurrentUser &&
        canUpdateEntry && (
          <>
            <_commentDelete>
              <Button
                variant="blank"
                text={commonTexts.delete}
                aria-label={intl.formatMessage(texts.deleteCommentButton)}
                onClick={() => setDisplayDeleteDialog(true)}
              />
            </_commentDelete>
            {displayDeleteDialog && (
              <ConfirmDialog
                message={texts.deleteComment}
                confirmText={commonTexts.delete}
                onClose={() => setDisplayDeleteDialog(false)}
                onConfirm={() => commentEvent.comment?.delete()}
              />
            )}
          </>
        )
      : [];

    return (
      <>
        <span>
          <FormattedMessage {...texts.changeLogCommentHeader} />
        </span>
        <_eventComment>{commentEvent.fields.text}</_eventComment>
        {deleteButton}
      </>
    );
  }
);

const _commentDelete = styled.div`
  button {
    color: ${vars.secondary};
  }
  button:hover {
    color: ${vars.primary};
  }
`;

const _eventComment = styled.blockquote`
  color: ${vars.greyLight10};
  word-break: break-word;
  margin: 0;
  padding-left: 0.5rem;
`;
