import React from "react";
import { PermissionType } from "@web/models/AccessControlModel";
import { observer } from "mobx-react";
import styled from "styled-components";
import { vars } from "@web/styles";
import { Permission, ServicePermission } from "@web/api/Integration/types";
import { Tooltip } from "@web/elements/Tooltip";

interface IProps<T extends PermissionType> {
  selectedPermissions: Set<T>;
  availablePermissions: T[];
  isDisabled: boolean;
  onChange?: (permissions: Set<T>) => void;
}

interface IToggleProps<T extends PermissionType> {
  permission: T;
  isSelected: boolean;
  isDisabled: boolean;
  onChange: (permission: T, isSelected: boolean) => void;
}

export const PermissionToggles = observer(
  <T extends PermissionType>({
    selectedPermissions,
    availablePermissions,
    isDisabled,
    onChange,
  }: IProps<T>) => {
    function handleChange(permission: T, isSelected: boolean) {
      const updated = new Set<T>(selectedPermissions);
      if (isSelected) {
        updated.add(permission);
      } else {
        updated.delete(permission);
      }
      onChange?.(updated);
    }

    return (
      <_wrap>
        {availablePermissions.map((permission) => (
          <Toggle
            key={permission}
            permission={permission}
            isSelected={selectedPermissions.has(permission)}
            isDisabled={isDisabled}
            onChange={handleChange}
          />
        ))}
      </_wrap>
    );
  }
);

const Toggle = observer(
  <T extends PermissionType>({
    permission,
    isSelected,
    isDisabled,
    onChange,
  }: IToggleProps<T>) => {
    const title = abbreviations[permission] ?? permission;
    return (
      <Tooltip textNode={permission}>
        <_toggleButton
          disabled={isDisabled}
          isSelected={isSelected}
          onClick={() => onChange(permission, !isSelected)}
        >
          {title}
        </_toggleButton>
      </Tooltip>
    );
  }
);

const _wrap = styled.div`
  display: flex;
`;

const _toggleButton = styled.button<{ isSelected: boolean }>`
  color: ${(p) => (p.isSelected ? vars.secondaryFg : vars.secondaryAltFg)};
  background: ${(p) => (p.isSelected ? vars.secondary : vars.secondaryAlt)};
  opacity: ${(p) => (p.isSelected ? 1 : 0.3)};
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-weight: 800;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  margin: 3px;
  border-radius: 3px;
  min-width: 23px;
  &:disabled {
    cursor: default;
  }
`;

const abbreviations: Record<Permission | ServicePermission, string> = {
  CreateChildren: "CC",
  Delete: "D",
  DeleteChildren: "DC",
  ReadChildren: "RC",
  ReadEntries: "RE",
  ReadObject: "RO",
  ModifyChildrenPermissions: "MCP",
  ModifyPermissions: "MP",
  Update: "U",
  UpdateChildren: "UC",
  UpdateChildrenSystemFields: "UCSF",
  UpdateSystemFields: "USF",
  UploadDocuments: "Upload",
  ChangeEntryStatus: "Status",
  ManageAccessGroups: "Groups",
  ManageFlows: "Flows",
};
