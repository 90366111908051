import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { texts } from "./texts";
import { Button } from "@web/elements/Button";

export const EditButton: React.FC<{
  applications: string[];
  disabled?: boolean;
  onClick: () => void;
}> = (p) => {
  const intl = useIntl();
  if (p.applications.length === 0) {
    return null;
  }

  const application = p.applications[0];
  let title = titles[application];
  if (!title) {
    console.warn(
      `Edit button title not found for application '${application}', using default title.`
    );
    title = texts.editWithDefault;
  }

  return (
    <Button
      text={title}
      variant="secondary"
      disabled={p.disabled}
      onClick={p.onClick}
    />
  );
};

const titles: Record<string, MessageDescriptor> = {
  "ms-word": texts.editWithWord,
  "ms-excel": texts.editWithExcel,
  "ms-powerpoint": texts.editWithPowerpoint,
};
