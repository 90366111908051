import React from "react";

export const SignatureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.01998 21.5604C8.14961 21.1156 7.58718 17.9162 7.32044 17.9162C7.05369 17.9162 6.72256 21.6853 5.01989 21.5604C3.31722 21.4356 3.5277 13.2845 3.37273 13.2339C3.21776 13.1832 3.13343 20.6376 1.00638 21.5604"
      stroke="#88134A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.5342 15.6884L14.5258 13.2703C14.589 13.2193 14.6768 13.2115 14.7479 13.2507L16.1575 14.0265C16.2309 14.0669 16.2713 14.1487 16.2589 14.2315L15.6952 17.9762C15.6874 18.0284 15.6592 18.0753 15.617 18.1068L11.3779 21.265C11.2403 21.3676 11.0459 21.2606 11.059 21.0894L11.4606 15.8287C11.4647 15.7738 11.4914 15.723 11.5342 15.6884Z"
      fill="#88134A"
      stroke="#88134A"
    />
    <circle
      cx="13.6632"
      cy="16.7068"
      r="1"
      transform="rotate(-15.7008 13.6632 16.7068)"
      fill="white"
    />
    <rect
      x="14.3013"
      y="11.6756"
      width="12.8684"
      height="3.96395"
      rx="0.5"
      transform="rotate(-60.8547 14.3013 11.6756)"
      fill="var(--svg-fill-fg, #88134A)"
    />
  </svg>
);

SignatureIcon.displayName = "SignatureIcon";
