import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useIntl, defineMessages } from "react-intl";
import { vars } from "@web/styles";
import {
  entryStatusColorTexts,
  EntryStatusModel,
  EntryStatusName,
} from "@web/models";
import { ActionMenu, RadioButton, StatusColor, Input } from "@web/elements";
import { commonTexts } from "@web/translations";
import { ENTRY_STATUS_COLORS } from "./types";

interface Props {
  initialStatus: EntryStatusName;
  statusOptions: EntryStatusModel[];
  editable: boolean;
  onStatusChange: (
    change: EntryStatusModel | undefined,
    comment?: string
  ) => void;
  onCloseClick: () => void;
}

export const EntryStatusSelect = observer((p: Props) => {
  const intl = useIntl();
  const initial = p.statusOptions.find((st) => st.name === p.initialStatus);
  const [selectedStatus, selectStatus] = useState(initial);
  const [comment, setComment] = useState("");

  const canSave = selectedStatus?.uuid !== initial?.uuid;

  const { statusOptions, editable } = p;

  const handleStatusChange = (status: EntryStatusModel | undefined) => {
    if (editable) {
      selectStatus(status);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCancel = () => {
    p.onCloseClick();
  };

  const handleSave = () => {
    const trimmed = comment.trim();
    p.onStatusChange(selectedStatus, trimmed);
    p.onCloseClick();
  };

  return (
    <_wrap>
      <h3>{intl.formatMessage(commonTexts.color)}</h3>
      <_content>
        {!editable && (
          <_infoMessage>{intl.formatMessage(texts.noAccess)}</_infoMessage>
        )}

        {statusOptions.map((status) => (
          <RadioButton
            disabled={!editable}
            key={status.uuid}
            name="color"
            label={status.label}
            value={status.uuid}
            checked={selectedStatus?.uuid === status.uuid}
            iconBefore={
              <StatusColor
                color={status.color}
                style={{ marginRight: "10px" }}
              />
            }
            onChange={() => handleStatusChange(status)}
          />
        ))}

        <RadioButton
          disabled={!editable}
          name="color"
          label={entryStatusColorTexts["NO-STATUS"]}
          value={"NO-STATUS"}
          checked={selectedStatus === undefined}
          iconBefore={
            <StatusColor
              color={ENTRY_STATUS_COLORS["NO-STATUS"]}
              style={{ marginRight: "10px" }}
            />
          }
          onChange={() => handleStatusChange(undefined)}
        />

        {canSave && (
          <Input
            autoFocus
            width="100%"
            value={comment}
            placeholder={intl.formatMessage(texts.addComment)}
            onChange={handleInputChange}
          />
        )}
      </_content>

      {p.editable && (
        <_content>
          <ActionMenu
            direction="vertical"
            onApply={handleSave}
            onCancel={handleCancel}
            applyIsDisabled={!canSave}
          />
        </_content>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  min-width: 285px;
  background-color: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  h3 {
    margin: 0;
    padding: 10px;
    font-size: 1.3em;
    font-weight: 300;
  }
`;

const _content = styled.div`
  padding: 10px;
  border-top: 2px solid ${vars.dark05};
  input[type="text"] {
    ::placeholder {
      font-size: 0.8em;
    }
  }
`;

const _infoMessage = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  background-color: ${vars.info};
  border-radius: 5px;
  color: ${vars.infoFg};
`;

const texts = defineMessages({
  addComment: {
    id: "entry.status.addcomment",
    defaultMessage: "Add a comment to the folder (optional)",
  },
  noAccess: {
    id: "entry.status.noaccess",
    defaultMessage: "You do not have access to edit the color.",
  },
});
