import React, { FC } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { ViewType } from "@web/stores";
import { EntryModel } from "@web/models";
import { vars } from "@web/styles";
import { CloseButton } from "@web/elements/Button";
import { media } from "@web/styles/utils";
import { ResultsGridItem } from "./ResultsGrid/ResultsGridItem";
import { ResultViewProps } from "./types";
import { AttributeHover, ResultsListItem } from "./ResultsList/ResultsListItem";

interface BaseProps extends ResultViewProps {
  entries: EntryModel[];
  viewType: ViewType;
  onClose: () => void;
}

interface Grid extends BaseProps {
  viewType: "grid";
  colWidth: number;
  colSpan: number;
}

interface List extends BaseProps {
  viewType: "list";
  onAttributeHover: (attribute: AttributeHover | undefined) => void;
}

type Props = Grid | List;

export const RecentEntries: FC<Props> = observer((p) => {
  const intl = useIntl();
  if (p.entries.length === 0) {
    return null;
  }

  return (
    <_wrap>
      <_closeButton onClick={p.onClose} />
      <h2>{intl.formatMessage(texts.title)}</h2>
      {p.viewType === "grid" ? (
        <_grid>
          {p.entries.map((entry) => (
            <ResultsGridItem
              key={`entryNode_recent_${entry.id}`}
              entry={entry}
              colSpan={p.colSpan}
              colWidth={p.colWidth}
              scrollToItem={p.scrollToItem}
              isFocused={false}
              isSelected={p.selectedEntries.items.some(
                (e) => e.id === entry.id
              )}
              onEntryClick={p.onEntryClick}
              onSelect={p.onSelect}
              isGlobalSearch={p.isGlobalSearch}
              isSelectable={p.selectedEntries.canAddToSelection}
            />
          ))}
        </_grid>
      ) : (
        <>
          {p.entries.map((entry) => (
            <ResultsListItem
              key={`entryNode_recent_${entry.id}`}
              entry={entry}
              isSelected={p.selectedEntries.items.some(
                (e) => e.id === entry.id
              )}
              isSelectable={p.selectedEntries.canAddToSelection}
              onSelect={p.onSelect}
              onEntryClick={p.onEntryClick}
              onDocumentClick={p.onDocumentClick}
              onAttributeHover={p.onAttributeHover}
            />
          ))}
        </>
      )}
    </_wrap>
  );
});

const _closeButton = styled(CloseButton)`
  color: ${vars.dark55};
`;

const _grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 0;

  ${media("desktop")} {
    grid-template-columns: repeat(
      var(--ResultGrid-cols),
      var(--ResultGrid-colWidth)
    );
  }

  ${media("compact")} {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const _wrap = styled.div`
  background: ${vars.dark05};
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  margin: 1rem 0;

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    color: ${vars.dark55};
  }

  ${_closeButton} {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  ${_grid} {
    padding: 0;
    margin-top: 1rem;
  }
`;

const texts = defineMessages({
  title: {
    id: "searchpage.recententries.title",
    defaultMessage: "Recently moved",
  },
});
