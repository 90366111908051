import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import { RootStore } from ".";
import { CommentModel } from "@web/models";
import PubSub from "./PubSub";

export class CommentStore {
  constructor(
    private api: Api,
    private rootStore: RootStore,
    public loggedInUserId: UUID
  ) {}

  async delete(id: UUID): Promise<void> {
    try {
      await this.api.deleteComment(id);

      PubSub.getInstance().notifyDelete({ type: "Comment", uuid: id });
    } catch (e) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.createFailed,
      });
      console.error("Error while deleting comment", e);
    }
  }

  async create(comment: string, entryId: UUID): Promise<void> {
    try {
      await this.api.createComment(comment, entryId);
      PubSub.getInstance().notifyAdd({
        type: "Comment",
        data: new CommentModel(this, entryId, this.loggedInUserId),
      });
    } catch (e) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.createFailed,
      });
      console.error("Error while creating comment", e);
    }
  }
}

const texts = defineMessages({
  deleteFailed: {
    id: "comment.message.delete.failed",
    defaultMessage: "Could not delete comment",
  },
  createFailed: {
    id: "comment.message.create.failed",
    defaultMessage: "Could not add comment",
  },
});
