import React from "react";
import { Box, PopoverBox } from "../../elements";
import styled, { css } from "styled-components";
import { SectionModel } from "../../models/SectionsModel";
import { observer } from "mobx-react";
import { Truncate } from "@web/elements";
import { FolderIcon } from "@web/elements/Icons";
import { CloseButton } from "@web/elements/Button";
import { vars } from "@web/styles";
import { LazyList } from "@web/components";
import { DataLoadingModel } from "@web/models";
import { FormattedMessage } from "react-intl";
import { isMedia, media } from "@web/styles/utils";

const LAZY_LIST_PROPS = {
  itemSize: 35,
};

interface IEvents {
  onSelectSectionClick: (section: SectionModel) => void;
  onSectionPageNeeded: () => Promise<void>;
  onQueryChange?: (newQuery: string) => void;
  onCloseClick: () => void;
}

interface IProps extends IEvents {
  triggerRef: React.RefObject<HTMLDivElement>;
  sections: SectionModel[];
  selectedSection: SectionModel | undefined;
  loadingStatus: DataLoadingModel;
}

@observer
export class SectionSelectorBox extends React.Component<IProps> {
  renderSectionRow = (index: number) => {
    const { sections, selectedSection, onSelectSectionClick } = this.props;
    const section = sections[index];
    return (
      <_sectionTitle
        selected={section === selectedSection}
        onClick={() => onSelectSectionClick(section)}
      >
        <Drawer />
        <Truncate maxWidth="90%">{section.title}</Truncate>
      </_sectionTitle>
    );
  };

  render() {
    const { onCloseClick, triggerRef } = this.props;

    return (
      <PopoverBox
        triggerRef={triggerRef}
        onClickOutside={onCloseClick}
        alignY="top"
      >
        <_wrap>
          {isMedia("compact") && (
            <_compactHeader>
              <CloseButton onClick={onCloseClick} />
              <Box grow="1">
                <FormattedMessage
                  id="searchpage.sectionselectorbox.section"
                  defaultMessage="Section"
                />
              </Box>
            </_compactHeader>
          )}

          <LazyList
            loadingStatus={this.props.loadingStatus}
            loadNextPage={this.props.onSectionPageNeeded}
            renderItem={this.renderSectionRow}
            onQueryChange={this.props.onQueryChange}
            {...LAZY_LIST_PROPS}
          />
        </_wrap>
      </PopoverBox>
    );
  }
}

const _wrap = styled.div`
  padding: 10px;
  display: flex;
  cursor: default;
  flex-direction: column;
  align-items: stretch;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  ${media("desktop")} {
    width: 287px;
    height: 440px;
  }
  ${media("compact")} {
    height: 100%;
  }
`;

const _sectionTitle = styled.div<{
  selected: boolean;
}>`
  padding: 6px 5px;
  line-height: 1em;
  cursor: pointer;
  ${(p) =>
    p.selected
      ? css`
          background: ${vars.primaryLight10};
          color: ${vars.primaryFg};
        `
      : css`
          background: ${vars.transparent};
          color: ${vars.primaryLight10};
        `};
`;

const _compactHeader = styled.div`
  display: flex;
  padding: 0px 5px 8px 5px;
  flex-grow: 0;
  text-align: center;
  font-size: 18px;
  align-items: center;
`;

const Drawer = styled(FolderIcon)`
  position: relative;
  top: 2px;
  margin-right: 6px;
`;
