import React, { RefObject } from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { vars } from "@web/styles";
import { SearchIcon } from "./Icons";

export const InputThemes: Record<string, FlattenSimpleInterpolation> = {
  search: css`
    background: ${vars.content};
    font-size: 16px;
    color: ${vars.contentFg};
    border: 1px solid ${vars.dark05};
    box-shadow: ${vars.shadow.z1};
    --svg-fill: ${vars.bodyFg};
  `,
  subtle: css`
    background: transparent;
    font-size: 16px;
    color: ${vars.contentFg};
    --svg-fill: ${vars.bodyFg};
  `,
  semiTransparent: css`
    color: inherit;
    background: rgba(255, 255, 255, 0.15);
    border: none;
    font-size: 16px;
    --svg-fill: inherit;
  `,
  filter: css`
    color: ${vars.primaryFg};
    background: ${vars.primaryDark10};
    border: none;
    font-size: 16px;
    opacity: 0.9;
    margin: 5px 0;
  `,
  default: css`
    color: ${vars.secondaryAltFg};
    border-color: ${vars.transparent};
    background-color: ${vars.secondaryAltLight20};
    border-radius: 3px;
    ::placeholder {
      color: ${vars.dark55};
    }
  `,
};

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  before?: React.ReactElement<any>;
  after?: React.ReactElement<any>;
  theme?: keyof typeof InputThemes;
  inputRef?: RefObject<HTMLInputElement>;
}

export const SearchInput: React.FC<IInput> = (props) => (
  <Input theme="search" type="search" before={<SearchIcon />} {...props} />
);

export const Input: React.FC<IInput> = ({
  theme = "default",
  before,
  after,
  width,
  inputRef,
  ...inputProps
}: IInput) => (
  <_wrap theme={theme} width={width}>
    {before && <_addon>{before}</_addon>}
    <_input type="text" {...inputProps} ref={inputRef} />
    {after && <_addon>{after}</_addon>}
  </_wrap>
);

const _wrap = styled.label<{ theme: keyof typeof InputThemes; width?: string }>`
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  padding: 0 0.5em;
  position: relative;
  width: ${(p) => p.width};
  max-width: 100%;
  ${(p) => InputThemes[p.theme]}
`;

const _input = styled.input`
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  height: 2.25em;
  padding: 0 0.5em;
  outline: none;
  width: 100%;
  flex: 1;

  ::placeholder {
    color: inherit;
    opacity: 0.6;
  }

  // Disable Edge native clear icon
  ::-ms-clear {
    display: none;
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button {
    display: none;
  }
`;

const _addon = styled.div`
  flex: 0;

  svg,
  img {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
  }
`;
