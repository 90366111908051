import { action, observable } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import { IReminderNode } from "@web/api/Integration/types";
import { RootStore } from ".";
import { EntryModel, ReminderModel } from "@web/models";

type ReminderUUID = UUID;

export class ReminderStore {
  @observable
  private reminderCache = new Map<ReminderUUID, ReminderModel>();

  constructor(private api: Api, private rootStore: RootStore) {}

  /**
   * Return a ReminderModel object from server data.
   */
  reminderFromJson = (json: IReminderNode): ReminderModel => {
    const existing = this.reminderCache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    }

    const reminder = new ReminderModel(this, json);
    this.reminderCache.set(reminder.uuid, reminder);
    return reminder;
  };

  /** A reminder was deleted, clean it from the client memory */
  @action.bound
  removeReminder(reminder: ReminderModel) {
    this.reminderCache.delete(reminder.uuid);
  }

  async createReminder(
    fields: Pick<ReminderModel, "date" | "title">,
    parentEntry: EntryModel
  ) {
    const { messageStore } = this.rootStore;

    try {
      const { data } = await this.api.createReminder(fields, parentEntry.uuid);
      parentEntry.addReminder(this.reminderFromJson(data.data));

      messageStore.addMessage({
        type: "success",
        title: texts.createReminderSuccess,
      });
    } catch (error) {
      console.error(
        `Creating Reminder for Entry #${parentEntry.uuid} failed!`,
        error
      );

      messageStore.addMessage({
        type: "failure",
        title: texts.createReminderFailed,
      });
    }
  }

  @action
  async updateReminder(
    reminder: ReminderModel,
    newFields: Pick<ReminderModel, "date" | "title">
  ) {
    const { messageStore } = this.rootStore;

    try {
      const { data } = await this.api.updateReminder(newFields, reminder.uuid);

      reminder.updateFromJson(data.data);

      messageStore.addMessage({
        type: "success",
        title: texts.updateReminderSuccess,
      });
    } catch (error) {
      console.error(`Updating Reminder #${reminder.uuid} failed!`, error);

      messageStore.addMessage({
        type: "failure",
        title: texts.updateReminderFailed,
      });
    }
  }

  @action
  async deleteReminder(reminder: ReminderModel, parentEntry: EntryModel) {
    const { messageStore } = this.rootStore;

    try {
      await this.api.deleteReminder(reminder.uuid);
      parentEntry.removeReminder(reminder.uuid);
      this.removeReminder(reminder);

      messageStore.addMessage({
        type: "success",
        title: texts.deleteReminderSuccess,
      });
    } catch (error) {
      console.error(`Deleting Reminder #${reminder.uuid} failed!`, error);

      messageStore.addMessage({
        type: "failure",
        title: texts.deleteReminderFailed,
      });
    }
  }
}

const texts = defineMessages({
  createReminderSuccess: {
    id: "request.reminder.create.success",
    defaultMessage: "New reminder added",
  },
  createReminderFailed: {
    id: "request.reminder.create.failed",
    defaultMessage: "Failed to add reminder",
  },
  updateReminderSuccess: {
    id: "request.reminder.update.success",
    defaultMessage: "Updated reminder",
  },
  updateReminderFailed: {
    id: "request.reminder.update.failed",
    defaultMessage: "Failed to update reminder",
  },
  deleteReminderSuccess: {
    id: "request.reminder.delete.success",
    defaultMessage: "Reminder deleted",
  },
  deleteReminderFailed: {
    id: "request.reminder.delete.failed",
    defaultMessage: "Could not delete reminder",
  },
});
