import React from "react";

export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 17.2501V21.0001H6.75L17.815 9.93506L14.065 6.18506L3 17.2501ZM20.705 7.04506C21.095 6.65506 21.095 6.02006 20.705 5.63006L18.37 3.29506C17.98 2.90506 17.345 2.90506 16.955 3.29506L15.125 5.12506L18.875 8.87506L20.705 7.04506Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

EditIcon.displayName = "EditIcon";
