import React from "react";

export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1679 10.3321C18.7979 9.70214 18.3517 8.625 17.4608 8.625H14.5C14.2239 8.625 14 8.40114 14 8.125V3.5C14 3.22386 13.7761 3 13.5 3H10.5C10.2239 3 10 3.22386 10 3.5V8.125C10 8.40114 9.77614 8.625 9.5 8.625H6.53921C5.64831 8.625 5.20214 9.70214 5.83211 10.3321L11.2929 15.7929C11.6834 16.1834 12.3166 16.1834 12.7071 15.7929L18.1679 10.3321ZM4 17.625C3.44772 17.625 3 18.0727 3 18.625V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V18.625C21 18.0727 20.5523 17.625 20 17.625H4Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
