import { defineMessage, MessageDescriptor } from "react-intl";
import { IColorStatusNode } from "@web/api/Integration/types";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";

export interface ColorStatusModel {
  uuid: UUID;
  id: number;
  name: string;
  color: keyof typeof TagStatusColor;
  isArchived: boolean;
}

export enum TagStatusColor {
  noColor = "#ccc",
  yellow = "#FFBD59",
  green = "#00B8BD",
  red = "#DF0353",
  blue = "#0967D5",
  purple = "#6800D0",
}

export const entryStatusColorTexts: Record<EntryStatusName, MessageDescriptor> =
  {
    RED: defineMessage({
      id: "entry.status.red",
      defaultMessage: "Red",
    }),
    ORANGE: defineMessage({
      id: "entry.status.orange",
      defaultMessage: "Orange",
    }),
    GREEN: defineMessage({
      id: "entry.status.green",
      defaultMessage: "Green",
    }),
    "NO-STATUS": defineMessage({
      id: "entry.status.noStatus",
      defaultMessage: "No color",
    }),
  };

export type EntryStatusName = "RED" | "ORANGE" | "GREEN" | "NO-STATUS";

export interface EntryStatusModel {
  name: EntryStatusName;
  uuid: UUID;
  color: string;
  label: MessageDescriptor;
}

export function parseEntryStatus(
  node: IColorStatusNode
): EntryStatusModel | undefined {
  if (!Object.keys(entryStatusColorTexts).includes(node.name)) {
    return undefined; // Unsupported entry status, ignore
  }
  const name = node.name as EntryStatusName;
  return {
    name,
    uuid: node.id,
    color: ENTRY_STATUS_COLORS[name],
    label: entryStatusColorTexts[name],
  };
}
