import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { TagModel } from "@web/models";
import { vars } from "@web/styles";
import { NewBadge } from "@web/elements";

interface Props {
  tag: TagModel;
}

export const TagRow: FC<Props> = observer(({ tag, children }) => {
  return (
    <_row title={tag.title}>
      <span>{tag.title}</span>
      {isRecent(tag) && <NewBadge />}
      {children}
    </_row>
  );
});

const _row = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 8px;
  justify-content: space-between;
  height: 100%;
  word-break: break-all;

  > span:first-of-type {
    flex-grow: 1;
    padding-right: 5px;
  }

  &:hover {
    background-color: ${vars.info};
    color: ${vars.contentFg};

    button {
      visibility: visible;
    }
  }

  button {
    visibility: hidden;
  }
`;

// TODO: move to tag domain object when possible
function isRecent(tag: TagModel) {
  return Date.now() - new Date(tag.createdDate).getTime() < 1000 * 60;
}
