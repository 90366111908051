import { observable, computed, action, ObservableMap } from "mobx";
import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import intersectionBy from "lodash/intersectionBy";
import differenceBy from "lodash/differenceBy";
import unionBy from "lodash/unionBy";
import {
  TagModel,
  SelectedTagsModel,
  ChangeTagEvent,
  ClassModel,
  PageLoadingModel,
  RequiredTagModel,
} from ".";

interface UnsavedTags {
  add: TagModel[];
  remove: TagModel[];
}

type ClassificationUUID = UUID;

/**
 * Custom map implementation used to hold selected tag values in filters, entries and when doing multiselect.
 * Exposes helper methods to:
 * - easily perform mutations on the data
 * - transform the data into other formats
 * - keep track of temporary changes to the data
 */
export class SelectedTagsMap {
  private tags: ObservableMap<ClassificationUUID, TagModel[]>;
  public selectedRequiredTag?: RequiredTagModel;
  public requiredTags: RequiredTagModel[] = [];
  public loadingInfo: PageLoadingModel;

  /**
   * Tags added when doing .replace - with an uneven occurrence.
   * Eg. [{ C: [T1, T2] }, { C: [T1] }] => { C: [T2] } (T2 is indeterminate)
   */
  private indeterminateTags: ObservableMap<string, TagModel[]>;

  @observable
  changes: UnsavedTags = {
    add: [],
    remove: [],
  };

  constructor(
    initialdata?: SelectedTagsModel,
    loadingStatus?: PageLoadingModel
  ) {
    this.tags = observable.map(initialdata);
    this.indeterminateTags = observable.map();
    this.loadingInfo = loadingStatus ?? {
      lastPageLoaded: 1,
      itemCount: this.tags.size,
      hasMore: false,
      pageLoading: false,
    };
  }

  @computed
  get isEmpty() {
    return this.tags.size === 0 || this.tagCount === 0;
  }

  @computed
  get hasChanges() {
    return this.changes.add.length > 0 || this.changes.remove.length > 0;
  }

  @computed
  get hasMore() {
    return this.loadingInfo.hasMore;
  }

  @computed
  get hasIndeterminateTags() {
    return this.indeterminateTags.size > 0;
  }

  @computed
  get tagCount() {
    let count = 0;
    for (const tags of this.tags.values()) {
      count += tags.length;
    }
    return count;
  }

  @computed
  get allClassificationIds(): string[] {
    return Array.from(this.tags.keys());
  }

  /**
   * Returns an array containing the tags for all classifications.
   */
  @computed
  get allTags(): TagModel[] {
    return flatten(Array.from(this.tags.values()));
  }

  /**
   * Returns an array containing the UUIDs for all tags in all classifications.
   */
  @computed
  get allTagIds(): UUID[] {
    return flatMap(Array.from(this.tags.values()), (tags) =>
      tags.map((t) => t.uuid)
    );
  }

  getGroupedTagIds(options: { exceptClassificationId?: UUID }): number[][] {
    const grouped: number[][] = [];
    for (const [classificationId, tags] of this.tags) {
      if (
        classificationId !== options.exceptClassificationId &&
        tags.length > 0
      ) {
        grouped.push(tags.map((tag) => tag.id));
      }
    }
    return grouped;
  }

  /**
   * Returns an array containing the UUIDs for all tags in all classifications + any required tag
   * that should be applied automatically.
   * Is used when creating a new Entry object with the integration API.
   */
  @computed
  get tagIdsForNewEntry(): UUID[] {
    const requiredTag = this.selectedRequiredTag
      ? [this.selectedRequiredTag.uuid]
      : [];
    return uniq(this.allTagIds.concat(requiredTag));
  }

  /**
   * Returns an array containing the UUIDs for locked tags. Currently we only
   * consider a tag as locked if it is the only required tag currently selected.
   */
  @computed
  get lockedTags(): UUID[] {
    if (this.requiredTags?.length === 0) {
      return [];
    }

    if (this.selectedRequiredTag) {
      return [this.selectedRequiredTag.uuid];
    }

    const requiredTagIds = this.requiredTags.map((tag) => tag.uuid);
    const alreadyAdded = requiredTagIds.filter((id) => this.hasTag(id, true));

    if (alreadyAdded.length !== 1) {
      return [];
    }

    return [alreadyAdded[0]];
  }

  /**
   * Returns the currently selected tag if there is only one selected.
   */
  @computed
  get singleSelectedTag(): { classification: UUID; tag: TagModel } | undefined {
    if (this.tagCount !== 1) return;
    let singleTag = undefined;
    flatMap(Array.from(this.tags), ([classification, tags]) => {
      if (tags.length === 1) {
        singleTag = { classification, tag: tags[0] };
      }
    });
    return singleTag;
  }

  /**
   * Used for the `tags` URL query parameter.
   * Format: `tagId1 tagId2_tagId3 tagId4`.
   * Note that spaces will be converted to `+` after URL encoding.
   */
  @computed
  get asURLParam(): string {
    const params: string[] = [];
    for (const [_, tags] of this.tags.entries()) {
      if (tags.length > 0) {
        params.push(
          tags
            .map((t) => t.id)
            .filter(Boolean) // HACK: To counter a potential fake tag id from the validate method
            .join(" ")
        );
      }
    }
    return params.join("_");
  }

  /**
   * Helper function valuable in tests and debugging to get a simpler overview
   * of which classifications and tags are represented by this instance.
   */
  get asObject(): Record<string, string[]> {
    const output: Record<string, string[]> = {};
    for (const [classificationId, tags] of this.tags.entries()) {
      if (tags.length > 0) {
        output[classificationId] = tags.map((t) => t.title);
      }
    }
    return output;
  }

  @computed
  get unsavedChanges() {
    return {
      add: Array.from(this.changes.add).map((t) => t.uuid),
      remove: Array.from(this.changes.remove).map((t) => t.uuid),
    };
  }

  @computed
  get allUnsavedTags() {
    return [
      ...Array.from(this.changes.add).map((t) => t.uuid),
      ...Array.from(this.changes.remove).map((t) => t.uuid),
    ];
  }

  @computed
  get tagsWithAttributes() {
    return this.allTags.filter((tag) => tag.attributes.length > 0);
  }

  @computed
  get allAttributeIds() {
    return this.tagsWithAttributes.flatMap((tag) =>
      tag.attributes.map((att) => att.uuid)
    );
  }

  /**
   * Returns an array containing the tag titles for a single classification
   * @param classificationId id of the classification
   * @param includeUnsavedChanges include unsaved tag changes
   */
  getTagTitles(
    classificationId: UUID,
    includeUnsavedChanges = false
  ): string[] {
    let titles = Array.from(
      this.tags.get(classificationId)?.values() || []
    ).map((t) => t.title);

    if (includeUnsavedChanges) {
      const { add, remove } = this.changes;
      titles = titles.filter(
        (tagTitle) =>
          !remove.some((removedTag) => removedTag.title === tagTitle)
      );
      titles.push(...add.map((addedTag) => addedTag.title));
    }

    return titles;
  }

  /**
   * Get indeterminate tags for a classification, excluding
   * tags with unsaved changes.
   * @param classificationId id of the classification
   */
  getIndeterminateTagTitles(classificationId: UUID): string[] | undefined {
    return this.indeterminateTags
      .get(classificationId)
      ?.filter((tag) => !this.allUnsavedTags.includes(tag.uuid))
      ?.map((tag) => tag.title);
  }

  /**
   * Checks whether a given tag is indeterminate
   */
  isIndeterminate(classificationId: UUID, tag: TagModel): boolean {
    return Boolean(
      this.indeterminateTags
        ?.get(classificationId)
        ?.some((t) => t.uuid === tag.uuid)
    );
  }

  /**
   * Returns the tag attributes for a single classification
   * @param classificationId id of the classification
   */
  getAttributeIds(classificationId: UUID): number[] {
    return (
      this.tags
        .get(classificationId)
        ?.flatMap((tag) =>
          tag.attributes.flatMap((attribute) => attribute.id)
        ) || []
    );
  }

  /**
   * Returns the tags for a single classification
   * @param classificationId id of the classification
   */
  get(classificationId: UUID): TagModel[] {
    return this.tags.get(classificationId) || [];
  }

  /**
   * Update the data when a single tag has been selected/de-selected
   * @param change the change event
   */
  @action.bound
  update(change: ChangeTagEvent) {
    const { selected, classificationId, tag } = change;

    if (selected) {
      if (!this.tags.has(classificationId)) {
        this.tags.set(classificationId, [tag]);
        return;
      }
      this.tags.get(classificationId)?.push(tag);
    } else {
      const removeIndex = this.tags
        .get(classificationId)
        ?.findIndex((t) => t.uuid === tag.uuid);

      if (removeIndex !== undefined && removeIndex > -1) {
        this.tags.get(classificationId)?.splice(removeIndex, 1);
      }

      if (tag.uuid === this.selectedRequiredTag?.uuid) {
        this.selectedRequiredTag = undefined;
      }
    }
  }

  /**
   * Store (but not apply) a change when a single tag has been selected/de-selected.
   * If the change is from an indeterminate tag, it should always be stored, if not
   * it should be stored only of it alters the original selected tags state.
   * @param change the change event
   */
  @action.bound
  saveChange(change: ChangeTagEvent) {
    const { selected, tag, classificationId } = change;

    const { add, remove } = this.changes;

    if (this.isIndeterminate(classificationId, tag)) {
      if (selected) {
        if (!add.some((addedTag) => addedTag.uuid === tag.uuid)) {
          add.push(tag);
        }
        if (remove.some((removedTag) => removedTag.uuid === tag.uuid)) {
          this.undoChange(tag, "remove");
        }
      } else {
        if (!remove.some((removedTag) => removedTag.uuid === tag.uuid)) {
          remove.push(tag);
        }
        if (add.some((addedTag) => addedTag.uuid === tag.uuid)) {
          this.undoChange(tag, "add");
        }
      }
      return;
    }

    const hasTag = this.hasTag(tag.uuid);
    if (selected) {
      if (!hasTag) {
        add.push(tag);
      } else {
        this.undoChange(tag, "remove");
      }
    } else {
      if (hasTag) {
        remove.push(tag);
      } else {
        this.undoChange(tag, "add");
      }
    }
  }

  /**
   * Undo an unsaved change for a single tag
   * @param tag the tag
   * @param type the type of the change
   */
  @action.bound
  undoChange(tag: TagModel, type: "add" | "remove") {
    const { add, remove } = this.changes;
    if (type === "add") {
      const removeIndex = add.findIndex(
        (addedTag) => addedTag.uuid === tag.uuid
      );
      if (removeIndex !== undefined && removeIndex > -1) {
        add.splice(removeIndex, 1);
      }
    } else {
      const removeIndex = remove.findIndex(
        (removedTag) => removedTag.uuid === tag.uuid
      );
      if (removeIndex !== undefined && removeIndex > -1) {
        remove.splice(removeIndex, 1);
      }
    }
  }

  /**
   * Apply unsaved changes for a classification
   * @param classificationId id of the classification
   */
  @action.bound
  applyUnsavedChanges(classificationId: UUID) {
    const { add, remove } = this.changes;
    const updatedTags = this.get(classificationId).filter(
      (tag) => !remove.some((removedTag) => removedTag.uuid === tag.uuid)
    );

    updatedTags.push(...add);
    this.set(classificationId, updatedTags);
    this.clearUnsavedChanges();
  }

  /**
   * Apply unsaved changes for a classification using external data
   * @param classificationId id of the classification
   * @param unsavedTags unsaved tags from another data source
   */
  @action.bound
  applyUnsavedChangesFrom(classificationId: UUID, unsavedTags: UnsavedTags) {
    const { add, remove } = unsavedTags;
    const updatedTags = this.get(classificationId).filter(
      (tag) => !remove.some((removedTag) => removedTag.uuid === tag.uuid)
    );

    updatedTags.push(...add);
    this.set(classificationId, uniqBy(updatedTags, "uuid"));
  }

  /**
   * Store changes needed to clear all tags for a classification
   * @param classificationId id of the classification
   * @param includeIndeterminateTags include indeterminate tags
   */
  @action.bound
  saveChangeToClearAll(
    classificationId: UUID,
    includeIndeterminateTags = false
  ) {
    let tagsToClear = this.get(classificationId);

    if (includeIndeterminateTags) {
      tagsToClear.concat(this.indeterminateTags.get(classificationId) || []);
    }

    if (this.requiredTags.length > 0) {
      // Do not clear _all_ required tags
      const requiredTagIds = this.requiredTags.map((t) => t.uuid);
      const alreadyAdded = requiredTagIds.filter((id) => this.hasTag(id));
      const alreadyAddedForClassification = tagsToClear
        .filter((t) => requiredTagIds.includes(t.uuid))
        .map((t) => t.uuid);

      if (alreadyAddedForClassification.length > 0) {
        if (isEqual(alreadyAddedForClassification, alreadyAdded)) {
          tagsToClear = tagsToClear.filter(
            (t) => t.uuid !== alreadyAddedForClassification[0]
          );
        }
      }
    }

    this.changes.remove = tagsToClear;
    this.changes.add = [];
  }

  /**
   * Set tags for a classification
   * @param classificationId id of the classification
   * @param tags array of tag objects
   */
  @action.bound
  set(classificationId: UUID, tags: TagModel[]) {
    this.tags.set(classificationId, tags);
  }

  /**
   * Check whether a tag with title is included in the data
   * @param classificationId id of the classification
   * @param tagTitle title of the tag
   */
  has(classificationId: UUID, tagTitle: string): boolean {
    return this.getTagTitles(classificationId)?.includes(tagTitle);
  }

  /**
   * Check whether a tag is included in the data
   * @param tagId the tag to look for
   * @param includeUnsavedChanges also consider the unsaved changes state
   */
  hasTag = (
    tagId: UUID | undefined,
    includeUnsavedChanges = false
  ): boolean => {
    if (!tagId) return false;
    if (!includeUnsavedChanges) {
      return this.allTags.some((t) => t.uuid === tagId);
    }
    return (
      // The tag is selected and saved
      this.allTags.some(
        (t) =>
          t.uuid === tagId ||
          // ... or seleced and not yet saved
          this.unsavedChanges.add.includes(tagId)
      ) &&
      // ... and NOT unselected and not yet saved
      !this.unsavedChanges.remove.includes(tagId)
    );
  };

  @action.bound
  clearAll() {
    this.tags.clear();
  }

  @action.bound
  clearUnsavedChanges() {
    this.changes.add = [];
    this.changes.remove = [];
  }

  /**
   * Replace data with merged values from a set of tag maps. Used for multiselect.
   * @param tagMaps array of tag maps (from entries)
   */
  @action.bound
  replace(tagMaps: SelectedTagsMap[]) {
    const newTagData: SelectedTagsModel = new Map();
    const newIndeterminateData: SelectedTagsModel = new Map();

    const classifications = uniq(
      tagMaps.flatMap((tagMap) => tagMap.allClassificationIds)
    );
    for (const cl of classifications) {
      const allTags = tagMaps.map((tagMap) => tagMap.get(cl));
      const common = intersectionBy(...allTags, "uuid");
      const unique = uniqBy(allTags.flat(), "uuid");
      const intermediate = differenceBy(unique, common, "uuid");

      if (intermediate.length > 0) {
        newIndeterminateData.set(cl, intermediate);
      }
      newTagData.set(cl, unique);
    }
    this.indeterminateTags.replace(newIndeterminateData);
    this.tags.replace(newTagData);
  }

  /**
   * Merge selected tags data. Used to update existing tags with fresh data.
   * @param tagMap map of selected tags keyed by classification
   */
  @action.bound
  merge(tagMap: SelectedTagsModel) {
    for (const [cl, tags] of tagMap.entries()) {
      const union = unionBy(tags, this.tags.get(cl) || [], "uuid");
      this.tags.set(cl, union);
    }
  }

  /**
   * Clear all selected values for a set of classification filters
   * @param filters
   */
  @action.bound
  clearSubset(filters: ClassModel[]) {
    for (const filter of filters) {
      const hasSelectedRequiredTag = this.tags
        .get(filter.uuid)
        ?.some((tag) => tag.uuid === this.selectedRequiredTag?.uuid);

      if (hasSelectedRequiredTag) {
        this.selectedRequiredTag = undefined;
      }

      this.tags.delete(filter.uuid);
    }
  }

  @action.bound
  clear(...classificationIds: UUID[]) {
    classificationIds.forEach((id) => this.tags.delete(id));
  }

  /**
   * Validate currently selected tags against the set of required tags.
   * NOTE! This method mutates the selectedRequiredTag property if there's one required tag.
   */
  validate(): boolean {
    if (this.selectedRequiredTag) {
      return true;
    }

    if (this.requiredTags.length === 0) {
      return true;
    }

    const requiredTagIds = this.requiredTags.map((t) => t.uuid);
    if (this.allTagIds.some((id) => requiredTagIds.includes(id))) {
      return true;
    }

    if (this.requiredTags.length === 1) {
      // Only 1 required tag, just add it so it will be visible in on the draft entry.
      // 🐉 The required tag data is only a partial tag, hence we fill in the missing fields.
      // After the new entry is saved we will get the full tag back from the api call.
      this.applySelectedRequiredTag(this.requiredTags[0]);
      return true;
    }

    // Signal that we need to ask the user to select a tag manually
    return false;
  }

  /**
   * Helper method for applying required tags when user has tag based access.
   */
  @action.bound
  applySelectedRequiredTag(tag: RequiredTagModel) {
    this.selectedRequiredTag = tag;
    this.applyUnsavedChangesFrom(tag.classification.uuid, {
      add: [
        {
          id: 0,
          uuid: tag.uuid,
          title: tag.title,
          attributes: [],
          permissions: new Set(),
          createdDate: "",
        },
      ],
      remove: [],
    });
  }

  copy(): SelectedTagsMap {
    const cpy = new SelectedTagsMap(new Map(this.tags));
    cpy.requiredTags = this.requiredTags;
    return cpy;
  }

  /**
   * Combines all the selected tag titles for a set of entries into
   * a single array. Used for showing selected values in the "More filters" button.
   * @param filters the classification filters to select from
   * @param append additional titles to append (usually from custom filters)
   */
  combine(filters: ClassModel[] | undefined, append: string[] = []): string[] {
    if (!filters) return [];
    const selected = filters.map((f) => f.uuid);
    return flatMap([...this.tags.entries()], ([cl, tags]) =>
      selected.includes(cl) ? tags.map((t) => t.title) : []
    ).concat(append);
  }

  /**
   * Helper method for mapping over the classification id and tag titles in the data.
   * @param fn callback function
   */
  map<U>(fn: (classification: UUID, titles: string[]) => U): U[] {
    return [...this.tags.entries()].map(([cl, tags]) =>
      fn(
        cl,
        tags.map((t) => t.title)
      )
    );
  }
}
