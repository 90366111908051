import styled from "styled-components";
import { ZIndex } from "@web/styles";
import { StyledButton } from "@web/elements/Button/styles";

export const UpdateBanner = styled(StyledButton).attrs({ variant: "primary" })`
  z-index: ${ZIndex.dialog};
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  margin: 0 auto;
  padding: 3px 10px;
  font-family: inherit;
  font-size: 0.8rem;
  cursor: pointer;

  > * {
    display: block;
    padding: 5px;
  }
`;
