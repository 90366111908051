import { createGlobalStyle, css, keyframes } from "styled-components";
import { createCssVarsOverrides, media } from "@web/styles/utils";
import { DEFAULT_THEME } from "@web/styles/themes/default";
import { createSimpleTheme, SimpleTheme } from "@web/styles/themes";

export const vars = DEFAULT_THEME;

export const resetCss = css`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    background: ${vars.body};
    color: ${vars.bodyFg};
    margin: 0;
    font-size: 0.875rem;

    ${media("compact")} {
      font-size: 1rem;
    }
  }

  h2 {
    font-weight: 500;
    line-height: normal;
    font-size: 13px;
  }

  h1 {
    font-weight: 300;
    line-height: normal;
    font-size: 18px;
  }

  a {
    background: ${vars.transparent};
    color: ${vars.primaryLight10};
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }
`;

const bodyTransitionIn = keyframes`
  from { opacity: 0.0001 }
  to { opacity: 1; }
`;

export const GlobalStyle = createGlobalStyle<{
  appTheme?: SimpleTheme;
}>`
  :root {
    ${(p) =>
      p.appTheme &&
      createCssVarsOverrides(createSimpleTheme(p.appTheme)).join("\n")}
  }

  body {
    animation: ${bodyTransitionIn} 450ms ease;
  }

  ${resetCss};
`;

export const ZIndex = {
  base: 1,
  resultsToolbar: 500,
  layout: 600,
  header: 1000,
  overlay: 1000,
  uploader: 2000,
  dropzone: 2100,
  message: 3000,
  bucket: 3001,
  classSelect: 4000,
  popover: 4000,
  dialog: 5000,
  tooltip: 5000,
  clickOutside: -100,
};

export const maxLines = (lines: number, lineHeight = 1.2) => css`
  --max-lines: ${lines};
  --line-height: ${lineHeight}em;
  line-height: var(--line-height);
  max-height: calc(var(--line-height) * var(--max-lines));
  overflow: hidden;
`;
