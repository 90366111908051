import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages } from "react-intl";
import { PipelineModel } from "@web/models";
import {
  IClassificationNodeExtended,
  IRuleNode,
  RuleType,
} from "@web/api/Integration/types";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { CreateButton } from "../CreateButton";
import { RemoveButton } from "../RemoveButton";
import { EditDocumentRule } from "./EditDocumentRule";
import { EditTagHasAttributeRule } from "./EditTagHasAttributeRule";
import { EditClassificationRule } from "./EditClassificationRule";

interface Props {
  pipeline: PipelineModel;
  findClassifications: (
    query: string
  ) => Promise<IClassificationNodeExtended[]>;
}

export const RuleList: FC<Props> = observer((p) => {
  const [getConfirmation] = useConfirmationDialog();
  const [selectedRule, setSelectedRule] = useState<IRuleNode | undefined>(
    undefined
  );
  const [addRule, setAddRule] = useState<RuleType | undefined>(undefined);

  useEffect(() => {
    p.pipeline.loadAllRules();
  }, []);

  useEffect(() => {
    if (
      p.pipeline.conditions.length > 0 &&
      p.pipeline.conditions.filter(
        (condition) => condition.type === "EntryHasTag"
      ).length > 0
    ) {
      p.pipeline.loadTagsExpandedForConditions();
    }
  }, [p.pipeline.conditions]);

  const hasCondition = p.pipeline.tagConditions.length > 0;

  const handleDelete = async (rule: IRuleNode) => {
    const confirmed = await getConfirmation({
      title: texts.deleteConfirmationTitle,
      message: texts.deleteConfirmationMessage,
      values: {
        ruleName: rule.name,
      },
    });

    if (confirmed) {
      p.pipeline.deleteRule(rule.id);
    }
  };
  return (
    <_wrap>
      <_header>
        <h3>
          <FormattedMessage
            {...texts.requiredTagsFromClassification}
            values={{ count: p.pipeline.classificationRules.length }}
          />
        </h3>
        <CreateButton
          onClick={() => setAddRule("EntryHasTagFromClassification")}
        />
      </_header>

      {addRule === "EntryHasTagFromClassification" && (
        <EditClassificationRule
          pipeline={p.pipeline}
          findClassifications={p.findClassifications}
          onClose={() => setAddRule(undefined)}
        />
      )}

      {p.pipeline.classificationRules.map((rule) => (
        <React.Fragment key={rule.id}>
          <_rule>
            {rule.name}
            <RemoveButton onClick={() => handleDelete(rule)} />
          </_rule>
        </React.Fragment>
      ))}

      <_header>
        <h3>
          <FormattedMessage
            {...texts.requiresPropertyOnTag}
            values={{ count: p.pipeline.attributeRules.length }}
          />
        </h3>
        {hasCondition ? (
          addRule !== "EntryHasAttributeValue" && (
            <CreateButton
              onClick={() => setAddRule("EntryHasAttributeValue")}
            />
          )
        ) : (
          <p>
            <FormattedMessage {...texts.ruleRequiresTagCondition} />
          </p>
        )}
      </_header>

      {hasCondition && addRule === "EntryHasAttributeValue" && (
        <EditTagHasAttributeRule
          pipeline={p.pipeline}
          onClose={() => setAddRule(undefined)}
        />
      )}

      {p.pipeline.attributeRules.map((rule) => (
        <React.Fragment key={rule.id}>
          {selectedRule?.id === rule.id ? (
            <EditTagHasAttributeRule
              pipeline={p.pipeline}
              rule={rule}
              onClose={() => setSelectedRule(undefined)}
            />
          ) : (
            <_rule onClick={() => setSelectedRule(rule)}>
              {rule.name}
              <RemoveButton onClick={() => handleDelete(rule)} />
            </_rule>
          )}
        </React.Fragment>
      ))}

      <_header>
        <h3>
          <FormattedMessage
            {...texts.ruleRequiredDocument}
            values={{ count: p.pipeline.documentRules.length }}
          />
        </h3>
        <CreateButton onClick={() => setAddRule("DocumentTitleStartsWith")} />
      </_header>

      {addRule === "DocumentTitleStartsWith" && (
        <EditDocumentRule
          pipeline={p.pipeline}
          onClose={() => setAddRule(undefined)}
        />
      )}

      {p.pipeline.documentRules.map((rule) => (
        <React.Fragment key={rule.id}>
          {selectedRule?.id === rule.id ? (
            <EditDocumentRule
              pipeline={p.pipeline}
              rule={rule}
              onClose={() => setSelectedRule(undefined)}
            />
          ) : (
            <_rule onClick={() => setSelectedRule(rule)}>
              {rule.name}
              <RemoveButton onClick={() => handleDelete(rule)} />
            </_rule>
          )}
        </React.Fragment>
      ))}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  > h3 {
    margin: 0px 0px 0px 0px;
    :not(:first-child) {
      margin-top: 25px;
    }
  }
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const _rule = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const texts = defineMessages({
  deleteConfirmationTitle: {
    id: "flow.settings.rule.delete.title",
    defaultMessage: "Are you sure you want to do this?",
  },
  deleteConfirmationMessage: {
    id: "flow.settings.rule.delete.message",
    defaultMessage:
      "This will permanently delete the rule <em>{ruleName}</em>.",
  },
  requiredTagsFromClassification: {
    id: "flow.settings.rule.required.tags.from.classification",
    defaultMessage: "Required tags from classification ({count})",
  },
  requiresPropertyOnTag: {
    id: "flow.settings.rule.requires.property.on.tag",
    defaultMessage: "Requires property on tag ({count})",
  },
  ruleRequiresTagCondition: {
    id: "flow.settings.rule.requires.tag.condition",
    defaultMessage: "Rule requires a tag condition",
  },
  ruleRequiredDocument: {
    id: "flow.settings.rule.required.document",
    defaultMessage: "Required documents ({count})",
  },
});
