import React, { forwardRef } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useCheckForEmptyFiles } from "@web/components/Upload/helpers";

interface FileInputProps {
  type: "singleFile" | "multipleFiles" | "folder";
  onUpload: (files: File[]) => void;
}

export const UploadInput = forwardRef<DropzoneRef, FileInputProps>((p, ref) => {
  const checkForEmptyFiles = useCheckForEmptyFiles();
  const isFolder = p.type === "folder" ? "true" : undefined;

  const handleDrop = async (files: File[]) => {
    if (await checkForEmptyFiles(files)) {
      p.onUpload(files);
    }
  };

  return (
    <Dropzone
      noDrag
      noKeyboard
      multiple={p.type !== "singleFile"}
      ref={ref}
      onDropAccepted={handleDrop}
    >
      {({ getInputProps }) => (
        <input
          {...getInputProps()}
          // @ts-expect-error
          // This attribute is not available in the React types, nor is it an option
          // in react-dropzone but it allows the file dialog to select and upload folders.
          // https://github.com/react-dropzone/react-dropzone/issues/134
          // https://caniuse.com/input-file-directory
          directory={isFolder}
          webkitdirectory={isFolder}
        />
      )}
    </Dropzone>
  );
});
