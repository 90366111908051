import React from "react";
import { UnknownIcon } from "@web/elements/Icons/FileTypes/UnknownIcon";
import { ImageIcon } from "@web/elements/Icons/FileTypes/ImageIcon";
import { TextIcon } from "@web/elements/Icons/FileTypes/TextIcon";
import { PdfIcon } from "@web/elements/Icons/FileTypes/PdfIcon";
import { VideoIcon } from "@web/elements/Icons/FileTypes/VideoIcon";
import { SpreadsheetIcon } from "@web/elements/Icons/FileTypes/SpreadsheetIcon";
import { EmailIcon } from "@web/elements/Icons/FileTypes/EmailIcon";

interface IProps {
  type: string;
}

const exts = {
  image: ["avif", "heic", "jpeg", "jpg", "gif", "bmp", "png", "webp"],
  text: ["txt", "md", "doc", "docx", "rtf"],
  pdf: ["pdf"],
  video: ["mp4", "mov", "avi", "wmv"],
  spreadsheet: ["xls", "xlsx"],
  presentation: ["ppt", "pptx"],
  email: ["eml", "msg"],
};

export const IconPreview = (p: IProps) => {
  if (exts.image.includes(p.type)) {
    return <ImageIcon />;
  }

  if (exts.pdf.includes(p.type)) {
    return <PdfIcon />;
  }

  if (exts.text.includes(p.type)) {
    return <TextIcon />;
  }

  if (exts.video.includes(p.type)) {
    return <VideoIcon />;
  }

  if (exts.spreadsheet.includes(p.type)) {
    return <SpreadsheetIcon />;
  }

  if (exts.email.includes(p.type)) {
    return <EmailIcon />;
  }

  return <UnknownIcon />;
};

(IconPreview as React.FC).displayName = "IconPreview";
