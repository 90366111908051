import { IFacetCountNode } from "@web/api/Integration/types";
import { FilterStore } from "@web/stores";
import { action, computed, observable } from "mobx";
import { ResultParamModel } from "./ResultModel";

type InternalTagId = number;

export class TagFacetCountsModel {
  @observable
  private countsByTagId = new Map<InternalTagId, number>();

  constructor(
    private store: FilterStore,
    private resultParams: ResultParamModel
  ) {}

  @computed get tagCount(): number {
    return this.countsByTagId.size;
  }

  getCountForTag(tagId: InternalTagId): number | undefined {
    return this.countsByTagId.get(tagId);
  }

  async needsCountForTags(tagIds: InternalTagId[]) {
    const missingTagIds = tagIds.filter((id) => !this.countsByTagId.has(id));
    if (missingTagIds.length > 0) {
      await this.store.loadFacetCounts(missingTagIds, this.resultParams);
    }
  }

  @action.bound
  updateFromJson(tagIds: InternalTagId[], json: IFacetCountNode[]) {
    for (const internalIdentifier of tagIds) {
      this.countsByTagId.set(internalIdentifier, 0);
    }
    for (const { internalIdentifier, count } of json) {
      this.countsByTagId.set(internalIdentifier, count);
    }
  }
}
