import { format } from "date-fns";
import {
  parseSearchQueryString,
  PipelineType,
  ResultParamModel,
} from "@web/models";
import { DATE_ONLY_FORMAT } from "@web/utils/dates";
import { APIParameters, IUpdateLinkedObjects } from "./types";

export function generateEntrySearchParameters(params: ResultParamModel) {
  const filterQueryItems: string[] = [];
  const parameters: APIParameters = {};

  if (params.section && params.section !== "all") {
    filterQueryItems.push("section.internalIdentifier=@sectionId");
    parameters["@sectionId"] = params.section;
  }

  if (params.tags) {
    const tagQueryItems = params.tags.map(
      (_, i) => `tags.internalIdentifier=@tagGroup${i}`
    );
    filterQueryItems.push(...tagQueryItems);
    params.tags.forEach(
      (tagsIds, i) => (parameters[`@tagGroup${i}`] = tagsIds)
    );
  }

  if (params.tagStatus) {
    filterQueryItems.push("tags.tagStatus.internalIdentifier=@tagStatus");
    parameters["@tagStatus"] = params.tagStatus;
  }

  if (params.customFilters) {
    if (params.customFilters["Entry Status"]) {
      filterQueryItems.push("entryStatus.name=@entryStatus");
      parameters["@entryStatus"] = params.customFilters["Entry Status"];
    }
  }

  if (params.attributes) {
    filterQueryItems.push(...params.attributes.query);
    Object.assign(parameters, params.attributes.params);
  }

  let searchQuery = "";
  if (params.query) {
    const { search, filters } = parseSearchQueryString(params.query);
    searchQuery = search;
    for (const filter of filters) {
      filterQueryItems.push(filter.query);
      Object.assign(parameters, filter.parameter);
    }
  }

  let order;
  if (params.order === "alphabetical") {
    order = { field: "title", order: "asc" };
  } else if (params.order === "newest") {
    order = { field: "createdDate", order: "desc" };
  } else if (params.order === "oldest") {
    order = { field: "createdDate", order: "asc" };
  } else if (params.order === "newest-document") {
    order = { field: "documents.documentVersions.createdDate", order: "desc" };
  }

  return {
    ...(searchQuery && {
      query: searchQuery,
    }),
    filterQuery: filterQueryItems.join(" && "),
    parameters,
    ...(order && { sort: order }),
  };
}

export function getPipelineLookupParameters(type: PipelineType): {
  query: string;
  parameters: Record<string, string>;
} {
  switch (type) {
    case "validation":
      return {
        query: "pipelineType=@validation",
        parameters: { "@validation": "validation" },
      };
    case "notification":
      return {
        query: "pipelineType=@notification",
        parameters: { "@notification": "notification" },
      };
    case "reminder":
      return {
        query: "pipelineType=@reminder",
        parameters: { "@reminder": "reminder" },
      };
  }
}

export function getAttributeValueParameter(value: string | number | Date) {
  let valueParam = {};
  if (value instanceof Date) {
    valueParam = { valueDate: format(value, DATE_ONLY_FORMAT) };
  } else if (typeof value === "number") {
    valueParam = { valueDouble: value };
  } else {
    valueParam = { valueString: value };
  }

  return valueParam;
}

export function formatLinkedObjectsUpdate(values: IUpdateLinkedObjects) {
  return [
    ...values.add.map((id) => ({ add: { id } })),
    ...values.remove.map((id) => ({ remove: { id } })),
  ];
}
