import React from "react";

export const VideoIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.61 54L57.39 54C58.83 54 60 52.7903 60 51.3356L60 20.6644C60 19.1944 58.83 18 57.39 18L14.61 18C13.17 18 12 19.1944 12 20.6644L12 51.3356C12 52.7903 13.17 54 14.61 54ZM31.6555 27.6C31.3258 27.3528 30.8555 27.588 30.8555 28L30.8555 44C30.8555 44.412 31.3258 44.6472 31.6555 44.4L42.3221 36.4C42.5888 36.2 42.5888 35.8 42.3221 35.6L31.6555 27.6Z"
      fill="currentColor"
    />
  </svg>
);
