import "core-js/features/array/flat-map";
import "core-js/features/object/from-entries";
import "mobx-react-lite/batchingForReactDom";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Api } from "@web/api";
import { API_URL } from "@web/utils/paths";
import { AppConfig } from "@web/config";
import App from "./app";
import { RootStore } from "./stores";
import { reportWebVitals, sendToBff } from "./utils/metrics";
import { UnhandledErrorPage } from "./pages/UnhandledErrorPage";
import { commonTexts } from "./translations";
import { LoginPage } from "./pages/LoginPage";

const api = new Api(API_URL);

reportWebVitals(sendToBff);

(async () => {
  try {
    // Don't render UI if we're in a login popup.
    // The original window will show the UI and close this window.
    const urlParams = new URLSearchParams(location.search);
    const isLoginPopup = urlParams.get("isLoginPopup") === "1";
    if (isLoginPopup) {
      return;
    }

    const configResponse = await api.loadConfig();

    const appConfig = new AppConfig(configResponse.data);
    const stores = new RootStore(appConfig, api);
    ReactDOM.render(
      <App stores={stores} config={appConfig} />,
      document.getElementById("root")
    );
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const status = err.response?.status;
      const loginUrl = err.response?.headers["redirect-to"];
      if (status === 401 && loginUrl) {
        ReactDOM.render(
          <LoginPage loginUrl={loginUrl} />,
          document.getElementById("root")
        );
      }
    } else {
      console.error("Error while initializing the application", err);
      ReactDOM.render(
        <UnhandledErrorPage
          unhandledErrorMessage={
            commonTexts.unhandledErrorMessage.defaultMessage
          }
          unhandledErrorTitle={commonTexts.unhandledErrorTitle.defaultMessage}
          retryLinkText={commonTexts.retry.defaultMessage}
        />,
        document.getElementById("root")
      );
    }
  }
})();
