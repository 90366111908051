import React, { useEffect } from "react";
import styled from "styled-components";
import uniqueId from "lodash/uniqueId";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { FileWithPath } from "react-dropzone";
import { ChecklistItem } from "./ChecklistItem";
import { DocumentTitleStartsWith } from "@web/api/Integration/types";
import {
  DocumentModel,
  EntryModel,
  PipelineModel,
  UploadJobResult,
  UploadRequest,
} from "@web/models";
import { handleDocumentUpload } from "@web/components/Upload/handlers";
import { CircleProgress } from "@web/elements/CircleProgress";
import { vars } from "@web/styles";
import { useUniqueId } from "@web/utils/hooks/useUniqueId";
import { media } from "@web/styles/utils";
import { CaretDownIcon } from "@web/elements/Icons";
import { StyledButton } from "@web/elements/Button/styles";
import {
  ConfirmationHandler,
  UploadResult,
} from "@web/components/Upload/types";
import { useStores } from "@web/stores/context";

interface Props {
  entry: EntryModel;
  pipeline: PipelineModel;
  isExpanded: boolean;
  onExpand: (expand: boolean) => void;
  onDocumentClick: (document: DocumentModel) => void;
  onUpload: (request: UploadRequest) => Promise<UploadResult | undefined>;
  getConfirmation: ConfirmationHandler;
}

function getPrefixedName(name: string, prefix: string) {
  return name.startsWith(prefix) ? name : `${prefix}_${name}`;
}

export const Checklist: React.FC<Props> = observer((p) => {
  const intl = useIntl();
  const checklistId = useUniqueId("checklist");
  const expandButtonId = useUniqueId("expandButton");
  const documentStore = useStores().documentStore;

  const { entry, pipeline } = p;

  useEffect(() => {
    pipeline.loadAllRules();
  }, [pipeline.uuid]);

  const handleDropFiles = (files: FileWithPath[], prefix: string) => {
    const modifiedFiles = files.map((file) => {
      const newFileName = getPrefixedName(file.name, prefix);
      const newFilePath = getPrefixedName(
        file.path?.replace(/^\//, "") ?? "",
        prefix
      );

      return { file, newFileName, newFilePath };
    });

    handleDocumentUpload(documentStore, modifiedFiles, {
      onUpload: p.onUpload,
      getConfirmation: p.getConfirmation,
      entry: p.entry,
    });
  };

  const getDocumentsByRule = (rule: DocumentTitleStartsWith) =>
    entry.documents.filter((doc) => doc.title.startsWith(rule.prefix));

  const getRuleSuccess = (rule: DocumentTitleStartsWith) =>
    pipeline.validatedDocuments(p.entry.uuid).includes(rule.id);

  const documentRules = pipeline.documentRules;

  const totalDocumentAmount = documentRules.length;

  const validDocumentAmount = pipeline.validatedDocuments(p.entry.uuid).length;

  const progressInPercent = pipeline.documentProgressForEntry(p.entry.uuid);

  const conditionTitles = pipeline.conditionDescriptionForEntry(p.entry);

  const titleText = intl.formatMessage(texts.title, {
    amount: totalDocumentAmount,
    conditions: conditionTitles,
    i: (text: string) => <i key={uniqueId()}>{text}</i>,
  });

  const progressText =
    progressInPercent === 100
      ? intl.formatMessage(texts.allDone)
      : intl.formatMessage(texts.progress, {
          valid: validDocumentAmount,
          total: totalDocumentAmount,
        });

  if (!totalDocumentAmount || entry.isDraft) {
    return null;
  }

  return (
    <div>
      <_header>
        <_progress>
          <CircleProgress percentage={progressInPercent} />
        </_progress>
        <_title>
          {titleText} ({progressText})
        </_title>
        <_expandButton
          id={expandButtonId}
          aria-controls={checklistId}
          aria-expanded={p.isExpanded}
          onClick={() => p.onExpand(!p.isExpanded)}
        >
          <CaretDownIcon />
          {intl.formatMessage(
            p.isExpanded ? texts.exitChecklist : texts.viewChecklist
          )}
        </_expandButton>
      </_header>

      <_checklist
        id={checklistId}
        hidden={!p.isExpanded}
        aria-labelledby={expandButtonId}
      >
        {documentRules.map((rule) => (
          <ChecklistItem
            key={rule.id}
            rule={rule}
            documents={getDocumentsByRule(rule)}
            isComplete={getRuleSuccess(rule)}
            onDropFiles={handleDropFiles}
            onDocumentClick={p.onDocumentClick}
          />
        ))}
      </_checklist>
    </div>
  );
});

const _header = styled.header`
  background: ${vars.primary};
  border-radius: 4px;
  color: ${vars.primaryFg};
  display: grid;
  align-items: center;
  grid-template:
    "progress title expand"
    / auto 1fr auto;
  grid-gap: 1rem;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: sticky;
  top: 0.5rem;
  z-index: 10;

  ${media("compact")} {
    grid-template:
      "progress title"
      "expand expand";
    padding: 1rem;
  }
`;

const _progress = styled.div`
  grid-area: progress;
`;

const _title = styled.h2`
  grid-area: title;
  font-size: 1rem;
  margin: 0;

  i {
    color: #d5d9fe;
    font-style: normal;
  }
`;

const _expandButton = styled(StyledButton).attrs({
  variant: "primary",
  size: 1.125,
})`
  grid-area: expand;
  min-width: 150px;

  &[aria-expanded="true"] svg {
    transform: rotate(-180deg);
  }
`;

const _checklist = styled.div``;

const texts = defineMessages({
  title: {
    id: "flow.checklist.header.title",
    defaultMessage:
      "{amount} required {amount, plural, one {document} other {documents}} for <i>{conditions}</i>",
  },
  progress: {
    id: "flow.checklist.header.progress",
    defaultMessage: "{valid} of {total} done",
  },
  allDone: {
    id: "flow.checklist.header.all_done",
    defaultMessage: "all done",
  },
  exitChecklist: {
    id: "flow.checklist.header.exit",
    defaultMessage: "Exit checklist",
  },
  viewChecklist: {
    id: "flow.checklist.header.view",
    defaultMessage: "View checklist",
  },
});
