import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@web/elements/Button";
import { vars } from "@web/styles";
import {
  AccessGroupModel,
  NamedPermissionSet,
  ObjectPermissionsModel,
  ObjectWithAccessControlModel,
} from "@web/models/AccessControlModel";
import {
  IObjectWithAccessControl,
  Permission,
} from "@web/api/Integration/types";
import { ObjectPermissionListModel } from "@web/models/AccessControlModel/ObjectPermissionListModel";
import { commonTexts } from "@web/translations";
import { LazyList } from "../LazyList";
import { Overlay } from "../Overlay";
import { AddPermissionsCell, ObjectPermissionsCell } from "./PermissionCell";

interface ListProps<ObjectType extends IObjectWithAccessControl> {
  permissionList: ObjectPermissionListModel<ObjectType>;
  availablePermissions: Permission[];
  namedPermissionSets: NamedPermissionSet<Permission>[];
  onClose: () => void;
}

interface RowProps<ObjectType extends IObjectWithAccessControl> {
  permissionList: ObjectPermissionListModel<ObjectType>;
  object: ObjectWithAccessControlModel<ObjectType>;
  accessGroup: AccessGroupModel;
  objectPermissions: ObjectPermissionsModel | undefined;
  availablePermissions: Permission[];
  namedPermissionSets: NamedPermissionSet<Permission>[];
}

const ROW_HEIGHT = 32;
const ROW_SPACING = 8;

export const AllObjectPermissionsBox = observer(
  <ObjectType extends IObjectWithAccessControl>({
    permissionList,
    availablePermissions,
    namedPermissionSets,
    onClose,
  }: ListProps<ObjectType>) => {
    useEffect(() => {
      object.loadAllPermissions();
    }, []);

    const intl = useIntl();

    const { items, loadNextPage, loadingStatus, filter, object } =
      permissionList;

    const renderRow = useCallback(
      (index: number) => {
        const { accessGroup, object, objectPermissions } = items[index];
        return (
          <Row
            accessGroup={accessGroup}
            object={object}
            objectPermissions={objectPermissions}
            permissionList={permissionList}
            availablePermissions={availablePermissions}
            namedPermissionSets={namedPermissionSets}
          />
        );
      },
      [items]
    );

    const title =
      typeof object.title !== undefined
        ? intl.formatMessage(texts.longTitle, { object: object.title })
        : intl.formatMessage(texts.shortTitle);

    return (
      <Overlay onEscape={onClose} onClickOutside={onClose}>
        <_wrap>
          <_title>{title}</_title>
          <LazyList
            itemSize={ROW_HEIGHT + ROW_SPACING}
            loadingStatus={loadingStatus}
            loadNextPage={loadNextPage}
            renderItem={renderRow}
            onQueryChange={filter}
          />
          <_closeButton text={commonTexts.close} onClick={onClose} />
        </_wrap>
      </Overlay>
    );
  }
);

const Row = observer(
  <ObjectType extends IObjectWithAccessControl>({
    permissionList,
    accessGroup,
    object,
    objectPermissions,
    availablePermissions,
    namedPermissionSets,
  }: RowProps<ObjectType>) => {
    return (
      <_row>
        <_accessGroup>{accessGroup.name}</_accessGroup>
        {objectPermissions ? (
          <ObjectPermissionsCell
            showGroupName={false}
            availablePermissions={availablePermissions}
            namedPermissionSets={namedPermissionSets}
            objectPermissions={objectPermissions}
            canModifyPermissions={object.canModifyPermissions}
          />
        ) : (
          <_rowHoverCell>
            <AddPermissionsCell
              initialSelectedAccessGroup={accessGroup}
              object={object}
              accessGroups={permissionList.accessGroupList}
              availablePermissions={availablePermissions}
              namedPermissionSets={namedPermissionSets}
            />
          </_rowHoverCell>
        )}
      </_row>
    );
  }
);

const _wrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 490px;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
`;

const _title = styled.h3`
  padding: 0;
  margin: 0 0 25px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const _closeButton = styled(Button).attrs({ variant: "blank" })`
  margin-top: 20px;
  color: ${vars.primaryLight10};
`;

const _rowHoverCell = styled.div`
  display: none;
`;

const _row = styled.div`
  position: relative;
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 160px;
  height: ${ROW_HEIGHT}px;
  overflow: hidden;
  align-items: stretch;
  justify-items: stretch;

  :hover > ${_rowHoverCell} {
    display: block;
  }
`;

const _accessGroup = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 9px 5px;
  justify-content: left;
`;

const texts = defineMessages({
  shortTitle: {
    id: "accessControl.allObjectPermissions.shortTitle",
    defaultMessage: "Permissions",
  },
  longTitle: {
    id: "accessControl.allObjectPermissions.longTitle",
    defaultMessage: "Group permissions for {object}",
  },
});
