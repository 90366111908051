import React, { useState, FC, ChangeEvent } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import {
  ATTRIBUTE_FILTER_OPTIONS,
  AttributeModel,
  AttributeType,
  FilterType,
} from "@web/models";
import { DMDatePicker } from "@web/components/DatePicker";
import { vars } from "@web/styles";
import { ActionMenu } from "@web/elements";
import { commonTexts } from "@web/translations";
import { AttributeOptionFilter } from "./AttributeOptionFilter";

interface IEvents {
  onChange: (definitionId: number, filter: FilterType) => void;
  onCancel: () => void;
  onDatePickerToggle: (open: boolean) => void;
}

interface IProps extends IEvents {
  attribute: AttributeModel;
  initialFilter?: FilterType;
}

export const AttributeFilter: FC<IProps> = observer((p) => {
  const intl = useIntl();
  const { type, name } = p.attribute;
  const filterOptions = ATTRIBUTE_FILTER_OPTIONS[type];
  const defaultFilter = filterOptions.find((filter) => filter.defaultChoice);
  const [filter, setFilter] = useState(
    p.initialFilter || defaultFilter || filterOptions[0]
  );

  const handleOptionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const filterFromOptions = filterOptions.find(
      (o) => o.id === e.target.value
    );
    if (filterFromOptions) {
      setFilter(filterFromOptions);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFilter: FilterType = { ...filter };
    if (updatedFilter.type === "range" && e.target.id === "secondInput") {
      updatedFilter.valueEnd = e.target.value;
    } else {
      updatedFilter.value = e.target.value;
    }

    setFilter(updatedFilter);
  };

  const handleDateChange = (date: Date, id?: string) => {
    const updatedFilter: FilterType = { ...filter };
    if (updatedFilter.type === "range" && id === "secondInput") {
      updatedFilter.valueEnd = date.toString();
    } else {
      updatedFilter.value = date.toString();
    }

    setFilter(updatedFilter);
  };

  const handleListOptionSelect = (rawOptionValue: string | number) => {
    if (filter.type !== "list") {
      return;
    }

    const updatedFilter: FilterType = {
      ...filter,
      value: [...filter.value],
    };

    if (updatedFilter.value.includes(rawOptionValue)) {
      updatedFilter.value = updatedFilter.value.filter(
        (v) => v !== rawOptionValue
      );
    } else {
      updatedFilter.value.push(rawOptionValue);
    }

    setFilter(updatedFilter);
    p.onChange(p.attribute.id, updatedFilter);
  };

  const handleSave = (e?: React.FormEvent) => {
    e?.preventDefault();
    p.onChange(p.attribute.id, filter);
  };

  const canSave = () => {
    if (type === "Date") {
      return true;
    }

    if (filter.type === "range") {
      return filter.value !== "" || filter.valueEnd !== "";
    }

    return Boolean(filter.value);
  };

  if (filter.type === "list") {
    return (
      <_wrap>
        <_header>
          <h3>{name}</h3>
        </_header>

        <AttributeOptionFilter
          filter={filter}
          attribute={p.attribute}
          onChange={handleListOptionSelect}
        />
      </_wrap>
    );
  }

  return (
    <_wrap>
      <_header>
        <h3>{name}</h3>
      </_header>

      <select
        onChange={handleOptionSelect}
        value={filter.id}
        disabled={filterOptions.length === 1}
      >
        {filterOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {intl.formatMessage(option.title)}
          </option>
        ))}
      </select>

      <_form onSubmit={handleSave}>
        {type === "Date" ? (
          <DateInput
            filter={filter}
            onDateChange={handleDateChange}
            onDatePickerToggle={p.onDatePickerToggle}
          />
        ) : (
          <TextInput
            attributeType={type}
            filter={filter}
            onInputChange={handleInputChange}
          />
        )}

        <ActionMenu
          direction="vertical"
          applyText={commonTexts.ok}
          applyIsDisabled={!canSave()}
          onApply={handleSave}
          onCancel={p.onCancel}
        />
      </_form>
    </_wrap>
  );
});

interface IDateInputProps {
  filter: FilterType;
  onDateChange: (date: Date, id?: string) => void;
  onDatePickerToggle: (open: boolean) => void;
}

const DateInput: FC<IDateInputProps> = (p) => {
  const intl = useIntl();
  const type = p.filter.type;
  if (p.filter.type === "relative" || p.filter.type === "list") {
    return null;
  }

  return (
    <_inputWrap>
      {type === "range" && (
        <label htmlFor="firstInput">{intl.formatMessage(texts.from)}</label>
      )}
      <DMDatePicker
        id="firstInput"
        initialDate={p.filter.value}
        onDateSelect={p.onDateChange}
        onCalendarOpen={() => p.onDatePickerToggle(true)}
        onCalendarClose={() => p.onDatePickerToggle(false)}
      />

      {p.filter.type === "range" && (
        <>
          <label htmlFor="secondInput">{intl.formatMessage(texts.to)}</label>
          <DMDatePicker
            id="secondInput"
            initialDate={p.filter.valueEnd}
            onDateSelect={p.onDateChange}
            onCalendarOpen={() => p.onDatePickerToggle(true)}
            onCalendarClose={() => p.onDatePickerToggle(false)}
          />
        </>
      )}
    </_inputWrap>
  );
};

interface ITextInputProps {
  attributeType: AttributeType;
  filter: FilterType;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: FC<ITextInputProps> = (p) => {
  const intl = useIntl();

  const renderPlaceholder = () => {
    switch (p.attributeType) {
      case "String":
        return intl.formatMessage(texts.stringPlaceholder);
      case "Numeric":
        return intl.formatMessage(texts.numericPlaceholder);
    }
  };

  if (p.filter.type === "relative") {
    return null;
  }

  return (
    <_inputWrap>
      {p.filter.type === "range" && (
        <label htmlFor="firstInput">{intl.formatMessage(texts.from)}</label>
      )}
      <_input
        autoFocus
        id="firstInput"
        type={p.attributeType === "Numeric" ? "number" : "text"}
        value={String(p.filter.value)}
        placeholder={renderPlaceholder()}
        onChange={p.onInputChange}
      />
      {p.filter.type === "range" && (
        <>
          <label htmlFor="secondInput">{intl.formatMessage(texts.to)}</label>
          <_input
            id="secondInput"
            type={p.attributeType === "Numeric" ? "number" : "text"}
            value={p.filter.valueEnd}
            placeholder={renderPlaceholder()}
            onChange={p.onInputChange}
          />
        </>
      )}
    </_inputWrap>
  );
};

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 0px;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  border-radius: 3px;
  min-width: 260px;
  max-width: 330px;

  > select {
    margin: 5px;
    padding: 5px;
    border: none;
    color: ${vars.infoFg};
  }

  > select[disabled] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    opacity: 1;
  }
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px 15px 5px;
  border-bottom: 1px solid ${vars.dark05};
  h3 {
    padding: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    flex: 1;
  }
`;

const _form = styled.form`
  display: flex;
  padding: 15px 10px 5px 10px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${vars.dark05};
`;

const _inputWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  label {
    margin: 0 3px;
  }
`;

const _input = styled.input`
  color: ${vars.secondaryAltFg};
  border-color: ${vars.transparent};
  background-color: ${vars.secondaryAltLight20};
  font: inherit;
  height: 2.25em;
  padding: 0 0.5em;
  outline: none;
  width: 100%;
  border-radius: 3px;
  ::placeholder {
    color: ${vars.dark55};
  }
`;

const texts = defineMessages({
  stringPlaceholder: {
    id: "attributes.filter.placeholder.string",
    defaultMessage: "text…",
  },
  numericPlaceholder: {
    id: "attributes.filter.placeholder.numeric",
    defaultMessage: "number…",
  },
  from: {
    id: "attributes.filter.from",
    defaultMessage: "From",
  },
  to: {
    id: "attributes.filter.to",
    defaultMessage: "To",
  },
});
