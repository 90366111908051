import React from "react";
import styled from "styled-components";
import { buttonReset } from "@web/elements/Button/styles";
import { vars } from "@web/styles";

export const SearchSuggestionButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (p) => {
  return (
    <_button {...p} role="listitem">
      {p.children}
    </_button>
  );
};

const _button = styled.button`
  ${buttonReset};

  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;
  text-align: left;

  :hover {
    background: ${vars.secondaryAltLight20};
  }

  :focus {
    background: ${vars.secondaryAltLight10};
  }
`;
