import React, { Ref } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { ColorStatusModel, TagStatusColor } from "@web/models";
import { StatusColor } from "@web/elements";
import { CrossIcon } from "@web/elements/Icons";
import { vars } from "@web/styles";

export type TagStatusButtonVariants = keyof typeof variants;

interface IProps {
  selected: ColorStatusModel[];
  variant?: TagStatusButtonVariants;
  onClearAll?: (e: React.MouseEvent) => void;
  onClick?: () => void;
}

const variants = {
  default: {
    bg: vars.transparent,
    fg: vars.light95,
    bd: vars.light55,
    hover: {
      bg: vars.light95,
      fg: vars.secondary,
      bd: vars.secondary,
    },
    active: {
      bg: vars.light25,
      fg: vars.light95,
      bd: vars.transparent,
    },
  },
  alt: {
    bg: vars.transparent,
    fg: vars.primaryDark20,
    bd: vars.dark25,
    hover: {
      bg: vars.light95,
      fg: vars.primaryDark20,
      bd: vars.secondary,
    },
    active: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.primaryLight10,
    },
  },
};

export const TagStatusButton = observer(
  React.forwardRef((p: IProps, ref: Ref<HTMLButtonElement>) => {
    const theme = variants[p.variant || "default"];
    const firstSelected = p.selected.length > 0 ? p.selected[0] : null;
    const moreText =
      p.selected.length > 1 ? ` + ${p.selected.length - 1}` : null;

    return (
      <_button
        ref={ref}
        empty={p.selected.length === 0}
        variant={theme}
        onClick={p.onClick}
      >
        <span>
          <FormattedMessage id="common.status" defaultMessage="Status" />
        </span>
        {firstSelected && (
          <>
            {firstSelected.color !== "noColor" && (
              <StatusColor color={TagStatusColor[firstSelected.color]} />
            )}
            <_name>{firstSelected.name}</_name>
            {moreText && <span>{moreText}</span>}
            {p.onClearAll && (
              <CrossIcon width={14} height={14} onClick={p.onClearAll} />
            )}
          </>
        )}
      </_button>
    );
  })
);

const _button = styled.button.attrs({ type: "button" })<{
  empty?: boolean;
  variant: typeof variants.default;
}>`
  background: ${(p) => (p.empty ? p.variant.bg : p.variant.active.bg)};
  color: ${(p) => (p.empty ? p.variant.fg : p.variant.active.fg)};
  border: 1px solid ${(p) => (p.empty ? p.variant.bd : p.variant.active.bd)};
  padding: 6px;
  margin: 3px 0px 3px 10px;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 4px;
  outline: none;
  font-size: 0.75rem;
  height: 30px;
  cursor: pointer;

  > span:first-of-type {
    margin-right: 4px;

    :not(:only-child) {
      ::after {
        content: ":";
      }
    }
  }

  > span:last-of-type {
    display: flex;
    align-items: center;
    margin-left: 3px;
  }

  > svg {
    margin-left: 7px;
  }
`;

const _name = styled.span`
  margin-left: 3px;
`;
