import React, { useCallback, useState } from "react";
import { defineMessages } from "react-intl";
import { IAccessGroupNode } from "@web/api/Integration/types";
import { AccessGroupMatrixModel } from "@web/models/AccessControlModel";
import { CreateButton } from "../Flow/CreateButton";
import { EditAccessGroupBox } from "./EditAccessGroupBox";

export const CreateAccessGroupButton = (p: {
  matrix: AccessGroupMatrixModel;
}) => {
  const [isCreatingAccessGroup, setCreatingAccessGroup] = useState(false);

  const handleCreate = useCallback(
    (json: Pick<IAccessGroupNode, "name" | "claims" | "description">) => {
      setCreatingAccessGroup(false);
      p.matrix.createAccessGroup(json);
    },
    [isCreatingAccessGroup]
  );

  return (
    <>
      <CreateButton
        text={texts.title}
        onClick={() => setCreatingAccessGroup(true)}
      />
      {isCreatingAccessGroup && (
        <EditAccessGroupBox
          isCreating={true}
          onCreate={handleCreate}
          onCancel={() => setCreatingAccessGroup(false)}
        />
      )}
    </>
  );
};

const texts = defineMessages({
  title: {
    id: "accessControl.addGroupButton.title",
    defaultMessage: "Add new access group",
  },
});
