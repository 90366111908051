import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";

export const VersionTag: React.FC<{
  shorthand?: boolean;
  versionNumber: number;
}> = (p) => {
  const label = p.shorthand ? texts.labelShort : texts.labelFull;

  return (
    <_tag>
      <FormattedMessage {...label} />
      {!p.shorthand && " "}
      {p.versionNumber}
    </_tag>
  );
};

const texts = defineMessages({
  labelShort: {
    id: "versiontag.label.short",
    defaultMessage: "v",
  },
  labelFull: {
    id: "versiontag.label.full",
    defaultMessage: "version",
  },
});

const _tag = styled.div`
  background: #d8f7ea;
  color: #2b061f;
  display: inline-block;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0 0.125rem;
  white-space: nowrap;
`;
