import React from "react";

export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 4.5C20 4.22386 19.7761 4 19.5 4H16.7867C16.6105 4 16.4473 3.90721 16.3571 3.75578L15.4572 2.24422C15.367 2.09279 15.2038 2 15.0276 2H9.64244C9.4662 2 9.30297 2.09279 9.21282 2.24422L8.3129 3.75578C8.22274 3.90721 8.05952 4 7.88327 4H5.5C5.22386 4 5 4.22386 5 4.5V6.5C5 6.77614 5.22386 7 5.5 7H19.5C19.7761 7 20 6.77614 20 6.5V4.5Z"
      fill="var(--svg-fill, currentColor)"
    />
    <path
      d="M6.12423 10.615C6.05722 9.74385 6.74605 9 7.61982 9H17.3802C18.2539 9 18.9428 9.74385 18.8758 10.615L18.1065 20.615C18.0464 21.3965 17.3948 22 16.611 22H8.38905C7.60524 22 6.95358 21.3965 6.89346 20.615L6.12423 10.615Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
