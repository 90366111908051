import React from "react";
import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime,
} from "react-intl";
import { differenceInCalendarDays } from "date-fns";

/**
 * Inspired by react-intl components <FormattedDate> & <FormattedRelativeTime>,
 * we've decided we want to experiment with a combination of those two.
 *
 * Rationale is to use relative date formatting, like "today" and "yesterday"
 * when the provided dates are near past dates. Primarily because several of us
 * has felt more confused than enlightened when seeing dates like "5 days ago".
 * What does that mean in practise, when was 5 days ago? ..end-users gotta do
 * that mental exercise of calculating the actual date themselfs.
 *
 * Whilst seeing "today" and "yesterday" is a lot less mental burden.
 */
export const DMFormattedDate: React.FC<{
  value: string | Date;
}> = ({ value }) => {
  const date = value instanceof Date ? value : new Date(value);
  const daysSinceDate = differenceInCalendarDays(date, Date.now());

  return daysSinceDate >= -1 ? (
    <>
      <FormattedRelativeTime value={daysSinceDate} numeric="auto" unit="day" />{" "}
      <FormattedTime
        value={date}
        hour="2-digit"
        minute="2-digit"
        hour12={false}
      />
    </>
  ) : (
    <FormattedDate value={date} year="numeric" day="2-digit" month="long" />
  );
};
