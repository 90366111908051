import styled from "styled-components";

export const Pulsating = styled.div`
  animation: pulse 300ms infinite alternate;
  @keyframes pulse {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 0.7;
    }
  }
`;
