import styled from "styled-components";
import { vars } from "@web/styles";

export const Select = styled.select`
  border: none;
  margin: 0px;
  padding: 8px;
  border: none;
  border-radius: 3px;
  color: ${vars.infoFg};
  padding-right: 30px;
  font-size: inherit;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="grey" d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>');
  background-position: right 8px top 50%;
  background-repeat: no-repeat;
  appearance: none;
  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
`;
