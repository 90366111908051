import React, { useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { DocumentModel } from "@web/models";
import { FileType, SkeletonText } from "@web/elements";
import { HighlightText } from "@web/elements/HighlightText";
import { maxLines, vars } from "@web/styles";
import { generateDocumentUrl } from "@web/utils/URLHelpers";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { DocumentSignatureBadge } from "@web/components/Document/DocumentSignatureBadge";

interface IStyle {
  previewSize?: number;
  hidePreview?: boolean;
}

interface IDocumentCardProps extends IStyle {
  document: DocumentModel;
  onClick?: () => void;
}

const DEFAULT_PREVIEW_SIZE = 72;

export const DocumentCard = observer((p: IDocumentCardProps) => {
  const [searchParams] = useSearchParams();
  const documentVersion = p.document.newestVersion;
  const highlights = p.document.searchHighlights;
  const size = p.previewSize || DEFAULT_PREVIEW_SIZE;

  useEffect(() => {
    p.document.pollForPreviewUpdates();
  }, []);

  if (!documentVersion) {
    return null;
  }

  const handleClick = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }
    e.preventDefault();
    p.onClick?.();
  };

  const linkProps = p.onClick && {
    to: generateDocumentUrl(p.document.id) + `?${searchParams.toString()}`,
    onClick: handleClick,
  };

  return (
    <_wrap
      as={p.onClick ? undefined : "div"}
      role="article"
      size={size}
      {...linkProps}
    >
      <_media>
        <DocumentThumbnail
          documentVersion={documentVersion}
          size={size}
          hidePreview={p.hidePreview}
        />
      </_media>
      <_content>
        <_title>
          <HighlightText
            highlights={highlights?.title}
            fallback={p.document.title}
          />
          <_signature>
            <DocumentSignatureBadge document={p.document} />
          </_signature>
        </_title>
        <_fileType>
          <FileType type={documentVersion.fileExt} />
        </_fileType>
        <_metaText>
          <HighlightText highlights={highlights?.fileContent} />
        </_metaText>
      </_content>
    </_wrap>
  );
});

export const SkeletonDocumentCard = (
  p: Pick<IDocumentCardProps, "previewSize">
) => (
  <_wrap
    as="div"
    size={p.previewSize || DEFAULT_PREVIEW_SIZE}
    data-testid="skeleton-document-card"
  >
    <_media />
    <_content>
      <_title>
        <SkeletonText length={10} />
      </_title>
      <_fileType>
        <SkeletonText length={3} />
      </_fileType>
    </_content>
  </_wrap>
);

export const EmptyDocumentCard = (p: {
  previewSize: number;
  alignContent?: "flex-start" | "flex-end" | "center";
  media: React.ReactNode;
  title: React.ReactNode;
}) => (
  <_wrap as="div" size={p.previewSize || DEFAULT_PREVIEW_SIZE}>
    <_media>{p.media}</_media>
    <_content style={{ alignSelf: p.alignContent }}>
      <_title>{p.title}</_title>
    </_content>
  </_wrap>
);

SkeletonDocumentCard.displayName = "SkeletonDocumentCard";

const _wrap = styled(Link).attrs<{ size: number }>((p) => ({
  style: {
    "--DocumentCard-thumbSize": p.size + "px",
  },
}))<{ size: number }>`
  background: ${vars.content};
  border: 2px solid transparent;
  border-radius: 0.5rem;
  color: ${vars.contentFg};
  display: flex;
  font-size: 0.8125rem;
  width: 100%;
`;

const _media = styled.div`
  background: ${vars.content};
  border: 1px solid ${vars.secondaryAltLight20};
  border-radius: 4px;
  color: ${vars.secondaryAltLight10};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--DocumentCard-thumbSize);
  height: var(--DocumentCard-thumbSize);
  margin-right: 0.875rem;
  overflow: hidden;

  img,
  svg {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const _content = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const _title = styled.div`
  margin-top: 0.25rem;
  word-break: break-word;
  ${maxLines(3)};
`;

const _signature = styled.span`
  font-size: 1rem;
  vertical-align: middle;
  margin-left: 2px;
`;

const _fileType = styled.div`
  margin-top: 0.5rem;
`;

const _metaText = styled.div`
  opacity: 0.75;
  margin-top: 0.5rem;
  font-size: 0.825em;
  ${maxLines(4)};
`;
