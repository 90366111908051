import React, { CSSProperties, useContext, useEffect, useRef } from "react";
import { SupportedLocale } from "@web/translations";
import { GlobalConfig } from "@config/context";

interface IProps {
  electronicDocumentId: number;
  previewTimestamp?: number;
  searchQuery?: string;
}

const IFRAME_STYLE: CSSProperties = {
  width: "100%",
  height: "100%",
  border: "none",
};

export const PDFPreview: React.FC<IProps> = ({
  electronicDocumentId,
  previewTimestamp,
  searchQuery = "",
}) => {
  const { locale } = useContext(GlobalConfig);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  let previewUrl = `/bff/file-preview/${electronicDocumentId}`;
  if (previewTimestamp) {
    previewUrl += `?timestamp=${previewTimestamp}`;
  }

  const viewerUrl = new URL(
    "/pdfjs-2.12.313-dist/web/viewer.html",
    window.location.origin
  );

  useEffect(() => {
    function handleViewerLoaded(event: Event) {
      const pdfViewerOptions = (event as CustomEvent).detail?.source
        ?.PDFViewerApplicationOptions;
      const pdfViewerApp = (event as CustomEvent).detail?.source
        ?.PDFViewerApplication;

      if (pdfViewerOptions !== undefined) {
        pdfViewerOptions.set("locale", localeToPdfjsCompatibleLocale(locale));
      } else {
        console.warn(
          "Could not set PDF.js .locale option because global PDF viewer application object was not found"
        );
      }

      if (pdfViewerApp !== undefined) {
        pdfViewerApp.initializedPromise.then(() => {
          if (searchQuery) {
            // Needed because PDF.js does not currently fill in the search text field with the current search query.
            //
            // Can be removed when changes in the upstream PR is released in a new PDF.js version.
            //
            // Upstream PR: https://github.com/mozilla/pdf.js/pull/12141
            pdfViewerApp.findBar.findField.value =
              queryToPdfjsCompatibleQuery(searchQuery);
          }

          // Override PDF.js dragover/drop listeners to disable drag/drop files into viewer
          ["dragover", "drop"].forEach((event) =>
            pdfViewerApp.appConfig.mainContainer.addEventListener(
              event,
              (e: DragEvent) => {
                e.stopPropagation();

                // By setting the iframe pointer-events to "none" for a brief moment, we allow the Dropzone to trigger the overlay.
                if (event === "dragover" && iframeRef.current) {
                  const el = iframeRef.current;
                  el.style.pointerEvents = "none";
                  setTimeout(() => (el.style.pointerEvents = "initial"), 300);
                }
              }
            )
          );
        });
      } else {
        console.warn("The global PDF viewer application object was not found");
      }
    }

    window.addEventListener("webviewerloaded", handleViewerLoaded, false);

    return () =>
      window.removeEventListener("webviewerloaded", handleViewerLoaded);
  }, []);

  viewerUrl.searchParams.set("file", previewUrl);
  viewerUrl.hash = queryToPdfjsSearchHash(searchQuery);

  return (
    <iframe
      aria-label="PDF viewer"
      ref={iframeRef}
      src={viewerUrl.toString()}
      style={IFRAME_STYLE}
    />
  );
};

function localeToPdfjsCompatibleLocale(locale: SupportedLocale): string {
  switch (locale) {
    case SupportedLocale.NB:
      return "nb-NO";
    case SupportedLocale.EN:
      return "en-GB";
  }
}

function queryToPdfjsCompatibleQuery(searchQuery: string) {
  // remove "*" and "~" at the end of the query and quotes usually related to phrases, as PDF.js does not understand these
  return searchQuery.replace(/[*~]$/g, "").replace(/"/g, "");
}

function queryToPdfjsSearchHash(searchQuery: string) {
  const cleanQuery = queryToPdfjsCompatibleQuery(searchQuery);
  const phrases = searchQuery.match(/"[^"]+"/g) ?? [];
  const isOneSearchPhrase = phrases.length === 1 && searchQuery === phrases[0];

  if (cleanQuery.length === 0) {
    return "";
  } else if (phrases.length === 0) {
    return `search=${cleanQuery}`;
  } else if (isOneSearchPhrase) {
    return `search=${cleanQuery}&phrase=true`;
  }

  return "";
}
