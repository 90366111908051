import React from "react";

export const TextIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <rect x="12" y="18" width="48" height="7.5" rx="1" fill="currentColor" />
    <rect x="12" y="31.5" width="48" height="7.5" rx="1" fill="currentColor" />
    <rect x="12" y="45" width="35.2" height="7.5" rx="1" fill="currentColor" />
  </svg>
);
