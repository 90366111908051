import React from "react";
import { default as styled } from "styled-components";
import { VBox } from "@web/elements";
import { GlobalStyle, vars } from "@web/styles";
import { Logo } from "@web/elements/Logo";

interface UnhandledErrorProps {
  unhandledErrorTitle: string;
  unhandledErrorMessage: string;
  retryLinkText: string;
}

export const UnhandledErrorPage = (props: UnhandledErrorProps) => (
  <>
    <GlobalStyle />
    <_wrap>
      <_pageError width="100%" height="100vh">
        <_logo />
        <h1>{props.unhandledErrorTitle}</h1>
        <p>{props.unhandledErrorMessage}</p>
        <a href="/">{props.retryLinkText}</a>
      </_pageError>
    </_wrap>
  </>
);

const _logo = styled(Logo)`
  color: ${vars.primary};
  width: 4rem;
  height: 4rem;
`;

const _wrap = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  grid-gap: 1rem;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
  width: 100vw;
  height: 100vh;

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

const _pageError = styled(VBox)`
  justify-content: center;
  align-items: center;
`;
