import React from "react";
import { observer } from "mobx-react";
import { IFilePreview } from ".";
import { IconPreview } from "./IconPreview";
import { getResizedUrl } from "@web/utils/gatsby-helpers";

const elem = document.createElement("video");
function canPlay(contentType = 'video/mp4; codecs="avc1.42E01E"'): boolean {
  if (elem.canPlayType) {
    const result: CanPlayTypeResult = elem.canPlayType(contentType);
    return result === "probably" || result === "maybe";
  }
  return false;
}

export const VideoPreview = observer((p: IFilePreview) => {
  const { documentPreviewImage, electronicDocumentId, fileExt } = p.version;
  if (!documentPreviewImage) {
    return null;
  }
  const { width, height, contentType } = documentPreviewImage;

  if (!canPlay(contentType)) {
    return <IconPreview type={fileExt} />;
  }

  return (
    <video
      key={`video-${electronicDocumentId}`}
      controls
      preload="none"
      poster={getResizedUrl(
        String(electronicDocumentId),
        fileExt,
        Math.round(p.maxWidth),
        width,
        height
      )}
      style={{
        margin: "0 auto",
        width: "100%",
        height: "100%",
        maxWidth: width + "px",
        maxHeight: height + "px",
      }}
    >
      <source
        src={`/bff/file-preview/${electronicDocumentId}`}
        type={contentType}
      />
    </video>
  );
});
