import React, { FC } from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { vars } from "@web/styles";

interface Props {
  title: MessageDescriptor;
  onClick?: () => void;
}

export const AddLink: FC<Props> = (p) => {
  const intl = useIntl();

  return <_wrap onClick={p.onClick}>+ {intl.formatMessage(p.title)}</_wrap>;
};

const _wrap = styled.button`
  text-align: center;
  padding: 15px;
  background: inherit;
  font-size: 1.3em;
  color: ${vars.primaryLight10};
  cursor: pointer;
  border: none;
  border-top: 2px solid ${vars.dark05};
  margin-top: 10px;
`;
