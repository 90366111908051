import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { FileAddIcon } from "@web/elements/Icons/FileTypes/FileAddIcon";
import { LightBulbIcon } from "@web/elements/Icons";
import { isMedia } from "@web/styles/utils";
import { vars } from "@web/styles";
import { DocumentUploadRequest, EntryModel } from "@web/models";
import { useConfig } from "@config/context";
import { DocumentUploadButton } from "..";
import commonCardTexts from "./texts";
import { UploadResult } from "@web/components/Upload/types";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";

export const EmptyCardHelp = (p: {
  entry: EntryModel;
  onUploadRequest: (
    request: DocumentUploadRequest
  ) => Promise<UploadResult | undefined>;
}) => {
  const { canUploadDocuments } = useConfig();
  const disableUpload = !p.entry.canAddDocument || !canUploadDocuments;
  const getConfirmation = useDuplicateUploadConfirmDialog();

  return (
    <_wrap>
      <_box>
        {!disableUpload && (
          <DocumentUploadButton
            entry={p.entry}
            onUpload={p.onUploadRequest}
            getConfirmation={getConfirmation}
          >
            <_boxIcon>
              <FileAddIcon />
            </_boxIcon>
            <_boxText>
              {isMedia("touch") ? (
                <FormattedMessage {...commonCardTexts.tapToAdd} />
              ) : (
                <>
                  <FormattedMessage
                    id="entry.emptyentry.dragndroptoadd.line1"
                    defaultMessage="Drag and drop or <em>browse</em>"
                  />
                  <br />
                  <FormattedMessage
                    id="entry.emptyentry.dragndroptoadd.line2"
                    defaultMessage="to add documents"
                  />
                </>
              )}
            </_boxText>
          </DocumentUploadButton>
        )}
      </_box>

      <_caption>
        <_captionIcon>
          <LightBulbIcon width="30" height="30" />
        </_captionIcon>
        <_captionText>
          <FormattedMessage
            id="entry.emptyentry.helptext"
            defaultMessage="This is a folder. Use folders to collect documents that are closely related
            (e.g. a contract with attachment, a photo album, meeting documents etc)."
          />
        </_captionText>
      </_caption>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  max-width: 510px;
  margin: 10px auto;
  padding: 1rem;
`;

const _box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${vars.secondaryAltLight20};
  border: 2px dashed ${vars.secondaryAltLight10};
  border-radius: 4px;
  color: ${vars.secondaryAltLight10};
  font-family: inherit;
  flex-grow: 1;
  max-height: 400px;
  cursor: pointer;
`;

const _boxIcon = styled.div`
  width: 160px;
  height: 160px;
  margin: 0 auto;
  svg {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const _boxText = styled.div`
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 20px;
  color: ${vars.secondaryAltFg};
  > em {
    color: ${vars.primary};
    font-style: inherit;
  }
`;

const _caption = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
`;

const _captionIcon = styled.div`
  padding-top: 10px;
  width: 42px;
  flex-shrink: 0;
  color: ${vars.primaryDark20};
`;

const _captionText = styled.div`
  font-size: 0.8125rem;
  line-height: 1.4em;
  color: ${vars.contentFg};
`;
