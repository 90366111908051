import React from "react";

export const CheckboxCheckedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="var(--svg-fill, white)"
        stroke="var(--svg-stroke, white)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2764 6.0864L6.47283 11.89L3.33333 8.75837L4.75291 7.34879L6.47208 9.06129L10.8667 4.66667L12.2764 6.0864Z"
        fill="var(--svg-check, black)"
      />
    </svg>
  );
};

export const CheckboxUncheckedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="var(--svg-fill, white)"
        stroke="var(--svg-stroke, white)"
      />
    </svg>
  );
};

export const CheckboxIndeterminateIcon = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="var(--svg-fill, white)"
        stroke="var(--svg-stroke, white)"
      />
      <path
        d="M4 6.66667H12V9.33333H4V6.66667Z"
        fill="var(--svg-check, black)"
      />
    </svg>
  );
};
