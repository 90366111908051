import React from "react";

export const CreateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="var(--svg-fill-bg, #E5E8FD)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0008 10.5656C11.0008 10.8417 10.7769 11.0656 10.5008 11.0656L6.62638 11.0656C6.35024 11.0656 6.12638 11.2894 6.12638 11.5656L6.12638 12.5656C6.12638 12.8417 6.35024 13.0656 6.62638 13.0656L10.5008 13.0656C10.7769 13.0656 11.0008 13.2894 11.0008 13.5656V17.44C11.0008 17.7162 11.2246 17.94 11.5008 17.94H12.5008C12.7769 17.94 13.0008 17.7162 13.0008 17.44L13.0008 13.5656C13.0008 13.2894 13.2246 13.0656 13.5008 13.0656L17.3752 13.0656C17.6514 13.0656 17.8752 12.8417 17.8752 12.5656L17.8752 11.5656C17.8752 11.2894 17.6514 11.0656 17.3752 11.0656L13.5008 11.0656C13.2246 11.0656 13.0008 10.8417 13.0008 10.5656L13.0008 6.69118C13.0008 6.41504 12.7769 6.19118 12.5008 6.19118L11.5008 6.19118C11.2246 6.19118 11.0008 6.41504 11.0008 6.69118L11.0008 10.5656Z"
      fill="var(--svg-fill-fg, #88134A)"
    />
  </svg>
);
