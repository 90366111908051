import { defineMessages } from "react-intl";

const commonStoreTexts = defineMessages({
  somethingWentWrong: {
    id: "common.stores.message.somethingwentwrong",
    defaultMessage: "Something went wrong",
  },
  updateCanTakeAWhile: {
    id: "common.stores.message.updatecantakeawhile",
    defaultMessage: "This could take a while…",
  },
  updateComplete: {
    id: "common.stores.message.updatecomplete.title",
    defaultMessage: "Update complete",
  },
  updatedWithTitle: {
    id: "common.stores.message.updatecomplete.textwithtitle",
    defaultMessage: "Updated {title}",
  },
  updatedFailedWithTitle: {
    id: "common.stores.message.updatefailed.textwithtitle",
    defaultMessage: "Failed to update {title}",
  },

  movingSection: {
    id: "selection.message.movingentry",
    defaultMessage: "Moving {count, plural, one {folder} other {folders}}…",
  },
  movingSectionSuccessTitle: {
    id: "selection.message.movingentry.success.title",
    defaultMessage: "{count, plural, one {Folder} other {Folders}} moved",
  },
  movingSectionSuccessText: {
    id: "selection.message.movingentry.success.text",
    defaultMessage: "It might take a moment…",
  },
  movingSectionFailure: {
    id: "selection.message.movingentry.failed",
    defaultMessage:
      "Moving {count, plural, one {folder} other {folders}} failed",
  },
});

export default commonStoreTexts;
