import React, { useRef, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import { observer } from "mobx-react";
import { EntryModel } from "@web/models";
import { Tooltip } from "@web/elements/Tooltip";
import {
  AnimatedNotificationsIcon,
  NotificationsIcon,
} from "@web/elements/Icons";
import { vars } from "@web/styles";
import { IconBadge } from "@web/elements/IconBadge";
import { PopoverBox } from "@web/elements";
import { RemindersDialog } from "./RemindersDialog";
import { StyledIconButton } from "@web/elements/Button/styles";

interface RemindersButtonProps {
  tooltipText: MessageDescriptor;
  entry: EntryModel;
  // exposed to make it easier to create pleasant storybooks
  displayRemindersInitially?: boolean;
}

export const RemindersButton = observer((p: RemindersButtonProps) => {
  const intl = useIntl();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [displayRemindersDialog, setDisplayRemindersDialog] = useState(
    Boolean(p.displayRemindersInitially)
  );
  const closeDialog = () => setDisplayRemindersDialog(false);

  if (p.entry.isDraft) {
    return null;
  }

  const remindersCount = p.entry.remindersCount;
  const hasOverdueReminders = p.entry.hasOverdueReminders;

  return (
    <>
      <Tooltip text={p.tooltipText}>
        <_button
          variant="primaryInverted"
          ref={buttonRef}
          color={hasOverdueReminders ? vars.danger : undefined}
          onClick={() => setDisplayRemindersDialog(!displayRemindersDialog)}
          aria-label={intl.formatMessage(p.tooltipText)}
        >
          {hasOverdueReminders ? (
            <AnimatedNotificationsIcon />
          ) : (
            <NotificationsIcon />
          )}
          <_badge hidden={remindersCount < 1}>
            <IconBadge>{remindersCount}</IconBadge>
          </_badge>
        </_button>
      </Tooltip>

      {displayRemindersDialog && (
        <PopoverBox
          triggerRef={buttonRef}
          margin={10}
          alignY="top"
          alignX="end-left-of-trigger"
          maxWidth={300}
          onClickOutside={closeDialog}
        >
          <RemindersDialog entry={p.entry} onCloseClick={closeDialog} />
        </PopoverBox>
      )}
    </>
  );
});

const _button = styled(StyledIconButton)<{ color?: string }>`
  color: ${(p) => p.color};
  gap: 0;
  width: auto;
  padding: 0.375em;
`;

const _badge = styled.span`
  width: 1rem;
  transform: scale(1);
  transition: 200ms ease-in-out;

  &[hidden] {
    display: inherit;
    width: 0;
    transform: scale(0);
  }
`;
