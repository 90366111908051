import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import { TagModel, ClassModel, SectionModel } from "@web/models";
import { ActionMenu, Input } from "@web/elements";
import { Button } from "@web/elements/Button";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

type SupportedEntity = TagModel | ClassModel | SectionModel;

interface BaseProps<T extends SupportedEntity> {
  onValueSave: (doSave: boolean, newValue: string, oldValue?: string) => void;
  getSimilarValues?: (input: string, currentValue?: string) => Promise<T[]>;
  similarValues?: T[];
  currentEntity?: T;
  suggestedValue?: string;
  saveText?: MessageDescriptor;
  isSaving?: boolean;
}

interface TagProps extends BaseProps<TagModel> {
  type: "tag";
  onOptionSelect?: (option: TagModel) => void;
}

interface ClassificationProps extends BaseProps<ClassModel> {
  type: "classification";
}

interface SectionProps extends BaseProps<SectionModel> {
  type: "section";
}

type Props = TagProps | ClassificationProps | SectionProps;

export const AddValueBox: React.FC<Props> = observer((p) => {
  const intl = useIntl();
  const { onValueSave, currentEntity } = p;
  const currentTitle = currentEntity?.title;

  const initialInput = p.suggestedValue || currentTitle || "";

  const [input, setInput] = useState(initialInput);
  const [error, setError] = useState<MessageDescriptor>();
  const [options, setOptions] = useState<SupportedEntity[]>([]);
  const [isVerifying, setIsVerifying] = useState(false);

  const saveText =
    p.saveText ?? (currentEntity ? commonTexts.save : commonTexts.add);
  const placeholder = currentTitle
    ? initialInput
    : intl.formatMessage(texts.placeholder, { entity: p.type });

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInput(newValue);
    setError(undefined);
    if (newValue === "" || newValue === initialInput) {
      setOptions([]);
      return;
    }

    const trimmed = newValue.trim();

    let similarValues: SupportedEntity[] = p.similarValues || [];
    if (p.getSimilarValues) {
      setIsVerifying(true);
      similarValues = await p.getSimilarValues(trimmed, currentTitle);
      setError(undefined);
      setIsVerifying(false);
    }

    // Allow existing values to be changed into same value with different case,
    // but don't allow for different case values in any other cases.
    const alreadyExists = similarValues.find((o) => {
      if (
        !currentTitle ||
        currentTitle.toLowerCase() !== o.title.toLowerCase()
      ) {
        return o.title.toLowerCase() === trimmed.toLowerCase();
      }
      return o.title === trimmed;
    });

    if (alreadyExists) {
      setError(texts.alreadyExists);
      setOptions([]);
      return;
    }
    setOptions(similarValues);
  };

  const canSave = () => {
    if (p.isSaving) {
      return false;
    }

    if (input.trim().length === 0 || error !== undefined || isVerifying) {
      return false;
    }

    // Verify if title has changed only if we are editing an
    // existing entity, and not if we are adding a new entity.
    if (currentTitle && initialInput === input.trim()) {
      return false;
    }

    return true;
  };

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    const { onValueSave, currentEntity } = p;
    onValueSave(true, input.trim(), currentEntity?.title);
  };

  return (
    <_wrap onSubmit={handleSubmit}>
      <_content style={{ flex: "1" }}>
        <_header>
          <h3>
            {intl.formatMessage(texts.title, {
              isNew: !p.currentEntity,
              entity: p.type,
            })}
          </h3>
        </_header>

        <Input
          autoFocus
          value={input}
          placeholder={placeholder}
          onChange={handleInputChange}
        />

        {error && <_error>{intl.formatMessage(error)}</_error>}

        {options.length > 0 && !error && (
          <_options>
            <b>
              {intl.formatMessage(texts.similarValues, {
                entity: p.type,
              })}
            </b>
            <ul>
              {options.map((option) => (
                <li key={option.uuid}>
                  {option.title}
                  {p.type === "tag" && p.onOptionSelect && (
                    <Button
                      onClick={() => p.onOptionSelect?.(option as TagModel)}
                      text={commonTexts.select}
                    />
                  )}
                </li>
              ))}
            </ul>
          </_options>
        )}
      </_content>

      {p.type === "tag" && (
        <_info>{intl.formatMessage(texts.addTagInfo)}</_info>
      )}

      <_content>
        <ActionMenu
          direction="vertical"
          applyIsDisabled={!canSave()}
          onApply={handleSubmit}
          onCancel={() => onValueSave(false, input)}
          applyText={p.isSaving ? commonTexts.saving : saveText}
        />
      </_content>
    </_wrap>
  );
});

const _wrap = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 0px;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  border-radius: 3px;
`;

const _header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    padding: 5px 0px 0px 0px;
    margin: 0 0 14px 0;
    font-size: 14px;
    font-weight: 500;
  }
  p {
    margin: 0 0 10px 0;
  }
`;

const _options = styled.div`
  padding: 10px 5px 0px 5px;
  margin-bottom: 10px;
  max-height: 175px;
  overflow-y: auto;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    align-items: center;
  }
`;

const _info = styled.div`
  font-size: 11px;
  background: ${vars.info};
  color: ${vars.infoFg};
  margin: 10px;
  padding: 8px;
`;

const _content = styled.div`
  padding: 0px 10px;
  label {
    width: 100%;
  }
`;

const _error = styled.div`
  background: ${vars.danger};
  color: ${vars.dangerFg};
  width: 100%;
  padding: 3px;
  border-radius: 1px;
  text-align: center;
  margin-top: 10px;
`;

const texts = defineMessages({
  alreadyExists: {
    id: "addvaluebox.alreadyexists",
    defaultMessage: "This value already exists",
  },
  similarValues: {
    id: "addvaluebox.similarvalues.title",
    defaultMessage:
      "Similar {entity, select, tag {tags} section {sections} classification {lists}}",
  },
  title: {
    id: "addvaluebox.title",
    defaultMessage:
      "{isNew, select, true {Add} false {Edit}} {entity, select, tag {tag} section {section} classification {list}}",
  },
  placeholder: {
    id: "addvaluebox.placeholder",
    defaultMessage:
      "{entity, select, tag {Tag} section {Section} classification {List}} title",
  },
  addTagInfo: {
    id: "addvaluebox.addtaginfo",
    defaultMessage:
      "When adding new tags, make sure there are no duplicates, and that you stick to a consistent wording",
  },
});
