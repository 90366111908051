import { MessageDescriptor } from "react-intl";
import { FlowEntityType, PipelineType } from "@web/models";

export const pipelineTexts: Record<
  PipelineType,
  Record<string, MessageDescriptor>
> = {
  validation: {
    edit: {
      id: "flow.settings.pipeline.validation.edit",
      defaultMessage: "Edit checklist",
    },
    create: {
      id: "flow.settings.pipeline.validation.create",
      defaultMessage: "Create checklist",
    },
    enabled: {
      id: "flow.settings.pipeline.validation.enabled",
      defaultMessage: "Enabled",
    },
    disabled: {
      id: "flow.settings.pipeline.validation.disabled",
      defaultMessage: "Disabled",
    },
    target: {
      id: "flow.settings.pipeline.validation.target",
      defaultMessage: "Target",
    },
    notValid: {
      id: "flow.settings.pipeline.not.valid",
      defaultMessage: "Pipeline can not be enabled",
    },
  },
  notification: {
    edit: {
      id: "flow.settings.pipeline.notification.edit",
      defaultMessage: "Edit notification",
    },
    create: {
      id: "flow.settings.pipeline.notification.create",
      defaultMessage: "Create notification",
    },
  },
  reminder: {
    edit: {
      id: "flow.settings.pipeline.reminder.edit",
      defaultMessage: "Edit reminder",
    },
    create: {
      id: "flow.settings.pipeline.reminder.create",
      defaultMessage: "Create reminder",
    },
  },
};

export const flowEntityTexts: Record<
  FlowEntityType,
  Record<string, MessageDescriptor>
> = {
  rules: {
    name: {
      id: "flow.settings.rule.name.placeholder.required",
      defaultMessage: "Rule name (required)",
    },
    description: {
      id: "flow.settings.rule.description.placeholder",
      defaultMessage: "Rule description",
    },
  },
  details: {},
  events: {},
  conditions: {},
  outcomes: {
    commentText: {
      id: "flow.settings.outcome.comment.placeholder",
      defaultMessage: "Comment text…",
    },
    addEntryComment: {
      id: "flow.settings.outcome.add.entry.comment",
      defaultMessage: "Add comment to folder",
    },
    changeEntryStatus: {
      id: "flow.settings.outcome.change.entry.status",
      defaultMessage: "Change folder color to",
    },
    sendSlackNotification: {
      id: "flow.settings.outcome.send.slack.notification",
      defaultMessage: "Send notification to Slack",
    },
    sendTeamsNotification: {
      id: "flow.settings.outcome.send.teams.notification",
      defaultMessage: "Send notification to Teams",
    },
    entryHasTag: {
      id: "flow.settings.outcome.entry.has.tag",
      defaultMessage: "Folders that include the tag",
    },
    entryHasSection: {
      id: "flow.settings.outcome.entry.has.section",
      defaultMessage: "Folders in section",
    },
    userBelongsToGroup: {
      id: "flow.settings.outcome.user.belongs.to.group",
      defaultMessage: "User belongs to access group",
    },
    tagHasClassification: {
      id: "flow.settings.outcome.tag.has.classification",
      defaultMessage: "Tag has classification (TODO)",
    },
  },
};
