import { useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useStores } from "@web/stores/context";
import { generateEntryUrl, mergeRouteParams } from "@web/utils/URLHelpers";
import { ResultOrderKey } from "@web/models";
import PubSub from "@web/stores/PubSub";

export interface SearchParams {
  // URL path
  documentId?: string;
  entryId?: string;
  sectionId?: string;
  // URL query
  tags?: string;
  customFilters?: string;
  query?: string;
  order?: ResultOrderKey;
  tagStatus?: string;
  attributes?: string;
}

// Use this to get type safety
type PathParams = "documentId" | "sectionId" | "entryId";

export const useSearchNavigationHandler = () => {
  const [searchParams] = useSearchParams();
  const pathParams = useParams<PathParams>();
  const navigate = useNavigate();

  const {
    documentStore,
    resultStore,
    recordStore,
    sectionStore,
    filterStore,
    flowStore,
    searchStore,
    multiSelectStore,
  } = useStores();

  const handleInitialSectionAndSearch = async (params: SearchParams) => {
    let sectionId = params.sectionId;
    if (!sectionId && !params.entryId && !params.documentId) {
      await sectionStore.loadInitialSections(undefined);
      if (sectionStore.isEmptyHierarchy) {
        return; // TODO: show "empty documaster" page
      }

      if (sectionStore.selectedSection) {
        sectionId = String(sectionStore.selectedSection.id);
        navigate(`/section/${sectionId}`, { replace: true });
      }
    } else if (params.documentId) {
      await documentStore.documentLoaded();
      sectionId = String(documentStore.document?.sectionId);
      sectionStore.loadInitialSections(sectionId);
    } else if (params.entryId) {
      await recordStore.entryLoaded();
      sectionId = String(recordStore.entry?.sectionId);
      sectionStore.loadInitialSections(sectionId);
    } else {
      sectionStore.loadInitialSections(params.sectionId);
    }

    if (sectionId === "all") {
      sectionStore.isGlobalSearch = true;
    }

    searchStore.setSearchQuery(params.query);
    resultStore.startEntrySearch({ ...params, sectionId });

    if (recordStore.entry) {
      await resultStore.resultsLoaded();
      resultStore.focus(recordStore.entry.id);
    }
  };

  useEffect(() => {
    const params = mergeRouteParams(searchParams, pathParams);

    filterStore.loadInitialTags(params.tags);
    filterStore.loadInitialSelectedTagStatuses(params.tagStatus);
    filterStore.loadInitialSelectedCustomValues(params.customFilters);
    filterStore.attributeFilters.initFromURLparameter(params.attributes);
    flowStore.loadChecklists();

    handleInitialSectionAndSearch(params);
  }, []);

  useEffect(() => {
    const params = mergeRouteParams(searchParams, pathParams);
    resultStore.startEntrySearch(params);
  }, [searchParams, pathParams.sectionId]);

  useEffect(() => {
    if (multiSelectStore.entries.action !== "Move") {
      multiSelectStore.entries.clearSelection();
    }
  }, [pathParams.sectionId]);

  useEffect(() => {
    // Handles click on home link: "/"
    if (
      !pathParams.sectionId &&
      !pathParams.entryId &&
      !pathParams.documentId
    ) {
      if (sectionStore.selectedSection) {
        const sectionId = String(sectionStore.selectedSection.id);
        navigate(`/section/${sectionId}`, { replace: true });
      }
      filterStore.resetSelectedFilterValues();
      searchStore.clearSearchQuery();
    }
  }, [pathParams]);

  useEffect(() => {
    const pubSub = PubSub.getInstance();
    const unsubscribe = pubSub.subscribe({
      onDataAdded: (event) => {
        if (event.type === "Entry" && event.context === "draft-entry") {
          navigate(generateEntryUrl(event.data.id));
        }
      },
    });

    return () => {
      unsubscribe();
    };
  }, []);
};

export const useIsSearchPage = () => {
  const pathParams = useParams<PathParams>();

  if (pathParams.sectionId) {
    return true;
  }

  return false;
};
