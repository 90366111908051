import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import debounce from "lodash/debounce";
import { Portal } from "@web/components/Portal";
import { vars, ZIndex } from "@web/styles";
import { containInViewport } from "@web/utils/helpers";
import { ClickOutside } from "@web/elements";
import { IThemeVariation } from "@web/styles/types";
import { MoreButton } from "@web/elements/Button";

interface IPopupProps {
  open?: boolean;
  on?: "click" | "hover";
  toggle: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;
  variation?: "light" | "dark";
  closeOnOutsideClick?: boolean;
  children: React.ReactNode;
  onOpen?: () => void;
}

interface IPos {
  x: number;
  y: number;
}

enum EDirection {
  Above,
  Below,
}

interface IState {
  content: IPos;
  arrow: IPos;
}

const variants = {
  dark: {
    bg: vars.primaryDark20,
    fg: vars.primaryFg,
    bd: vars.light55,
    active: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.light55,
    },
    hover: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.light55,
    },
  },
  light: {
    bg: vars.content,
    fg: vars.contentFg,
    bd: vars.dark05,
    active: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.light55,
    },
    hover: {
      bg: vars.secondaryAltLight20,
      fg: vars.primaryDark20,
      bd: vars.dark85,
    },
  },
};

export function Dropdown(p: IPopupProps) {
  const eventType = p.on || "click";

  const toggleRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(!!p.open);
  const [direction, setDirection] = useState(EDirection.Below);
  const [position, setPosition] = useState<IState | undefined>(undefined);

  const toggle = () => (isOpen ? close() : open());
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  const updatePosition = debounce(() => {
    if (!toggleRef.current || !popupRef.current || !arrowRef.current) return;

    const toggleRect = toggleRef.current.getBoundingClientRect();
    const popupRect = popupRef.current.getBoundingClientRect();
    const { top, left, viewportHeight } = containInViewport(
      {
        top: toggleRect.top,
        left: toggleRect.left + toggleRect.width / 2 - popupRect.width / 2,
        width: popupRect.width,
        height: popupRect.height,
      },
      8
    );

    const isBelow = top + toggleRect.height + popupRect.height > viewportHeight;

    if (isBelow) {
      setDirection(EDirection.Below);
    } else {
      setDirection(EDirection.Above);
    }

    setPosition({
      content: {
        x: left,
        y: isBelow ? top - toggleRect.height : top + toggleRect.height,
      },
      arrow: {
        x: toggleRect.left + toggleRect.width / 2,
        y: isBelow ? top + toggleRect.height : top + toggleRect.height,
      },
    });
  });

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);

    if (eventType === "click") {
      toggleRef.current?.addEventListener("click", toggle);
    } else {
      toggleRef.current?.addEventListener("mouseover", open);
      toggleRef.current?.addEventListener("mouseout", close);
    }

    if (isOpen) {
      p.onOpen?.();
    }

    return () => {
      toggleRef.current?.removeEventListener("click", toggle);
      toggleRef.current?.removeEventListener("mouseover", open);
      toggleRef.current?.removeEventListener("mouseout", close);
      window.removeEventListener("scroll", updatePosition);
    };
  }, [isOpen]);

  useLayoutEffect(() => {
    updatePosition();
  }, [isOpen]);

  const variation = variants[p.variation ?? "dark"];

  return (
    <>
      {p.toggle(toggleRef)}
      {isOpen && (
        <Portal>
          <_arrow
            ref={arrowRef}
            variation={variation}
            direction={direction}
            x={position?.arrow.x}
            y={position?.arrow.y}
          />
          <_content
            ref={popupRef}
            variation={variation}
            x={position?.content.x}
            y={position?.content.y}
            onClick={close}
          >
            {eventType === "click" && p.closeOnOutsideClick && (
              <ClickOutside onClick={close} />
            )}
            {p.children}
          </_content>
        </Portal>
      )}
    </>
  );
}

Dropdown.defaultProps = {
  closeOnOutsideClick: true,
  toggle: (ref: React.RefObject<HTMLButtonElement>) => <MoreButton ref={ref} />,
};

interface IStyleProps extends Partial<IPos> {
  variation: IThemeVariation;
  direction?: EDirection;
}

const setStyleAttrs = (p: IPos) => ({
  style: {
    top: p.y,
    left: p.x,
  },
});

const upArrow = css<IStyleProps>`
  border-color: ${(p) => p.variation.bg} transparent transparent;
  border-width: 0.25rem 0.25rem 0 0.25rem;
  transform: translate(-50%, 0);
`;

const downArrow = css<IStyleProps>`
  border-color: transparent transparent ${(p) => p.variation.bg};
  border-width: 0 0.25rem 0.25rem 0.25rem;
  transform: translate(-50%, -100%);
`;

const _arrow = styled.div.attrs<IStyleProps>(setStyleAttrs)<IStyleProps>`
  ${(p) => p.direction === EDirection.Above && downArrow};
  ${(p) => p.direction === EDirection.Below && upArrow};
  border-style: solid;
  position: fixed;
  z-index: ${ZIndex.tooltip};
`;

const _content = styled.div.attrs(setStyleAttrs)<IStyleProps>`
  background: ${(p) => p.variation.bg};
  color: ${(p) => p.variation.fg};
  border-radius: 0.25rem;
  box-shadow: ${vars.shadow.z2};
  display: inline-block;
  position: fixed;
  font-size: 1rem;
  z-index: ${ZIndex.tooltip};
`;
