import React from "react";
import styled from "styled-components";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import { MessageDescriptor, useIntl } from "react-intl";
import { vars } from "@web/styles";

interface INavTab extends NavLinkProps {
  icon: React.ReactElement<any>;
  label: MessageDescriptor;
  isHomeLink?: boolean;
}

export const NavTab = (p: INavTab) => {
  const intl = useIntl();
  const location = useLocation();

  const title = intl.formatMessage(p.label);

  const getStyle = (state: { isActive: boolean }) => {
    if (p.isHomeLink) {
      return location.pathname.includes("settings")
        ? STYLE
        : { ...STYLE, ...ACTIVE_STYLE };
    }
    return state.isActive ? { ...STYLE, ...ACTIVE_STYLE } : STYLE;
  };

  return (
    <NavLink title={title} to={p.to} style={getStyle}>
      <_icon>{p.icon}</_icon>
    </NavLink>
  );
};

const _icon = styled.div`
  svg {
    vertical-align: middle;
  }
`;

const STYLE: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
};

const ACTIVE_STYLE: React.CSSProperties = {
  background: vars.primary,
  color: vars.primaryFg,
};
