import React from "react";
import { observer } from "mobx-react";
import { MessageModel } from "@web/models";
import { Message } from "./Message";

interface IProps {
  messages: MessageModel[];
  hideMessage: (message: MessageModel) => void;
  removeMessage: (index: number) => void;
}

export const MessageList = observer((props: IProps) => {
  const { messages, hideMessage, removeMessage } = props;

  return (
    <>
      {messages
        .filter((m) => m.status !== "initial")
        .map((message, index) => (
          <Message
            key={message.id}
            index={index}
            hide={() => hideMessage(message)}
            remove={() => removeMessage(index)}
            {...message}
          />
        ))}
    </>
  );
});
