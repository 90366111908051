import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FocusScope } from "react-aria";
import { SearchSuggestionModel, SearchSuggestionType } from "@web/models";
import { Overlay } from "@web/components";
import { media, useMedia } from "@web/styles/utils";
import { vars } from "@web/styles";
import { IconButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { useUniqueId } from "@web/utils/hooks/useUniqueId";
import { SearchButton } from "./SearchButton";
import { SearchInput } from "./SearchInput";
import { SearchSuggestions } from "./SearchSuggestions";

export interface SearchProps {
  query: string;
  isGlobalSearch: boolean;
  suggestions: SearchSuggestionModel;
  onSubmit: () => void;
  onChange: (value: string) => void;
  onSuggestionClick: (
    suggestions: SearchSuggestionType,
    closeCb: () => void
  ) => void;
  onClear: () => void;
  onGlobalToggle: (checked: boolean) => void;
}

export const Search = observer((p: SearchProps) => {
  const { isCompact } = useMedia();

  const wrapRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const popupId = useUniqueId("searchPopup");
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [[top, left], setPosition] = useState([0, 0]);

  const open = (e: React.MouseEvent) => {
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setPosition([top, left]);
    setSearchOpen(true);
  };

  const close = () => {
    setSearchOpen(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    p.onSubmit();
    close();
  };

  const handleSuggestionClick = (suggestion: SearchSuggestionType) => {
    inputRef.current?.focus();
    p.onSuggestionClick(suggestion, close);
  };

  return (
    <div ref={wrapRef}>
      <SearchButton
        value={p.query}
        ariaControls={popupId}
        ariaExpanded={isSearchOpen}
        onClick={open}
        onClear={p.onClear}
      />
      {isSearchOpen && (
        <Overlay bg="transparent" onEscape={close} onClickOutside={close}>
          <_popup id={popupId} role="combobox" $top={top} $left={left}>
            <_panel>
              <_form onSubmit={handleSubmit}>
                {isCompact && (
                  <IconButton
                    icon="CaretLeftIcon"
                    text={commonTexts.back}
                    disableTooltip
                    size={0.875}
                    tabIndex={-1}
                    onClick={close}
                  />
                )}
                <SearchInput
                  ref={inputRef}
                  suggestionsRef={suggestionsRef}
                  value={p.query}
                  isGlobalSearch={p.isGlobalSearch}
                  onChange={p.onChange}
                  onChangeGlobal={p.onGlobalToggle}
                  onClickClear={p.onClear}
                  onEscape={close}
                />
              </_form>

              <FocusScope>
                <_suggestions ref={suggestionsRef}>
                  <SearchSuggestions
                    suggestions={p.suggestions}
                    onClickSuggestion={handleSuggestionClick}
                    onEscapeFocus={() => inputRef.current?.focus()}
                  />
                </_suggestions>
              </FocusScope>
            </_panel>
          </_popup>
        </Overlay>
      )}
    </div>
  );
});

const PANEL_DESKTOP_WIDTH = 500;

const _popup = styled.div<{
  $top: number;
  $left: number;
}>`
  position: fixed;

  ${media("desktop")} {
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.2));
    top: ${(p) => p.$top}px;
    left: ${(p) => p.$left}px;

    /* If there's too little room for the help section, center the popup */
    @media (max-width: ${(p) => PANEL_DESKTOP_WIDTH + p.$left}px) {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  ${media("compact")} {
    background: ${vars.content};
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const _panel = styled.div`
  display: flex;
  flex-direction: column;

  ${media("desktop")} {
    background: ${vars.content};
    border-radius: 8px;
    width: ${PANEL_DESKTOP_WIDTH}px;
    max-height: calc(100vh - 2rem);
  }

  ${media("compact")} {
    flex: 1;
    max-height: 100vh;
  }
`;

const _form = styled.form`
  ${media("compact")} {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }
`;

const _suggestions = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
`;
