import React from "react";

export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H7.42773V18C7.42773 19.1046 8.32316 20 9.42773 20H18.4277C19.5323 20 20.4277 19.1046 20.4277 18V9C20.4277 7.89543 19.5323 7 18.4277 7H16.4286V5C16.4286 3.89543 15.5331 3 14.4286 3H5ZM14.4286 7L14.4286 5L5 5V15H7.42773V9C7.42773 7.89543 8.32316 7 9.42773 7H14.4286ZM9.42773 9H18.4277V18H9.42773V9Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

CopyIcon.displayName = "CopyIcon";
