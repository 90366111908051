import { RefObject, useEffect, useRef } from "react";

/**
 * This hook exists to make handling clicks outside a React.js component's content
 * element a seemless experience for us as developers.
 *
 * It was created in a time where we had trouble displaying multiple components who
 * were interested in clicks outside their own content;
 *
 * Which of them should be closed?
 * In what order should they be closed?
 *
 * By using this hook, we ensure that behaviour is predictable throughout our app.
 *
 * Conceptually it works like a stack of books. The last component who got displayed
 * and used this hook, will be the one who gets notified of a click outside its content.
 */

type OnClickOutsideListener = {
  contentRef: RefObject<HTMLElement>;
  onClickOutside: (evt: MouseEvent) => void;
};

/**
 * A Last-In-First-Out stack containing listeners who have stated they want to
 * be notified whenever clicks happens *outside* an HTML element they have said
 * to be where their "content" is located.
 *
 * The Last-In-First-Out approach is important because there might be multiple
 * popover/dialog-looking components being shown to the end-user at once.
 *
 * As soon as a click happens, if it is outside of the last listener's "content"
 * element, that listener should be notified, presumably so it can close whatever
 * popover or dialog it shows.
 */
const listenerStack: Array<OnClickOutsideListener> = [];

window.addEventListener(
  "click",
  function handleWindowClick(evt: MouseEvent) {
    const mostRecentListener = listenerStack[0];
    if (!mostRecentListener) {
      return;
    }

    const { contentRef, onClickOutside } = mostRecentListener;
    const ref = contentRef.current;

    if (!ref || ref.contains(evt.target as Element)) {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();
    onClickOutside(evt);
  },
  true
);

export function useClickOutside(onClickOutside: (evt: MouseEvent) => void) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    listenerStack.unshift({ onClickOutside, contentRef });

    return () => {
      listenerStack.shift();
    };
  }, []);

  return contentRef;
}
