import React, { useState } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import { components, MultiValue, OptionProps, SingleValue } from "react-select";
import { observer } from "mobx-react";
import { Dialog } from "@web/components/Dialog";
import { ActionMenu } from "@web/elements";
import { commonTexts } from "@web/translations";
import { EntryModel } from "@web/models";
import { IEntryNode } from "@web/api/Integration/types";
import { selectTheme } from "@web/styles/DMReactSelectStyle";
import { useStores } from "@web/stores/context";

interface SelectOption {
  label: string;
  value: IEntryNode;
}

interface Props {
  sourceEntry: EntryModel;
  onClose: () => void;
}

function isSingleValueOption(
  option: SingleValue<SelectOption> | MultiValue<SelectOption>
): option is SingleValue<SelectOption> {
  return (
    option === null ||
    Object.keys(option).includes("label") ||
    Object.keys(option).includes("value")
  );
}

export const CreateLinkDialog: React.FC<Props> = observer(
  (props: { sourceEntry: EntryModel; onClose: () => void }) => {
    const { recordStore } = useStores();
    const intl = useIntl();
    const [selectedEntry, setSelectedEntry] = useState<IEntryNode>();
    const canSave = !!selectedEntry;

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      if (canSave) {
        recordStore.linkEntry(props.sourceEntry.uuid, selectedEntry.id);
        props.onClose();
      }
    };

    function handleSelectChange(
      selected: SingleValue<SelectOption> | MultiValue<SelectOption>
    ) {
      if (isSingleValueOption(selected)) {
        setSelectedEntry(selected?.value);
      }
    }

    const getTitle = (entry: IEntryNode) => {
      if (entry.title) {
        return entry.title;
      } else if (entry.isSingleDocumentEntry && !!entry.documents.length) {
        const singleDocument = entry.documents[0];
        if (singleDocument) {
          return singleDocument.title;
        }
      }

      return intl.formatMessage(commonTexts.untitled);
    };

    async function loadOptions(inputValue: string) {
      const entries = await recordStore.findEntriesToLink(
        inputValue,
        props.sourceEntry.uuid
      );

      // The source entry can be linked to at most 20 other entries.
      // We fetch 40 and try to filter out the already linked entries manually.
      // In this way we can show at least 20 entries that are not already linked.
      // There is currently no easy way to do this via query.
      const filteredEntries =
        entries?.filter((entry) => {
          return (
            !props.sourceEntry.currentToLinks.find(
              (currentLink) => currentLink.id === entry.id
            ) &&
            !props.sourceEntry.currentFromLinks.find(
              (currentLink) => currentLink.id === entry.id
            )
          );
        }) || [];

      return filteredEntries.map((entry: IEntryNode) => ({
        value: entry,
        label: getTitle(entry),
      }));
    }

    return (
      <Dialog
        title={texts.title}
        onEscape={props.onClose}
        onClickOutside={props.onClose}
      >
        <_form onSubmit={handleSubmit}>
          <_inputWrap>
            <AsyncSelect
              autoFocus
              isClearable
              theme={selectTheme}
              placeholder={intl.formatMessage(texts.placeholder)}
              loadOptions={loadOptions}
              onChange={handleSelectChange}
              loadingMessage={({ inputValue }) =>
                intl.formatMessage(texts.loadingMessage, {
                  value: inputValue,
                })
              }
              noOptionsMessage={({ inputValue }) =>
                inputValue.length > 0
                  ? intl.formatMessage(commonTexts.noMatches)
                  : null
              }
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: (props: OptionProps<SelectOption>) => (
                  <components.Option {...props}>
                    <div>{props.data.label}</div>
                  </components.Option>
                ),
              }}
            />
          </_inputWrap>
          <ActionMenu
            applyIsDisabled={!canSave}
            onApply={handleSubmit}
            onCancel={props.onClose}
            applyText={commonTexts.add}
          />
        </_form>
      </Dialog>
    );
  }
);

const _form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input {
    width: 100%;
  }
`;

const _inputWrap = styled.div`
  margin: 10px 0;
`;

const texts = defineMessages({
  title: {
    id: "dialog.new.link.title",
    defaultMessage: "Link new entry",
  },
  placeholder: {
    id: "entry.selectbox.placeholder",
    defaultMessage: "Search for entries",
  },
  loadingMessage: {
    id: "entry.selectbox.lookingfor",
    defaultMessage: 'Looking for "{value}"',
  },
});
