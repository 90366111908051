/**
 * Common model used when lazy loading and autocompleting any
 * entities with the new integration API
 */

export interface PageLoadingModel {
  // Are there more items to load? (This information comes from the most recent API request.)
  hasMore: boolean;
  pageLoading: boolean;
  lastPageLoaded: number;
  itemCount: number;
}

export interface QueryLoadingModel extends PageLoadingModel {
  query: string;
  showSearch?: boolean;
}

export type DataLoadingModel = PageLoadingModel | QueryLoadingModel;

export function isAutocomplete(
  model: DataLoadingModel
): model is QueryLoadingModel {
  return model.hasOwnProperty("query");
}

export const INITIAL_LOADING_STATUS: PageLoadingModel = {
  pageLoading: false,
  lastPageLoaded: 0,
  hasMore: true,
  itemCount: 0,
};

export const EMPTY_LOADING_STATUS: PageLoadingModel = {
  pageLoading: false,
  lastPageLoaded: 0,
  hasMore: false,
  itemCount: 0,
};

export const INITIAL_SEARCH_LOADING_STATUS: QueryLoadingModel = {
  pageLoading: false,
  lastPageLoaded: 0,
  hasMore: true,
  itemCount: 0,
  query: "",
};
