import axios from "axios";
import { observable, action } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import {
  IClassificationNode,
  MissingClassificationsResponse,
} from "@web/api/Integration/types";
import { RootStore } from ".";
import {
  ClassModel,
  SectionModel,
  ColorStatusModel,
  WritableFields,
  AttributeModel,
} from "@web/models";
import commonStoreTexts from "./texts";

type ClassificationID = UUID;

export class ClassificationStore {
  constructor(private api: Api, public rootStore: RootStore) {}

  @observable
  classificationCache = new Map<ClassificationID, ClassModel>();

  classificationFromJson = (json: IClassificationNode) => {
    const existing = this.classificationCache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    } else {
      const classification = new ClassModel(this, json);
      this.classificationCache.set(classification.uuid, classification);
      return classification;
    }
  };

  @action
  private removeClassificationFromAllSections(classification: ClassModel) {
    const { sections } = this.rootStore.sectionStore;
    const uuid = classification.uuid;
    sections.forEach((section) => section.removeClassification(uuid));
  }

  findLinkableClassifications = async (sectionId: UUID, query: string) => {
    try {
      const { data } = await this.api.findClassifications(query, sectionId);
      // HACK: the backend does not fully support this kind of query
      // so we need to do some filtering on our side.
      return data.data.filter(
        (node) => !node.sections?.some((s) => s.id === sectionId)
      );
    } catch (e) {
      console.error("error when autocompleting on classifications: " + e);
      return [];
    }
  };

  findClassifications = async (query: string) => {
    try {
      const { data } = await this.api.findClassifications(query);
      return data.data;
    } catch (e) {
      console.error("Failed to find classifications", e);
      return [];
    }
  };

  /** Classification CRUD */
  linkClassification = async (
    section: SectionModel,
    classificationId: UUID
  ) => {
    const { messageStore } = this.rootStore;
    try {
      const { data } = await this.api.linkClassificationToSection(
        classificationId,
        section.uuid
      );

      const classification = this.classificationFromJson(data.data);
      section.addClassification(classification);

      messageStore.addMessage({
        type: "success",
        title: texts.listAddedToSectionSuccess,
        values: {
          sectionTitle: section.title,
        },
      });
    } catch (error) {
      messageStore.addMessage({
        type: "failure",
        title: texts.listAddedToSectionFailed,
        values: {
          sectionTitle: section.title,
        },
      });
    }
  };

  unlinkClassification = async (
    classification: ClassModel,
    section: SectionModel
  ) => {
    const { messageStore } = this.rootStore;
    try {
      await this.api.unlinkClassification(classification.uuid, section.uuid);

      messageStore.addMessage({
        type: "success",
        title: texts.unlinkClassificationStarted,
      });
    } catch (e) {
      let isInProgressError = false;
      if (axios.isAxiosError(e)) {
        isInProgressError = e.response?.status === 409;
      }
      if (isInProgressError) {
        messageStore.addMessage({
          type: "generic",
          title: texts.unlinkClassificationInProgress,
        });
      } else {
        messageStore.addMessage({
          type: "failure",
          title: texts.unlinkClassificationFailed,
        });
      }
    }
  };

  createClassification = async (section: SectionModel, title: string) => {
    const { messageStore } = this.rootStore;
    const messageId = messageStore.createMessage({
      type: "updating",
      title: texts.createClassificationInProgress,
      text: `"${title}"`,
    });

    try {
      const { data } = await this.api.createClassification(title, section.uuid);

      const newClassification = this.classificationFromJson(data.data);
      section.addClassification(newClassification);

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.createClassificationSuccess,
      });
    } catch (error) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.createClassificationFailed,
      });
    }
  };

  deleteClassification = async (classification: ClassModel) => {
    const { messageStore } = this.rootStore;
    const messageId = messageStore.createMessage({
      type: "updating",
      title: texts.deleteClassificationInprogress,
      text:
        classification.tags.length > 50
          ? commonStoreTexts.updateCanTakeAWhile
          : undefined,
    });

    try {
      await this.api.deleteClassification(classification.uuid);
      this.removeClassificationFromAllSections(classification);
      this.classificationCache.delete(classification.uuid);

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deleteClassificationSuccess,
        text: undefined,
      });
    } catch (error) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.deleteClassificationFailed,
        text: undefined,
      });
    }
  };

  updateClassification = async (
    classification: ClassModel,
    title: string,
    isMandatory: boolean
  ) => {
    const { messageStore } = this.rootStore;
    try {
      const { data } = await this.api.updateClassification(
        classification.uuid,
        title,
        isMandatory
      );

      classification.updateFromJson(data.data);

      messageStore.addMessage({
        type: "success",
        title: texts.updateClassificationSuccess,
      });
    } catch (error) {
      console.error("Error updating classification", error);
      messageStore.addMessage({
        type: "failure",
        title: texts.updateClassificationFailed,
      });
    }
  };

  getSectionCount = async (classification: ClassModel) => {
    if (classification.sectionCount) return;
    try {
      const { data } = await this.api.getClassification(classification.uuid);
      classification.sectionCount = data.data.sections?.length;
    } catch (error) {
      console.error("Error getting section count", error);
    }
  };

  createTagStatus = async (
    classification: ClassModel,
    fields: WritableFields<ColorStatusModel>
  ) => {
    const { messageStore } = this.rootStore;

    try {
      classification.saving = true;
      const { data } = await this.api.createTagStatus(
        fields,
        classification.uuid
      );

      classification.addStatusFromJson(data.data);

      messageStore.addMessage({
        type: "success",
        title: texts.createStatusSuccess,
      });
    } catch (error) {
      console.error("Error creating tag status", error);
      messageStore.addMessage({
        type: "failure",
        title: texts.createStatusFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  createAttribute = async (
    classification: ClassModel,
    fields: WritableFields<AttributeModel>
  ) => {
    const { messageStore } = this.rootStore;

    try {
      classification.saving = true;

      const { data } = await this.api.createAttribute(fields, {
        classification: { id: classification.uuid },
      });

      classification.addAttributeFromJson(data.data);

      messageStore.addMessage({
        type: "success",
        title: texts.createAttributeSuccess,
      });
    } catch (error) {
      console.error("Error creating classification attribute", error);
      messageStore.addMessage({
        type: "failure",
        title: texts.createAttributeFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  updateTagStatus = async (
    classification: ClassModel,
    fields: WritableFields<ColorStatusModel>,
    status: ColorStatusModel
  ) => {
    const { messageStore } = this.rootStore;
    try {
      classification.saving = true;
      await this.api.updateTagStatus(status.uuid, fields);
      status = Object.assign(status, fields);

      messageStore.addMessage({
        type: "success",
        title: texts.updateStatusSuccess,
      });
    } catch (error) {
      console.error("Error updating tag status", error);
      messageStore.addMessage({
        type: "failure",
        title: texts.updateStatusFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  deleteTagStatus = async (classification: ClassModel, statusId: UUID) => {
    const { messageStore } = this.rootStore;
    try {
      classification.saving = true;
      await this.api.deleteTagStatus(statusId);
      classification.removeStatus(statusId);

      messageStore.addMessage({
        type: "success",
        title: texts.deleteStatusSuccess,
      });
    } catch (error) {
      console.error("Error deleting tag status", error);
      messageStore.addMessage({
        type: "failure",
        title: texts.deleteStatusFailed,
      });
    } finally {
      classification.saving = false;
    }
  };

  findMissingTagsInDifferentSection = async (
    entryIds: UUID[],
    newSectionId: UUID
  ): Promise<MissingClassificationsResponse> => {
    const response = await this.api.getMissingTagsInSection(
      entryIds,
      newSectionId
    );
    return response.data;
  };
}

const texts = defineMessages({
  createClassificationInProgress: {
    id: "request.classification.create.inprogress",
    defaultMessage: "Adding list",
  },
  createClassificationSuccess: {
    id: "request.classification.create.success",
    defaultMessage: "New list added",
  },
  createClassificationFailed: {
    id: "request.classification.create.failed",
    defaultMessage: "Failed to add list",
  },
  listAddedToSectionSuccess: {
    id: "request.classification.linktosection.success",
    defaultMessage: "New list added to <em>{sectionTitle}</em>",
  },
  listAddedToSectionFailed: {
    id: "request.classification.linktosection.failed",
    defaultMessage: "Failed to add list to <em>{sectionTitle}</em>",
  },
  deleteClassificationInprogress: {
    id: "request.classification.delete.inprogress",
    defaultMessage: "Deleting list",
  },
  deleteClassificationSuccess: {
    id: "request.classification.delete.success",
    defaultMessage: "List deleted",
  },
  deleteClassificationFailed: {
    id: "request.classification.delete.failed",
    defaultMessage: "Failed to delete list",
  },
  unlinkClassificationStarted: {
    id: "request.classification.unlink.started",
    defaultMessage: "Started removal operation",
  },
  unlinkClassificationFailed: {
    id: "request.classification.unlink.failed",
    defaultMessage: "Failed to start removal operation",
  },
  unlinkClassificationInProgress: {
    id: "request.classification.unlink.inprogress",
    defaultMessage: "Removal operation is already in progress",
  },
  updateClassificationFailed: {
    id: "request.classification.update.failed",
    defaultMessage: "Failed to update list",
  },
  updateClassificationSuccess: {
    id: "request.classification.update.success",
    defaultMessage: "Updated list",
  },
  createStatusFailed: {
    id: "request.tagstatus.create.failed",
    defaultMessage: "Failed to create status",
  },
  createStatusSuccess: {
    id: "request.tagstatus.create.success",
    defaultMessage: "Status created",
  },
  updateStatusFailed: {
    id: "request.tagstatus.update.failed",
    defaultMessage: "Failed to update status",
  },
  updateStatusSuccess: {
    id: "request.tagstatus.update.success",
    defaultMessage: "Status updated",
  },
  deleteStatusFailed: {
    id: "request.tagstatus.delete.failed",
    defaultMessage: "Failed to delete status",
  },
  deleteStatusSuccess: {
    id: "request.tagstatus.delete.success",
    defaultMessage: "Status deleted",
  },
  createAttributeFailed: {
    id: "request.classification.attribute.create.failed",
    defaultMessage: "Failed to create property",
  },
  createAttributeSuccess: {
    id: "request.classification.attribute.create.success",
    defaultMessage: "New property created",
  },
});
