import React from "react";

export const CrossIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6588 12L18.6591 6.99843C19.1136 6.54374 19.1136 5.7957 18.6591 5.34102C18.2045 4.88633 17.4567 4.88633 17.0021 5.34102L12.0018 10.3426L7.00157 5.34102C6.547 4.88633 5.79916 4.88633 5.34459 5.34102C5.11731 5.56836 5 5.86904 5 6.16972C5 6.4704 5.11731 6.77108 5.34459 6.99843L10.3449 12L5.34459 17.0016C5.11731 17.2289 5 17.5296 5 17.8303C5 18.131 5.11731 18.4316 5.34459 18.659C5.79916 19.1137 6.547 19.1137 7.00157 18.659L12.0018 13.6574L17.0021 18.659C17.4567 19.1137 18.2045 19.1137 18.6591 18.659C19.1136 18.2043 19.1136 17.4563 18.6591 17.0016L13.6588 12Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

CrossIcon.displayName = "CrossIcon";
