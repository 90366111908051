import React from "react";
import { observer } from "mobx-react";
import { useIntl, MessageDescriptor } from "react-intl";
import {
  CustomFilterModel,
  EntryStatusName,
  FilterChangeEvent,
} from "@web/models";
import { ScrollBox, Checkbox, StatusColor, Box } from "@web/elements";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";
import { CaretLeftIcon } from "@web/elements/Icons";
import { commonTexts } from "@web/translations";
import { Button } from "@web/elements/Button";
import { _headerWrap, _headerLink, _title, _action } from "./styles";

interface Props {
  filter: CustomFilterModel;
  selectedValues: string[];
  onFilterChange: (change: FilterChangeEvent) => void;
  onBackClick?: (e: React.MouseEvent) => void;
  onClearClick: (e: React.MouseEvent) => void;
}

export const CustomFilter: React.FC<Props> = observer((p) => {
  const intl = useIntl();

  const handleFilterToggle = (label: string, value: string) => {
    const { filter, selectedValues } = p;

    const change = {
      filterId: filter.uuid,
      selected: !selectedValues.includes(value),
      value,
    };

    p.onFilterChange(change);
  };

  const renderRow = (row: { label: MessageDescriptor; value: string }) => {
    const { filter, selectedValues } = p;
    const { label, value } = row;

    const icon =
      filter.uuid === "Entry Status" ? (
        <StatusColor color={ENTRY_STATUS_COLORS[value as EntryStatusName]} />
      ) : undefined;

    return (
      <Box height="30px" key={value}>
        <Checkbox
          label={intl.formatMessage(label)}
          checked={selectedValues.includes(value)}
          iconBefore={icon}
          variant="alt"
          onChange={() => handleFilterToggle(intl.formatMessage(label), value)}
        />
      </Box>
    );
  };

  return (
    <>
      <_headerWrap>
        {p.onBackClick && (
          <_headerLink
            onClick={p.onBackClick}
            aria-label={intl.formatMessage(commonTexts.back)}
          >
            <CaretLeftIcon />
          </_headerLink>
        )}
        <_title>{intl.formatMessage(p.filter.title)}</_title>
      </_headerWrap>
      <ScrollBox flex="1" pl="11px" pr="11px" pt="15px">
        {p.filter.values.map((v) => renderRow(v))}
      </ScrollBox>
      {p.selectedValues.length > 0 && (
        <_action>
          <Button
            text={commonTexts.clear}
            variant="blank"
            onClick={p.onClearClick}
          />
        </_action>
      )}
    </>
  );
});
