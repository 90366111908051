import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
import { ChangeLogEvent } from "@web/components/Entry/HistoryPane";
import { ChangelogModModel } from "@web/models";
import { ChangedRelatedTags } from "@web/api/Integration/types";
import { Label } from "@web/elements";

const WE_DONT_KNOW_THE_TAG_CLASSIFICATION = "";

export const TagsChangedEvent: React.FC<{
  event: ChangelogModModel<ChangedRelatedTags>;
}> = ({ event }) => {
  const addedTagTitles = event.changed.Tag.filter(
    (tagChange) => tagChange.newValue !== null
  ).map((tagChange) => tagChange.fields.title);
  const removedTagTitles = event.changed.Tag.filter(
    (tagChange) => tagChange.newValue === null
  ).map((tagChange) => tagChange.fields.title);

  return (
    <>
      {addedTagTitles.length > 0 && (
        <ChangeLogEvent
          authorId={event.userId}
          authorName={event.userName}
          date={event.eventTime}
        >
          <_tagEventWrap>
            <FormattedMessage
              {...textByEvent.Entry_MOD_tagSet}
              values={{
                titles: (
                  <>
                    {addedTagTitles.map((tagTitle, index) => (
                      <Label
                        key={"added-tags-" + event.id + index}
                        title={WE_DONT_KNOW_THE_TAG_CLASSIFICATION}
                        values={[tagTitle]}
                      />
                    ))}
                  </>
                ),
                titlesCount: addedTagTitles.length,
              }}
            />
          </_tagEventWrap>
        </ChangeLogEvent>
      )}

      {removedTagTitles.length > 0 && (
        <ChangeLogEvent
          authorId={event.userId}
          authorName={event.userName}
          date={event.eventTime}
        >
          <_tagEventWrap>
            <FormattedMessage
              {...textByEvent.Entry_MOD_tagRemoved}
              values={{
                titles: (
                  <>
                    {removedTagTitles.map((tagTitle, index) => (
                      <Label
                        key={"removed-tags-" + event.id + index}
                        title={WE_DONT_KNOW_THE_TAG_CLASSIFICATION}
                        values={[tagTitle]}
                      />
                    ))}
                  </>
                ),
                titlesCount: removedTagTitles.length,
              }}
            />
          </_tagEventWrap>
        </ChangeLogEvent>
      )}
    </>
  );
};
const textByEvent = defineMessages({
  Entry_MOD_tagSet: {
    id: "entry.historypane.event.entry.modified.tag.set",
    defaultMessage:
      "set {titlesCount, plural, one {tag} other {tags}} {titles}",
  },
  Entry_MOD_tagRemoved: {
    id: "entry.historypane.event.entry.modified.tag.removed",
    defaultMessage:
      "removed {titlesCount, plural, one {tag} other {tags}} {titles}",
  },
});

const _tagEventWrap = styled.div`
  line-height: 1.8em;
  > div {
    line-height: initial;
    margin-bottom: 2px;
    margin-right: 2px;
  }
`;
