import React, { useContext } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { Route, Routes, Navigate } from "react-router-dom";
import { SectionsContainer } from "@web/pages/SettingsPage/containers/SectionsContainer";
import { ChecklistsContainer } from "@web/pages/SettingsPage/containers/ChecklistContainer";
import { NotificationsContainer } from "@web/pages/SettingsPage/containers/NotificationsContainer";
import { AccessControlContainer } from "@web/pages/SettingsPage/containers/AccessControlContainer";
import { LinkTab } from "@web/elements";
import { vars } from "@web/styles";
import { ErrorBanner } from "@web/components/Error/ErrorBanner";
import { GlobalConfig } from "@config/context";

const DEFAULT_TAB = "sections";

const SettingsPage = () => {
  const intl = useIntl();
  const { canManageFlows, canManageAccessGroups } = useContext(GlobalConfig);

  return (
    <_wrap>
      <h1>{intl.formatMessage(texts.title)}</h1>
      <_tabs>
        <LinkTab to={"sections"}>{intl.formatMessage(texts.sections)}</LinkTab>
        <LinkTab to={"checklists"} disabled={!canManageFlows}>
          {intl.formatMessage(texts.pipelines)}
        </LinkTab>
        <LinkTab to={"notifications"} disabled={!canManageFlows}>
          {intl.formatMessage(texts.notifications)}
        </LinkTab>
        <LinkTab to={"access"} disabled={!canManageAccessGroups}>
          {intl.formatMessage(texts.accessControl)}
        </LinkTab>
      </_tabs>
      <Routes>
        <Route path={"/"} element={<Navigate to={DEFAULT_TAB} replace />} />
        <Route path={"sections"} element={<SectionsContainer />} />
        <Route path={"checklists"} element={<ChecklistsContainer />} />
        <Route path={"notifications"} element={<NotificationsContainer />} />
        <Route path={"access"} element={<AccessControlContainer />} />
        <Route path="*" element={<ErrorBanner type="notFound" />} />
      </Routes>
    </_wrap>
  );
};

const _wrap = styled.div`
  min-height: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;
  > h1 {
    padding: 10px 0 0 1rem;
  }
`;

const _tabs = styled.div`
  border-bottom: 2px solid ${vars.dark05};
  font-size: 1rem;
  display: flex;
  padding: 0.3rem;
  margin-bottom: 10px;
  overflow-x: auto;
  flex-wrap: nowrap;
  flex-shrink: 0;
`;

export const texts = defineMessages({
  title: {
    id: "settingspage.title",
    defaultMessage: "Settings",
  },
  sections: {
    id: "settingspage.tab.sectionsandtags",
    defaultMessage: "Sections and tags",
  },
  pipelines: {
    id: "settingspage.tab.checklists",
    defaultMessage: "Checklists",
  },
  accessControl: {
    id: "settingspage.tab.accessControl",
    defaultMessage: "Access Control",
  },
  notifications: {
    id: "settingspage.tab.notifications",
    defaultMessage: "Notifications",
  },
});

export default SettingsPage;
