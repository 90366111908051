import {
  IObjectWithAccessControl,
  IPermissionAdd,
  IPermissionNode,
  Permission,
} from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import { action, computed, observable } from "mobx";
import { AccessGroupModel } from "./AccessGroupModel";
import { ObjectPermissionsModel } from "./ObjectPermissionsModel";

export type ObjectTypeString =
  | "section"
  | "classification"
  | "tag"
  | "flowContainer"
  | "listContainer";

export class ObjectWithAccessControlModel<
  ObjectType extends IObjectWithAccessControl
> {
  @observable uuid: UUID;
  @observable json: ObjectType;

  @observable
  private objectPermissionsById = new Map<UUID, ObjectPermissionsModel>();

  @observable
  private objectPermissionsByAccessGroupId = new Map<
    UUID,
    ObjectPermissionsModel
  >();

  constructor(
    private store: AccessControlStore,
    public objectType: ObjectTypeString,
    json: ObjectType
  ) {
    this.uuid = json.id;
    this.json = json;
    this.json.permissions?.forEach((x) => this.updatePermissionFromJson(x));
  }

  @computed
  get objectPermissions(): ObjectPermissionsModel[] {
    return Array.from(this.objectPermissionsById.values());
  }

  @computed
  get canModifyPermissions(): boolean {
    return this.json.effectivePermissions.includes("ModifyPermissions");
  }

  @computed
  get title(): string | undefined {
    const json = this.json as any;
    if (typeof json["title"] === "string") {
      return json["title"];
    }
    if (this.objectType === "flowContainer") {
      return "FlowContainer";
    }
    if (this.objectType === "listContainer") {
      return "ListContainer";
    }
    return undefined;
  }

  loadAllPermissions() {
    this.store.loadAllObjectPermissionsForObject(this);
  }

  objectPermissionsForAccessGroup(
    accessGroup: AccessGroupModel
  ): ObjectPermissionsModel | undefined {
    return this.objectPermissionsByAccessGroupId.get(accessGroup.uuid);
  }

  @action.bound
  updateFromJson(json: ObjectType) {
    this.uuid = json.id;
    this.json = json;
    this.json.permissions?.forEach((x) => this.updatePermissionFromJson(x));
  }

  @action.bound
  updatePermissionFromJson(
    json: IPermissionNode,
    options?: {
      markRecentlyChanged: boolean;
    }
  ) {
    const found = this.objectPermissionsById.get(json.id);
    if (found) {
      found.updateFromJson(json, options);
    } else {
      const objectPermissions = this.store.objectPermissionsFromJson(json);
      this.objectPermissionsById.set(json.id, objectPermissions);
      this.objectPermissionsByAccessGroupId.set(
        json.accessGroup.id,
        objectPermissions
      );
      if (options?.markRecentlyChanged) {
        objectPermissions.isRecentlyChanged = true;
      }
    }
  }

  @action.bound
  addPermissions(accessGroup: AccessGroupModel, permissions: Set<Permission>) {
    const fields: IPermissionAdd = {
      accessGroup: { id: accessGroup.uuid },
      explicitPermissions: Array.from(permissions),
    };
    switch (this.objectType) {
      case "section":
        fields.section = { id: this.uuid };
        break;
      case "classification":
        fields.classification = { id: this.uuid };
        break;
      case "tag":
        fields.tag = { id: this.uuid };
        break;
      case "flowContainer":
        fields.flowContainer = { id: this.uuid };
        break;
      case "listContainer":
        fields.listContainer = { id: this.uuid };
        break;
    }
    this.store.addObjectPermission(this.uuid, this.objectType, fields);
  }

  @action.bound
  purgePermissionsById(permissionId: UUID) {
    const objectPermission = this.objectPermissionsById.get(permissionId);
    if (objectPermission) {
      this.objectPermissionsById.delete(permissionId);
      this.objectPermissionsByAccessGroupId.delete(objectPermission.group.uuid);
    }
  }
}
