import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { useKeyboard } from "react-aria";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";
import { containInViewport } from "@web/utils/helpers";
import { Portal } from "@web/components/Portal";
import { useClickOutside } from "@web/utils/hooks/useClickOutside";
import { useScrollBlocker } from "@web/utils/hooks/useScrollBlocker";

interface IEvents {
  onClickOutside: (evt: MouseEvent) => void;
}

export interface PopoverBoxProps extends IEvents {
  children: React.ReactNode;
  triggerRef: React.RefObject<HTMLElement>;
  margin?: number;
  maxWidth?: number;
  maxHeight?: number;
  alignY?: "top" | "bottom";
  alignX?:
    | "start-left-of-trigger"
    | "start-right-of-trigger"
    | "end-left-of-trigger";
}

export const PopoverBox = ({
  margin = 0,
  maxWidth = 287,
  maxHeight = 440,
  alignY = "bottom",
  alignX = "start-left-of-trigger",
  ...p
}: PopoverBoxProps) => {
  useScrollBlocker();

  const hasBeenUnmounted = useRef(false);
  const contentRef = useClickOutside(p.onClickOutside);
  const [position, setPosition] = useState(
    calculatePosition(p.triggerRef, contentRef, alignY, alignX)
  );

  const { keyboardProps } = useKeyboard({
    onKeyUp: (e) => {
      if (e.key === "Escape") {
        p.onClickOutside({} as any);
      }
    },
  });

  useEffect(() => {
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);

      hasBeenUnmounted.current = true;
    };
  }, []);

  useLayoutEffect(() => {
    updatePosition();
  }, []);

  const updatePosition = debounce(() => {
    // Because of debounce, we have to double check if the component is still mounted
    // to avoid React.js errors logged about state updates on unmounted components.
    // Seen while running tests, most probably never an issue in practise
    if (!hasBeenUnmounted.current) {
      setPosition(calculatePosition(p.triggerRef, contentRef, alignY, alignX));
    }
  });

  return (
    <Portal>
      <_wrap
        aria-modal
        role="dialog"
        position={position}
        ref={contentRef}
        margin={margin}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        {...keyboardProps}
      >
        {p.children}
      </_wrap>
    </Portal>
  );
};

const _wrap = styled.div<{
  position: { top: number; left: number };
  margin: number;
  maxWidth: number;
  maxHeight: number;
}>`
  position: fixed;
  background: ${vars.content};
  color: ${vars.contentFg};
  z-index: ${ZIndex.popover};

  ${media("compact")} {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${media("desktop")} {
    top: ${(p) => p.position.top + p.margin}px;
    left: ${(p) => p.position.left + p.margin}px;
    box-shadow: ${vars.shadow.z3};
    max-width: ${(p) => p.maxWidth}px;
    max-height: ${(p) => p.maxHeight}px;
    border-radius: 3px;
    animation: fadeIn 0.3s;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

const calculatePosition = (
  triggerRef: React.RefObject<HTMLElement>,
  contentRef: React.RefObject<HTMLElement>,
  alignY: "top" | "bottom",
  alignX:
    | "start-left-of-trigger"
    | "start-right-of-trigger"
    | "end-left-of-trigger"
) => {
  const contentEl = contentRef.current;
  const triggerEl = triggerRef.current;

  if (triggerEl) {
    const contentRect = contentEl?.getBoundingClientRect();
    const triggerRect = triggerEl.getBoundingClientRect();

    /**
     * When the content size is not known yet, we better position the popover outside
     * the viewport for a fraction of a second.
     *
     * Previously we used { width: 0, height: 0 } in this scenario, but that caused a
     * slight flash of the popover in an unexpected position before it got re-painted
     * in the correct position in the end.
     */
    if (contentRect === undefined) {
      return { top: -9999, left: -9999 };
    }

    const contentSize = {
      width: contentRect.width,
      height: contentRect.height,
    };

    const top = alignY === "bottom" ? triggerRect.bottom : triggerRect.top;
    const left = {
      "start-right-of-trigger": triggerRect.right,
      "start-left-of-trigger": triggerRect.left,
      "end-left-of-trigger": triggerRect.left - contentSize.width,
    }[alignX];

    return containInViewport({ top, left, ...contentSize }, 8);
  }
  return { top: 0, left: 0 }; // Fallback
};
