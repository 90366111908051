import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Truncate } from "@web/elements";
import { vars } from "@web/styles";

interface IProps {
  title: string;
}

export const SectionLabel: FC<IProps> = observer((p) => {
  return (
    <_wrap title={p.title}>
      <Truncate>{p.title}</Truncate>
    </_wrap>
  );
});

const _wrap = styled.div`
  display: inline-flex;
  border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;
  background: ${vars.secondaryAlt};
  color: ${vars.secondaryAltFg};
  padding: 6px 12px 6px 8px;
  max-width: 120px;
  font-size: 13px;
`;
