import { defineMessages } from "react-intl";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";

export const useCheckForEmptyFiles = () => {
  const [getConfirmation] = useConfirmationDialog();
  return async (files: File[]) => {
    /**
     * NOTE!
     * In this particular case, we'd want the cancel action to be the primary course of action.
     * If it contains empty files we show the confirmation dialog, else we'll just let it pass as true.
     */
    if (containsEmptyFiles(files)) {
      return !(await getConfirmation({
        title: texts.emptyFilesDialogTitle,
        message: texts.emptyFilesDialogBody,
        confirmText: texts.emptyFilesDialogCancel,
        cancelText: texts.emptyFilesDialogConfirm,
      }));
    } else {
      return true;
    }
  };
};

const containsEmptyFiles = (files: File[]) =>
  files.some((file) => file.size <= 0);

const texts = defineMessages({
  emptyFilesDialogTitle: {
    id: "upload.emptyFile.dialog.title",
    defaultMessage: "Empty file!",
  },
  emptyFilesDialogBody: {
    id: "upload.emptyFile.dialog.body",
    defaultMessage:
      "One or more of the files you're trying to upload appears empty. This could be because you're trying to upload a file from OneDrive/Dropbox/Box etc. that's not yet synced.",
  },
  emptyFilesDialogCancel: {
    id: "upload.emptyFile.dialog.cancel",
    defaultMessage: "Cancel upload",
  },
  emptyFilesDialogConfirm: {
    id: "upload.emptyFile.dialog.confirm",
    defaultMessage: "Upload anyway",
  },
});
