import { UploadJob, UploadJobStatus, VersionUploadRequest } from "@web/models";
import { DocumentVersionStore } from "@web/stores";
import { DocumentCreator } from "@web/components/Upload/creators/types";

export const createVersion = async (
  versionStore: DocumentVersionStore,
  allJobs: UploadJob[]
) => {
  const createPromises: Promise<void>[] = [];

  allJobs.forEach((job) => {
    const { fileId, documentUuid, documentId } = job.result;

    if (!fileId) {
      job.setStatus(UploadJobStatus.failed);
      return;
    }

    if (!documentUuid || !documentId) {
      job.setStatus(UploadJobStatus.failed);
      return;
    }

    createPromises.push(
      versionStore.createVersion(fileId, documentUuid, documentId)
    );
  });

  const allSettled = await Promise.allSettled(createPromises);

  allSettled.forEach((value, index) => {
    if (value.status === "fulfilled") {
      allJobs[index].setStatus(UploadJobStatus.completed);
    } else {
      allJobs[index].setStatus(UploadJobStatus.failed);
    }
  });
};
