import React, { forwardRef } from "react";
import styled from "styled-components";
import Dropzone, {
  DropEvent,
  DropzoneRef,
  DropzoneState,
  FileRejection,
} from "react-dropzone";
import { defineMessages, useIntl } from "react-intl";
import { vars, ZIndex } from "@web/styles";
import { useCheckForEmptyFiles } from "@web/components/Upload/helpers";
import { UploadDropzoneProps } from "./types";

type DropIntoType = "entry" | "document" | "version";

interface IProps {
  type: DropIntoType;
  multiple: boolean;
  overlayPosition: "fixed" | "absolute";
  onDrop: (
    accepted: File[],
    rejected: FileRejection[],
    event: DropEvent
  ) => void;
  onDragOver?: (e: React.DragEvent) => void;
  onDragLeave?: (e: React.DragEvent) => void;
  children: React.ReactNode;
}

export const UploadDropzone = forwardRef<
  DropzoneRef,
  IProps & UploadDropzoneProps
>((props, ref) => {
  const checkForEmptyFiles = useCheckForEmptyFiles();
  const intl = useIntl();
  const { type, children, multiple, disabled, overlayPosition } = props;

  const handleDrop = async (
    files: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) => {
    if (await checkForEmptyFiles(files)) {
      props.onDrop(files, rejectedFiles, event);
    }
  };

  return (
    <Dropzone
      ref={ref}
      multiple={multiple}
      disabled={disabled}
      noClick={true}
      noKeyboard={true}
      noDragEventsBubbling={true}
      onDrop={handleDrop}
      onDragOver={props.onDragOver}
      onDragLeave={props.onDragLeave}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
      }: DropzoneState) => (
        <_zone {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
          {isDragActive && (
            <_overlay position={overlayPosition}>
              {isDragReject ? (
                <_message error>
                  {intl.formatMessage(texts.notAccepted)}
                </_message>
              ) : (
                <_message>{intl.formatMessage(texts[type])}</_message>
              )}
            </_overlay>
          )}
        </_zone>
      )}
    </Dropzone>
  );
});

const _zone = styled.div`
  width: 100%;
  height: 100%;
`;

const _overlay = styled.div<{ position: string }>`
  background: ${vars.light25};
  backdrop-filter: blur(10px);
  position: ${(p) => p.position};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.dropzone};
`;

const _message = styled.div<{ error?: boolean }>`
  background: ${(p) => (p.error ? vars.danger : vars.secondary)};
  color: ${(p) => (p.error ? vars.dangerFg : vars.secondaryFg)};
  box-shadow: ${vars.shadow.z3};
  font-size: 1rem;
  border-radius: 8px;
  padding: 1rem;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
`;

const texts = defineMessages({
  entry: {
    id: "upload.dropzone.uploadtype.entry",
    defaultMessage: "Drop files to upload",
  },
  document: {
    id: "upload.dropzone.uploadtype.document",
    defaultMessage: "Drop files to upload documents",
  },
  version: {
    id: "upload.dropzone.uploadtype.version",
    defaultMessage: "Drop file to upload new version",
  },
  notAccepted: {
    id: "upload.dropzone.filesnotaccepted",
    defaultMessage: "Files not accepted",
  },
});
