import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MessageDescriptor, useIntl, FormattedMessage } from "react-intl";
import { CrossIcon } from "@web/elements/Icons";
import { Box, HBox } from "@web/elements";
import ellipsis from "polished/lib/mixins/ellipsis";
import { isMedia, media } from "@web/styles/utils";

interface IProps {
  title: MessageDescriptor | string;
  onCloseClick: (e: React.MouseEvent) => void;
  onClearAllClick: (e: React.MouseEvent) => void;
}

export const Header = observer((p: IProps) => {
  const intl = useIntl();
  const showClose = isMedia("compact");
  const showClearAll = true;
  const title =
    typeof p.title === "string" ? p.title : intl.formatMessage(p.title);

  return (
    <Wrap>
      {showClose && (
        <Box flex="1">
          <Dismiss onClick={p.onCloseClick}>
            <CrossIcon />
          </Dismiss>
        </Box>
      )}
      {showClose ? (
        <LargeTitle>{title}</LargeTitle>
      ) : (
        <SmallTitle title={title}>{title}</SmallTitle>
      )}
      {showClearAll && (
        <HBox grow="1" shrink="0" hAlign="flex-end">
          <ClearAll onClick={p.onClearAllClick}>
            <FormattedMessage
              id="searchpage.filters.clearall"
              defaultMessage="Clear all"
            />
          </ClearAll>
        </HBox>
      )}
    </Wrap>
  );
});

const Wrap = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  padding: 14px 11px 0 11px;
  align-items: center;
`;

const Dismiss = styled.div.attrs({ role: "button" })`
  cursor: pointer;
`;

const LargeTitle = styled.div.attrs({ role: "heading" })`
  text-align: center;
  ${media("compact")} {
    font-size: 18px;
  }
  ${media("desktop")} {
    font-size: 13px;
  }
`;

const SmallTitle = styled.div.attrs({ role: "heading" })`
  text-align: left;
  font-size: 1rem;
  ${ellipsis()};
`;

const ClearAll = styled.div.attrs({ role: "button" })`
  cursor: pointer;
  font-size: 10px;
  margin-left: 0.5rem;
`;

ClearAll.displayName = "ClearAll";
