import React, { useState } from "react";
import styled from "styled-components";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { Dialog } from "@web/components/Dialog";
import { ActionMenu, Checkbox, Input } from "@web/elements";
import { commonTexts } from "@web/translations";

export const EditClassification = (p: {
  title: string;
  isMandatory: boolean;
  saveText: MessageDescriptor;
  onSave: (title: string, isMandatory: boolean) => void;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const [title, setTitle] = useState(p.title ?? "");
  const [isMandatory, setIsMandatory] = useState(p.isMandatory);
  const canSave = !!title.trim();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (canSave) {
      p.onSave(title.trim(), isMandatory);
      p.onClose();
    }
  };

  return (
    <Dialog
      title={texts.dialogTitle}
      onEscape={p.onClose}
      onClickOutside={p.onClose}
    >
      <_form onSubmit={handleSubmit}>
        <Input
          autoFocus
          value={title}
          placeholder={intl.formatMessage(commonTexts.untitled)}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Checkbox
          label={intl.formatMessage(texts.required)}
          checked={isMandatory}
          onChange={() => setIsMandatory(!isMandatory)}
        />
        <ActionMenu
          applyIsDisabled={!canSave}
          onApply={handleSubmit}
          onCancel={p.onClose}
          applyText={p.saveText}
        />
      </_form>
    </Dialog>
  );
};

const _form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input {
    width: 100%;
  }
`;

const texts = defineMessages({
  dialogTitle: {
    id: "settings.classification.edit",
    defaultMessage: "Edit list",
  },
  required: { id: "classification.required", defaultMessage: "Required" },
});
