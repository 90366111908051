import { action, computed, observable, when } from "mobx";
import chunk from "lodash/chunk";
import { defineMessages } from "react-intl";
import { RootStore } from "@web/stores";
import { EntryModel, RequiredTagModel, SectionModel } from "@web/models";
import { Api } from "@web/api";
import PubSub, { AddEvent, Subscriber } from "@web/stores/PubSub";
import commonStoreTexts from "./texts";
import { DocumentBucket, EntryBucket } from "@web/models/BucketModel";

export class MultiSelectStore implements Subscriber {
  @observable documents = new DocumentBucket(this);
  @observable entries = new EntryBucket(this);

  constructor(private api: Api, public rootStore: RootStore) {
    PubSub.getInstance().subscribe(this);
  }

  onDataAdded(event: AddEvent): void {
    if (event.type === "Entry" && event.context !== "draft-entry") {
      this.entries.addToSelection(event.data);
      this.entries.setAction("Edit");
    }
  }

  requiredTagsLoaded = async (
    sectionId: number
  ): Promise<RequiredTagModel[]> => {
    const { requiredTags } = this.rootStore.sectionStore;
    await when(() => requiredTags.get(sectionId) !== undefined);
    return requiredTags.get(sectionId) || [];
  };

  findMissingTags = async (bucket: EntryBucket, sectionId: UUID) => {
    const { classificationStore } = this.rootStore;
    bucket.missingTags = { status: "LOADING" };
    const result = await classificationStore.findMissingTagsInDifferentSection(
      bucket.allIds,
      sectionId
    );
    bucket.missingTags = { status: "SUCCESS", result };
  };

  /**
   * SELECTION
   */
  @computed
  get hasSelection() {
    return this.documents.hasSelection || this.entries.hasSelection;
  }

  @action
  clearSelection = (): void => {
    this.documents.clearSelection();
    this.entries.clearSelection();
  };

  /**
   * TAGGING
   */
  applyTagsToEntries = async (bucket: EntryBucket, classificationId: UUID) => {
    const { messageStore } = this.rootStore;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.taggingSection,
    });

    const { add, remove } = bucket.selectedTags.unsavedChanges;

    bucket.inProgress = true;

    try {
      await batchRequests(bucket.allIds, async (ids) => {
        if (add.length) {
          await this.api.batch({
            ids,
            type: "Entry",
            action: "tag",
            tagIds: add,
          });
        }

        if (remove.length) {
          await this.api.batch({
            ids,
            type: "Entry",
            action: "untag",
            tagIds: remove,
          });
        }

        bucket.applyTagChanges(classificationId);
      });

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.taggingSectionSuccess,
      });
    } catch (err) {
      console.error("Error while applying tags:", err);
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.taggingSectionFailure,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  /**
   * DELETION
   */
  deleteDocumentSelection = async (bucket: DocumentBucket) => {
    const { messageStore, documentStore } = this.rootStore;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.deletingSelection,
    });

    bucket.inProgress = true;

    try {
      await batchRequests(bucket.allIds, async (ids) => {
        await this.api.batch({
          ids,
          type: "Document",
          action: "delete",
        });

        bucket.items.forEach((doc) => {
          PubSub.getInstance().notifyDelete({
            type: "Document",
            uuid: doc.uuid,
            entryId: doc.entryId,
            entryUuid: doc.entryUuid,
          });
          documentStore.removeDocument(doc);
        });

        bucket.clearSelection();
      });

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deletingSelectionSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.deletingSelectionFailure,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  deleteEntrySelection = async (bucket: EntryBucket) => {
    const { messageStore, recordStore } = this.rootStore;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.deletingSelection,
    });

    bucket.inProgress = true;

    try {
      await batchRequests(bucket.allIds, async (ids) => {
        await this.api.batch({
          ids,
          type: "Entry",
          action: "delete",
        });

        bucket.items.forEach((entry) => {
          PubSub.getInstance().notifyDelete({
            type: "Entry",
            uuid: entry.uuid,
          });
          recordStore.removeEntry(entry);
        });

        bucket.clearSelection();
      });

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deletingSelectionSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.deletingSelectionFailure,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  copyDocumentsToEntry = async (
    bucket: DocumentBucket,
    toEntry: EntryModel
  ) => {
    const { messageStore, recordStore } = this.rootStore;
    const { itemCount: count } = bucket;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.copyDocumentsStarted,
      values: { count },
    });

    bucket.inProgress = true;
    try {
      if (toEntry.isDraft) {
        toEntry = await recordStore.saveDraftEntry();
      }

      await batchRequests(bucket.allIds, async (ids) => {
        const entryUuid = bucket.entryUuid;
        if (!entryUuid) {
          console.error("Could not copy document: fromEntry not available.");
          return;
        }

        const fromEntry = recordStore.entryCache.get(entryUuid);
        if (!fromEntry) {
          console.error("Could not copy document: fromEntry not available.");
          return;
        }

        await this.api.documentCopy({
          documentIds: ids,
          targetEntryId: toEntry.uuid,
        });

        await toEntry.reload();
      });

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.copyDocumentsSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.copyDocumentsFailed,
      });
    } finally {
      bucket.cancelAction();
      bucket.clearSelection();
      bucket.inProgress = false;
    }
  };

  /**
   * MOVING
   */
  moveDocumentsToEntry = async (
    bucket: DocumentBucket,
    toEntry: EntryModel
  ) => {
    const { messageStore, recordStore } = this.rootStore;
    const { itemCount: count } = bucket;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.moveDocumentsStarted,
      values: { count },
    });

    bucket.inProgress = true;
    try {
      if (toEntry.isDraft) {
        toEntry = await recordStore.saveDraftEntry();
      }

      await batchRequests(bucket.allIds, async (ids) => {
        const fromEntry = recordStore.entryCache.get(bucket.entryUuid!);
        if (!fromEntry) {
          console.error("Could not move document: fromEntry not available.");
          return;
        }

        await this.api.batch({
          ids,
          type: "Document",
          action: "move",
          parentId: toEntry.uuid,
          parentType: "Entry",
        });

        bucket.items.forEach((doc) => {
          toEntry.addDocument(doc);
          fromEntry?.removeDocument(doc.uuid);
        });

        if (fromEntry?.isSingleDocumentEntry) {
          fromEntry.delete();
        }
      });

      bucket.cancelAction();

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.moveDocumentsSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.moveDocumentsFailed,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  moveSingleDocumentEntriesToEntry = async (
    bucket: EntryBucket,
    toEntry: EntryModel
  ) => {
    const { messageStore, recordStore, resultStore } = this.rootStore;
    const { itemCount: count } = bucket;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.moveDocumentsStarted,
      values: { count },
    });

    bucket.inProgress = true;
    try {
      if (toEntry.isDraft) {
        toEntry = await recordStore.saveDraftEntry();
      }

      await batchRequests(bucket.allIds, async (ids) => {
        await this.api.batch({
          ids,
          type: "Entry",
          action: "merge",
          targetId: toEntry.uuid,
        });
      });

      bucket.cancelAction();
      bucket.clearSelection();
      resultStore.resetSearch();
      await resultStore.loadEntryResults();
      await toEntry.reload();

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.moveDocumentsSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.moveDocumentsFailed,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  moveDocumentsToSection = async (
    bucket: DocumentBucket,
    newSection: SectionModel
  ) => {
    const { messageStore, recordStore, resultStore } = this.rootStore;
    const { itemCount: count } = bucket;

    const messageId = messageStore.addMessage({
      type: "updating",
      title: texts.moveDocumentsStarted,
      values: { count },
    });

    bucket.inProgress = true;
    try {
      await batchRequests(bucket.allIds, async (ids) => {
        const entryUuid = bucket.entryUuid;
        if (entryUuid) {
          const fromEntry = recordStore.entryCache.get(entryUuid);
          if (!fromEntry) {
            console.error("Could not move document: fromEntry not available.");
            return;
          }
        }

        await this.api.batch({
          ids,
          type: "Document",
          action: "split",
          targetId: newSection.uuid,
        });
      });

      bucket.cancelAction();
      bucket.clearSelection();
      resultStore.resetSearch();
      resultStore.loadEntryResults();

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.moveDocumentsSuccess,
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.moveDocumentsFailed,
      });
    } finally {
      bucket.inProgress = false;
    }
  };

  @action.bound
  moveEntriesToSection = async (
    bucket: EntryBucket,
    newSection: SectionModel
  ) => {
    const { messageStore, recordStore, resultStore } = this.rootStore;

    const count = bucket.itemCount;
    const messageId = messageStore.addMessage({
      type: "updating",
      title: commonStoreTexts.movingSection,
      text: commonStoreTexts.movingSectionSuccessText,
      values: { count },
    });

    try {
      bucket.inProgress = true;

      await batchRequests(bucket.items, async (entries) => {
        await recordStore.moveMultipleToSection(entries, newSection);
      });

      bucket.removeMissingTags();
      resultStore.recentEntries.push(...bucket.items);
      bucket.cancelAction();

      messageStore.updateMessage(messageId, {
        type: "success",
        title: commonStoreTexts.movingSectionSuccessTitle,
        text: undefined,
        values: { count },
      });
    } catch (err) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: commonStoreTexts.movingSectionFailure,
        values: { count },
      });
    } finally {
      bucket.inProgress = false;
    }
  };
}

async function batchRequests<ItemType>(
  items: ItemType[],
  handler: (items: ItemType[]) => Promise<void>,
  batchSize = 100
): Promise<void> {
  const batches = chunk(items, batchSize);

  for (const batch of batches) {
    await handler(batch);
  }
}

const texts = defineMessages({
  deletingSelection: {
    id: "selection.message.deleting",
    defaultMessage: "Deleting selection",
  },
  deletingSelectionSuccess: {
    id: "selection.message.deleting.success",
    defaultMessage: "Selection deleted",
  },
  deletingSelectionFailure: {
    id: "selection.message.deleting.failed",
    defaultMessage: "Deletion failed",
  },
  taggingSection: {
    id: "request.entry.batch.tag.inprogress",
    defaultMessage: "Applying tags",
  },
  taggingSectionSuccess: {
    id: "request.entry.batch.tag.success",
    defaultMessage: "Tags applied",
  },
  taggingSectionFailure: {
    id: "request.entry.batch.tag.failed",
    defaultMessage: "Applying tags failed",
  },
  moveDocumentsStarted: {
    id: "request.document.batch.move.inprogress",
    defaultMessage: "Moving {count, plural, one {document} other {documents}}…",
  },
  moveDocumentsSuccess: {
    id: "request.document.batch.move.success",
    defaultMessage: "{count, plural, one {Document} other {Documents}} moved",
  },
  moveDocumentsFailed: {
    id: "request.document.batch.move.failed",
    defaultMessage: "Could not move documents",
  },
  copyDocumentsStarted: {
    id: "request.document.batch.copy.inprogress",
    defaultMessage:
      "Copying {count, plural, one {document} other {documents}}…",
  },
  copyDocumentsSuccess: {
    id: "request.document.batch.copy.success",
    defaultMessage: "{count, plural, one {Document} other {Documents}} copied",
  },
  copyDocumentsFailed: {
    id: "request.document.batch.copy.failed",
    defaultMessage: "Could not copy documents",
  },
});
