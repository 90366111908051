import * as React from "react";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import styled from "styled-components";
import loadingSvgSrc from "@web/assets/loading.svg";

export function Loading({ text }: { text?: MessageDescriptor }) {
  return (
    <Wrap>
      <img src={loadingSvgSrc} />
      {text && <FormattedMessage {...text} />}
    </Wrap>
  );
}

const Wrap = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 0.5rem;
  width: 100%;
  height: 100%;

  img {
    width: 50px;
    height: 50px;
  }
`;
