import React from "react";

export const MoreIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="6" cy="12" r="2" fill="var(--svg-fill, currentColor)" />
    <circle cx="12" cy="12" r="2" fill="var(--svg-fill, currentColor)" />
    <circle cx="18" cy="12" r="2" fill="var(--svg-fill, currentColor)" />
  </svg>
);
