import React from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import ellipsis from "polished/lib/mixins/ellipsis";
import Dropzone from "react-dropzone";
import { CheckmarkCheckedIcon } from "@web/elements/Icons";
import { DocumentCard } from "@web/components/DocumentCard";
import { DocumentModel } from "@web/models";
import { DocumentTitleStartsWith } from "@web/api/Integration/types";
import { vars } from "@web/styles";
import { media } from "@web/styles/utils";

interface Props {
  rule: DocumentTitleStartsWith;
  documents: DocumentModel[];
  isComplete: boolean;
  onDropFiles: (acceptedFiles: File[], prefix: string) => void;
  onDocumentClick: (document: DocumentModel) => void;
}

export const ChecklistItem: React.FC<Props> = (p) => {
  const intl = useIntl();

  const title = `${p.rule.name} (${
    p.isComplete
      ? intl.formatMessage(texts.complete)
      : intl.formatMessage(texts.incomplete)
  })`;

  return (
    <_wrap data-complete={p.isComplete}>
      <_details>
        <_checkIcon />

        <_title>{title}</_title>

        {p.rule.description && (
          <_content>
            <ul>
              <li>{p.rule.description}</li>
            </ul>
          </_content>
        )}
      </_details>

      <_documents>
        {p.documents.map((doc) => (
          <DocumentCard
            key={"checklist_" + doc.id}
            document={doc}
            previewSize={160}
            onClick={() => p.onDocumentClick(doc)}
          />
        ))}
      </_documents>

      <Dropzone
        onDrop={(files) => p.onDropFiles(files, p.rule.prefix)}
        noDragEventsBubbling={true}
      >
        {({ isDragAccept, isDragReject, getRootProps, getInputProps }) => (
          <_dropzone
            {...getRootProps()}
            isAccept={isDragAccept}
            isReject={isDragReject}
          >
            <input {...getInputProps()} />
            <span>{intl.formatMessage(texts.uploadText)}</span>
            <span>
              {intl.formatMessage(texts.prefix)}: <em>{p.rule.prefix}</em>
            </span>
          </_dropzone>
        )}
      </Dropzone>
    </_wrap>
  );
};

const _wrap = styled.section`
  font-size: 1rem;
  display: grid;
  grid-gap: 1em;
  margin: 1.5rem 0.5rem;

  & + & {
    margin-top: 2rem;
  }
`;

const _details = styled.article`
  position: relative;
  padding-left: 2.5em;
  min-width: 0; // needed for text overflowing
  line-height: 1.4;
`;

const _checkIcon = styled(CheckmarkCheckedIcon)`
  position: absolute;
  top: 0.13rem;
  left: 0;

  ${_wrap}[data-complete=false] & {
    --fill-color: ${vars.secondaryAltLight10};
  }

  ${_wrap}[data-complete=true] & {
    --fill-color: ${vars.success};
  }
`;

const _title = styled.h1`
  font-size: 1em;
  margin: 0;
  padding: 0.25em 0;
  font-weight: 600;

  svg {
    margin-left: 0.5rem;
  }
`;

const _content = styled.div`
  display: grid;
  grid-gap: 1em;
  font-size: 0.8125em;

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &[aria-hidden="true"] {
    p {
      ${ellipsis()}
    }

    ul {
      display: none;
    }
  }
`;

const _documents = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 400px));
  grid-gap: 0.5rem;

  :empty {
    display: none;
  }
`;

const _dropzone = styled.div<{ isAccept: boolean; isReject: boolean }>`
  border: 2px dashed ${vars.secondaryAltLight10};
  border-radius: 5px;
  padding: 1rem 2.25rem;
  cursor: pointer;
  color: ${vars.secondaryAlt};
  display: flex;
  justify-content: space-between;
  font-weight: normal;

  em {
    font-weight: 500;
    font-style: normal;
    color: ${vars.primary};
  }

  ${media("compact")} {
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }

  border-color: ${(p) => p.isAccept && vars.success};
  border-color: ${(p) => p.isReject && vars.danger};
`;

const texts = defineMessages({
  uploadText: {
    id: "flow.checklist.item.upload",
    defaultMessage: "Drag and drop or <em>browse</em> to upload",
  },
  prefix: {
    id: "flow.checklist.item.prefix",
    defaultMessage: "Filename prefix",
  },
  complete: {
    id: "flow.checklist.item.complete",
    defaultMessage: "Complete",
  },
  incomplete: {
    id: "flow.checklist.item.incomplete",
    defaultMessage: "Incomplete",
  },
});
