import {
  DocumentEditSessionRaw,
  DocumentPreviewImage,
} from "@web/api/BFF/types";
import { SystemAccountUUID, EntryStatusName } from "@web/models";
import { DataServicesStatus } from "@web/api/Internal/types";

export enum IntegrationApiPaths {
  section = "api/v1/section",
  classification = "api/v1/classification",
  tag = "api/v1/tag",
  tagStatus = "api/v1/tag-status",
  accessGroup = "api/v2/access-group",
  permissions = "api/v1/permissions",
  attribute = "api/v1/attribute-definition",
  attributeValue = "api/v1/attribute-value",
  attributeListValue = "api/v1/attribute-list-value",
  entry = "api/v1/entry",
  entryBulk = "api/v1/entry/bulk-create",
  entry_facet = "api/v1/entry/facet",
  entryStatus = "api/v1/entry-status",
  documentBulk = "api/v1/document/bulk-create",
  document = "api/v1/document",
  documentVersion = "api/v1/document-version",
  downloadFile = "api/v1/download",
  comment = "api/v1/comment",
  lock = "api/v1/lock",
  reminder = "api/v1/reminder",
  uploadFile = "api/v1/upload-temp",
  listContainer = "api/v1/list-container",
  flowContainer = "api/v1/flow-container", // For FlowContainer permissions
  flow_container = "api/v1/flow/flow-container", // For FlowContainer objects
  flow_pipeline = "api/v1/flow/pipeline",
  flow_result = "api/v1/flow/result",
  flow_rule = "api/v1/flow/rule",
  flow_event = "api/v1/flow/event",
  flow_condition = "api/v1/flow/condition",
  flow_outcome = "api/v1/flow/outcome",
  flow_target = "api/v1/flow/target",
}

/* New API interfaces */
export type UUID = string;

/* Types for API queries  **/
export type APIParamValue = string | number | boolean | (string | number)[];
export type APIParameters = Record<string, APIParamValue>;

export type Permission =
  | "CreateChildren"
  | "Delete"
  | "DeleteChildren"
  | "ReadChildren"
  | "ReadEntries"
  | "ReadObject"
  | "ModifyChildrenPermissions"
  | "ModifyPermissions"
  | "Update"
  | "UpdateChildren"
  | "UpdateChildrenSystemFields"
  | "UpdateSystemFields";

export type ServicePermission =
  | "UploadDocuments"
  | "ChangeEntryStatus"
  | "ManageAccessGroups"
  | "ManageFlows";

export interface IPermissions {
  effectivePermissions: Permission[];
}

export interface ILoadingStatus {
  hasMore: boolean;
  pageSize: number;
  page: number;
  total?: number;
}

export interface ILoadData<T> extends ILoadingStatus {
  data: Array<T>;
}

export interface ILoadObject<T> {
  data: T;
}

export interface IUpdateLinkedObjects {
  add: UUID[];
  remove: UUID[];
}

export interface IAutocomplete<K extends string> {
  data: Record<K, { suggestions: string[] }>;
}

export interface ISectionNode extends IPermissions {
  id: UUID; // This is the new UUID scheme
  // We need to use "internalIdentifier" to get the old numeric IDs
  internalIdentifier: number;
  title: string;
  classifications?: IClassificationNode[];
}

export interface IClassificationNode extends IPermissions {
  id: UUID; // This is the new UUID scheme
  // We need to use "internalIdentifier" to get the old numeric IDs
  internalIdentifier: number;
  createdDate: string;
  title: string;
  isMandatory: boolean;
  sections?: { id: UUID }[];
  tagStatuses?: IColorStatusNode[];
  attributeDefinitions?: IAttributeNode[];
}

export interface IClassificationNodeExtended extends IPermissions {
  id: UUID;
  internalIdentifier: number;
  title: string;
  sections: { id: UUID; title: string }[];
}

export interface MissingClassificationsResponse {
  // at most 5 classifications will be returned
  data: MissingClassificationNode[];
  // whether or not more than the returned classifications is missing
  hasMore: boolean;
}

export interface MissingClassificationNode {
  id: UUID;
  title: string;
  // at most 5 tags will be returned
  tags: Array<{ id: UUID; title: string }>;
  // whether or not more than the returned tags exists
  hasMore: boolean;
}

export interface ITagNode extends IPermissions {
  id: UUID; // This is the new UUID scheme
  // We need to use "internalIdentifier" to get the old numeric IDs
  internalIdentifier: number;
  createdDate: string;
  title: string;
  tagStatus?: IColorStatusNode;
  attributeDefinitions?: IAttributeNode[];
  attributeValues?: IAttributeValueNode[];
  attributeListValues?: IAttributeValueNode[];
}

export interface ITagNodeExpanded extends ITagNode {
  classification: { id: UUID; title: string };
}

export type IAttributeType =
  | "Date"
  | "String"
  | "Numeric"
  | "DateTime"
  | "DateList"
  | "StringList"
  | "NumericList";

export interface IAttributeNode extends IPermissions {
  id: UUID;
  internalIdentifier: number;
  name: string;
  attributeType: IAttributeType;
  attributeListValues?: IAttributeValueNode[];
}

export interface IAttributeValueNode extends IPermissions {
  id: UUID;
  valueDate?: string;
  valueDateTime?: string;
  valueString?: string;
  valueDouble?: number;
  definition: Pick<IAttributeNode, "id">;
  updatedDate: Date;
  entry?: Pick<IEntryNode, "id">;
  tag?: Pick<ITagNode, "id">;
}

export interface IEntryNode extends IPermissions {
  id: UUID;
  internalIdentifier: number;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  title?: string;
  section: {
    id: UUID;
    internalIdentifier: number;
    title: string;
  };
  entryStatus?: IColorStatusNode;
  tags: ITagNodeExpanded[];
  documents: IDocumentNode[];
  reminders?: IReminderNode[];
  highlights?: Record<string, string[]>;
  attributeValues?: IAttributeValueNode[];
  attributeListValues?: IAttributeValueNode[];
  resources?: {
    documents: ILoadingStatus & {
      totalMatchingDocuments?: number; // Added by BFF
    };
    tags?: ILoadingStatus;
    reminders?: ILoadingStatus;
  };
  isSingleDocumentEntry?: boolean;
}

export interface IReminderNode extends IPermissions {
  id: UUID;
  title: string;
  reminderDate: string;
  createdBy: string;
  createdByUserId: UUID;
  description?: string;
}

export interface IDocumentNode extends IPermissions {
  id: UUID;
  internalIdentifier: number;
  title: string;
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  documentVersions: IDocumentVersionNode[];
  entry: {
    id: UUID;
    internalIdentifier: number;
    section: {
      internalIdentifier: number;
    };
    title?: string;
    isSingleDocumentEntry?: boolean;
  };
  resources: {
    documentVersions: ILoadingStatus;
  };
  lock?: ILockNode;
  highlights?: Record<string, string[]>;
  // Added by BFF
  editSession?: DocumentEditSessionRaw;
}

export interface IUpdatedDocumentNode
  extends Pick<
    IDocumentNode,
    | "createdBy"
    | "createdDate"
    | "effectivePermissions"
    | "id"
    | "internalIdentifier"
    | "title"
    | "updatedDate"
  > {
  createdByUserId: UUID;

  resources: {
    self: string;
  };
  revision: number;

  updatedBy: string;
  updatedByUserId: UUID;
}

export interface IPollableDocumentNode extends IPermissions {
  id: UUID;
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  documentVersions: Array<{ id: string; externalIds?: IExternalIdNode[] }>;
  lock?: ILockNode;
  // Added by BFF
  editSession?: DocumentEditSessionRaw;
}

export interface IPollableEntryNode extends IPermissions {
  id: UUID;
  createdDate: string;
  updatedDate: string;
  documents: IDocumentNode[];
  entryStatus?: IColorStatusNode;
  resources: {
    documents: ILoadingStatus;
  };
  dataServicesStatus?: DataServicesStatus;
}

export interface IDocumentVersionNode extends IPermissions {
  id: UUID;
  internalIdentifier: number;
  versionNumber: number;
  createdDate: string;
  updatedDate: string;
  createdBy: string;
  fileName: string;
  fileSize: number;
  checksum: string;
  contentType: string;
  highlights?: Record<string, string[]>;
  electronicDocument: {
    internalIdentifier: number;
    id: UUID;
  };
  externalIds?: IExternalIdNode[];

  // Added by BFF
  documentPreviewImage?: DocumentPreviewImage;
  fileExt?: string;
  editWith?: string[];
}

export interface IElectronicDocumentNode {
  id: UUID;
  checksum: string;
}

export interface IExternalIdNode {
  id: UUID;
  externalId: string;
  createdDate: string;
}

export interface ILockNode extends IPermissions {
  id: UUID;
  expiryDate: string;
  createdBy: string;
  createdByUserId: UUID;
  createdDate: string;
  updatedBy: string;
  updatedByUserId: UUID;
  updatedDate: string;
}

export interface ICommentNode extends IPermissions {
  id: UUID;
  createdDate: string;
  createdBy: string;
  text: string;
}

export interface IColorStatusNode {
  id: UUID;
  internalIdentifier: number;
  name: string;
  color: string | null;
  isArchived: boolean;
}

export interface IColorStatusNodeExtended extends IColorStatusNode {
  classification: {
    id: UUID;
  };
}

export interface IFacetCountNode {
  internalIdentifier: number;
  count: number;
}

export type ChangelogEventNode = {
  changed?: ChangelogChangedDetails;
  transactionId: number;
  eventType: "MOD" | "ADD" | "DEL";
  eventTime: string;
  fields: {
    [fieldName: string]: string;
  };
  type:
    | "Comment"
    | "Document"
    | "Entry"
    | "EntryStatus"
    | "Section"
    | "AttributeValue";
  // the entity/resource UUID of the entity `.type` this event relates to
  systemId: UUID;
  userName: string;
  userId: UUID | SystemAccountUUID;
};

export type ChangelogChangedDetails =
  | ChangedFieldsOnEntity
  | ChangedRelatedEntryStatusEntity;

export type AttributeValueChanges = {
  valueString?: {
    newValue: string | undefined;
    previousValue: string | undefined;
  };
  valueDate?: {
    newValue: Date | undefined;
    previousValue: Date | undefined;
  };
  valueDouble?: {
    newValue: number | undefined;
    previousValue: number | undefined;
  };
};

export type ChangedFieldsOnEntity = {
  [changedFieldName: string]:
    | OneRelatedEntityChanged
    | MultipleRelatedEntitiesChanges;
};

type OneRelatedEntityChanged = {
  previousValue: string | null;
  newValue: string | null;
};

type MultipleRelatedEntitiesChanges = Array<OneRelatedEntityChanged>;

export type ChangedRelatedEntryStatusEntity = {
  EntryStatus: {
    previousValue: UUID | null;
    newValue: UUID | null;
    fields: {
      name: EntryStatusName | null;
    };
  };
};

export type ChangedRelatedTags = {
  Tag: Array<{
    previousValue: UUID | null;
    newValue: UUID | null;
    fields: {
      title: string;
    };
  }>;
};

export type ChangedRelatedAttributeListValues = {
  AttributeListValue: Array<{
    previousValue: UUID | null;
    newValue: UUID | null;
    fields: {
      definitionTitle: string;
      valueString?: string;
      valueDate?: string;
      valueDouble?: number;
    };
  }>;
};

export type ChangedAttributeValues = {
  AttributeValue: {
    previousValue: UUID | null;
    newValue: UUID | null;
    fields: {
      definitionTitle: string;
      valueString?: string;
      valueDate?: Date;
      valueNumeric?: number;
    };
  };
};

export interface IPipelineNode extends IPermissions {
  id: UUID;
  createdDate: string;
  createdBy: string;
  name: string;
  pipelineType?: "validation" | "notification" | "reminder";
  description: string;
  isEnabled: boolean;
  target: ITargetNode;
  conditions: IConditionNode[];
  rules: IRuleNode[];
  events: IEventNode[];
  successOutcomes: IOutcomeNode[];
  failureOutcomes: IOutcomeNode[];
  resources: {
    rules: ILoadingStatus;
  };
}

export type OutcomeType =
  | "AddEntryComment"
  | "ChangeEntryStatus"
  | "SendSlackNotification"
  | "SendTeamsNotification";

interface CommonOutputNodeFields {
  id: UUID;
  type: OutcomeType;
  name: string;
  description?: string;
}

export interface ICommentOutcomeNode extends CommonOutputNodeFields {
  type: "AddEntryComment";
  entryComment: string;
}

export interface IStatusOutcomeNode extends CommonOutputNodeFields {
  type: "ChangeEntryStatus";
  entryStatus: UUID;
}

export interface INotificationOutcomeNode extends CommonOutputNodeFields {
  type: "SendSlackNotification" | "SendTeamsNotification";
  webhookURL: string;
  isSensitive: boolean;
}

export type IOutcomeNode =
  | ICommentOutcomeNode
  | IStatusOutcomeNode
  | INotificationOutcomeNode;

export type ConditionType =
  | "EntryHasTag"
  | "EntryHasSection"
  | "UserBelongsToGroup"
  | "TagHasClassification";

interface CommonConditionNodeFields {
  id: UUID;
  name: string;
  description: string;
}

export interface ITagConditionNode extends CommonConditionNodeFields {
  type: "EntryHasTag";
  entryTagId: UUID;
}

export interface ISectionConditionNode extends CommonConditionNodeFields {
  type: "EntryHasSection";
  entrySectionId: UUID;
}

export interface IUserConditionNode extends CommonConditionNodeFields {
  type: "UserBelongsToGroup";
  groupId: string;
}

export type IConditionNode =
  | ITagConditionNode
  | ISectionConditionNode
  | IUserConditionNode;

export type RuleType =
  | "DocumentTitleStartsWith"
  | "EntryHasTagFromClassification"
  | "EntryHasExactTag"
  | "EntryHasAttributeValue";

interface CommonRuleNodeFields {
  id: UUID;
  type: RuleType;
  name: string;
  description: string;
}

export interface DocumentTitleStartsWith extends CommonRuleNodeFields {
  type: "DocumentTitleStartsWith";
  prefix: string;
}

export interface EntryHasAttributeValue extends CommonRuleNodeFields {
  type: "EntryHasAttributeValue";
  definitionId: UUID;
}

export interface EntryHasExactTag extends CommonRuleNodeFields {
  type: "EntryHasExactTag";
  entryTagId: UUID;
}

export interface EntryHasTagFromClassification extends CommonRuleNodeFields {
  type: "EntryHasTagFromClassification";
  entryTagClassificationId: UUID;
}

export type IRuleNode =
  | DocumentTitleStartsWith
  | EntryHasExactTag
  | EntryHasTagFromClassification
  | EntryHasAttributeValue;

export interface IExecutionNode {
  id: UUID;
  targetType: "Entry" | "Document";
  targetId: UUID;
  results: IResultNode[];
}

export interface IResultNode {
  id: UUID;
  componentId: UUID;
  componentType: "condition" | "rule";
  isSuccessful: boolean;
  createdDate: string;
}

export interface IEventNode {
  id: UUID;
  name: string;
}

export type TargetName = "Entry" | "Tag";

export interface ITargetNode {
  id: UUID;
  name: TargetName;
}

export enum ClaimsMode {
  ANY = "ANY",
  ALL = "ALL",
}

export interface IAccessGroupNode {
  id: UUID;
  name: string;
  description: string;
  claims: string[];
  claimsMode: ClaimsMode;
  globalPermissions: Permission[];
  servicePermissions: ServicePermission[];
}

export interface IObjectWithAccessControl {
  id: UUID;
  effectivePermissions: Permission[];
  permissions?: IPermissionNode[];
}

export interface IPermissionNode {
  id: UUID;
  explicitPermissions: Permission[];
  accessGroup: IAccessGroupNode;
}

export interface IPermissionAdd {
  explicitPermissions: Permission[];
  accessGroup: { id: UUID };
  section?: { id: UUID };
  classification?: { id: UUID };
  tag?: { id: UUID };
  flowContainer?: { id: UUID };
  listContainer?: { id: UUID };
}

export interface ISectionWithAccessControl extends IObjectWithAccessControl {
  title: string;
}

export interface IClassificationWithAccessControl
  extends IObjectWithAccessControl {
  title: string;
  determinesAccessControl: boolean;
}

export interface ITagWithAccessControl extends IObjectWithAccessControl {
  title: string;
  classification: {
    title: string;
  };
}

export interface IFlowContainerWithAccessControl
  extends IObjectWithAccessControl {
  name: string;
}

export interface IListContainerWithAccessControl
  extends IObjectWithAccessControl {}
