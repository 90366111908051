import React from "react";
import styled from "styled-components";
import { vars } from "@web/styles";
import { BackButton, CloseButton } from "@web/elements/Button";
import { Loading } from "@web/elements/Loading";

interface Props {
  title: string;
  subtitle?: string;
  showButton?: "back" | "close";
  showSpinner?: boolean;
  onButtonClick?: () => void;
}

export const Header: React.FC<Props> = (p) => (
  <_header>
    {p.showSpinner && (
      <_spinner>
        <Loading />
      </_spinner>
    )}
    {p.showButton === "back" && !p.showSpinner && (
      <BackButton onClick={p.onButtonClick} />
    )}
    <h3>
      {p.title}
      {p.subtitle && <span>: {p.subtitle}</span>}
    </h3>
    {p.showButton === "close" && <CloseButton onClick={p.onButtonClick} />}
  </_header>
);

const _header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0px 10px;
  min-height: 50px;
  border-bottom: 2px solid ${vars.dark05};
  h3 {
    padding: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    flex: 1;
    :not(:only-child) {
      :first-child:nth-last-child(2) {
        margin-left: 32px;
      }
      :last-child:nth-last-child(1) {
        margin-right: 32px;
      }
    }
  }
`;

const _spinner = styled.div`
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
  }
`;
