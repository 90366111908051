import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { AttributeValueChanges } from "@web/api/Integration/types";
import { ChangeLogEvent } from "@web/components/Entry/HistoryPane";
import { Label } from "@web/elements";
import { ChangelogModModel, SingleEventModel } from "@web/models";
import { MessageWithValues } from "@web/translations";
import { RelatedComment } from "./RelatedComment";

export const AttributeValueEvent: React.FC<{
  event: SingleEventModel;
  canUpdateEntry: boolean;
}> = ({ event, canUpdateEntry }) => {
  const _textForEvent = (
    event: SingleEventModel
  ): MessageWithValues | undefined => {
    if (event.eventType === "MOD") {
      const changeEvent = event as ChangelogModModel<AttributeValueChanges>;
      let newValue: string | number | undefined =
        changeEvent.changed.valueString?.newValue;
      if (!newValue) {
        newValue = changeEvent.changed.valueDate
          ? (newValue = changeEvent.changed.valueDate?.newValue?.toString())
          : "";
      }
      if (!newValue) {
        newValue = Number(changeEvent.changed.valueDouble?.newValue);
      }
      return {
        ...textByEvent.AttributeValue_MOD,
        values: {
          attributeTitle: (
            <Label
              key={
                "change-attribute-value-" +
                event.eventTime.toString() +
                event.id
              }
              values={[changeEvent.fields.definitionTitle || changeEvent.id]}
            />
          ),
          newValue,
        },
      };
    } else {
      const eventText =
        event.eventType === "ADD"
          ? textByEvent.AttributeValue_ADD
          : textByEvent.AttributeValue_DEL;
      return {
        ...eventText,
        values: {
          attributeTitle: (
            <Label
              key={
                "change-attribute-value-" +
                event.eventTime.toString() +
                event.id
              }
              values={[event.fields.definitionTitle]}
            />
          ),
          newValue:
            event.fields.valueString ||
            event.fields.valueDate?.toString() ||
            event.fields.valueDouble,
        },
      };
    }
  };
  const text = _textForEvent(event);
  if (!text) return null;
  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.eventTime}
    >
      <>
        <FormattedMessage {...text} />{" "}
        {event.relatedComment && (
          <RelatedComment
            canUpdateEntry={canUpdateEntry}
            commentEvent={event.relatedComment}
          />
        )}
      </>
    </ChangeLogEvent>
  );
};

const textByEvent = defineMessages({
  AttributeValue_ADD: {
    id: "entry.historypane.event.attributevalue.added",
    defaultMessage: "{attributeTitle} set to <em>{newValue}</em>",
  },
  AttributeValue_MOD: {
    id: "entry.historypane.event.attributevalue.modified",
    defaultMessage: "{attributeTitle} changed to <em>{newValue}</em>",
  },
  AttributeValue_DEL: {
    id: "entry.historypane.event.attributevalue.cleared",
    defaultMessage: "cleared {attributeTitle}",
  },
});
