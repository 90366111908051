import React, { useCallback } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  ObjectPermissionsMatrixModel,
  ObjectWithAccessControlModel,
} from "@web/models/AccessControlModel";
import {
  IClassificationWithAccessControl,
  IObjectWithAccessControl,
  ISectionWithAccessControl,
  ITagWithAccessControl,
} from "@web/api/Integration/types";
import { BackButton } from "@web/elements/Button";
import { vars } from "@web/styles";
import { CaretRightIcon } from "@web/elements/Icons";
import { Checkbox } from "@web/elements";
import { StyledButton } from "@web/elements/Button/styles";
import { Label } from "@web/elements/Label";
import { LazyList } from "../LazyList";
import {
  AddPermissionsCell,
  ObjectPermissionsCell,
  AllObjectPermissionsCell,
} from "./PermissionCell";

interface Props<ObjectType extends IObjectWithAccessControl> {
  matrix: ObjectPermissionsMatrixModel<ObjectType>;
  hideObjects?: boolean;
  renderMatrixHeader?: () => JSX.Element;
  renderRowTitle: (
    object: ObjectWithAccessControlModel<ObjectType>
  ) => JSX.Element;
}

interface RowProps<ObjectType extends IObjectWithAccessControl> {
  matrix: ObjectPermissionsMatrixModel<ObjectType>;
  object: ObjectWithAccessControlModel<ObjectType>;
  renderRowTitle: (
    object: ObjectWithAccessControlModel<ObjectType>
  ) => JSX.Element;
}

const ROW_HEIGHT = 47;
const ROW_SPACING = 8;

export const ObjectPermissionsMatrix = observer(
  <ObjectType extends IObjectWithAccessControl>({
    renderMatrixHeader,
    renderRowTitle,
    matrix,
    hideObjects,
  }: Props<ObjectType>) => {
    const renderLoadingItem = useCallback(() => null, []);

    const renderRow = useCallback(
      (index: number) => (
        <Row
          matrix={matrix}
          object={matrix.objects[index]}
          renderRowTitle={renderRowTitle}
        />
      ),
      [matrix.objects]
    );

    const showObjects = hideObjects !== true;
    return (
      <_matrix>
        {renderMatrixHeader && <div>{renderMatrixHeader()}</div>}
        <_content>
          {showObjects && (
            <LazyList
              itemSize={ROW_HEIGHT + ROW_SPACING}
              loadingStatus={matrix.loadingStatus}
              loadNextPage={matrix.loadNextPage}
              renderLoadingItem={renderLoadingItem}
              renderItem={renderRow}
              onQueryChange={matrix.filterObjects}
            />
          )}
        </_content>
      </_matrix>
    );
  }
);

const Row = observer(
  <ObjectType extends IObjectWithAccessControl>({
    matrix,
    object,
    renderRowTitle,
  }: RowProps<ObjectType>) => {
    const cutoff = 5;
    const visiblePermissions = object.objectPermissions.slice(0, cutoff);

    const showAddButton =
      object.canModifyPermissions && object.objectPermissions.length <= cutoff;

    const showMoreButton = object.objectPermissions.length > cutoff;

    return (
      <_row>
        <_rowTitle>{renderRowTitle(object)}</_rowTitle>
        {visiblePermissions.map((objectPermissions) => (
          <ObjectPermissionsCell
            showGroupName={true}
            key={objectPermissions.uuid}
            objectPermissions={objectPermissions}
            availablePermissions={matrix.availablePermissions}
            namedPermissionSets={matrix.namedPermissionSets}
            canModifyPermissions={object.canModifyPermissions}
          />
        ))}
        {showAddButton && (
          <_rowHoverCell>
            <AddPermissionsCell
              object={object}
              availablePermissions={matrix.availablePermissions}
              namedPermissionSets={matrix.namedPermissionSets}
              accessGroups={matrix.accessGroups}
            />
          </_rowHoverCell>
        )}
        {showMoreButton && (
          <AllObjectPermissionsCell
            permissionList={matrix.permissionListForObject(object)}
            availablePermissions={matrix.availablePermissions}
            namedPermissionSets={matrix.namedPermissionSets}
          >
            <FormattedMessage {...texts.showMore} />
          </AllObjectPermissionsCell>
        )}
      </_row>
    );
  }
);

export const RowTitle = observer((p: { children: React.ReactNode }) => (
  <_rowTitle>{p.children}</_rowTitle>
));

export const SectionRowTitle = observer(
  (p: { object: ObjectWithAccessControlModel<ISectionWithAccessControl> }) => (
    <_sectionRowTitle>{p.object.json.title}</_sectionRowTitle>
  )
);

export const ClassificationRowTitle = observer(
  (p: {
    object: ObjectWithAccessControlModel<IClassificationWithAccessControl>;
    onClick: () => void;
  }) => (
    <_rowTitleButton onClick={p.onClick}>
      <CaretRightIcon />
      <Label title={p.object.json.title} />
    </_rowTitleButton>
  )
);

export const TagRowTitle = observer(
  (p: { object: ObjectWithAccessControlModel<ITagWithAccessControl> }) => (
    <_rowTitle indent={39}>
      <Label title={p.object.json.title} />
    </_rowTitle>
  )
);

export const TagMatrixHeader = observer(
  (p: {
    classification:
      | ObjectWithAccessControlModel<IClassificationWithAccessControl>
      | undefined;
    onChange: (change: {
      classificationId: UUID;
      determinesAccessControl: boolean;
    }) => void;
    onCloseClick: () => void;
  }) => {
    if (!p.classification) {
      return null;
    }

    const intl = useIntl();

    const handleCheckboxChange = useCallback(() => {
      if (p.classification) {
        p.onChange({
          classificationId: p.classification.uuid,
          determinesAccessControl:
            !p.classification.json.determinesAccessControl,
        });
      }
    }, [p.classification.uuid]);

    return (
      <_tagMatrixHeader>
        <BackButton onClick={p.onCloseClick} />
        <_tagMatrixHeaderClassification>
          <Label title={p.classification.json.title} />
        </_tagMatrixHeaderClassification>
        <Checkbox
          label={intl.formatMessage(
            texts.classificationDeterminesAccessControl
          )}
          checked={p.classification.json.determinesAccessControl}
          onChange={handleCheckboxChange}
        />
      </_tagMatrixHeader>
    );
  }
);

const _matrix = styled.div`
  font-size: 0.825rem;
  height: 100%;
  background: white;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`;

const _tagMatrixHeader = styled.div`
  padding: 11px 0;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
`;

const _tagMatrixHeaderClassification = styled.div`
  width: 130px;
  margin-right: 10px;
`;

const _content = styled.div`
  position: relative;
  flex-grow: 2;
`;

const _rowHoverCell = styled.div`
  display: none;
`;

const _row = styled.div`
  position: relative;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 200px repeat(6, 160px);
  height: ${ROW_HEIGHT}px;
  overflow: hidden;
  align-items: stretch;
  justify-items: stretch;

  :hover > ${_rowHoverCell} {
    display: block;
  }
`;

const _rowTitle = styled.div<{ indent?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${(p) => p.indent ?? 0}px;
  overflow: hidden;
`;

const _rowTitleButton = styled(StyledButton).attrs({ variant: "blank" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  > svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const _sectionRowTitle = styled.div`
  background: ${vars.primary};
  color: ${vars.primaryFg};
  border: none;
  border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;
  cursor: default;
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  font: inherit;
  font-size: 0.8125rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const texts = defineMessages({
  classificationDeterminesAccessControl: {
    id: "accessControl.objectMatrix.classificationDeterminesAccessControl",
    defaultMessage: "Use this classification for access control",
  },
  showMore: {
    id: "accessControl.objectMatrix.showMore",
    defaultMessage: "+ More",
  },
});
