import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { canDelete, DocumentModel, DocumentVersionModel } from "@web/models";
import { media } from "@web/styles/utils";
import { VersionTag } from "@web/elements/VersionTag";
import { Menu, MenuItem } from "@web/components/Menu";
import { ConfirmDialog } from "@web/components/ConfirmDialog";
import { DescriptionList } from "@web/elements/DescriptionList";
import { Dropdown } from "@web/components/Dropdown";
import { MoreButton } from "@web/elements/Button";
import { texts } from "./texts";
import { CreatedBy } from "./CreatedBy";
import { SignatureDetails } from "./SignatureDetails";

interface IVersionItemProps {
  documentTitle: string;
  document: DocumentModel;
  version: DocumentVersionModel;
  isFocus?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onDelete: (versionId: UUID) => void;
}

export const VersionItem: React.FC<IVersionItemProps> = (p) => {
  const { createdBy, createdDate, fileName, versionNumber, checksum } =
    p.version;

  const intl = useIntl();
  const wrapRef = useRef<HTMLDivElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (p.isFocus) wrapRef.current?.focus();
  }, [p.isFocus]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const isEnterOrSpace = ["Enter", " "].includes(e.key);

    if (isEnterOrSpace) {
      e.preventDefault();
      p.onClick?.();
    }
  };

  const handleDeleteVersion = () => {
    p.onDelete(p.version.uuid);
    setShowDeleteDialog(false);
  };

  return (
    <_wrap
      ref={wrapRef}
      role="button"
      aria-selected={p.isSelected}
      tabIndex={0}
      onClick={p.onClick}
      onKeyDown={handleKeyDown}
    >
      <_header>
        <_version>
          <VersionTag versionNumber={versionNumber} />
        </_version>

        <_selectText>
          {p.isSelected
            ? intl.formatMessage(texts.versionSelectedVersion)
            : intl.formatMessage(texts.versionSelectVersion)}
        </_selectText>

        {canDelete(p.version) && (
          <_actions>
            <Dropdown
              variation="light"
              toggle={(ref) => (
                <MoreButton ref={ref} onClick={(e) => e.stopPropagation()} />
              )}
            >
              <Menu>
                <MenuItem onClick={() => setShowDeleteDialog(true)}>
                  {intl.formatMessage(texts.tooltipDeleteVersion)}
                </MenuItem>
              </Menu>
            </Dropdown>
          </_actions>
        )}
      </_header>

      <DescriptionList>
        <dt>{intl.formatMessage(texts.detailsFileName)}</dt>
        <dd>{fileName}</dd>
        <dt>{intl.formatMessage(texts.detailsChecksum)}</dt>
        <dd>{checksum}</dd>

        <SignatureDetails document={p.document} version={p.version} />

        <dt>{intl.formatMessage(texts.detailsUploaded)}</dt>
        <dd>
          <CreatedBy datetime={createdDate} userName={createdBy} />
        </dd>
      </DescriptionList>

      {showDeleteDialog && (
        <ConfirmDialog
          title={texts.dialogDeleteVersionTitle}
          message={texts.dialogDeleteVersionMessage}
          values={{ title: p.documentTitle, versionNumber }}
          onConfirm={handleDeleteVersion}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </_wrap>
  );
};

const _wrap = styled.div`
  padding: 1rem;
  position: relative;

  :not(:only-of-type) {
    cursor: pointer;

    &[aria-selected="true"] {
      background: #f6f4fb;
    }
  }
`;

const _header = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr auto;
  margin-bottom: 0.5rem;
`;

const _version = styled.div`
  opacity: 0.5;

  ${_wrap}:focus-within &,
  ${_wrap}:hover &,
  ${_wrap}[aria-selected=true] & {
    opacity: 1;
  }
`;

const _selectText = styled.span`
  color: #3b46c3;

  ${_wrap}[aria-selected=true] & {
    color: #0bac6b;
  }

  visibility: hidden;

  ${_wrap}:focus-within &,
  ${_wrap}:not(:only-of-type):hover & {
    visibility: visible;
  }
`;

const _actions = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  ${media("noTouch")} {
    visibility: hidden;

    ${_wrap}:focus-within &,
    ${_wrap}:hover & {
      visibility: visible;
    }
  }
`;
