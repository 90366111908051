import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { hGap } from "@web/styles/utils";
import throttle from "lodash/throttle";

interface OverflowerProps {
  gap?: number;
  onOverflowChange?: (overflowCount: number) => void;
  moreComponent: (args: {
    visibleItems: React.ReactNodeArray;
    overflowItems: React.ReactNodeArray;
    ref: (node: HTMLDivElement) => void;
  }) => React.ReactNode;
}

export const Overflower: React.FC<OverflowerProps> = (p) => {
  const [visibleItems, setVisibleItems] = useState<ReactNode[]>([]);
  const [overflowItems, setOverflowItems] = useState<ReactNode[]>([]);
  const [itemWidths, setItemWidths] = useState<number[]>([]);
  const [moreNode, setMoreNode] = useState<HTMLDivElement | null>(null);

  const wrapRef = useRef<HTMLDivElement>(null);

  /**
   * We use a callback ref instead to get notified when there are changes
   * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const moreRef = useCallback((node) => {
    if (node !== null) {
      setMoreNode(node);
    }
  }, []);

  useEffect(() => {
    setVisibleItems(React.Children.toArray(p.children));
    setOverflowItems([]);
    setItemWidths([]);
  }, [p.children]);

  useEffect(() => {
    p.onOverflowChange?.(visibleItems.length);
  }, [visibleItems.length]);

  useLayoutEffect(() => {
    if (wrapRef.current && !itemWidths.length) {
      /**
       * Since we don't have refs for the children,
       * we get the measurements from the actual DOM children.
       */
      const widths = Array.from(wrapRef.current.children)
        .filter((child) => child !== moreNode)
        .map((child) => child.getBoundingClientRect().width);

      setItemWidths(widths);
    }
    calculateOverflow();
    addEventListener("resize", throttledCalculateOverflow);
    return () => {
      removeEventListener("resize", throttledCalculateOverflow);
    };
  }, [itemWidths, moreNode]);

  const throttledCalculateOverflow = throttle(() => calculateOverflow(), 100);
  const calculateOverflow = () => {
    if (!wrapRef.current) return;

    const wrapWidth = wrapRef.current.getBoundingClientRect().width;
    const moreWidth = moreNode?.getBoundingClientRect().width ?? 0;
    const newVisibleItems: ReactNode[] = [];
    const newOverflowItems: ReactNode[] = [];

    /**
     * Looping through the React Children,
     * summing up its accumulated width at the current index
     * and deciding if it should be visible or not.
     */
    React.Children.forEach(p.children, (child, childIndex) => {
      const indexSum = itemWidths.reduce((sum, width, sumIndex) => {
        if (sumIndex <= childIndex) sum += width + (p.gap ?? 0);
        return sum;
      }, 0);

      if (indexSum + moreWidth < wrapWidth) {
        newVisibleItems.push(child);
      } else {
        newOverflowItems.push(child);
      }
    });

    setVisibleItems(newVisibleItems);
    setOverflowItems(newOverflowItems);
  };

  return (
    <_wrap ref={wrapRef} gap={p.gap}>
      {visibleItems}
      {p.moreComponent({ visibleItems, overflowItems, ref: moreRef })}
    </_wrap>
  );
};

const _wrap = styled.div<{ gap?: number }>`
  display: flex;
  overflow: hidden;
  width: 100%;
  ${(p) => p.gap && hGap(p.gap + "px")}
`;
