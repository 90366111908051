import {
  DocumentModel,
  DocumentUploadRequest,
  UploadJob,
  UploadJobStatus,
} from "@web/models";
import { UUID } from "@web/api/Integration/types";
import PubSub from "@web/stores/PubSub";
import { DocumentStore } from "@web/stores";
import { DocumentCreator } from "@web/components/Upload/creators/types";

export const createDocuments = async (
  documentStore: DocumentStore,
  documentUploadRequest: DocumentUploadRequest,
  allJobs: UploadJob[]
) => {
  const documents: DocumentCreator[] = [];

  const { entryId, entryUuid, files } = documentUploadRequest;

  if (!entryUuid) {
    throw new Error(
      "job.result.entryUuid is required before creating document"
    );
  }

  allJobs
    .filter((job) => [UploadJobStatus.uploading].includes(job.status))
    .forEach((job) => {
      const fileId = job.result.fileId;

      job.startProgressUpdate((time) => {
        const completedPercent = Math.min(100, 90 + 10 * (time / 20000));
        return { completedPercent };
      });

      if (!fileId) {
        job.setStatus(UploadJobStatus.failed);
        return;
      }

      documents.push({
        entry: { id: entryUuid },
        title: job.data.title,
        documentVersions: [
          {
            electronicDocument: {
              id: fileId,
            },
          },
        ],
      });
    });

  const models = await documentStore.createDocuments(documents);

  const fileIdDocumentMap: Map<UUID, DocumentModel> = new Map();

  models.forEach((doc) => {
    doc.versions.forEach((version) => {
      fileIdDocumentMap.set(version.electronicDocumentUuid, doc);
    });
  });

  allJobs.forEach((job, index) => {
    if (job.status !== UploadJobStatus.uploading) {
      return;
    }

    if (!job.result.fileId) {
      job.setStatus(UploadJobStatus.failed);
      return;
    }

    const doc = fileIdDocumentMap.get(job.result.fileId);
    if (doc) {
      job.setStatus(UploadJobStatus.completed);
      job.setResult({
        documentId: doc.id,
        documentUuid: doc.uuid,
        entryUuid: doc.entryUuid,
        entryId: doc.entryId,
      });
    } else {
      job.setStatus(UploadJobStatus.failed);
    }
  });
};
