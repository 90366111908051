import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import {
  isClassFilter,
  FilterModel,
  SelectedCustomFilterValues,
  TagSearchModel,
  FilterChangeEvent,
  SelectedTagsMap,
  SelectedTagStatusMap,
  TagFacetCountsModel,
} from "@web/models";
import { PopoverBox } from "@web/elements";
import { CustomFilter } from "@web/components/Filters/CustomFilter";
import { isMedia, media } from "@web/styles/utils";
import { vars, ZIndex } from "@web/styles";
import { Button } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { _action } from "./styles";
import { FilterButtonList } from "./FilterButtonList";
import { TagFilter } from "./TagFilter";

interface IEvents {
  onFilterChange: (change: FilterChangeEvent) => void;
  onTagStatusChange: () => void;
  onClearAllTags: (filters: FilterModel[]) => void;
  onCloseClick: () => void;
}

interface IProps extends IEvents {
  triggerRef: React.RefObject<HTMLDivElement>;
  filters: FilterModel[];
  selectedTags: SelectedTagsMap;
  selectedCustomValues: SelectedCustomFilterValues;
  selectedTagStatuses: SelectedTagStatusMap;
  tagSearch: TagSearchModel;
  noMatchText?: JSX.Element | string;
  facetCounts?: TagFacetCountsModel;
}

interface IState {
  selected?: FilterModel;
}

@observer
export class FilterSelect extends React.Component<IProps, IState> {
  state: IState = {
    selected: undefined,
  };

  get currentFilter() {
    const { filters } = this.props;
    const { selected } = this.state;

    // We are showing multiple filters, return the selected one
    if (this.isMultiple) {
      return selected;
    }

    // We are showing a single filter
    return filters[0];
  }

  get isMultiple() {
    return this.props.filters.length > 1;
  }

  handleSelect = (filterId: string) => {
    const { filters, tagSearch } = this.props;
    const selected = filters.find((f) => f.uuid === filterId);
    this.setState({ selected });
    if (selected && isClassFilter(selected)) {
      tagSearch.setActiveClassification(filterId);
    }
  };

  handleClear = (filterId: string) => {
    const { filters } = this.props;
    const filter = filters.find((f) => f.uuid === filterId);
    if (filter) {
      this.props.onClearAllTags([filter]);
    }
  };

  handleBackClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ selected: undefined });
  };

  handleClearAllClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { filters } = this.props;
    this.props.onClearAllTags(filters);
  };

  renderSelectedFilter(filter: FilterModel) {
    if (isClassFilter(filter)) {
      const selectedTagStatuses = this.props.selectedTagStatuses.get(
        filter.uuid
      );
      return (
        <TagFilter
          classification={filter}
          selectedTags={this.props.selectedTags.getTagTitles(filter.uuid)}
          tagSearch={this.props.tagSearch}
          selectedTagStatuses={selectedTagStatuses}
          onFilterChange={this.props.onFilterChange}
          facetCounts={this.props.facetCounts}
          onBackClick={this.isMultiple ? this.handleBackClick : undefined}
          onTagStatusChange={this.props.onTagStatusChange}
          onClearClick={() => this.handleClear(filter.uuid)}
        />
      );
    } else {
      return (
        <CustomFilter
          filter={filter}
          onBackClick={this.isMultiple ? this.handleBackClick : undefined}
          selectedValues={this.props.selectedCustomValues[filter.uuid] || []}
          onFilterChange={this.props.onFilterChange}
          onClearClick={() => this.handleClear(filter.uuid)}
        />
      );
    }
  }

  render() {
    const currentFilter = this.currentFilter;

    return (
      <PopoverBox
        alignY="top"
        margin={-5}
        triggerRef={this.props.triggerRef}
        onClickOutside={this.props.onCloseClick}
      >
        <Wrap>
          {currentFilter ? (
            this.renderSelectedFilter(currentFilter)
          ) : (
            <FilterButtonList
              filters={this.props.filters}
              selectedTags={this.props.selectedTags}
              selectedCustomValues={this.props.selectedCustomValues}
              selectedTagStatuses={this.props.selectedTagStatuses}
              onCloseClick={this.props.onCloseClick}
              onSelect={this.handleSelect}
              onClear={this.handleClear}
              onClearAllClick={this.props.onClearAllTags}
            />
          )}
          {isMedia("compact") && (
            <_action>
              <Button
                text={commonTexts.close}
                variant="primary"
                onClick={this.props.onCloseClick}
              />
            </_action>
          )}
        </Wrap>
      </PopoverBox>
    );
  }
}

const Wrap = styled.div`
  color: ${vars.primaryFg};
  background: ${vars.primaryDark20};
  display: flex;
  cursor: default;
  flex-direction: column;
  align-items: stretch;
  z-index: ${ZIndex.classSelect};
  font-size: 13px;
  -webkit-font-smoothing: antialiased;

  ${media("desktop")} {
    width: 287px;
    height: 440px;
    border-radius: 3px;
  }

  ${media("compact")} {
    height: 100%;
  }
`;
