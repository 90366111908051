import React, { FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { PipelineModel } from "@web/models";
import { EntryHasAttributeValue } from "@web/api/Integration/types";
import { ActionMenu, Input } from "@web/elements";
import { flowEntityTexts } from "../../texts";
import { AttributeDefinitionSelect } from "./AttributeDefinitionSelect";

interface Props {
  pipeline: PipelineModel;
  rule?: EntryHasAttributeValue;
  onClose: () => void;
}

export const EditTagHasAttributeRule: FC<Props> = observer((p) => {
  const intl = useIntl();

  const [name, setName] = useState(p.rule?.name || "");
  const [description, setDescription] = useState(p.rule?.description || "");
  const [selectedAttribute, setSelectedAttribute] = useState(
    p.rule?.definitionId || ""
  );

  const handleSaveRule = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (selectedAttribute) {
      const fields: Omit<EntryHasAttributeValue, "id"> = {
        name,
        description,
        type: "EntryHasAttributeValue",
        definitionId: selectedAttribute,
      };
      if (p.rule) {
        p.pipeline.updateRule(p.rule, fields);
      } else {
        p.pipeline.addRule(fields);
      }
    }
    p.onClose();
  };

  const handleAddedRequiredAttribute = (attributeId: string) => {
    setSelectedAttribute(attributeId);
  };

  const canSave = () => {
    if (p.rule) {
      return (
        name !== p.rule.name ||
        description !== (p.rule.description || "") ||
        p.rule.definitionId !== (selectedAttribute || "")
      );
    }
    return name.length > 0 && selectedAttribute;
  };

  return (
    <_wrap>
      <_edit onSubmit={handleSaveRule}>
        <_input>
          <Input
            placeholder={intl.formatMessage(flowEntityTexts.rules.name)}
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </_input>
        <_input>
          <Input
            placeholder={intl.formatMessage(flowEntityTexts.rules.description)}
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
          />
        </_input>
        <label>
          <FormattedMessage {...texts.mustHaveTagProperty} />
        </label>
        <AttributeDefinitionSelect
          tags={p.pipeline.tagConditionsExpanded}
          rule={p.rule}
          existingRules={p.pipeline.attributeRules}
          onSelectAttribute={handleAddedRequiredAttribute}
        />
        <ActionMenu
          onApply={handleSaveRule}
          onCancel={p.onClose}
          applyIsDisabled={!canSave()}
          applyText={p.rule ? texts.save : texts.add}
          direction="horizontal"
        />
      </_edit>
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f1f2f3;
  margin-bottom: 5px;
  border-radius: 3px;
  background: #e5e8fd;
  svg {
    visibility: hidden;
    margin-left: 5px;
  }
  > {
    margin-bottom: 10px;
  }
`;

const _edit = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  margin: 10px 5px;
  h3:not(:first-child) {
    margin-top: 15px;
  }
`;

const _input = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-grow: 1;
  input {
    font-size: 14px;
  }
  label {
    width: 100%;
  }
`;

const texts = defineMessages({
  add: {
    id: "flow.settings.rule.add",
    defaultMessage: "Add rule",
  },
  save: {
    id: "flow.settings.rule.save",
    defaultMessage: "Save rule",
  },
  mustHaveTagProperty: {
    id: "flow.settings.rule.must.have.tag.property",
    defaultMessage: "Must have tag property:",
  },
});
