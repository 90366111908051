import React, { FC, useEffect, useState, createRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import styled from "styled-components";
import { AttributeModel, AttributeValueModel } from "@web/models";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import { DMDatePicker } from "@web/components/DatePicker";
import { Header } from "../Header";
import { AddLink } from "../AddLink";
import { Empty } from "../Empty";
import { ActionMenu, Input } from "@web/elements";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { SettingsBox } from "../Box";
import { AttributeOptionMenu } from "./Menus";

interface Props {
  attribute: AttributeModel;
  onCloseClick?: () => void;
}

export const EditAttributeOptions: FC<Props> = observer(
  ({ attribute, onCloseClick }) => {
    const intl = useIntl();
    const listRef = createRef<HTMLDivElement>();
    const [getConfirmation] = useConfirmationDialog();
    const [input, setInput] = useState("");
    const [addOption, setAddOption] = useState(false);
    const [renameOption, setRenameOption] = useState<AttributeValueModel>();

    useEffect(() => {
      attribute.loadOptions();
    }, []);

    const handleDelete = async (option: AttributeValueModel) => {
      const confirmed = await getConfirmation({
        title: texts.deleteValueDialogTitle,
        message: texts.deleteValueDialogQuestion,
        confirmText: commonTexts.delete,
        warningText: commonTexts.asyncDeleteWarning,
        values: {
          title: option.formattedValue(intl),
          entity: "attributeListValue",
        },
      });

      if (confirmed) {
        attribute.deleteOption(option.uuid);
      }
    };

    const handleRename = (option: AttributeValueModel) => {
      setInput(String(option.rawValue));
      setRenameOption(option);
    };

    const handleSaveOption = (e?: React.FormEvent) => {
      e?.preventDefault();
      const trimmed = input.trim();
      if (addOption) {
        attribute.createOption(trimmed);
        setAddOption(false);
      } else if (renameOption) {
        attribute.updateOption(renameOption.uuid, trimmed);
        setRenameOption(undefined);
      }
      setInput("");
    };

    const handleCancel = () => {
      setAddOption(false);
      setRenameOption(undefined);
      setInput("");
    };

    const handleCloseClick = () => {
      if (addOption) {
        setAddOption(false);
      } else if (renameOption) {
        setRenameOption(undefined);
      } else {
        onCloseClick?.();
      }
    };

    const canSave = () => {
      const trimmed = input.trim();
      if (trimmed.length === 0) {
        return false;
      }

      const alreadyExists = attribute.hasOption(trimmed);

      if (renameOption) {
        return renameOption.rawValue !== trimmed && !alreadyExists;
      }

      return !alreadyExists;
    };

    const loading = attribute.optionsLoadingStatus.pageLoading;
    const title = addOption ? texts.create : texts.title;
    const isEditing = addOption || renameOption;

    return (
      <SettingsBox contentIsLoading={loading}>
        <Header
          title={attribute.name}
          subtitle={intl.formatMessage(title)}
          showButton={!isEditing ? "back" : undefined}
          onButtonClick={handleCloseClick}
        />

        {isEditing && (
          <_addOption onSubmit={handleSaveOption}>
            {attribute.type === "DateList" && (
              <DMDatePicker
                initialDate={input || null}
                excludeDates={attribute.optionList.map(
                  (o) => new Date(o.value)
                )}
                onDateSelect={(date) => setInput(date.toString())}
                placeholderText={intl.formatMessage(texts.placeholder)}
              />
            )}

            {attribute.type !== "DateList" && (
              <Input
                autoFocus
                value={input}
                placeholder={
                  renameOption
                    ? String(renameOption?.rawValue)
                    : intl.formatMessage(texts.placeholder)
                }
                type={attribute.type === "NumericList" ? "number" : "text"}
                onChange={(e) => setInput(e.target.value)}
              />
            )}
            <ActionMenu
              direction="vertical"
              applyIsDisabled={!canSave()}
              onApply={handleSaveOption}
              onCancel={handleCancel}
              applyText={addOption ? commonTexts.add : commonTexts.save}
            />
          </_addOption>
        )}

        {!isEditing && (
          <>
            {attribute.hasNoOptionsAdded && <Empty title={texts.empty} />}

            <_optionList ref={listRef}>
              {attribute.optionList.map((option) => (
                <_option key={option.uuid}>
                  {option.formattedValue(intl)}
                  <AttributeOptionMenu
                    attributeOption={option}
                    onDelete={() => handleDelete(option)}
                    onRename={() => handleRename(option)}
                  />
                </_option>
              ))}
            </_optionList>

            <AddLink title={texts.create} onClick={() => setAddOption(true)} />
          </>
        )}
      </SettingsBox>
    );
  }
);

const _option = styled.div`
  display: flex;
  padding: 8px 8px;
  align-items: center;
  justify-content: space-between;
  svg {
    flex-shrink: 0;
    visibility: hidden;
  }
  button {
    :focus {
      svg {
        visibility: visible;
      }
    }
  }
  &:hover {
    background-color: ${vars.info};
    svg {
      visibility: visible;
    }
  }
`;

const _optionList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 10px;
  flex: 1;
`;

const _addOption = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  flex: 1;
  padding: 0px 15px;
`;

const texts = defineMessages({
  title: {
    id: "settings.attribute.options.title",
    defaultMessage: "list values",
  },
  create: {
    id: "settings.attribute.options.add",
    defaultMessage: "Add list value",
  },
  empty: {
    id: "settings.attribute.options.empty",
    defaultMessage: "No values have been added to this list.",
  },
  placeholder: {
    id: "settings.attribute.options.placeholder",
    defaultMessage: "New value",
  },
  deleteValueDialogTitle: {
    id: "settings.attribute.options.deletedialog.title",
    defaultMessage: "Delete list value",
  },
  deleteValueDialogQuestion: {
    id: "settings.attribute.options.deletedialog.question",
    defaultMessage:
      "This will permanently remove the list value <em>{title}</em> from anywhere it has been applied. Are you sure you want to proceed?",
  },
});
