import React, { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import debounce from "lodash/debounce";
import { FilterModel, FilterChangeEvent } from "@web/models";
import { useStores } from "@web/stores/context";
import { Filters } from "@web/components";
import { AttributeFilterList } from "@web/components/AttributeFilters/AttributeFilterList";
import { isMedia } from "@web/styles/utils";
import { FilterButton } from "@web/components/Filters/FilterButton";

const FilterContainer: FC = observer(() => {
  const navigate = useNavigate();
  const {
    sectionStore,
    filterStore,
    tagStore,
    resultStore,
    messageStore,
    currentSearchURL,
  } = useStores();

  const navigateDebounced = debounce(() => {
    navigate(currentSearchURL());
  }, 1300);

  const handleFilterChange = (change: FilterChangeEvent) => {
    filterStore.setFilterValueSelected(change);
    navigateDebounced();
  };

  const handleTagStatusChange = () => {
    const { classificationId, tagStatuses } = tagStore.search;
    // Use `new Set(...)` to make a copy of the state from TagStore.serach
    // to keep the states as separate observables.
    filterStore.setTagStatusSelected(new Set(tagStatuses), classificationId!);
    navigateDebounced();
  };

  const handleClearFilters = (filters: FilterModel[]) => {
    filterStore.clearFilters(filters);
    tagStore.search.tagStatuses.clear();
    navigate(currentSearchURL());
  };

  const handleClearGlobalFilter = (classificationId: UUID) => {
    filterStore.selectedTags.clear(classificationId);
    navigate(currentSearchURL());
  };

  const handleAttributeFilterChange = () => {
    navigate(currentSearchURL());
  };

  const handleFilterClose = (filterId: string) => {
    filterStore.handleFilterClose(filterId);
    tagStore.resetSearch();
    filterStore.clearFacetCounts();
  };

  const handleFilterOpen = (filterId: string) => {
    filterStore.prepareFacetCounts(filterId, resultStore.params);
  };

  if (sectionStore.isGlobalSearch) {
    return (
      <_globalFilterList>
        {filterStore.selectedTags.map((cl, tags) => (
          <FilterButton
            key={cl}
            hideTitle
            title={""}
            selectedValues={tags}
            filterId={cl}
            variation="subtle"
            onClearClick={handleClearGlobalFilter}
          />
        ))}
      </_globalFilterList>
    );
  }

  return (
    <>
      <Filters
        key={sectionStore.selectedSection?.id}
        filters={filterStore.filters}
        selectedTags={filterStore.selectedTags}
        selectedCustomValues={filterStore.selectedCustomValues}
        selectedTagStatuses={filterStore.selectedTagStatuses}
        tagSearch={tagStore.search}
        facetCounts={filterStore.facetCounts}
        onClearFilters={handleClearFilters}
        onFilterChange={handleFilterChange}
        onTagStatusChange={handleTagStatusChange}
        onFilterOpen={handleFilterOpen}
        onFilterClosed={handleFilterClose}
        mandatoryClassMessageIsShown={messageStore.hasMandatoryClassMessage}
      />
      {isMedia("desktop") && (
        <AttributeFilterList
          selectedTags={filterStore.selectedTags}
          filters={filterStore.attributeFilters}
          onChange={handleAttributeFilterChange}
        />
      )}
    </>
  );
});

const _globalFilterList = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  > div {
    margin-right: 10px;
  }
`;

export default FilterContainer;
