import { defineMessages } from "react-intl";

export const texts = defineMessages({
  tooltipClose: {
    id: "document.tooltip.close",
    defaultMessage: "Close document",
  },
  tooltipDownload: {
    id: "document.tooltip.download",
    defaultMessage: "Download document",
  },
  tooltipDeleteDocument: {
    id: "document.tooltip.deleteDocument",
    defaultMessage: "Delete document",
  },
  tooltipDeleteLockedDocument: {
    id: "document.tooltip.deleteLockedDocument",
    defaultMessage: "Can't delete a locked document",
  },
  tooltipDeleteVersion: {
    id: "document.tooltip.deleteVersion",
    defaultMessage: "Delete version",
  },
  tooltipEditTitle: {
    id: "document.tooltip.editTitle",
    defaultMessage: "Edit title",
  },
  tooltipLockDocument: {
    id: "document.tooltip.lock",
    defaultMessage: "Lock document",
  },
  tooltipUnlockDocument: {
    id: "document.tooltip.unlock",
    defaultMessage: "Unlock document",
  },
  tooltipUploadLockedDocument: {
    id: "document.tooltip.uploadLockedDocument",
    defaultMessage: "Can't add new version to a locked document",
  },
  tooltipSendForSignature: {
    id: "document.tooltip.sendForSignature",
    defaultMessage: "Send document for signature",
  },
  tooltipSendForSignaturePdfOnly: {
    id: "document.tooltip.sendForSignaturePdfOnly",
    defaultMessage: "Send document for signature (PDF only)",
  },
  tooltipSendForSignatureAlreadySigned: {
    id: "document.tooltip.sendForSignatureAlreadySigned",
    defaultMessage: "Document is signed",
  },
  tooltipSendForSignatureAlreadySent: {
    id: "document.tooltip.sendForSignatureAlreadySent",
    defaultMessage: "Document is sent for signature",
  },
  tooltipSendForSignatureNewestVersionOnly: {
    id: "document.tooltip.sendForSignatureNewestVersionOnly",
    defaultMessage: "Send document for signature (newest version only)",
  },
  tooltipSendForSignatureIsLocked: {
    id: "document.tooltip.sendForSignatureIsLocked",
    defaultMessage: "Send document for signature (must be unlocked first)",
  },
  tooltipSendForSignatureTooLarge: {
    id: "document.tooltip.sendForSignatureTooLarge",
    defaultMessage: "Send document for signature (max 10MB)",
  },
  dialogUnlockOthersDocumentTitle: {
    id: "document.dialog.unlockOthersDocument.title",
    defaultMessage: "Unlock document?",
  },
  dialogUnlockOthersDocumentMessage: {
    id: "document.dialog.unlockOthersDocument.message",
    defaultMessage:
      "This document was locked by <em>{lockedBy}</em>, are you sure?",
  },
  dialogDeleteDocumentTitle: {
    id: "document.dialog.deleteDocument.title",
    defaultMessage: "Delete document?",
  },
  dialogDeleteDocumentMessage: {
    id: "document.dialog.deleteDocument.message",
    defaultMessage: `This will delete <em>{title}</em>{versionsCount, plural,
      =0 {}
      one {}
      other { and all its versions}
    }.`,
  },
  dialogDeleteVersionTitle: {
    id: "document.dialog.deleteVersion.title",
    defaultMessage: "Delete version?",
  },
  dialogDeleteVersionMessage: {
    id: "document.dialog.deleteVersion.message",
    defaultMessage: `This will delete version {versionNumber} of <em>{title}</em>.`,
  },
  dialogEditTitleTitle: {
    id: "document.dialog.editTitle.title",
    defaultMessage: "Edit title",
  },
  addNewVersion: {
    id: "document.button.upload",
    defaultMessage: "Add version",
  },
  detailsFileType: {
    id: "document.details.filetype",
    defaultMessage: "Type",
  },
  detailsFileSize: {
    id: "document.details.filesize",
    defaultMessage: "Size",
  },
  detailsCreated: {
    id: "document.details.created",
    defaultMessage: "Created",
  },
  detailsModified: {
    id: "document.details.modified",
    defaultMessage: "Modified",
  },
  detailsFileName: {
    id: "document.details.filename",
    defaultMessage: "File name",
  },
  detailsChecksum: {
    id: "document.details.checksum",
    defaultMessage: "Checksum",
  },
  detailsUploaded: {
    id: "document.details.uploaded",
    defaultMessage: "Uploaded",
  },
  detailsSentForSignature: {
    id: "document.details.sentForSignature",
    defaultMessage: "Signing",
  },
  detailsSigned: {
    id: "document.details.signed",
    defaultMessage: "Signed",
  },
  lockedSince: {
    id: "document.details.lockedSince",
    defaultMessage: "Locked {formattedDate}",
  },
  lockExpires: {
    id: "document.details.lockExpires",
    defaultMessage: "Automatically unlocks {formattedDate}",
  },
  whoIsEditing: {
    id: "document.details.whoIsEditing",
    defaultMessage: "{user} is editing",
  },
  editingSince: {
    id: "document.details.editingSince",
    defaultMessage: "Started {formattedDate}",
  },
  editingLastSaved: {
    id: "document.details.editingLastSaved",
    defaultMessage: "Last saved {formattedDate}",
  },
  versionCreatedText: {
    id: "document.version.createdText",
    defaultMessage: "{formattedDate} by",
  },
  navigationText: {
    id: "document.section.navigation.text",
    defaultMessage: "Document {currentNumber, number} of {totalCount, number}",
  },
  showSelectedVersion: {
    id: "document.section.versions.showSelectedVersion",
    defaultMessage: "Only show current version",
  },
  showAllVersions: {
    id: "document.section.versions.showAllVersions",
    defaultMessage: "Show all versions",
  },
  versionSelectedVersion: {
    id: "document.section.versions.selectedVersion",
    defaultMessage: "Selected version",
  },
  versionSelectVersion: {
    id: "document.section.version.selectVersion",
    defaultMessage: "Select this version",
  },
  sectionPreviewTitle: {
    id: "document.section.preview.title",
    defaultMessage: "Preview",
  },
  sectionDetailsTitle: {
    id: "document.section.details.title",
    defaultMessage: "Document details",
  },
  editWithDefault: {
    id: "document.editWith.default",
    defaultMessage: "Edit",
  },
  editWithWord: {
    id: "document.editWith.word",
    defaultMessage: "Edit with Word",
  },
  editWithExcel: {
    id: "document.editWith.excel",
    defaultMessage: "Edit with Excel",
  },
  editWithPowerpoint: {
    id: "document.editWith.powerpoint",
    defaultMessage: "Edit with PowerPoint",
  },
  tabDocumentInfoTitle: {
    id: "entry.documentInfo",
    defaultMessage: "Document info",
  },
});
