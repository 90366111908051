import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import isValid from "date-fns/isValid";
import { ResultOrderKey } from "@web/models";
import { FormattedDate, FormattedMessage } from "react-intl";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";

interface IProps {
  title: string;
  order: ResultOrderKey;
  className?: string;
}

export const HeaderItem = observer((p: IProps) => {
  let headerMessage: JSX.Element = <></>;

  if (p.order.includes("relevance")) {
    // Show empty header when we do free-text search
    headerMessage = <></>;
  } else if (p.title === "null") {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.letter.untitled"
        defaultMessage="Untitled"
      />
    );
  } else if (p.order.includes("alphabetical")) {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.letter.title"
        defaultMessage="Starting with <strong>{firstEntryTitleLetter}</strong>"
        values={{
          firstEntryTitleLetter: p.title,
        }}
      />
    );
  } else if (p.title === "null") {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.letter.untitled"
        defaultMessage="Untitled"
      />
    );
  } else if (p.title === "today") {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.today"
        defaultMessage="Today"
      />
    );
  } else if (p.title === "thisWeek") {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.thisweek"
        defaultMessage="This week"
      />
    );
  } else if (p.title === "thisMonth") {
    headerMessage = (
      <FormattedMessage
        id="searchpage.sortgroup.thismonth"
        defaultMessage="This month"
      />
    );
  } else if (isValid(new Date(p.title))) {
    headerMessage = (
      <FormattedDate value={p.title} year="numeric" month="long" />
    );
  }
  return <_wrap className={p.className}>{headerMessage}</_wrap>;
});

const _wrap = styled.div`
  background: ${vars.body};
  box-sizing: content-box;
  color: ${vars.dark55};
  font-size: 1rem;
  height: 1rem;
  line-height: 1rem;
  margin: 0 -1rem;
  position: sticky;
  top: var(--search-safe-area-top, 0);
  transition: top var(--search-header-transition-duration, 0ms);
  z-index: ${ZIndex.resultsToolbar};

  ${media("desktop")} {
    padding: 0.5rem 1.5rem;

    :first-child {
      margin-top: calc(var(--search-toolbar-height) * -1);
    }
  }

  ${media("compact")} {
    padding: 0 1rem;

    :first-child {
      margin-top: calc(calc(var(--search-toolbar-height) / 2) * -1);
    }
  }
`;
