import React from "react";
import styled from "styled-components";
import { vars } from "@web/styles";
import { MessageDescriptor, useIntl } from "react-intl";

interface IProps {
  label: string | MessageDescriptor;
  onClick?: () => void;
  to?: string;
}

export function Link({ label, onClick, to }: IProps) {
  const intl = useIntl();
  const labelText =
    typeof label === "string" ? label : intl.formatMessage(label);

  return (
    <Wrap>
      {to ? (
        <a href={to}>{labelText}</a>
      ) : (
        <span onClick={onClick}>{labelText}</span>
      )}
    </Wrap>
  );
}

const Wrap = styled.span`
  background: ${vars.transparent};
  color: ${vars.primaryLight10};
  cursor: pointer;
`;
