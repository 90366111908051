import styled from "styled-components";
import { vars } from "@web/styles";

export const Separator = styled.hr`
  border: none;
  border-top: 2px solid ${vars.dark05};
  box-sizing: border-box;
  margin: 0;
  width: 100%;
`;
