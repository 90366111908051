import React from "react";
import { AutoCompleteSuggestionModel } from "@web/models";
import { ProfileImage } from "@web/elements";
import { SearchSuggestionButton } from "./SearchSuggestionButton";

interface Props {
  data: AutoCompleteSuggestionModel;
  onClick: () => void;
}

export const SuggestedUser = (p: Props) => (
  <SearchSuggestionButton onClick={p.onClick}>
    <ProfileImage name={p.data.value} size="small" /> {p.data.value}
  </SearchSuggestionButton>
);
