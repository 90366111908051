import sortedIndexBy from "lodash/sortedIndexBy";
import { action, computed, observable } from "mobx";
import { ISectionNode, Permission } from "@web/api/Integration/types";
import { SectionStore } from "@web/stores";
import { ClassModel, PermissionModel } from "@web/models";

export class SectionModel implements PermissionModel {
  @observable readonly id: number;
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable title: string;
  @observable classifications: ClassModel[] = [];

  constructor(private store: SectionStore, node: ISectionNode) {
    this.store = store; // Needs to be set for mocking to work
    const { classificationStore } = this.store.rootStore;
    this.uuid = node.id;
    this.id = node.internalIdentifier;
    this.permissions = new Set(node.effectivePermissions);
    this.title = node.title;
    this.classifications = (node.classifications || []).map(
      classificationStore.classificationFromJson
    );
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canUpload() {
    return this.permissions.has("CreateChildren");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get isReadOnly() {
    return !this.canUpdate && !this.canDelete;
  }

  @action.bound
  updateState(fields: Partial<ClassModel>) {
    Object.assign(this, fields);
  }

  @action.bound
  updateFromJson(json: Partial<ISectionNode>) {
    if (json.title) {
      this.title = json.title;
    }
  }

  @action.bound
  removeClassification(classificationId: UUID) {
    const index = this.classifications.findIndex(
      (cl) => cl.uuid === classificationId
    );
    if (index === -1) return;
    this.classifications.splice(index, 1);
  }

  @action.bound
  addClassification(classification: ClassModel) {
    if (this.classifications.some((cl) => cl.id === classification.id)) {
      return;
    }
    // We keep classifications sorted alphabetically
    const insertAt = sortedIndexBy(this.classifications, classification, (cl) =>
      cl.title.toLowerCase()
    );

    this.classifications.splice(insertAt, 0, classification);
    if (classification.sectionCount) {
      classification.sectionCount++;
    }
  }

  findLinkableClassifications = (titleQuery: string) => {
    const { classificationStore } = this.store.rootStore;
    return classificationStore.findLinkableClassifications(
      this.uuid,
      titleQuery
    );
  };

  updateTitle(newTitle: string) {
    return this.store.renameSection(this, newTitle);
  }

  createClassification = (title: string) => {
    const { classificationStore } = this.store.rootStore;
    return classificationStore.createClassification(this, title);
  };

  linkClassification(classificationId: UUID) {
    const { classificationStore } = this.store.rootStore;
    return classificationStore.linkClassification(this, classificationId);
  }

  delete() {
    return this.store.deleteSection(this);
  }
}
