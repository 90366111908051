import React, { FC, useState, useRef, RefObject, createRef } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  AttributeModel,
  ChangelogAddOrDelModel,
  SelectedTagsMap,
  EntryModel,
  SingleEventModel,
  canUpdate,
  isEntryAttributeListValuesChanged,
  TagModel,
} from "@web/models";
import { PopoverBox } from "@web/elements";
import {
  SelectListAttribute,
  SetAttribute,
  AttributeLabel,
} from "@web/components/Attributes";
import { vars } from "@web/styles";

interface Props {
  entry: EntryModel;
}

export const AttributeList: FC<Props> = observer((p) => {
  const selectedTags: SelectedTagsMap = p.entry.tags;
  const attributeValues = p.entry.attributeValues;
  const canUpdateEntry = canUpdate(p.entry);

  const [selected, setSelected] = useState<
    { attribute: AttributeModel; tag: TagModel } | undefined
  >(undefined);
  const buttonRefs = useRef<Record<string, RefObject<HTMLButtonElement>>>({});

  const tags = selectedTags.tagsWithAttributes;
  if (tags.length === 0) {
    return null;
  }

  const createOrReturnRef = (id: string) => {
    if (!buttonRefs.current[id]) {
      buttonRefs.current[id] = createRef();
    }
    return buttonRefs.current[id];
  };

  // The block below is related to picking out comments from the change log on an entry.
  // this is a hack because the API does not support it.
  // It should be replaced if this is useful. ED 06-2021
  const comments: Map<number, ChangelogAddOrDelModel> = new Map();
  if (p.entry) {
    const { changelog } = p.entry;
    if (changelog.status === "SUCCESS") {
      const latestEvents: Map<string, SingleEventModel> = new Map();
      changelog.result.forEach((logItem) => {
        if (!logItem.isCollapsed) {
          const event: SingleEventModel = logItem;
          if (event.fields?.definitionTitle) {
            const { definitionTitle } = event.fields;
            if (!latestEvents.has(definitionTitle)) {
              latestEvents.set(definitionTitle, logItem);
            }
          } else if (isEntryAttributeListValuesChanged(event)) {
            const { definitionTitle } =
              event.changed.AttributeListValue[0].fields;
            if (!latestEvents.has(definitionTitle)) {
              latestEvents.set(definitionTitle, logItem);
            }
          }
        }
      });
      tags.map((tag) =>
        tag.attributes.map((att) => {
          if (
            latestEvents.get(att.name) &&
            latestEvents.get(att.name)?.relatedComment?.fields
          ) {
            let comment = latestEvents.get(att.name)?.relatedComment;
            if (comment) {
              comment = comment as ChangelogAddOrDelModel;
              comments.set(att?.id, comment);
            }
          }
        })
      );
    }
  }

  const showTitle = tags.length > 1;
  const required = p.entry.requiredAttributes;

  return (
    <>
      <_wrap>
        {tags.map((tag) => (
          <_item key={tag.id}>
            {showTitle && <h3>{tag.title}</h3>}
            {tag.attributes.map((attribute) => (
              <AttributeLabel
                key={attribute.id}
                ref={createOrReturnRef(attribute.uuid)}
                canUpdate={canUpdateEntry}
                attribute={attribute}
                isRequired={required?.includes(attribute.uuid)}
                comment={comments.get(attribute.id)}
                attributeValues={attributeValues}
                onClick={() => setSelected({ attribute, tag })}
              />
            ))}
          </_item>
        ))}
      </_wrap>
      {selected && (
        <PopoverBox
          alignY="top"
          margin={-5}
          triggerRef={buttonRefs.current[selected.attribute.uuid]}
          onClickOutside={() => setSelected(undefined)}
        >
          {selected.attribute.isListAttribute ? (
            <SelectListAttribute
              attribute={selected.attribute}
              attributeValues={attributeValues}
              onDone={() => setSelected(undefined)}
            />
          ) : (
            <SetAttribute
              tag={selected.tag}
              attribute={selected.attribute}
              attributeValues={attributeValues}
              onDone={() => setSelected(undefined)}
            />
          )}
        </PopoverBox>
      )}
    </>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
`;

const _item = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  h3 {
    all: unset;
    font-weight: 600;
    font-size: 0.75rem;
    color: ${vars.dark75};

    ::after {
      content: ":";
    }
  }
`;
