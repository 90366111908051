import React from "react";

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5159 15.8485C17.7942 15.1183 16.6283 15.1183 15.9065 15.8485C15.1848 16.5786 15.1848 17.7581 15.9065 18.4882L17.8489 20.4532C18.5707 21.1834 19.7366 21.1834 20.4583 20.4532C21.1801 19.7231 21.1801 18.5436 20.4583 17.8135L18.5159 15.8485Z"
      fill="var(--svg-fill, currentColor)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5125 17.2C14.6616 17.2 18.0251 13.7974 18.0251 9.6C18.0251 5.40264 14.6616 2 10.5125 2C6.36348 2 3 5.40264 3 9.6C3 13.7974 6.36348 17.2 10.5125 17.2ZM10.5137 14.3498C13.1069 14.3498 15.209 12.2231 15.209 9.59976C15.209 6.97641 13.1069 4.84976 10.5137 4.84976C7.92053 4.84976 5.81836 6.97641 5.81836 9.59976C5.81836 12.2231 7.92053 14.3498 10.5137 14.3498Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
