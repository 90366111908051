import React from "react";

export const Pdf = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1C0 0.447716 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V23C24 23.5523 23.5523 24 23 24H1C0.447716 24 0 23.5523 0 23V1Z"
      fill="#FAE7E7"
    />
    <path
      d="M4.25098 13.5879V16H2.53711V8.89062H5.37402C5.91764 8.89062 6.39779 8.99154 6.81445 9.19336C7.23438 9.39193 7.55827 9.67676 7.78613 10.0479C8.01725 10.4157 8.13281 10.834 8.13281 11.3027C8.13281 11.9961 7.88379 12.5511 7.38574 12.9678C6.89095 13.3812 6.21061 13.5879 5.34473 13.5879H4.25098ZM4.25098 12.2646H5.37402C5.70605 12.2646 5.95833 12.1816 6.13086 12.0156C6.30664 11.8496 6.39453 11.6152 6.39453 11.3125C6.39453 10.9805 6.30501 10.7152 6.12598 10.5166C5.94694 10.318 5.7028 10.2171 5.39355 10.2139H4.25098V12.2646Z"
      fill="#D10A10"
    />
    <path
      d="M9.05078 16V8.89062H11.3408C11.9691 8.89062 12.5339 9.03385 13.0352 9.32031C13.5365 9.60352 13.9271 10.0055 14.207 10.5264C14.4902 11.0439 14.6335 11.625 14.6367 12.2695V12.5967C14.6367 13.2477 14.4984 13.832 14.2217 14.3496C13.9482 14.8639 13.5609 15.2676 13.0596 15.5605C12.5615 15.8503 12.0049 15.9967 11.3896 16H9.05078ZM10.7646 10.2139V14.6816H11.3604C11.8519 14.6816 12.2295 14.5075 12.4932 14.1592C12.7568 13.8076 12.8887 13.2868 12.8887 12.5967V12.2891C12.8887 11.6022 12.7568 11.0846 12.4932 10.7363C12.2295 10.388 11.8454 10.2139 11.3408 10.2139H10.7646Z"
      fill="#D10A10"
    />
    <path
      d="M19.9932 13.168H17.2295V16H15.5156V8.89062H20.2715V10.2139H17.2295V11.8496H19.9932V13.168Z"
      fill="#D10A10"
    />
  </svg>
);

export const Document = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#EBF2F9" />
    <rect x="4" y="7" width="16" height="2" fill="#3679C8" />
    <rect x="4" y="11" width="16" height="2" fill="#3679C8" />
    <rect x="4" y="15" width="12" height="2" fill="#3679C8" />
  </svg>
);

export const Image = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#1BAF73" fillOpacity="0.13" />
    <path
      d="M7.5 11.5L4 16.5H20L14 9L10 14L7.5 11.5Z"
      fill="#1BAF73"
      stroke="#1BAF73"
    />
  </svg>
);

export const Video = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#F1E0FF" />
    <path
      d="M4 8C4 6.89543 4.89543 6 6 6H20V16C20 17.1046 19.1046 18 18 18H6C4.89543 18 4 17.1046 4 16V8Z"
      fill="#9013FE"
    />
    <path d="M7.5 5.5L9.5 9.5" stroke="#F1E0FF" strokeWidth="2" />
    <path d="M11.5 5.5L13.5 9.5" stroke="#F1E0FF" strokeWidth="2" />
    <path d="M15.5 5.5L17.5 9.5" stroke="#F1E0FF" strokeWidth="2" />
    <rect x="4" y="9" width="16" height="3" fill="#9013FE" />
  </svg>
);

export const Email = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#FFF0E3" />
    <rect x="3.8999" y="7" width="16.2" height="9.9" rx="1" fill="#FF8A29" />
    <path
      d="M3 6.5C2.78176 6.5 2.58874 6.64154 2.52313 6.84968C2.45752 7.05782 2.53448 7.28447 2.71327 7.40962L11.7133 13.7096C11.8854 13.8301 12.1146 13.8301 12.2867 13.7096L21.2867 7.40962C21.4655 7.28447 21.5425 7.05782 21.4769 6.84968C21.4113 6.64154 21.2182 6.5 21 6.5H3Z"
      stroke="#FFF0E3"
      strokeLinejoin="round"
    />
  </svg>
);

export const Sheets = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#EBF2F9" />
    <rect x="6" y="5" width="2" height="12" fill="#3679C8" />
    <rect x="10" y="5" width="2" height="12" fill="#3679C8" />
    <rect
      x="20"
      y="7"
      width="2"
      height="16"
      transform="rotate(90 20 7)"
      fill="#3679C8"
    />
    <rect
      x="20"
      y="11"
      width="2"
      height="16"
      transform="rotate(90 20 11)"
      fill="#3679C8"
    />
  </svg>
);

export const Presentations = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="1" fill="#728894" fillOpacity="0.18" />
    <rect x="3" y="8" width="18" height="9" fill="#728894" />
  </svg>
);
