import React, { ReactElement } from "react";
import isEmpty from "lodash/isEmpty";
import { UUID } from "@web/api/Integration/types";

export interface BackendEntity {
  uuid: UUID;
  title: string;
}

export type WritableFields<T> = Partial<
  Omit<T, "uuid" | "id" | "permissions" | "createdBy" | "createdDate">
>;

export type SystemAccountUUID = "System" & {
  // This field is never used or provided anywhere.
  // It is only present to force TypeScript into making this type unique, instead of an alias for any kind of "string".
  __systemUuid: never;
};

/**
 * Certain actions & changes are done by the system/backend/documaster/big brother/call-it-what-you-will.
 * Whenever that is the case, the author of those changes will be represented by an unique account ID that no one else has.
 */
export function isSystemAccount(
  id: UUID | SystemAccountUUID | undefined
): id is SystemAccountUUID {
  return id === "System";
}

/**
 * The following type helps us model state related to fetching data from the backend
 * and avoid common pitfalls it's easy to fall into otherwise.
 *
 * It is inspired by the great "Slaying a UI antipattern" article from the elm community
 * and slightly simplified to fit our current needs.
 *
 * Accomponied with {@link transformResult} below to enforce our lazy selves to explicitly
 * handle the different stages HTTP requests go through.
 *
 * - http://blog.jenkster.com/2016/06/how-elm-slays-a-ui-antipattern.html
 * - https://blogg.kantega.no/slaying_a_ui_antipattern_with_typescript_and_react
 */
export type RemoteData<SUCCESS_DATA_TYPE> =
  | { status: "NOT_REQUESTED" }
  | { status: "LOADING" }
  | { status: "ERROR"; error: Error }
  | { status: "SUCCESS"; result: SUCCESS_DATA_TYPE };

/**
 * Helper function meant to:
 *
 * 1. Ease the pain of work with {@link RemoteData} objects in React.js components
 * 2. Enforce handling of all states
 *
 * Although the last point might seem a bit verbose and weird from a glance, it's very deliberate
 * to up our game for our end-users' sake.
 *
 * There is not escaping HTTP requests go through these difference stages. Not handling one or more
 * of them is sooooo tempting. One thing is totally forgetting to handle one or more of them, like
 * loading or errors happening, usually things work and pretty fast too, so it works from a glance.
 *
 * But in the long run, our end-users are the ones who gets a less than ideal user experience, cause
 * they *will* end up seeing errors and slow networks more than once.
 *
 * Hence enforcing ourselves to think about all the stages is better for everyone. As an added bonus
 * more question will be answered by the code explictly rather than us having to dive in and
 * read between the lines for educated guesses; what do we render when data has not been loaded yet? ..etc.
 */
export function renderResult<SUCCESS_DATA_TYPE>(
  data: RemoteData<SUCCESS_DATA_TYPE>,
  renderFunctions: {
    NOT_REQUESTED: () => ReactElement<any> | null;
    LOADING: () => ReactElement<any> | null;
    SUCCESS: (
      result: SUCCESS_DATA_TYPE
    ) => ReactElement<any> | JSX.Element[] | null;
    SUCCESS_EMPTY?: () => ReactElement<any> | null;
    ERROR: (error: Error) => ReactElement<any> | null;
  }
): ReactElement<any> | null {
  switch (data.status) {
    case "NOT_REQUESTED":
      return renderFunctions.NOT_REQUESTED();
    case "LOADING":
      return renderFunctions.LOADING();
    case "SUCCESS":
      if (isEmpty(data.result) && renderFunctions.SUCCESS_EMPTY) {
        return renderFunctions.SUCCESS_EMPTY();
      }

      // For convience, we wrap what is provided from the SUCCESS function in a fragment
      // allowing SUCCESS functions to return an array of JSX elements directly, without
      // wrapping them in a fragment themselves all the time
      return React.createElement(
        React.Fragment,
        null,
        renderFunctions.SUCCESS(data.result)
      );
    case "ERROR":
      return renderFunctions.ERROR(data.error);
  }
}
