import styled from "styled-components";
import { vars } from "@web/styles";

export const DescriptionList = styled.dl`
  display: grid;
  grid-template-columns: minmax(4rem, auto) 1fr;
  grid-gap: 0.5rem 1rem;
  margin: 0;
  word-break: break-all;

  > dt {
    color: ${vars.dark55};
  }

  > dd {
    margin: 0;
  }
`;
