import React from "react";

export const FolderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.51353 2.75C3.96707 2.75 3.48396 3.10496 3.32065 3.62644L1.80712 8.45956C1.76926 8.58046 1.75 8.70642 1.75 8.83311V11.459C1.75 12.1494 2.30964 12.709 3 12.709H12.93C13.6203 12.709 14.18 12.1494 14.18 11.459V8.83311C14.18 8.70642 14.1607 8.58046 14.1228 8.45955L12.6093 3.62644C12.446 3.10496 11.9629 2.75 11.4164 2.75H4.51353ZM1.8892 3.17817C2.24847 2.03091 3.31132 1.25 4.51353 1.25H11.4164C12.6186 1.25 13.6815 2.03091 14.0408 3.17817L15.5543 8.01128C15.6376 8.27728 15.68 8.55438 15.68 8.83311V11.459C15.68 12.9778 14.4487 14.209 12.93 14.209H3C1.48122 14.209 0.25 12.9778 0.25 11.459V8.83311C0.25 8.55438 0.292375 8.27728 0.375672 8.01128L1.8892 3.17817Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 8.60748H4.78563C5.54243 8.60748 6.15594 9.22099 6.15594 9.97779C6.15594 10.1823 6.32173 10.3481 6.52624 10.3481H9.44157C9.64608 10.3481 9.81187 10.1823 9.81187 9.97779C9.81187 9.22099 10.4254 8.60748 11.1822 8.60748H15.43V9.60748H11.1822C10.9777 9.60748 10.8119 9.77327 10.8119 9.97779C10.8119 10.7346 10.1984 11.3481 9.44157 11.3481H6.52624C5.76944 11.3481 5.15594 10.7346 5.15594 9.97779C5.15594 9.77327 4.99014 9.60748 4.78563 9.60748H0.5V8.60748Z"
      fill="currentColor"
    />
  </svg>
);
