import React from "react";

export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.625 8.71875H16.6875V6.84375C16.6875 4.26562 14.5781 2.15625 12 2.15625C9.42188 2.15625 7.3125 4.26562 7.3125 6.84375V8.71875H6.375C5.34366 8.71875 4.5 9.56241 4.5 10.5938V19.9688C4.5 21.0001 5.34366 21.8438 6.375 21.8438H17.625C18.6563 21.8438 19.5 21.0001 19.5 19.9688V10.5938C19.5 9.56241 18.6563 8.71875 17.625 8.71875ZM12 17.25C10.9687 17.25 10.125 16.4063 10.125 15.375C10.125 14.3437 10.9687 13.5 12 13.5C13.0313 13.5 13.875 14.3437 13.875 15.375C13.875 16.4063 13.0313 17.25 12 17.25ZM14.9063 8.71875H9.09366V6.84375C9.09366 5.24981 10.4063 3.93741 12 3.93741C13.5939 3.93741 14.9063 5.24981 14.9063 6.84375V8.71875Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
