import { DMFormattedDate, PopoverBox } from "@web/elements";
import { Button } from "@web/elements/Button";
import { ChangelogAddOrDelModel } from "@web/models";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import React, { Ref, RefObject } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import styled from "styled-components";

interface Events {
  onCancel: (e?: React.MouseEvent) => void;
  closeButtonLabel?: MessageDescriptor;
}

interface Props extends Events {
  comment: ChangelogAddOrDelModel | undefined;
}

export const CommentPopover = React.forwardRef(
  (props: Props, ref: Ref<HTMLButtonElement>) => {
    const { comment } = props;
    if (!comment) return null;
    return (
      <PopoverBox
        alignY="top"
        margin={-5}
        triggerRef={ref as RefObject<HTMLElement>}
        onClickOutside={(e) => {
          e.stopPropagation();
          props.onCancel();
        }}
      >
        <_commentWrap>
          <_comment>
            <_date>
              <DMFormattedDate value={comment.eventTime} />
            </_date>
            <div>
              <_user>{comment.userName}</_user>{" "}
              <span>
                <FormattedMessage {...texts.commentedText} />
              </span>
            </div>
            <p>{comment.fields.text}</p>
          </_comment>
          <Button
            text={props.closeButtonLabel ?? commonTexts.close}
            variant="primary"
            onClick={props.onCancel}
          />
        </_commentWrap>
      </PopoverBox>
    );
  }
);

CommentPopover.displayName = "CommentPopover";

const texts = defineMessages({
  commentedText: {
    id: "entry.attributes.values.comment.commentedText",
    defaultMessage: "commented:",
  },
});

const _commentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 0 0 0;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 0.875rem;
  border-radius: 3px;
  min-width: 260px;
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  padding: 0 10px 10px 10px;
`;

const _date = styled.div`
  color: ${vars.dark55};
  text-transform: capitalize;
  margin-bottom: 5px;
`;
const _comment = styled.div`
  padding: 15px 10px 0 10px;
`;
const _user = styled.span`
  font-weight: 600;
`;
