import React, { FC, useState, useRef, useEffect } from "react";
import throttle from "lodash/throttle";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { SectionModel } from "@web/models";
import { ScrollBox } from "@web/elements";
import { Overlay } from "@web/components/Overlay";
import { Section } from "@web/components/Settings/Section/Section";
import { AddValueBox } from "@web/components/AddValueBox";
import { Button } from "@web/elements/Button";
import { Loading } from "@web/elements/Loading";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";
import { useStores } from "@web/stores/context";
import { useConfig } from "@config/context";

export const SectionsContainer: FC = observer(() => {
  const intl = useIntl();
  const config = useConfig();
  const { sectionStore, tagStore } = useStores();

  const [hoveredClass, setHoveredClass] = useState<UUID>();
  const [editSection, setEditSection] = useState<SectionModel>();
  const [addNewSection, setAddNewSection] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sectionStore.loadSectionPage(1);
  }, []);

  const handleSectionScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = scrollRef.current;
    if (!target) {
      return;
    }

    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;

    if (bottom) {
      sectionStore.loadNextPage();
    }
  };

  const throttledScroll = throttle(handleSectionScroll, 300, {
    leading: false,
    trailing: true,
  });

  const handleRenameSectionClick = (section: SectionModel) => {
    setEditSection(section);
  };

  const handleSaveSection = (doSave: boolean, newTitle: string) => {
    if (doSave) {
      if (addNewSection) {
        sectionStore.createSection(newTitle);
      }
      if (editSection) {
        editSection.updateTitle(newTitle);
      }
    }
    setAddNewSection(false);
    setEditSection(undefined);
  };

  const { sections, loadingStatus } = sectionStore;

  if (!sections) {
    return <b>{intl.formatMessage(commonTexts.loading)}</b>;
  }

  return (
    <_wrap>
      {config.canCreateSection && (
        <_addLink
          icon="AddIcon"
          text={texts.addSection}
          onClick={() => setAddNewSection(true)}
        />
      )}

      <_scrollContainer ref={scrollRef} onScroll={throttledScroll}>
        {sections.map((section) => (
          <Section
            key={section.id}
            section={section}
            onRenameClick={handleRenameSectionClick}
            highlightedClassification={hoveredClass}
            onClassificationHover={setHoveredClass}
            tagSearch={tagStore.search}
          />
        ))}
        {loadingStatus.pageLoading && <Loading text={commonTexts.loading} />}
      </_scrollContainer>

      {(addNewSection || editSection) && (
        <Overlay
          onClickOutside={() => handleSaveSection(false, "")}
          onEscape={() => handleSaveSection(false, "")}
        >
          <BoxOverlay size="small">
            <AddValueBox
              type="section"
              currentEntity={editSection}
              getSimilarValues={sectionStore.getSimilarSections}
              onValueSave={handleSaveSection}
            />
          </BoxOverlay>
        </Overlay>
      )}
    </_wrap>
  );
});

const _scrollContainer = styled(ScrollBox)`
  display: flex;
  flex-direction: column;
  min-height: 0px;
  height: 0px; // Need this for proper scrolling in Safari
  flex: 1 1 auto;
`;

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-top: 15px;
  h2 {
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid ${vars.dark05};
  }
  padding-left: 2rem;
  padding-right: 2rem;
`;

const _addLink = styled(Button).attrs({ variant: "blank" })`
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  flex-shrink: 0;
  svg {
    margin-right: 10px;
  }
`;

const BoxOverlay = styled.div<{ size?: "large" | "small" }>`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: ${(p) => (p.size === "small" ? "200px" : "500px")};
  background: ${vars.content};
  border-radius: 3px;
`;

const texts = defineMessages({
  addSection: {
    id: "settingspage.addsection",
    defaultMessage: "Add section",
  },
});
