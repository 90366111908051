import React from "react";

export const PlanetIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9172 11C10.9711 10.3663 11 9.69497 11 9C11 8.30503 10.9711 7.63371 10.9172 7H7.08282C7.02894 7.63371 7 8.30503 7 9C7 9.69497 7.02894 10.3663 7.08282 11H10.9172ZM10.6415 13H7.35848C7.7199 14.8135 8.32036 16 9 16C9.67964 16 10.2801 14.8135 10.6415 13ZM5.07622 11C5.02598 10.3546 5 9.68396 5 9C5 8.31604 5.02598 7.64535 5.07622 7H2.28988C2.10128 7.63371 2 8.30503 2 9C2 9.69497 2.10128 10.3663 2.28988 11H5.07622ZM3.25469 13H5.32395C5.41794 13.5363 5.53133 14.0393 5.66274 14.4992C5.73836 14.7639 5.82411 15.0293 5.92196 15.2887C4.85011 14.7631 3.93234 13.9715 3.25469 13ZM12.6761 13H14.7453C14.0677 13.9715 13.1499 14.7631 12.078 15.2887C12.1759 15.0293 12.2616 14.7639 12.3373 14.4992C12.4687 14.0393 12.5821 13.5363 12.6761 13ZM15.7101 11H12.9238C12.974 10.3546 13 9.68396 13 9C13 8.31604 12.974 7.64535 12.9238 7H15.7101C15.8987 7.63371 16 8.30503 16 9C16 9.69497 15.8987 10.3663 15.7101 11ZM12.6761 5H14.7453C14.0677 4.02849 13.1499 3.23691 12.078 2.7113C12.1759 2.97072 12.2616 3.23614 12.3373 3.50081C12.4687 3.96072 12.5821 4.4637 12.6761 5ZM9 2C8.32036 2 7.7199 3.18652 7.35848 5H10.6415C10.2801 3.18652 9.67964 2 9 2ZM5.66274 3.50081C5.53133 3.96072 5.41794 4.4637 5.32395 5H3.25469C3.93234 4.02849 4.85011 3.23691 5.92196 2.7113C5.82411 2.97072 5.73836 3.23614 5.66274 3.50081ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

PlanetIcon.displayName = "PlanetIcon";
