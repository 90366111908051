import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { DocumentModel, UploadRequest } from "@web/models";
import { EditButton } from "@web/components/Document/EditButton";
import { Tooltip } from "@web/elements/Tooltip";
import { texts } from "@web/components/Document/texts";
import { EditSession } from "@web/components/Document/EditSession";
import { LockedBy } from "@web/components/Document/LockedBy";
import { Details } from "@web/components/Document/Details";
import { VersionList } from "@web/components/Document/VersionList";
import { hGap } from "@web/styles/utils";
import { vars } from "@web/styles";
import { VersionUploadButton } from "@web/components";
import { useStores } from "@web/stores/context";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";

interface DocumentAsideProps {
  document: DocumentModel;
}

export const DocumentSidebar = observer((p: DocumentAsideProps) => {
  const { uploadStore } = useStores();
  const getConfirmation = useDuplicateUploadConfirmDialog();

  const currentVersion = p.document.currentVersion;
  const editWith = currentVersion.editWith ?? [];
  const editSession = p.document.editSession;
  const disableEditButton = p.document.isLocked || editSession !== undefined;

  async function handleUploadDocument(request: UploadRequest) {
    return uploadStore.addRequest(request);
  }

  return (
    <div>
      {p.document.canAddVersion && (
        <_editButtons>
          <EditButton
            applications={editWith}
            disabled={disableEditButton}
            onClick={p.document.startEditSession}
          />
          <Tooltip
            text={texts.tooltipUploadLockedDocument}
            disabled={!p.document.isLockedForCurrentUser}
          >
            <VersionUploadButton
              disabled={p.document.isLockedForCurrentUser}
              document={p.document}
              onUpload={handleUploadDocument}
              getConfirmation={getConfirmation}
            />
          </Tooltip>
        </_editButtons>
      )}

      {editSession && (
        <_editWrap>
          <EditSession editSession={editSession} />
        </_editWrap>
      )}

      {!editSession && p.document.lock && p.document.isLocked && (
        <_lockWrap>
          <LockedBy lock={p.document.lock} />
        </_lockWrap>
      )}

      <_details>
        <Details currentVersion={currentVersion} document={p.document} />
      </_details>

      {p.document.versions.length > 1 && (
        <_versions>
          <VersionList document={p.document} />
        </_versions>
      )}
    </div>
  );
});

const _editButtons = styled.div`
  display: flex;
  padding: 0.75rem;

  ${hGap("1rem")}
`;

const _lockWrap = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
  display: grid;
  grid-template-columns: minmax(4rem, auto) 1fr;
  grid-gap: 0.5rem 1rem;
  align-items: start;
`;

const _editWrap = styled.div`
  padding: 0 1rem 1rem 1rem;
`;

const _details = styled.div`
  border-top: 2px solid ${vars.dark05};
  padding: 1rem;
`;

const _versions = styled.div`
  border-top: 2px solid ${vars.dark05};
  overflow: hidden;
  flex: 1;
`;
