import React from "react";

export const UnknownIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <path
      d="M41.962 16.0816L43.2347 14.8002C43.7422 14.2893 44.5557 14.2893 45.0552 14.8002L53.497 23.2996C54.0044 23.8105 54.0044 24.6296 53.497 25.1324L52.4095 26.2273C49.6788 28.9766 45.2324 29.3091 42.3245 26.7544C39.1346 23.9484 39.0138 19.0418 41.962 16.0816Z"
      fill="currentColor"
    />
    <path
      d="M39.8934 28.2236C36.5159 24.7967 36.2741 19.5395 38.6359 15.5965C39.047 14.9031 38.5876 14.0242 37.7734 14.0242L19.193 14C18.532 14 18 14.5322 18 15.1934V55.1066C18 55.7678 18.532 56.3 19.193 56.3H52.807C53.468 56.3 54 55.7678 54 55.1066L53.9919 30.8523C53.9919 30.0782 53.1617 29.5783 52.4846 29.9572C48.5347 32.1343 43.2145 31.5941 39.8934 28.2236Z"
      fill="currentColor"
    />
  </svg>
);
