import React, { useEffect, useRef } from "react";
import { useStores } from "@web/stores/context";
import styled, { css } from "styled-components";
import throttle from "lodash/throttle";
import { ColumnWidths } from "./useColumnWidths";
import { defineMessages, useIntl } from "react-intl";
import { vars, ZIndex } from "@web/styles";
import { observer } from "mobx-react";

interface ResizeInfo {
  columnIndex: number;
  startMouseX: number;
  startWidth: number;
}

interface Props {
  columnWidths: ColumnWidths;
}

const MIN_WIDTH = 40;

export const ColumnTitles = observer(({ columnWidths }: Props) => {
  const intl = useIntl();
  const { filterStore } = useStores();

  const resizeInfo = useRef<ResizeInfo | undefined>(undefined);

  useEffect(() => {
    const handleMouseMove = throttle((event: MouseEvent) => {
      if (!resizeInfo.current) {
        return;
      }
      const { columnIndex, startMouseX, startWidth } = resizeInfo.current;
      const mouseX = Math.min(event.clientX, window.innerWidth - 50);
      const width = Math.max(MIN_WIDTH, startWidth + mouseX - startMouseX);
      columnWidths.setColumn(columnIndex, width);
    }, 10);

    const handleMouseUp = () => {
      resizeInfo.current = undefined;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const startResize = (event: React.MouseEvent, columnIndex: number) => {
    event.preventDefault();
    resizeInfo.current = {
      startMouseX: event.clientX,
      startWidth: columnWidths.widths[columnIndex],
      columnIndex,
    };
  };

  const hasWidths = columnWidths.widths.length > 0;

  const columns = [
    intl.formatMessage(texts.columnName),
    ...filterStore.classFilters.map((x) => x.title),
  ];

  return (
    <_wrap>
      {hasWidths &&
        columns.map((title, index) => (
          <_column index={index} key={`column-title-${index}`}>
            <span>{title}</span>
            <_resizer onMouseDown={(event) => startResize(event, index)} />
          </_column>
        ))}
    </_wrap>
  );
});

const _wrap = styled.div`
  background: ${vars.body};
  color: ${vars.dark55};
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  user-select: none;
  margin: 0 -1rem;
  padding: 0.5rem 1.5rem;
  overflow: hidden;
  position: sticky;
  top: calc(
    var(--search-safe-area-top) + calc(var(--search-toolbar-height) / 2)
  );
  transition: top var(--search-header-transition-duration);
  z-index: ${ZIndex.resultsToolbar + 1};
`;

const _column = styled.div<{ index: number }>`
  margin-left: 1rem;
  flex: 0 0 var(--ResultsList-column-${(p) => p.index}, auto);
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  :first-child {
    /* move everything to the right
       to align with data in rows */
    margin-left: 108px;

    /* move title column left to align with left edge */
    overflow: visible;
    > span {
      margin-left: -108px;
    }
  }
`;

const _resizer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(
    90deg,
    transparent 45%,
    rgba(0, 0, 0, 0.3) 45%,
    rgba(0, 0, 0, 0.3) 55%,
    ${vars.body} 55%
  );
  cursor: col-resize;
`;

const texts = defineMessages({
  columnName: {
    id: "searchpage.column.name",
    defaultMessage: "Name",
  },
});
