import React from "react";
import styled from "styled-components";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { vars } from "@web/styles";
import { WarningIcon } from "@web/elements/Icons";

type ErrorType = "notFound" | "noAccess";

interface IProps {
  type: ErrorType;
}

export const ErrorBanner = (p: IProps) => (
  <_wrap>
    <WarningIcon />
    <h1>
      <FormattedMessage {...texts[p.type]} />
    </h1>
  </_wrap>
);

const _wrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${vars.dark05};
  border-radius: 5px;
`;

const texts: Record<ErrorType, MessageDescriptor> = defineMessages({
  noAccess: {
    id: "error.noaccess",
    defaultMessage: "You do not have access to this content.",
  },
  notFound: {
    id: "error.notfound",
    defaultMessage: "The content you requested could not be found.",
  },
});
