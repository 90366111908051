import { action, computed, observable } from "mobx";
import { MessageDescriptor, defineMessage } from "react-intl";
import {
  IAttributeType,
  Permission,
  IAttributeNode,
  IAttributeValueNode,
} from "@web/api/Integration/types";
import { PermissionModel, TagModel, WritableFields } from ".";
import { AttributeStore } from "@web/stores";
import { ClassModel, AttributeValueModel } from "@web/models";
import {
  INITIAL_SEARCH_LOADING_STATUS,
  QueryLoadingModel,
} from "./DataLoadingModel";

/*
Exclude `DateTime` attribute type due to the following:
1) No current use case for setting date AND time.
2) The BE does not currently have a consistant way of handling timezones.
*/
export type AttributeType = Exclude<IAttributeType, "DateTime">;

type OptionUUID = UUID;

export class AttributeModel implements PermissionModel {
  @observable readonly id: number;
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable readonly type: AttributeType;
  @observable name: string;
  @observable options: Map<OptionUUID, AttributeValueModel> = new Map();
  @observable optionsLoadingStatus: QueryLoadingModel = {
    ...INITIAL_SEARCH_LOADING_STATUS,
  };

  constructor(private store: AttributeStore, node: IAttributeNode) {
    this.uuid = node.id;
    this.id = node.internalIdentifier;
    this.permissions = new Set(node.effectivePermissions);
    this.name = node.name;
    const supportedType =
      node.attributeType === "DateTime" ? "Date" : node.attributeType;
    this.type = supportedType;

    if (node.attributeListValues) {
      this.updateOptionsFromJson(node.attributeListValues);
    }
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get isListAttribute() {
    return this.type.endsWith("List");
  }

  @computed
  get hasNoOptionsAdded() {
    const { hasMore, pageLoading, itemCount } = this.optionsLoadingStatus;
    return !hasMore && !pageLoading && itemCount === 0;
  }

  @computed
  get optionList(): AttributeValueModel[] {
    return Array.from(this.options.values());
  }

  hasOption(possibleOption: string) {
    return this.optionList.some(
      (x) => String(x.value) === String(possibleOption)
    );
  }

  @action.bound
  updateFromJson(json: Partial<IAttributeNode>) {
    if (json.name) {
      this.name = json.name;
    }
    if (json.attributeListValues) {
      this.updateOptionsFromJson(json.attributeListValues);
    }
  }

  @action.bound
  updateOptionsFromJson(json: IAttributeValueNode[]) {
    for (const option of json) {
      const value = this.store.attributeValueFromJson(option);
      this.options.set(value.uuid, value);
    }

    this.optionsLoadingStatus = {
      ...this.optionsLoadingStatus,
      lastPageLoaded: 1,
      hasMore: false,
      itemCount: this.options.size,
      pageLoading: false,
    };
  }

  @action.bound
  removeOption(uuid: UUID) {
    this.options.delete(uuid);
    this.optionsLoadingStatus.itemCount--;
  }

  @action.bound
  async loadOptions() {
    if (this.options.size > 0 || !this.optionsLoadingStatus.hasMore) {
      return;
    }
    await this.store.loadAttributeOptions(this);
  }

  update(values: WritableFields<AttributeModel>) {
    this.store.updateAttribute(this, values.name!);
  }

  delete(parent: TagModel | ClassModel) {
    this.store.deleteAttribute(this, parent);
  }

  createOption(rawValue: string) {
    if (this.type === "DateList") {
      this.store.createAttributeOption(this, new Date(rawValue));
    } else if (this.type === "NumericList") {
      this.store.createAttributeOption(this, Number(rawValue));
    } else {
      this.store.createAttributeOption(this, rawValue);
    }
  }

  updateOption(uuid: UUID, rawValue: string | number | Date) {
    if (this.type === "DateList") {
      this.store.updateAttributeOption(this, uuid, new Date(rawValue));
    } else if (this.type === "NumericList") {
      this.store.updateAttributeOption(this, uuid, Number(rawValue));
    } else {
      this.store.updateAttributeOption(this, uuid, rawValue);
    }
  }

  deleteOption(uuid: UUID) {
    this.store.deleteAttributeOption(this, uuid);
  }
}

export const ATTRIBUTE_TYPE_OPTIONS: Record<AttributeType, MessageDescriptor> =
  {
    Date: defineMessage({
      id: "attribute.type.date",
      defaultMessage: "Date",
    }),
    String: defineMessage({
      id: "attribute.type.string",
      defaultMessage: "Text",
    }),
    Numeric: defineMessage({
      id: "attribute.type.numeric",
      defaultMessage: "Number",
    }),
    DateList: defineMessage({
      id: "attribute.type.datelist",
      defaultMessage: "Date list",
    }),
    StringList: defineMessage({
      id: "attribute.type.stringlist",
      defaultMessage: "Text list",
    }),
    NumericList: defineMessage({
      id: "attribute.type.numericlist",
      defaultMessage: "Number list",
    }),
  };
