import { observable, action, computed, when, reaction } from "mobx";
import unionBy from "lodash/unionBy";
import {
  ClassModel,
  SelectedCustomFilterValues,
  ClassLastUsedModel,
  customFilterValuesToString,
} from "@web/models";
import { Api } from "@web/api";
import {
  applyChangeToSelectedFilters,
  ENTRY_STATUS_FILTER,
  CustomFilterModel,
  FilterModel,
  isClassFilter,
  FilterChangeEvent,
  isCustomFilterChange,
  SelectedTagsMap,
  isCustomFilter,
  TagModel,
  SelectedTagStatusMap,
  AttributeFilterMap,
  ResultParamModel,
  TagFacetCountsModel,
} from "@web/models";
import { SearchParams } from "@web/pages/SearchPage/handler";
import {
  parseAndGroupTagResponse,
  parseTagStatusResponse,
} from "./ResponseParser";
import { ILocalStorage } from "./types";
import { RootStore } from ".";

export const LAST_SELECTED_CLASSES_STORAGE_KEY = "lastSelectedClasses";

export class FilterStore {
  @observable
  selectedTags = new SelectedTagsMap();

  @observable
  selectedCustomValues: SelectedCustomFilterValues = {};

  @observable
  selectedTagStatuses: SelectedTagStatusMap = new Map();

  @observable
  attributeFilters = new AttributeFilterMap();

  @observable
  highlightedTag?: { classification?: ClassModel; tag: TagModel };

  customFilters: CustomFilterModel[] = [ENTRY_STATUS_FILTER];

  @observable
  classFilters: ClassModel[] = [];

  @observable
  private classFiltersLoaded = false;

  @observable
  facetCounts: TagFacetCountsModel | undefined;

  constructor(
    private api: Api,
    private rootStore: RootStore,
    private localStorage: ILocalStorage
  ) {}

  async filtersLoaded() {
    await when(() => this.classFiltersLoaded);
  }

  private sectionSelectionReaction = reaction(
    () => this.rootStore.sectionStore.selectedSection,
    (newSection) => {
      if (newSection) {
        this.setClassFilters(newSection.classifications);
        this.requiredTagsLoaded();
      }
    }
  );

  private async requiredTagsLoaded() {
    const { sectionStore } = this.rootStore;

    await when(() => sectionStore.requiredTagsForCurrentSection !== undefined);

    this.selectedTags.requiredTags =
      sectionStore.requiredTagsForCurrentSection || [];
  }

  /**
   * Set tag to highlight in the `TagHeader` UI component.
   * Reacts whenever a tag is added/removed from the selected tags map.
   */
  private tagSelectReaction = reaction(
    () => this.selectedTags.tagCount,
    () => {
      const singleTag = this.selectedTags.singleSelectedTag;
      if (singleTag) {
        this.highlightedTag = {
          tag: singleTag.tag,
          classification: this.classFilters.find(
            (cl) => cl.uuid === singleTag.classification
          ),
        };
      } else {
        this.highlightedTag = undefined;
      }
    }
  );

  @computed
  get searchParameters(): SearchParams {
    return {
      tags: this.selectedTags.asURLParam,
      attributes: this.attributeFilters.asURLParam,
      customFilters: customFilterValuesToString(this.selectedCustomValues),
      tagStatus: Array.from(this.selectedTagStatuses.values())
        .flatMap((allStatuses) =>
          Array.from(allStatuses).map((statusId) => statusId.toString())
        )
        .join(" "),
    };
  }

  @computed
  get filters(): FilterModel[] {
    // Make sure all filters appear at the same time
    if (!this.classFiltersLoaded) {
      return [];
    }
    return [...this.classFilters, ...this.customFilters];
  }

  /**
   * Returns true when no tag has been selected from mandatory filters
   */
  @computed
  get haveToSelectFromMandatoryClass() {
    return !!this.classFilters.find(
      (classModel) =>
        classModel.isMandatory &&
        this.selectedTags.get(classModel.uuid).length === 0
    );
  }

  /**
   * Load the initial selected tags when the
   * search page loads for the first time.
   *
   * @tagParams tags given by the URL params
   */
  @action
  async loadInitialTags(tagParams: string | undefined) {
    if (!tagParams) {
      return;
    }
    const tagsToFind = tagParams.replace(/_/g, " ").split(" ");
    const { data } = await this.api.findTagsById(tagsToFind);
    if (data.data.length > 0) {
      const tags = parseAndGroupTagResponse(
        data.data,
        this.rootStore.attributeStore
      );

      await this.filtersLoaded();
      this.selectedTags = new SelectedTagsMap(tags);
      this.addRecentlyUsedTags(this.classFilters);
    }
  }

  @action
  loadInitialSelectedTagStatuses = async (
    tagStatusParam: string | undefined
  ) => {
    if (!tagStatusParam) {
      return;
    }
    const { data } = await this.api.findTagStatusesById(
      tagStatusParam.split(" ")
    );
    this.selectedTagStatuses = parseTagStatusResponse(data);
  };

  @action
  loadInitialSelectedCustomValues = (customValueParams: string | undefined) => {
    if (!customValueParams) {
      return;
    }
    this.selectedCustomValues = JSON.parse(customValueParams);
  };

  @action
  setClassFilters = (classifications: ClassModel[]) => {
    this.addRecentlyUsedTags(classifications);
    this.classFilters = classifications;
    this.classFiltersLoaded = true;
  };

  @action.bound
  setTagStatusSelected(statuses: Set<number>, classification: UUID) {
    if (statuses.size > 0) {
      this.selectedTagStatuses.set(classification, statuses);
    } else {
      this.selectedTagStatuses.delete(classification);
    }
  }

  @action.bound
  setFilterValueSelected(change: FilterChangeEvent) {
    if (isCustomFilterChange(change)) {
      this.selectedCustomValues = applyChangeToSelectedFilters(
        change,
        this.selectedCustomValues
      );
    } else {
      this.selectedTags.update(change);
      if (change.selected) {
        this.storeRecentlyUsedTags(this.selectedTags.asObject);
      } else {
        const attributes = change.tag.attributes.flatMap((attr) => attr.id);
        this.attributeFilters.clearSubset(attributes);
      }
    }
  }

  @action.bound
  clearFilters(filters: FilterModel[]) {
    const classFilters = filters.filter(isClassFilter);
    const customFilters = filters.filter(isCustomFilter);
    for (const filter of customFilters) {
      delete this.selectedCustomValues[filter.uuid];
    }
    for (const filter of classFilters) {
      this.selectedTagStatuses.delete(filter.uuid);
      this.attributeFilters.clearSubset(
        this.selectedTags.getAttributeIds(filter.uuid)
      );
    }
    this.selectedTags.clearSubset(classFilters);
    this.addRecentlyUsedTags(classFilters);
  }

  @action.bound
  resetSelectedFilterValues() {
    this.selectedTags.clearAll();
    this.selectedCustomValues = {};
    this.selectedTagStatuses.clear();
    this.attributeFilters.clearAll();
  }

  @action.bound
  handleFilterClose(filterId: string) {
    this.addRecentlyUsedTags(
      this.classFilters.filter((f) => f.uuid === filterId)
    );
  }

  @action.bound
  prepareFacetCounts(filterId: string, resultParams: ResultParamModel) {
    // Modify resultParams to use the freshest selected tags
    // and also omitting the current filter's classification
    const modifiedParams: ResultParamModel = {
      ...resultParams,
      tags: this.selectedTags.getGroupedTagIds({
        exceptClassificationId: filterId,
      }),
    };

    this.facetCounts = new TagFacetCountsModel(this, modifiedParams);
  }

  @action.bound
  async loadFacetCounts(
    tagInternalIds: number[],
    resultParams: ResultParamModel
  ) {
    const first200 = tagInternalIds.slice(0, 200);
    const {
      data: { data },
    } = await this.api.getTagFacets(first200, resultParams);
    this.facetCounts?.updateFromJson(first200, data);
  }

  @action.bound
  clearFacetCounts() {
    this.facetCounts = undefined;
  }

  private storeRecentlyUsedTags(values: SelectedCustomFilterValues) {
    // TODO: start using tag IDs instead of name + move away from legacy model
    const lastSelected = this.localStorage.getItem(
      LAST_SELECTED_CLASSES_STORAGE_KEY
    );

    const newData: ClassLastUsedModel = JSON.parse(lastSelected || "{}");
    for (const key in values) {
      const newValue = values[key].map((val) => ({
        value: val,
        lastUsed: Date.now(),
      }));

      if (newData[key]) {
        const union = unionBy(newValue, newData[key], "value");
        newData[key] = union
          .sort((a, b) => (a.lastUsed > b.lastUsed ? -1 : 1))
          .slice(0, 8);
      } else {
        newData[key] = newValue;
      }
    }

    try {
      this.localStorage.setItem(
        LAST_SELECTED_CLASSES_STORAGE_KEY,
        JSON.stringify(newData)
      );
    } catch (err) {
      console.error(
        "Error while storing last selected tags into localStorage:",
        err
      );
    }
  }

  private addRecentlyUsedTags(classFilters: ClassModel[]) {
    try {
      const lastSelectedStored = this.localStorage.getItem(
        LAST_SELECTED_CLASSES_STORAGE_KEY
      );
      if (lastSelectedStored) {
        const lastSelected: ClassLastUsedModel = JSON.parse(lastSelectedStored);

        for (const cl of classFilters) {
          if (lastSelected[cl.uuid]) {
            cl.updateRecentlyUsedTags(
              lastSelected[cl.uuid].filter(
                (c) => !this.selectedTags.has(cl.uuid, c.value)
              )
            );
          }
        }
      }
    } catch (e) {
      try {
        this.localStorage.setItem(LAST_SELECTED_CLASSES_STORAGE_KEY, "{}");
      } catch {
        console.error("Failed when using 'localStorage.setItem'.");
      }
    }
  }
}
