import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "@web/styles/utils";
import { ReminderModel, EntryModel } from "@web/models";
import { RemindersList } from "./RemindersList";
import { ReminderForm } from "./RemindersForm";

type DialogContent =
  | { type: "list" }
  | { type: "add" }
  | { type: "edit"; reminder: ReminderModel };

interface Props {
  entry: EntryModel;
  onCloseClick?: () => void;
}

export const RemindersDialog: React.FC<Props> = (p) => {
  const [activeContent, setActiveContent] = useState<DialogContent>({
    type: "list",
  });
  const displayList = () => setActiveContent({ type: "list" });

  useEffect(() => {
    p.entry.loadReminders();
  }, [p.entry.id]);

  return (
    <_wrap>
      {activeContent.type === "list" && (
        <RemindersList
          entry={p.entry}
          onAddClick={() => setActiveContent({ type: "add" })}
          onCloseClick={p.onCloseClick}
          onReminderClick={(reminder) =>
            setActiveContent({ type: "edit", reminder })
          }
        />
      )}

      {activeContent.type === "add" && (
        <ReminderForm
          onClose={displayList}
          onSave={(title, date) => p.entry.createReminder({ title, date })}
        />
      )}

      {activeContent.type === "edit" && (
        <ReminderForm
          reminder={activeContent.reminder}
          onClose={() =>
            setActiveContent({
              type: "list",
            })
          }
          onDeleteClick={() => p.entry.deleteReminder(activeContent.reminder)}
          onSave={(title, date) =>
            activeContent.reminder.update({ title, date })
          }
        />
      )}
    </_wrap>
  );
};

const _wrap = styled.article`
  ${media("desktop")} {
    width: 300px;
  }

  font-size: 1rem;
`;
