import { useState } from "react";
import { useMedia } from "@web/styles/utils";
import { useUniqueId } from "@web/utils/hooks/useUniqueId";

export const useTabPanels = <T>(initialPanel: T, defaultCondition = true) => {
  const [currentPanel, setPanel] = useState<T>(initialPanel);
  const panelId = useUniqueId("panel");
  const tabId = useUniqueId("tab");

  const getTabProps = (panel: T, condition = defaultCondition) =>
    condition && {
      role: "tab",
      id: `${tabId}-${panel}`,
      "aria-controls": `${panelId}-${panel}`,
      "aria-selected": panel === currentPanel,
      onClick: () => setPanel(panel),
    };

  const getPanelProps = (newPanel: T, condition = defaultCondition) =>
    condition && {
      role: "panel",
      id: `${panelId}-${newPanel}`,
      hidden: newPanel !== currentPanel,
      "aria-labelledby": `${tabId}-${newPanel}`,
    };

  return {
    currentPanel,
    getTabProps,
    getPanelProps,
    setPanel,
  };
};
