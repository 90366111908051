import React, { ReactElement } from "react";
import { defineMessages, MessageDescriptor } from "react-intl";
import nb from "./nb.json";

// required for Edge 18 support (before being Chromium-based)
import "@formatjs/intl-relativetimeformat/dist/polyfill";

// additional locale data needed to format plural numbers & dates for the languages we support
import "@formatjs/intl-pluralrules/dist/locale-data/en";
import "@formatjs/intl-pluralrules/dist/locale-data/nb";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/nb";

/**
 * This is useful for stores if they want to return a self contained translatable message
 * that contains dynamic value placeholders, and we want to avoid the React.js components
 * rendering those messages to the end-user to know which values it needs to provide to
 * react-intl's methods or components.
 *
 * They can instead forward the entire object matching the signature below and react-intl
 * will be happily resolve the fully transable message.
 */
export type MessageWithValues = MessageDescriptor & {
  values?: Record<string, string | number | ReactElement<void>>;
};

export enum SupportedLocale {
  EN = "en-GB",
  NB = "nb",
}

/**
 * HTML is escaped by default by react-intl, to allow rich formatting of text we gotta
 * define functions handling each HTML tag explicitly (or it will get HTML escaped).
 *
 * Refs https://formatjs.io/docs/react-intl/components/#rich-text-formatting
 */
export const RICH_TEXT_FORMATTERS: Record<string, any> = {
  em: (text: string[]) => <em>{text}</em>,
  strong: (text: string[]) => <strong>{text}</strong>,
  b: (chunk: string[]) => <b>{chunk}</b>,
  br: <br />,
} as const;

export function getTranslationsForLocale(
  locale: SupportedLocale
): Record<string, string> | undefined {
  switch (locale) {
    case SupportedLocale.NB:
      return nb;
    case SupportedLocale.EN:
    default:
      return undefined;
  }
}

export const commonTexts = defineMessages({
  add: {
    id: "common.add",
    defaultMessage: "Add",
  },
  and: {
    id: "common.and",
    defaultMessage: "and",
  },
  asyncDeleteWarning: {
    id: "common.asyncdelete.warning",
    defaultMessage: `Sometimes it takes a while for the {entity, select,
        tag {tag}
        section {section}
        classification {list}
        tagStatus {status}
        attribute {property}
        attributeListValue {list value}
        other {object}
      } to be deleted, depending on the number of documents stored in Documaster.
      The deletion operation will run quietly in the background.
      `,
  },
  back: {
    id: "common.back",
    defaultMessage: "Go back",
  },
  addNewTag: {
    id: "common.addnewtag",
    defaultMessage: "Add new tag",
  },
  apply: {
    id: "common.apply",
    defaultMessage: "Apply",
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Cancel",
  },
  clear: {
    id: "common.clear",
    defaultMessage: "Clear",
  },
  close: {
    id: "common.close",
    defaultMessage: "Close",
  },
  color: {
    id: "common.color",
    defaultMessage: "Color",
  },
  delete: {
    id: "common.delete",
    defaultMessage: "Delete",
  },
  done: {
    id: "common.done",
    defaultMessage: "Done",
  },
  edit: {
    id: "common.edit",
    defaultMessage: "Edit",
  },
  expand: {
    id: "common.expand",
    defaultMessage: "Expand",
  },
  lastUpdated: {
    id: "common.lastUpdated",
    defaultMessage: "Last updated {dateString} ago",
  },
  loading: {
    id: "common.loading",
    defaultMessage: "Loading…",
  },
  minimize: {
    id: "common.minimize",
    defaultMessage: "Minimize",
  },
  more: {
    id: "common.more",
    defaultMessage: "more",
  },
  moreOptions: {
    id: "common.moreoptions",
    defaultMessage: "More options",
  },
  move: {
    id: "common.move",
    defaultMessage: "Move",
  },
  moveHere: {
    id: "common.movehere",
    defaultMessage: "Move here",
  },
  copyHere: {
    id: "common.copyhere",
    defaultMessage: "Copy here",
  },
  new: {
    id: "common.new",
    defaultMessage: "New",
  },
  noMatches: {
    id: "common.nomatches",
    defaultMessage: "No matches",
  },
  ok: {
    id: "common.ok",
    defaultMessage: "OK",
  },
  or: {
    id: "common.or",
    defaultMessage: "or",
  },
  remove: {
    id: "common.remove",
    defaultMessage: "Remove",
  },
  retry: {
    id: "common.retry",
    defaultMessage: "Retry",
  },
  select: {
    id: "common.select",
    defaultMessage: "Select",
  },
  search: {
    id: "common.search",
    defaultMessage: "Search…",
  },
  save: {
    id: "common.save",
    defaultMessage: "Save",
  },
  saving: {
    id: "common.saving",
    defaultMessage: "Saving changes…",
  },
  status: {
    id: "common.status",
    defaultMessage: "Status",
  },
  tags: {
    id: "common.tags",
    defaultMessage: "tags",
  },
  lock: {
    id: "common.lock",
    defaultMessage: "Lock",
  },
  unlock: {
    id: "common.unlock",
    defaultMessage: "Unlock",
  },
  unhandledErrorTitle: {
    id: "common.errorTitle",
    defaultMessage: "Ooops, you hit a snag!",
  },
  unhandledErrorMessage: {
    id: "common.errorMessage",
    defaultMessage:
      "An error occured, please reload the application or contact support.",
  },
  untitled: {
    id: "common.untitled",
    defaultMessage: "Untitled",
  },
  untitledEntry: {
    id: "common.untitledEntry",
    defaultMessage: "Untitled folder",
  },
  next: {
    id: "common.next",
    defaultMessage: "Next",
  },
  previous: {
    id: "common.previous",
    defaultMessage: "Previous",
  },
  create: {
    id: "common.create",
    defaultMessage: "Create",
  },
  month: {
    id: "common.month",
    defaultMessage: "Month",
  },
  year: {
    id: "common.year",
    defaultMessage: "Year",
  },
  nameRequired: {
    id: "common.name.required",
    defaultMessage: "Name (required)",
  },
  description: {
    id: "common.description",
    defaultMessage: "Description",
  },
});
