import React from "react";
import { UploadButton } from "./UploadButton";
import { VersionUploadProps } from "./types";
import { handleVersionUpload } from "./handlers";
import { defineMessages } from "react-intl";
import { Button } from "@web/elements/Button";
import { useStores } from "@web/stores/context";

export const VersionUploadButton = (
  p: VersionUploadProps & { disabled?: boolean }
) => {
  const documentVersionStore = useStores().documentVersionStore;

  const handleUpload = (files: File[]) => {
    handleVersionUpload(documentVersionStore, files[0], p);
  };

  return (
    <UploadButton
      disabled={p.disabled}
      multiple={false}
      onUpload={handleUpload}
    >
      <Button
        text={texts.addNewVersion}
        variant="secondary"
        disabled={p.disabled}
      />
    </UploadButton>
  );
};

const texts = defineMessages({
  addNewVersion: {
    id: "document.info.addversion",
    defaultMessage: "Add new version",
  },
});
