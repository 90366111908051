import React from "react";

export const FolderUploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C0.447715 3 0 3.44772 0 4V20.24C0 20.7923 0.447716 21.24 1 21.24H23C23.5523 21.24 24 20.7923 24 20.24V5.71C24 5.15772 23.5523 4.71 23 4.71H8.72727V4C8.72727 3.44772 8.27956 3 7.72727 3H1ZM6.96713 14.1257C6.52168 14.1257 6.29859 13.5871 6.61358 13.2722L11.6529 8.23282C12.0434 7.84229 12.6766 7.84229 13.0671 8.23282L18.1065 13.2722C18.4215 13.5871 18.1984 14.1257 17.7529 14.1257L14.0362 14.1257V18.34C14.0362 18.6161 13.8124 18.84 13.5362 18.84L11.1838 18.84C10.9077 18.84 10.6838 18.6161 10.6838 18.34V14.1257H6.96713Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
