import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { ellipsis } from "polished";
import { defineMessages, useIntl } from "react-intl";
import { UploadGroup, UploadJobStatus, UploadGroupProgress } from "@web/models";
import { FileType } from "@web/elements";
import { ProgressBar } from "@web/elements/ProgressBar";
import { vars } from "@web/styles";
import { StyledButton } from "@web/elements/Button/styles";

interface IProps {
  upload: UploadGroup;
  isCurrentlyDisplayed?: boolean;
  onEntryClick: (recordId: number) => void;
  onDocumentClick: (documentId: number, recordId: number) => void;
}

export const UploadOverlayItem: React.FC<IProps> = observer((props) => {
  const intl = useIntl();

  function handleItemClick() {
    props.upload.isEntryGroup ? handleRecordClick() : handleDocumentClick();
  }
  function handleRecordClick() {
    const { entryId } = props.upload;
    if (entryId) {
      props.onEntryClick(entryId);
    }
  }
  function handleDocumentClick() {
    const { jobs } = props.upload;
    if (jobs.length === 0) {
      return;
    }

    const { documentId, entryId } = jobs[0].result;
    if (documentId && entryId) {
      props.onDocumentClick(documentId, entryId);
    }
  }

  const { jobs, progress, title, isEntryGroup, status, isInProgress } =
    props.upload;

  if (jobs.length === 0) {
    return null;
  }

  const { fileFormat, fileName } = jobs[0].result;
  const isClickable = status === UploadJobStatus.completed;
  const cardTitle = title || intl.formatMessage(texts.entry);

  return (
    <_wrap>
      <_content onClick={isClickable ? handleItemClick : undefined}>
        {isEntryGroup ? (
          <_title>
            {cardTitle}{" "}
            <_more>
              {intl.formatMessage(texts.withFilesCount, { count: jobs.length })}
            </_more>
          </_title>
        ) : (
          <_title>
            <FileType type={fileFormat || ""} />
            {fileName}
          </_title>
        )}

        <Status
          status={status}
          progress={progress}
          isCurrentlyDisplayed={props.isCurrentlyDisplayed}
        />
      </_content>

      {isInProgress && (
        <_progress>
          <ProgressBar completedPercent={progress.completedPercent} />
        </_progress>
      )}
    </_wrap>
  );
});

const Status: React.FC<{
  progress: UploadGroupProgress;
  status: UploadJobStatus;
  isCurrentlyDisplayed?: boolean;
}> = ({ status, progress, isCurrentlyDisplayed }) => {
  const intl = useIntl();

  switch (status) {
    case UploadJobStatus.created:
    case UploadJobStatus.waitingForEntry:
    case UploadJobStatus.readyForUpload:
      return <_status>{intl.formatMessage(texts.waiting)}</_status>;
    case UploadJobStatus.uploading:
      return <_status>{Math.round(progress.completedPercent)}%</_status>;
    case UploadJobStatus.cancelled:
      return <_statusFail>{intl.formatMessage(texts.cancelled)}</_statusFail>;
    case UploadJobStatus.failed:
      return <_statusFail>{intl.formatMessage(texts.failed)}</_statusFail>;
    case UploadJobStatus.completed:
      return !isCurrentlyDisplayed ? (
        <_btnOpen>{intl.formatMessage(texts.open)}</_btnOpen>
      ) : null;
  }
};

const _wrap = styled.div`
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 1rem;
  overflow: hidden;
  padding: 1rem;

  :hover {
    background: ${vars.secondaryAltLight20};
    color: ${vars.primaryDark20};
  }

  > *:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const _content = styled.div.attrs((p) => ({ role: p.onClick && "button" }))`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: ${(p) => p.onClick && `pointer`};
`;

const _title = styled.div`
  ${ellipsis()};
  flex: 1;
`;

const _more = styled.div`
  display: inline-block;
  font-size: 0.875em;
  opacity: 0.75;
`;

const _status = styled.span`
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const _statusFail = styled(_status)`
  background: ${vars.danger};
  color: ${vars.dangerFg};
  font-weight: bold;
  align-self: center;
`;

const _btnOpen = styled(StyledButton)`
  border-radius: 0.5rem;
  font-weight: bold;
`;

const _progress = styled.div``;

const texts = defineMessages({
  cancelled: {
    id: "upload.overlayitem.cancelled",
    defaultMessage: "Cancelled",
  },
  entry: {
    id: "upload.overlayitem.entry",
    defaultMessage: "Folder",
  },
  failed: {
    id: "upload.overlayitem.failed",
    defaultMessage: "Failed",
  },
  open: {
    id: "upload.overlayitem.open",
    defaultMessage: "Open",
  },
  waiting: {
    id: "upload.overlayitem.waiting",
    defaultMessage: "Waiting",
  },
  withFilesCount: {
    id: "upload.overlayitem.withfilescount",
    defaultMessage: "with {count, plural, one {1 file} other {# files}}",
  },
});
