import React, { useState } from "react";
import styled from "styled-components";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { Dialog } from "@web/components/Dialog";
import { ActionMenu, Input } from "@web/elements";
import { commonTexts } from "@web/translations";

export const EditTitleDialog = (p: {
  allowEmpty?: boolean;
  defaultValue?: string;
  titleText?: MessageDescriptor;
  saveText?: MessageDescriptor;
  onSave: (value: string) => void;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(p.defaultValue ?? "");
  const canSave = value !== p.defaultValue && (value.trim() || p.allowEmpty);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (canSave) {
      p.onSave(value.trim());
      p.onClose();
    }
  };

  return (
    <Dialog
      title={p.titleText || texts.title}
      onEscape={p.onClose}
      onClickOutside={p.onClose}
    >
      <_form onSubmit={handleSubmit}>
        <Input
          autoFocus
          value={value}
          placeholder={intl.formatMessage(commonTexts.untitled)}
          onChange={(e) => setValue(e.target.value)}
        />
        <ActionMenu
          applyIsDisabled={!canSave}
          onApply={handleSubmit}
          onCancel={p.onClose}
          applyText={p.saveText}
        />
      </_form>
    </Dialog>
  );
};

const _form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input {
    width: 100%;
  }
`;

const texts = defineMessages({
  title: { id: "dialog.editTitle.title", defaultMessage: "Edit title" },
});
