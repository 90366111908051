import React from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import {
  SelectedCustomFilterValues,
  FilterModel,
  isClassFilter,
  SelectedTagsMap,
  SelectedTagStatusMap,
} from "@web/models";
import { Box, ScrollBox } from "@web/elements";
import { FilterButton } from "@web/components/Filters/FilterButton";
import { TagStatusButton } from "@web/components/TagStatus/TagStatusButton";
import { isMedia } from "@web/styles/utils";
import { CrossIcon } from "@web/elements/Icons";
import { commonTexts } from "@web/translations";
import { Button } from "@web/elements/Button";
import { useStores } from "@web/stores/context";
import { texts } from "./texts";
import { _headerWrap, _action, _title, _headerLink } from "./styles";

interface Props {
  filters: FilterModel[];
  selectedTags: SelectedTagsMap;
  selectedCustomValues: SelectedCustomFilterValues;
  selectedTagStatuses: SelectedTagStatusMap;
  onSelect: (filterId: string) => void;
  onClear: (filterId: string) => void;
  onClearAllClick: (filters: FilterModel[]) => void;
  onCloseClick: (e: React.MouseEvent) => void;
}

export const FilterButtonList = observer((p: Props) => {
  const intl = useIntl();
  const { messageStore } = useStores();
  const { selectedTags, selectedTagStatuses, selectedCustomValues } = p;
  const title = isMedia("compact") ? texts.filters : texts.moreFilters;
  const showClearAllButton =
    !selectedTags.isEmpty ||
    selectedTagStatuses.size > 0 ||
    Object.keys(selectedCustomValues).length > 0;

  return (
    <>
      <_headerWrap>
        {p.onCloseClick && isMedia("compact") && (
          <_headerLink
            onClick={p.onCloseClick}
            aria-label={intl.formatMessage(commonTexts.close)}
          >
            <CrossIcon />
          </_headerLink>
        )}
        <_title>{intl.formatMessage(title)}</_title>
      </_headerWrap>
      <ScrollBox flex="1" pl="11px" pr="11px">
        {p.filters.map((filter, i) => {
          let selected: string[] = [];
          let statusButton: JSX.Element | undefined = undefined;
          let isMandatory = false;

          if (isClassFilter(filter)) {
            selected = selectedTags.getTagTitles(filter.uuid);
            isMandatory = filter.isMandatory;

            const statuses = selectedTagStatuses.get(filter.uuid);
            if (statuses && selected.length === 0) {
              statusButton = (
                <TagStatusButton
                  selected={filter.tagStatuses.filter((status) =>
                    statuses?.has(status.id)
                  )}
                />
              );
            }
          } else {
            selected = selectedCustomValues[filter.uuid] || [];
          }

          const showMandatoryClassError =
            isMandatory &&
            selected.length === 0 &&
            messageStore.hasMandatoryClassMessage;

          return (
            <Box key={i} mv=".25rem">
              <FilterButton
                isMultiline
                variation="dark"
                title={filter.title}
                filterId={filter.uuid}
                selectedValues={selected}
                onClearClick={p.onClear}
                onOpenClick={p.onSelect}
                error={showMandatoryClassError}
              >
                {statusButton}
              </FilterButton>
            </Box>
          );
        })}
      </ScrollBox>
      {showClearAllButton && (
        <_action>
          <Button
            text={texts.clearAll}
            variant="blank"
            onClick={() => p.onClearAllClick(p.filters)}
          />
        </_action>
      )}
    </>
  );
});
