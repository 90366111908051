import React, { Component, createRef, RefObject } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import {
  SelectedCustomFilterValues,
  isClassFilter,
  FilterModel,
  combineSelectedValues,
  TagSearchModel,
  FilterChangeEvent,
  SelectedTagsMap,
  isCustomFilter,
  SelectedTagStatusMap,
  TagFacetCountsModel,
} from "@web/models";
import { isMedia } from "@web/styles/utils";
import { Overflower } from "@web/components/Overflower";
import { TagStatusButton } from "../TagStatus/TagStatusButton";
import { FilterButton } from "./FilterButton";
import { FilterSelect } from "./FilterSelect";
import { texts } from "./texts";

interface IProps {
  filters: FilterModel[];
  selectedTags: SelectedTagsMap;
  selectedCustomValues: SelectedCustomFilterValues;
  selectedTagStatuses: SelectedTagStatusMap;
  tagSearch: TagSearchModel;
  facetCounts?: TagFacetCountsModel;
  onFilterChange: (change: FilterChangeEvent) => void;
  onTagStatusChange: () => void;
  onClearFilters: (filters: FilterModel[]) => void;
  onFilterOpen?: (filterId: string) => void;
  onFilterClosed?: (filterId: string) => void;
  mandatoryClassMessageIsShown?: boolean;
}

interface IState {
  openFilter?: string;
}

const MORE_ID = "more-component";

@observer
export class Filters extends Component<IProps, IState> {
  private buttonRefs: { [key: string]: RefObject<HTMLDivElement> } = {};
  // We do not want to use state for this as we don't want to cause a full
  // rerender everytime the cutoff changes.
  private currentCutoff = 0;

  state: IState = {
    openFilter: undefined,
  };

  get moreText() {
    return isMedia("compact") ? texts.filters : texts.moreFilters;
  }

  getFilters(filterId: string) {
    const { filters } = this.props;
    if (filterId === MORE_ID) {
      return filters.slice(this.currentCutoff);
    } else {
      const filter = filters.find((f) => f.uuid === filterId);
      if (filter) return [filter];
    }
  }

  handleButtonClick = (filterId: string) => {
    const { tagSearch, selectedTagStatuses, onFilterOpen } = this.props;
    if (filterId !== MORE_ID) {
      tagSearch.setActiveClassification(filterId);
      const statuses = selectedTagStatuses.get(filterId);
      tagSearch.tagStatuses = new Set(statuses);
    }
    this.setState({ openFilter: filterId });
    onFilterOpen?.(filterId);
  };

  handleButtonClearClick = (filterId: string) => {
    const { onClearFilters } = this.props;
    const filters = this.getFilters(filterId);
    if (filters) {
      onClearFilters(filters);
    }
  };

  handleFilterClose = () => {
    if (this.state.openFilter) {
      this.props.onFilterClosed?.(this.state.openFilter);
    }
    this.setState({ openFilter: undefined });
  };

  handleOverflowChange = (overflowCount: number) => {
    this.currentCutoff = overflowCount;
  };

  createOrReturnRef = (id: string) => {
    if (!this.buttonRefs[id]) {
      this.buttonRefs[id] = createRef();
    }
    return this.buttonRefs[id];
  };

  renderFilterSelect = () => {
    const { openFilter } = this.state;
    if (!openFilter) return;
    const filtersToShow = this.getFilters(openFilter);
    if (!filtersToShow) return;

    return (
      <FilterSelect
        filters={filtersToShow}
        triggerRef={this.buttonRefs[openFilter]}
        selectedTags={this.props.selectedTags}
        selectedCustomValues={this.props.selectedCustomValues}
        selectedTagStatuses={this.props.selectedTagStatuses}
        tagSearch={this.props.tagSearch}
        facetCounts={this.props.facetCounts}
        onCloseClick={this.handleFilterClose}
        onClearAllTags={this.props.onClearFilters}
        onFilterChange={this.props.onFilterChange}
        onTagStatusChange={this.props.onTagStatusChange}
      />
    );
  };

  render() {
    const { filters, selectedTags, selectedCustomValues, selectedTagStatuses } =
      this.props;

    if (filters.length === 0) {
      return null;
    }

    return (
      <>
        <_wrap>
          <Overflower
            gap={8}
            onOverflowChange={this.handleOverflowChange}
            moreComponent={({ visibleItems, overflowItems, ref }) => {
              const filters = this.getFilters(MORE_ID);
              const classFilters = filters?.filter(isClassFilter);
              const customFilters = filters?.filter(isCustomFilter);
              const selectedCustom = combineSelectedValues(
                selectedCustomValues,
                customFilters
              );
              const selected = selectedTags.combine(
                classFilters,
                selectedCustom
              );
              return (
                !!overflowItems.length && (
                  <div ref={ref}>
                    <FilterButton
                      hideValue
                      ref={this.createOrReturnRef(MORE_ID)}
                      title={this.moreText}
                      filterId={MORE_ID}
                      selectedValues={selected}
                      onClearClick={this.handleButtonClearClick}
                      onOpenClick={this.handleButtonClick}
                    />
                  </div>
                )
              );
            }}
          >
            {filters.map((filter, i) => {
              let selected: string[] = [];
              let statusButton: JSX.Element | undefined = undefined;
              let isMandatory = false;

              if (isClassFilter(filter)) {
                selected = selectedTags.getTagTitles(filter.uuid);
                isMandatory = filter.isMandatory;

                const statuses = selectedTagStatuses.get(filter.uuid);
                if (statuses && selected.length === 0) {
                  statusButton = (
                    <TagStatusButton
                      selected={filter.tagStatuses.filter((st) =>
                        statuses?.has(st.id)
                      )}
                    />
                  );
                }
              } else {
                selected = selectedCustomValues[filter.uuid] || [];
              }

              const showMandatoryClassError =
                isMandatory &&
                selected.length === 0 &&
                this.props.mandatoryClassMessageIsShown;

              return (
                <FilterButton
                  key={i}
                  ref={this.createOrReturnRef(filter.uuid)}
                  title={filter.title}
                  filterId={filter.uuid}
                  selectedValues={selected}
                  error={showMandatoryClassError}
                  onClearClick={this.handleButtonClearClick}
                  onOpenClick={this.handleButtonClick}
                >
                  {statusButton}
                </FilterButton>
              );
            })}
          </Overflower>
        </_wrap>

        {this.state.openFilter && this.renderFilterSelect()}
      </>
    );
  }
}

const _wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;

  > * + * {
    margin-left: 0.5rem;
  }
`;
