import React from "react";

interface ICircleProgressProps {
  size?: number;
  percentage: number;
}

export const CircleProgress: React.FC<ICircleProgressProps> = (p) => {
  const size = p.size ?? 44;

  const viewBox = `0 0 ${size} ${size}`;
  const strokeWidth = size / 10;
  const radius = (size - strokeWidth) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * p.percentage) / 100;

  const circleProps = {
    fill: "none",
    cx: size / 2,
    cy: size / 2,
    r: radius,
    strokeWidth: strokeWidth + "px",
  };

  return (
    <svg
      role="progressbar"
      aria-valuenow={p.percentage}
      aria-valuemin={0}
      aria-valuemax={100}
      width={size}
      height={size}
      viewBox={viewBox}
      style={{ verticalAlign: "middle" }}
    >
      <circle
        {...circleProps}
        stroke="var(--svg-tracer-stroke, rgba(255,255,255,.2))"
      />
      <circle
        {...circleProps}
        stroke="var(--svg-progress-stroke, #00B8BD)"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        style={{ translate: "all 200ms" }}
      />
      <text
        x="50%"
        y="50%"
        dy=".35em"
        fontSize={size / 3.25}
        fill="var(--svg-text-fill, white)"
        textAnchor="middle"
      >
        {p.percentage}%
      </text>
    </svg>
  );
};
