import {
  IKeyboardStore,
  KeyboardEventHandler,
  KeyCombination,
} from "@web/stores/types";

interface IListeners {
  [key: string]: KeyboardEventHandler;

  ArrowUp: KeyboardEventHandler;
  ArrowDown: KeyboardEventHandler;
  ArrowLeft: KeyboardEventHandler;
  ArrowRight: KeyboardEventHandler;
  Enter: KeyboardEventHandler;
}

interface IClient {
  listeners: Partial<IListeners>;
  enabled: () => boolean;
}

export class KeyboardStore implements IKeyboardStore {
  private clients = new Map<string, IClient>();

  constructor() {
    window.addEventListener("keydown", this.handleKeydown);
  }

  private handleKeydown = (e: KeyboardEvent) => {
    for (const client of this.clients.values()) {
      if (!client.enabled()) {
        continue;
      }
      const handler = client.listeners[e.key];
      if (handler) {
        // Do not invoke handler if an input field is in focus
        // and there is a value in the field
        const activeInput = getActiveInputField();
        if (activeInput) {
          if (
            activeInput.value !== "" ||
            activeInput.dataset.isOpen === "true"
          ) {
            return;
          }
          activeInput.blur();
        }
        e.preventDefault();
        handler(e);
      }
    }
  };

  removeClient(identifier: string) {
    this.clients.delete(identifier);
  }

  installClient(
    identifier: string,
    enabled: () => boolean,
    listeners: Partial<Record<KeyCombination, KeyboardEventHandler>>
  ): void {
    this.clients = this.clients.set(identifier, { listeners, enabled });
  }
}

const getActiveInputField = () => {
  const focusedDOMElement = document.activeElement;
  if (focusedDOMElement && focusedDOMElement.tagName === "INPUT") {
    return focusedDOMElement as HTMLInputElement;
  }
};
