import React from "react";
import { SingleEventModel } from "@web/models";
import {
  ChangeLogEvent,
  textForEvent,
} from "@web/components/Entry/HistoryPane";

export const SingleEvent: React.FC<{
  event: SingleEventModel;
}> = ({ event }) => {
  const text = textForEvent(event);
  // This generic purpose component shouldn't render anything if we have not
  // translated this particular type of event yet
  if (text === undefined) {
    return null;
  }

  const textWithFieldValues = {
    ...text,
    values: {
      ...text.values,
      ...event.fields,
    },
  };

  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.eventTime}
      text={textWithFieldValues}
    />
  );
};
