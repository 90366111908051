import React from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ResultOrderKey } from "@web/models";
import { useStores } from "@web/stores/context";
import styled from "styled-components";
import { SortMenu } from "@web/components/Results/SortMenu";
import { MoreButton } from "@web/elements/Button";
import { Menu, MenuItem } from "@web/components/Menu";
import { vars } from "@web/styles";
import { Dropdown } from "@web/components/Dropdown";
import { media, useMedia } from "@web/styles/utils";
import { ViewTypeMenu } from "@web/components/Results/ViewTypeMenu";

const ToolbarContainer: React.FC = observer(() => {
  const navigate = useNavigate();
  const { isCompact } = useMedia();
  const { resultStore, searchStore, currentSearchURL } = useStores();

  function handleCSVDownload() {
    resultStore.downloadCSV();
  }

  function handleSortClick(order: ResultOrderKey) {
    searchStore.setResultOrder(order);
    navigate(currentSearchURL());
  }

  return (
    <_toolbar>
      <_group>
        <TotalCount
          count={resultStore.resultState.totalCount}
          type={resultStore.resultState.totalCountType}
        />
      </_group>

      <_group>
        <SortMenu
          options={resultStore.sortOptions}
          onSortClick={handleSortClick}
        />
        {!isCompact && (
          <ViewTypeMenu
            viewType={resultStore.viewType}
            onChange={(viewType) => (resultStore.viewType = viewType)}
          />
        )}

        <_separator />

        <Dropdown
          closeOnOutsideClick
          toggle={(ref) => <MoreButton size={0.875} ref={ref} />}
        >
          <Menu>
            <MenuItem onClick={() => handleCSVDownload()}>
              <FormattedMessage
                id="searchpage.toolbar.downloadcsv"
                defaultMessage="Download CSV"
              />
            </MenuItem>
          </Menu>
        </Dropdown>
      </_group>
    </_toolbar>
  );
});

export default ToolbarContainer;

const TotalCount = ({
  count,
  type,
}: {
  count: number;
  type: "documents" | "entries";
}) => {
  if (count < 1) {
    return null;
  }

  switch (type) {
    case "documents":
      return (
        <FormattedMessage
          id="searchpage.toolbar.documentscount"
          defaultMessage={`{count, plural,
            one {1 document}
            other {# documents}
          }`}
          values={{ count }}
        />
      );
    case "entries":
      return (
        <FormattedMessage
          id="searchpage.toolbar.entrycount"
          defaultMessage={`{count, plural,
            one {1 item}
            other {# items}
          }`}
          values={{ count }}
        />
      );
    default:
      return null;
  }
};

const _toolbar = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 0.875rem;
  gap: 0.5rem;

  ${media("compact")} {
    width: 100%;
  }
`;

const _group = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const _separator = styled.div`
  background: ${vars.dark25};
  width: 1px;
  height: 1rem;
  margin: 0 0.25rem;
`;
