import React, { forwardRef, Ref } from "react";
import styled from "styled-components";
import ellipsis from "polished/lib/mixins/ellipsis";
import { MessageDescriptor, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { AsteriskIcon, CrossIcon } from "@web/elements/Icons";
import { StatusColor } from "@web/elements";
import { vars } from "@web/styles";
import { IThemeVariation } from "@web/styles/types";
import { commonTexts } from "@web/translations";
import { EntryStatusName } from "@web/models";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";

interface IProps {
  title: string | MessageDescriptor;
  filterId: string;
  selectedValues: string[];
  error?: boolean;
  variation?: "dark" | "light" | "subtle";
  hideValue?: boolean;
  hideTitle?: boolean;
  children?: JSX.Element;
  isMultiline?: boolean;
  onClearClick: (filterId: string) => void;
  onOpenClick?: (filterId: string) => void;
}

const variants = {
  dark: {
    bg: vars.primaryDark20,
    fg: vars.primaryFg,
    bd: vars.light55,
    active: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.light55,
    },
    hover: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.light55,
    },
  },
  light: {
    bg: vars.content,
    fg: vars.contentFg,
    bd: vars.dark05,
    active: {
      bg: vars.primaryDark20,
      fg: vars.primaryFg,
      bd: vars.transparent,
    },
    hover: {
      bg: vars.content,
      fg: vars.contentFg,
      bd: vars.dark25,
    },
  },
  subtle: {
    bg: vars.dark05,
    fg: vars.contentFg,
    bd: vars.dark05,
    active: {
      bg: vars.dark35,
      fg: vars.primaryFg,
      bd: vars.transparent,
    },
    hover: {
      bg: vars.content,
      fg: vars.contentFg,
      bd: vars.dark25,
    },
  },
};

export const FilterButton = observer(
  // eslint-disable-next-line react/display-name
  forwardRef((p: IProps, ref: Ref<HTMLDivElement>) => {
    const intl = useIntl();
    const theme = variants[p.variation ?? "light"];
    const hasSelectedValues = p.selectedValues.length > 0 || !!p.children;

    let value: string | JSX.Element = "";

    if (hasSelectedValues) {
      value = p.selectedValues.slice(0, 3).join(", ");
    }

    const valuePlus =
      p.selectedValues.length > 3 ? `+ ${p.selectedValues.length - 3}` : "";

    const handleClearClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      p.onClearClick(p.filterId);
    };

    const handleClick = () => {
      p.onOpenClick?.(p.filterId);
    };

    const label =
      typeof p.title === "string" ? p.title : intl.formatMessage(p.title);

    const tooltip = hasSelectedValues
      ? `${label}: ${p.selectedValues.join(", ")}`
      : label;

    return (
      <_wrap
        ref={ref}
        onClick={handleClick}
        selected={hasSelectedValues}
        variation={theme}
        disabled={!p.onOpenClick}
        error={p.error}
      >
        {p.filterId === "Entry Status" ? (
          <_statusColor
            color={
              ENTRY_STATUS_COLORS[
                (p.selectedValues[0] as EntryStatusName) ?? "NO-STATUS"
              ]
            }
          />
        ) : (
          <_label title={tooltip} isMultiline={p.isMultiline}>
            {!p.hideTitle && (
              <_title>
                {label}
                {p.error && (
                  <>
                    &nbsp;
                    <AsteriskIcon />
                  </>
                )}
              </_title>
            )}
            {value && !p.hideValue && (
              <_value isMultiline={p.isMultiline}>
                {value} {valuePlus}
              </_value>
            )}
          </_label>
        )}
        {p.children}
        {hasSelectedValues && (
          <_clear
            onClick={handleClearClick}
            title={intl.formatMessage(commonTexts.clear)}
          >
            <CrossIcon />
          </_clear>
        )}
      </_wrap>
    );
  })
);

const _wrap = styled.div.attrs({ role: "button" })<{
  selected: boolean;
  variation: IThemeVariation;
  disabled?: boolean;
  error?: boolean;
}>`
  background: ${(p) => (p.selected ? p.variation.active.bg : p.variation.bg)};
  color: ${(p) => (p.selected ? p.variation.active.fg : p.variation.fg)};

  border: 2px solid
    ${(p) =>
      p.error
        ? vars.danger
        : p.selected
        ? p.variation.active.bd
        : p.variation.bd};

  box-shadow: ${vars.shadow.z1};
  border-radius: 0.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  cursor: ${(p) => (p.disabled ? "inherit" : "pointer")};
`;

const _label = styled.div<{ isMultiline?: boolean }>`
  padding: 0.5rem 0.875rem;
  display: flex;
  flex-wrap: ${(p) => p.isMultiline && "wrap"};
  align-items: center;
  min-width: 0;

  :not(:only-child) {
    padding-right: 0;
  }
`;

const _statusColor = styled(StatusColor)`
  padding: 0 0.5rem;
  width: 2.5rem;
`;

const _title = styled.div`
  ${ellipsis()};
  :not(:only-child) {
    margin-right: 0.25rem;
    ::after {
      content: ":";
    }
  }
`;

const _value = styled.div<{ isMultiline?: boolean }>`
  ${(p) => !p.isMultiline && ellipsis()}
  word-break: break-all;
  align-items: center;
`;

const _clear = styled.div.attrs({ role: "button" })`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
`;
