import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";

interface Props {
  attention?: boolean;
  children: React.ReactNode;
}

export const Overlay = styled(motion.div).attrs({
  animate: { translateY: 0 },
  initial: { translateY: 100 },
  exit: { translateY: 100 },
  transition: {
    type: "spring",
    duration: 0.4,
    bounce: 0.25,
  },
})`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  left: 0;
  width: 100%;
  z-index: ${ZIndex.bucket};
  padding: 0 1rem;

  ${media("compact")} {
    padding: 0;
    bottom: 0;
  }

  // Passes through clicks on the wrapper, but not on its children
  pointer-events: none;
  > * {
    pointer-events: initial;
  }
`;

export const Wrap = styled.div<Props>`
  background: ${(p) => (p.attention ? vars.warning : vars.secondary)};
  border-radius: 4px;
  box-shadow: ${vars.shadow.z3};
  color: ${(p) => (p.attention ? vars.warningFg : vars.secondaryFg)};
  display: flex;
  align-items: center;
  padding: 0.75rem;
  gap: 1rem;
  max-width: 100%;
  min-height: 60px;

  ${media("compact")} {
    border-radius: 0;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 0.5rem;
  white-space: nowrap;
  font-size: 1rem;

  ${media("compact")} {
    flex: 1;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  ${media("compact")} {
    order: 3;
    flex: 1 0 100%;
  }

  ${media("compact", "touch")} {
    flex-wrap: nowrap;
    margin: 0 -1rem;
    padding: 0 1rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    > * {
      flex-shrink: 0;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 0.5rem;
`;
