import React from "react";
import styled from "styled-components";
import { useIntl, defineMessages } from "react-intl";
import { vars } from "@web/styles";
import { ProfileImage } from "@web/elements/ProfileImage";
import { Truncate } from "@web/elements";

export const CardCreatedBy = ({ children }: { children: string }) => {
  const intl = useIntl();
  const title = intl.formatMessage(texts.entryCreatedBy, { name: children });

  return (
    <_wrap>
      <ProfileImage name={children} size="small" aria-hidden />{" "}
      <_name title={title}>{children}</_name>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  > * {
    margin-right: 5px;
  }
`;

const _name = styled(Truncate)`
  color: ${vars.dark55};
  font-size: 0.8125rem;
`;

const texts = defineMessages({
  entryCreatedBy: {
    id: "entry.footer.createdby",
    defaultMessage: "Folder created by {name}",
  },
});
