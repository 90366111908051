import {
  observable,
  action,
  computed,
  IReactionDisposer,
  reaction,
  comparer,
  when,
} from "mobx";
import { Api } from "@web/api";
import {
  EntryModel,
  OutcomeEventType,
  PipelineCreateFields,
  PipelineModel,
  PipelineType,
  WritableFields,
} from "@web/models";
import {
  ConditionType,
  IConditionNode,
  IEventNode,
  IOutcomeNode,
  IRuleNode,
  ITagNode,
  ITargetNode,
  OutcomeType,
  TargetName,
} from "@web/api/Integration/types";

export class FlowStore {
  @observable
  pipelines: Record<PipelineType, PipelineModel[]> = {
    validation: [],
    notification: [],
    reminder: [],
  };

  @observable
  events: IEventNode[] = [];

  @observable
  isCreatingPipeline = false;

  @observable
  private checklistsWasLoaded = false;

  private targets?: ITargetNode[];

  private documentChecklistDisposer?: IReactionDisposer;
  private tagChecklistDisposer?: IReactionDisposer;
  private attributeChecklistDisposer?: IReactionDisposer;
  private checklistResultsDisposer?: IReactionDisposer;

  constructor(private api: Api) {}

  @computed
  get allTagConditions(): UUID[] {
    return this.pipelines.validation
      .filter((pipe) => pipe.isActive)
      .flatMap((pipe) => pipe.tagConditions.flatMap((cond) => cond.entryTagId));
  }

  async checklistsLoaded() {
    await when(() => this.checklistsWasLoaded);
  }

  @action
  clearChecklistResultsCache() {
    this.pipelines.validation.forEach((pipe) => {
      pipe.results.clear();
    });
  }

  private stopMonitoringEntry() {
    this.documentChecklistDisposer?.();
    this.tagChecklistDisposer?.();
    this.attributeChecklistDisposer?.();
    this.checklistResultsDisposer?.();
  }

  async monitorEntryChecklists(entry: EntryModel | undefined) {
    this.stopMonitoringEntry();
    if (!entry || entry.isDraft) {
      return;
    }

    await this.checklistsLoaded();
    entry.checklists?.forEach((ch) => ch.refreshResults(entry.uuid));

    this.documentChecklistDisposer = reaction(
      () => entry.documentCount,
      () => {
        entry.checklists
          ?.filter((ch) => ch.documentRules.length > 0)
          ?.forEach((ch) => ch.pollResults(entry.uuid));
      },
      { delay: 10 }
    );

    this.tagChecklistDisposer = reaction(
      () => entry.tags.allTagIds,
      () => {
        entry.checklists
          ?.filter((ch) => ch.classificationRules.length > 0)
          ?.forEach((ch) => ch.pollResults(entry.uuid));
      },
      { equals: comparer.structural, delay: 10, fireImmediately: true }
    );

    this.attributeChecklistDisposer = reaction(
      () => entry.attributeValues.allValues,
      () => {
        entry.checklists
          ?.filter((ch) => ch.attributeRules.length > 0)
          ?.forEach((ch) => ch.pollResults(entry.uuid));
      },
      { equals: comparer.structural, delay: 10 }
    );

    this.checklistResultsDisposer = reaction(
      () => entry.checklistsAreValid,
      () => {
        entry.resetDataPollerFrequency();
        entry.reloadChangelog();
      },
      { delay: 10 }
    );
  }

  getSupportedEvents(type: PipelineType, target: TargetName): IEventNode[] {
    // Only a subset of the events can be applied to different pipeline types/targets
    switch (type) {
      case "validation":
        return this.events.filter((event) =>
          ENTRY_CHEKLIST_EVENTS.includes(event.name)
        );
      case "reminder":
        return this.events.filter((event) =>
          REMINDER_EVENTS.includes(event.name)
        );
      case "notification":
        if (target === "Tag") {
          return this.events.filter((event) =>
            TAG_NOTIFICATION_EVENTS.includes(event.name)
          );
        }
        return this.events.filter((event) =>
          ENTRY_NOTIFICATION_EVENTS.includes(event.name)
        );
      default:
        return [];
    }
  }

  /**
   * Get all validation pipelines ("checklists") connected to a
   * specific entry by checking the pipleline condition(s).
   * @param entry the entry
   */
  getChecklistsForEntry(entry: EntryModel | undefined) {
    if (!entry) return undefined;

    return this.pipelines.validation.filter((pipe) =>
      pipe.entryMatchesConditions(entry)
    );
  }

  @action.bound
  async loadChecklists() {
    if (this.pipelines.validation.length > 0) {
      return;
    }
    try {
      const { data } = await this.api.getFlowPipelines("validation");
      this.pipelines.validation = data.data.map(
        (node) => new PipelineModel(this, node)
      );
      this.checklistsWasLoaded = true;
    } catch (e) {
      console.error("Failed to load flow checklists", e);
    }
  }

  @action.bound
  async loadNotifications() {
    if (this.pipelines.notification.length > 0) {
      return;
    }
    try {
      const { data } = await this.api.getFlowPipelines("notification");
      this.pipelines.notification = data.data.map(
        (node) => new PipelineModel(this, node)
      );
    } catch (e) {
      console.error("Failed to load flow notifications", e);
    }
  }

  @action.bound
  async loadReminders() {
    if (this.pipelines.reminder.length > 0) {
      return;
    }
    try {
      const { data } = await this.api.getFlowPipelines("reminder");
      this.pipelines.reminder = data.data.map(
        (node) => new PipelineModel(this, node)
      );
    } catch (e) {
      console.error("Failed to load flow reminders", e);
    }
  }

  @action.bound
  async loadEvents() {
    if (this.events.length > 0) {
      return;
    }
    try {
      const { data } = await this.api.getFlowEvents();
      this.events = data.data;
    } catch (e) {
      console.error("Failed to load flow events", e);
    }
  }

  @action.bound
  async addPipeline(fields: PipelineCreateFields) {
    try {
      this.isCreatingPipeline = true;
      if (!this.targets) {
        // We need the target id to create a pipeline
        const { data } = await this.api.getFlowTargets();
        this.targets = data.data;
      }

      const targetId = this.targets.find(
        (target) => target.name === fields.targetName
      )?.id;

      if (!targetId) {
        console.error("Failed to retrieve targets when creating pipeline");
        return;
      }

      const pipelineType = fields.pipelineType || "validation";
      const { data } = await this.api.createPipeline(
        fields,
        this.getSupportedEvents(pipelineType, fields.targetName).map(
          (e) => e.id
        ),
        targetId,
        DEFAULT_SUCCESS_OUTCOME[pipelineType]
      );
      const newPipeline = new PipelineModel(this, data.data);
      this.pipelines[pipelineType].push(newPipeline);
      return newPipeline;
    } catch (e) {
      console.error("Failed to create flow pipeline", e);
    } finally {
      this.isCreatingPipeline = false;
    }
  }

  async updatePipeline(
    pipeline: PipelineModel,
    fields: WritableFields<PipelineModel>
  ) {
    try {
      pipeline.setSavingStatus("details", true);
      await this.api.updatePipeline(pipeline.uuid, fields);
      Object.assign(pipeline, fields);
    } catch (e) {
      console.error("Failed to update flow pipeline", e);
    } finally {
      pipeline.setSavingStatus("details", false);
    }
  }

  async updatePipelineEvents(
    pipeline: PipelineModel,
    eventId: UUID,
    selected: boolean
  ) {
    try {
      pipeline.setSavingStatus("events", true);
      const { data } = await this.api.updatePipelineEvents(
        pipeline.uuid,
        eventId,
        selected
      );
      pipeline.events = data.data.events;
    } catch (e) {
      console.error("Failed to update flow pipeline events", e);
    } finally {
      pipeline.setSavingStatus("events", false);
    }
  }

  async deletePipeline(pipelineId: UUID, type: PipelineType = "validation") {
    try {
      await this.api.deletePipeline(pipelineId);
      this.pipelines[type] = this.pipelines[type].filter(
        (pipe) => pipe.uuid !== pipelineId
      );
    } catch (e) {
      console.error("Failed to delete flow pipeline", e);
    } finally {
    }
  }

  async addRule(pipeline: PipelineModel, fields: WritableFields<IRuleNode>) {
    try {
      if (!fields.type) {
        console.warn("Rule type is required to add flow rule.");
        return;
      }

      pipeline.setSavingStatus("rules", true);

      const { data } = await this.api.createRule(
        pipeline.uuid,
        fields.type,
        fields
      );
      pipeline.rules.unshift(data.data);
      if (pipeline.ruleLoadingStatus.total !== undefined) {
        pipeline.ruleLoadingStatus.total++;
      }
    } catch (e) {
      console.error("Failed to add flow rule", e);
    } finally {
      pipeline.setSavingStatus("rules", false);
    }
  }

  async deleteRule(pipeline: PipelineModel, ruleId: UUID) {
    try {
      pipeline.setSavingStatus("rules", true);
      await this.api.deleteRule(ruleId);
      pipeline.rules = pipeline.rules.filter((rule) => rule.id !== ruleId);
      if (pipeline.ruleLoadingStatus.total) {
        pipeline.ruleLoadingStatus.total--;
      }
    } catch (e) {
      console.error("Failed to delete flow rule", e);
    } finally {
      pipeline.setSavingStatus("rules", false);
    }
  }

  async updateRule(
    pipeline: PipelineModel,
    rule: IRuleNode,
    fields: WritableFields<IRuleNode>
  ) {
    try {
      pipeline.setSavingStatus("rules", true);
      await this.api.updateRule(rule.id, rule.type, fields);
      Object.assign(rule, fields);
    } catch (e) {
      console.error("Failed to update flow rule", e);
    } finally {
      pipeline.setSavingStatus("rules", false);
    }
  }

  async addCondition(
    pipeline: PipelineModel,
    type: ConditionType,
    fields: WritableFields<IConditionNode>
  ) {
    try {
      pipeline.setSavingStatus("conditions", true);
      const { data } = await this.api.createCondition(
        pipeline.uuid,
        type,
        fields
      );
      pipeline.conditions.push(data.data);
    } catch (e) {
      console.error("Failed to add flow condition", e);
    } finally {
      pipeline.setSavingStatus("conditions", false);
    }
  }

  async deleteCondition(pipeline: PipelineModel, conditionId: UUID) {
    try {
      pipeline.setSavingStatus("conditions", true);
      await this.api.deleteCondition(conditionId);
      pipeline.conditions = pipeline.conditions.filter(
        (condition) => condition.id !== conditionId
      );
    } catch (e) {
      console.error("Failed to delete flow condition", e);
    } finally {
      pipeline.setSavingStatus("conditions", false);
    }
  }

  async createOutcome(
    pipeline: PipelineModel,
    type: OutcomeType,
    eventType: OutcomeEventType,
    fields: WritableFields<IOutcomeNode>
  ) {
    try {
      pipeline.setSavingStatus("outcomes", true);
      const { data } = await this.api.createOutcome(
        pipeline.uuid,
        type,
        eventType,
        fields
      );
      if (eventType === "pipelineSuccess") {
        pipeline.successOutcomes.push(data.data);
      } else {
        pipeline.failureOutcomes.push(data.data);
      }
    } catch (e) {
      console.error("Failed to add flow outcome", e);
    } finally {
      pipeline.setSavingStatus("outcomes", false);
    }
  }

  async updateOutcome(
    pipeline: PipelineModel,
    outcome: IOutcomeNode,
    fields: WritableFields<IOutcomeNode>
  ) {
    try {
      pipeline.setSavingStatus("outcomes", true);
      await this.api.updateOutcome(outcome.id, outcome.type, fields);
      Object.assign(outcome, fields);
    } catch (e) {
      console.error("Failed to update flow outcome", e);
    } finally {
      pipeline.setSavingStatus("outcomes", false);
    }
  }

  async deleteOutcome(pipeline: PipelineModel, outcomeId: UUID) {
    try {
      pipeline.setSavingStatus("outcomes", true);
      await this.api.deleteOutcome(outcomeId);
      pipeline.successOutcomes = pipeline.successOutcomes.filter(
        (outcome) => outcome.id !== outcomeId
      );
      pipeline.failureOutcomes = pipeline.failureOutcomes.filter(
        (outcome) => outcome.id !== outcomeId
      );
    } catch (e) {
      console.error("Failed to delete flow outcome", e);
    } finally {
      pipeline.setSavingStatus("outcomes", false);
    }
  }

  /**
   * Load results for the latest pipeline execution for an entry.
   * Used via the Pipeline object.
   * @param pipelineId the pipeline id
   * @param entryId the entry id
   */
  loadResultsForEntry(pipelineId: UUID, entryId: UUID) {
    return this.api.getFlowResults(pipelineId, entryId);
  }

  loadRulesForPipeline(pipelineId: UUID) {
    return this.api.getFlowPipelineRules(pipelineId);
  }

  @action.bound
  async findTagsWithAttributeDefinitions(tagIds: UUID[]): Promise<ITagNode[]> {
    try {
      const { data } = await this.api.findTagsWithAttributeDefinitions(tagIds);
      return data.data;
    } catch (e) {
      console.error("Failed to find tags", e);
      return [];
    }
  }
}

const ENTRY_CHEKLIST_EVENTS = [
  "Document Create",
  "Document Update",
  "Document Delete",
  "Document Version Create",
  "Document Version Update",
  "Document Version Delete",
  "Entry Create",
  "Entry Update",
  "Entry Tag Change",
];

const ENTRY_NOTIFICATION_EVENTS = [
  "Entry Create",
  "Entry Tag Change",
  "Entry Status Change",
];

const TAG_NOTIFICATION_EVENTS = [
  "Tag Create",
  "Tag Status Change",
  "Entry Tag Change",
];

const REMINDER_EVENTS = ["Reminder Due"];

const DEFAULT_SUCCESS_OUTCOME: Record<
  PipelineType,
  Partial<IOutcomeNode> | undefined
> = {
  validation: {
    type: "AddEntryComment",
    name: "Comment Success Outcome",
    description: "Default success outcome",
    entryComment: "This folder has been approved!",
  },
  reminder: undefined,
  notification: undefined,
};
