import React from "react";

export const AddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C13 2.44772 12.5523 2 12 2V2C11.4477 2 11 2.44772 11 3V10C11 10.5523 10.5523 11 10 11H3C2.44772 11 2 11.4477 2 12V12C2 12.5523 2.44772 13 3 13H10C10.5523 13 11 13.4477 11 14V21C11 21.5523 11.4477 22 12 22V22C12.5523 22 13 21.5523 13 21V14C13 13.4477 13.4477 13 14 13H21C21.5523 13 22 12.5523 22 12V12C22 11.4477 21.5523 11 21 11H14C13.4477 11 13 10.5523 13 10V3Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

AddIcon.displayName = "AddIcon";
