import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import {
  observable,
  action,
  computed,
  when,
  reaction,
  comparer,
  IReactionDisposer,
  ObservableMap,
} from "mobx";
import { defineMessages } from "react-intl";
import axios from "axios";
import {
  IDocumentVersionNode,
  IEntryNode,
  UUID,
} from "@web/api/Integration/types";
import {
  WritableFields,
  EntryModel,
  createDraftEntry,
  SectionModel,
  EntryStatusModel,
  parseEntryStatus,
} from "@web/models";
import {
  parseAndGroupTagResponse,
  parseChangelogResponse,
} from "@web/stores/ResponseParser";
import { Api, getRequestError } from "@web/api";
import { commonTexts } from "@web/translations";
import { EntryPollingFlags } from "@web/api/BFF/types";
import { AppConfig } from "@config/index";
import commonStoreTexts from "./texts";
import PubSub, {
  Subscriber,
  DeleteEvent,
  AddEvent,
  AddEntryEventContext,
  LinkEvent,
} from "./PubSub";
import { RootStore } from ".";
import { EntryCreator } from "@web/components/Upload/creators/types";
import { BULK_CREATE_BATCH_SIZE } from "@web/stores/constants";

const DOCUMENTS_PAGE_SIZE = 50;
const ALL_TAGS_PAGE_SIZE = 1000;

type EntryUUID = UUID;

export class RecordStore implements Subscriber {
  entryCache = new ObservableMap<EntryUUID, EntryModel>();

  @observable loading = false;
  @observable error?: Error;
  @observable entry?: EntryModel;
  @observable entryStatuses: EntryStatusModel[] = [];

  private changelogDisposer?: IReactionDisposer;

  constructor(
    protected api: Api,
    public rootStore: RootStore,
    protected appConfig: AppConfig
  ) {
    reaction(
      () => this.entry,
      (entry) => {
        entry?.prepareUiState();
        this.rootStore.flowStore.monitorEntryChecklists(entry);
      }
    );

    PubSub.getInstance().subscribe(this);
  }

  async entryLoaded() {
    await when(() => this.entry !== undefined);
    this.error = undefined;
  }

  @computed
  get hasEntry() {
    return this.entry !== undefined;
  }

  @computed
  get currentEntryId() {
    return this.entry?.id;
  }

  /**
   * Return EntryModel object from server data.
   */
  entryFromJson = (json: IEntryNode) => {
    const existing = this.entryCache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    }

    const entry = new EntryModel(this, json);
    this.entryCache.set(entry.uuid, entry);
    return entry;
  };

  /**
   * Return EntryModel object from server data.
   */
  entriesFromJson = (json: IEntryNode[]) => {
    const entryModels: EntryModel[] = [];

    json.forEach((entry) => {
      const existing = this.entryCache.get(entry.id);
      if (existing) {
        existing.updateFromJson(entry);
        return existing;
      }

      const entryModel = new EntryModel(this, entry);
      this.entryCache.set(entryModel.uuid, entryModel);

      entryModels.push(entryModel);
    });

    return entryModels;
  };

  /**
   * Set current (visible) entry from existing result page data
   * and load additional data needed for the card view.
   */
  @action.bound
  setEntry(entry: EntryModel) {
    if (this.entry?.id === entry.id) {
      return;
    }

    this.changelogDisposer?.();
    this.entry?.resetUiState();
    this.entry = entry;

    entry.loadReminders(true);
    entry.loadRemainingTags();
    entry.attributeValues.loadValues();
    entry.reloadLinks();

    this.changelogDisposer = reaction(
      () => [
        entry.title,
        entry.entryStatus,
        entry.documentCount,
        entry.tags.allTagIds,
        entry.attributeValues.allValues,
      ],
      () => entry.reloadChangelog(),
      // 'delay' means throttling in practice
      { equals: comparer.structural, delay: 100, fireImmediately: true }
    );
  }

  @action.bound
  clearEntry() {
    this.changelogDisposer?.();
    this.entry?.resetUiState();
    this.entry = undefined;
  }

  // An entry was deleted, clean it from the client memory
  @action.bound
  removeEntry(entry: EntryModel) {
    const { documentStore, attributeStore, reminderStore } = this.rootStore;
    if (this.entry?.uuid === entry.uuid) {
      // The deleted entry is currently visible,
      // clean up UI state before proceeding.
      this.clearEntry();
    }

    this.entryCache.delete(entry.uuid);
    entry.documents.forEach(documentStore.removeDocument);
    entry.currentReminders.forEach(reminderStore.removeReminder);
    attributeStore.valueCache.delete(entry.uuid);
    entry.destroy();
  }

  @action.bound
  startDraftEntry(
    fields: Pick<EntryModel, "sectionUuid" | "sectionId" | "tags">
  ): EntryModel {
    this.clearEntry();
    this.entry = createDraftEntry(this, fields);
    return this.entry;
  }

  @action.bound
  async saveEntryTags(entry: EntryModel, classificationId: UUID) {
    if (!entry.tags.hasChanges) {
      return;
    }

    if (entry.isDraft) {
      entry.tags.applyUnsavedChanges(classificationId);
      await this.saveDraftEntry();
      return;
    }

    try {
      await this.api.updateEntryTags(entry.uuid, entry.tags.unsavedChanges);
      entry.tags.applyUnsavedChanges(classificationId);
      entry.attributeValues.purgeValues(entry.tags.allAttributeIds);
      entry.checklists?.forEach((cl) => cl.pollResults(entry.uuid));

      const messageText = entry.title
        ? commonStoreTexts.updatedWithTitle
        : texts.updateCompleteTextWithoutTitle;

      this.rootStore.messageStore.addMessage({
        type: "success",
        title: commonStoreTexts.updateComplete,
        text: messageText,
        values: {
          title: entry.title ?? "",
        },
      });
    } catch (e) {
      console.error("RecordStore.saveEntryTags()", e);
      entry.tags.clearUnsavedChanges();
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.updateTagsFailed,
        action: {
          title: commonTexts.retry,
          onClick: () => this.saveEntryTags(entry, classificationId),
        },
      });
    }
  }

  @action.bound
  async saveEntryStatus(
    entry: EntryModel,
    status: EntryStatusModel | undefined,
    comment?: string
  ) {
    if (entry.isDraft) {
      entry = await this.saveDraftEntry();
    }

    const { messageStore } = this.rootStore;

    try {
      await this.api.updateEntryStatus(entry.uuid, status?.uuid);

      if (!!comment && !isEmpty(comment)) {
        await this.api.createComment(comment, entry.uuid);
      }

      entry.updateState({ entryStatus: status?.name || "NO-STATUS" });

      messageStore.addMessage({
        type: "success",
        title: texts.updateCompleteTextWithoutTitle,
      });
    } catch (error) {
      console.error("RecordStore.saveEntryStatus()", error);

      messageStore.addMessage({
        type: "failure",
        title: texts.updateFailedTextWithoutTitle,
      });
    }
  }

  /**
   * Create a new entry on the server.
   *
   * @param entries
   * @param eventContext the context of this entry event, eg. "draft-entry"
   */
  @action.bound
  async createEntries(
    entries: EntryCreator[],
    eventContext?: AddEntryEventContext
  ): Promise<EntryModel[]> {
    let entryModels: EntryModel[] = [];
    const chunkSize = BULK_CREATE_BATCH_SIZE;

    for (let i = 0; i < entries.length; i += chunkSize) {
      const chunk = entries.slice(i, i + chunkSize);

      try {
        const { data } = await this.api.createEntries(chunk);
        entryModels = entryModels.concat(this.entriesFromJson(data.data));
      } catch (e) {
        // Mute the error so we process all chunks. Missing entry models will be marked as failed.
      }
    }

    if (eventContext === "draft-entry" && entries.length === 1) {
      this.setEntry(entryModels[0]);
    }
    entryModels.forEach((entryModel) => {
      PubSub.getInstance().notifyAdd({
        type: "Entry",
        data: entryModel,
        context: eventContext,
      });
    });

    return entryModels;
  }

  /**
   * Create a new entry on the server.
   *
   * @param fields fields to save
   * @param eventContext the context of this entry event, eg. "draft-entry"
   */
  @action.bound
  async createEntry(
    fields: {
      sectionId: UUID;
      tags: UUID[];
      title?: string;
      externalIds?: string[];
      createdDate?: string;
      isSingleDocumentEntry?: boolean;
    },
    eventContext?: AddEntryEventContext
  ): Promise<EntryModel> {
    const { data } = await this.api.createEntry(
      fields.sectionId,
      fields.tags,
      fields.title,
      fields.externalIds,
      fields.createdDate,
      fields.isSingleDocumentEntry
    );

    const entry = this.entryFromJson(data.data);

    if (eventContext === "draft-entry") {
      this.setEntry(entry);
    }

    PubSub.getInstance().notifyAdd({
      type: "Entry",
      data: entry,
      context: eventContext,
    });

    return entry;
  }

  @action.bound
  async saveDraftEntry() {
    if (!this.entry?.isDraft) {
      return Promise.reject(
        new Error("Could not save draft entry: no draft entry exists.")
      );
    }
    const { sectionUuid, tags, title } = this.entry;

    try {
      const entry = await this.createEntry(
        {
          sectionId: sectionUuid,
          tags: tags.tagIdsForNewEntry,
          title,
          isSingleDocumentEntry: false,
        },
        "draft-entry"
      );

      this.rootStore.messageStore.addMessage({
        type: "success",
        title: texts.createdEntry,
      });

      return entry;
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.createdEntryFailed,
        text: getRequestError(error)?.message,
      });
      return Promise.reject(new Error("Could not save draft entry: " + error));
    }
  }

  @action.bound
  async saveEntry(entry: EntryModel, fields: WritableFields<EntryModel>) {
    if (entry.isDraft) {
      entry.updateState(fields);
      await this.saveDraftEntry();
      return;
    }

    const { messageStore } = this.rootStore;

    // Save updates to server
    let requestCompleted = false;

    const messageId = this.rootStore.messageStore.createMessage(
      {
        type: "updating",
        title: texts.updatingEntry,
      },
      2000,
      () => !requestCompleted
    );

    try {
      const { data } = await this.api.updateEntry(entry.uuid, fields);
      entry.updateFromJson(data.data);

      const messageText = entry.title
        ? commonStoreTexts.updatedWithTitle
        : texts.updateCompleteTextWithoutTitle;

      messageStore.updateMessage(messageId, {
        type: "success",
        title: commonStoreTexts.updateComplete,
        text: messageText,
        values: {
          title: entry.title ?? "",
        },
      });
    } catch (error) {
      console.error("RecordStore.saveEntry()", error);

      const messageText = entry.title
        ? commonStoreTexts.updatedFailedWithTitle
        : texts.updateFailedTextWithoutTitle;

      messageStore.updateMessage(messageId, {
        type: "failure",
        text: messageText,
        values: {
          title: entry.title ?? "",
        },
        action: {
          title: commonTexts.retry,
          onClick: () => this.saveEntry(entry, fields),
        },
      });
    } finally {
      requestCompleted = true;
    }
  }

  reloadEntry = async (entry: EntryModel) => {
    try {
      const entryAsJson = await this.api.getEntryByInternalId(
        entry.id,
        DOCUMENTS_PAGE_SIZE
      );

      entry.updateFromJson(entryAsJson);
    } catch (error) {
      this.handleError(getRequestError(error));
    }
  };

  pollForEntryUpdates = async (entry: EntryModel) => {
    try {
      const flags: EntryPollingFlags[] = [];
      if (entry.isProcessingDocuments) {
        flags.push("includeDataServicesStatus");
      }
      const { data } = await this.api.getPollableEntryByUuid(entry.uuid, flags);
      entry.handlePollingUpdate(data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        if (status && [403, 404].includes(status)) {
          entry.stopDataPoller();
        }
      }
      this.handleError(getRequestError(error));
    }
  };

  @action.bound
  async loadEntry(entryInternalId: number) {
    this.loading = true;

    try {
      const entryAsJson = await this.api.getEntryByInternalId(
        entryInternalId,
        DOCUMENTS_PAGE_SIZE
      );
      const entry = this.entryFromJson(entryAsJson);

      this.setEntry(entry);
    } catch (error) {
      this.error = getRequestError(error);
    } finally {
      this.loading = false;
    }
  }

  async loadNextPageOfDocuments(entry: EntryModel) {
    entry.documentsLoadingInfo.pageLoading = true;

    try {
      const res = await this.api.getEntryDocuments(
        entry.id,
        entry.documentsLoadingInfo.lastPageLoaded + 1,
        DOCUMENTS_PAGE_SIZE
      );
      entry.addDocumentsFromJson(res.data);
    } catch (e) {
      console.error("Error while loading entry documents:", e);

      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.deleteEntryFailed,
      });
    } finally {
      entry.documentsLoadingInfo.pageLoading = false;
    }
  }

  @action.bound
  async loadEntryStatuses() {
    if (this.entryStatuses.length > 0) {
      return;
    }
    try {
      const { data } = await this.api.getEntryStatuses();
      this.entryStatuses = compact(data.data.map(parseEntryStatus));
    } catch (e) {
      console.error("Failed to load entry statuses", e);
    }
  }

  loadRemainingTags: (entry: EntryModel, pageNumber?: number) => Promise<void> =
    async (entry, page = 1) => {
      entry.tags.loadingInfo.pageLoading = true;

      try {
        const { data } = await this.api.findTagsByEntry(
          entry.uuid,
          page,
          ALL_TAGS_PAGE_SIZE
        );
        entry.tags.loadingInfo.hasMore = data.hasMore;
        entry.tags.loadingInfo.lastPageLoaded = data.page;

        const nextSelectedTags = parseAndGroupTagResponse(
          data.data,
          this.rootStore.attributeStore
        );

        entry.tags.merge(nextSelectedTags);
      } catch (error) {
        console.error(
          `Error while loading remaining tags for Entry #${entry.id}:`,
          error
        );
      } finally {
        entry.tags.loadingInfo.pageLoading = false;
      }

      if (entry.tags.loadingInfo.hasMore) {
        return this.loadRemainingTags(entry, page + 1);
      }
    };

  async loadReminders(entry: EntryModel) {
    const { reminderStore } = this.rootStore;

    if (entry.reminders.status === "NOT_REQUESTED") {
      entry.updateState({
        reminders: { status: "LOADING" },
      });
    }

    try {
      const { data } = await this.api.getEntryReminders(entry.uuid);
      const reminders = data.data.map(reminderStore.reminderFromJson);

      entry.updateState({
        reminders: {
          status: "SUCCESS",
          result: reminders,
        },
      });
    } catch (error) {
      entry.updateState({
        reminders: { status: "ERROR", error: getRequestError(error) },
      });
    }
  }

  @action.bound
  async loadAttributes(entry: EntryModel) {
    entry?.attributeValues.loadValues();
  }

  @action.bound
  async loadChangelogEvents(entry: EntryModel) {
    // Postpone displaying loading status when response it taking longer than expected
    // and avoid causing high blood pressure for our end-users when being presented
    // with loading indicators (yes, research has proved that to be the case)
    const loadingTimer = setTimeout(() => {
      if (entry.changelog.status === "NOT_REQUESTED") {
        entry.changelog = { status: "LOADING" };
      }
    }, 1000);

    try {
      const response = await this.api.getEntryChangelog(entry.uuid);
      const result = parseChangelogResponse(
        response.data,
        this.rootStore.commentStore
      );

      entry.changelog = {
        status: "SUCCESS",
        result,
      };
    } catch (error) {
      console.error(`Entry #${entry.uuid} events update failed!`, error);

      if (entry.changelog.status !== "SUCCESS") {
        entry.changelog = { status: "ERROR", error: getRequestError(error) };
      }
    } finally {
      clearTimeout(loadingTimer);
    }
  }

  @action.bound
  async loadFromLinks(entry: EntryModel) {
    // Postpone displaying loading status when response is taking longer than expected
    // and avoid causing high blood pressure for our end-users when being presented
    // with loading indicators (yes, research has proved that to be the case)
    const loadingTimer = setTimeout(() => {
      if (entry.fromLinks.status === "NOT_REQUESTED") {
        entry.fromLinks = { status: "LOADING" };
      }
    }, 1000);

    try {
      const linkedEntries = await this.api.getLinksFromEntries(entry.uuid);

      entry.fromLinks = {
        status: "SUCCESS",
        result: linkedEntries,
      };
    } catch (error) {
      console.error(`Entry #${entry.uuid} events update failed!`, error);

      if (entry.fromLinks.status !== "SUCCESS") {
        entry.fromLinks = { status: "ERROR", error: getRequestError(error) };
      }
    } finally {
      clearTimeout(loadingTimer);
    }
  }

  @action.bound
  async loadToLinks(entry: EntryModel) {
    // Postpone displaying loading status when response is taking longer than expected
    // and avoid causing high blood pressure for our end-users when being presented
    // with loading indicators (yes, research has proved that to be the case)
    const loadingTimer = setTimeout(() => {
      if (entry.toLinks.status === "NOT_REQUESTED") {
        entry.toLinks = { status: "LOADING" };
      }
    }, 1000);

    try {
      const linkedEntries = await this.api.getLinksToEntries(entry.uuid);

      entry.toLinks = {
        status: "SUCCESS",
        result: linkedEntries,
      };
    } catch (error) {
      console.error(`Entry #${entry.uuid} events update failed!`, error);

      if (entry.toLinks.status !== "SUCCESS") {
        entry.toLinks = { status: "ERROR", error: getRequestError(error) };
      }
    } finally {
      clearTimeout(loadingTimer);
    }
  }

  @action.bound
  async findEntriesToLink(searchTerm: string, sourceEntry: UUID) {
    try {
      const linkedEntries = await this.api.findEntriesToLink(
        searchTerm,
        sourceEntry
      );

      return linkedEntries.data.data;
    } catch (error) {
      console.error(`Failed to search for entries`, error);
    }
  }

  async deleteEntry(entry: EntryModel) {
    const { messageStore } = this.rootStore;

    let requestCompleted = false;
    const messageId = messageStore.createMessage(
      {
        type: "updating",
        title: texts.deletingEntry,
      },
      500,
      () => !requestCompleted
    );

    try {
      await this.api.deleteEntry(entry.uuid);

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deleteEntrySuccess,
      });

      this.removeEntry(entry);

      PubSub.getInstance().notifyDelete({ type: "Entry", uuid: entry.uuid });
    } catch (error) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.deleteEntryFailed,
      });
    } finally {
      requestCompleted = true;
    }
  }

  async moveToSection(
    entry: EntryModel,
    newSection: SectionModel
  ): Promise<void> {
    const messageId = this.rootStore.messageStore.addMessage({
      type: "updating",
      title: commonStoreTexts.movingSection,
      values: {
        count: 1,
      },
    });

    try {
      // Using the "batch" API, as opposed the ordinary Entry API endpoints to change the section,
      // because it handles the logic of removing non-existing tags in the destination section for us
      await this.api.batch({
        ids: [entry.uuid],
        type: "Entry",
        action: "move",
        parentId: newSection.uuid,
        parentType: "Section",
      });

      entry.sectionId = newSection.id;
      entry.sectionUuid = newSection.uuid;

      PubSub.getInstance().notifyUpdate({
        type: "Entry",
        data: entry,
        fields: ["sectionId", "sectionUuid"],
      });

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: commonStoreTexts.movingSectionSuccessTitle,
        text: commonStoreTexts.movingSectionSuccessText,
        values: {
          count: 1,
        },
      });
    } catch (err) {
      console.error(`Error moving entry to different Section:`, err);

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "failure",
        title: commonStoreTexts.movingSectionFailure,
        values: {
          count: 1,
        },
      });

      throw err;
    }
  }

  async moveMultipleToSection(
    entries: EntryModel[],
    newSection: SectionModel
  ): Promise<void> {
    try {
      await this.api.batch({
        ids: entries.map((entry) => entry.uuid),
        type: "Entry",
        action: "move",
        parentId: newSection.uuid,
        parentType: "Section",
      });

      entries.forEach((entry) => {
        entry.sectionId = newSection.id;
        entry.sectionUuid = newSection.uuid;
        entry.sectionTitle = newSection.title;

        PubSub.getInstance().notifyUpdate({
          type: "Entry",
          data: entry,
          fields: ["sectionId", "sectionUuid"],
        });
      });
    } catch (err) {
      console.error(`Error moving entries to different Section:`, err);
      throw err;
    }
  }

  async linkEntry(sourceEntry: UUID, targetEntry: UUID) {
    const { messageStore } = this.rootStore;

    let requestCompleted = false;
    const messageId = messageStore.createMessage(
      {
        type: "updating",
        title: texts.linkingEntry,
      },
      500,
      () => !requestCompleted
    );

    try {
      await this.api.linkEntry(sourceEntry, targetEntry);

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.linkEntrySuccess,
      });

      PubSub.getInstance().notifyLinkEvent({
        type: "Add",
        sourceEntry,
        targetEntries: [targetEntry],
      });
    } catch (error) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.linkEntryFailed,
      });
    } finally {
      requestCompleted = true;
    }
  }

  async unlinkEntries(
    sourceEntry: UUID,
    linksToEntries: UUID[],
    linksFromEntries: UUID[]
  ) {
    const { messageStore } = this.rootStore;

    let requestCompleted = false;
    const messageId = messageStore.createMessage(
      {
        type: "updating",
        title: texts.unlinkingEntry,
      },
      500,
      () => !requestCompleted
    );

    try {
      await this.api.unlinkEntries(
        sourceEntry,
        linksToEntries,
        linksFromEntries
      );

      messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.unlinkEntrySuccess,
      });

      PubSub.getInstance().notifyLinkEvent({
        type: "Remove",
        sourceEntry,
        targetEntries: [...linksToEntries, ...linksFromEntries],
      });
    } catch (error) {
      messageStore.updateMessage(messageId, {
        type: "failure",
        title: texts.unlinkEntryFailed,
      });
    } finally {
      requestCompleted = true;
    }
  }

  handleError(error: Error) {
    console.error("RecordStore.handleError()", error);
    this.loading = false;

    this.rootStore.messageStore.addMessage({
      type: "failure",
      title: commonStoreTexts.somethingWentWrong,
      text: error.message,
    });
  }

  /** Subscriber interface */
  onDataAdded(event: AddEvent) {
    switch (event.type) {
      case "Document":
        const entry = this.entryCache.get(event.data.entryUuid);
        entry?.addDocument(event.data);

        if (this.entry?.uuid === event.data.entryUuid) {
          this.entry.processingStatus = "restart";
          this.entry.resetDataPollerFrequency();
        }
        break;
      case "Comment":
        this.entry?.reloadChangelog();
        break;
    }
  }

  onDataDeleted(deletedEvent: DeleteEvent) {
    switch (deletedEvent.type) {
      case "Document":
        const entry = this.entryCache.get(deletedEvent.entryUuid);
        entry?.removeDocument(deletedEvent.uuid);
        break;
      case "Comment":
        const isCommentRelatedToAnEvent =
          this.entry?.currentChangelogEvents.some(
            (event) =>
              (event.type === "Comment" &&
                "systemId" in event &&
                event.systemId === deletedEvent.uuid) ||
              event.relatedComment?.systemId === deletedEvent.uuid // event.relatedComment? is for the comments attached to the event - ED July 2021
          );

        if (isCommentRelatedToAnEvent && this.entry) {
          this.entry.reloadChangelog();
        }
        break;
    }
  }

  onLinkAddedOrRemoved(event: LinkEvent) {
    this.entry?.reloadLinks();
  }
}

const texts = defineMessages({
  deletingEntry: {
    id: "entry.message.deleteentry.deleting",
    defaultMessage: "Deleting folder…",
  },
  deleteEntrySuccess: {
    id: "entry.message.deleteentry.success",
    defaultMessage: "Folder deleted",
  },
  deleteEntryFailed: {
    id: "entry.message.deleteentry.failed",
    defaultMessage: "Could not delete folder",
  },
  updatingEntry: {
    id: "entry.message.updating",
    defaultMessage: "Updating folder",
  },
  createdEntry: {
    id: "entry.message.create",
    defaultMessage: "New folder added",
  },
  createdEntryFailed: {
    id: "entry.message.create.failed",
    defaultMessage: "Failed to add folder",
  },
  updateCompleteTextWithoutTitle: {
    id: "entry.message.updatecomplete.textwithouttitle",
    defaultMessage: "Updated folder",
  },
  updateFailedTextWithoutTitle: {
    id: "entry.message.updatefailed.textwithouttitle",
    defaultMessage: "Failed to update folder",
  },
  updateTagsFailed: {
    id: "entry.message.updatetags.failed",
    defaultMessage: "Failed to update tags",
  },
  linkingEntry: {
    id: "entry.message.entry.linking",
    defaultMessage: "Linking folder...",
  },
  unlinkingEntry: {
    id: "entry.message.entry.unlinking",
    defaultMessage: "Removing link(s)...",
  },
  linkEntrySuccess: {
    id: "entry.message.entry.link.success",
    defaultMessage: "Folder linked",
  },
  unlinkEntrySuccess: {
    id: "entry.message.entry.unlink.success",
    defaultMessage: "Link(s) removed",
  },
  linkEntryFailed: {
    id: "entry.message.entry.link.failed",
    defaultMessage: "Could not link folder",
  },
  unlinkEntryFailed: {
    id: "entry.message.entry.unlink.failed",
    defaultMessage: "Could not remove link(s) to folder",
  },
});
