import React, { FC, useContext } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages } from "react-intl";
import { useStores } from "@web/stores/context";
import { vars } from "@web/styles";
import {
  ClassificationRowTitle,
  ObjectPermissionsMatrix,
  RowTitle,
  SectionRowTitle,
  TagMatrixHeader,
  TagRowTitle,
} from "@web/components/AccessControl/ObjectPermissionsMatrix";
import { AccessGroupMatrix } from "@web/components/AccessControl/AccessGroupMatrix";
import { useTabPanels } from "@web/utils/hooks/useTabPanels";
import { Button } from "@web/elements/Button";
import { CreateAccessGroupButton } from "@web/components/AccessControl/CreateAccessGroupButton";
import { ErrorBanner } from "@web/components/Error/ErrorBanner";
import { GlobalConfig } from "@config/context";

type TabPanels =
  | "groups"
  | "sections"
  | "classifications-tags"
  | "flows"
  | "lists";
const INITIAL_OBJECT_PANEL = "groups";

export const AccessControlContainer: FC = observer(() => {
  const { accessControlStore } = useStores();
  const { canManageAccessGroups } = useContext(GlobalConfig);
  const { getTabProps, getPanelProps, currentPanel } =
    useTabPanels<TabPanels>(INITIAL_OBJECT_PANEL);

  if (!canManageAccessGroups) {
    return <ErrorBanner type="noAccess" />;
  }

  return (
    <_wrap>
      <_tabBar>
        <_tabItem text={texts.accessGroups} {...getTabProps("groups")} />
        <_tabItem text={texts.sections} {...getTabProps("sections")} />
        <_tabItem
          text={texts.classificationsAndTags}
          {...getTabProps("classifications-tags")}
        />
        <_tabItem text={texts.flows} {...getTabProps("flows")} />
        <_tabItem text={texts.lists} {...getTabProps("lists")} />
        <_tabBarTool>
          {currentPanel === "groups" && (
            <CreateAccessGroupButton
              matrix={accessControlStore.accessGroupsMatrix}
            />
          )}
        </_tabBarTool>
      </_tabBar>

      {canManageAccessGroups && (
        <_panel {...getPanelProps("groups")}>
          <AccessGroupMatrix matrix={accessControlStore.accessGroupsMatrix} />
        </_panel>
      )}

      <_panel {...getPanelProps("sections")}>
        <ObjectPermissionsMatrix
          matrix={accessControlStore.sectionsMatrix}
          renderRowTitle={(object) => <SectionRowTitle object={object} />}
        />
      </_panel>

      <_panel {...getPanelProps("classifications-tags")}>
        {accessControlStore.selectedClassification ? (
          <ObjectPermissionsMatrix
            matrix={accessControlStore.tagsMatrix}
            renderRowTitle={(object) => <TagRowTitle object={object} />}
            hideObjects={
              !accessControlStore.selectedClassification.json
                .determinesAccessControl
            }
            renderMatrixHeader={() => (
              <TagMatrixHeader
                classification={accessControlStore.selectedClassification}
                onCloseClick={() =>
                  accessControlStore.selectClassification(undefined)
                }
                onChange={({ classificationId, determinesAccessControl }) => {
                  accessControlStore.setClassificationDeterminesAccessControl(
                    classificationId,
                    determinesAccessControl
                  );
                }}
              />
            )}
          />
        ) : (
          <ObjectPermissionsMatrix
            matrix={accessControlStore.classificationsMatrix}
            renderRowTitle={(object) => (
              <ClassificationRowTitle
                object={object}
                onClick={() =>
                  accessControlStore.selectClassification(object.uuid)
                }
              />
            )}
          />
        )}
      </_panel>

      <_panel {...getPanelProps("flows")}>
        <ObjectPermissionsMatrix
          matrix={accessControlStore.flowContainersMatrix}
          renderRowTitle={() => (
            <RowTitle>
              <FormattedMessage {...texts.flows} />
            </RowTitle>
          )}
        />
      </_panel>

      <_panel {...getPanelProps("lists")}>
        <ObjectPermissionsMatrix
          matrix={accessControlStore.listContainersMatrix}
          renderRowTitle={() => (
            <RowTitle>
              <FormattedMessage {...texts.lists} />
            </RowTitle>
          )}
        />
      </_panel>
    </_wrap>
  );
});

const _wrap = styled.div`
  padding: 0 2rem;
  color: ${vars.contentFg};
  padding-top: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const _tabBar = styled.div`
  display: flex;
  margin-top: 2em;
  margin-bottom: 1em;
`;

const _tabItem = styled(Button).attrs({ variant: "blank" })`
  margin-right: 2em;
  padding: 0;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  padding: 10px;

  &[aria-selected="false"] {
    color: ${vars.dark55};
  }
`;

const _tabBarTool = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const _panel = styled.div`
  flex: 1;
`;

const texts = defineMessages({
  accessGroups: {
    id: "accessControl.settings.accessGroups",
    defaultMessage: "Access Groups",
  },
  sections: {
    id: "accessControl.settings.sections",
    defaultMessage: "Sections",
  },
  classificationsAndTags: {
    id: "accessControl.settings.classificationsAndTags",
    defaultMessage: "Classifications & tags",
  },
  flows: {
    id: "accessControl.settings.flows",
    defaultMessage: "Flows",
  },
  lists: {
    id: "accessControl.settings.lists",
    defaultMessage: "Lists",
  },
});
