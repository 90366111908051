import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { defineMessages } from "react-intl";
import styled from "styled-components";
import { SearchIcon, SettingsIcon } from "@web/elements/Icons";
import { Logo } from "@web/elements/Logo";
import { NavTab } from "@web/elements/NavTab";
import { ProfileImage } from "@web/elements/ProfileImage";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";
import { UserProfileDialog } from "../UserProfileDialog/UserProfileDialog";

interface IPageLayoutProps {
  fullName: string;
  showNavBar: boolean;
}

export const Layout: React.FC<IPageLayoutProps> = (p) => {
  const [showUserProfileDialog, setShowUserProfileDialog] = useState(false);

  return (
    <_wrap showNavBar={p.showNavBar}>
      {p.showNavBar && (
        <_navBar>
          <_navItem>
            <NavLink to="/">
              <Logo />
            </NavLink>
          </_navItem>

          <_mainMenu>
            <_navItem>
              <NavTab
                isHomeLink
                to="/"
                label={texts.search}
                icon={<SearchIcon />}
              />
            </_navItem>
          </_mainMenu>

          <_navItem>
            <NavTab
              to="/settings"
              label={texts.settings}
              icon={<SettingsIcon />}
            />
          </_navItem>

          <_navButton onClick={() => setShowUserProfileDialog(true)}>
            <ProfileImage name={p.fullName} size="medium" />
          </_navButton>

          {showUserProfileDialog && (
            <UserProfileDialog
              userFullName={p.fullName}
              onClose={({ wasConfirmed }) =>
                wasConfirmed === false && setShowUserProfileDialog(false)
              }
            />
          )}
        </_navBar>
      )}

      <_content>{p.children}</_content>
    </_wrap>
  );
};

const _wrap = styled.div<{ showNavBar: boolean }>`
  ${media("desktop")} {
    --navbar-size: ${(p) => p.showNavBar && "72px"};
    --layout-safe-area-left: var(--navbar-size);
  }

  ${media("compact")} {
    --navbar-size: ${(p) => p.showNavBar && "60px"};
    --layout-safe-area-bottom: var(--navbar-size);
  }
`;

const _navBar = styled.header`
  background: ${vars.primaryDark20};
  color: ${vars.primary};
  display: flex;
  position: fixed;
  z-index: ${ZIndex.layout};

  ${media("desktop")} {
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
  }

  ${media("compact")} {
    height: 60px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const _navItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--navbar-size);
  height: var(--navbar-size);

  a {
    background: ${vars.primaryDark20};
    color: ${vars.primary};

    :hover {
      background: ${vars.primaryDark10};
      color: ${vars.primaryFg};
    }

    :active {
      background: ${vars.primary};
      color: ${vars.primaryFg};
    }
  }
`;

const _navButton = styled(_navItem.withComponent("button"))`
  background: none;
  border: none;
  cursor: pointer;

  :hover {
    background: ${vars.primaryDark10};
    color: ${vars.primaryFg};
  }
`;

const _mainMenu = styled.nav`
  flex: 1;
`;

const _content = styled.main`
  height: 100vh;

  ${media("desktop")} {
    margin-left: var(--layout-safe-area-left);
    position: relative;
  }

  ${media("compact")} {
    margin-bottom: var(--layout-safe-area-bottom);
  }
`;

const texts = defineMessages({
  settings: {
    id: "layout.navbar.settings",
    defaultMessage: "Settings",
  },
  search: {
    id: "layout.navbar.search",
    defaultMessage: "Search",
  },
});
