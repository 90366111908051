import React, { FC, useRef, useState } from "react";
import { observer } from "mobx-react";
import { MessageDescriptor } from "react-intl";
import { ClassModel, SelectedTagsMap, TagSearchModel } from "@web/models";
import { LabelList, TagSelect } from "@web/components";
import { PopoverBox } from "@web/elements";
import { LabelVariant } from "@web/elements/Label";

interface Events {
  onApply?: (classificationId: UUID) => void;
}

interface Props extends Events {
  editable: boolean;
  truncate?: boolean;
  classifications: ClassModel[];
  selectedTags: SelectedTagsMap;
  tagSearch: TagSearchModel;
  emptyMessage?: MessageDescriptor;
  labelVariant?: LabelVariant;
  requiredClassifications?: string[];
  checklistTags?: UUID[];
}

export const ClassEdit: FC<Props> = observer((p) => {
  const [selectedClass, setSelectedClass] = useState<ClassModel>();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClassificationClick = (
    classification: ClassModel,
    ref: React.RefObject<HTMLButtonElement>
  ) => {
    buttonRef.current = ref.current;
    setSelectedClass(classification);
  };

  const handleClassificationClose = (shouldApply: boolean) => {
    const { onApply, selectedTags } = p;
    if (selectedClass && shouldApply) {
      onApply?.(selectedClass.uuid);
    } else {
      selectedTags.clearUnsavedChanges();
    }

    setSelectedClass(undefined);
  };

  const {
    editable,
    truncate,
    emptyMessage,
    selectedTags,
    labelVariant,
    requiredClassifications,
    tagSearch,
    classifications,
  } = p;

  return (
    <>
      <LabelList
        truncate={truncate}
        labels={classifications}
        selectedTags={selectedTags}
        editable={editable}
        emptyMessage={emptyMessage}
        onLabelClick={handleClassificationClick}
        variant={labelVariant}
        requiredClassifications={requiredClassifications}
      />
      {selectedClass && buttonRef && (
        <PopoverBox
          alignY="top"
          margin={-5}
          triggerRef={buttonRef}
          onClickOutside={() => handleClassificationClose(false)}
        >
          <TagSelect
            classification={selectedClass}
            tagSearch={tagSearch}
            selectedTags={selectedTags}
            checklistTags={p.checklistTags}
            onDone={handleClassificationClose}
          />
        </PopoverBox>
      )}
    </>
  );
});
