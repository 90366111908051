import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { vars } from "@web/styles";
import { EntryModel, ReminderModel } from "@web/models";
import { ClockIcon } from "@web/elements/Icons";
import { A11yText } from "@web/elements/A11yText";

interface ReminderItemProps {
  entry: EntryModel;
  reminder: ReminderModel;
  onClick: (reminder: ReminderModel) => void;
}

export const ReminderItem = observer((p: ReminderItemProps) => {
  const intl = useIntl();
  const isUpdatable = p.reminder.canUpdate;

  /**
   * This click handler is purely for people using the mouse to ensure the
   * entire item is clickable with no dead zones. This was nicer than padding-gore.
   * `_content` will be handling the same thing in a properly accessible manner.
   */
  const handleWrapClick = (e: React.MouseEvent) => {
    if (isUpdatable && e.target === e.currentTarget) {
      p.onClick(p.reminder);
    }
  };

  return (
    <_wrap isUpdatable={isUpdatable} onClick={handleWrapClick}>
      <_content
        as={isUpdatable ? "button" : "div"}
        onClick={() => isUpdatable && p.onClick(p.reminder)}
      >
        <_title role="heading">{p.reminder.title}</_title>
        {p.reminder.isCreatedBySystem ? (
          <_autoAdded>{intl.formatMessage(texts.autoAdded)}</_autoAdded>
        ) : null}
        <_date
          dateTime={p.reminder.date.toISOString()}
          isOverdue={p.reminder.isOverdue}
        >
          <ClockIcon role="presentation" />
          {intl.formatDate(p.reminder.date, {
            year: "numeric",
            day: "2-digit",
            month: "long",
          })}
          {p.reminder.isOverdue && (
            <A11yText>({intl.formatMessage(texts.overdue)})</A11yText>
          )}
        </_date>
      </_content>

      {isUpdatable && p.reminder.isOverdue && (
        <_actions>
          <_action onClick={() => p.reminder.snooze("tomorrow")}>
            {intl.formatMessage(texts.remindMeTomorrow)}
          </_action>
          <_action onClick={() => p.reminder.snooze("next-year")}>
            {intl.formatMessage(texts.remindMeNextYear)}
          </_action>
        </_actions>
      )}
    </_wrap>
  );
});

const _wrap = styled.div<{ isUpdatable: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem;

  :hover,
  :focus-within {
    background: ${(p) => p.isUpdatable && vars.secondaryAltLight20};
    cursor: ${(p) => p.isUpdatable && "pointer"};
  }

  & + & {
    border-top: 1px solid ${vars.secondaryAltLight10};
  }
`;

const _content = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
`;

const _title = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`;

const _date = styled.time<{ isOverdue: boolean }>`
  color: ${(p) => (p.isOverdue ? vars.danger : vars.dark55)};
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
`;

const _actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.25rem;
  font-size: 0.875rem;
  padding-left: 1.25rem;
`;

const _action = styled.button`
  background: none;
  border: none;
  color: ${vars.secondary};
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: left;

  :hover {
    text-decoration: underline;
  }
`;

const _autoAdded = styled.small`
  background: ${vars.note};
  color: ${vars.noteFg};
  padding: 3px;
  border-radius: 2px;
`;

const texts = defineMessages({
  autoAdded: {
    id: "entry.reminders.automatically-added",
    defaultMessage: "Automatically added",
  },
  overdue: {
    id: "entry.reminders.overdue",
    defaultMessage: "Overdue",
  },
  remindMeTomorrow: {
    id: "entry.reminders.snooze.tomorrow",
    defaultMessage: "Remind me tomorrow",
  },
  remindMeNextYear: {
    id: "entry.reminders.snooze.next-year",
    defaultMessage: "Remind me in one year",
  },
});
