import React, { FC, useRef, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import ellipsis from "polished/lib/mixins/ellipsis";
import { AttributeModel, FilterType } from "@web/models";
import { CrossIcon } from "@web/elements/Icons";
import { vars } from "@web/styles";
import { PopoverBox } from "@web/elements";
import { INTL_DATE_FORMAT } from "@web/utils/dates";
import { AttributeFilter } from "./AttributeFilter";

interface IProps {
  attribute: AttributeModel;
  filter?: FilterType;
  onClearClick: (attributeId: number) => void;
  onChange: (definitionId: number, filter: FilterType) => void;
}

export const AttributeButton: FC<IProps> = observer((p) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const isDatePickerOpen = useRef(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClearClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    p.onClearClick(p.attribute.id);
  };

  const handleFilterChange = (definitionId: number, filter: FilterType) => {
    if (isDatePickerOpen.current === true) {
      return;
    }
    if (filter.type !== "list") {
      setOpen(false);
    }

    p.onChange(definitionId, filter);
  };

  const formatValue = () => {
    if (!p.filter) return "";
    switch (p.filter.type) {
      case "relative":
        return intl.formatMessage(p.filter.title);
      case "list":
        let values = p.filter.value;
        if (p.attribute.type === "DateList") {
          values = values.map((d) => intl.formatDate(d, INTL_DATE_FORMAT));
        }
        return values.join(", ");
      case "equals":
        if (p.attribute.type === "Date") {
          return intl.formatDate(p.filter.value, INTL_DATE_FORMAT);
        }
        return String(p.filter.value);
      case "range":
        if (p.attribute.type === "Date") {
          return (
            intl.formatDate(p.filter.value, INTL_DATE_FORMAT) +
            " – " +
            intl.formatDate(p.filter.valueEnd, INTL_DATE_FORMAT)
          );
        }
        return `${p.filter.value} – ${p.filter.valueEnd}`;
    }
  };

  const handleDatePickerToggle = (open: boolean) => {
    /**
     * Add delay to avoid race condition when an open DatePicker is closed.
     * The clickOutside handler for the DatePicker will always be triggered before
     * the clickOutside handler for the filter PopoverBox. We want to identify
     * whether a DatePicker is open when a clickOutside is triggered on the filter PopoverBox
     * to prevent it closing in these cases. Without the delay `isDatePickerOpen` would already
     * have been set to false by the time we reach the handleClickOutside code below.
     */
    setTimeout(() => {
      isDatePickerOpen.current = open;
    }, 100);
  };

  const handleClickOutside = () => {
    if (isDatePickerOpen.current === true) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <_button
        ref={buttonRef}
        empty={!p.filter}
        title={formatValue()}
        onClick={() => setOpen(true)}
      >
        <span>{p.attribute.name}</span>
        {p.filter && (
          <span>
            <span>{formatValue()}</span>
            <CrossIcon width={14} height={14} onClick={handleClearClick} />
          </span>
        )}
      </_button>
      {isOpen && (
        <PopoverBox
          alignY="top"
          alignX="start-left-of-trigger"
          margin={-3}
          maxWidth={350}
          triggerRef={buttonRef}
          onClickOutside={handleClickOutside}
        >
          <AttributeFilter
            attribute={p.attribute}
            initialFilter={p.filter}
            onChange={handleFilterChange}
            onCancel={() => setOpen(false)}
            onDatePickerToggle={handleDatePickerToggle}
          />
        </PopoverBox>
      )}
    </>
  );
});

const _button = styled.button.attrs({ type: "button" })<{
  empty?: boolean;
}>`
  background: ${(p) => (p.empty ? vars.content : vars.primaryDark20)};
  color: ${(p) => (p.empty ? vars.contentFg : vars.primaryFg)};
  border: 1px solid ${(p) => (p.empty ? vars.dark05 : vars.transparent)};
  box-shadow: ${vars.shadow.z1};
  padding: 6px 10px;
  border-radius: 0.5rem;
  margin: 3px 10px 3px 0px;
  display: inline-flex;
  align-items: center;
  outline: none;
  font-size: 0.8rem;
  cursor: pointer;
  > span:first-of-type {
    margin-right: 4px;
    :not(:only-child) {
      ::after {
        content: ":";
      }
    }
  }
  > span:last-of-type {
    margin-left: 3px;
    display: inline-flex;
    align-items: center;
    align-content: center;

    > span {
      ${ellipsis()};
      max-width: 200px;
    }

    > svg {
      margin-left: 10px;
      flex-shrink: 0;
    }
  }
`;
