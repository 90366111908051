import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useStores } from "@web/stores/context";

export const useDocumentNavigationHandler = () => {
  const { documentStore, recordStore } = useStores();
  const params = useParams();

  /* Change */
  useEffect(() => {
    const doc = documentStore.document;
    if (Number(params.documentId) !== doc?.id) {
      documentStore.loadDocument(Number(params.documentId));
    }
  }, [params]);

  useEffect(() => {
    /* Mount */
    recordStore.entry?.focusDocument(Number(params.documentId));

    /* Unmount */
    return () => {
      documentStore.clearDocument();
    };
  }, []);
};
