import { vars } from "@web/styles";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

interface IProps extends NavLinkProps {
  disabled?: boolean;
}

export const LinkTab: React.FC<IProps> = ({ children, disabled, ...p }) => {
  if (disabled) {
    return null;
  }

  const getStyle = (p: { isActive: boolean }) => {
    return p.isActive ? { ...STYLE, ...ACTIVE_STYLE } : STYLE;
  };

  return (
    <NavLink {...p} style={getStyle}>
      {children}
    </NavLink>
  );
};

const STYLE: React.CSSProperties = {
  padding: "0.5rem",
  color: vars.dark55,
};

const ACTIVE_STYLE: React.CSSProperties = {
  color: vars.contentFg,
  fontWeight: 600,
};
