import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { SimpleTheme } from "@web/styles/themes";
import { getThemeFromUrl } from "@web/utils/helpers";
import { vars } from "@web/styles";
import { GlobalConfig } from "@config/context";

const PREDEFINED = {
  simpleDefault: {
    body: "#f8f6f9",
    content: "#ffffff",
    primary: "#861349",
    secondary: "#3b46c4",
  },
  ["Dark Mode (broken)"]: {
    body: "#121212",
    content: "#222222",
    primary: "#7a1545",
    secondary: "#3b46c3",
  },
  whiteLabel: {
    body: "#f2f2f2",
    content: "#ffffff",
    primary: "#09639f",
    secondary: "#0BAC6B",
  },
};

export const ThemePicker = observer(() => {
  const config = useContext(GlobalConfig);
  const [theme, setTheme] = useState<SimpleTheme | undefined>(config.theme);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleThemeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "URL_THEME") {
      setTheme(getThemeFromUrl());
    } else {
      setTheme(PREDEFINED[e.target.value as keyof typeof PREDEFINED]);
    }
  };

  const handleThemeEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (selectRef.current?.value === "URL_THEME") {
        setTheme(getThemeFromUrl());
      } else
        setTheme(
          PREDEFINED[selectRef.current?.value as keyof typeof PREDEFINED]
        );
    } else {
      setTheme(undefined);
    }
  };

  const handleColorChange = debounce((name: string, value: string) => {
    setTheme(
      Object.assign({}, config.theme ?? PREDEFINED.simpleDefault, {
        [name]: value,
      })
    );
  });

  useEffect(() => {
    config.setSimpleTheme(theme);
  }, [theme]);

  return (
    <_wrap>
      <input
        type="checkbox"
        checked={!!config.theme}
        onChange={handleThemeEnable}
      />
      <select ref={selectRef} onChange={handleThemeSelect}>
        {Object.keys(PREDEFINED).map((themeName) => (
          <option key={themeName} value={themeName}>
            {themeName}
          </option>
        ))}
        <option value="URL_THEME" disabled={!getThemeFromUrl()}>
          URL theme
        </option>
      </select>
      <table>
        <tbody>
          {Object.entries(
            theme ??
              PREDEFINED[selectRef.current?.value as keyof typeof PREDEFINED] ??
              PREDEFINED.simpleDefault
          ).map(([name, value]) => (
            <tr key={"color" + name}>
              <th>{name}</th>
              <td>
                <input
                  type="color"
                  value={value}
                  onChange={(e) => handleColorChange(name, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <pre>{JSON.stringify(theme, null, 2)}</pre>
    </_wrap>
  );
});

const _wrap = styled.div`
  background: ${vars.content};
  border: 2px solid ${vars.dark05};
  border-radius: 1rem 0 0 1rem;
  color: ${vars.contentFg};
  padding: 1.5rem;
  position: fixed;
  top: 50%;
  right: 0;
  font-size: 1rem;
  z-index: 10000;
  transform: translate(calc(100% - 1.75rem), -50%);
  transition: transform 500ms ease-in-out;

  ::before {
    content: "Theme editor";
    position: absolute;
    top: 50%;
    left: 0.25rem;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left top 0;
    font-size: 0.75rem;
    font-weight: 600;
    opacity: 0.5;
  }

  :hover {
    transform: translate(0, -50%);
  }

  caption,
  th,
  td {
    text-align: left;
    padding: 0.5rem;
  }
`;
