import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import styled from "styled-components";
import { ellipsis } from "polished";
import { DocumentModel } from "@web/models";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { commonTexts } from "@web/translations";
import { FileType } from "@web/elements";
import { DocumentSignatureBadge } from "@web/components/Document/DocumentSignatureBadge";
import { VersionTag } from "@web/elements/VersionTag";
import { useMedia } from "@web/styles/utils";
import { EditTitleDialog } from "@web/components/Dialogs/EditTitleDialog";
import { vars } from "@web/styles";

export const DocumentTitle = observer((p: { document: DocumentModel }) => {
  const intl = useIntl();
  const { isDesktop } = useMedia();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const title = p.document.title;

  const handleSave = (title?: string) => {
    if (!title) {
      return;
    }
    p.document.update({ title });
  };

  return (
    <_wrap>
      <_entryTitle to={generateEntryUrl(p.document.entryId)}>
        {p.document.entryTitle ?? intl.formatMessage(commonTexts.untitledEntry)}
      </_entryTitle>

      <_documentTitle>
        {p.document.canUpdate ? (
          <button
            title={intl.formatMessage(commonTexts.edit)}
            onClick={() => setIsEditMode(true)}
          >
            {title}
          </button>
        ) : (
          <h1>{title}</h1>
        )}
      </_documentTitle>

      {isDesktop && <FileType type={p.document.currentVersion?.fileExt} />}

      {isDesktop && p.document.versions.length > 1 && (
        <VersionTag
          shorthand
          versionNumber={p.document.currentVersion.versionNumber}
        />
      )}

      {isDesktop && p.document && (
        <_signature>
          <DocumentSignatureBadge document={p.document} />
        </_signature>
      )}

      {isEditMode && (
        <EditTitleDialog
          defaultValue={p.document.title}
          onSave={handleSave}
          onClose={() => setIsEditMode(false)}
        />
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  > * {
    margin: 0;
  }
`;

const _signature = styled.span`
  font-size: 1rem;
  display: flex;
`;

const _entryTitle = styled(Link)`
  ${ellipsis()}
  flex-shrink: 2;
  min-width: 3rem;
  background: none;
  border: none;
  color: ${vars.dark25};
  display: inline-block;
  font: inherit;
  font-weight: 500;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;

  :hover {
    color: ${vars.contentFg};
  }
`;

const _documentTitle = styled.div`
  display: inline-block;
  margin: 0;
  overflow: hidden;

  h1,
  button {
    ${ellipsis()}
    background: none;
    border: none;
    color: inherit;
    display: inline-block;
    font: inherit;
    font-size: 20px;
    padding: 0;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    word-break: break-all;

    ::before {
      display: inline-block;
      content: "/";
      margin-right: 0.5rem;
    }
  }

  button {
    cursor: pointer;
  }
`;
