import React from "react";
import { observer } from "mobx-react";
import GatsbyImg from "gatsby-image";
import {
  getFluidImageProps,
  getToFormatFromExt,
} from "@web/utils/gatsby-helpers";
import { DocumentVersionModel } from "@web/models";
import { IconPreview } from "@web/components/FilePreview/IconPreview";

interface Props {
  documentVersion: DocumentVersionModel;
  size: number;
  hidePreview?: boolean;
}

export const DocumentThumbnail = observer(
  ({ documentVersion, size, hidePreview }: Props) => {
    if (
      hidePreview ||
      documentVersion.documentPreviewImage?.state !== "complete"
    ) {
      return <IconPreview type={documentVersion.fileExt} />;
    }

    const { base64, width, height } = documentVersion.documentPreviewImage;

    let maxWidthForGatsby: number = size;
    const containerStyle: React.CSSProperties = {
      height: `${size}px`,
      width: `${size}px`,
    };
    const imageStyle: React.CSSProperties = { objectPosition: "top center" };

    const aspect = width / height;
    if (aspect <= 1) {
      // vertical image
      if (aspect < 0.5) {
        imageStyle.objectFit = "contain";
      } else {
        // avoid blurry image when we scale with fill
        maxWidthForGatsby = Math.round(size * (1 - aspect + 1));
      }
    } else {
      // horizontal image - we want to centerify if we use contain
      if (aspect > 1.6) {
        imageStyle.objectFit = "contain";
        imageStyle.objectPosition = "center center";
      } else {
        // avoid blurry image when we scale with fill
        maxWidthForGatsby = Math.round(size * aspect);
      }
    }

    // If image is smaller than the bounds, lets keep original size;
    const isSmallerThanPreview = height < size && width < size;
    if (isSmallerThanPreview) {
      containerStyle.width = width + "px";
      containerStyle.height = height + "px";
    }

    const fluidProps = getFluidImageProps(
      {
        id: documentVersion.electronicDocumentId,
        args: {
          maxWidth: maxWidthForGatsby,
          sizes: "",
          toFormat: getToFormatFromExt(documentVersion.fileExt),
        },
        extension: documentVersion.fileExt,
      },
      { base64, width, height }
    );

    return (
      <GatsbyImg
        style={containerStyle}
        imgStyle={imageStyle}
        fluid={fluidProps}
      />
    );
  }
);
