import React, { FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  isCommentOutcome,
  isNotificationOutcome,
  isStatusOutcome,
  PipelineModel,
  EntryStatusModel,
} from "@web/models";
import { IOutcomeNode } from "@web/api/Integration/types";
import { ActionMenu, Input, StatusColor, Label } from "@web/elements";
import { OUTCOME_OPTIONS } from "@web/components/Flow/helpers";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";
import { vars } from "@web/styles";
import { flowEntityTexts } from "../texts";

interface IProps {
  pipeline: PipelineModel;
  outcome: IOutcomeNode;
  entryStatuses: EntryStatusModel[];
  onClose: () => void;
}

export const EditOutcome: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const defaultText = isCommentOutcome(p.outcome) ? p.outcome.entryComment : "";
  const defaultStatus = isStatusOutcome(p.outcome) ? p.outcome.entryStatus : "";

  const [text, setText] = useState(defaultText);
  const [entryStatus, setEntryStatus] = useState<UUID>(defaultStatus);

  const handleSaveOutcome = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (isCommentOutcome(p.outcome)) {
      p.pipeline.updateOutcome(p.outcome, { entryComment: text });
    } else {
      p.pipeline.updateOutcome(p.outcome, { entryStatus });
    }
    p.onClose();
  };

  const canSave = () => {
    if (isCommentOutcome(p.outcome)) {
      return text.length > 0 && text !== p.outcome.entryComment;
    }

    if (isStatusOutcome(p.outcome)) {
      return entryStatus !== p.outcome.entryStatus;
    }
  };

  return (
    <_wrap onSubmit={handleSaveOutcome}>
      <_edit>
        <div>
          <FormattedMessage {...OUTCOME_OPTIONS[p.outcome.type]} />
        </div>

        {isCommentOutcome(p.outcome) && (
          <Input
            placeholder={
              p.outcome?.entryComment ||
              intl.formatMessage(flowEntityTexts.outcomes.commentText)
            }
            value={text}
            onChange={(evt) => setText(evt.target.value)}
          />
        )}

        {isStatusOutcome(p.outcome) && (
          <_statusWrap>
            {p.entryStatuses.map((status) => (
              <Label
                key={status.uuid}
                toggled={entryStatus === status.uuid}
                onClick={() => setEntryStatus(status.uuid)}
              >
                <StatusColor color={ENTRY_STATUS_COLORS[status.name]} />
              </Label>
            ))}
          </_statusWrap>
        )}

        {isNotificationOutcome(p.outcome) && (
          <FormattedMessage {...texts.editNotSupportedYet} />
        )}
      </_edit>

      <ActionMenu
        onApply={handleSaveOutcome}
        onCancel={p.onClose}
        applyIsDisabled={!canSave()}
        applyText={texts.save}
        direction="horizontal"
      />
    </_wrap>
  );
});

const _wrap = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px 0;
  border-radius: 3px;
  background: ${vars.content};

  > {
    margin-bottom: 10px;
  }
`;

const _edit = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 10px 5px;

  div:first-child {
    flex-shrink: 0;
  }

  button {
    margin-left: 10px;
  }

  label {
    width: 100%;
    margin-left: 10px;
  }

  input {
    font-size: 13px;
  }

  --svg-fill: transparent;
  --svg-stroke: transparent;
  --svg-check: white;
`;

const _statusWrap = styled.div`
  display: flex;

  > div {
    margin-left: 15px;
  }
`;

const texts = defineMessages({
  save: {
    id: "flow.settings.outcome.save",
    defaultMessage: "Update outcome",
  },
  editNotSupportedYet: {
    id: "flow.settings.outcome.edit.not.supported.yet",
    defaultMessage: "Edit not supported yet…",
  },
});
