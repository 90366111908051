import styled from "styled-components";
import { ZIndex } from "@web/styles";

export const ClickOutside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.clickOutside};
`;
