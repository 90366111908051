import { addDays, addMonths, setHours, setMinutes } from "date-fns";
import { FormatDateOptions } from "react-intl";

export const INTL_DATE_FORMAT: FormatDateOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
};

export const DATE_ONLY_FORMAT = "yyyy-MM-dd";

export const getDateWithoutTime = (initial?: string | Date) => {
  let date: Date;
  if (initial) {
    date = new Date(initial);
  } else {
    date = new Date();
  }

  date.setHours(0, 0, 0, 0);
  return date;
};

export function tomorrowAtNoon() {
  const noonOfToday = setMinutes(setHours(new Date(), 12), 0);
  return addDays(noonOfToday, 1);
}

export function oneMonthFromNow() {
  const noonOfToday = setMinutes(setHours(new Date(), 12), 0);
  return addMonths(noonOfToday, 1);
}

const currentYear = new Date().getFullYear();

export const YEARS: number[] = [];
for (var i = currentYear - 30; i < currentYear + 30; i++) {
  YEARS.push(i);
}

export const MONTHS = [...Array(12).keys()].map(
  (i) => new Date(currentYear, i)
);
