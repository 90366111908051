import React from "react";

export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill="#28DD8F" />
    <path d="M5 9.5L8.66667 15L16 4" stroke="white" strokeWidth="2" />
  </svg>
);

SuccessIcon.displayName = "SuccessIcon";
