import { action, computed, observable } from "mobx";
import { DocumentVersionStore } from "@web/stores";
import { API_URL } from "@web/utils/paths";
import {
  IDocumentVersionNode,
  IntegrationApiPaths,
  Permission,
} from "@web/api/Integration/types";
import {
  PermissionModel,
  ExternalIdModel,
  parseExternalIdNode,
} from "@web/models";
import { DocumentPreviewImage } from "@web/api/BFF/types";
import { fileExtension } from "@web/utils/helpers";

export class DocumentVersionModel implements PermissionModel {
  @observable readonly uuid: UUID;
  @observable readonly id: number;
  @observable readonly electronicDocumentId: number; // Associated file (ElectronicDocument)
  @observable readonly electronicDocumentUuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable readonly createdBy: string;
  @observable readonly createdDate: string;
  @observable updatedDate: string;
  @observable versionNumber: number;
  @observable fileName: string;
  @observable checksum: string;
  @observable fileSize: number;
  @observable contentType: string;
  @observable externalIds: ExternalIdModel[] = [];
  // Parent document references
  @observable parentUuid: UUID;
  @observable parentId: number;
  // Added by BFF
  @observable documentPreviewImage?: DocumentPreviewImage;
  @observable fileExt: string;
  @observable editWith?: string[];
  // Fields added for UI
  @observable downloadUrl: string;
  @observable text?: string; // Field only set when fetching text for text documents
  @observable hasRunOcrCheck: boolean = false;

  constructor(
    private readonly store: DocumentVersionStore,
    json: IDocumentVersionNode,
    parentId: number,
    parentUUID: UUID
  ) {
    this.uuid = json.id;
    this.id = json.internalIdentifier;
    this.electronicDocumentId = json.electronicDocument.internalIdentifier;
    this.electronicDocumentUuid = json.electronicDocument.id;
    this.permissions = new Set(json.effectivePermissions);
    this.createdBy = json.createdBy;
    this.createdDate = json.createdDate;
    this.updatedDate = json.updatedDate;
    this.versionNumber = json.versionNumber;
    this.fileName = json.fileName;
    this.checksum = json.checksum;
    this.fileSize = json.fileSize;
    this.contentType = json.contentType;

    this.parentUuid = parentUUID;
    this.parentId = parentId;
    this.fileExt = json.fileExt ?? fileExtension(json.fileName);

    this.downloadUrl = `${API_URL}${IntegrationApiPaths.downloadFile}/${this.electronicDocumentUuid}`;

    this.updateFromJson(json);
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get hasCompletedPreview() {
    return (
      this.documentPreviewImage &&
      this.documentPreviewImage.state !== "in-progress"
    );
  }

  @computed
  get isSentForSignature() {
    return this.externalIds.some((x) =>
      x.externalId.startsWith("Unsigned_Source_")
    );
  }

  @computed
  get sentForSignatureDate() {
    return this.externalIds.find((x) =>
      x.externalId.startsWith("Unsigned_Source_")
    )?.createdDate;
  }

  @computed
  get isSigned() {
    return this.externalIds.some((x) =>
      x.externalId.startsWith("Signed_Result_")
    );
  }

  @computed
  get signedDate() {
    return this.externalIds.find((x) =>
      x.externalId.startsWith("Signed_Result_")
    )?.createdDate;
  }

  /**
   * Is true either when preview state is "in-prgress" or when
   * the preview needs an ocr check.
   * @see needsOcrCheck
   */
  @computed
  get isProcessingPreview() {
    return !this.hasCompletedPreview || this.needsOcrCheck;
  }

  /**
   * Return true if documentPreview.ocrStatus is missing or "queued",
   * and the ocr check has not been run.
   * Always returns false if the file is not a PDF.
   */
  @computed
  get needsOcrCheck(): boolean {
    if (this.contentType !== "application/pdf") {
      return false;
    }

    const isQueued =
      this.documentPreviewImage?.ocrStatus === undefined ||
      this.documentPreviewImage?.ocrStatus === "queued";

    return isQueued && !this.hasRunOcrCheck;
  }

  @computed
  get previewTimestamp(): number | undefined {
    const createdAt = this.documentPreviewImage?.createdAt;
    if (!createdAt) {
      return undefined;
    }

    const timestamp = Date.parse(createdAt);
    if (isNaN(timestamp)) {
      return undefined;
    }

    return timestamp;
  }

  /**
   * Set up state when this document is shown from the fullscreen UI.
   */
  prepareUiState() {
    this.hasRunOcrCheck = false;
    this.store.runBffOcrCheckIfNeeded(this);
  }

  @action.bound
  updateFromJson(json: Partial<IDocumentVersionNode>) {
    if (json.updatedDate) {
      this.updatedDate = json.updatedDate;
    }
    if (json.externalIds) {
      this.externalIds = json.externalIds.map(parseExternalIdNode);
    }
    if (json.editWith) {
      this.editWith = json.editWith;
    }
    if (json.documentPreviewImage) {
      this.documentPreviewImage = json.documentPreviewImage;
    }

    return this;
  }

  /**
   * Update fields on this document version object.
   */
  @action.bound
  updateState(fields: Partial<DocumentVersionModel>) {
    Object.assign(this, fields);
  }

  @action.bound
  updatePreview(updatedPreview: DocumentPreviewImage) {
    this.documentPreviewImage = updatedPreview;
  }

  /* -- CRUD and API operations -- */
  delete = () => {
    return this.store.deleteVersion(this);
  };

  sendForSignature = () => {
    return this.store.sendForSignature(this);
  };

  loadPreview = () => {
    return this.store.loadPreview(this);
  };
}
