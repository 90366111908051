import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { ColorStatusModel } from "@web/models";
import { TagStatusMultiSelect } from "./TagStatusMultiselect";
import { TagStatusButton, TagStatusButtonVariants } from "./TagStatusButton";
import { PopoverBox } from "@web/elements";

interface Props {
  selectedStatusIds: Set<number>;
  options: ColorStatusModel[];
  variant?: TagStatusButtonVariants;
  onChange?: () => void;
}

export const TagStatusFilter = observer((p: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isSelectingStatus, selectStatus] = useState(false);

  if (p.options.length === 0) {
    return null;
  }

  const selectedStatuses = p.options.filter((st) =>
    p.selectedStatusIds?.has(st.id)
  );

  const handleClick = () => {
    selectStatus(true);
  };

  const handleClickOutside = () => {
    selectStatus(false);
  };

  const handleTagStatusChange = (
    status: ColorStatusModel,
    selected: boolean
  ) => {
    if (selected) {
      p.selectedStatusIds.add(status.id);
    } else {
      p.selectedStatusIds.delete(status.id);
    }
    p.onChange?.();
  };

  const handleClearAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    p.selectedStatusIds.clear();
    p.onChange?.();
  };

  return (
    <>
      <TagStatusButton
        ref={buttonRef}
        selected={selectedStatuses}
        variant={p.variant}
        onClick={handleClick}
        onClearAll={handleClearAll}
      />

      {isSelectingStatus && (
        <PopoverBox
          triggerRef={buttonRef}
          onClickOutside={handleClickOutside}
          alignX="start-right-of-trigger"
          margin={-15}
        >
          <TagStatusMultiSelect
            options={p.options}
            selectedStatusIds={p.selectedStatusIds}
            onChange={handleTagStatusChange}
            onCloseClick={() => selectStatus(false)}
          />
        </PopoverBox>
      )}
    </>
  );
});
