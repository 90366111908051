import React, { forwardRef } from "react";
import { observer } from "mobx-react";
import {
  defineMessages,
  MessageDescriptor,
  FormattedMessage,
} from "react-intl";
import styled from "styled-components";
import { Menu, MenuItem } from "@web/components/Menu";
import { CaretDownIcon } from "@web/elements/Icons";
import { ResultOrder, ResultOrderKey } from "@web/models";
import { Dropdown } from "@web/components/Dropdown";
import { vars } from "@web/styles";
import { media } from "@web/styles/utils";
import { StyledButton } from "@web/elements/Button/styles";

interface IProps {
  options: ResultOrder;
  onSortClick: (order: ResultOrderKey) => void;
}

const texts = defineMessages({
  newest: {
    id: "searchpage.sortmenu.ordernewest",
    defaultMessage: "Newest to oldest",
  },
  oldest: {
    id: "searchpage.sortmenu.orderoldest",
    defaultMessage: "Oldest to newest",
  },
  alphabetical: {
    id: "searchpage.sortmenu.orderalphabetical",
    defaultMessage: "Alphabetical",
  },
  relevance: {
    id: "searchpage.sortmenu.orderrelevance",
    defaultMessage: "Relevance",
  },
  newestDocument: {
    id: "searchpage.sortmenu.newest.document",
    defaultMessage: "Newest document",
  },
});

export const SortMenu = observer((p: IProps) => {
  const { options, onSortClick } = p;
  return (
    <Dropdown toggle={(ref) => <Toggle ref={ref} {...p} />}>
      <Menu>
        {options.options.map((option) => (
          <MenuItem
            key={option}
            selected={option === options.selected}
            onClick={() => onSortClick(option)}
          >
            <FormattedMessage {...textForResultOrder(option)} />
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
});

const Toggle = observer(
  forwardRef((p: IProps, ref: React.Ref<HTMLButtonElement>) => (
    <StyledButton variant="primaryInverted" ref={ref}>
      <_label>
        <FormattedMessage
          id="searchpage.sortmenu.sortby"
          defaultMessage="Sort by"
        />
        :
      </_label>
      <_value>
        <FormattedMessage {...textForResultOrder(p.options.selected)} />
      </_value>
      <CaretDownIcon />
    </StyledButton>
  ))
);

function textForResultOrder(order: ResultOrderKey): MessageDescriptor {
  switch (order) {
    case "newest":
      return texts.newest;
    case "newest-document":
      return texts.newestDocument;
    case "oldest":
      return texts.oldest;
    case "alphabetical":
      return texts.alphabetical;
    case "relevance-newest":
    case "relevance-oldest":
    case "relevance-alphabetical":
    case "relevance-newest-document":
      return texts.relevance;
  }
}

const _label = styled.div`
  color: ${vars.bodyFg};
  font-size: 0.625rem;
  font-weight: bold;
  text-transform: uppercase;

  ${media("compact")} {
    display: none;
  }
`;

const _value = styled.div`
  background: ${vars.transparent};
  color: ${vars.primaryLight10};
`;
