import React, { FC } from "react";
import { observer } from "mobx-react";
import { AnimatePresence, MotionConfig } from "framer-motion";
import { useStores } from "@web/stores/context";
import { DocumentBucketOverlay } from "@web/components/Bucket/DocumentBucket";
import { EntryBucketOverlay } from "@web/components/Bucket/EntryBucket";
import * as Bucket from "@web/components/Bucket/styles";

export const BucketContainer: FC = observer(() => {
  const {
    multiSelectStore,
    sectionStore,
    recordStore,
    documentStore,
    tagStore,
  } = useStores();

  const { documents, entries } = multiSelectStore;

  const handleClose = () => {
    multiSelectStore.clearSelection();
  };

  const isDocumentOpen = documentStore.document;

  return (
    <MotionConfig reducedMotion="user">
      <AnimatePresence>
        {documents.hasSelection && !isDocumentOpen && (
          <Bucket.Overlay key="documentbucket">
            <DocumentBucketOverlay
              bucket={documents}
              openEntry={recordStore?.entry}
              openSection={sectionStore.selectedSection}
              onClose={handleClose}
            />
          </Bucket.Overlay>
        )}

        {entries.hasSelection && (
          <Bucket.Overlay key="entrybucket">
            <EntryBucketOverlay
              bucket={entries}
              openEntry={recordStore?.entry}
              tagSearch={tagStore.search}
              openSection={sectionStore.selectedSection}
              onClose={handleClose}
            />
          </Bucket.Overlay>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
});
