import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { MessageModel } from "@web/models";
import { SuccessIcon, FailureIcon, EditIcon } from "@web/elements/Icons";
import { Button, CloseButton } from "@web/elements/Button";
import { Truncate } from "@web/elements";
import { vars, ZIndex } from "@web/styles";

const ICONS = {
  success: <SuccessIcon />,
  failure: <FailureIcon />,
  updating: <EditIcon />,
  generic: null,
};

interface Props extends MessageModel {
  index: number;
  hide: () => void;
  remove: () => void;
}

export const Message: FC<Props> = observer((p) => {
  const intl = useIntl();
  const timer = useRef<number>();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    clearTimeout(timer.current);
    if (p.type === "success") {
      timer.current = window.setTimeout(() => p.hide(), 4000);
    }
  }, [p.type]);

  const animationEnded = () => {
    const { status, remove } = p;
    if (status === "remove") {
      remove();
    }
  };

  const translatedTextOrString = (
    text?: string | MessageDescriptor,
    values?: Record<string, string | number>
  ) => {
    if (text === undefined) {
      return "";
    }
    return typeof text === "string" ? text : intl.formatMessage(text, values);
  };

  const { type, title, text, values, action, status, index, hide } = p;
  const icon = ICONS[type];

  return (
    <_wrap index={index} animation={status} onAnimationEnd={animationEnded}>
      <_content>
        {icon}
        <_text>
          <h3>{translatedTextOrString(title, values)}</h3>
          <Truncate>{translatedTextOrString(text, values)}</Truncate>
        </_text>
        {action && (
          <Button
            text={translatedTextOrString(action.title, values)}
            variant="primaryInverted"
            onClick={action.onClick}
          />
        )}
      </_content>
      <CloseButton onClick={hide} />
    </_wrap>
  );
});

const _wrap = styled.div<{
  index: number;
  animation?: "show" | "remove" | "initial";
}>`
  ${(p) =>
    p.animation && `animation: ${p.animation} 0.3s 1 ease-out forwards;`};
  z-index: ${ZIndex.message};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  background: ${vars.content};
  box-shadow: ${vars.shadow.z3};
  border-radius: 3px;
  height: 65px;
  color: ${vars.contentFg};
  font-size: 12px;
  width: 280px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  transition: transform 0.3s;
  transform: translateX(-${(p) => p.index * 300}px);

  svg {
    flex-shrink: 0;
  }

  @keyframes remove {
    to {
      bottom: -75px;
    }
    from {
      bottom: 10px;
    }
  }

  @keyframes show {
    from {
      bottom: -75px;
    }
    to {
      bottom: 10px;
    }
  }
`;

const _content = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const _text = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 0px 10px;
  h3:first-of-type {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;
