import React from "react";
import styled from "styled-components";
import Dropzone, { DropzoneState } from "react-dropzone";
import { observer } from "mobx-react";
import { useCheckForEmptyFiles } from "@web/components/Upload/helpers";

interface IProps {
  disabled?: boolean;
  multiple: boolean;
  onUpload: (files: File[]) => void;
}

export const UploadButton: React.FC<IProps> = observer((p) => {
  const checkForEmptyFiles = useCheckForEmptyFiles();

  const handleDrop = async (files: File[]) => {
    if (await checkForEmptyFiles(files)) {
      p.onUpload(files);
    }
  };

  return (
    <Dropzone
      noClick={p.disabled}
      onDropAccepted={handleDrop}
      multiple={p.multiple}
    >
      {({ getRootProps, getInputProps }: DropzoneState) => (
        <_wrap {...getRootProps()} role="button">
          <input {...getInputProps()} />
          {p.children}
        </_wrap>
      )}
    </Dropzone>
  );
});

const _wrap = styled.div`
  outline: none;
`;
