import React from "react";

export const FileUploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7317 24H4C3.44772 24 3 23.5523 3 23V1C3 0.447716 3.44772 0 4 0H16.2973C16.7818 0 17.2497 0.175836 17.6143 0.494847L21.0487 3.49994C21.4827 3.87972 21.7317 4.42837 21.7317 5.0051V23C21.7317 23.5523 21.284 24 20.7317 24ZM7.01686 13.1958C6.57141 13.1958 6.34833 12.6572 6.66331 12.3423L11.5417 7.4639C11.9322 7.07337 12.5654 7.07337 12.9559 7.4639L17.8343 12.3423C18.1492 12.6572 17.9262 13.1958 17.4807 13.1958L13.8841 13.1958V17.2951C13.8841 17.5713 13.6602 17.7951 13.3841 17.7951H11.1135C10.8373 17.7951 10.6135 17.5713 10.6135 17.2951L10.6135 13.1958H7.01686Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
