import React, { forwardRef } from "react";
import { observer } from "mobx-react";
import {
  defineMessages,
  MessageDescriptor,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { Menu, MenuItem } from "@web/components/Menu";
import { CaretDownIcon } from "@web/elements/Icons";
import styled from "styled-components";
import { Dropdown } from "@web/components/Dropdown";
import { vars } from "@web/styles";
import { media } from "@web/styles/utils";
import { ViewType } from "@web/stores";
import { StyledButton } from "@web/elements/Button/styles";

interface Props {
  viewType: ViewType;
  onChange: (viewType: ViewType) => void;
}

const texts = defineMessages({
  listView: {
    id: "searchpage.viewTypeMenu.list",
    defaultMessage: "List view",
  },
  gridView: {
    id: "searchpage.viewTypeMenu.grid",
    defaultMessage: "Grid view",
  },
  title: {
    id: "searchpage.viewTypeMenu.title",
    defaultMessage: "View",
  },
});

const options: ViewType[] = ["grid", "list"];

export const ViewTypeMenu = observer((p: Props) => {
  const intl = useIntl();
  return (
    <Dropdown toggle={(ref) => <Toggle ref={ref} {...p} />}>
      <Menu>
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === p.viewType}
            onClick={() => p.onChange(option)}
          >
            <FormattedMessage {...textForViewType(option)} />
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
});

const Toggle = observer(
  forwardRef(({ viewType }: Props, ref: React.Ref<HTMLButtonElement>) => {
    const intl = useIntl();
    return (
      <StyledButton variant="primaryInverted" ref={ref}>
        <_title>{intl.formatMessage(texts.title)}:</_title>
        <_value>
          <FormattedMessage {...textForViewType(viewType)} />
        </_value>
        <CaretDownIcon />
      </StyledButton>
    );
  })
);

function textForViewType(viewType: ViewType): MessageDescriptor {
  switch (viewType) {
    case "grid":
      return texts.gridView;
    case "list":
      return texts.listView;
  }
}

const _title = styled.div`
  color: ${vars.bodyFg};
  font-size: 0.625rem;
  font-weight: bold;
  text-transform: uppercase;

  ${media("compact")} {
    display: none;
  }
`;

const _value = styled.div`
  background: ${vars.transparent};
  color: ${vars.primaryLight10};
`;
