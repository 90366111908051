import React, {
  FC,
  useState,
  useRef,
  RefObject,
  createRef,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { AttributeModel, ClassModel, TagModel } from "@web/models";
import { PopoverBox } from "@web/elements";
import {
  AttributeLabel,
  SetAttribute,
  SelectListAttribute,
} from "@web/components/Attributes";

interface Props {
  classification: ClassModel;
  tag: TagModel;
}

export const TagAttributeList: FC<Props> = observer((p) => {
  const [selectedAttribute, selectAttribute] = useState<AttributeModel>();
  const buttonRefs = useRef<Record<string, RefObject<HTMLButtonElement>>>({});

  useEffect(() => {
    if (attributes.length > 0) {
      p.tag.attributeValues?.loadValues();
    }
  }, []);

  const createOrReturnRef = (id: string) => {
    if (!buttonRefs.current[id]) {
      buttonRefs.current[id] = createRef();
    }
    return buttonRefs.current[id];
  };

  const attributes = p.classification.attributes;
  const attributeValues = p.tag.attributeValues;

  if (attributes.length === 0 || !attributeValues) {
    return null;
  }

  return (
    <>
      <_wrap>
        {attributes.map((att) => (
          <AttributeLabel
            key={att.id}
            ref={createOrReturnRef(att.uuid)}
            canUpdate={p.classification.canUpdate}
            attribute={att}
            attributeValues={attributeValues}
            onClick={() => selectAttribute(att)}
          />
        ))}
      </_wrap>

      {selectedAttribute && (
        <PopoverBox
          alignY="top"
          margin={-5}
          triggerRef={buttonRefs.current[selectedAttribute.uuid]}
          onClickOutside={() => selectAttribute(undefined)}
        >
          {selectedAttribute.isListAttribute ? (
            <SelectListAttribute
              disableComment
              attribute={selectedAttribute}
              attributeValues={attributeValues}
              onDone={() => selectAttribute(undefined)}
            />
          ) : (
            <SetAttribute
              tag={p.tag}
              disableAdditionalFields
              attribute={selectedAttribute}
              attributeValues={attributeValues}
              onDone={() => selectAttribute(undefined)}
            />
          )}
        </PopoverBox>
      )}
    </>
  );
});

const _wrap = styled.div`
  width: 100%;
  font-size: 13px;
  > button {
    margin: 5px;
  }
  h3 {
    font-weight: normal;
    margin: 10px 0px 0px 0px;
  }
`;
