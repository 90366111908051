import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import noResultsSvg from "@web/assets/spider.svg";
import { commonTexts } from "@web/translations";
import { Button } from "@web/elements/Button";
import { SearchDescriptionModel } from "@web/models/SearchModel";
import { media } from "@web/styles/utils";
import { StyledButton } from "@web/elements/Button/styles";

interface ExternalProps extends SearchDescriptionModel {
  updateSearchQuery: (query: string) => void;
}

interface InternalProps extends SearchDescriptionModel {
  replaceQuery: (query: string) => void;
}

export const NoResultsDescription = (p: ExternalProps) => {
  const replaceQuery = (newQuery: string) =>
    p.updateSearchQuery(`${p.freetextFilters.join(" ")} ${newQuery}`.trim());

  return (
    <_wrap>
      {typeof noResultsSvg === "string" && <img src={noResultsSvg} />}

      <_header>
        <HeaderText {...p} />
      </_header>
      <_suggestion>
        {isPhrase(p.query) ? (
          <PhraseSuggestion {...p} replaceQuery={replaceQuery} />
        ) : (
          <WordsSuggestion {...p} replaceQuery={replaceQuery} />
        )}
      </_suggestion>
    </_wrap>
  );
};

const HeaderText: React.FC<SearchDescriptionModel> = ({
  freetextFilters,
  words,
  query,
}) => {
  const optionalFreetextFiltersText = freetextFilters.length > 0 && (
    <FormattedMessage
      id="searchpage.noresults.title.freetextfilterstext"
      defaultMessage="for {activeFreetextFilters}"
      values={{
        activeFreetextFilters: formatStringList(freetextFilters, "conjunction"),
      }}
    />
  );

  if (query === "") {
    return (
      <FormattedMessage
        id="searchpage.noresults.title.withoutquery"
        defaultMessage="We found no results {optionalFreetextFiltersText}"
        values={{
          optionalFreetextFiltersText,
        }}
      />
    );
  }

  if (isPhrase(query)) {
    return (
      <FormattedMessage
        id="searchpage.noresults.title.withphrase"
        defaultMessage="We found no results {optionalFreetextFiltersText} containing the phrase <strong>{query}</strong>"
        values={{
          optionalFreetextFiltersText,
          query,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="searchpage.noresults.title.withwords"
      defaultMessage="We found no results {optionalFreetextFiltersText} containing the {wordCount, plural, one {word} other {words}} {words}"
      values={{
        optionalFreetextFiltersText,
        words: formatStringList(words),
        wordCount: words.length,
      }}
    />
  );
};

const PhraseSuggestion: React.FC<InternalProps> = ({ replaceQuery, words }) => {
  const handleWordSearch = () => replaceQuery(words.join(" "));

  return words.length > 0 ? (
    <FormattedMessage
      id="searchpage.noresults.suggestion.phrase"
      defaultMessage="Try searching for {asSeparateWords} instead."
      values={{
        asSeparateWords: (
          <StyledButton variant="blank" onClick={handleWordSearch}>
            {formatStringList(words)}
          </StyledButton>
        ),
      }}
    />
  ) : null;
};

const WordsSuggestion: React.FC<InternalProps> = ({
  replaceQuery,
  words,
  query,
}) => {
  if (words.length != 1 || query.endsWith("*") || query.endsWith("~")) {
    return null;
  }

  const isLongEnoughForTildeTermToMakeSense = query.length > 5;
  const handleWildcardSearch = () => replaceQuery(query + "*");
  const handleTildeSearch = () => replaceQuery(query + "~");

  const asWildcard = (
    <Button text={query + "*"} variant="blank" onClick={handleWildcardSearch} />
  );
  const asTilde = (
    <Button text={query + "~"} variant="blank" onClick={handleTildeSearch} />
  );

  return isLongEnoughForTildeTermToMakeSense ? (
    <FormattedMessage
      id="searchpage.noresults.suggestion.words.wildcardandtilde"
      defaultMessage="Try searching for {asWildcard} to find words that start with <strong>{query}</strong> or {asTilde} to find variations of the word instead."
      values={{
        asWildcard,
        asTilde,
        query,
      }}
    />
  ) : (
    <FormattedMessage
      id="searchpage.noresults.suggestion.words.wildcard"
      defaultMessage="Try searching for {asWildcard} to find words that start with <strong>{query}</strong> instead."
      values={{
        asWildcard,
        query,
      }}
    />
  );
};

const formatStringList = (
  terms: string[],
  style: "conjunction" | "disjunction" = "disjunction"
): JSX.Element[] => {
  const separatorText =
    style === "conjunction" ? commonTexts.and : commonTexts.or;

  return terms.map((term, idx) => {
    const comma = idx !== 0 && ", ";
    const isLastTerm = terms.length > 1 && idx == terms.length - 1;
    const separatorWord = isLastTerm && (
      <_ordinaryText>
        {" "}
        <FormattedMessage {...separatorText} />{" "}
      </_ordinaryText>
    );

    return (
      <span key={idx}>
        {separatorWord || comma}
        <strong>{term}</strong>
      </span>
    );
  });
};

const isPhrase = (text: string) => text.match(/"([^\\"]|\\")*"/);

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;

  ${media("desktop")} {
    margin-top: 50px;
  }

  ${media("compact")} {
    margin-top: 10px;
  }
`;

const _header = styled.h3`
  margin-top: 20px;
  font-size: 20px;
`;

const _suggestion = styled.div`
  font-size: 15px;
  margin-top: 15px;
`;

const _ordinaryText = styled.span`
  font-weight: initial;
`;
