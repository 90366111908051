import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { vars } from "@web/styles";

interface IProps {
  checked: boolean;
  disabled?: boolean;
  title?: string;
  onToggle: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: FC<IProps> = (p) => {
  return (
    <_wrap disabled={p.disabled}>
      <_toggle title={p.title}>
        <input
          type="checkbox"
          onChange={p.onToggle}
          defaultChecked={p.checked}
          disabled={p.disabled}
        />
        <_slider></_slider>
      </_toggle>
    </_wrap>
  );
};

const _slider = styled.span`
  display: inline-block;
  border-radius: 100px;
  width: 40px;
  height: 15px;
  background-color: ${vars.greyLight30};
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  top: 5px;
  cursor: pointer;
  :before {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    background-color: ${vars.grey};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    margin-top: -5px;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    margin-right: 0;
    transition: all 0.2s;
  }
`;

const _wrap = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${(p) => p.disabled && "opacity: 0.5;"}
  ${_slider} {
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  }
`;

const _toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + span {
    background-color: ${vars.secondaryAlt};
  }
  input:focus + span {
    box-shadow: 0 0 1px ${vars.secondaryAlt};
  }
  input:checked + span:before {
    left: 100%;
    margin-left: -20px;
    background-color: ${vars.primaryLight10};
  }

  [disabled]:not([disabled="false"]) + span {
    background: ${vars.greyLight30} !important;
  }

  [disabled]:not([disabled="false"]) + span:active:before {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
  }

  [disabled]:not([disabled="false"]) + span:before {
    background-color: ${vars.grey} !important;
  }
`;
