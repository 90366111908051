import React, { ChangeEvent, FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { vars } from "@web/styles";
import { PipelineModel } from "@web/models";
import { media } from "@web/styles/utils";
import { Toggle } from "@web/elements";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { RemoveButton } from "../RemoveButton";
import { pipelineTexts } from "../texts";

interface IProps {
  pipelines: PipelineModel[];
  onPipelineClick: (pipe: PipelineModel) => void;
}

export const PipelineList: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const [getConfirmation] = useConfirmationDialog();

  const handleDelete = async (pipe: PipelineModel) => {
    const confirmed = await getConfirmation({
      title: texts.deleteConfirmationTitle,
      message: texts.deleteConfirmationMessage,
      values: {
        pipelineName: pipe.name,
      },
    });

    if (confirmed) {
      pipe.delete();
    }
  };

  const handleDisable = (
    e: ChangeEvent<HTMLInputElement>,
    pipeline: PipelineModel
  ) => {
    pipeline.update({ isEnabled: e.target.checked });
  };

  const toggleTitle = (pipe: PipelineModel) => {
    if (!pipe.isValid) {
      return intl.formatMessage(pipelineTexts.validation.notValid);
    }

    if (pipe.isEnabled) {
      return intl.formatMessage(pipelineTexts.validation.disabled);
    }

    return intl.formatMessage(pipelineTexts.validation.enabled);
  };

  if (p.pipelines.length === 0) {
    return (
      <_wrap>
        <FormattedMessage {...texts.noneCreated} />
      </_wrap>
    );
  }

  return (
    <_wrap>
      {p.pipelines.map((pipe) => (
        <_pipeline key={pipe.uuid}>
          <_description onClick={() => p.onPipelineClick(pipe)}>
            <h3>{pipe.name}</h3>
            {pipe.description}
          </_description>
          <_info>
            {pipe.pipelineType === "validation" && (
              <FormattedMessage
                {...texts.rulesCount}
                values={{ count: pipe.ruleLoadingStatus.total }}
              />
            )}
            {pipe.pipelineType === "notification" && (
              <FormattedMessage
                {...texts.targetName}
                values={{ name: pipe.target.name }}
              />
            )}
          </_info>
          <Toggle
            onToggle={(e) => handleDisable(e, pipe)}
            checked={pipe.isEnabled}
            disabled={!pipe.isValid && !pipe.isEnabled}
            title={toggleTitle(pipe)}
          />
          <RemoveButton onClick={() => handleDelete(pipe)} />
        </_pipeline>
      ))}
    </_wrap>
  );
});

const _wrap = styled.div`
  margin-bottom: 25px;
`;

const _pipeline = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: ${vars.dark05};
  :hover {
  }

  ${media("compact")} {
    flex-direction: column;
  }
`;

const _description = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  h3 {
    padding: 0px 0px 5px 0px;
    margin: 0;
    color: #53153f;
  }

  ${media("desktop")} {
    width: 70%;
  }
`;

const _info = styled.div`
  flex-shrink: 0;
  min-width: 120px;
  margin: 0px 5px;
  display: flex;
  flex-direction: column;
  color: ${vars.dark55};
  font-size: 1.1em;
  ${media("desktop")} {
    align-self: center;
  }
`;

const texts = defineMessages({
  deleteConfirmationTitle: {
    id: "flow.settings.pipeline.delete.title",
    defaultMessage: "Are you sure you want to do this?",
  },
  deleteConfirmationMessage: {
    id: "flow.settings.pipeline.delete.message",
    defaultMessage: "This will permanently delete <em>{pipelineName}</em>.",
  },
  noneCreated: {
    id: "flow.settings.pipeline.none.created",
    defaultMessage: "None created…",
  },
  rulesCount: {
    id: "flow.settings.pipeline.rules.count",
    defaultMessage: "{count} rule(s)",
  },
  targetName: {
    id: "flow.settings.pipeline.target.name",
    defaultMessage: "Target: {name}",
  },
});
