import { SystemAccountUUID } from "./CommonModel";
import {
  UUID,
  ChangelogEventNode,
  ChangedFieldsOnEntity,
  ChangedRelatedEntryStatusEntity,
  ChangedRelatedTags,
  ChangedRelatedAttributeListValues,
  AttributeValueChanges,
} from "@web/api/Integration/types";
import { CommentModel } from "./CommentModel";

export type ChangelogEventModel = SingleEventModel | CollapsedEventsModel;

type CommonEventModelFields = Pick<ChangelogEventNode, "fields" | "type"> & {
  isCollapsed: false;
  eventTime: Date;
  id: string;
  systemId: UUID;
  userName: string;
  userId: UUID | SystemAccountUUID;

  comment?: CommentModel;
  // A hack to enable comments to be presented in relation to the change it was made in relation
  // to in the UI. Might be replaced by some proper solution later - ED May 2021
  relatedComment?: SingleEventModel;
};

export type ChangelogAddOrDelModel = CommonEventModelFields & {
  eventType: "ADD" | "DEL";
};

export type ChangelogModModel<
  CHANGED_DETAILS_TYPE = ChangedFieldsOnEntity | AttributeValueChanges
> = CommonEventModelFields & {
  eventType: "MOD";
  changed: CHANGED_DETAILS_TYPE;
};

export type SingleEventModel = ChangelogAddOrDelModel | ChangelogModModel;

export type CollapsedEventsModel = Pick<
  SingleEventModel,
  "eventType" | "type" | "userName" | "userId"
> & {
  isCollapsed: true;
  // concatenated string of all collapsed single event IDs, useful for React.js .key-prop when rendering a list of components
  id: string;
  // convenience for the rendering components to avoid having to extract it from the items in .events field
  newestEventTime: Date;
  oldestEventTime: Date;
  events: SingleEventModel[];

  // A hack to enable comments to be presented in relation to the change it was made in relation
  // to in the UI. Might be replaced by some proper solution later - ED May 2021
  relatedComment?: SingleEventModel;
};

export function isAddOrDelEvent(
  event: ChangelogEventModel
): event is ChangelogAddOrDelModel {
  return ["DEL", "ADD"].includes(event.eventType) && !event.isCollapsed;
}

export function isEntryStatusChanged(
  event: SingleEventModel
): event is ChangelogModModel<ChangedRelatedEntryStatusEntity> {
  return (
    event.type === "Entry" &&
    event.eventType === "MOD" &&
    "EntryStatus" in event.changed
  );
}

export function isEntryTagsChanged(
  event: SingleEventModel
): event is ChangelogModModel<ChangedRelatedTags> {
  return (
    event.type === "Entry" &&
    event.eventType === "MOD" &&
    "Tag" in event.changed
  );
}

export function isEntryAttributeListValuesChanged(
  event: SingleEventModel
): event is ChangelogModModel<ChangedRelatedAttributeListValues> {
  return (
    event.type === "Entry" &&
    event.eventType === "MOD" &&
    "AttributeListValue" in event.changed
  );
}
