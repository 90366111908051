import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { ProfileImage, DateTime } from "@web/elements";
import { vars } from "@web/styles";
import { texts } from "./texts";

export const CreatedBy: React.FC<{
  datetime: string;
  userName: string;
}> = (p) => (
  <div>
    <FormattedMessage
      {...texts.versionCreatedText}
      values={{ formattedDate: <DateTime datetime={p.datetime} /> }}
    />
    <_author>
      <ProfileImage name={p.userName} size="small" />
      {p.userName}
    </_author>
  </div>
);

const _author = styled.div`
  color: ${vars.dark55};
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  align-items: center;
  margin: 0.5rem 0 0 -2.5rem;
`;
