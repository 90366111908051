import { addYears, isPast } from "date-fns";
import { action, computed, observable } from "mobx";
import { IReminderNode, Permission } from "@web/api/Integration/types";
import { ReminderStore } from "@web/stores";
import { tomorrowAtNoon } from "@web/utils/dates";
import { isSystemAccount } from "./CommonModel";
import { PermissionModel, EntryModel } from "@web/models";

export class ReminderModel implements PermissionModel {
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable title: string;
  @observable date: Date;

  readonly userId: UUID;
  readonly userName: string;
  readonly description: string | undefined;

  constructor(private readonly store: ReminderStore, json: IReminderNode) {
    this.uuid = json.id;
    this.permissions = new Set(json.effectivePermissions);
    this.date = new Date(json.reminderDate);
    this.title = json.title;
    this.userId = json.createdByUserId;
    this.userName = json.createdBy;
    this.description = json.description;
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get isOverdue() {
    return isPast(this.date);
  }

  @computed
  get isCreatedBySystem() {
    return isSystemAccount(this.userId);
  }

  @action.bound
  updateFromJson(json: IReminderNode) {
    this.date = new Date(json.reminderDate);
    this.title = json.title;
    return this;
  }

  update(fields: Pick<ReminderModel, "title" | "date">): Promise<void> {
    return this.store.updateReminder(this, fields);
  }

  delete(parent: EntryModel) {
    return this.store.deleteReminder(this, parent);
  }

  snooze(wantedDate: "tomorrow" | "next-year") {
    const newDate =
      wantedDate === "tomorrow" ? tomorrowAtNoon() : addYears(this.date, 1);

    return this.store.updateReminder(this, {
      date: newDate,
      title: this.title,
    });
  }
}
