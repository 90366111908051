import React, { useEffect, useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import {
  FixedSizeList,
  VariableSizeList,
  ListProps,
  ListChildComponentProps,
} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { useFocusManager, useKeyboard } from "react-aria";
import loadingSvgSrc from "@web/assets/loading.svg";
import {
  DataLoadingModel,
  isAutocomplete,
  INITIAL_LOADING_STATUS,
} from "@web/models";
import { InputThemes, SearchInput } from "@web/elements/Input";
import { SearchIcon } from "@web/elements/Icons";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";

const SHOW_SEARCH_THRESHOLD = 12;
const LOADING_INDICATOR = <img src={loadingSvgSrc} />;

interface IProps
  extends Omit<ListProps, "children" | "itemCount" | "width" | "height"> {
  loadingStatus: DataLoadingModel;
  listStyle?: React.CSSProperties;
  searchStyle?: keyof typeof InputThemes;
  emptyText?: MessageDescriptor;
  loadNextPage: () => Promise<void> | null;
  renderItem: (index: number) => JSX.Element;
  renderLoadingItem?: () => JSX.Element | null;
  renderTitle?: () => JSX.Element | null;
  onQueryChange?: (newQuery: string) => void;
  itemSize: ((index: number) => number) | number;
  refreshRowSizes?: number[];
  scrollTo?: number;
}

export const LazyList: React.FC<IProps> = observer(
  ({
    listStyle,
    searchStyle,
    emptyText,
    loadingStatus,
    itemSize,
    refreshRowSizes,
    scrollTo,
    loadNextPage,
    renderItem,
    renderLoadingItem,
    renderTitle,
    onQueryChange,
    itemData,
    ...rest
  }) => {
    const variableListRef = useRef<VariableSizeList | null>(null);
    const fixedListRef = useRef<FixedSizeList | null>(null);
    const firstFocusRef = useRef<HTMLAnchorElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const focusManager = useFocusManager();
    const { keyboardProps } = useKeyboard({
      onKeyUp: (e) => {
        if (!focusManager) {
          e.continuePropagation();
          return;
        }

        if (e.key === "Escape") {
          if (
            !searchInputRef.current ||
            searchInputRef.current === document.activeElement ||
            firstFocusRef.current === document.activeElement
          ) {
            e.continuePropagation();
          } else {
            searchInputRef.current.focus();
          }
        }
      },
      onKeyDown: (e) => {
        if (!focusManager) {
          e.continuePropagation();
          return;
        }

        switch (e.key) {
          case "ArrowDown":
            e.preventDefault();
            focusManager.focusNext();
            break;
          case "ArrowUp":
            e.preventDefault();
            focusManager.focusPrevious();
            break;
          case "Home":
          case "End":
          case "PageDown":
          case "PageUp":
            e.preventDefault();
            break;
          default:
            e.continuePropagation();
        }
      },
    });

    const { hasMore, pageLoading, itemCount } = loadingStatus;

    useLayoutEffect(() => {
      if (fixedListRef.current && loadingStatus.lastPageLoaded === 1) {
        fixedListRef.current.scrollTo(0);
      }

      if (variableListRef.current && loadingStatus.lastPageLoaded === 1) {
        variableListRef.current.scrollTo(0);
      }
    }, [loadingStatus.lastPageLoaded]);

    useLayoutEffect(() => {
      if (scrollTo !== undefined && scrollTo !== -1) {
        variableListRef.current?.scrollToItem(scrollTo);
      }
    }, [scrollTo]);

    useEffect(() => {
      if (
        variableListRef.current &&
        refreshRowSizes &&
        refreshRowSizes.length > 0
      ) {
        // react-window will cache all row sizes by default, so we need
        // to manually tell it to refresh the sizes to enable expanding
        // rows in the list. Prop type is an array to make sure this effect
        // triggers even if the index value does not change, which will
        // happen when expanding and then minimising the same row.
        // Also, see the documentation for `resetAfterIndex` below.
        variableListRef.current.resetAfterIndex(refreshRowSizes[0], true);
      }
    }, [refreshRowSizes]);

    let query: string | undefined;
    let showSearch: boolean | undefined;
    if (isAutocomplete(loadingStatus)) {
      query = loadingStatus.query;
      showSearch = loadingStatus.showSearch ?? !!query;
    } else {
      showSearch = itemCount > SHOW_SEARCH_THRESHOLD;
    }

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    // If there are no rows then add one row to hold empty message
    const itemCountWithLoader = hasMore
      ? itemCount + 1
      : itemCount === 0
      ? 1
      : itemCount;

    const loadingIndicator =
      pageLoading && isAutocomplete(loadingStatus)
        ? LOADING_INDICATOR
        : undefined;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index: number) => !hasMore || index < itemCount;

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
      onQueryChange?.(e.currentTarget.value);
    };

    const _itemData = {
      ...itemData,
      isItemLoaded,
      renderLoadingItem,
      itemCount,
      emptyText,
      renderItem,
    };

    return (
      <_wrap {...keyboardProps}>
        <a ref={firstFocusRef} tabIndex={0} />

        {showSearch && (
          <SearchInput
            autoFocus
            inputRef={searchInputRef}
            before={<SearchIcon />}
            value={query || ""}
            onChange={handleSearchChange}
            theme={searchStyle}
            after={loadingIndicator}
          />
        )}
        {renderTitle && <div>{renderTitle()}</div>}
        <_listWrap style={listStyle}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCountWithLoader}
                loadMoreItems={loadNextPage}
                threshold={20}
              >
                {({ onItemsRendered, ref }) =>
                  typeof itemSize == "number" ? (
                    <FixedSizeList
                      className="styled-scrollbar"
                      ref={(list) => {
                        // https://github.com/bvaughn/react-window/issues/417
                        // The infinite loader list ref is always a function _but_
                        // it is typed as `React.Ref`, which can be a ref object
                        // _or_ a function.
                        if (typeof ref === "function") {
                          const setInfRef = ref as (
                            instance: FixedSizeList | null
                          ) => void;
                          setInfRef(list); // Give InfiniteLoader a reference to the list
                        }
                        fixedListRef.current = list; // Set our own ref to it as well
                      }}
                      itemCount={itemCountWithLoader}
                      onItemsRendered={onItemsRendered}
                      height={height}
                      width={width}
                      itemSize={itemSize}
                      itemData={_itemData}
                      {...rest}
                    >
                      {Item}
                    </FixedSizeList>
                  ) : (
                    <VariableSizeList
                      className="styled-scrollbar"
                      ref={(list) => {
                        // https://github.com/bvaughn/react-window/issues/417
                        // The infinite loader list ref is always a function _but_
                        // it is typed as `React.Ref`, which can be a ref object
                        // _or_ a function.
                        if (typeof ref === "function") {
                          const setInfRef = ref as (
                            instance: VariableSizeList | null
                          ) => void;
                          setInfRef(list); // Give InfiniteLoader a reference to the list
                        }
                        variableListRef.current = list; // Set our own ref to it as well
                      }}
                      itemCount={itemCountWithLoader}
                      onItemsRendered={onItemsRendered}
                      height={height}
                      width={width}
                      itemSize={itemSize}
                      itemData={_itemData}
                      {...rest}
                    >
                      {Item}
                    </VariableSizeList>
                  )
                }
              </InfiniteLoader>
            )}
          </AutoSizer>
        </_listWrap>
      </_wrap>
    );
  }
);

const Item = ({ data, index, style }: ListChildComponentProps) => {
  let content: JSX.Element | null;

  if (!data.isItemLoaded(index)) {
    content = data.renderLoadingItem ? (
      data.renderLoadingItem()
    ) : (
      <FormattedMessage {...commonTexts.loading} />
    );
  } else if (data.itemCount === 0) {
    content = <FormattedMessage {...data.emptyText} />;
  } else {
    content = data.renderItem(index);
  }

  return <div style={style}>{content}</div>;
};

LazyList.defaultProps = {
  loadingStatus: INITIAL_LOADING_STATUS,
  emptyText: commonTexts.noMatches,
};

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const _listWrap = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .styled-scrollbar {
    scroll-padding: 10px;
    scrollbar-color: var(--scrollbar-color, ${vars.dark15}) transparent;

    ::-webkit-scrollbar-corner {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-color, ${vars.dark15});
      border-radius: 0.5rem;

      :hover {
        background-color: var(
          --scrollbar-hover-color,
          var(--scrollbar-color, ${vars.dark25})
        );
      }
    }
  }
`;
