/**
 * `/internal/api/*` backend endpoints that has deliberately been exposed & proxied in the BFF.
 */
export enum InternalApiPaths {
  batchSelection = "batch/selection",
  documentCopy = "api/v1/document/copy",
  batchEntryMoveInfo = "batch/move-entry-info",
  unlinkClassification = "classification/unlink",
  entryDocumentsDownload = "entry/{uuid}/download",
  entryChangelog = "entry/{uuid}/changelog",
  requiredTags = "tag/required",
}

/* Internal API interfaces */
export interface IRequiredTags {
  id: UUID;
  title: string;
  tags: {
    id: UUID;
    title: string;
  }[];
}

export interface IBatchTagParams {
  ids: UUID[];
  type: "Entry";
  action: "tag" | "untag";
  tagIds: UUID[];
}

export interface IBatchDeleteParams {
  ids: UUID[];
  type: "Entry" | "Document";
  action: "delete";
}

export interface IBatchMoveParams {
  ids: UUID[];
  type: "Entry" | "Document";
  action: "move";
  parentId: UUID;
  parentType: "Section" | "Entry";
}

export interface IBatchCopyParams {
  documentIds: UUID[];
  targetEntryId: UUID;
}

export interface IBatchMoveDocumentsToSectionParams {
  ids: UUID[];
  type: "Document";
  action: "split";
  targetId: UUID;
}

export interface IBatchMoveSingleDocumentEntriesToFolderParams {
  ids: UUID[];
  type: "Entry";
  action: "merge";
  targetId: UUID;
}

export type BatchParams =
  | IBatchDeleteParams
  | IBatchTagParams
  | IBatchMoveParams
  | IBatchMoveSingleDocumentEntriesToFolderParams
  | IBatchMoveDocumentsToSectionParams;

export interface DataServicesStatus {
  status: "processing" | "processed";
}
