import { IPermissionNode, Permission } from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import { action, observable } from "mobx";
import { AccessGroupModel } from "./AccessGroupModel";

export class ObjectPermissionsModel {
  @observable uuid: UUID;
  @observable group: AccessGroupModel;
  @observable permissions: Set<Permission>;
  @observable isRecentlyChanged: boolean = false;

  constructor(private store: AccessControlStore, json: IPermissionNode) {
    this.uuid = json.id;
    this.group = this.store.accessGroupFromJson(json.accessGroup);
    this.permissions = new Set<Permission>(json.explicitPermissions);
  }

  @action.bound
  updateFromJson(
    json: IPermissionNode,
    options?: {
      markRecentlyChanged: boolean;
    }
  ) {
    this.uuid = json.id;
    this.group = this.store.accessGroupFromJson(json.accessGroup);
    this.permissions = new Set<Permission>(json.explicitPermissions);
    if (options?.markRecentlyChanged) {
      this.isRecentlyChanged = true;
    }
  }

  @action.bound
  updatePermissions(permissions: Set<Permission>) {
    if (permissions.size > 0) {
      const fields = { explicitPermissions: Array.from(permissions) };
      this.store.saveObjectPermissions(this, fields);
    } else {
      this.store.deletePermissions(this.uuid);
    }
  }

  toJson(): IPermissionNode {
    return {
      id: this.uuid,
      accessGroup: this.group.toJson(),
      explicitPermissions: Array.from(this.permissions),
    };
  }
}
