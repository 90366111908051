import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import addMonths from "date-fns/addMonths";
import styled from "styled-components";
import { Checkbox, Select } from "@web/elements";

export const SetReminder = (p: {
  referenceDate: Date;
  options: number[];
  defaultOption: number;
  onChange: (reminderDate: Date | undefined) => void;
}) => {
  const eligibleOptions = getEligibleOptions(p.options, p.referenceDate);
  const intl = useIntl();
  const [isChecked, setChecked] = useState(true);
  const [option, setOption] = useState<number>(
    eligibleOptions.includes(p.defaultOption)
      ? p.defaultOption
      : eligibleOptions[0]
  );

  useEffect(() => {
    p.onChange(
      isChecked && eligibleOptions.length !== 0
        ? addMonths(p.referenceDate, -option)
        : undefined
    );
  }, [isChecked, option]);

  if (eligibleOptions.length === 0) {
    return null;
  }

  return (
    <_wrap>
      <_checkbox
        label={intl.formatMessage(texts.checkboxLabel)}
        checked={isChecked}
        onChange={(e) => setChecked(e.target.checked)}
      />

      <_select
        aria-label={intl.formatMessage(texts.selectLabel)}
        name="reminder-select"
        value={option}
        onChange={(e) => setOption(Number(e.target.value))}
      >
        {eligibleOptions.map((option) => (
          <option
            key={option}
            value={option}
            data-testid={"reminder-option-" + option}
          >
            {intl.formatMessage(texts.optionLabel, { month: option })}
          </option>
        ))}
      </_select>
    </_wrap>
  );
};

/**
 * Returns the options that are eligible for the given reference date.
 * @param options
 * @param referenceDate
 */
const getEligibleOptions = (options: number[], referenceDate: Date) => {
  const today = new Date(new Date().toDateString());
  const reference = new Date(referenceDate.toDateString());
  return options.filter((option) => addMonths(reference, -option) >= today);
};

const _wrap = styled.div`
  display: grid;
`;

const _checkbox = styled(Checkbox)`
  font-size: 0.875rem;
`;

const _select = styled(Select)`
  width: 100%;
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
`;

export const texts = defineMessages({
  checkboxLabel: {
    id: "entry.attributes.reminder.label",
    defaultMessage: "Create reminder",
  },
  selectLabel: {
    id: "entry.attributes.reminder.select",
    defaultMessage: "Select date for reminder",
  },
  optionLabel: {
    id: "entry.attributes.reminder.option",
    defaultMessage:
      "{month} {month, plural, one {month} other {months}} in advance",
  },
});
