import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { vars } from "@web/styles";
import { media } from "@web/styles/utils";
import { useStores } from "@web/stores/context";
import { TagHeader } from "@web/components/Tag/TagHeader";
import { useConfig } from "@config/context";
import { SearchContainer } from "./SearchContainer";
import FilterContainer from "./FilterContainer";
import SectionContainer from "./SectionContainer";

export const TopbarContainer = observer(() => {
  const { filterStore, tagStore, sectionStore } = useStores();
  const { hasFeature } = useConfig();

  return (
    <_topbar isGlobalSearch={sectionStore.isGlobalSearch}>
      {hasFeature("sectionSelect") && (
        <_section>
          <SectionContainer />
        </_section>
      )}
      <_search>
        <SearchContainer />
      </_search>
      <_filter>
        <FilterContainer />
      </_filter>
      {!sectionStore.isGlobalSearch && (
        <_tagHeader>
          <TagHeader
            highlightedTag={filterStore.highlightedTag}
            onSaveStatus={tagStore.setTagStatus}
            onCloseClick={() => (filterStore.highlightedTag = undefined)}
          />
        </_tagHeader>
      )}
    </_topbar>
  );
});

const _topbar = styled.div<{ isGlobalSearch?: boolean }>`
  background: ${(p) => (p.isGlobalSearch ? vars.secondary : vars.body)};
  color: ${vars.bodyFg};
  position: relative;
  padding: 1rem 1rem 0;
  grid-gap: 0.5rem;
  height: 100%;
  max-width: 100%;

  ${(p) =>
    p.isGlobalSearch &&
    `
    --svg-fill: ${vars.secondaryFg};
    color: ${vars.secondaryFg};
  `}
  ${media("desktop")} {
    display: grid;
    align-items: center;
    grid-template:
      "section search"
      "filter filter"
      "tagheader tagheader"
      / auto 1fr;
  }

  ${media("compact")} {
    display: grid;
    grid-template:
      "section search filter"
      "tagheader tagheader tagheader"
      / 33% min-content 33%;
  }
`;

const _section = styled.div`
  grid-area: section;

  ${media("desktop")} {
    height: 42px;
    min-width: 150px;
    display: flex;
    align-items: center;
  }
`;

const _search = styled.div`
  grid-area: search;
`;

const _filter = styled.div`
  grid-area: filter;
`;

const _tagHeader = styled.div`
  grid-area: tagheader;
`;
