import styled, { css } from "styled-components";
import { vars } from "@web/styles";
import { StyledButton } from "@web/elements/Button/styles";

export const Menu = styled.menu`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0.25rem;
`;

export const MenuItem = styled(StyledButton).attrs({
  variant: "blank",
})<{
  selected?: boolean;
  danger?: boolean;
  disabled?: boolean;
}>`
  border: 1px solid transparent;
  justify-content: flex-start;
  white-space: nowrap;
  display: flex;

  :hover {
    border-color: ${vars.greyLight30};
  }

  ${(p) =>
    p.selected &&
    css`
      background: ${vars.primaryDark20};
      color: ${vars.primaryFg};
    `}

  ${(p) =>
    p.danger &&
    css`
      color: ${vars.danger};
    `}
  
  
  svg {
    color: ${vars.primary};
  }
`;

MenuItem.displayName = "MenuItem";
