import { parseToRgb } from "polished";
import { SimpleTheme } from "@web/styles/themes";
import { HISTORY_STORAGE_KEY } from "@web/stores";
import { SupportedLocale } from "@web/translations";

const LOCALE_STORAGE_ITEM_KEY = "afe_locale";

/**
 * Calculates top/left ensuring it doesn't overflow the viewport.
 */
export const containInViewport = (
  rect: {
    top: number;
    left: number;
    width: number;
    height: number;
  },
  edge = 0
) => {
  const docRect = getDocumentRect();
  return {
    top: Math.max(
      Math.min(rect.top, docRect.height - rect.height - edge),
      edge
    ),
    left: Math.max(
      Math.min(rect.left, docRect.width - rect.width - edge),
      edge
    ),
    viewportHeight: docRect.height,
    viewportWidth: docRect.width,
  };
};

/**
 * Creates an element and measures its dimensions.
 * Useful when document is within an iframe and has no height due to eg. fixed children etc.
 */
export const getDocumentRect = () => {
  const el = document.createElement("div");
  Object.assign(el.style, {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
  });
  document.body.appendChild(el);
  const out = el.getBoundingClientRect();
  document.body.removeChild(el);
  return out;
};

/**
 * iOS Safari uses scrollingElement for scrollTop for some reason.
 */
export const getScrollElement = () =>
  document.scrollingElement || document.documentElement;

export const getScrollTop = () => getScrollElement().scrollTop;

/**
 * Adds a colon `:` to the time zone (Safari and IE11).
 * @param {string} date - 2010-01-01T00:00:00.000+0100
 * @returns {string} fixed date - 2010-01-01T00:00:00.000+01:00
 */
export const fixDateStringFormat = (date: string) => {
  const pos = date.length - 2;

  if (date[date.length - 3] === ":") {
    return date;
  }

  return [date.slice(0, pos), ":", date.slice(pos)].join("");
};

export const serialize = (obj: { [key: string]: string }): string =>
  Object.keys(obj)
    .reduce((a: string[], k: string) => {
      a.push(k + "=" + encodeURIComponent(obj[k]));
      return a;
    }, [])
    .join("&");

/**
 * Returns the text up to the last dot '.' in provided file path.
 * If the name starts with a dot '.', returns the full name including the first dot.
 * If the name has no dots, returns the full name.
 * @param filePath
 */
export const pathWithoutExtension = (filePath: string | undefined): string => {
  if (typeof filePath === "undefined") {
    return "";
  }
  if (filePath.charAt(0) === ".") {
    return filePath;
  }

  const dotIndex = filePath.lastIndexOf(".");
  if (dotIndex === -1) {
    return filePath;
  }

  const beforeDot = filePath.charAt(dotIndex - 1);
  if (beforeDot === "/") {
    return filePath;
  }

  return filePath.substring(0, dotIndex);
};

/**
 * Returns the text after the last dot '.' in the name.
 * If the name has no dots, returns an empty string.
 * @param name File name to parse
 */
export const fileExtension = (name: string): string => {
  if (typeof name === "undefined") {
    return "";
  }
  const dot = name.lastIndexOf(".");
  if (dot === -1) {
    return "";
  }
  return name.substring(dot + 1);
};

export const getRootDir = (path: string | undefined) => {
  if (!path) {
    return;
  }

  const split = path.split("/");

  if (split.length === 1) {
    // Not a directory
    return;
  }

  if (split[0] === "") {
    // Is directory with leading slash
    return split[1];
  }

  // Is directory without leading slash
  return split[0];
};

export const fileDir = (path: string | undefined): string => {
  if (typeof path === "undefined") {
    return "";
  }
  const lastSlash = path.lastIndexOf("/");
  if (lastSlash === -1) {
    return "";
  }
  return path.substring(0, lastSlash);
};

export const errorMessage = (err: any): string | undefined => {
  if (typeof err === "undefined") {
    return undefined;
  }
  if (typeof err === "string") {
    return err;
  }
  if (typeof err === "object" && typeof err.message === "string") {
    return err.message;
  }
};

/**
 * Escape text so it's suitable for using inside a RegExp expression.
 * Source: https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
 * @param text Text to escpe
 */
export const escapeForRegExp = (text: string): string => {
  return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const sortStringsCaseInsensitive = (a: string, b: string) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a > b ? 1 : a < b ? -1 : 0;
};

const parseFeatureParams = (params: string | null) => {
  if (!params) {
    return {};
  }

  return params.split(",").reduce((acc: Record<string, boolean>, value) => {
    // Features starting with ! should be disabled
    if (value.charAt(0) === "!") {
      acc[value.substr(1)] = false;
    } else {
      acc[value] = true;
    }
    return acc;
  }, {});
};

export const getFeaturesFromUrl = (): Record<string, boolean> => {
  const qs = window.location.search;
  const urlParams = new URLSearchParams(qs).get("features");

  if (!urlParams) {
    // If no params are set in URL, check session storage
    try {
      const sessionParams = sessionStorage.getItem("features");
      return parseFeatureParams(sessionParams);
    } catch (err) {
      return {};
    }
  }

  // Store params in session storage to keep between reloads
  try {
    sessionStorage.setItem("features", urlParams);
  } catch (err) {
    // fall through
  }

  return parseFeatureParams(urlParams);
};

export const getLocaleFromStorage = () => {
  const locale = localStorage.getItem(LOCALE_STORAGE_ITEM_KEY);

  if (!locale) {
    return null;
  }

  return Object.values(SupportedLocale).includes(locale as SupportedLocale)
    ? (locale as SupportedLocale)
    : null;
};

export const storeLocale = (locale: SupportedLocale) => {
  try {
    localStorage.setItem(LOCALE_STORAGE_ITEM_KEY, locale);
  } catch (_error) {
    // fall through
  }
};

const testColorValidity = (color: string) => {
  try {
    parseToRgb(color);
    return true;
  } catch {
    console.warn(`${color} is not a parsable color format.`);
    return false;
  }
};

const parseThemeParams = (params: string | null): SimpleTheme | undefined => {
  if (!params) {
    return;
  }

  const colors = params.split(",").map((hexColor) => "#" + hexColor);

  if (colors.length !== 4 || !colors.every(testColorValidity)) {
    console.warn("The theme is not parsable. Theme discarded.");
    return;
  }

  return {
    body: colors[0],
    content: colors[1],
    primary: colors[2],
    secondary: colors[3],
  };
};

export const getThemeFromUrl = (): SimpleTheme | undefined => {
  const qs = window.location.search;
  const urlParams = new URLSearchParams(qs).get("theme");

  if (!urlParams) {
    try {
      const sessionParams = sessionStorage.getItem("theme");
      return parseThemeParams(sessionParams);
    } catch {
      return;
    }
  }

  try {
    sessionStorage.setItem("theme", urlParams);
  } catch {
    // Fall through
  }

  return parseThemeParams(urlParams);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getSearchHistoryFromLocalStore = (): string[] => {
  try {
    return JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY) ?? "[]") ?? [];
  } catch (error) {
    console.error("Failed to retrieve search history from local storage");
    return [];
  }
};
