import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import { vars } from "@web/styles";
import { LabelVariant, labelVariants } from "./Label";

export interface IRadioButtonProps {
  label: MessageDescriptor | string;
  name: string;
  value: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
  disabled?: boolean;
  iconBefore?: JSX.Element;
  variant?: LabelVariant;
}

export const RadioButton = ({
  label,
  name,
  value,
  checked,
  setChecked,
  disabled,
  iconBefore,
  variant,
}: IRadioButtonProps) => {
  const intl = useIntl();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    setChecked(event.target.checked);
  };

  const displayLabel =
    typeof label === "string" ? label : intl.formatMessage(label);

  return (
    <_label
      title={displayLabel}
      aria-label={displayLabel}
      variant={variant}
      className={checked ? "isChecked" : ""}
    >
      <_input
        type="radio"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      {iconBefore}
      <_displayLabel>{displayLabel}</_displayLabel>
    </_label>
  );
};

const _input = styled.input`
  // hide circle
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: inherit;
`;

const _label = styled.label<Pick<IRadioButtonProps, "variant">>`
  display: inline-flex;
  flex-shrink: 0;
  border-radius: 1rem;
  font-size: 0.8125rem;
  font-family: inherit;
  margin: 0;
  padding: 0.3rem 0.625rem;
  max-width: 100%;
  min-height: 1.875rem;
  line-height: normal;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  :is(button):hover:not(:disabled) {
    cursor: pointer;
  }

  &.isChecked {
    font-weight: 600;
    color: ${vars.primary};
    background-color: ${vars.info};
    border-color: ${vars.primary};
  }

  :hover {
    background: ${vars.secondaryAltLight20};
  }

  :focus {
    background: ${vars.secondaryAltLight10};
  }

  ${(p) => labelVariants[p.variant || "default"]};
`;

const _displayLabel = styled.span`
  width: 100%;
  white-space: normal;
`;
