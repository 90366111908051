import styled from "styled-components";

export const Truncate = styled.span<{ maxWidth?: string }>`
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : "100%")};
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
`;
