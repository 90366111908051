import { observable } from "mobx";
import { useState } from "react";

// See https://mobx.js.org/react-integration.html#using-local-observable-state-in-observer-components
// for explanation of mobx local state

export interface ColumnWidths {
  readonly widths: number[];
  clear: () => void;
  setAll: (widths: number[]) => void;
  setColumn: (columnIndex: number, width: number) => void;
}

export const useColumnWidths = () => {
  const [columns] = useState<ColumnWidths>(() => {
    const columnWidths: ColumnWidths = {
      widths: [],
      clear() {
        this.widths.length = 0;
      },
      setAll(widths: number[]) {
        widths.forEach((width, index) => {
          this.setColumn(index, width);
        });
      },
      setColumn(columnIndex: number, width: number) {
        this.widths[columnIndex] = Math.max(0, width);
      },
    };
    return observable(columnWidths);
  });

  return columns;
};
