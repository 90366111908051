import React, { useEffect } from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { Portal } from "@web/components/index";
import { vars, ZIndex } from "@web/styles";
import { useClickOutside } from "@web/utils/hooks/useClickOutside";

export interface IDialogProps {
  title?: MessageDescriptor;
  values?: Record<string, React.ReactText | React.ReactElement>;
  onClickOutside?: () => void;
  onEscape?: () => void;
}

export const Dialog: React.FC<IDialogProps> = (p) => {
  const intl = useIntl();
  const contentRef = useClickOutside(() => p.onClickOutside?.());

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        p.onEscape?.();
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, []);

  return (
    <Portal>
      <_wrap>
        <_dialog
          ref={contentRef}
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialogTitle"
          aria-describedby="dialogContent"
        >
          {p.title && (
            <_title id="dialogTitle">
              {p.title.id
                ? intl.formatMessage(p.title, p.values)
                : p.title.defaultMessage}
            </_title>
          )}
          <div id="dialogContent">{p.children}</div>
        </_dialog>
      </_wrap>
    </Portal>
  );
};

const _wrap = styled.div`
  background: ${vars.overlay};
  font-size: 0.875rem;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.dialog};
`;

const _dialog = styled.div`
  background: ${vars.content};
  border-radius: 4px;
  margin: auto;
  width: 400px;
  max-width: 100vw;
  padding: 1rem;
`;

const _title = styled.div`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;
