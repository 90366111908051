import React, { FC, useRef, useState } from "react";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { DocumentModel, EntryModel, UploadRequest } from "@web/models";
import { Menu, MenuItem } from "@web/components/Menu";
import { Dropdown } from "@web/components/Dropdown";
import { IconButton, MoreButton } from "@web/elements/Button";
import { Tooltip } from "@web/elements/Tooltip";
import { useMedia } from "@web/styles/utils";
import { CommentPopover } from "@web/components/Comment/CommentPopover";
import { RemindersButton } from "@web/components/Entry/Reminders/RemindersButton";
import { DocumentUploadButton } from "@web/components";
import { useStores } from "@web/stores/context";
import { commonTexts } from "@web/translations";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { texts as docTexts } from "@web/components/Document/texts";
import { DownloadIcon } from "@web/elements/Icons";
import { StyledIconButton } from "@web/elements/Button/styles";
import { useSignatureToolbarItem } from "@web/components/Document/useSignatureToolbarItem";
import {
  ConfirmationHandler,
  UploadResult,
} from "@web/components/Upload/types";
import { generateDownloadDocumentVersionUrl } from "@web/stores/ResponseParser";
import { useConfig } from "@config/context";

export interface ToolbarProps {
  entry: EntryModel;
  document?: DocumentModel;
  onUpload: (request: UploadRequest) => Promise<UploadResult | undefined>;
  getConfirmation: ConfirmationHandler;
}

type Dialogs = "comment" | null;

export const EntryToolbar: FC<ToolbarProps> = observer((p) => {
  const intl = useIntl();
  const { isCompact } = useMedia();
  const config = useConfig();
  const stores = useStores();
  const navigate = useNavigate();
  const [getConfirmation] = useConfirmationDialog();
  const [dialog, setDialog] = useState<Dialogs>(null);
  const signatureItem = useSignatureToolbarItem(p.document);

  const commentButtonRef = useRef<HTMLButtonElement>(null);

  const isLocked = p.document?.isLockedForCurrentUser;

  const canDeleteEntry = p.entry.canDelete;
  const canDownload = p.entry.documentCount > 0;
  const canUpload = p.entry.canAddDocument && config.canUploadDocuments;
  const canMove = p.entry.canUpdate && !p.entry.isDraft;
  const canComment = p.entry.canUpdate && !p.entry.isDraft;
  const canLock = p.document && p.document.canUpdate && !p.document.isLocked;
  const canUnlock = p.document && p.document.canUnlock;
  const downloadText = p.entry.isSingleDocumentEntry
    ? texts.downloadDocument
    : texts.downloadDocuments;
  const downloadUrl = p.entry.isSingleDocumentEntry
    ? generateDownloadDocumentVersionUrl(
        p.document?.currentVersion.electronicDocumentUuid
      )
    : p.entry.downloadDocumentsUrl;

  const handleMoveEntryClick = () => {
    stores.multiSelectStore.entries.addToSelection(p.entry);
    stores.multiSelectStore.entries.setAction("Move", true);
    stores.multiSelectStore.entries.specifyMoveAction("MoveToSection");
  };

  const handleMoveDocumentClick = async () => {
    const confirmed = await getConfirmation({
      title: texts.moveDocumentConfirmTitle,
      message: texts.moveDocumentConfirmMessage,
    });

    if (p.document && confirmed) {
      stores.multiSelectStore.documents.addToSelection(p.document);
      stores.multiSelectStore.documents.setAction("Move", true);
      stores.multiSelectStore.documents.specifyMoveAction("MoveToFolder");
    }
  };

  const handleCopyDocumentClick = async () => {
    const confirmed = await getConfirmation({
      title: texts.copyDocumentConfirmTitle,
      message: texts.copyDocumentConfirmMessage,
    });

    if (p.document && confirmed) {
      stores.multiSelectStore.documents.addToSelection(p.document);
      stores.multiSelectStore.documents.setAction("Copy", true);
    }
  };

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: texts.deleteDialogTitle,
      message: texts.deleteDialogMessage,
      confirmText: commonTexts.delete,
      values: {
        title: p.entry.title || intl.formatMessage(commonTexts.untitledEntry),
        documentsCount: p.entry.documentCount || 0,
      },
    });

    if (confirmed) {
      p.entry.delete();
      stores.multiSelectStore.entries.removeFromSelection(p.entry);
      navigate(stores.currentSearchURL(), { replace: true });
    }
  };

  const handleUnlock = async () => {
    if (!p.document?.isLockedForCurrentUser) {
      p.document?.deleteLock();
      return;
    }

    const confirmed = await getConfirmation({
      title: docTexts.dialogUnlockOthersDocumentTitle,
      message: docTexts.dialogUnlockOthersDocumentMessage,
      confirmText: commonTexts.unlock,
      values: {
        lockedBy: p.document.lock!.createdBy,
      },
    });

    if (confirmed) {
      p.document.deleteLock();
    }
  };

  const closeDialog = () => setDialog(null);

  const renderCommentDialogInPopover = () => (
    <CommentPopover
      ref={commentButtonRef}
      onAddedComment={onAddedComment}
      onClose={closeDialog}
    />
  );

  const onAddedComment = (comment: string) => {
    p.entry.createComment(comment);
  };

  if (isCompact) {
    if (p.entry.isReadOnly && !canDownload) {
      return null;
    }

    return (
      <>
        <Dropdown toggle={(ref) => <MoreButton ref={ref} />}>
          <Menu>
            {canDownload && (
              <MenuItem as="a" href={downloadUrl} download>
                {intl.formatMessage(downloadText)}
              </MenuItem>
            )}
            {canDeleteEntry && (
              <MenuItem disabled={isLocked} onClick={handleDelete}>
                {intl.formatMessage(texts.deleteEntry)}
              </MenuItem>
            )}
            {canMove && (
              <MenuItem onClick={handleMoveEntryClick}>
                {intl.formatMessage(texts.moveEntry)}
              </MenuItem>
            )}
            {canMove && p.entry.isSingleDocumentEntry && (
              <MenuItem onClick={handleMoveDocumentClick}>
                {intl.formatMessage(texts.moveDocument)}
              </MenuItem>
            )}
            {p.entry.isSingleDocumentEntry && (
              <MenuItem onClick={handleCopyDocumentClick}>
                {intl.formatMessage(texts.copyDocument)}
              </MenuItem>
            )}
            {canComment && (
              <MenuItem
                onClick={() => setDialog("comment")}
                ref={commentButtonRef}
              >
                {intl.formatMessage(texts.commentEntry)}
              </MenuItem>
            )}
            {canLock && (
              <MenuItem
                disabled={p.document?.newestVersion.isSentForSignature}
                onClick={p.document?.createLock}
              >
                {intl.formatMessage(docTexts.tooltipLockDocument)}
              </MenuItem>
            )}
            {canUnlock && (
              <MenuItem onClick={handleUnlock}>
                {intl.formatMessage(docTexts.tooltipUnlockDocument)}
              </MenuItem>
            )}
            {signatureItem && (
              <MenuItem
                disabled={signatureItem.disabled}
                onClick={signatureItem.onClick}
              >
                {intl.formatMessage(docTexts.tooltipSendForSignature)}
              </MenuItem>
            )}
          </Menu>
        </Dropdown>

        {/**
         * Because of the <Dropdown> closes & thereby unmounts itself as soon as an item has
         * been clicked, it's important for the dialog to be rendered *outside* the <Dropdown>.
         *
         * Otherwise the dialog will also be unmounted and in practise never displayed, because
         * it will also be unmounted when the <Dropdown> closes.
         */}
        {dialog === "comment" && renderCommentDialogInPopover()}
      </>
    );
  }

  return (
    <>
      <RemindersButton entry={p.entry} tooltipText={texts.reminders} />
      {p.entry.isSingleDocumentEntry && (
        <IconButton
          icon="CopyIcon"
          onClick={handleCopyDocumentClick}
          text={intl.formatMessage(texts.copyDocument)}
        />
      )}
      {canMove &&
        (p.entry.isSingleDocumentEntry ? (
          <Dropdown
            toggle={(ref) => (
              <IconButton icon="MoveIcon" text={commonTexts.move} ref={ref} />
            )}
          >
            <Menu>
              <MenuItem onClick={handleMoveEntryClick}>
                {intl.formatMessage(texts.moveEntry)}
              </MenuItem>
              <MenuItem onClick={handleMoveDocumentClick}>
                {intl.formatMessage(texts.moveDocument)}
              </MenuItem>
            </Menu>
          </Dropdown>
        ) : (
          <IconButton
            icon="MoveIcon"
            text={texts.moveEntry}
            onClick={handleMoveEntryClick}
          />
        ))}

      {canDownload && (
        <Tooltip text={downloadText}>
          <StyledIconButton
            variant="primaryInverted"
            as="a"
            download
            href={downloadUrl}
            aria-label={intl.formatMessage(downloadText)}
          >
            <DownloadIcon />
          </StyledIconButton>
        </Tooltip>
      )}
      {canDeleteEntry && (
        <IconButton
          icon="DeleteIcon"
          text={
            isLocked ? docTexts.tooltipDeleteLockedDocument : texts.deleteEntry
          }
          disabled={isLocked}
          onClick={handleDelete}
        />
      )}
      {canComment && (
        <IconButton
          icon="CommentIcon"
          text={texts.commentEntry}
          ref={commentButtonRef}
          onClick={() => setDialog("comment")}
        />
      )}
      {canLock && (
        <IconButton
          icon="LockIcon"
          text={docTexts.tooltipLockDocument}
          disabled={p.document?.newestVersion?.isSentForSignature}
          onClick={p.document?.createLock}
        />
      )}
      {canUnlock && (
        <IconButton
          icon="LockOpenIcon"
          text={docTexts.tooltipUnlockDocument}
          onClick={handleUnlock}
        />
      )}
      {canUpload && (
        <DocumentUploadButton
          entry={p.entry}
          onUpload={p.onUpload}
          getConfirmation={p.getConfirmation}
        >
          <IconButton
            icon="AddIcon"
            text={texts.addDocuments}
            aria-label={intl.formatMessage(texts.addDocuments)}
          />
        </DocumentUploadButton>
      )}
      {signatureItem && (
        <IconButton
          icon={signatureItem.icon}
          text={signatureItem.label}
          disabled={signatureItem.disabled}
          disableTooltip={false}
          onClick={signatureItem.onClick}
        />
      )}

      {dialog === "comment" && renderCommentDialogInPopover()}
    </>
  );
});

const texts = defineMessages({
  addDocuments: {
    id: "entry.header.adddocuments",
    defaultMessage: "Add documents",
  },
  commentEntry: {
    id: "entry.header.commententry",
    defaultMessage: "Comment on folder",
  },
  deleteEntry: {
    id: "entry.header.deleteentry",
    defaultMessage: "Delete folder",
  },
  downloadDocuments: {
    id: "entry.header.downloaddocuments",
    defaultMessage: "Download documents",
  },
  downloadDocument: {
    id: "document.tooltip.download",
    defaultMessage: "Download document",
  },
  moveEntry: {
    id: "entry.header.moveentry",
    defaultMessage: "Move to section",
  },
  moveDocument: {
    id: "entry.header.movedocument",
    defaultMessage: "Move to folder",
  },
  copyDocument: {
    id: "entry.header.copydocument",
    defaultMessage: "Copy to folder",
  },
  reminders: {
    id: "entry.header.reminders",
    defaultMessage: "Reminders",
  },
  deleteDialogTitle: {
    id: "entry.deletedialog.title",
    defaultMessage: "Delete folder?",
  },
  deleteDialogMessage: {
    id: "entry.deletedialog.message",
    defaultMessage: `This will delete <em>{title}</em>{documentsCount, plural,
        =0 {}
        one { and the document}
        other { and its # documents}
      }.`,
  },
  moveDocumentConfirmTitle: {
    id: "entry.movedocumentdialog.title",
    defaultMessage: "Move document to a different folder?",
  },
  moveDocumentConfirmMessage: {
    id: "entry.movedocumentdialog.message",
    defaultMessage: "Note that the current tags and activity will be lost.",
  },
  copyDocumentConfirmTitle: {
    id: "entry.copydocumentdialog.title",
    defaultMessage: "Copy document to a different folder?",
  },
  copyDocumentConfirmMessage: {
    id: "entry.copydocumentdialog.message",
    defaultMessage: "Note that the current tags and activity will be lost.",
  },
});
