import parseUrl from "url-parse";
import { FluidObject } from "gatsby-image";

export enum ImageFormat {
  NO_CHANGE = "",
  WEBP = "webp",
  JPG = "jpg",
  PNG = "png",
}

export class FluidPreviewArgs {
  maxWidth!: number;
  maxHeight?: number;
  sizes!: string;
  toFormat!: ImageFormat;
}
export interface PreviewOptions {
  id: string | number;
  extension: string;
  args: FluidPreviewArgs;
}

interface ImageDimensions {
  width: number;
  height: number;
  aspectRatio: number;
}

export interface ImageAsset {
  url: string;
  assetId: string;
  extension: string;
  dimensions: ImageDimensions;
  base64: string;
  tracedSVG: string;
}

export interface StoredMetaData {
  width: number;
  height: number;
  base64: string;
  tracedSVG?: string;
}

export const DEFAULT_FIXED_WIDTH = 400;
export const DEFAULT_FLUID_MAX_WIDTH = 800;
const sizeMultipliersFluid = [0.25, 0.5, 1, 1.5, 2, 3];

function convertToFormat(url: string, toFormat: string) {
  const parsed = parseUrl(url, true);
  const filename = parsed.pathname.replace(/.*\//, "");
  const extension = filename.replace(/.*\./, "");
  const isConvertedToTarget = parsed.query.fm === toFormat;
  const isOriginal = extension === toFormat;

  if (isConvertedToTarget && isOriginal) {
    const { fm, ...params } = parsed.query;
    parsed.set("query", params);
    return parsed.toString();
  }

  if (isConvertedToTarget || isOriginal) {
    return url;
  }

  const newQuery = { ...parsed.query, fm: toFormat };
  parsed.set("query", newQuery);
  return parsed.toString();
}

function isWebP(url: string) {
  const isConverted = url.includes("fm=webp");
  const isOriginal = /[a-f0-9]+-\d+x\d+\.webp/.test(url);
  return isConverted || isOriginal;
}

export function getFluidGatsbyImage(
  image: ImageAsset,
  args: FluidPreviewArgs
): FluidObject {
  const { dimensions, extension, tracedSVG, base64 } = image;

  const url = `${image.url}${image.url.includes("?") ? "&" : "?"}`;

  const maxWidth = args.maxWidth || DEFAULT_FLUID_MAX_WIDTH;
  let desiredAspectRatio = dimensions.aspectRatio;

  // If we're cropping, calculate the specified aspect ratio
  if (args.maxHeight) {
    desiredAspectRatio = maxWidth / args.maxHeight;
  }

  const maxHeight =
    args.maxHeight || Math.round(maxWidth / dimensions.aspectRatio);

  let forceConvert: string | null = null;
  if (args.toFormat) {
    forceConvert = args.toFormat;
  } else if (isWebP(image.url)) {
    forceConvert = "jpg";
  }

  const sizes = args.sizes || `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;
  const widths = sizeMultipliersFluid
    .map((scale) => Math.round(maxWidth * scale))
    .filter((width) => width < dimensions.width)
    .concat(dimensions.width);

  // console.log("  widths", widths);
  const initial = { webp: [] as string[], base: [] as string[] };
  const srcSets = widths
    .filter((currentWidth) => currentWidth <= dimensions.width)
    .reduce((acc, currentWidth) => {
      const currentHeight = Math.round(currentWidth / desiredAspectRatio);
      const imgUrl = `${url}w=${currentWidth}&h=${currentHeight}`;

      const webpUrl = convertToFormat(imgUrl, "webp");
      const baseUrl = convertToFormat(imgUrl, forceConvert || image.extension);
      acc.webp.push(`${webpUrl} ${currentWidth}w`);
      acc.base.push(`${baseUrl} ${currentWidth}w`);
      return acc;
    }, initial);

  const baseSrc = `${url}w=${maxWidth}&h=${maxHeight}`;
  const src = convertToFormat(baseSrc, forceConvert || extension);
  const srcWebp = convertToFormat(baseSrc, "webp");

  return {
    tracedSVG,
    base64,
    aspectRatio: desiredAspectRatio,
    src,
    srcWebp,
    srcSet: srcSets.base.join(",\n"),
    srcSetWebp: srcSets.webp.join(",\n"),
    sizes,
  };
}

export function getToFormatFromExt(extension: string) {
  // formats that might have transparency might force PNG
  return ["svg", "gif", "png", "apng", "bmp", "tiff"].includes(extension)
    ? ImageFormat.PNG
    : ImageFormat.JPG;
}

export function getFluidImageProps(
  options: PreviewOptions,
  metaData: StoredMetaData
): FluidObject {
  if (!metaData) {
    throw new Error("Missing metadata");
  }
  const { width, height, base64, tracedSVG = "" } = metaData;

  if (typeof width === "undefined" || typeof height === "undefined") {
    throw new Error("Missing width or height");
  }

  const imageAsset: ImageAsset = {
    url: `/bff/preview/resize/${options.id}.${options.extension}`,
    assetId: String(options.id),
    extension: options.extension,
    dimensions: {
      aspectRatio: width / height,
      height,
      width,
    },
    base64,
    tracedSVG,
  };

  return getFluidGatsbyImage(imageAsset, options.args);
}

export function getResizedUrl(
  id: string,
  extension: string,
  maxWidth: number,
  width: number,
  height: number
) {
  const fmt = getToFormatFromExt(extension);
  if (width > maxWidth) {
    width = maxWidth;
    height = Math.round(maxWidth / (width / height));
  }
  return `/bff/preview/resize/${id}.${extension}?w=${width}&h=${height}&fmt=${fmt}`;
}
