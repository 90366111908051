import React from "react";

export const SpreadsheetIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <rect
      x="12"
      y="17"
      width="21.3362"
      height="17.3344"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="38.666"
      y="17"
      width="21.3362"
      height="17.3344"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="12"
      y="38.668"
      width="21.3362"
      height="17.3344"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="38.666"
      y="38.668"
      width="21.3362"
      height="17.3344"
      rx="1"
      fill="currentColor"
    />
  </svg>
);
