import React from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import { DateTime } from "@web/elements";
import { DocumentModel, DocumentVersionModel } from "@web/models";
import { texts } from "./texts";

interface Props {
  document: DocumentModel;
  version: DocumentVersionModel;
}

export const SignatureDetails = observer(({ document, version }: Props) => {
  const intl = useIntl();
  const sentForSignatureDate = version.isSentForSignature
    ? version.sentForSignatureDate
    : undefined;
  const signedDate = version.isSigned ? version.signedDate : undefined;
  const showSentForSignature = !document.hasNewerSignedVersion(version);

  return (
    <>
      {showSentForSignature && sentForSignatureDate && (
        <>
          <dt>{intl.formatMessage(texts.detailsSentForSignature)}</dt>
          <dd>
            <DateTime datetime={sentForSignatureDate} />
          </dd>
        </>
      )}

      {signedDate && (
        <>
          <dt>{intl.formatMessage(texts.detailsSigned)}</dt>
          <dd>
            <DateTime datetime={signedDate} />
          </dd>
        </>
      )}
    </>
  );
});
