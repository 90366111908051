import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const Portal: React.FC = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  /**
   * Ensure we create the container element only once, in a synchronous fashion as opposed
   * to asynchronous like `useEffect()` and friends.
   *
   * That has proven important timing-wize for using components to be able to measure dimensions
   * and positions immediately upon mounting.
   */
  function getContainer() {
    if (!containerRef.current) {
      containerRef.current = document.createElement("div");
      document.body.appendChild(containerRef.current);
    }

    return containerRef.current;
  }

  useEffect(() => {
    return () => containerRef.current?.remove();
  }, []);

  /**
   * It has proven very important to be confident the dynamically created container element has
   * been *appended* into the DOM *before* `createPortal()` gets invoked.
   *
   * Our initial functional component & hooks implementation of this component did not do that,
   * but rather appended it into the DOM after the component had mounted, and it seemingly worked as expected.
   * There were some subtle nuances discovered later that made us aware of the importance of this:
   *
   * 1. Items rendered inside an `<AutoSizer>` from the react-virtualized-auto-sizer package being
   *    rendered into a `<Portal>`, ended up overflowing the `<Portal>`. But *only* the very first
   *    time that combination was rendered. If the `<Portal>` got re-opened, the issue would not
   *    happen, but rather work as expected.
   *
   *    In practise we found that `<AutoSizer>` calculated the wrong available height the first time.
   *
   * 2. Children provided to this `<Portal>` did not get their `.ref` prop invoked early enough,
   *    but rather later than expected causing issues related to resolving their underlying DOM
   *    element positions to be a challenge.
   */
  return createPortal(children, getContainer());
};
