import React from "react";

export const EmailIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <path
      d="M12.5 51.9991C12.2239 51.9991 12 51.7752 12 51.4991C12 41.8044 12 32.147 12 22.378C12 21.9538 12.4948 21.7165 12.8227 21.9856C16.6255 25.1069 32.0645 37.7391 35.3736 40.4466C35.7419 40.7479 36.2648 40.7426 36.6332 40.4414L59.1835 22.0065C59.51 21.7395 60 21.9719 60 22.3936C60 32.1225 60 41.7881 60 51.4991C60 51.7752 59.7762 51.9991 59.5 51.9991C43.8665 51.9991 28.2122 51.9991 12.5 51.9991Z"
      fill="currentColor"
    />
    <path
      d="M55.8459 19C56.3126 19 56.5248 19.5828 56.1674 19.8829C49.6114 25.3895 43.1576 30.8089 36.6615 36.2641C36.2897 36.5763 35.7473 36.5763 35.3754 36.2642C28.8994 30.8276 22.4353 25.3973 15.8697 19.883C15.5123 19.5828 15.7245 19 16.1912 19C29.4461 19 42.5632 19 55.8459 19Z"
      fill="currentColor"
    />
  </svg>
);
