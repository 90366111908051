import { defineMessages } from "react-intl";

export const texts = defineMessages({
  filters: {
    id: "searchpage.filters.filters",
    defaultMessage: "Filters",
  },
  moreFilters: {
    id: "searchpage.filters.morefilters",
    defaultMessage: "More filters",
  },
  clearAll: {
    id: "searchpage.filters.clearall",
    defaultMessage: "Clear all",
  },
  showHideFilterMenu: {
    id: "searchpage.filters.showhidefiltermenu",
    defaultMessage: "Show/hide filters",
  },
});
