import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { UploadGroup } from "@web/models";
import { UploadOverlayItem } from "./UploadOverlayItem";
import { Button, CloseButton, IconButton } from "@web/elements/Button";
import { Portal } from "@web/components";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";
import { commonTexts, MessageWithValues } from "@web/translations";
import { StyledButton } from "@web/elements/Button/styles";

interface IProps extends IEvents {
  uploads: UploadGroup[];
  uploadText: MessageWithValues | undefined;
  hasActiveUploads: boolean;
  displayedEntryId?: number;
  displayedDocumentId?: number;
  onCancelClick: () => void;
}

interface IEvents {
  onRecordClick: (recordId: number) => void;
  onDocumentClick: (documentId: number, recordId: number) => void;
}

export const UploadOverlay: React.FC<IProps> = observer((props) => {
  const { uploads, uploadText, hasActiveUploads, onCancelClick } = props;

  const intl = useIntl();
  const [isVisible, setVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    setVisible(uploads.length > 0);
  }, [uploads.length]);

  function toggleExpanded(e: React.MouseEvent) {
    e.stopPropagation();
    setExpanded(!isExpanded);
  }

  function handleCloseClick() {
    setVisible(false);
  }

  function handleRecordClick(recordId: number) {
    props.onRecordClick(recordId);
  }

  function handleDocumentClick(documentId: number, recordId: number) {
    props.onDocumentClick(documentId, recordId);
  }

  const isCurrentlyDisplayed = (group: UploadGroup) => {
    const { displayedEntryId, displayedDocumentId } = props;
    return group.isEntryGroup
      ? displayedEntryId !== undefined && displayedEntryId === group.entryId
      : displayedDocumentId !== undefined &&
          displayedDocumentId === group.jobs[0]?.result.documentId;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <_wrap>
        <_header onClick={toggleExpanded}>
          <_title>
            {uploadText && <FormattedMessage {...uploadText} />}
            {hasActiveUploads && (
              <_btnCancel onClick={onCancelClick}>
                <FormattedMessage {...commonTexts.cancel} />
              </_btnCancel>
            )}
          </_title>
          <_controls>
            {isExpanded ? (
              <IconButton
                disableTooltip
                icon="CaretDownIcon"
                text={commonTexts.minimize}
                onClick={toggleExpanded}
              />
            ) : (
              <IconButton
                disableTooltip
                icon="CaretUpIcon"
                text={commonTexts.expand}
                onClick={toggleExpanded}
              />
            )}
            {!hasActiveUploads && <CloseButton onClick={handleCloseClick} />}
          </_controls>
        </_header>
        {isExpanded && (
          <_list>
            {uploads.map((uploadGroup) => (
              <_listItem key={uploadGroup.groupId}>
                <UploadOverlayItem
                  upload={uploadGroup}
                  onEntryClick={handleRecordClick}
                  onDocumentClick={handleDocumentClick}
                  isCurrentlyDisplayed={isCurrentlyDisplayed(uploadGroup)}
                />
              </_listItem>
            ))}
          </_list>
        )}
      </_wrap>
    </Portal>
  );
});

const _wrap = styled.div`
  background: ${vars.content};
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: ${vars.shadow.z3};
  color: ${vars.contentFg};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  position: fixed;
  width: 100%;
  max-height: 90vh;
  z-index: ${ZIndex.uploader};

  ${media("desktop")} {
    right: 5.5rem;
    bottom: 0;
    max-width: 500px;
  }

  ${media("compact")} {
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const _header = styled.div.attrs({ role: "button" })`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const _title = styled.div`
  font-size: 1.25em;
  margin: 0.5rem 1rem;
`;

const _btnCancel = styled(StyledButton).attrs({ variant: "blank" })`
  margin-left: 0.5rem;
  background: ${vars.transparent};
  color: ${vars.primaryLight10};
`;

const _controls = styled.div`
  align-self: flex-start;
  margin: 0.5rem;
`;

const _list = styled.div`
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const _listItem = styled.div`
  margin-bottom: 0.25rem;
`;

const texts = defineMessages({
  cancelAllUploads: {
    id: "upload.overlay.cancelall",
    defaultMessage: "Cancel all uploads",
  },
});
