import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { AttributeFilterMap, FilterType, SelectedTagsMap } from "@web/models";
import { AttributeButton } from "./AttributeButton";

interface IProps {
  selectedTags: SelectedTagsMap;
  filters: AttributeFilterMap;
  onChange: () => void;
}

export const AttributeFilterList: FC<IProps> = observer(
  ({ selectedTags, filters, onChange }) => {
    const tags = selectedTags.tagsWithAttributes;
    if (tags.length === 0) {
      return null;
    }

    const handleChange = (definitionId: number, filter: FilterType) => {
      if (filter.type !== "list" || filter.value.length > 0) {
        filters.set(definitionId, filter);
      } else {
        filters.delete(definitionId);
      }
      onChange();
    };

    const handleClear = (definitionId: number) => {
      filters.delete(definitionId);
      onChange();
    };

    const showTitle = tags.length > 1;

    return (
      <_wrap>
        {tags.map((tag) => (
          <_section key={tag.id}>
            {showTitle && <span>{tag.title}</span>}
            {tag.attributes.map((attribute) => (
              <AttributeButton
                key={attribute.uuid}
                attribute={attribute}
                filter={filters?.get(attribute.id)}
                onChange={handleChange}
                onClearClick={handleClear}
              />
            ))}
          </_section>
        ))}
      </_wrap>
    );
  }
);

AttributeFilterList.displayName = "AttributeFilter";

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
  padding: 5px 0px;
  font-size: 13px;
  border-radius: 3px;
  flex-wrap: wrap;
`;

const _section = styled.div`
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
