import React from "react";

export const FullscreenExitIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 16.6667H7.33333V20H9.33333V14.6667H4V16.6667ZM7.33333 7.33333H4V9.33333H9.33333V4H7.33333V7.33333ZM14.6667 20H16.6667V16.6667H20V14.6667H14.6667V20ZM16.6667 7.33333V4H14.6667V9.33333H20V7.33333H16.6667Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
