import { IAccessGroupNode, ILoadData } from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import { action, observable } from "mobx";
import debounce from "lodash/debounce";
import {
  INITIAL_SEARCH_LOADING_STATUS,
  QueryLoadingModel,
} from "../DataLoadingModel";
import { AccessGroupModel } from "./AccessGroupModel";

export class AccessGroupListModel {
  @observable
  loadingStatus: QueryLoadingModel = {
    ...INITIAL_SEARCH_LOADING_STATUS,
    showSearch: true,
  };

  @observable
  accessGroups: AccessGroupModel[] = [];

  constructor(private store: AccessControlStore) {}

  @action.bound
  private resetLoadingStatus(options: { resetQuery: boolean }) {
    this.loadingStatus.pageLoading = false;
    this.loadingStatus.lastPageLoaded = 0;
    this.loadingStatus.hasMore = false;
    this.loadingStatus.itemCount = 0;
    if (options.resetQuery) {
      this.loadingStatus.query = "";
    }
  }

  @action.bound
  reset() {
    this.accessGroups = [];
    this.resetLoadingStatus({ resetQuery: true });
  }

  @action.bound
  filter(query: string) {
    this.loadingStatus.query = query;
    this.reloadDebounced();
  }

  private reloadDebounced = debounce(this.reload, 300);

  @action.bound
  reload() {
    this.accessGroups = [];
    this.resetLoadingStatus({ resetQuery: false });
    this.loadNextPage();
  }

  @action.bound
  async loadNextPage(): Promise<void> {
    if (this.loadingStatus.pageLoading) {
      return;
    }
    this.loadingStatus.pageLoading = true;

    const pageSize = 100;
    this.store.loadAccessGroups(
      this,
      this.loadingStatus.lastPageLoaded + 1,
      pageSize,
      this.loadingStatus.query
    );
  }

  @action.bound
  updateFromJson({ data, hasMore, page }: ILoadData<IAccessGroupNode>) {
    this.accessGroups.push(
      ...data.map<AccessGroupModel>((json) =>
        this.store.accessGroupFromJson(json)
      )
    );

    this.loadingStatus = {
      ...this.loadingStatus,
      lastPageLoaded: page,
      hasMore: hasMore,
      itemCount: this.accessGroups.length,
      pageLoading: false,
    };
  }
}
