import styled from "styled-components";

const Base = styled.div.attrs({ role: "button" })<{ disabled?: boolean }>`
  width: 0;
  height: 0;
  display: inline-flex;
  margin: 3px;
  ${(p) => !p.disabled && "cursor: pointer;"}
  ${(p) => p.disabled && "opacity: 0.5;"}
`;

export const ArrowUp = styled(Base)`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid currentColor;
`;

export const ArrowDown = styled(Base)`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid currentColor;
`;

export const ArrowLeft = styled(Base)`
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid currentColor;
`;

export const ArrowRight = styled(Base)`
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid currentColor;
`;
