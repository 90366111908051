import { CommentStore } from "@web/stores/CommentStore";
import { SystemAccountUUID } from "./";

export class CommentModel {
  constructor(
    private store: CommentStore,
    public uuid: UUID,
    public userId: UUID | SystemAccountUUID
  ) {
    this.store = store; // Needs to be set for mocking to work
  }

  // TODO: add check on comment 'Delete' permission if the changelog API
  // provides these permissions in the future and change this to .canDelete
  get isCreatedByCurrentUser() {
    return this.userId === this.store.loggedInUserId ? true : false;
  }

  delete() {
    return this.store.delete(this.uuid);
  }
}
