import React from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { IconButton, ButtonIconType } from "@web/elements/Button";
import { texts } from "@web/components/Document/texts";
import { useMedia } from "@web/styles/utils";
import { Menu, MenuItem } from "@web/components/Menu";
import { DocumentModel } from "@web/models";
import { Dropdown } from "@web/components/Dropdown";
import { commonTexts } from "@web/translations";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { useSignatureToolbarItem } from "@web/components/Document/useSignatureToolbarItem";

interface IDocumentToolbarProps {
  document: DocumentModel;
}

export const DocumentToolbar: React.FC<IDocumentToolbarProps> = observer(
  (p) => {
    const intl = useIntl();
    const { isCompact } = useMedia();
    const navigate = useNavigate();
    const [getConfirmation] = useConfirmationDialog();

    const handleDeleteDocument = async () => {
      const confirmed = await getConfirmation({
        title: texts.dialogDeleteDocumentTitle,
        message: texts.dialogDeleteDocumentMessage,
        confirmText: commonTexts.delete,
        values: {
          title: p.document.title,
          versionsCount: p.document.versions.length,
        },
      });

      if (confirmed) {
        p.document.delete();
        navigate(generateEntryUrl(p.document.entryId));
      }
    };

    const handleUnlockDocument = async () => {
      if (!p.document.isLockedForCurrentUser) {
        p.document.deleteLock();
        return;
      }

      const confirmed = await getConfirmation({
        title: texts.dialogUnlockOthersDocumentTitle,
        message: texts.dialogUnlockOthersDocumentMessage,
        confirmText: commonTexts.unlock,
        values: {
          lockedBy: p.document.lock!.createdBy,
        },
      });

      if (confirmed) {
        p.document.deleteLock();
      }
    };

    const items: Array<{
      icon: ButtonIconType;
      label: MessageDescriptor;
      disabled?: boolean;
      onClick?: () => void;
    }> = [
      {
        icon: "DownloadIcon",
        label: texts.tooltipDownload,
        onClick: p.document.download,
      },
    ];

    if (p.document.isLocked) {
      items.push({
        icon: "LockOpenIcon",
        label: texts.tooltipUnlockDocument,
        disabled: !p.document.canUnlock,
        onClick: handleUnlockDocument,
      });
    } else {
      items.push({
        icon: "LockIcon",
        label: texts.tooltipLockDocument,
        disabled:
          !p.document.canUpdate || p.document.newestVersion?.isSentForSignature,
        onClick: p.document.createLock,
      });
    }

    if (p.document.canDelete) {
      items.push({
        icon: "DeleteIcon",
        label: p.document.isLockedForCurrentUser
          ? texts.tooltipDeleteLockedDocument
          : texts.tooltipDeleteDocument,
        disabled: p.document.isLockedForCurrentUser,
        onClick: handleDeleteDocument,
      });
    }

    const signatureItem = useSignatureToolbarItem(p.document);
    if (signatureItem) {
      items.push(signatureItem);
    }

    return (
      <>
        {isCompact ? (
          <Dropdown
            toggle={(ref) => (
              <IconButton icon="MoreIcon" text={commonTexts.more} ref={ref} />
            )}
          >
            <Menu>
              {items.map((item, i) => (
                <MenuItem
                  key={i}
                  disabled={item.disabled ?? false}
                  onClick={item.onClick}
                >
                  <FormattedMessage {...item.label} />
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        ) : (
          <>
            {items.map((item, i) => (
              <IconButton
                key={i}
                icon={item.icon}
                text={item.label}
                disabled={item.disabled ?? false}
                disableTooltip={false}
                aria-label={intl.formatMessage(item.label)}
                onClick={item.onClick}
              />
            ))}
          </>
        )}
      </>
    );
  }
);
