import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { useConfig } from "@web/config/context";
import { RadioButton } from "@web/elements/RadioButton";
import { SupportedLocale } from "@web/translations";
import { PlanetIcon } from "@web/elements/Icons/PlanetIcon";
import { _h2 } from "./UserProfileDialog";

const LanguageSwitcher = () => {
  const intl = useIntl();
  const config = useConfig();

  const handleLanguageChange = (locale: SupportedLocale) => {
    config.setLocale(locale);
  };

  const languageSwitcherHeaderId = "language-switcher-header";

  return (
    <>
      <_h2 id={languageSwitcherHeaderId}>
        <PlanetIcon />
        {intl.formatMessage(texts.language)}
      </_h2>
      <_div role="radiogroup" aria-labelledby={languageSwitcherHeaderId}>
        {Object.values(SupportedLocale).map((locale) => (
          <RadioButton
            name="language-switcher"
            key={`language-switcher-${locale}`}
            value={locale}
            label={intl.formatMessage(texts[locale])}
            checked={config.locale === locale}
            setChecked={() => handleLanguageChange(locale)}
          />
        ))}
      </_div>
    </>
  );
};

const _div = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const texts = defineMessages({
  language: {
    id: "user.profile.menu.language",
    defaultMessage: "Language",
  },
  [SupportedLocale.EN]: {
    id: "locale.en",
    defaultMessage: "English",
  },
  [SupportedLocale.NB]: {
    id: "locale.nb",
    defaultMessage: "Norsk bokmål",
  },
});

export default LanguageSwitcher;
