import React, { SyntheticEvent } from "react";
import styled, { css } from "styled-components";
import { MessageDescriptor } from "react-intl";
import { Button } from "@web/elements/Button";
import { commonTexts } from "@web/translations";

interface IEvents {
  onCancel?: () => void;
  onApply: (evt: SyntheticEvent) => void;
}

interface IProps extends IEvents {
  cancelText: string | MessageDescriptor;
  applyText: string | MessageDescriptor;
  applyIsDisabled?: boolean;
  direction: "horizontal" | "vertical";
}

export const ActionMenu = (p: IProps) => {
  return (
    <_wrap direction={p.direction}>
      {p.onCancel && <Button text={p.cancelText} onClick={p.onCancel} />}
      <Button
        text={p.applyText}
        variant="primary"
        disabled={p.applyIsDisabled}
        onClick={p.onApply}
      />
    </_wrap>
  );
};

const verticalCss = css`
  flex-direction: column-reverse;
  width: 100%;
  flex-grow: 1;
  *:first-child {
    margin-top: 10px;
  }
`;

const horizontalCss = css`
  flex-direction: row;
  justify-content: flex-end;
  *:first-child {
    margin-right: 15px;
  }
`;

const _wrap = styled.div<{ direction: "horizontal" | "vertical" }>`
  display: flex;
  font-size: 14px;
  align-self: flex-end;
  flex-shrink: 0;
  ${(p) => (p.direction === "vertical" ? verticalCss : horizontalCss)}
`;

ActionMenu.defaultProps = {
  applyText: commonTexts.apply,
  cancelText: commonTexts.cancel,
  direction: "horizontal",
};

ActionMenu.displayName = "ActionMenu";
