import { RecentEntries } from "@web/components/Results/RecentEntries";
import { useStores } from "@web/stores/context";
import { observer } from "mobx-react";
import React, {
  createRef,
  ElementRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { HeaderItem } from "../ResultsGrid/HeaderItem";
import { ResultViewProps } from "../types";
import { AttributeTooltip } from "./AttributeTooltip";
import { ColumnTitles } from "./ColumnTitles";
import {
  AttributeHover,
  ResultsListItem,
  ResultsListItemSkeleton,
} from "./ResultsListItem";
import { ColumnWidths, useColumnWidths } from "./useColumnWidths";

type ResultListItemHandle = ElementRef<typeof ResultsListItem>;

const OBSERVED_ROW_COUNT = 100;

export const ResultsList = observer((p: ResultViewProps) => {
  const columnWidths = useColumnWidths();
  const { sectionStore } = useStores();

  const itemRefs = useRef<RefObject<ResultListItemHandle>[]>(
    Array.from({ length: OBSERVED_ROW_COUNT }).map(() => createRef())
  );

  const [attributeHover, setAttributeHover] = useState<
    AttributeHover | undefined
  >();

  // Clear column widths when changing sections
  useEffect(() => {
    columnWidths.clear();
  }, [sectionStore.selectedSection?.id]);

  useEffect(() => {
    // Don't calculate while loading
    if (p.resultState.isLoading) {
      return;
    }

    // Don't re-calculate widths if they're already set
    if (columnWidths.widths.length > 0) {
      return;
    }

    /**
     * Check if there is anything in the result view yet.
     * If there is nothing we shouldn't measure anything, so we return.
     */
    const hasItemRefs =
      (itemRefs.current[0].current?.columnWidths.length ?? 0) > 0;
    if (!hasItemRefs) {
      return;
    }

    // Collect maximum measured width of columns in the result rows
    const maxWidths: number[] = [];
    for (const item of itemRefs.current) {
      const refWidths = item.current?.columnWidths ?? [];
      for (let i = 0; i < refWidths.length; i++) {
        maxWidths[i] = Math.max(
          maxWidths[i] ?? 0,
          Math.ceil(refWidths[i] ?? 0)
        );
      }
    }

    // Find available width, subtracting some fixed space to
    // allow for the columns that aren't resizable (yet)
    const availableWidth = window.innerWidth - 480;

    // Limiting any one column to max 1/3 of the available width
    const limit = Math.floor(availableWidth / 3);
    const limitedWidths = maxWidths.map((width) => Math.min(width, limit));

    const totalWidth = limitedWidths.reduce(
      (result, width) => result + width,
      0
    );
    if (totalWidth >= availableWidth) {
      // If total width exceeds available space, scale all columns down to fit
      const scale = availableWidth / totalWidth;
      const scaledWidths = limitedWidths.map((width) =>
        Math.floor(scale * width)
      );
      columnWidths.setAll(scaledWidths);
    } else {
      // If total width is less than available width, give the extra space to first column
      const extra = availableWidth - totalWidth;
      const expandedWidths = limitedWidths.map((width, index) =>
        index === 0 ? width + extra : width
      );
      columnWidths.setAll(expandedWidths);
    }
  }, [p.resultState.isLoading]);

  useLayoutEffect(() => {
    if (p.resultState.count > 1 && !p.resultState.isLoading) {
      p.onLoadComplete();
    }
  }, [p.resultState.isLoading]);

  let itemRefIndex = 0;

  const allEntries = p.groupedEntries["all"] ?? [];

  return (
    <_wrap columnWidths={columnWidths}>
      {/* This empty HeaderItem needs to be here to keep the toolbar layout in place. */}
      <HeaderItem title="" order="newest" />

      <RecentEntries
        entries={p.recentEntries}
        onClose={p.onClearRecentEntries}
        viewType={"list"}
        onAttributeHover={setAttributeHover}
        {...p}
      />

      <ColumnTitles columnWidths={columnWidths} />

      {allEntries.map((entry) => (
        <ResultsListItem
          key={entry.uuid}
          ref={itemRefs.current[itemRefIndex++]}
          entry={entry}
          isSelected={p.selectedEntries.hasItem(entry.uuid)}
          isSelectable={p.selectedEntries.canAddToSelection}
          isGlobalSearch={sectionStore.isGlobalSearch}
          onSelect={p.onSelect}
          onEntryClick={p.onEntryClick}
          onDocumentClick={p.onDocumentClick}
          onAttributeHover={setAttributeHover}
        />
      ))}

      {p.resultState.isLoading && <LoadingResults />}

      {attributeHover && (
        <AttributeTooltip
          {...attributeHover}
          onMouseOut={() => setAttributeHover(undefined)}
        />
      )}
    </_wrap>
  );
});

const LoadingResults = () => {
  return (
    <>
      {Array.from({ length: 20 }).map((_v, i) => (
        <ResultsListItemSkeleton key={"loading-" + i} />
      ))}
    </>
  );
};

/**
 * But why observer()?
 * Column widths are changed every 10ms while resizing, and using observer()
 * with a mobx object as prop was the way to make sure only <_wrap>
 * re-renders while resizing (and not all of its children.)
 */
const _wrap = observer(
  styled.div.attrs<{ columnWidths: ColumnWidths }>((p) => {
    const style: Record<string, string> = {};
    p.columnWidths.widths.forEach((width, index) => {
      style[`--ResultsList-column-${index}`] = `${width}px`;
    });
    return { style };
  })<{ columnWidths: ColumnWidths }>`
    font-size: 1rem;
  `
);
