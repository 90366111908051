import React, { Ref, RefObject, useState } from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";

import { ActionMenu, Input, PopoverBox } from "@web/elements";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

interface Events {
  onClose: (e?: React.MouseEvent) => void;
  onAddedComment: (comment: string) => void;
  closeButtonLabel?: MessageDescriptor;
}

export const CommentPopover = React.forwardRef(
  (props: Events, ref: Ref<HTMLButtonElement>) => {
    const intl = useIntl();
    const [comment, setComment] = useState("");

    const canSave = comment && comment.trim().length > 0;

    const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    const handleSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();
      props.onAddedComment(comment);
      props.onClose();
    };

    return (
      <PopoverBox
        alignY="top"
        margin={20}
        alignX="end-left-of-trigger"
        triggerRef={ref as RefObject<HTMLElement>}
        onClickOutside={() => {
          props.onClose();
        }}
      >
        <_wrap>
          <_header>
            <h3>{intl.formatMessage(texts.addComment)}</h3>
          </_header>
          <_form onSubmit={handleSubmit}>
            <_comment>
              <Input autoFocus value={comment} onChange={onCommentChange} />
            </_comment>
            <ActionMenu
              direction="vertical"
              applyIsDisabled={!canSave}
              applyText={commonTexts.add}
              onCancel={props.onClose}
              onApply={handleSubmit}
            />
          </_form>
        </_wrap>
      </PopoverBox>
    );
  }
);

const texts = defineMessages({
  addComment: {
    id: "searchpage.toolbar.button.addcomment",
    defaultMessage: "Add comment",
  },
});

CommentPopover.displayName = "CommentPopover";

CommentPopover.defaultProps = {
  closeButtonLabel: commonTexts.close,
};

const _form = styled.form`
  padding: 0 10px;
  label {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 0 0 0;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 0.875rem;
  border-radius: 3px;
  min-width: 260px;
  box-shadow: 0px 5px 24px rgb(0 0 0 / 10%);
  padding: 8px 0;
`;

const _header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px 15px 5px;
  border-bottom: 1px solid ${vars.dark05};
  h3 {
    padding: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    flex: 1;
  }
`;

const _comment = styled.div`
  padding: 10px 0 10px 0;
`;
