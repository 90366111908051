import styled from "styled-components";

interface Props {
  color: string;
  size?: number;
}

export const StatusColor = styled.div.attrs<Props>((p) => ({
  "data-testid": `color_${p.color}`,
}))<Props>`
  font-size: ${(p) => p.size ?? 1}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;

  ::after {
    background: ${(p) => p.color};
    border-radius: 2rem;
    content: "";
    display: block;
    width: 1.25em;
    height: 0.5em;
  }
`;
