import React, { FC, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import styled from "styled-components";
import { AttributeModel, FilterType } from "@web/models";
import { Checkbox } from "@web/elements";
import { commonTexts } from "@web/translations";

interface IProps {
  filter: FilterType;
  attribute: AttributeModel;
  onChange: (val: string | number) => void;
}

export const AttributeOptionFilter: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const { attribute, filter } = p;

  useEffect(() => {
    attribute.loadOptions();
  }, []);

  if (filter.type !== "list") {
    return null;
  }

  if (attribute.optionsLoadingStatus.pageLoading) {
    return <_wrap>{intl.formatMessage(commonTexts.loading)}</_wrap>;
  }

  if (attribute.hasNoOptionsAdded) {
    return <_wrap>{intl.formatMessage(texts.empty)}</_wrap>;
  }

  const selectedOptions = filter.value;

  return (
    <_wrap>
      {attribute.optionList.map((value) => (
        <Checkbox
          key={value.uuid}
          label={value.formattedValue(intl)}
          labelMultiline={true}
          defaultChecked={selectedOptions.includes(value.rawValue)}
          onChange={() => p.onChange(value.rawValue)}
        />
      ))}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 290px;
  min-height: 50px;
  overflow-y: auto;
  padding: 5px;
  label {
    margin: 0 3px;
    padding: 8px 2px;
  }
`;

const texts = defineMessages({
  empty: {
    id: "attributes.filter.options.empty",
    defaultMessage: "No options have been added yet",
  },
});
