import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import { Params } from "react-router-dom";
import { SearchParams } from "@web/pages/SearchPage/handler";

export function mergeRouteParams(
  searchParams: URLSearchParams,
  pathParams: Params
): SearchParams {
  return {
    ...Object.fromEntries(searchParams.entries()),
    ...pathParams,
  };
}

/**
 * Special encoding required within UTF-8 Content-Disposition headers.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#Examples
 */
export function encodeRFC5987ValueChars(str: string) {
  return (
    encodeURIComponent(str)
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      .replace(/['()]/g, escape) // i.e., %27 %28 %29
      .replace(/\*/g, "%2A")
      // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      .replace(/%(?:7C|60|5E)/g, unescape)
  );
}

type QueryParams = Record<string, string | number | boolean | undefined>;
export const generateUrlQueryString = (params: QueryParams = {}): string => {
  const notEmptyParams = omitBy(params, isEmpty);
  const valuesAsString = mapValues(notEmptyParams, String);
  return new URLSearchParams(valuesAsString).toString();
};

export const generateEntryUrl = (entryId: number): string =>
  `/record/${entryId}`;

export const generateDocumentUrl = (documentId: number): string =>
  `/document/${documentId}`;
