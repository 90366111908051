import { action, computed, observable } from "mobx";
import {
  Permission,
  IClassificationNode,
  IColorStatusNode,
  IAttributeNode,
} from "@web/api/Integration/types";
import { parseColorStatusNode } from "@web/stores/ResponseParser";
import { ClassificationStore } from "@web/stores";
import {
  AttributeModel,
  ColorStatusModel,
  CreateTagModel,
  isAutocomplete,
  PermissionModel,
  SectionModel,
  TagModel,
  WritableFields,
} from ".";

export interface ClassLastUsedModel {
  [label: string]: {
    value: string;
    lastUsed: number;
  }[];
}

export class ClassModel implements PermissionModel {
  @observable readonly id: number;
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable readonly createdDate: string;
  @observable title: string;
  @observable tags: TagModel[] = [];
  @observable tagStatuses: ColorStatusModel[] = [];
  @observable attributes: AttributeModel[] = [];
  @observable isMandatory: boolean;
  @observable sectionCount?: number;
  @observable recentValues?: string[];

  @observable saving = false;

  @observable createTagState?: CreateTagModel;

  constructor(private store: ClassificationStore, node: IClassificationNode) {
    this.store = store; // Needs to be set for mocking to work
    const { tagStore, attributeStore } = this.store.rootStore;

    this.uuid = node.id;
    this.id = node.internalIdentifier;
    this.permissions = new Set(node.effectivePermissions);
    this.createdDate = node.createdDate;
    this.title = node.title;
    this.isMandatory = node.isMandatory;
    this.tags = tagStore.initTags(this.uuid);

    this.tagStatuses = node.tagStatuses
      ? node.tagStatuses.map(parseColorStatusNode)
      : [];

    this.attributes = node.attributeDefinitions
      ? node.attributeDefinitions.map(attributeStore.createAttribute)
      : [];
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get isReadOnly() {
    return !this.canDelete && !this.canUpdate;
  }

  @computed
  get canCreateTags() {
    return this.permissions.has("CreateChildren");
  }

  @computed
  get recentlyUsedTags() {
    if (isAutocomplete(this.tagLoadingStatus) || this.tags.length <= 12) {
      return [];
    }
    return this.tags.filter((tag) => this.recentValues?.includes(tag.title));
  }

  @computed
  get hasNoTags() {
    return (
      this.tagLoadingStatus.itemCount === 0 &&
      !this.tagLoadingStatus.hasMore &&
      !isAutocomplete(this.tagLoadingStatus)
    );
  }

  @computed
  get hasAttributesDefined() {
    return this.tagStatuses.length > 0 || this.attributes.length > 0;
  }

  @computed
  get isRecentlyCreated() {
    return Date.now() - new Date(this.createdDate).getTime() < 1000 * 60;
  }

  @computed
  get tagLoadingStatus() {
    const { tagStore } = this.store.rootStore;
    return tagStore.getLoadingStatus(this.uuid);
  }

  loadMoreTags = () => {
    const { tagStore } = this.store.rootStore;
    return tagStore.loadTags(
      this.uuid,
      this.tagLoadingStatus.lastPageLoaded + 1
    );
  };

  @action.bound
  updateState(fields: Partial<ClassModel>) {
    Object.assign(this, fields);
  }

  @action.bound
  updateFromJson(json: Partial<IClassificationNode>) {
    if (json.title) {
      this.title = json.title;
    }

    if (json.tagStatuses) {
      this.tagStatuses = json.tagStatuses
        ? json.tagStatuses.map(parseColorStatusNode)
        : [];
    }

    if (json.isMandatory !== undefined) {
      this.isMandatory = json.isMandatory;
    }
  }

  @action.bound
  addStatusFromJson(json: IColorStatusNode) {
    this.tagStatuses.push(parseColorStatusNode(json));
  }

  @action.bound
  addAttributeFromJson(json: IAttributeNode) {
    const { attributeStore } = this.store.rootStore;
    this.attributes.push(attributeStore.createAttribute(json));
  }

  @action.bound
  removeStatus(statusId: UUID) {
    this.tagStatuses = this.tagStatuses.filter((st) => st.uuid !== statusId);

    for (const tag of this.tags) {
      if (tag.status?.uuid === statusId) {
        tag.status = undefined;
      }
    }
  }

  @action.bound
  updateRecentlyUsedTags(
    localStorageData: {
      value: string;
      lastUsed: number;
    }[]
  ) {
    this.recentValues = localStorageData
      .sort((a, b) => (a.lastUsed > b.lastUsed ? -1 : 0))
      .slice(0, 3)
      .map((c) => c.value);
  }

  @action.bound
  setIsMandatory(isMandatory: boolean) {
    this.isMandatory = isMandatory;
  }

  update = (newTitle: string, isMandatory: boolean) => {
    return this.store.updateClassification(this, newTitle, isMandatory);
  };

  delete() {
    return this.store.deleteClassification(this);
  }

  createTagStatus(fields: WritableFields<ColorStatusModel>) {
    return this.store.createTagStatus(this, fields);
  }

  updateTagStatus(
    status: ColorStatusModel,
    fields: WritableFields<ColorStatusModel>
  ) {
    return this.store.updateTagStatus(this, fields, status);
  }

  deleteTagStatus(uuid: UUID) {
    return this.store.deleteTagStatus(this, uuid);
  }

  loadSectionCount() {
    if (this.sectionCount) {
      return;
    }
    return this.store.getSectionCount(this);
  }

  unlinkFromSection(section: SectionModel) {
    this.store.unlinkClassification(this, section);
  }

  createAttribute = (fields: WritableFields<AttributeModel>) => {
    this.store.createAttribute(this, fields);
  };

  createTag = (tagTitle: string) => {
    const { tagStore } = this.store.rootStore;
    return tagStore.createTag(this, tagTitle);
  };

  verifyNewTagTitle = (potentialTitle: string, excludeTitle?: string) => {
    const { tagStore } = this.store.rootStore;
    const exclude = excludeTitle ? [excludeTitle] : undefined;
    return tagStore.getSimilarTags(this.uuid, potentialTitle, exclude);
  };

  // TODO: move these to tag domain object when possible
  deleteTag = (tag: TagModel) => {
    const { tagStore } = this.store.rootStore;
    return tagStore.deleteTag(this, tag);
  };

  renameTag = (tag: TagModel, newTitle: string) => {
    const { tagStore } = this.store.rootStore;
    return tagStore.updateTag(this, tag, newTitle);
  };

  setStatusForTag = (tag: TagModel, status: ColorStatusModel | undefined) => {
    const { tagStore } = this.store.rootStore;
    return tagStore.setTagStatus(tag, status);
  };

  createAttributeForTag = (
    tag: TagModel,
    fields: WritableFields<AttributeModel>
  ) => {
    const { tagStore } = this.store.rootStore;
    return tagStore.createAttribute(tag, fields);
  };
}
