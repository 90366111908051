import React from "react";
import { DocumentModel } from "@web/models";
import { SignedDocumentIcon, SigningDocumentIcon } from "../../elements/Icons";

interface Props {
  document: DocumentModel;
}

export const DocumentSignatureBadge = ({ document }: Props) => {
  if (document.newestVersion.isSigned) {
    return <SignedDocumentIcon width="1em" height="1em" />;
  }

  if (document.newestVersion.isSentForSignature) {
    return <SigningDocumentIcon width="1em" height="1em" />;
  }

  return null;
};
