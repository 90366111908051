import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import {
  EntryHasAttributeValue,
  IRuleNode,
  ITagNode,
} from "@web/api/Integration/types";
import { RadioButton } from "@web/elements";

interface Events {
  onSelectAttribute: (attribute: string) => void;
}

interface Props extends Events {
  tags: ITagNode[];
  rule?: EntryHasAttributeValue;
  existingRules?: Array<IRuleNode>;
}

export const AttributeDefinitionSelect: FC<Props> = observer((props) => {
  const [selectedAttribute, setSelectedAttribute] = useState(
    props.rule?.definitionId || undefined
  );

  return (
    <_wrap>
      {props.tags.map((tag) => (
        <React.Fragment key={tag.id}>
          {tag.attributeDefinitions && (
            <_attributeWrapper>
              <_tagTitle>{tag.title}</_tagTitle>
              {tag.attributeDefinitions.map((attribute) => (
                <RadioButton
                  key={`input-${attribute.id}`}
                  name="attributeDefinition"
                  label={attribute.name}
                  value={attribute.id}
                  checked={selectedAttribute === attribute.id}
                  onChange={() => {
                    setSelectedAttribute(attribute.id);
                    props.onSelectAttribute(attribute.id);
                  }}
                  disabled={
                    !!props?.existingRules?.some(
                      (rule) =>
                        (rule as EntryHasAttributeValue).definitionId ===
                        attribute.id
                    )
                  }
                />
              ))}
            </_attributeWrapper>
          )}
        </React.Fragment>
      ))}
    </_wrap>
  );
});

const _wrap = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
const _attributeWrapper = styled.div``;
const _tagTitle = styled.div`
  font-weight: bold;
`;
