import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { ChangedRelatedAttributeListValues } from "@web/api/Integration/types";
import { ChangeLogEvent } from "@web/components/Entry/HistoryPane";
import { Label } from "@web/elements/Label";
import {
  ChangelogModModel,
  formatAttributeValue,
  parseAttributeValue,
} from "@web/models";
import { RelatedComment } from "./RelatedComment";

export const ListAttributeValueEvent: React.FC<{
  event: ChangelogModModel<ChangedRelatedAttributeListValues>;
  canUpdateEntry: boolean;
}> = ({ event, canUpdateEntry }) => {
  const intl = useIntl();

  const addedValues = event.changed.AttributeListValue.filter(
    (change) => change.newValue !== null
  ).map((tagChange) => tagChange.fields);
  const removedValues = event.changed.AttributeListValue.filter(
    (change) => change.newValue === null
  ).map((tagChange) => tagChange.fields);

  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.eventTime}
    >
      {addedValues.length > 0 && (
        <_eventWrap>
          <FormattedMessage
            {...textByEvent.Entry_MOD_listAttributeAdded}
            values={{
              attributes: (
                <>
                  {addedValues.map((fields, index) => (
                    <Label
                      key={"added-list-attribute-" + event.id + index}
                      title={fields.definitionTitle}
                      values={[
                        formatAttributeValue(parseAttributeValue(fields), intl),
                      ]}
                    />
                  ))}
                </>
              ),
            }}
          />
        </_eventWrap>
      )}
      {removedValues.length > 0 && (
        <_eventWrap>
          <FormattedMessage
            {...textByEvent.Entry_MOD_listAttributeRemoved}
            values={{
              attributes: (
                <>
                  {removedValues.map((fields, index) => (
                    <Label
                      key={"removed-list-attribute-" + event.id + index}
                      title={fields.definitionTitle}
                      values={[
                        formatAttributeValue(parseAttributeValue(fields), intl),
                      ]}
                    />
                  ))}
                </>
              ),
            }}
          />
        </_eventWrap>
      )}{" "}
      {event.relatedComment && (
        <RelatedComment
          canUpdateEntry={canUpdateEntry}
          commentEvent={event.relatedComment}
        />
      )}
    </ChangeLogEvent>
  );
};

const textByEvent = defineMessages({
  Entry_MOD_listAttributeAdded: {
    id: "entry.historypane.event.entry.modified.listAttribute.set",
    defaultMessage: "added {attributes}",
  },
  Entry_MOD_listAttributeRemoved: {
    id: "entry.historypane.event.entry.modified.listAttribute.removed",
    defaultMessage: "removed {attributes}",
  },
});

const _eventWrap = styled.div`
  line-height: 1.8em;
  > div {
    line-height: initial;
    margin-bottom: 2px;
    margin-right: 2px;
  }
`;
