import pick from "lodash/pick";
import { MessageDescriptor } from "react-intl";
import {
  ConditionType,
  OutcomeType,
  TargetName,
} from "@web/api/Integration/types";
import { PipelineType } from "@web/models";
import { flowEntityTexts } from "./texts";

// TODO: Make OutcomeType enumeration
export const OUTCOME_OPTIONS: Record<OutcomeType, MessageDescriptor> = {
  AddEntryComment: flowEntityTexts.outcomes.addEntryComment,
  ChangeEntryStatus: flowEntityTexts.outcomes.changeEntryStatus,
  SendSlackNotification: flowEntityTexts.outcomes.sendSlackNotification,
  SendTeamsNotification: flowEntityTexts.outcomes.sendTeamsNotification,
};

// TODO: Make ConditionType enumeration
export const CONDITION_OPTIONS: Record<ConditionType, MessageDescriptor> = {
  EntryHasTag: flowEntityTexts.outcomes.entryHasTag,
  EntryHasSection: flowEntityTexts.outcomes.entryHasSection,
  UserBelongsToGroup: flowEntityTexts.outcomes.userBelongsToGroup,
  TagHasClassification: flowEntityTexts.outcomes.tagHasClassification,
};

export function getOutcomeOptions(
  type?: PipelineType
): Partial<typeof OUTCOME_OPTIONS> {
  switch (type) {
    case "notification":
    case "reminder":
      return pick(OUTCOME_OPTIONS, [
        "SendSlackNotification",
        "SendTeamsNotification",
      ]);
    default:
      return pick(OUTCOME_OPTIONS, ["AddEntryComment", "ChangeEntryStatus"]);
  }
}

export function getConditionOptions(
  target: TargetName
): Partial<typeof CONDITION_OPTIONS> {
  switch (target) {
    case "Tag":
      return pick(CONDITION_OPTIONS, ["TagHasClassification"]);
    case "Entry":
      return pick(CONDITION_OPTIONS, [
        "EntryHasTag",
        "EntryHasSection",
        "UserBelongsToGroup",
      ]);
  }
}
