import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { UpdateBanner } from "@web/elements/UpdateBanner";
import { EntryModel } from "@web/models";

interface Props {
  entry: EntryModel;
}

export const EntryUpdateBanner = (p: Props) => {
  const intl = useIntl();

  if (p.entry.hasUpdate === "documentCount") {
    return (
      <UpdateBanner
        onClick={
          p.entry.isSingleDocumentEntry
            ? p.entry.reload
            : p.entry.reloadDocuments
        }
      >
        {intl.formatMessage(texts.updatesHasDocumentCount)}
        <strong>{intl.formatMessage(texts.updatesReloadDocuments)}</strong>
      </UpdateBanner>
    );
  }

  return null;
};

const texts = defineMessages({
  updatesHasDocumentCount: {
    id: "entry.updates.hasDocumentCount",
    defaultMessage: "Document(s) has been uploaded/deleted",
  },
  updatesReloadDocuments: {
    id: "entry.updates.reloadDocuments",
    defaultMessage: "Reload documents",
  },
});
