import { isEmpty } from "lodash";
import { action, observable } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import {
  AttributeModel,
  AttributeValuesParent,
  AttributeValuesMapModel,
  ClassModel,
  TagModel,
} from "@web/models";
import { RootStore } from ".";
import { AttributeValueModel } from "@web/models/AttributeValueModel";
import {
  IAttributeNode,
  IAttributeValueNode,
  IUpdateLinkedObjects,
} from "@web/api/Integration/types";
import { AppConfig } from "@config/index";

type ParentUUID = UUID;
type AttributeValueUUID = UUID;

export type AttributeReminder = { title: string; date: Date };

export class AttributeStore {
  /* Cached attribute values for entries that has been opened. **/
  @observable
  valueCache = new Map<ParentUUID, AttributeValuesMapModel>();
  @observable
  attributeValueCache = new Map<AttributeValueUUID, AttributeValueModel>();
  @observable
  loggedinUserId: string;

  constructor(
    private api: Api,
    private rootStore: RootStore,
    config: AppConfig
  ) {
    this.loggedinUserId = config.loggedInUser.id;
  }

  /**
   * Create a new AttributeModel object from server data.
   * The attributes belongs to either tags OR classifications.
   */
  @action.bound
  createAttribute(node: IAttributeNode): AttributeModel {
    return new AttributeModel(this, node);
  }

  @action.bound
  updateAttribute = async (attribute: AttributeModel, name: string) => {
    const { messageStore } = this.rootStore;
    try {
      await this.api.updateAttribute(attribute.uuid, name);
      attribute.name = name;
      messageStore.addMessage({
        type: "success",
        title: texts.updateSuccess,
      });
    } catch (e) {
      console.error("Failed to update attribute", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.updateFailed,
      });
    }
  };

  @action.bound
  deleteAttribute = async (
    attribute: AttributeModel,
    parent: TagModel | ClassModel
  ) => {
    const { messageStore } = this.rootStore;
    try {
      parent.saving = true;
      await this.api.deleteAttribute(attribute.uuid);
      const removeIndex = parent.attributes.findIndex(
        (a) => a.uuid === attribute.uuid
      );
      if (removeIndex > -1) {
        parent.attributes.splice(removeIndex, 1);
      }

      messageStore.addMessage({
        type: "success",
        title: texts.deleteSuccess,
      });
    } catch (e) {
      console.error("Failed to delete attribute", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.deleteFailed,
      });
    } finally {
      parent.saving = false;
    }
  };

  valuesForEntry(
    entryId: UUID,
    json?: IAttributeValueNode[]
  ): AttributeValuesMapModel {
    const existing = this.valueCache.get(entryId);
    if (existing) {
      if (json) {
        existing.updateFromJson(json, { clearFirst: true });
      }
      return existing;
    } else {
      const values = new AttributeValuesMapModel(
        this,
        {
          objectType: "entry",
          uuid: entryId,
        },
        json
      );
      this.valueCache.set(entryId, values);
      return values;
    }
  }

  valuesForTag(
    tagId: UUID,
    json?: IAttributeValueNode[]
  ): AttributeValuesMapModel {
    const existing = this.valueCache.get(tagId);
    if (existing) {
      if (json) {
        existing.updateFromJson(json, { clearFirst: true });
      }
      return existing;
    } else {
      const values = new AttributeValuesMapModel(
        this,
        {
          objectType: "tag",
          uuid: tagId,
        },
        json
      );
      this.valueCache.set(tagId, values);
      return values;
    }
  }

  attributeValueFromJson(json: IAttributeValueNode): AttributeValueModel {
    const existing = this.attributeValueCache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    } else {
      const value = new AttributeValueModel(this, json);
      this.attributeValueCache.set(json.id, value);
      return value;
    }
  }

  @action.bound
  async createAttributeValue(
    parent: AttributeValuesParent,
    definitionId: UUID,
    value: string | number | Date,
    comment?: string,
    reminder?: AttributeReminder
  ): Promise<void> {
    try {
      const { data } = await this.api.createAttributeValue(
        value,
        definitionId,
        parent
      );

      this.attributeValueFromJson(data.data);
      this.valuesForEntry(parent.uuid).updateFromJson([data.data], {
        clearFirst: false,
      });

      if (parent.objectType === "entry" && (comment || reminder)) {
        const entry = this.rootStore.recordStore.entryCache.get(parent.uuid);

        if (comment) {
          entry?.createComment(comment);
        }

        if (reminder) {
          entry?.createReminder(reminder);
        }
      }
    } catch (e) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.createValueFailed,
      });
      console.error("Error while creating attribute value", e);
    }
  }

  @action
  async updateAttributeValue(
    attributeValue: AttributeValueModel,
    newValue: string | number | Date,
    comment?: string,
    reminder?: AttributeReminder
  ) {
    try {
      const { data } = await this.api.updateAttributeValue(
        attributeValue.uuid,
        newValue
      );

      attributeValue.updateFromJson(data.data);

      if (attributeValue.entryId && (comment || reminder)) {
        const entry = this.rootStore.recordStore.entryCache.get(
          attributeValue.entryId
        );

        if (comment) {
          entry?.createComment(comment);
        }

        if (reminder) {
          // TODO should match with externalId
          const associatedReminder = entry?.currentReminders.find(
            (r) => r.title === reminder.title
          );

          if (associatedReminder) {
            associatedReminder.update(reminder);
          } else {
            entry?.createReminder(reminder);
          }
        }
      }
    } catch (e) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.updateFailed,
      });

      console.error("Error while updating attribute value", e);
    }
  }

  @action.bound
  async deleteAttributeValue(attributeValue: AttributeValueModel) {
    try {
      await this.api.deleteAttributeValue(attributeValue.uuid);
      return true;
    } catch (e) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.deleteFailed,
      });
      console.error("Error while deleting attribute value", e);
    }

    return false;
  }

  @action
  async loadAttributeValues(attributeValues: AttributeValuesMapModel) {
    const { objectType, uuid } = attributeValues.parent;

    try {
      let allValues: IAttributeValueNode[] = [];

      if (objectType === "entry") {
        const data = await Promise.all([
          this.api.getEntryAttributes(uuid),
          this.api.getEntryListAttributes(uuid),
        ]);

        const singleValues = data[0].data.data;
        const listValues = data[1].data.data;
        allValues = singleValues.concat(listValues);
      } else if (objectType === "tag") {
        const data = await Promise.all([
          this.api.getTagAttributes(uuid),
          this.api.getTagListAttributes(uuid),
        ]);

        const singleValues = data[0].data.data;
        const listValues = data[1].data.data;
        allValues = singleValues.concat(listValues);
      }

      attributeValues.updateFromJson(allValues, {
        clearFirst: false,
      });
    } catch (e) {
      console.error(`Error loading attribute values for ${objectType}`, e);
    }
  }

  @action.bound
  async updateAttributeValues(
    attributeValues: AttributeValuesMapModel,
    changes: IUpdateLinkedObjects,
    comment?: string
  ) {
    const { messageStore } = this.rootStore;
    try {
      const { objectType, uuid } = attributeValues.parent;

      let newValues: IAttributeValueNode[] = [];

      if (objectType === "entry") {
        const {
          data: { data },
        } = await this.api.updateEntryAttributeListValues(uuid, changes);

        if (!!comment && !isEmpty(comment)) {
          await this.api.createComment(comment, uuid);
        }

        newValues = data.attributeValues.concat(data.attributeListValues);
      } else if (objectType === "tag") {
        const {
          data: { data },
        } = await this.api.updateTagAttributeListValues(uuid, changes);

        newValues = data.attributeValues.concat(data.attributeListValues);
      }

      attributeValues.updateFromJson(newValues, { clearFirst: true });
    } catch (error) {
      messageStore.addMessage({
        type: "failure",
        title: texts.updateFailed,
      });
      console.error("Failed to update attribute list values", error);
    }
  }

  @action.bound
  async loadAttributeOptions(attribute: AttributeModel) {
    try {
      attribute.optionsLoadingStatus.pageLoading = true;
      const { data } = await this.api.getAttributeListValues(
        attribute.uuid,
        attribute.type
      );

      attribute.updateOptionsFromJson(data.data);
    } catch (e) {
      console.error("Error while loading attribute options", e);
    } finally {
      attribute.optionsLoadingStatus.pageLoading = false;
    }
  }

  @action.bound
  async createAttributeOption(
    attribute: AttributeModel,
    value: string | number | Date
  ) {
    const { messageStore } = this.rootStore;

    try {
      const { data } = await this.api.createAttributeListValue(
        value,
        attribute.uuid
      );

      attribute.updateOptionsFromJson([data.data]);

      messageStore.addMessage({
        type: "success",
        title: texts.createOptionSuccess,
      });
    } catch (e) {
      messageStore.addMessage({
        type: "failure",
        title: texts.createOptionFailed,
      });
      console.error("Error while creating attribute option", e);
    }
  }

  @action.bound
  async updateAttributeOption(
    attribute: AttributeModel,
    id: UUID,
    newValue: string | number | Date
  ) {
    const { messageStore } = this.rootStore;
    try {
      const { data } = await this.api.updateAttributeListValue(id, newValue);
      attribute.updateOptionsFromJson([data.data]);

      messageStore.addMessage({
        type: "success",
        title: texts.updateOptionSuccess,
      });
    } catch (e) {
      messageStore.addMessage({
        type: "failure",
        title: texts.updateOptionFailed,
      });

      console.error("Error while updating attribute option", e);
    }
  }

  @action.bound
  async deleteAttributeOption(attribute: AttributeModel, optionUuid: UUID) {
    const { messageStore } = this.rootStore;
    try {
      await this.api.deleteAttributeListValue(optionUuid);
      attribute.removeOption(optionUuid);
      messageStore.addMessage({
        type: "success",
        title: texts.deleteOptionSuccess,
      });
    } catch (e) {
      messageStore.addMessage({
        type: "failure",
        title: texts.deleteOptionFailed,
      });
      console.error("Error while deleting attribute option", e);
    }
  }
}

const texts = defineMessages({
  updateFailed: {
    id: "request.attribute.update.failed",
    defaultMessage: "Could not update property",
  },
  updateSuccess: {
    id: "request.attribute.update.success",
    defaultMessage: "Property updated",
  },
  deleteFailed: {
    id: "request.attribute.delete.failed",
    defaultMessage: "Could not delete property",
  },
  deleteSuccess: {
    id: "request.attribute.delete.success",
    defaultMessage: "Property deleted",
  },
  createValueFailed: {
    id: "request.entry.attributevalue.create.failed",
    defaultMessage: "Could not add property to folder",
  },
  createOptionFailed: {
    id: "request.attribute.option.create.failed",
    defaultMessage: "Could not create list value",
  },
  createOptionSuccess: {
    id: "request.attribute.option.create.success",
    defaultMessage: "List value added",
  },
  updateOptionFailed: {
    id: "request.attribute.option.update.failed",
    defaultMessage: "Could not update list value",
  },
  updateOptionSuccess: {
    id: "request.attribute.option.update.success",
    defaultMessage: "List value updated",
  },
  deleteOptionFailed: {
    id: "request.attribute.option.delete.failed",
    defaultMessage: "Could not delete list value",
  },
  deleteOptionSuccess: {
    id: "request.attribute.option.delete.success",
    defaultMessage: "List value deleted",
  },
});
