import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { vars } from "@web/styles";
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CheckboxIndeterminateIcon,
} from "@web/elements/Icons";

export type CheckboxVariant = keyof typeof variants;

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelStyle?: React.CSSProperties;
  labelMultiline?: boolean;
  labelRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>;
  subtitle?: string;
  indeterminate?: boolean;
  iconAfter?: JSX.Element;
  iconBefore?: JSX.Element;
  variant?: CheckboxVariant;
}

const variants = {
  default: {
    bg: vars.secondaryAlt,
    fg: vars.secondaryAltFg,
    bd: vars.dark25,
    hover: {
      bg: vars.secondaryAltLight10,
      fg: vars.secondaryAltFg,
      bd: vars.dark25,
    },
    active: {
      bg: vars.secondary,
      fg: vars.secondaryFg,
      bd: vars.secondary,
    },
  },
  alt: {
    bg: vars.primary,
    fg: vars.primaryFg,
    bd: vars.dark25,
    hover: {
      bg: vars.primaryLight10,
      fg: vars.primaryFg,
      bd: vars.dark25,
    },
    active: {
      bg: vars.primary,
      fg: vars.primaryFg,
      bd: vars.dark55,
    },
  },
  form: {
    bg: vars.secondaryAltLight20,
    fg: vars.secondaryAltFg,
    bd: vars.transparent,
    hover: {
      bg: vars.secondaryAltLight20,
      fg: vars.secondaryAltFg,
      bd: vars.transparent,
    },
    active: {
      bg: vars.secondaryAltLight20,
      fg: vars.secondaryAltFg,
      bd: vars.transparent,
    },
  },
};

const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

export const Checkbox: React.FC<ICheckboxProps> = (p) => {
  const {
    variant = "default",
    label,
    labelStyle,
    subtitle,
    indeterminate,
    iconAfter,
    iconBefore,
    className,
    ...inputProps
  } = p;
  const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null);
  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node) {
        node.indeterminate = indeterminate ?? false;
        setInputNode(node);
      }
    },
    [p]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange?.(e);
  };

  const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    /**
     * A bug in Firefox stops input events from labels when holding modifier
     * keys (shift, alt, ctrl), so we'll click it programmatically instead
     * https://bugzilla.mozilla.org/show_bug.cgi?id=559506
     * */
    if (isFirefox && e.shiftKey) {
      inputNode?.click();
    }
  };

  return (
    <_wrap
      title={label}
      labelMultiline={p.labelMultiline ?? false}
      onClick={handleLabelClick}
      className={className}
    >
      <_realInput
        {...inputProps}
        ref={inputRef}
        type="checkbox"
        onChange={handleInputChange}
      />
      <_fakeInput role="presentation" variant={variants[variant]}>
        <_uncheckedIcon />
        <_checkedIcon />
        <_indeterminateIcon />
      </_fakeInput>
      {iconBefore && <_addon>{iconBefore}</_addon>}
      {label && (
        <_label
          style={labelStyle}
          multiline={p.labelMultiline ?? false}
          ref={p.labelRef}
        >
          <span>{label}</span>
          <span>{subtitle}</span>
        </_label>
      )}
      {iconAfter && <_addon>{iconAfter}</_addon>}
    </_wrap>
  );
};

const _wrap = styled.label<{ labelMultiline: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: ${(p) => (p.labelMultiline ? "flex-start" : "center")};
  flex-grow: 1;
  gap: 0.5rem;

  &:focus-within {
    border-radius: 2px;
    outline: 2px solid ${vars.secondary};
    outline-offset: -2px;
  }
`;

const _realInput = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const _uncheckedIcon = styled(CheckboxUncheckedIcon)``;
const _checkedIcon = styled(CheckboxCheckedIcon)``;
const _indeterminateIcon = styled(CheckboxIndeterminateIcon)``;

const _fakeInput = styled.span<{ variant: typeof variants.default }>`
  display: flex;
  flex-shrink: 0;

  > * {
    display: none;
  }

  ${_realInput}:not(:checked):not(:indeterminate) + & ${_uncheckedIcon} {
    display: block;
  }

  ${_realInput}:checked + & ${_checkedIcon} {
    display: block;
  }

  ${_realInput}:indeterminate + & ${_indeterminateIcon} {
    display: block;
  }

  --svg-fill: ${(p) => p.variant.bg};
  --svg-stroke: ${(p) => p.variant.bd};
  --svg-check: ${(p) => p.variant.fg};

  /**
   * We use && in this case, due to an edge case issue with self referencing in styled-components.
   * Using a single & prints the component class. A double && prints the dynamic class, which is 
   * needed in this case so that a new instance doesn't overwrite the old component class.
   *
   * Note that this increases specificity, but is not an issue in this case.
   * https://github.com/styled-components/styled-components/issues/3244
   */
  ${_wrap}:hover && {
    --svg-fill: ${(p) => p.variant.hover.bg};
    --svg-stroke: ${(p) => p.variant.hover.bd};
    --svg-check: ${(p) => p.variant.hover.fg};
  }

  ${_realInput}:checked + && {
    --svg-fill: ${(p) => p.variant.active.bg};
    --svg-stroke: ${(p) => p.variant.active.bd};
    --svg-check: ${(p) => p.variant.active.fg};
  }

  ${_realInput}:disabled + & {
    opacity: 0.45;
  }

  svg {
    vertical-align: middle;
  }
`;

const _label = styled.div<{ multiline: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: hidden;

  > span {
    ${(p) =>
      p.multiline
        ? css`
            line-height: 1.4em;
            margin-top: -1px;
            word-break: break-word;
          `
        : css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
  }

  > span:nth-of-type(2) {
    opacity: 0.8;
    font-size: 0.8em;
  }
`;

const _addon = styled.span`
  flex-shrink: 0;
`;

Checkbox.displayName = "Checkbox";
_label.displayName = "DisplayLabel";
