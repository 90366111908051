import React from "react";
import { Link } from "react-router-dom";
import { default as styled } from "styled-components";
import { VBox } from "@web/elements";

export const NotFoundPage = () => (
  <Page404 width="100%" height="100vh">
    <h1>404 Page not found!</h1>
    <Link to="/">Home</Link>
  </Page404>
);

const Page404 = styled(VBox)`
  justify-content: center;
  align-items: center;
`;
