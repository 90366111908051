import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { FocusScope } from "react-aria";
import {
  ClassModel,
  ChangeTagEvent,
  TagModel,
  TagFacetCountsModel,
  TagSearchModel,
} from "@web/models";
import { LazyList } from "@web/components";
import { useRowHeights } from "@web/components/LazyList/useRowHeights";
import { FlexBox } from "@web/elements";
import { TagRow } from "@web/components/TagSelect/TagRow";
import { TagStatusFilter } from "@web/components/TagStatus/TagStatusFilter";
import { media } from "@web/styles/utils";
import { commonTexts } from "@web/translations";
import { Button, IconButton } from "@web/elements/Button";
import { vars } from "@web/styles";
import { useKeyboardEvent } from "@web/utils/hooks/useKeyboardEvent";
import { texts } from "./texts";
import { _action, _headerWrap, _title } from "./styles";

interface Events {
  onFilterChange: (change: ChangeTagEvent) => void;
  onTagStatusChange: () => void;
  onBackClick?: (e: React.MouseEvent) => void;
  onClearClick: (e: React.MouseEvent) => void;
}

interface Props extends Events {
  classification: ClassModel;
  tagSearch: TagSearchModel;
  selectedTags: string[];
  selectedTagStatuses?: Set<number>;
  facetCounts?: TagFacetCountsModel;
}

export const TagFilter: FC<Props> = observer((p) => {
  const keys = useKeyboardEvent();
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>();
  const [menuIsOpen, openMenu] = useState(p.selectedTagStatuses?.size ?? false);
  const { getRowHeight, getRowHeightHandler, refreshRowSizes } = useRowHeights({
    spacing: 14,
    initialHeight: 30,
  });

  const { classification, tagSearch, selectedTags } = p;
  const { recentlyUsedTags, tagLoadingStatus } = classification;
  const matchingTags = tagSearch.matches;

  useEffect(() => {
    const tagIds = new Set<number>([
      ...classification.tags.map((tag) => tag.id),
      ...(matchingTags ?? []).map((tag) => tag.id),
    ]);
    p.facetCounts?.needsCountForTags(Array.from(tagIds));
  }, [tagLoadingStatus.itemCount, p.facetCounts?.tagCount]);

  const handleChange = (
    tag: TagModel,
    selected: boolean,
    selectedIndex?: number
  ) => {
    if (
      keys?.shiftKey &&
      selectedIndex !== undefined &&
      lastSelectedIndex !== undefined
    ) {
      const tags = recentlyUsedTags.concat(classification.tags);

      let range;

      if (lastSelectedIndex <= selectedIndex) {
        range = tags.slice(lastSelectedIndex + 1, selectedIndex + 1);
      } else {
        range = tags.slice(selectedIndex, lastSelectedIndex);
      }

      range.forEach((tag) =>
        p.onFilterChange({
          classificationId: classification.uuid,
          tag,
          selected,
        })
      );
    } else {
      p.onFilterChange({
        classificationId: classification.uuid,
        tag,
        selected,
      });
    }

    setLastSelectedIndex(selectedIndex);
  };

  const handleClearClick = (e: React.MouseEvent) => {
    p.onClearClick(e);
  };

  const renderTagRow = (index: number) => {
    const { selectedTags, facetCounts } = p;
    const tags = recentlyUsedTags.concat(classification.tags);
    const tag = matchingTags ? matchingTags[index] : tags[index];

    if (!tag) {
      return <></>;
    }

    const recent = index < recentlyUsedTags.length;

    return (
      <TagRow
        tag={tag}
        key={tag.id}
        index={index}
        selected={selectedTags.includes(tag.title)}
        checkboxVariant={"alt"}
        isRecent={recent}
        facetCounts={facetCounts}
        onChange={handleChange}
        onHeight={getRowHeightHandler(index)}
      />
    );
  };

  const itemCount = tagLoadingStatus.itemCount + recentlyUsedTags.length;
  const loadingStatus = { ...tagLoadingStatus, itemCount };

  return (
    <_wrap>
      <_headerWrap>
        {p.onBackClick && (
          <IconButton
            disableTooltip
            icon="CaretLeftIcon"
            text={commonTexts.back}
            variant="blank"
            onClick={p.onBackClick}
          />
        )}

        <_title>{classification.title}</_title>

        {classification.tagStatuses.length > 0 && (
          <IconButton
            disableTooltip
            icon={menuIsOpen ? "CaretUpIcon" : "CaretDownIcon"}
            text={texts.showHideFilterMenu}
            variant="blank"
            onClick={() => openMenu(!menuIsOpen)}
          />
        )}
      </_headerWrap>
      {menuIsOpen && (
        <TagStatusFilter
          selectedStatusIds={tagSearch.tagStatuses}
          options={classification.tagStatuses}
          onChange={p.onTagStatusChange}
        />
      )}
      <_listWrap>
        <FocusScope autoFocus contain>
          <LazyList
            loadingStatus={loadingStatus}
            loadNextPage={classification.loadMoreTags}
            renderItem={renderTagRow}
            onQueryChange={tagSearch.setQuery}
            searchStyle={"filter"}
            itemSize={getRowHeight}
            refreshRowSizes={refreshRowSizes}
          />
        </FocusScope>
      </_listWrap>
      {selectedTags.length > 0 && (
        <_action>
          <Button
            text={commonTexts.clear}
            variant="blank"
            onClick={handleClearClick}
          />
        </_action>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  --scrollbar-color: ${vars.light15};
  --scrollbar-hover-color: ${vars.light55};

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  outline: none;

  ${media("desktop")} {
    width: 287px;
    height: 440px;
    border-radius: 3px;
  }

  ${media("compact")} {
    height: 100%;
  }
`;

const _listWrap = styled(FlexBox)`
  padding: 0 0.5rem;
  flex-direction: column;
  flex: 1;
`;
