import React from "react";
import styled from "styled-components";
import { LightBulbIcon } from "./Icons";

export const InfoText: React.FC = (p) => {
  return (
    <_wrap>
      <LightBulbIcon width="25" height="25" />
      <div>{p.children}</div>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  svg {
    margin: 5px 10px 5px 0px;
    flex-shrink: 0;
  }
`;
