import * as React from "react";
import styled from "styled-components";
import { vars } from "@web/styles";
import { MessageDescriptor, useIntl } from "react-intl";

/*
 * ⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️
 *            Danger Danger! High Voltage!
 *   This component breaks the great walls of React
 *          May God have mercy on our souls
 * ⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️
 */

interface EditableTextProps {
  autoFocus?: boolean;
  allowEmpty?: boolean;
  maxLength?: number;
  disabled?: boolean;
  initialValue?: string;
  placeholder?: MessageDescriptor | string;
  onSave: (value: string) => void;
}

const trimText = (text: string) => text.replace(/\s{2,}/g, " ").trim();

export const EditableText = (p: EditableTextProps) => {
  const intl = useIntl();
  const maxLength = p.maxLength ?? 255;
  const textRef = React.useRef<string>(p.initialValue || "");
  const editorRef = React.useRef<HTMLDivElement>(null);

  let placeholder = "";
  if (p.placeholder !== undefined) {
    placeholder =
      typeof p.placeholder === "string"
        ? p.placeholder
        : intl.formatMessage(p.placeholder);
  }

  React.useEffect(() => {
    if (p.autoFocus) {
      editorRef.current?.focus();
    }
  }, []);

  const revert = (): void => {
    if (editorRef.current) {
      textRef.current = p.initialValue || "";
      editorRef.current.innerText = textRef.current;
    }
  };

  const save = (): void => {
    const text = trimText(textRef.current || "");

    if (editorRef.current) {
      editorRef.current.innerText = text;
    }

    if (text === p.initialValue) {
      return;
    }

    if (!text && !p.allowEmpty) {
      revert();
      return;
    }

    p.onSave(text);
  };

  const handleBeforeInput = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (e.target.innerText.length >= maxLength) {
      e.preventDefault();
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLDivElement>) => {
    textRef.current = e.target.innerText;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const plainText = e.clipboardData.getData("text/plain").slice(0, maxLength);
    document.execCommand("insertText", false, plainText);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (e.key === "Escape") {
      revert();
      editorRef.current?.blur();
    }

    if (e.key === "Enter") {
      e.preventDefault();
      editorRef.current?.blur();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleBlur = () => {
    save();
  };

  return (
    <_editor
      contentEditable={!p.disabled}
      suppressContentEditableWarning
      ref={editorRef}
      placeholder={placeholder}
      onInput={handleInput}
      onBeforeInput={handleBeforeInput}
      onBlur={handleBlur}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    >
      {p.initialValue}
    </_editor>
  );
};

const _editor = styled.div`
  cursor: text;

  &[contenteditable="true"]:hover,
  &[contenteditable="true"]:focus {
    background: ${vars.highlight};
    color: ${vars.primaryDark20};
    outline: none;
  }

  :focus {
    text-transform: none;
  }

  :empty::before {
    content: attr(placeholder);
    display: inherit;
    opacity: 0.35;
  }
`;
