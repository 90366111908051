import React from "react";
import { escapeForRegExp } from "@web/utils/helpers";
import { vars } from "@web/styles";

interface IProps {
  fallback?: string;
  fullText?: string;
  highlightText?: string;
  highlights?: Part[];
  highlightStyle?: {
    color: string;
    backgroundColor: string;
  };
}

interface Part {
  id: string;
  text: string;
  isHighlighted: boolean;
}

export const HighlightText = (p: IProps) => {
  const style = p.highlightStyle || {
    backgroundColor: vars.highlight,
    color: vars.primaryDark20,
  };

  if (!p.highlights?.length && !p.fullText && !p.highlightText) {
    return <span>{p.fallback}</span>;
  }

  const parts =
    p.highlights || getParts(p.fullText || "", p.highlightText || "");

  return (
    <span>
      {parts.map((part) =>
        part.isHighlighted ? (
          <span key={part.id} style={style}>
            {part.text}
          </span>
        ) : (
          <span key={part.id}>{part.text}</span>
        )
      )}
    </span>
  );
};

let idCounter = 0;
function createPartId(): string {
  idCounter++;
  return `highlight-part-${idCounter}`;
}

function getParts(fullText: string, highlightText: string): Part[] {
  if (highlightText === "") {
    return [{ text: fullText, isHighlighted: false, id: createPartId() }];
  }
  const regex = new RegExp(escapeForRegExp(highlightText), "gi");
  const parts: Part[] = [];
  let match: RegExpExecArray | null;
  let lastIndex = 0;
  do {
    match = regex.exec(fullText);
    if (match !== null) {
      if (match.index > lastIndex) {
        parts.push({
          text: fullText.slice(lastIndex, match.index),
          isHighlighted: false,
          id: createPartId(),
        });
      }
      parts.push({
        text: match[0],
        isHighlighted: true,
        id: createPartId(),
      });
      lastIndex = regex.lastIndex;
    }
  } while (match !== null);
  if (lastIndex < fullText.length) {
    parts.push({
      text: fullText.slice(lastIndex),
      isHighlighted: false,
      id: createPartId(),
    });
  }
  return parts;
}
