import axios, { AxiosError, CancelToken } from "axios";
import formatISO from "date-fns/formatISO";
import { FileWithPath } from "react-dropzone";
import {
  DocumentModel,
  ColorStatusModel,
  AttributeModel,
  WritableFields,
  ResultParamModel,
  PipelineType,
  PipelineCreateFields,
  EntryModel,
  AttributeType,
  OutcomeEventType,
  AttributeValuesParent,
} from "@web/models";
import {
  BffPaths,
  DocumentPreviewImage,
  DocumentEditSessionAsCreatedRaw,
  IConfig,
  EntryPollingFlags,
} from "@web/api/BFF/types";
import {
  InternalApiPaths,
  BatchParams,
  IRequiredTags,
  IBatchCopyParams,
} from "@web/api/Internal/types";
import {
  IntegrationApiPaths,
  UUID,
  ILoadData,
  ILoadObject,
  IUpdateLinkedObjects,
  ISectionNode,
  IClassificationNode,
  IEntryNode,
  ITagNode,
  ITagNodeExpanded,
  IDocumentNode,
  ICommentNode,
  IDocumentVersionNode,
  ChangelogEventNode,
  IColorStatusNode,
  IAttributeNode,
  IColorStatusNodeExtended,
  ILockNode,
  IPipelineNode,
  IResultNode,
  IAttributeValueNode,
  IElectronicDocumentNode,
  IClassificationNodeExtended,
  IRuleNode,
  IPollableDocumentNode,
  IPollableEntryNode,
  IUpdatedDocumentNode,
  IReminderNode,
  IEventNode,
  RuleType,
  IConditionNode,
  IOutcomeNode,
  ITargetNode,
  OutcomeType,
  MissingClassificationsResponse,
  ISectionWithAccessControl,
  IClassificationWithAccessControl,
  ITagWithAccessControl,
  IFlowContainerWithAccessControl,
  IListContainerWithAccessControl,
  IAccessGroupNode,
  IPermissionNode,
  IPermissionAdd,
  ConditionType,
  IFacetCountNode,
  IAutocomplete,
  APIParameters,
} from "@web/api/Integration/types";
import {
  formatLinkedObjectsUpdate,
  generateEntrySearchParameters,
  getAttributeValueParameter,
  getPipelineLookupParameters,
} from "@web/api/Integration/helpers";
import { encodeRFC5987ValueChars } from "@web/utils/URLHelpers";
import { ObjectTypeString } from "@web/models/AccessControlModel";
import { ApiError } from "@web/api/APIError";
import ApiInstance from "./ApiInstance";
import {
  DocumentCreator,
  EntryCreator,
} from "@web/components/Upload/creators/types";

const CLASSIFICATIONS_PAGE_SIZE = 15;
const DUPLICATE_CHECK_BULK_SIZE = 100;

export class Api extends ApiInstance {
  /** Internal API */
  batch = (args: BatchParams) =>
    this.instance.post(InternalApiPaths.batchSelection, args);

  documentCopy = (args: IBatchCopyParams) =>
    this.instance.post(InternalApiPaths.documentCopy, args);

  unlinkClassification = (classificationId: UUID, sectionId: UUID) =>
    this.instance.post(InternalApiPaths.unlinkClassification, {
      data: {
        primaryEntityId: classificationId,
        unlinkFromEntityId: sectionId,
        unlinkFromEntityType: "Section",
      },
    });

  getEntryChangelog(entryId: UUID) {
    const url = InternalApiPaths.entryChangelog.replace("{uuid}", entryId);
    return this.instance.get<ILoadData<ChangelogEventNode>>(url);
  }

  async getLinksFromEntries(entryId: UUID): Promise<IEntryNode[]> {
    const { data: payload } = await this.instance.post<ILoadData<IEntryNode>>(
      IntegrationApiPaths.entry + "/lookup",
      {
        query: "linksToEntries.id=@id",
        parameters: { "@id": entryId },
        expand: ["documents"],
        attributes: ["documents.title"],
        flags: ["includeInternalIdentifier"],
        pageSize: 20,
      }
    );

    return payload.data;
  }

  async getLinksToEntries(entryId: UUID): Promise<IEntryNode[]> {
    const { data: payload } = await this.instance.post<ILoadData<IEntryNode>>(
      IntegrationApiPaths.entry + "/lookup",
      {
        query: "linksFromEntries.id=@id",
        parameters: { "@id": entryId },
        expand: ["documents"],
        attributes: ["documents.title"],
        flags: ["includeInternalIdentifier"],
        pageSize: 20,
      }
    );

    return payload.data;
  }

  getMissingTagsInSection = (entryIds: UUID[], targetSectionId: UUID) =>
    this.instance.post<MissingClassificationsResponse>(
      InternalApiPaths.batchEntryMoveInfo,
      {
        entryIds,
        targetSectionId,
      }
    );

  getRequiredTags = (sectionId: number) => {
    return this.instance.get<ILoadData<IRequiredTags>>(
      InternalApiPaths.requiredTags,
      {
        params: { sectionId },
      }
    );
  };

  /** Integration API */
  sections = (page = 1, pageSize = 10) =>
    this.instance.post<ILoadData<ISectionNode>>(
      IntegrationApiPaths.section + "/lookup",
      {
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "hideSystemClassifications",
        ],
        sort: [{ field: "title", order: "asc" }],
        expand: [
          "classifications",
          "classifications.tagStatuses",
          "classifications.attributeDefinitions",
        ],
        expandPageSize: {
          classifications: CLASSIFICATIONS_PAGE_SIZE,
        },
        expandSort: {
          classifications: { field: "title", order: "asc" },
        },
        pageSize,
        page,
      }
    );

  getSection = async (sectionId: number) => {
    const { data: payload } = await this.instance.post<ILoadData<ISectionNode>>(
      IntegrationApiPaths.section + "/lookup",
      {
        query: "internalIdentifier=@id",
        parameters: { "@id": sectionId },
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "hideSystemClassifications",
        ],
        expand: [
          "classifications",
          "classifications.tagStatuses",
          "classifications.attributeDefinitions",
        ],
        expandPageSize: {
          classifications: CLASSIFICATIONS_PAGE_SIZE,
        },
        expandSort: {
          classifications: { field: "title", order: "asc" },
        },
        pageSize: 1,
      }
    );

    const section = payload.data[0];
    if (section === undefined) {
      throw new ApiError("not_found");
    }

    return section;
  };

  createSection = (title: string) =>
    this.instance.post<ILoadObject<ISectionNode>>(IntegrationApiPaths.section, {
      flags: ["includeInternalIdentifier"],
      data: { title },
    });

  updateSection = (id: UUID, title: string) =>
    this.instance.put<ILoadObject<ISectionNode>>(
      `${IntegrationApiPaths.section}/${id}`,
      {
        flags: ["includeInternalIdentifier"],
        data: { title },
      }
    );

  deleteSection = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.section}/${id}`, {
      params: {
        flags: "unlinkIfInUse",
      },
    });

  findSections = (titleQuery: string, exclude?: string[], page = 1) => {
    let query = "title%=@title";
    const parameters: APIParameters = {
      "@title": `%${titleQuery}%`,
    };

    if (exclude && exclude.length > 0) {
      query += " && title!=@excludeTitles";
      parameters["@excludeTitles"] = exclude;
    }

    return this.instance.post<ILoadData<ISectionNode>>(
      IntegrationApiPaths.section + "/lookup",
      {
        query,
        parameters,
        flags: [
          "includeInternalIdentifier",
          "hideSystemClassifications",
          "enableBetaSupport",
        ],
        expand: ["classifications", "classifications.attributeDefinitions"],
        sort: [{ field: "title", order: "asc" }],
        pageSize: 25,
        page,
      }
    );
  };

  getClassification = (id: UUID) =>
    this.instance.get<ILoadObject<IClassificationNode>>(
      `${IntegrationApiPaths.classification}/${id}`,
      {
        params: {
          expand: "sections",
          attributes: "sections.id",
        },
      }
    );

  createClassification = (
    title: string,
    sectionId: UUID,
    tags: string[] = []
  ) =>
    this.instance.post<ILoadObject<IClassificationNode>>(
      IntegrationApiPaths.classification,
      {
        flags: ["includeInternalIdentifier"],
        data: {
          title,
          sections: [{ id: sectionId }],
          tags: tags.map((t) => ({ title: t })),
        },
      }
    );

  updateClassification = (id: UUID, title: string, isMandatory: boolean) =>
    this.instance.put<ILoadObject<IClassificationNode>>(
      `${IntegrationApiPaths.classification}/${id}`,
      {
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["tagStatuses"],
        data: { title, isMandatory },
      }
    );

  deleteClassification = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.classification}/${id}`, {
      params: {
        flags: "unlinkIfInUse",
      },
    });

  linkClassificationToSection = (classificationId: UUID, sectionId: UUID) =>
    this.instance.put<ILoadObject<IClassificationNode>>(
      `${IntegrationApiPaths.classification}/${classificationId}`,
      {
        flags: ["includeInternalIdentifier"],
        update: {
          sections: { add: { id: sectionId } },
        },
      }
    );

  findClassifications = (titleQuery: string, excludeSection?: UUID) => {
    let query = "title%=@title && isSystem=@false";
    const parameters: APIParameters = {
      "@title": `%${titleQuery}%`,
      "@false": false,
    };

    if (excludeSection) {
      query += " && sections.id!=@sectionId";
      parameters["@sectionId"] = excludeSection;
    }

    return this.instance.post<ILoadData<IClassificationNodeExtended>>(
      IntegrationApiPaths.classification + "/lookup",
      {
        query,
        parameters,
        flags: ["includeInternalIdentifier"],
        expand: ["sections"],
        attributes: ["sections.id", "sections.title"],
        pageSize: 25,
      }
    );
  };

  createTagStatus = (
    fields: WritableFields<ColorStatusModel>,
    classificationId: UUID
  ) => {
    return this.instance.post<ILoadObject<IColorStatusNode>>(
      IntegrationApiPaths.tagStatus,
      {
        flags: ["enableBetaSupport", "includeInternalIdentifier"],
        data: {
          name: fields.name,
          color: fields.color === "noColor" ? null : fields.color,
          isArchived: fields.isArchived,
          classification: {
            id: classificationId,
          },
        },
      }
    );
  };

  updateTagStatus = (
    id: UUID,
    fields: Partial<Omit<ColorStatusModel, "uuid">>
  ) => {
    const modifiedFields = {
      ...fields,
      ...(fields.color && {
        color: fields.color === "noColor" ? null : fields.color,
      }),
    };

    return this.instance.put<ILoadObject<IColorStatusNode>>(
      `${IntegrationApiPaths.tagStatus}/${id}`,
      {
        flags: ["enableBetaSupport"],
        data: modifiedFields,
      }
    );
  };

  deleteTagStatus = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.tagStatus}/${id}`, {
      params: {
        flags: "enableBetaSupport, unlinkIfInUse",
      },
    });

  setTagStatus = (tagId: UUID, statusId: UUID | undefined) => {
    return this.instance.put<ILoadObject<ITagNode>>(
      `${IntegrationApiPaths.tag}/${tagId}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          tagStatus: statusId ? { id: statusId } : null,
        },
      }
    );
  };

  findTagStatusesById = (statusIds: string[]) => {
    const query = statusIds
      .map((_, i) => `internalIdentifier=@id${i}`)
      .join(" || ");

    const parameters = Object.fromEntries(
      statusIds.map((id, i) => [`@id${i}`, id])
    );

    return this.instance.post<ILoadData<IColorStatusNodeExtended>>(
      IntegrationApiPaths.tagStatus + "/lookup",
      {
        query,
        parameters,
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["classification"],
        attributes: ["classification.id"],
        pageSize: 100,
        page: 1,
      }
    );
  };

  tags = (classificationId: UUID, page = 1) =>
    this.instance.post<ILoadData<ITagNode>>(
      `${IntegrationApiPaths.tag}/lookup`,
      {
        query:
          "classification.id=@classificationId && (tagStatus.id=@null || tagStatus.isArchived=@false)",
        parameters: {
          "@classificationId": classificationId,
          "@false": false,
          "@null": null,
        },
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["tagStatus", "attributeDefinitions"],
        sort: { field: "title", order: "asc" },
        pageSize: 100,
        page,
      }
    );

  createTag = (classificationId: UUID, title: string) =>
    this.instance.post<ILoadObject<ITagNode>>(IntegrationApiPaths.tag, {
      flags: ["includeInternalIdentifier", "enableBetaSupport"],
      data: {
        title,
        classification: {
          id: classificationId,
        },
      },
    });

  updateTag = (id: UUID, title: string) =>
    this.instance.put<ILoadObject<ITagNode>>(
      `${IntegrationApiPaths.tag}/${id}`,
      {
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["tagStatus", "attributeDefinitions"],
        data: { title },
      }
    );

  deleteTag = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.tag}/${id}`, {
      params: {
        flags: "unlinkIfInUse",
      },
    });

  findTagsById = (tagIds: string[]) => {
    return this.instance.post<ILoadData<ITagNodeExpanded>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query: "internalIdentifier=@ids",
        parameters: {
          "@ids": tagIds,
        },
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["classification", "tagStatus", "attributeDefinitions"],
        attributes: ["classification.id"],
        pageSize: 10,
        page: 1,
      }
    );
  };

  findTagsWithAttributeDefinitions = (tagIds: UUID[]) => {
    return this.instance.post<ILoadData<ITagNode>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query: "id=@tagIds&&attributeDefinitions!=@null",
        parameters: {
          "@tagIds": tagIds,
          "@null": null,
        },
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["attributeDefinitions"],
      }
    );
  };

  findTagsInSection = (sectionId: UUID, titleQuery: string) => {
    const query =
      "classification.sections.id=@sectionId && title%=@title && classification.isSystem=@false";
    const parameters = {
      "@sectionId": sectionId,
      "@title": `%${titleQuery}%`,
      "@false": false,
    };

    return this.instance.post<ILoadData<ITagNodeExpanded>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query,
        parameters,
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["classification", "tagStatus", "attributeDefinitions"],
        attributes: ["classification.title"],
        sort: { field: "title", order: "asc" },
        pageSize: 8,
        page: 1,
      }
    );
  };

  findTagsByTitle = (titleQuery: string) => {
    const query = "title%=@title";
    const parameters = {
      "@title": `%${titleQuery}%`,
    };

    return this.instance.post<ILoadData<ITagNodeExpanded>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query,
        parameters,
        flags: ["includeInternalIdentifier"],
        expand: ["classification"],
        attributes: ["classification.title"],
        sort: { field: "title", order: "asc" },
        pageSize: 8,
        page: 1,
      }
    );
  };

  findTagsByEntry = (entryId: UUID, page: number, pageSize: number) =>
    this.instance.post<ILoadData<ITagNodeExpanded>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query: "classification.isSystem=@false && entries.id=@entryId",
        parameters: {
          "@entryId": entryId,
          "@false": false,
        },
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["classification", "attributeDefinitions"],
        attributes: ["classification.title"],
        pageSize,
        page,
      }
    );

  findTagsInClassification = (
    classificationId: UUID,
    titleQuery: string,
    exclude?: string[],
    tagStatuses?: number[],
    page = 1
  ) => {
    let query = "classification.id=@classId";
    const parameters: APIParameters = {
      "@classId": classificationId,
    };

    if (titleQuery) {
      query += " && title%=@title";
      parameters["@title"] = `%${titleQuery}%`;
    }

    if (exclude && exclude.length > 0) {
      query += " && " + "title!=@excludeTitles";
      parameters["@excludeTitles"] = exclude;
    }

    if (tagStatuses && tagStatuses.length > 0) {
      query += " && tagStatus.internalIdentifier=@tagStatuses";
      parameters["@tagStatuses"] = tagStatuses;
    }

    return this.instance.post<ILoadData<ITagNode>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query,
        parameters,
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        expand: ["tagStatus", "attributeDefinitions"],
        sort: { field: "title", order: "asc" },
        page,
        pageSize: 35,
      }
    );
  };

  getTagFacets = (
    internalIdentifiers: number[],
    resultParams: ResultParamModel
  ) => {
    const { filterQuery, parameters, query } =
      generateEntrySearchParameters(resultParams);

    return this.instance.post<ILoadData<IFacetCountNode>>(
      `${IntegrationApiPaths.entry_facet}`,
      {
        filterQuery,
        parameters,
        internalIdentifiers,
        flags: ["enableBetaSupport"],
        query,
      }
    );
  };

  async getEntryByInternalId(
    internalId: string | number,
    documentsPageSize: number
  ): Promise<IEntryNode> {
    if (typeof internalId === "string" && internalId.length === 0) {
      throw new TypeError("Internal ID must not be an empty string");
    }
    if (typeof internalId === "number" && isNaN(internalId)) {
      throw new TypeError("Internal ID must a number");
    }

    const { data: payload } = await this.instance.post<ILoadData<IEntryNode>>(
      IntegrationApiPaths.entry + "/lookup",
      {
        query: "internalIdentifier=@id",
        parameters: { "@id": internalId },
        expand: [
          "documents",
          "documents.documentVersions",
          "documents.documentVersions.electronicDocument",
          "documents.documentVersions.externalIds",
          "entryStatus",
          "section",
          "tags",
          "tags.classification",
        ],
        expandPageSize: {
          documents: documentsPageSize,
          "documents.documentVersions": 1,
          tags: 100,
        },
        expandSort: {
          documents: { field: "title", order: "asc" },
        },
        attributes: [
          "entryStatus.name",
          "section.id",
          "section.title",
          "tags.classification.id",
          "documents.documentVersions.fileName",
          "documents.documentVersions.checksum",
          "documents.documentVersions.checksumAlgorithm",
          "documents.documentVersions.fileSize",
          "documents.documentVersions.contentType",
          "documents.documentVersions.versionNumber",
          "documents.documentVersions.createdBy",
          "documents.documentVersions.createdByUserId",
          "documents.documentVersions.createdDate",
          "documents.documentVersions.updatedDate",
          "documents.documentVersions.updatedBy",
          "documents.documentVersions.updatedByUserId",
        ],
        flags: [
          "enableBetaSupport",
          "hideSystemClassifications",
          "includeInternalIdentifier",
          // Not interested in the total count of entries matched, that's always 1, but it's needed to get entry.resources.documents.total
          "includeTotal",
        ],
        pageSize: 1,
      }
    );

    const entry = payload.data[0];
    if (entry === undefined) {
      throw new ApiError("not_found");
    }

    return entry;
  }

  getPollableEntryByUuid = (uuid: UUID, bffFlags: EntryPollingFlags[] = []) =>
    this.instance.get<ILoadObject<IPollableEntryNode>>(
      `${IntegrationApiPaths.entry}/${uuid}`,
      {
        params: {
          flags: "includeInternalIdentifier, includeTotal, enableBetaSupport",
          expand: "documents, entryStatus",
          attributes: "documents.id, entryStatus.name",
          bffFlags: bffFlags.join(","),
        },
      }
    );

  getEntryStatuses = () =>
    this.instance.post<ILoadData<IColorStatusNode>>(
      `${IntegrationApiPaths.entryStatus}/lookup`,
      {
        query: "name=@names",
        parameters: {
          "@names": ["RED", "GREEN", "ORANGE"],
        },
        flags: "enableBetaSupport",
      }
    );

  getEntryDocuments = (internalId: number, page: number, pageSize: number) =>
    this.instance.post<ILoadData<IDocumentNode>>(
      IntegrationApiPaths.document + "/lookup",
      {
        query: "entry.internalIdentifier=@entryId",
        parameters: { "@entryId": internalId },
        expand: [
          "entry",
          "entry.section",
          "documentVersions",
          "documentVersions.electronicDocument",
          "documentVersions.externalIds",
        ],
        attributes: ["entry.id", "entry.section.id"],
        flags: [
          "enableBetaSupport",
          "includeInternalIdentifier",
          "includeTotal",
        ],
        sort: [{ field: "title", order: "asc" }],
        pageSize,
        page,
      }
    );

  createEntries = (entries: EntryCreator[], cancelToken?: CancelToken) =>
    this.instance.post<ILoadObject<IEntryNode[]>>(
      IntegrationApiPaths.entryBulk,
      {
        data: entries,
        expand: [
          "section",
          "tags",
          "tags.classification",
          "tags.attributeDefinitions",
          "documents",
          "documents.documentVersions",
          "documents.documentVersions.electronicDocument",
        ],
        attributes: ["section.id"],
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "forceIndexing",
        ],
        expandPageSize: {
          documents: 1000,
          "documents.documentVersions": 1000,
          "documents.documentVersions.electronicDocument": 1000,
        },
      },
      { cancelToken }
    );

  createEntry = (
    sectionId: UUID,
    tagIds: UUID[],
    title?: string,
    externalIds?: string[],
    createdDate?: string,
    isSingleDocumentEntry?: boolean
  ) =>
    this.instance.post<ILoadObject<IEntryNode>>(IntegrationApiPaths.entry, {
      data: {
        ...(title && { title }),
        section: { id: sectionId },
        tags: tagIds.map((id) => ({ id })),
        ...(externalIds && {
          externalIds: externalIds.map((externalId) => ({
            externalId,
          })),
        }),
        ...(createdDate && {
          createdDate: createdDate,
        }),
        isSingleDocumentEntry,
      },
      expand: [
        "section",
        "tags",
        "tags.classification",
        "tags.attributeDefinitions",
        "documents",
      ],
      attributes: ["section.id"],
      flags: [
        "includeInternalIdentifier",
        "enableBetaSupport",
        "forceIndexing",
      ],
    });

  updateEntry(entryUuid: UUID, fields: Partial<EntryModel>) {
    const modifiedFields = {
      ...fields,
      ...("title" in fields && {
        title: !fields.title ? null : fields.title,
      }),
      ...(fields.entryStatus && {
        entryStatus:
          fields.entryStatus === "NO-STATUS" ? null : fields.entryStatus,
      }),
    };

    return this.instance.put<ILoadObject<IEntryNode>>(
      `${IntegrationApiPaths.entry}/${entryUuid}`,
      {
        flags: ["enableBetaSupport"],
        data: modifiedFields,
      }
    );
  }

  updateEntryStatus(entryUuid: UUID, statusId: UUID | undefined) {
    return this.instance.put(`${IntegrationApiPaths.entry}/${entryUuid}`, {
      flags: ["enableBetaSupport"],
      data: {
        entryStatus: statusId ? { id: statusId } : null,
      },
    });
  }

  updateEntryTags = (id: UUID, tags: IUpdateLinkedObjects) =>
    this.instance.put<ILoadObject<IEntryNode>>(
      `${IntegrationApiPaths.entry}/${id}`,
      {
        update: {
          tags: formatLinkedObjectsUpdate(tags),
        },
        flags: ["deleteAttributeValues", "enableBetaSupport"],
      }
    );

  getEntryTags = (entryId: UUID) =>
    this.instance.post<ILoadData<ITagNodeExpanded>>(
      `${IntegrationApiPaths.tag}/lookup`,
      {
        query: "entries.id=@entryId && attributeDefinitions.id!=@null",
        parameters: { "@entryId": entryId, "@null": null },
        expand: ["attributeDefinitions", "classification"],
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "hideSystemClassifications",
        ],
        pageSize: 1000,
      }
    );

  getEntryReport = (params: ResultParamModel, cancelToken?: CancelToken) => {
    const { query, filterQuery, parameters } =
      generateEntrySearchParameters(params);

    return this.instance.post<Blob>(
      `${IntegrationApiPaths.entry}/report`,
      {
        report: "csv_v1",
        ftsRequest: {
          ...(query && {
            query,
            queryAttributes: [
              "title",
              "documents.title",
              "documents.documentVersions.text",
              "comments.text",
            ],
          }),
          filterQuery,
          parameters,
          flags: ["enableBetaSupport", "enableAdvancedFTS"],
        },
      },
      {
        cancelToken,
        responseType: "blob",
      }
    );
  };

  deleteEntry = (id: UUID) => {
    return this.instance.delete(`${IntegrationApiPaths.entry}/${id}`);
  };

  findEntries = (
    params: ResultParamModel,
    page = 1,
    options: {
      includeAttributes?: boolean;
    } = {},
    cancelToken?: CancelToken
  ) => {
    const { query, filterQuery, parameters, sort } =
      generateEntrySearchParameters(params);

    return this.instance.post<ILoadData<IEntryNode>>(
      `${IntegrationApiPaths.entry}/search`,
      {
        ...(query && {
          query,
          queryAttributes: [
            "title",
            "documents.title",
            "documents.documentVersions.text",
            "comments.text",
          ],
        }),
        filterQuery,
        parameters,
        expand: [
          "section",
          "entryStatus",
          "tags",
          "tags.classification",
          "tags.tagStatus",
          "documents",
          "documents.documentVersions",
          "documents.documentVersions.electronicDocument",
          "documents.documentVersions.externalIds",
          "reminders",
          ...(options.includeAttributes
            ? [
                "attributeValues",
                "attributeValues.definition",
                "attributeListValues",
                "attributeListValues.definition",
                "tags.attributeDefinitions",
              ]
            : []),
        ],
        expandPageSize: {
          tags: 100,
          documents: 8,
          "documents.documentVersions": 1,
          reminders: 1,
        },
        expandSort: {
          documents: { field: "title", order: "asc" },
          reminders: { field: "reminderDate", order: "asc" },
        },
        attributes: [
          "tags.classification.id",
          "section.id",
          "section.title",
          "entryStatus.name",
          "documents.documentVersions.fileName",
          "documents.documentVersions.checksum",
          "documents.documentVersions.checksumAlgorithm",
          "documents.documentVersions.fileSize",
          "documents.documentVersions.contentType",
          "documents.documentVersions.versionNumber",
          "documents.documentVersions.createdBy",
          "documents.documentVersions.createdByUserId",
          "documents.documentVersions.createdDate",
          "documents.documentVersions.updatedDate",
          "documents.documentVersions.updatedBy",
          "documents.documentVersions.updatedByUserId",
          ...(options.includeAttributes
            ? [
                "attributeValues.definition.id",
                "attributeListValues.definition.id",
              ]
            : []),
        ],
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "enableAdvancedFTS",
          "includeTotal",
          "hideSystemClassifications",
        ],
        ...(sort && { sort }),
        pageSize: 20,
        page,
      },
      {
        cancelToken,
      }
    );
  };

  findEntriesToLink = (
    searchTerm: string,
    sourceEntry: UUID,
    cancelToken?: CancelToken
  ) => {
    return this.instance.post<ILoadData<IEntryNode>>(
      `${IntegrationApiPaths.entry}/search`,
      {
        query: searchTerm.trim(),
        queryAttributes: ["documents.title", "title"],
        filterQuery: "id!=@id",
        parameters: { "@id": sourceEntry },
        expand: ["documents"],
        pageSize: 40,
        page: 1,
      },
      {
        cancelToken,
      }
    );
  };

  linkEntry(sourceEntry: UUID, targetEntry: UUID) {
    return this.instance.put<ILoadObject<IEntryNode>>(
      `${IntegrationApiPaths.entry}/${sourceEntry}`,
      {
        flags: ["enableBetaSupport"],
        update: {
          linksToEntries: [{ add: { id: targetEntry } }],
        },
      }
    );
  }

  unlinkEntries(
    sourceEntry: UUID,
    linksToEntries: UUID[],
    linksFromEntries: UUID[]
  ) {
    const removeActionsToLinks = linksToEntries.map((targetEntryId) => {
      return { remove: { id: targetEntryId } };
    });
    const removeActionsFromLinks = linksFromEntries.map((targetEntryId) => {
      return { remove: { id: targetEntryId } };
    });

    return this.instance.put<ILoadObject<IEntryNode>>(
      `${IntegrationApiPaths.entry}/${sourceEntry}`,
      {
        flags: ["enableBetaSupport"],
        update: {
          ...(!!removeActionsToLinks.length && {
            linksToEntries: removeActionsToLinks,
          }),
          ...(!!removeActionsFromLinks.length && {
            linksFromEntries: removeActionsFromLinks,
          }),
        },
      }
    );
  }

  findSuggestedDocuments = (query: string, sectionId?: number) => {
    return this.instance.post<ILoadData<IDocumentNode>>(
      `${IntegrationApiPaths.document}/search`,
      {
        query,
        queryAttributes: ["title"],
        filterQuery: "entry.section.internalIdentifier=@sectionId",
        parameters: {
          "@sectionId": sectionId,
        },
        expand: [
          "entry",
          "entry.section",
          "documentVersions",
          "documentVersions.electronicDocument",
        ],
        page: 1,
        pageSize: 5,
        flags: [
          "includeInternalIdentifier",
          "enableBetaSupport",
          "enableAdvancedFTS",
        ],
      }
    );
  };

  createAttribute = (
    fields: WritableFields<AttributeModel>,
    parent: {
      [P in "tag" | "classification"]?: { id: UUID };
    }
  ) =>
    this.instance.post<ILoadObject<IAttributeNode>>(
      IntegrationApiPaths.attribute,
      {
        flags: ["enableBetaSupport", "includeInternalIdentifier"],
        data: {
          name: fields.name,
          attributeType: fields.type,
          ...parent,
        },
      }
    );

  updateAttribute = (id: UUID, name: string) =>
    this.instance.put<ILoadObject<IAttributeNode>>(
      `${IntegrationApiPaths.attribute}/${id}`,
      {
        flags: ["enableBetaSupport", "includeInternalIdentifier"],
        data: { name },
      }
    );

  deleteAttribute = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.attribute}/${id}`, {
      params: {
        flags: "enableBetaSupport, unlinkIfInUse",
      },
    });

  getEntryReminders = (entryId: UUID) =>
    this.instance.get<ILoadData<IReminderNode>>(
      `${IntegrationApiPaths.entry}/${entryId}/reminders`,
      {
        params: {
          flags: "enableBetaSupport",
          sortField: "reminderDate",
          sortOrder: "asc",
          pageSize: 100,
        },
      }
    );

  createReminder = (fields: { date: Date; title: string }, entryId: UUID) =>
    this.instance.post<ILoadObject<IReminderNode>>(
      `${IntegrationApiPaths.reminder}`,
      {
        data: {
          reminderDate: fields.date.toISOString(),
          entry: {
            id: entryId,
          },
          title: fields.title,
        },
        flags: ["enableBetaSupport"],
      }
    );

  updateReminder = (fields: { date: Date; title: string }, reminderId: UUID) =>
    this.instance.put<ILoadObject<IReminderNode>>(
      `${IntegrationApiPaths.reminder}/${reminderId}`,
      {
        data: {
          reminderDate: fields.date.toISOString(),
          title: fields.title,
        },
        flags: ["enableBetaSupport"],
      }
    );

  deleteReminder = (reminderId: UUID) =>
    this.instance.delete<void>(
      `${IntegrationApiPaths.reminder}/${reminderId}`,
      {
        params: {
          flags: "enableBetaSupport",
        },
      }
    );

  getAttributeListValues = (definitionId: UUID, type: AttributeType) => {
    let sortField;
    switch (type) {
      case "DateList":
        sortField = "valueDate";
        break;
      case "StringList":
        sortField = "valueString";
        break;
      case "NumericList":
        sortField = "valueDouble";
        break;
    }

    return this.instance.get<ILoadData<IAttributeValueNode>>(
      `${IntegrationApiPaths.attribute}/${definitionId}/attribute-list-values`,
      {
        params: {
          flags: "enableBetaSupport",
          expand: ["definition"],
          sortField,
          sortOrder: "asc",
          pageSize: 1000,
        },
      }
    );
  };

  createAttributeListValue = (
    value: string | number | Date,
    definitionId: UUID
  ) => {
    return this.instance.post<ILoadObject<IAttributeValueNode>>(
      `${IntegrationApiPaths.attributeListValue}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["definition"],
        data: {
          ...getAttributeValueParameter(value),
          definition: { id: definitionId },
        },
      }
    );
  };

  updateAttributeListValue = (id: UUID, value: string | number | Date) => {
    return this.instance.put<ILoadObject<IAttributeValueNode>>(
      `${IntegrationApiPaths.attributeListValue}/${id}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["definition"],
        data: {
          ...getAttributeValueParameter(value),
        },
      }
    );
  };

  deleteAttributeListValue = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.attributeListValue}/${id}`, {
      params: {
        flags: "enableBetaSupport, unlinkIfInUse",
      },
    });

  createAttributeValue = (
    value: string | number | Date,
    definitionId: UUID,
    parent: AttributeValuesParent
  ) => {
    return this.instance.post<ILoadObject<IAttributeValueNode>>(
      `${IntegrationApiPaths.attributeValue}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["definition", "definition.tag", "entry", "tag"],
        attributes: ["definition.tag.id", "entry.id", "tag.id"],
        data: {
          ...getAttributeValueParameter(value),
          [parent.objectType]: { id: parent.uuid },
          definition: { id: definitionId },
        },
      }
    );
  };

  updateAttributeValue = (id: UUID, value: string | number | Date) => {
    return this.instance.put<ILoadObject<IAttributeValueNode>>(
      `${IntegrationApiPaths.attributeValue}/${id}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["definition", "definition.tag", "entry", "tag"],
        attributes: ["definition.tag.id", "entry.id", "tag.id"],
        data: {
          ...getAttributeValueParameter(value),
        },
      }
    );
  };

  deleteAttributeValue = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.attributeValue}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });

  getEntryAttributes = (entryId: UUID) =>
    this.instance.get<ILoadData<IAttributeValueNode>>(
      `${IntegrationApiPaths.entry}/${entryId}/attribute-values`,
      {
        params: {
          flags: "enableBetaSupport",
          expand: ["definition", "definition.tag", "entry"],
          attributes: ["definition.tag.id", "entry.id"],
          pageSize: 1000,
        },
      }
    );

  getEntryListAttributes = (entryId: UUID) =>
    this.instance.get<ILoadData<IAttributeValueNode>>(
      `${IntegrationApiPaths.entry}/${entryId}/attribute-list-values`,
      {
        params: {
          flags: "enableBetaSupport",
          expand: "definition, definition.tag",
          attributes: "definition.tag.id",
          pageSize: 1000,
        },
      }
    );

  getTagAttributes = (tagId: UUID) =>
    this.instance.get<ILoadData<IAttributeValueNode>>(
      `${IntegrationApiPaths.tag}/${tagId}/attribute-values`,
      {
        params: {
          flags: "enableBetaSupport",
          expand: ["definition", "tag"],
          attributes: ["tag.id"],
          pageSize: 1000,
        },
      }
    );

  getTagListAttributes = (tagId: UUID) =>
    this.instance.get<ILoadData<IAttributeValueNode>>(
      `${IntegrationApiPaths.tag}/${tagId}/attribute-list-values`,
      {
        params: {
          flags: "enableBetaSupport",
          expand: "definition",
          pageSize: 1000,
        },
      }
    );

  updateEntryAttributeListValues = (id: UUID, values: IUpdateLinkedObjects) =>
    this.instance.put<
      ILoadObject<
        IEntryNode & {
          attributeValues: IAttributeValueNode[];
          attributeListValues: IAttributeValueNode[];
        }
      >
    >(`${IntegrationApiPaths.entry}/${id}`, {
      update: {
        attributeListValues: formatLinkedObjectsUpdate(values),
      },
      expand: [
        "attributeValues",
        "attributeValues.definition",
        "attributeListValues",
        "attributeListValues.definition",
      ],
      flags: ["deleteAttributeValues", "enableBetaSupport"],
    });

  updateTagAttributeListValues = (id: UUID, values: IUpdateLinkedObjects) =>
    this.instance.put<
      ILoadObject<
        ITagNode & {
          attributeValues: IAttributeValueNode[];
          attributeListValues: IAttributeValueNode[];
        }
      >
    >(`${IntegrationApiPaths.tag}/${id}`, {
      update: {
        attributeListValues: formatLinkedObjectsUpdate(values),
      },
      expand: [
        "attributeValues",
        "attributeValues.definition",
        "attributeListValues",
        "attributeListValues.definition",
      ],
      flags: ["deleteAttributeValues", "enableBetaSupport"],
      pageSize: 100,
    });

  getDocumentByNumericId = async (id: number): Promise<IDocumentNode> => {
    const { data: payload } = await this.instance.post<
      ILoadData<IDocumentNode>
    >(IntegrationApiPaths.document + "/lookup", {
      query: "internalIdentifier=@id",
      parameters: { "@id": id },
      expand: [
        "entry",
        "entry.section",
        "documentVersions",
        "documentVersions.electronicDocument",
        "documentVersions.externalIds",
        "lock",
      ],
      attributes: ["entry.id", "entry.title", "entry.section.id"],
      flags: ["includeInternalIdentifier", "enableBetaSupport"],
      pageSize: 1,
    });

    const document = payload.data[0];
    if (document === undefined) {
      throw new ApiError("not_found");
    }

    return document;
  };

  getPollableDocumentByUuid = (uuid: UUID) =>
    this.instance.get<ILoadObject<IPollableDocumentNode>>(
      `${IntegrationApiPaths.document}/${uuid}`,
      {
        params: {
          expand: ["documentVersions", "lock", "documentVersions.externalIds"],
          attributes: ["documentVersions.id"],
          flags: ["enableBetaSupport"],
        },
      }
    );

  updateDocument = (id: UUID, fields: WritableFields<DocumentModel>) =>
    this.instance.put<ILoadObject<IUpdatedDocumentNode>>(
      `${IntegrationApiPaths.document}/${id}`,
      {
        flags: ["includeInternalIdentifier", "enableBetaSupport"],
        data: fields,
      }
    );

  createDocuments = (
    documents: DocumentCreator[],
    cancelToken?: CancelToken
  ) => {
    return this.instance.post<ILoadObject<IDocumentNode[]>>(
      IntegrationApiPaths.documentBulk,
      {
        flags: ["includeInternalIdentifier", "includeTotal", "forceIndexing"],
        expand: [
          "entry",
          "entry.section",
          "documentVersions",
          "documentVersions.electronicDocument",
        ],
        data: documents,
        expandPageSize: {
          documentVersions: 1000,
          "documentVersions.electronicDocument": 1000,
        },
      },
      { cancelToken }
    );
  };

  createDocument = (
    title: string,
    electronicDocumentId: UUID,
    entryId: UUID,
    cancelToken?: CancelToken,
    additionalFields?: {
      text: string | undefined;
    }
  ) =>
    this.instance.post<ILoadObject<IDocumentNode>>(
      `${IntegrationApiPaths.document}`,
      {
        flags: ["includeInternalIdentifier", "includeTotal", "forceIndexing"],
        expand: [
          "entry",
          "entry.section",
          "documentVersions",
          "documentVersions.electronicDocument",
        ],
        data: {
          title,
          entry: { id: entryId },
          documentVersions: [
            {
              electronicDocument: {
                id: electronicDocumentId,
              },
              ...(additionalFields?.text && {
                text: additionalFields.text,
              }),
            },
          ],
        },
      },
      { cancelToken }
    );

  deleteDocument = (id: UUID) => {
    return this.instance.delete(`${IntegrationApiPaths.document}/${id}`);
  };

  getDocumentVersions = (documentId: UUID, page = 1) =>
    this.instance.get<ILoadData<IDocumentVersionNode>>(
      `${IntegrationApiPaths.document}/${documentId}/document-versions`,
      {
        params: {
          expand: "electronicDocument",
          flags: "includeInternalIdentifier",
          sortField: "createdDate",
          sortOrder: "desc",
          pageSize: 10,
          page,
        },
      }
    );

  hasDuplicatedChecksum = async (documentChecksum: string[]) => {
    const chunkSize = DUPLICATE_CHECK_BULK_SIZE;
    let results: IDocumentVersionNode[] = [];

    for (let i = 0; i < documentChecksum.length; i += chunkSize) {
      const chunk = documentChecksum.slice(i, i + chunkSize);
      const query: string[] = [];
      const params: { [key: string]: string } = {};

      chunk.forEach((checksum, index) => {
        params[`@checksum${index}`] = checksum;
        query.push(`checksum = @checksum${index}`);
      });

      const response = await this.instance.post<
        ILoadData<IDocumentVersionNode>
      >(`${IntegrationApiPaths.documentVersion}/lookup`, {
        query: query.join(" || "),
        parameters: params,

        attributes: ["checksum"],
        expand: ["electronicDocument"],
        flags: ["enableBetaSupport", "includeTotal"],
        pageSize: query.length,
      });

      if (response.data && Array.isArray(response.data.data)) {
        results = results.concat(response.data.data);
      }
    }

    return results;
  };

  createDocumentVersion = (electronicDocumentId: UUID, documentId: UUID) =>
    this.instance.post<ILoadObject<IDocumentVersionNode>>(
      `${IntegrationApiPaths.documentVersion}`,
      {
        flags: ["includeInternalIdentifier"],
        data: {
          electronicDocument: { id: electronicDocumentId },
          document: { id: documentId },
        },
        expand: [
          // BFF needs to know the version' `.electronicDocument.internalIdentifier`
          // to trigger preview generation and decoration onto the response of this request
          "electronicDocument",
        ],
      }
    );

  deleteDocumentVersion = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.documentVersion}/${id}`);

  uploadFile = (
    file: FileWithPath,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    cancelToken?: CancelToken
  ) => {
    return this.instance.post<IElectronicDocumentNode>(
      IntegrationApiPaths.uploadFile,
      file,
      {
        headers: {
          "Content-type": "application/octet-stream",
          "Content-disposition": `attachment; filename*=utf-8''${encodeRFC5987ValueChars(
            file.name
          )}`,
        },
        onUploadProgress,
        cancelToken,
      }
    );
  };

  createComment = (text: string, entryId: UUID) =>
    this.instance.post<ILoadObject<ICommentNode>>(IntegrationApiPaths.comment, {
      data: { text, entry: { id: entryId } },
    });

  deleteComment = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.comment}/${id}`);

  createDocumentLock = (documentId: UUID, expiryDate: Date) =>
    this.instance.post<ILoadObject<ILockNode>>(IntegrationApiPaths.lock, {
      data: {
        expiryDate: formatISO(expiryDate),
        document: { id: documentId },
      },
      flags: ["enableBetaSupport"],
    });

  deleteLock = (id: UUID) =>
    this.instance.delete(
      `${IntegrationApiPaths.lock}/${id}?flags=enableBetaSupport`
    );

  getFlowPipelines = (type: PipelineType) => {
    const { query, parameters } = getPipelineLookupParameters(type);

    return this.instance.post<ILoadData<IPipelineNode>>(
      `${IntegrationApiPaths.flow_pipeline}/lookup`,
      {
        query,
        parameters,
        flags: ["enableBetaSupport", "includeTotal"],
        expand: [
          "target",
          "conditions",
          "rules",
          "events",
          "successOutcomes",
          "failureOutcomes",
        ],
        pageSize: 50,
      }
    );
  };

  createPipeline = (
    fields: PipelineCreateFields,
    events: UUID[],
    targetId: UUID,
    successOutcome?: Partial<IOutcomeNode>
  ) =>
    this.instance.post<ILoadObject<IPipelineNode>>(
      `${IntegrationApiPaths.flow_pipeline}`,
      {
        data: {
          name: fields.name,
          ...(fields.description && {
            description: fields.description,
          }),
          isEnabled: false,
          pipelineType: fields.pipelineType,
          target: { id: targetId },
          events: events.map((id) => ({ id })),
          ...(successOutcome && {
            successOutcomes: [successOutcome],
          }),
        },
        expand: [
          "target",
          "conditions",
          "rules",
          "events",
          "successOutcomes",
          "failureOutcomes",
        ],
        flags: ["enableBetaSupport", "includeTotal"],
      }
    );

  getFlowEvents = () =>
    this.instance.get<ILoadData<IEventNode>>(
      `${IntegrationApiPaths.flow_event}`,
      {
        params: {
          flags: "enableBetaSupport",
          pageSize: 1000,
        },
      }
    );

  getFlowTargets = () =>
    this.instance.get<ILoadData<ITargetNode>>(
      `${IntegrationApiPaths.flow_target}`,
      {
        params: {
          flags: "enableBetaSupport",
          pageSize: 1000,
        },
      }
    );

  getFlowResults = (pipelineId: UUID, entryId: UUID) =>
    this.instance.post<ILoadData<IResultNode>>(
      `${IntegrationApiPaths.flow_result}/lookup`,
      {
        query:
          "pipelineExecution.pipeline.id=@pipelineId && componentType=@rule && pipelineExecution.targetId=@entryId",
        parameters: {
          "@pipelineId": pipelineId,
          "@entryId": entryId,
          "@rule": "rule",
        },
        sort: { field: "createdDate", order: "desc" },
        flags: ["enableBetaSupport"],
        pageSize: 1000,
      }
    );

  getFlowPipelineRules = (pipelineId: UUID) =>
    this.instance.get<ILoadData<IRuleNode>>(
      `${IntegrationApiPaths.flow_pipeline}/${pipelineId}/rules`,
      {
        params: {
          flags: "enableBetaSupport, includeTotal",
          pageSize: 1000,
        },
      }
    );

  updatePipeline = (id: UUID, fields: WritableFields<IPipelineNode>) =>
    this.instance.put<ILoadObject<IPipelineNode>>(
      `${IntegrationApiPaths.flow_pipeline}/${id}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          ...("name" in fields && {
            name: fields.name,
          }),
          ...("description" in fields && {
            description: fields.description ? fields.description : null,
          }),
          ...("isEnabled" in fields && {
            isEnabled: fields.isEnabled,
          }),
        },
      }
    );

  createRule = (pipelineId: UUID, type: RuleType, fields: Partial<IRuleNode>) =>
    this.instance.post<ILoadObject<IRuleNode>>(
      `${IntegrationApiPaths.flow_rule}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          type,
          pipeline: {
            id: pipelineId,
          },
          ...(fields.name && {
            name: fields.name,
          }),
          ...("description" in fields && {
            description: fields.description ? fields.description : null,
          }),
          ...("definitionId" in fields && {
            definitionId: fields.definitionId,
          }),
          ...("prefix" in fields && {
            prefix: fields.prefix,
          }),
          ...("entryTagClassificationId" in fields && {
            entryTagClassificationId: fields.entryTagClassificationId,
          }),
        },
      }
    );

  createCondition = (
    pipelineId: UUID,
    type: ConditionType,
    fields: Partial<IConditionNode>
  ) =>
    this.instance.post<ILoadObject<IConditionNode>>(
      `${IntegrationApiPaths.flow_condition}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          type,
          pipeline: {
            id: pipelineId,
          },
          ...(fields.name && {
            name: fields.name,
          }),
          ...("entryTagId" in fields && {
            entryTagId: fields.entryTagId,
          }),
          ...("groupId" in fields && {
            groupId: fields.groupId,
          }),
          ...("entrySectionId" in fields && {
            entrySectionId: fields.entrySectionId,
          }),
        },
      }
    );

  deleteCondition = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.flow_condition}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });

  updateRule = (id: UUID, type: string, fields: Partial<IRuleNode>) =>
    this.instance.put<ILoadObject<IRuleNode>>(
      `${IntegrationApiPaths.flow_rule}/${id}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          type,
          ...(fields.name && {
            name: fields.name,
          }),
          ...("description" in fields && {
            description: fields.description ? fields.description : null,
          }),
          ...("prefix" in fields && {
            prefix: fields.prefix,
          }),
          ...("definitionId" in fields && {
            definitionId: fields.definitionId,
          }),
        },
      }
    );

  deleteRule = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.flow_rule}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });

  updatePipelineEvents = (id: UUID, eventId: UUID, selected: boolean) =>
    this.instance.put<ILoadObject<IPipelineNode>>(
      `${IntegrationApiPaths.flow_pipeline}/${id}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["events"],
        update: {
          events: selected
            ? { add: { id: eventId } }
            : { remove: { id: eventId } },
        },
      }
    );

  deletePipeline = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.flow_pipeline}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });

  createOutcome = (
    pipelineId: UUID,
    type: OutcomeType,
    eventType: OutcomeEventType,
    fields: Partial<IOutcomeNode>
  ) =>
    this.instance.post<ILoadObject<IOutcomeNode>>(
      `${IntegrationApiPaths.flow_outcome}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          type,
          ...(fields.name && {
            name: fields.name,
          }),
          [eventType]: {
            id: pipelineId,
          },
          ...("entryComment" in fields && {
            entryComment: fields.entryComment,
          }),
          ...("entryStatus" in fields && {
            entryStatusId: fields.entryStatus,
          }),
          ...("webhookURL" in fields && {
            webhookURL: fields.webhookURL,
          }),
          ...("isSensitive" in fields && {
            isSensitive: fields.isSensitive,
          }),
        },
      }
    );

  updateOutcome = (id: UUID, type: string, fields: Partial<IOutcomeNode>) =>
    this.instance.put<ILoadObject<IOutcomeNode>>(
      `${IntegrationApiPaths.flow_outcome}/${id}`,
      {
        flags: ["enableBetaSupport"],
        data: {
          type,
          ...("entryComment" in fields && {
            entryComment: fields.entryComment,
          }),
          ...("entryStatus" in fields && {
            entryStatusId: fields.entryStatus,
          }),
          ...("webhookURL" in fields && {
            webhookURL: fields.webhookURL,
          }),
          ...("isSensitive" in fields && {
            isSensitive: fields.isSensitive,
          }),
        },
      }
    );

  deleteOutcome = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.flow_outcome}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });

  findUsers = (query: string) => {
    return this.instance.post<IAutocomplete<"createdBy">>(
      `${IntegrationApiPaths.document}/autocomplete`,
      {
        query: `%${query}%`,
        queryAttributes: "createdBy",
        pageSize: 10,
      }
    );
  };

  /** BFF */
  loadConfig = () => this.instance.get<IConfig>(BffPaths.config);

  getFilePreviewData = (electronicDocumentId: number) =>
    this.instance.get<DocumentPreviewImage>(
      `${BffPaths.filePreviewMeta}/${electronicDocumentId}`
    );

  runBffOcrCheck = (electronicDocumentId: number) =>
    this.instance.post<DocumentPreviewImage>(
      `${BffPaths.filePreviewOcrCheck}/${electronicDocumentId}`
    );

  createDocumentEditSession = (documentId: UUID, timezone: string) =>
    this.instance.post<DocumentEditSessionAsCreatedRaw>(
      BffPaths.documentEditSession,
      {
        documentId,
        timezone,
      }
    );

  /** Access Control API */
  getSectionsWithAccessControl = (
    page: number,
    pageSize: number,
    search: string
  ) =>
    this.instance.post<ILoadData<ISectionWithAccessControl>>(
      IntegrationApiPaths.section + "/lookup",
      {
        query: search.length > 0 ? `title%=@search` : "",
        parameters: {
          "@search": `%${search}%`,
        },
        expand: ["permissions", "permissions.accessGroup"],
        attributes: ["title"],
        flags: ["enableBetaSupport"],
        sort: { field: "title", order: "asc" },
        pageSize,
        page,
      }
    );

  getClassificationsWithAccessControl = (
    page: number,
    pageSize: number,
    search: string
  ) =>
    this.instance.post<ILoadData<IClassificationWithAccessControl>>(
      IntegrationApiPaths.classification + "/lookup",
      {
        query: search.length > 0 ? `title%=@search` : "",
        parameters: {
          "@search": `%${search}%`,
        },
        expand: ["permissions", "permissions.accessGroup"],
        attributes: ["title", "determinesAccessControl"],
        flags: ["enableBetaSupport"],
        sort: { field: "title", order: "asc" },
        pageSize,
        page,
      }
    );

  updateClassificationWithAccessControl = (
    id: UUID,
    json: Pick<IClassificationWithAccessControl, "determinesAccessControl">
  ) =>
    this.instance.put<ILoadObject<IClassificationWithAccessControl>>(
      `${IntegrationApiPaths.classification}/${id}`,
      {
        data: json,
        expand: ["permissions", "permissions.accessGroup"],
        attributes: ["title", "determinesAccessControl"],
        flags: ["enableBetaSupport"],
      }
    );

  getTagsWithAccessControl = (
    classificationId: UUID | undefined,
    page: number,
    pageSize: number,
    search: string
  ) => {
    const query: string[] = [];
    if (classificationId !== undefined) {
      query.push("classification.id=@classificationId");
    }
    if (search.length > 0) {
      query.push("title%=@search");
    }
    return this.instance.post<ILoadData<ITagWithAccessControl>>(
      IntegrationApiPaths.tag + "/lookup",
      {
        query: query.join(" && "),
        parameters: {
          "@classificationId": classificationId,
          "@search": search,
        },
        expand: ["classification", "permissions", "permissions.accessGroup"],
        attributes: ["title", "classification.title"],
        flags: ["enableBetaSupport"],
        sort: { field: "title", order: "asc" },
        pageSize,
        page,
      }
    );
  };

  getFlowContainersWithAccessControl = (
    page: number,
    pageSize: number,
    search: string
  ) =>
    this.instance.post<ILoadData<IFlowContainerWithAccessControl>>(
      IntegrationApiPaths.flow_container + "/lookup",
      {
        expand: ["permissions", "permissions.accessGroup"],
        attributes: [],
        flags: ["enableBetaSupport"],
        pageSize,
        page,
      }
    );

  getListContainersWithAccessControl = (
    page: number,
    pageSize: number,
    search: string
  ) =>
    this.instance.post<ILoadData<IListContainerWithAccessControl>>(
      IntegrationApiPaths.listContainer + "/lookup",
      {
        expand: ["permissions", "permissions.accessGroup"],
        attributes: [],
        flags: ["enableBetaSupport"],
        pageSize,
        page,
      }
    );

  getAccessGroups = (page: number, pageSize: number, search: string) =>
    this.instance.get<ILoadData<IAccessGroupNode>>(
      IntegrationApiPaths.accessGroup,
      {
        params: {
          pageSize,
          page,
          name: search,
          expand: "sections,classifications,tags",
          flags: "enableBetaSupport,includeTotal",
        },
      }
    );

  updateAccessGroup = (json: IAccessGroupNode) => {
    const { id, ...data } = json;
    return this.instance.put<ILoadObject<IAccessGroupNode>>(
      `${IntegrationApiPaths.accessGroup}/${id}`,
      {
        flags: ["enableBetaSupport"],
        data,
      }
    );
  };

  deleteAccessGroup = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.accessGroup}/${id}`);

  createAccessGroup = (
    json: Pick<IAccessGroupNode, "name" | "claims" | "description">
  ) =>
    this.instance.post<ILoadObject<IAccessGroupNode>>(
      IntegrationApiPaths.accessGroup,
      {
        flags: ["enableBetaSupport"],
        data: json,
      }
    );

  getObjectPermissionsForObject = (
    objectId: UUID,
    objectType: ObjectTypeString,
    page: number,
    pageSize: number
  ) => {
    let path: string;
    switch (objectType) {
      case "section":
      case "classification":
      case "tag":
      case "listContainer":
        path = `${IntegrationApiPaths[objectType]}/${objectId}/permissions`;
        break;
      case "flowContainer":
        path = `${IntegrationApiPaths.flowContainer}/${objectId}/permissions`;
        break;
      default:
        throw new Error(`Unhandled objectType '${objectType}`);
    }

    return this.instance.get<ILoadData<IPermissionNode>>(path, {
      params: {
        pageSize,
        page,
        flags: "enableBetaSupport",
        expand: "accessGroup",
      },
    });
  };

  updateObjectPermission = (json: IPermissionNode) => {
    const { id, explicitPermissions } = json;
    return this.instance.put<ILoadObject<IPermissionNode>>(
      `${IntegrationApiPaths.permissions}/${id}`,
      {
        flags: ["enableBetaSupport"],
        expand: ["accessGroup"],
        data: { explicitPermissions },
      }
    );
  };

  createObjectPermission = (json: IPermissionAdd) =>
    this.instance.post<ILoadObject<IPermissionNode>>(
      IntegrationApiPaths.permissions,
      {
        flags: ["enableBetaSupport"],
        expand: ["accessGroup"],
        data: json,
      }
    );

  deletePermissions = (id: UUID) =>
    this.instance.delete(`${IntegrationApiPaths.permissions}/${id}`, {
      params: {
        flags: "enableBetaSupport",
      },
    });
}

export const getRequestError = (e: unknown): AxiosError | Error => {
  if (axios.isAxiosError(e) || e instanceof Error) {
    return e;
  }

  return new Error("Unknown error");
};
