import React from "react";
import styled from "styled-components";
import { VBox } from "@web/elements";
import { vars } from "@web/styles";

interface IProps {
  message: string;
}

export function ErrorMessage({ message }: IProps) {
  return (
    <_wrap vAlign="center" hAlign="center">
      {message}
    </_wrap>
  );
}

const _wrap = styled(VBox)`
  background: ${vars.danger};
  color: ${vars.dangerFg};
  padding: 3px;
  margin: 5px;
  border-radius: 2px;
`;
