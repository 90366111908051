import { useConfig } from "@web/config/context";
import { DocumentModel } from "@web/models";
import { MessageDescriptor } from "react-intl";
import { ButtonIconType } from "../../elements/Button";
import { texts } from "@web/components/Document/texts";

interface SignatureToolbarItem {
  icon: ButtonIconType;
  label: MessageDescriptor;
  disabled?: boolean;
  onClick?: () => void;
}

// Scrive has a 10MB file size limit
// https://support.scrive.com/hc/en-us/articles/360002902419-What-is-the-maximum-file-size-of-a-PDF-in-Scrive
const SCRIVE_FILE_SIZE_LIMIT_BYTES = 10000000;

export const useSignatureToolbarItem = (
  document?: DocumentModel
): SignatureToolbarItem | undefined => {
  const config = useConfig();

  if (!document || !config.canSendForSignature) {
    return undefined;
  }

  let disabled = false;
  let label = texts.tooltipSendForSignature;

  if (document.newestVersion.contentType !== "application/pdf") {
    disabled = true;
    label = texts.tooltipSendForSignaturePdfOnly;
  }

  if (document.newestVersion.isSigned) {
    disabled = true;
    label = texts.tooltipSendForSignatureAlreadySigned;
  }

  if (document.newestVersion.isSentForSignature) {
    disabled = true;
    label = texts.tooltipSendForSignatureAlreadySent;
  }

  if (document.currentVersion !== document.newestVersion) {
    disabled = true;
    label = texts.tooltipSendForSignatureNewestVersionOnly;
  }

  if (document.isLocked) {
    disabled = true;
    label = texts.tooltipSendForSignatureIsLocked;
  }

  if (document.newestVersion.fileSize > SCRIVE_FILE_SIZE_LIMIT_BYTES) {
    disabled = true;
    label = texts.tooltipSendForSignatureTooLarge;
  }

  return {
    icon: "SignatureIcon",
    onClick: document.newestVersion?.sendForSignature,
    label,
    disabled,
  };
};
