import axios from "axios";
import throttle from "lodash/throttle";
import { action, computed, observable, ObservableMap } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import { errorMessage } from "@web/utils/helpers";
import {
  DRAFT_ENTRY_ID,
  UploadGroup,
  UploadJob,
  UploadJobStatus,
  UploadRequest,
  UploadRequestType,
} from "@web/models";
import { MessageWithValues } from "@web/translations";
import { RootStore } from ".";
import { UploadResult } from "@web/components/Upload/types";

export class UploadStore {
  @observable
  uploads: UploadGroup[] = [];

  public get allowDuplication(): boolean {
    return this._allowDuplication;
  }

  private jobMap = new ObservableMap<string, UploadJob>();

  constructor(
    private api: Api,
    private rootStore: RootStore,
    private _allowDuplication: boolean
  ) {
    window.onbeforeunload = () => {
      if (this.hasActiveUploads) {
        return "Upload in progress. Are you sure want to leave this page?";
      }
    };
  }

  @computed
  get hasActiveUploads(): boolean {
    return this.uploads.some((group) => group.isActive);
  }

  @computed
  get activeUploadsCount(): number {
    return this.uploads.filter((group) => group.isActive).length;
  }

  @computed
  get uploadText(): MessageWithValues | undefined {
    if (this.uploads.length === 0) {
      return undefined;
    }

    const values = {
      activeUploadsCount: this.activeUploadsCount,
      allUploadsCount: this.uploads.length,
    };

    const message = this.hasActiveUploads
      ? texts.uploading
      : texts.uploadedComplete;

    return {
      ...message,
      values,
    };
  }

  public async markDuplicates(jobs: UploadJob[]): Promise<void> {
    const checksums: Map<UploadJob, string> = new Map();

    jobs.forEach((job) => {
      checksums.set(job, job.result.checkSum);
    });

    if (checksums.size > 0) {
      try {
        const results = await this.api.hasDuplicatedChecksum(
          Array.from(checksums.values())
        );

        results.forEach((result) => {
          checksums.forEach((checksum, job) => {
            if (job.result.checkSum === result.checksum) {
              job.result.isDuplicated = true;
            }
          });
        });
      } catch (err) {
        if (axios.isCancel(err)) {
          jobs.forEach((job) => job.setStatus(UploadJobStatus.cancelled));
        } else {
          jobs.forEach((job) => {
            job.setStatus(UploadJobStatus.failed);
            job.setResult({ error: errorMessage(err) });
          });
        }
      }
    }
  }

  @action
  addRequest = async (
    request: UploadRequest,
    haveToSelectTag?: boolean
  ): Promise<UploadResult | undefined> => {
    if (haveToSelectTag) {
      this.rootStore.messageStore.addMissingMandatoryTagMessage();
      return;
    }

    const entryId =
      request.type === UploadRequestType.document ? request.entryId : undefined;

    if (entryId === DRAFT_ENTRY_ID) {
      throw new Error(
        "Creating documents based on draft entry object is not allowed."
      );
    }

    const jobs =
      request.type === UploadRequestType.version
        ? [new UploadJob(request, request.file)]
        : request.files.map((file) => new UploadJob(request, file));

    return this.addJobs(request, jobs);
  };

  @action
  cancelAllJobs = () => {
    const allJobs = Array.from(this.jobMap.values());
    const runningJobs = allJobs.filter((job) => {
      switch (job.status) {
        case UploadJobStatus.created:
        case UploadJobStatus.waitingForEntry:
        case UploadJobStatus.readyForUpload:
        case UploadJobStatus.uploading:
          return true;
      }
    });

    runningJobs.forEach((job) => job.cancel());
  };

  @action
  private addJobs = (
    request: UploadRequest,
    jobs: UploadJob[]
  ): Promise<UploadResult> => {
    jobs.forEach((job) => this.jobMap.set(job.jobId, job));

    if (request.type === UploadRequestType.entry) {
      this.uploads.unshift(new UploadGroup(jobs, request.type, request.title));
    } else {
      jobs.forEach((job) =>
        this.uploads.unshift(new UploadGroup([job], request.type))
      );
    }

    return this.runJobs(request, jobs);
  };

  private runJobs = async (
    request: UploadRequest,
    jobs: UploadJob[]
  ): Promise<UploadResult> => {
    if (jobs.some((job) => job.status !== UploadJobStatus.created)) {
      throw new Error("Can only run job from 'created' status");
    }

    for (const job of jobs) {
      job.setStatus(UploadJobStatus.readyForUpload);
      await this.runJob(job);
    }

    return {
      jobs,
    };
  };

  private runJob = async (job: UploadJob) => {
    if (job.status !== UploadJobStatus.readyForUpload) {
      throw new Error(
        "Can only start uploading job from 'readyForUpload' status"
      );
    }
    job.setStatus(UploadJobStatus.uploading);
    try {
      await this.uploadFile(job);
    } catch (err) {
      if (axios.isCancel(err)) {
        job.setStatus(UploadJobStatus.cancelled);
      } else {
        job.setStatus(UploadJobStatus.failed);
        job.setResult({ error: errorMessage(err) });
      }
    }
  };

  private uploadFile = async (job: UploadJob) => {
    const { file } = job.data;
    const cancelToken = job.cancelToken.token;
    const result = await this.api.uploadFile(
      file,
      throttle(({ loaded, total }) => {
        job.setProgress({
          totalBytes: total,
          completedBytes: loaded,
          completedPercent: (80 * loaded) / total,
        });
      }, 100),
      cancelToken
    );

    job.setResult({
      fileId: result.data.id,
      checkSum: result.data.checksum,
      isDuplicated: false,
    });
  };
}

const texts = defineMessages({
  uploading: {
    id: "upload.overlay.title.uploading.web",
    defaultMessage: `Uploading {activeUploadsCount, plural,
      one {1 item}
      other {# items}
    }`,
  },
  uploadedComplete: {
    id: "upload.overlay.title.uploadingcomplete.web",
    defaultMessage: `Finished uploading`,
  },
});
