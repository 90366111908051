import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import { EntryModel, EntryStatusModel } from "@web/models";
import { useConfig } from "@config/context";
import { EntryStatusSelect } from "./EntryStatusSelect";
import { PopoverBox, StatusColor } from "@web/elements";
import { ENTRY_STATUS_COLORS } from "./types";
import { Label } from "@web/elements/Label";

interface Props {
  entry: EntryModel;
  statusOptions: EntryStatusModel[];
}

interface StatusProps {
  entry: EntryModel;
  size?: number;
}

export const EntryStatusEdit: FC<Props> = observer((p) => {
  const { canChangeEntryStatus } = useConfig();
  const [open, setOpen] = useState(false);
  const ref = React.createRef<HTMLButtonElement>();

  const { entry, statusOptions } = p;

  if (entry.isDraft) {
    return null;
  }

  const enabled =
    entry.canUpdate && canChangeEntryStatus && statusOptions.length > 0;
  const currentStatus = entry.entryStatus;

  return (
    <>
      <Label
        ref={ref}
        toggled={currentStatus !== "NO-STATUS"}
        disabled={!enabled}
        onClick={() => setOpen(true)}
      >
        <StatusColor color={ENTRY_STATUS_COLORS[currentStatus]} />
      </Label>

      {open && (
        <PopoverBox
          triggerRef={ref}
          onClickOutside={() => setOpen(false)}
          margin={5}
          alignY="top"
        >
          <EntryStatusSelect
            editable={enabled}
            initialStatus={currentStatus}
            statusOptions={statusOptions}
            onCloseClick={() => setOpen(false)}
            onStatusChange={entry.updateStatus}
          />
        </PopoverBox>
      )}
    </>
  );
});

export const EntryStatus = observer((props: StatusProps) => {
  const status = props.entry.entryStatus;

  if (!status || status === "NO-STATUS") {
    return null;
  }

  return <StatusColor color={ENTRY_STATUS_COLORS[status]} />;
});
