import React from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { LoResPreviewImage } from "@web/api/BFF/types";

interface Props {
  electronicDocumentId: number;
  loResImages: LoResPreviewImage[];
}

export const PDFPreviewLowBandwidth: React.FC<Props> = ({
  electronicDocumentId,
  loResImages,
}) => {
  const intl = useIntl();

  return (
    <_wrap>
      {loResImages.map((image) => {
        const loadingStrategy = image.pageNumber > 3 ? "lazy" : "eager";

        return (
          <img
            key={image.pageNumber}
            src={`/bff/preview/pdf-page/${electronicDocumentId}/${image.pageNumber}.webp`}
            alt={intl.formatMessage(texts.page, {
              pageNumber: image.pageNumber,
            })}
            height={`${image.height * 1.5}px`}
            width={`${image.width * 1.5}px`}
            loading={loadingStrategy}
          />
        );
      })}
    </_wrap>
  );
};

const _wrap = styled.div`
  height: 100%;
  width: 100%;

  overflow: scroll;

  > img {
    margin: 20px auto;
    display: block;
  }
`;

const texts = defineMessages({
  page: {
    id: "filepreview.pdf.page",
    defaultMessage: "Page {pageNumber}",
  },
});
