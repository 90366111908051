import React from "react";
import styled, { keyframes } from "styled-components";
import { vars } from "@web/styles";

const SkeletonPulse = keyframes`
  from { opacity: .2; }
  to { opacity: .05; }
`;

interface IProps {
  length?: number;
  size?: number;
}

const SkeletonBase = styled.div<IProps>`
  background: ${vars.dark55};
  border-radius: 2px;
  animation: ${SkeletonPulse} 500ms infinite alternate;
  display: inline-block;
`;

export const SkeletonText = styled(SkeletonBase).attrs((p: IProps) => ({
  style: {
    width: `${p.length || 10}em`,
    fontSize: p.size ? `${p.size}em` : "inherit",
  },
}))`
  height: 1em;
  max-width: 100%;
`;

export const SkeletonBox = styled(SkeletonBase)`
  width: ${(p) => p.size ?? 5}em;
  height: ${(p) => p.size ?? 5}em;
`;
