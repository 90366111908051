import React from "react";

export const LockOpenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1875 8.5625H17.125C18.1563 8.5625 19 9.40616 19 10.4375V19.8125C19 20.8438 18.1563 21.6875 17.125 21.6875H5.875C4.84366 21.6875 4 20.8438 4 19.8125V10.4375C4 9.40616 4.84366 8.5625 5.875 8.5625H14.4062L14.4063 6.6875C14.4063 5.09356 13.0939 3.78116 11.5 3.78116C10.3054 3.78116 9.26873 4.51854 8.82442 5.55916L7.15505 4.93314C7.85317 3.21753 9.54131 2 11.5 2C14.0781 2 16.1875 4.10938 16.1875 6.6875V8.5625ZM9.625 15.2188C9.625 16.2501 10.4687 17.0938 11.5 17.0938C12.5313 17.0938 13.375 16.2501 13.375 15.2188C13.375 14.1874 12.5313 13.3438 11.5 13.3438C10.4687 13.3438 9.625 14.1874 9.625 15.2188Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
