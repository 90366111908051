import styled, { css } from "styled-components";

interface IPadding {
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  pa?: string;
  pv?: string;
  ph?: string;
}

interface IMargin {
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  ma?: string;
  mv?: string;
  mh?: string;
}

interface IPosition {
  pos?: "absolute" | "relative" | "fixed" | "sticky";
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface IFlex {
  flex?: string;
  grow?: string;
  shrink?: string;
  basis?: string;
}

interface ISize {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
}

interface IModProps extends ISize, IFlex, IMargin, IPadding, IPosition {
  display?: string;
  overflow?: "hidden" | "scroll" | "auto" | "visible";
  className?: string;
  tAlign?: "left" | "right" | "center" | "justify";
  full?: boolean;
}

interface IFlexModProps extends IModProps {
  column?: boolean;
  gap?: string;
  wrap?: boolean;
  vAlign?: string;
  hAlign?: string;
}

export const Box = styled.div<IModProps>`
  display: ${(p) => p.display || "block"};
  height: ${(p) => p.height};
  min-height: ${(p) => p.minHeight};
  max-height: ${(p) => p.maxHeight};
  width: ${(p) => p.width};
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};

  flex: ${(p) => p.flex};
  flex-grow: ${(p) => p.grow};
  flex-shrink: ${(p) => p.shrink};
  flex-basis: ${(p) => p.basis};
  overflow: ${(p) => p.overflow};
  text-align: ${(p) => p.tAlign};

  padding-top: ${(p) => p.pt || p.pv || p.pa};
  padding-right: ${(p) => p.pr || p.ph || p.pa};
  padding-bottom: ${(p) => p.pb || p.pv || p.pa};
  padding-left: ${(p) => p.pl || p.ph || p.pa};

  margin-top: ${(p) => p.mt || p.mv || p.ma};
  margin-right: ${(p) => p.mr || p.mh || p.ma};
  margin-bottom: ${(p) => p.mb || p.mv || p.ma};
  margin-left: ${(p) => p.ml || p.mh || p.ma};

  position: ${(p) => p.pos};
  top: ${(p) => p.top};
  right: ${(p) => p.right};
  bottom: ${(p) => p.bottom};
  left: ${(p) => p.left};

  ${(p) =>
    p.full &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `}
`;

export const FlexBox = styled(Box)<IFlexModProps>`
  display: flex;
  flex-direction: ${(p) => (p.column ? "column" : "row")};
  flex-wrap: ${(p) => p.wrap && "wrap"};
  align-items: ${(p) => (p.column ? p.hAlign : p.vAlign)};
  justify-content: ${(p) => (p.column ? p.vAlign : p.hAlign)};

  > *:not(:first-child) {
    margin-top: ${(p) => p.column && p.gap};
    margin-left: ${(p) => !p.column && p.gap};
  }
`;

export const HBox = styled(FlexBox)`
  overflow: ${(p) => p.overflow || "hidden"};
`;

export const VBox = styled(FlexBox).attrs({ column: true })`
  overflow: ${(p) => p.overflow || "hidden"};
`;

export const ScrollBox = styled(Box)`
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;
