import React, { useState, FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { vars } from "@web/styles";
import { ClassModel, SectionModel, TagSearchModel } from "@web/models";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { commonTexts } from "@web/translations";
import { Overlay } from "@web/components";
import { NewBadge } from "@web/elements";
import { EditClassification } from "@web/components/Settings/Classification/EditClassification";
import { EditTags } from "../Tag/EditTags";
import { EditTagStatuses } from "../TagStatus/EditTagStatuses";
import { EditAttributes } from "../Attribute/EditAttributes";
import { ClassificationMenu } from "./Menus";

interface Props {
  classification: ClassModel;
  section: SectionModel;
  highlighted?: boolean;
  tagSearch: TagSearchModel;
  onHover: (classificationId: UUID | undefined) => void;
}

type EditDialogs =
  | "editClassification"
  | "statuses"
  | "attributes"
  | "tags"
  | null;

export const ClassificationButton: FC<Props> = observer((p) => {
  const [getConfirmation] = useConfirmationDialog();
  const [editDialog, setEditDialog] = useState<EditDialogs>(null);

  const { classification, section } = p;

  const handleUnlink = async () => {
    const confirmed = await getConfirmation({
      title: texts.unlinkDialogTitle,
      message: texts.unlinkDialogQuestion,
      confirmText: commonTexts.remove,
      warningText: texts.unlinkDialogWarning,
      values: {
        classification: classification.title,
        section: section.title,
      },
    });

    if (confirmed) {
      classification.unlinkFromSection(section);
    }
  };

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: texts.deleteClassification,
      message: texts.deleteDialogQuestion,
      confirmText: commonTexts.delete,
      warningText: commonTexts.asyncDeleteWarning,
      values: {
        title: classification.title,
        entity: "classification",
      },
    });

    if (confirmed) {
      classification.delete();
    }
  };

  const resetEditDialog = () => setEditDialog(null);

  const { title, uuid, isRecentlyCreated, update, isMandatory } =
    classification;

  return (
    <>
      <_wrap
        highlighted={p.highlighted}
        onMouseEnter={() => p.onHover(uuid)}
        onMouseLeave={() => p.onHover(undefined)}
        onDoubleClick={() => setEditDialog("tags")}
      >
        <_title>{title}</_title>
        {isRecentlyCreated && <NewBadge />}
        <ClassificationMenu
          section={section}
          classification={classification}
          onDelete={handleDelete}
          onUnlink={handleUnlink}
          onTagEdit={() => setEditDialog("tags")}
          onRename={() => setEditDialog("editClassification")}
          onAttributesEdit={() => setEditDialog("attributes")}
          onStatusEdit={() => setEditDialog("statuses")}
        />
      </_wrap>

      {/** Overlays for different edit operations */}
      {editDialog === "editClassification" && (
        <EditClassification
          saveText={commonTexts.save}
          title={title}
          isMandatory={isMandatory}
          onSave={update}
          onClose={resetEditDialog}
        />
      )}

      {editDialog === "attributes" && (
        <Overlay onEscape={resetEditDialog} onClickOutside={resetEditDialog}>
          <EditAttributes
            parent={classification}
            isSaving={classification.saving}
            showButton={"close"}
            onBackClick={resetEditDialog}
            onCreateAttribute={classification.createAttribute}
          />
        </Overlay>
      )}

      {editDialog === "statuses" && (
        <Overlay onEscape={resetEditDialog} onClickOutside={resetEditDialog}>
          <EditTagStatuses
            classification={classification}
            onCloseClick={resetEditDialog}
          />
        </Overlay>
      )}

      {editDialog === "tags" && (
        <Overlay onEscape={resetEditDialog} onClickOutside={resetEditDialog}>
          <EditTags
            classification={classification}
            tagSearch={p.tagSearch}
            onCloseClick={resetEditDialog}
          />
        </Overlay>
      )}
    </>
  );
});

const _wrap = styled.div<{ highlighted?: boolean }>`
  background-color: ${(p) =>
    p.highlighted ? vars.secondaryAltLight10 : vars.content};
  display: inline-flex;
  align-items: center;
  margin: 5px 10px;
  padding: 4px 8px 4px 12px;
  color: ${vars.contentFg};
  border: 1px solid ${(p) => (p.highlighted ? vars.transparent : vars.dark05)};
  border-radius: 5px;
  outline: none;
  box-shadow: ${vars.shadow.z1};
  font-size: 1.1em;
  cursor: default;
`;

const _title = styled.div`
  margin-right: 6px;
`;

const texts = defineMessages({
  deleteClassification: {
    id: "settings.classification.delete",
    defaultMessage: "Delete list",
  },
  deleteDialogQuestion: {
    id: "settings.classification.deletedialog.question",
    defaultMessage:
      "This will delete the list <em>{title}</em> and remove all corresponding tags from folders. Are you sure you want to proceed?",
  },
  unlinkDialogTitle: {
    id: "settings.classification.unlinkdialog.title",
    defaultMessage: "Remove {classification}",
  },
  unlinkDialogQuestion: {
    id: "settings.classification.unlinkdialog.question",
    defaultMessage: `Any tags from the list <em>{classification}</em> will be removed
      from folders in the section <em>{section}</em>.`,
  },
  unlinkDialogWarning: {
    id: "settings.classification.unlinkdialog.warning",
    defaultMessage: `Sometimes it takes a while for the list
      to be removed, depending on the number of documents stored in Documaster.
      The operation will run quietly in the background.`,
  },
});
