import React, { useState, useEffect, FC } from "react";
import { observer } from "mobx-react";
import styled, { css } from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import {
  TagModel,
  ClassModel,
  TagSearchModel,
  WritableFields,
  AttributeModel,
} from "@web/models";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { AddValueBox, LazyList, Overlay } from "@web/components";
import { EditAttributes } from "../Attribute/EditAttributes";
import { TagStatusFilter } from "@web/components/TagStatus/TagStatusFilter";
import { Header } from "../Header";
import { AddLink } from "../AddLink";
import { TagMenu } from "./Menu";
import { Empty } from "../Empty";
import { SetTagAttributes } from "./SetTagAttributes";
import { TagRow } from "./TagRow";
import { SettingsBox } from "../Box";

interface Events {
  onCloseClick: () => void;
}

interface Props extends Events {
  classification: ClassModel;
  tagSearch: TagSearchModel;
}

const LAZY_LIST_PROPS = {
  itemSize: 36,
};

export const EditTags: FC<Props> = observer((p) => {
  const intl = useIntl();
  const [getConfirmation] = useConfirmationDialog();
  const [addTag, setAddTag] = useState(false);
  const [renameTag, setRename] = useState<TagModel>();
  const [editAttributesForTag, setEditAttributes] = useState<TagModel>();
  const [setAttributesForTag, setSetAttributes] = useState<TagModel>();

  const { classification } = p;

  useEffect(() => {
    p.tagSearch.setActiveClassification(classification.uuid);
    return () => {
      p.tagSearch.clear();
    };
  }, []);

  const handleDelete = async (tag: TagModel) => {
    const confirmed = await getConfirmation({
      title: texts.deleteTagDialogTitle,
      message: texts.deleteTagDialogQuestion,
      confirmText: commonTexts.delete,
      warningText: commonTexts.asyncDeleteWarning,
      values: {
        title: tag.title,
        entity: "tag",
      },
    });

    if (confirmed) {
      classification.deleteTag(tag);
    }
  };

  const handleSaveTag = (doSave: boolean, title: string) => {
    if (doSave && addTag) {
      classification.createTag(title);
    }

    if (doSave && renameTag) {
      classification.renameTag(renameTag, title);
    }

    setAddTag(false);
    setRename(undefined);
  };

  const closeAddReset = () => {
    setAddTag(false);
    setRename(undefined);
  };

  const handleCreateAttribute = (
    fields: WritableFields<AttributeModel>,
    tag: TagModel
  ) => {
    classification.createAttributeForTag(tag, fields);
  };

  const handleQueryChange = (query: string) => {
    p.tagSearch.setQuery(query);
  };

  const renderRow = (index: number) => {
    const tags = p.tagSearch.matches || classification.tags;
    const tag = tags[index];
    if (!tag) {
      return <></>;
    }
    return (
      <TagRow tag={tag} key={tag.id}>
        <TagMenu
          tag={tag}
          parentHasAttributes={classification.hasAttributesDefined}
          onDelete={() => handleDelete(tag)}
          onRename={() => setRename(tag)}
          onAttributesEdit={() => setEditAttributes(tag)}
          onAttributesSet={() => setSetAttributes(tag)}
        />
      </TagRow>
    );
  };

  const isSaving =
    classification.createTagState?.saving || classification.saving;

  return (
    <>
      <SettingsBox>
        <Header
          showButton="close"
          showSpinner={isSaving}
          title={classification.title}
          subtitle={intl.formatMessage(commonTexts.tags)}
          onButtonClick={p.onCloseClick}
        />

        <_filters>
          <TagStatusFilter
            variant="alt"
            selectedStatusIds={p.tagSearch.tagStatuses}
            options={classification.tagStatuses}
          />
        </_filters>

        {classification.hasNoTags && <Empty title={texts.noTagsAdded} />}

        <_tags>
          <LazyList
            loadingStatus={classification.tagLoadingStatus}
            loadNextPage={classification.loadMoreTags}
            renderItem={renderRow}
            onQueryChange={handleQueryChange}
            {...LAZY_LIST_PROPS}
          />
        </_tags>

        {classification.canCreateTags && (
          <AddLink title={texts.addTag} onClick={() => setAddTag(true)} />
        )}
      </SettingsBox>

      {/** Overlays for different edit operations */}
      {(addTag || renameTag) && (
        <Overlay
          bg="transparent"
          onEscape={closeAddReset}
          onClickOutside={closeAddReset}
        >
          <SettingsBox>
            <AddValueBox
              type="tag"
              currentEntity={renameTag}
              getSimilarValues={classification.verifyNewTagTitle}
              onValueSave={handleSaveTag}
            />
          </SettingsBox>
        </Overlay>
      )}

      {editAttributesForTag && (
        <Overlay
          bg="transparent"
          onClickOutside={() => setEditAttributes(undefined)}
          onEscape={() => setEditAttributes(undefined)}
        >
          <EditAttributes
            parent={editAttributesForTag}
            isSaving={editAttributesForTag.saving}
            showButton={"back"}
            onBackClick={() => setEditAttributes(undefined)}
            onCreateAttribute={handleCreateAttribute}
          />
        </Overlay>
      )}

      {setAttributesForTag && (
        <Overlay
          bg="transparent"
          onClickOutside={() => setEditAttributes(undefined)}
          onEscape={() => setEditAttributes(undefined)}
        >
          <SetTagAttributes
            classification={classification}
            tag={setAttributesForTag}
            onBackClick={() => setSetAttributes(undefined)}
          />
        </Overlay>
      )}
    </>
  );
});

const _filters = styled.span`
  :not(:empty) {
    padding-bottom: 10px;
  }
`;

const _tags = styled.div`
  height: 100%;
  margin: 0 10px;
`;

const texts = defineMessages({
  title: {
    id: "settings.tags.title",
    defaultMessage: "{classificationTitle}: tags",
  },
  addTag: {
    id: "settings.tags.add",
    defaultMessage: "Add tag",
  },
  deleteTagDialogTitle: {
    id: "settings.tags.deletedialog.title",
    defaultMessage: "Delete tag",
  },
  deleteTagDialogQuestion: {
    id: "settings.tags.deletedialog.question",
    defaultMessage:
      "This will permanently remove the tag <em>{title}</em> from any folders where it has been applied. Are you sure you want to proceed?",
  },
  noTagsAdded: {
    id: "settings.tags.notagsadded",
    defaultMessage: "No tags added.",
  },
});
