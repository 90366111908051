import React, { useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { Button } from "@web/elements/Button";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

interface Props {
  locked: boolean;
  children: React.ReactNode;
}

export const TagLock = (p: Props) => {
  const intl = useIntl();
  const [locked, setLocked] = useState(p.locked);
  const [dialog, setDialog] = useState(false);
  const targetRef = useRef<EventTarget | null>(null);

  const handleChildrenClick = (e: React.MouseEvent) => {
    if (locked) {
      e.stopPropagation();
      setDialog(true);
      targetRef.current = e.target;
    }
  };

  const handleUnlock = () => {
    setLocked(false);
    setTimeout(() => {
      (targetRef.current as HTMLElement)?.click();
    });
  };

  const handleCancel = () => {
    setDialog(false);
    targetRef.current = null;
  };

  return (
    <>
      <_children onClickCapture={handleChildrenClick}>{p.children}</_children>
      {locked && dialog && (
        <_overlay>
          <_inner>
            {intl.formatMessage(texts.message)}
            <_actions>
              <Button
                text={texts.unlock}
                variant="primary"
                icon="LockOpenIcon"
                onClick={handleUnlock}
              />
              <Button
                autoFocus
                text={commonTexts.cancel}
                onClick={handleCancel}
              />
            </_actions>
          </_inner>
        </_overlay>
      )}
    </>
  );
};

const _children = styled.div`
  display: contents;
`;

const _overlay = styled.div`
  background: ${vars.light85};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const _inner = styled.div`
  background: ${vars.content};
  border-radius: 0.25rem;
  box-shadow: ${vars.shadow.z1}, ${vars.shadow.z3};
  color: ${vars.infoFg};
  padding: 0.325rem;
  padding-left: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const _actions = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const texts = defineMessages({
  message: {
    id: "entry.tags.lock.message",
    defaultMessage: "Unlock to make changes",
  },
  unlock: {
    id: "entry.tags.lock.unlock",
    defaultMessage: "Unlock",
  },
});
