import styled from "styled-components";
import { vars } from "@web/styles";

export const Tabs = styled.div.attrs(() => ({ role: "tablist" }))`
  background: ${vars.content};
  border-bottom: 2px solid ${vars.dark05};
  display: flex;
  overflow: auto;
`;

export const Tab = styled.button`
  background: none;
  border: none;
  color: ${vars.dark55};
  cursor: pointer;
  font: inherit;
  font-weight: 500;
  padding: 0.75rem;
  margin: 0;
  flex-shrink: 0;

  :hover {
    color: ${vars.contentFg};
  }

  &[aria-selected="true"] {
    color: ${vars.contentFg};
  }
`;
