import { IObjectWithAccessControl } from "@web/api/Integration/types";
import { AccessControlStore } from "@web/stores/AccessControlStore";
import { action, computed } from "mobx";
import { QueryLoadingModel } from "../DataLoadingModel";
import { AccessGroupListModel } from "./AccessGroupListModel";
import { AccessGroupModel } from "./AccessGroupModel";
import { ObjectPermissionsModel } from "./ObjectPermissionsModel";
import { ObjectWithAccessControlModel } from "./ObjectWithAccessControlModel";

interface ListItem<ObjectType extends IObjectWithAccessControl> {
  accessGroup: AccessGroupModel;
  object: ObjectWithAccessControlModel<ObjectType>;
  objectPermissions: ObjectPermissionsModel | undefined;
}

export class ObjectPermissionListModel<
  ObjectType extends IObjectWithAccessControl
> {
  constructor(
    private store: AccessControlStore,
    public object: ObjectWithAccessControlModel<ObjectType>,
    public accessGroupList: AccessGroupListModel
  ) {}

  @computed
  get items(): ListItem<ObjectType>[] {
    return this.accessGroupList.accessGroups.map((accessGroup) => {
      const objectPermissions =
        this.object.objectPermissionsForAccessGroup(accessGroup);
      return { object: this.object, objectPermissions, accessGroup };
    });
  }

  @computed
  get loadingStatus(): QueryLoadingModel {
    return this.accessGroupList.loadingStatus;
  }

  @action.bound
  filter(query: string) {
    this.accessGroupList.filter(query);
  }

  @action.bound
  async loadNextPage(): Promise<void> {
    this.accessGroupList.loadNextPage();
  }
}
