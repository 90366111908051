import { APIParameters } from "@web/api/Integration/types";
import { getDateWithoutTime } from "@web/utils/dates";
import {
  addDays,
  addMonths,
  addYears,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { defineMessage, MessageDescriptor } from "react-intl";
import { AttributeType } from ".";

/** Filter types */
type BaseFilterType = {
  id:
    | "ne" // numeric: equals
    | "nr" // numeric: range
    | "se" // string: equals
    | "de" // date: equals
    | "dr" // date: range
    | "pm" // date: past month
    | "pq" // date: past quarter
    | "py" // date: past year
    | "nm" // date: next months
    | "nq" // date: next quarter
    | "ny" // date: next year
    | "ln" // list: numeric
    | "ls" // list: string
    | "ld"; // list: date
  title: MessageDescriptor;
  attributeType: AttributeType;
  defaultChoice?: true;
};

type Range = BaseFilterType & {
  type: "range";
  value: string;
  valueEnd: string;
};

type Equals = BaseFilterType & {
  type: "equals";
  value: string | Date;
};

type RelativeTo = BaseFilterType & {
  type: "relative";
  value: Date;
  valueEnd: Date;
};

type List = BaseFilterType & {
  type: "list";
  value: (string | number)[];
};

export type FilterType = Range | Equals | RelativeTo | List;
export type AttributeFilterParam = {
  query: string[];
  params: APIParameters;
};

const TODAY = getDateWithoutTime();

export const ATTRIBUTE_FILTER_OPTIONS: Record<AttributeType, FilterType[]> = {
  Numeric: [
    {
      id: "ne",
      type: "equals",
      attributeType: "Numeric",
      title: defineMessage({
        id: "attribute.filter.numeric.equals",
        defaultMessage: "Equals number",
      }),
      value: "",
    },
    {
      id: "nr",
      type: "range",
      attributeType: "Numeric",
      defaultChoice: true,
      title: defineMessage({
        id: "attribute.filter.numeric.range",
        defaultMessage: "Is in range",
      }),
      value: "",
      valueEnd: "",
    },
  ],
  String: [
    {
      id: "se",
      type: "equals",
      attributeType: "String",
      title: defineMessage({
        id: "attribute.filter.string.equals",
        defaultMessage: "Equals text",
      }),
      value: "",
    },
  ],
  Date: [
    {
      id: "dr",
      type: "range",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.range",
        defaultMessage: "Select dates",
      }),
      value: TODAY.toString(),
      valueEnd: addDays(TODAY, 1).toString(),
    },
    {
      id: "de",
      type: "equals",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.equals",
        defaultMessage: "Equals date",
      }),
      value: TODAY.toString(),
    },
    {
      id: "pm",
      type: "relative",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.previous-month",
        defaultMessage: "Last 30 days",
      }),
      value: subDays(TODAY, 30),
      valueEnd: TODAY,
    },
    {
      id: "pq",
      type: "relative",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.previous-querter",
        defaultMessage: "Last 3 months",
      }),
      value: subMonths(TODAY, 3),
      valueEnd: TODAY,
    },
    {
      id: "py",
      type: "relative",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.previous-year",
        defaultMessage: "Last 12 months",
      }),
      value: subYears(TODAY, 1),
      valueEnd: TODAY,
    },
    {
      id: "nm",
      type: "relative",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.next-month",
        defaultMessage: "Next 30 days",
      }),
      value: TODAY,
      valueEnd: addDays(TODAY, 30),
    },
    {
      id: "nq",
      type: "relative",
      attributeType: "Date",
      defaultChoice: true,
      title: defineMessage({
        id: "attribute.filter.date.next-querter",
        defaultMessage: "Next 3 months",
      }),
      value: TODAY,
      valueEnd: addMonths(TODAY, 3),
    },
    {
      id: "ny",
      type: "relative",
      attributeType: "Date",
      title: defineMessage({
        id: "attribute.filter.date.next-year",
        defaultMessage: "Next 12 months",
      }),
      value: TODAY,
      valueEnd: addYears(TODAY, 1),
    },
  ],
  DateList: [
    {
      id: "ld",
      type: "list",
      attributeType: "DateList",
      title: defineMessage({
        // Not in use, as there is only 1 filtering option for lists
        id: "attribute.filter.list.date",
        defaultMessage: "Date options",
      }),
      value: [],
    },
  ],
  StringList: [
    {
      id: "ls",
      type: "list",
      attributeType: "StringList",
      title: defineMessage({
        // Not in use, as there is only 1 filtering option for lists
        id: "attribute.filter.list.string",
        defaultMessage: "Text options",
      }),
      value: [],
    },
  ],
  NumericList: [
    {
      id: "ln",
      type: "list",
      attributeType: "NumericList",
      title: defineMessage({
        // Not in use, as there is only 1 filtering option for lists
        id: "attribute.filter.list.number",
        defaultMessage: "Number options",
      }),
      value: [],
    },
  ],
};
