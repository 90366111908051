import styled, { css } from "styled-components";
import { vars } from "@web/styles";

const overridableTypeButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => ({
  type: props.type ?? "button",
});

const buttonReset = css`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const FloatingButton = styled.button.attrs(overridableTypeButton)`
  ${buttonReset};
  background: ${vars.primary};
  border-radius: 100%;
  box-shadow: ${vars.shadow.z3};
  color: ${vars.primaryFg};
  width: 3rem;
  height: 3rem;
`;
