import { css } from "styled-components";
import { useEffect, useState } from "react";
import throttle from "lodash/throttle";

/**
 * Loops over a theme object, replacing the values with a CSS var function that
 * contains a generated CSS variable name and the original value as a fallback.
 *
 * @example
 * const vars = createCssVarObject({
 *  button: {
 *    color: "red",
 *    hover: {
 *      color: "blue"
 *    }
 *  }
 * });
 *
 * vars.button.color; // "var(--theme-button-color, red)"
 * vars.button.hover.color; // "var(--theme-button-hover-color, blue)"
 *
 * const _myButton = styled.button`
 *  color: ${vars.button.color};
 *  :hover {
 *    color: ${vars.button.hover.color};
 *  }
 * `;
 */
export const createCssVars = <T extends { [key: string]: any }>(
  themeObject: T,
  namespace = "theme"
): T => {
  const out: { [key: string]: any } = {};

  for (const key in themeObject) {
    const path = namespace ? [namespace, key].join("-") : key;
    const value = themeObject[key];

    if (typeof value === "object") {
      out[key] = createCssVars(value, path);
    } else {
      out[key] = `var(--${path}, ${value})`;
    }
  }

  return out as T;
};

/**
 * Generates an array of custom css properties to use in the :root pseudo class.
 *
 * @example
 * const theme = createCssVariables({
 *  button: {
 *    color: "red",
 *    hover: {
 *      color: "blue"
 *    }
 *  }
 * });
 *
 * theme; // ["--theme-button-color: red;", "--theme-button-hover-color: blue;"]
 *
 * const themeCss = createGlobalStyle`
 *  :root {
 *    ${theme.join("\n")}
 *  }
 * `;
 */
export const createCssVarsOverrides = (
  themeObject: Record<string, any>,
  namespace = "theme"
): string[] => {
  const out: string[] = [];

  Object.entries(themeObject).forEach(([key, value]) => {
    const path = namespace ? [namespace, key].join("-") : key;
    if (typeof value === "object") {
      out.push(...createCssVarsOverrides(value, path));
    } else {
      out.push(`--${path}: ${value};`);
    }
  });

  return out;
};

export const mediaQuery = {
  desktop: "(min-width: 769px)",
  compact: "(max-width: 768px)",
  touch: "(hover: none)",
  noTouch: "(hover: hover)",
};

/**
 * Returns true/false on media query match. For use in regular components.
 * @param media
 */
export const isMedia = (...media: Array<keyof typeof mediaQuery>) => {
  const query = media.map((m) => mediaQuery[m]).join(" and ");
  return window.matchMedia(query).matches;
};

/**
 * Returns a CSS media query. For use in styled components.
 * @example
 * media("compact", "touch")
 * @returns
 * `@media (max-width: 768px) and (hover: none)`
 */
export const media = (...media: Array<keyof typeof mediaQuery>) => {
  const query = media.map((m) => mediaQuery[m]).join(" and ");
  return `@media ${query}`;
};

/**
 * React Hook returning an object of the queries and does a re-render every time
 * the current mediaQuery changes.
 */
export const useMedia = () => {
  const [, updateState] = useState({});

  useEffect(() => {
    const matches = Object.values(mediaQuery).map((query) =>
      window.matchMedia(query)
    );

    matches.forEach((match) => {
      if ("addEventListener" in match) {
        match.addEventListener("change", updateState);
      } else {
        // Deprecated method, but used by Edge 18
        match.addListener(updateState);
      }
    });

    return () =>
      matches.forEach((match) => {
        if ("removeEventListener" in match) {
          match.removeEventListener("change", updateState);
        } else {
          // Deprecated method, but used by Edge 18
          match.removeListener(updateState);
        }
      });
  }, []);

  return {
    isCompact: isMedia("compact"),
    isDesktop: isMedia("desktop"),
    isTouchDevice: isMedia("touch"),
    isNotTouchDevice: isMedia("noTouch"),
  };
};

/**
 * Adds margins to create a horizontal gap between the children.
 * @param gap - CSS measuring unit (eg. 1rem)
 */
export const hGap = (gap: string) => css`
  margin-left: calc(${gap} * -1);
  > * {
    margin-left: ${gap} !important;
  }
`;

/**
 * Adds margins to create a vertical gap between the children.
 * @param gap - CSS measuring unit (eg. 1rem)
 */
export const vGap = (gap: string) => css`
  margin-top: calc(${gap} * -1);
  > * {
    margin-top: ${gap} !important;
  }
`;
