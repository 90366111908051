import React, { CSSProperties, useEffect, useRef, useState } from "react";
import GatsbyImg from "gatsby-image";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styled from "styled-components";
import debounce from "lodash/debounce";
import screenfull, { Screenfull } from "screenfull";
import { defineMessages, useIntl } from "react-intl";
import { DocumentPreviewImage } from "@web/api/BFF/types";
import { vars } from "@web/styles";
import { IconButton } from "@web/elements/Button";
import {
  getFluidImageProps,
  getToFormatFromExt,
} from "@web/utils/gatsby-helpers";

interface IImagePreviewProps {
  electronicDocumentId: number;
  documentPreviewImage: DocumentPreviewImage;
  downloadUrl: string;
  fileExt: string;
  maxWidth: number;
  maxHeight: number;
  preload?: boolean;
}

// Bypassing typing issue: https://github.com/sindresorhus/screenfull.js/issues/173
const Fullscreen = screenfull as Screenfull;

export const ImagePreview: React.FC<IImagePreviewProps> = (p) => {
  const intl = useIntl();
  const wrapRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setFullscreen] = useState(false);
  const [zoomLoadState, setZoomLoadState] = useState<
    "initial" | "loading" | "loaded"
  >("initial");

  const { width: originalWidth, height: originalHeight } =
    p.documentPreviewImage;

  useEffect(() => {
    if (Fullscreen.isEnabled) {
      const handler = () => {
        setFullscreen(Fullscreen.element === wrapRef.current);
      };
      window.addEventListener("fullscreenchange", handler);
      return () => window.removeEventListener("fullscreenchange", handler);
    }
  }, []);

  useEffect(() => {
    setZoomLoadState("initial");
  }, [p.electronicDocumentId, p.preload]);

  const handleZoomChange = debounce(() => {
    const shouldLoadFullResImage =
      zoomLoadState === "initial" &&
      p.maxWidth + p.maxHeight < originalWidth + originalHeight;

    if (shouldLoadFullResImage) {
      setZoomLoadState("loading");
    }
  }, 100);

  const handleFullscreen = () => {
    if (Fullscreen.element) {
      Fullscreen.exit();
    } else if (wrapRef.current) {
      Fullscreen.request(wrapRef.current);
    }
  };

  /**
   * Prevent rendering until we have maxWidth/maxHeight in order
   * to reduce the number of requests to the BFF resize endpoint.
   */
  if (!p.maxWidth || !p.maxHeight) {
    return null;
  }

  const fluidProps = getFluidImageProps(
    {
      id: p.electronicDocumentId,
      extension: p.fileExt,
      args: {
        maxWidth: roundUpClosest100(p.maxWidth / window.devicePixelRatio),
        toFormat: getToFormatFromExt(p.fileExt),
        sizes: "",
      },
    },
    p.documentPreviewImage
  );

  const imgStyle: CSSProperties = {
    width: originalWidth,
    height: originalHeight,
    maxWidth: p.maxWidth,
    maxHeight: p.maxHeight,
  };

  const gatsbyImage = (
    <GatsbyImg
      key={p.electronicDocumentId}
      fluid={fluidProps}
      style={imgStyle}
    />
  );

  const zoomedImage = (
    <img
      src={p.downloadUrl}
      style={imgStyle}
      onLoad={() => setZoomLoadState("loaded")}
    />
  );

  if (p.preload) {
    return <_preloadWrap>{gatsbyImage}</_preloadWrap>;
  }

  /**
   * Increase the scroll wheel speed when in fullscreen due to a bug in the library
   * causing zooming to slow down when changing parent size.
   * https://github.com/prc5/react-zoom-pan-pinch/issues/141
   */
  const wheelStep = isFullscreen ? 100 : 6.5;

  const fullscreenButtonTitle = isFullscreen
    ? texts.fullscreenExit
    : texts.fullscreenEnter;

  return (
    <_wrap ref={wrapRef}>
      <TransformWrapper
        key={p.electronicDocumentId}
        wheel={{ step: wheelStep }}
        onZoomChange={handleZoomChange}
        options={{
          centerContent: false,
          limitToWrapper: true,
        }}
      >
        {({ zoomIn, zoomOut }: { zoomIn: () => void; zoomOut: () => void }) => (
          <>
            <TransformComponent>
              <_imageWrap style={{ width: p.maxWidth, height: p.maxHeight }}>
                {zoomLoadState !== "loaded" && gatsbyImage}
                {zoomLoadState !== "initial" && zoomedImage}
              </_imageWrap>
            </TransformComponent>

            {zoomLoadState === "loading" && (
              <_fullresNote>{intl.formatMessage(texts.loadZoom)}</_fullresNote>
            )}

            <_toolbar>
              <IconButton
                icon="ZoomOutIcon"
                text={intl.formatMessage(texts.zoomOut)}
                onClick={zoomOut}
              />
              <IconButton
                icon="ZoomInIcon"
                onClick={zoomIn}
                text={intl.formatMessage(texts.zoomIn)}
              />
              {Fullscreen.isEnabled && (
                <IconButton
                  icon={
                    isFullscreen ? "FullscreenExitIcon" : "FullscreenEnterIcon"
                  }
                  text={intl.formatMessage(fullscreenButtonTitle)}
                  onClick={handleFullscreen}
                />
              )}
            </_toolbar>
          </>
        )}
      </TransformWrapper>
    </_wrap>
  );
};

const _wrap = styled.div``;

const _preloadWrap = styled.div`
  position: absolute;
  visibility: hidden;
`;

const _imageWrap = styled.div`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  img {
    object-fit: contain !important;
    object-position: center center !important;
    position: absolute;
  }

  > * {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${_wrap}:fullscreen & {
    width: 100vw !important;
    height: 100vh !important;
  }
`;

const _fullresNote = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  padding: 0.25rem;
  pointer-events: none;
  text-align: center;
`;

const _toolbar = styled.div`
  background: ${vars.primaryDark20};
  border-radius: 4px;
  color: ${vars.primaryFg};
  display: flex;
  padding: 0 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  --svg-fill: ${vars.primaryFg};
  --svg-stroke: ${vars.primaryFg};
`;

function roundUpClosest100(num: number) {
  return Math.ceil(num / 100) * 100;
}

const texts = defineMessages({
  loadZoom: {
    id: "filepreview.zoomLoading",
    defaultMessage: "Fetching full resolution image…",
  },
  zoomIn: {
    id: "filepreview.tooltip.zoomIn",
    defaultMessage: "Zoom in",
  },
  zoomOut: {
    id: "filepreview.tooltip.zoomOut",
    defaultMessage: "Zoom out",
  },
  fullscreenEnter: {
    id: "filepreview.tooltip.fullscreenEnter",
    defaultMessage: "Enter fullscreen",
  },
  fullscreenExit: {
    id: "filepreview.tooltip.fullscreenExit",
    defaultMessage: "Exit fullscreen",
  },
});
