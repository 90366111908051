import React, { useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled, { keyframes } from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { vars } from "@web/styles";

export interface IRadioProps {
  label: MessageDescriptor | string;
  name: string;
  value: string;
  disabled?: boolean;
  color?: string;
  checked?: boolean;
  style?: React.StyleHTMLAttributes<HTMLElement>;
  showDivider?: boolean;
  iconBefore?: JSX.Element;
  className?: string;
  onChange: (value: string) => void;
}

export const RadioButton = (props: IRadioProps) => {
  const {
    name,
    value,
    disabled,
    checked,
    style,
    showDivider,
    iconBefore,
    className,
  } = props;

  const intl = useIntl();
  const [changed, setChanged] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    setChanged(true);
    props.onChange(event.target.value);
  };

  const label =
    typeof props.label === "string"
      ? props.label
      : intl.formatMessage(props.label);

  return (
    <Wrap style={style} {...(className && { className })}>
      <LabelWrap>
        <RealRadio
          type="radio"
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
        />

        <_radioIcon
          changed={changed}
          disabled={disabled}
          checked={checked}
          color={disabled ? vars.grey : vars.secondary}
        />

        {iconBefore}
        <DisplayLabel
          style={{
            color: disabled ? vars.grey : vars.secondary,
          }}
        >
          {label}
        </DisplayLabel>
      </LabelWrap>
      {showDivider && <Divider />}
    </Wrap>
  );
};

interface RadioIconProps {
  checked: boolean | undefined;
  disabled: boolean | undefined;
  changed: boolean;
  color: string;
}

const _radioIcon = styled.div<RadioIconProps>`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex: 0 0 20px;

  ${(p) =>
    p.checked
      ? `box-shadow: inset 0 0 0 1px #ccc, inset 0 0 0 5px #fff, inset 0 0 0 15px ${
          p.color
        };

    ${
      p.changed &&
      `
    animation-name: select-radio;
    animation-duration: 0.2s;
    animation-iteration-count: 1;

    @keyframes select-radio {
      0% {
        box-shadow: 0 0 0 0 #ccc, inset 0 0 0 2px #fff, inset 0 0 0 3px  ${p.color}, inset 0 0 0 16px #fff, inset 0 0 0 15px ${p.color};
      }
      90% {
        box-shadow: 0 0 0 3px #ddd, inset 0 0 0 0 #fff, inset 0 0 0 1px  ${p.color}, inset 0 0 0 2px #fff, inset 0 0 0 15px ${p.color};
      }
      100% {
        box-shadow: 0 0 0 6px #ccc, inset 0 0 0 0 #fff, inset 0 0 0 1px  ${p.color}, inset 0 0 0 3px #fff, inset 0 0 0 15px ${p.color};
      }
    }
    `
    }
    `
      : `box-shadow: inset 0 0 0 1px #ccc, inset 0 0 0 15px #fff, inset 0 0 0 15px ${p.color};
  `}
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
`;

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${vars.dark05};
  height: 1px;
  width: 100%;
  padding: 2px 0px;
`;

const LabelWrap = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
`;

const DisplayLabel = styled.div`
  line-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
`;

const RealRadio = styled.input`
  display: none;
`;
