import styled from "styled-components";
import { vars } from "@web/styles";

export const SearchControlWrap = styled.div`
  --svg-fill: ${vars.dark55};

  background: ${vars.content};
  border: 1px solid ${vars.dark05};
  border-radius: 8px;
  color: ${vars.contentFg};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 1rem 0 0.5rem;

  :hover {
    border-color: ${vars.dark15};
  }

  svg {
    vertical-align: middle;
  }

  > * {
    flex-shrink: 0;
  }
`;
