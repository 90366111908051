import React from "react";
import { defineMessages } from "react-intl";
import { observer } from "mobx-react";
import { EntryModel } from "@web/models";
import { ClassEdit } from "@web/components/ClassEdit";
import { useStores } from "@web/stores/context";
import { useMedia } from "@web/styles/utils";
import { commonTexts } from "@web/translations";

export const EntryTagEdit = observer((p: { entry: EntryModel }) => {
  const { flowStore, tagStore } = useStores();
  const { isCompact } = useMedia();

  const allClassifications = p.entry.section?.classifications || [];

  const alteredClassifications = allClassifications.filter((classification) =>
    p.entry.tags.allClassificationIds.includes(classification.uuid)
  );

  const classes = p.entry.canUpdate
    ? allClassifications
    : alteredClassifications;

  const requiredClassifications = p.entry.requiredClassifications;

  return (
    <ClassEdit
      truncate={isCompact}
      editable={p.entry.canChangeTags}
      classifications={classes}
      tagSearch={tagStore.search}
      selectedTags={p.entry.tags}
      emptyMessage={texts.noTags}
      requiredClassifications={requiredClassifications}
      checklistTags={flowStore.allTagConditions}
      onApply={(classification) => p.entry.updateTags(classification)}
    />
  );
});

const texts = defineMessages({
  noTags: {
    id: "entry.tags.notags",
    defaultMessage: "No tags…",
  },
});
