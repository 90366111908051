import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import memoize from "lodash/memoize";

interface Options {
  spacing: number;
  initialHeight: number;
}

export const useRowHeights = ({ spacing, initialHeight }: Options) => {
  const rowHeightsTmp = useRef<number[]>([]);
  const [rowHeights, setRowHeights] = useState<number[]>([]);
  const [refreshRowSizes, setRefreshRowSizes] = useState<number[]>([0]);

  const setRowHeightsDebounced = useCallback(
    debounce((heights: number[]) => {
      setRowHeights(heights);
      setRefreshRowSizes([0]);
    }, 10),
    []
  );

  const setRowHeight = (index: number, height: number) => {
    if (rowHeightsTmp.current[index] === height) {
      return;
    }
    rowHeightsTmp.current[index] = height;
    setRowHeightsDebounced(rowHeightsTmp.current.slice());
  };

  const getRowHeightHandler = memoize((index: number) => {
    return (height: number) => {
      setRowHeight(index, height);
    };
  });

  const getRowHeight = (index: number) => {
    const height = rowHeights[index];
    if (!height) {
      return initialHeight;
    }
    return height + spacing;
  };

  useEffect(() => {
    return () => {
      setRowHeightsDebounced.cancel();
    };
  }, []);

  return {
    setRowHeight,
    getRowHeight,
    getRowHeightHandler,
    refreshRowSizes,
  };
};
