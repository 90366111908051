import React from "react";

export const PdfIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" fill="none" />
    <path
      d="M13 45H17.7777V39.9531H20.4243C24.4372 39.9531 27.1267 37.6704 27.1267 34.0098C27.1267 30.3906 24.5059 28 20.5961 28H13V45ZM17.7777 36.3672V31.6855H19.4619C21.1375 31.6855 22.1429 32.5488 22.1429 34.0098C22.1429 35.4624 21.1375 36.3672 19.4619 36.3672H17.7777Z"
      fill="currentColor"
    />
    <path
      d="M35.6509 45C41.0129 45 44.4157 41.8125 44.4157 36.5C44.4157 31.1875 41.0129 28 35.5822 28H28.8797V45H35.6509ZM33.6574 41.082V31.918H35.3416C38.0913 31.918 39.6037 32.9473 39.6037 36.5C39.6037 40.0527 38.0913 41.082 35.4791 41.082H33.6574Z"
      fill="currentColor"
    />
    <path
      d="M46.6262 45H51.4039V38.3594H58.2438V34.6406H51.4039V31.7188H59V28H46.6262V45Z"
      fill="currentColor"
    />
  </svg>
);
