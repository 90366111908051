import React, {
  createRef,
  CSSProperties,
  FC,
  useCallback,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import times from "lodash/times";
import throttle from "lodash/throttle";
import { FormattedMessage } from "react-intl";
import {
  DocumentBucket,
  DocumentModel,
  DocumentUploadRequest,
  EntryModel,
} from "@web/models";
import { isMedia } from "@web/styles/utils";
import { vars } from "@web/styles";
import { AddIcon } from "@web/elements/Icons";
import { Box, CheckMark } from "@web/elements";
import { useKeyboardEvent } from "@web/utils/hooks/useKeyboardEvent";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";
import { UploadResult } from "@web/components/Upload/types";
import { useConfig } from "@config/context";
import { DocumentUploadButton } from "..";
import {
  DocumentCard,
  EmptyDocumentCard,
  SkeletonDocumentCard,
} from "../DocumentCard";
import commonCardTexts from "./texts";
import { EmptyCardHelp } from "./EmptyCardHelp";

interface Props {
  entry: EntryModel;
  documentSelection: DocumentBucket;
  onDocumentClick: (document: DocumentModel) => void;
  onUploadRequest: (
    request: DocumentUploadRequest
  ) => Promise<UploadResult | undefined>;
}

const PREVIEW_SIZE = 160;

export const DocumentGrid: FC<Props> = observer((p) => {
  const config = useConfig();
  const scrollRef = createRef<HTMLDivElement>();
  const skeletonRef = createRef<HTMLDivElement>();

  const { entry, onUploadRequest } = p;
  const documentsLoaded = entry.documentCount === entry.documents.length;
  const getConfirmation = useDuplicateUploadConfirmDialog();

  const handleScroll = () => {
    const scrollEl = scrollRef.current;
    const skeletonEl = skeletonRef.current;
    if (!scrollEl || !skeletonEl || documentsLoaded) {
      return;
    }

    const { scrollTop, clientHeight } = scrollEl;
    const pixelsScrolled = clientHeight + scrollTop;
    const topOfSkeleton = skeletonEl.offsetTop;
    const distanceToSkeleton = topOfSkeleton - pixelsScrolled;

    if (distanceToSkeleton < 250) {
      entry.loadMoreDocuments();
    }
  };

  const throttledScroll = throttle(handleScroll, 300, {
    leading: false,
    trailing: true,
  });

  const { documents, documentCount, focusedDocumentId } = entry;
  const loadingCount = documentCount ? documentCount - documents.length : 0;
  const canUpload =
    config.canUploadDocuments && entry.canAddDocument && !loadingCount;

  if (documentCount === 0 && documentsLoaded) {
    return <EmptyCardHelp entry={entry} onUploadRequest={onUploadRequest} />;
  }

  return (
    <_wrap ref={scrollRef} onScroll={throttledScroll}>
      <_grid>
        {documents.map((doc) => (
          <DocumentBox
            key={doc.id}
            entry={entry}
            document={doc}
            documentSelection={p.documentSelection}
            onClick={p.onDocumentClick}
            isFocused={doc.id === focusedDocumentId}
          />
        ))}

        {times(loadingCount, (i) => (
          <_box key={i} ref={i === 0 ? skeletonRef : undefined}>
            <SkeletonDocumentCard previewSize={PREVIEW_SIZE} />
          </_box>
        ))}

        {canUpload && (
          <DocumentUploadButton
            entry={entry}
            onUpload={onUploadRequest}
            getConfirmation={getConfirmation}
          >
            <AddDocumentBox key="add-new-document" />
          </DocumentUploadButton>
        )}
      </_grid>
    </_wrap>
  );
});

const _wrap = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const _grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
`;

interface BoxProps {
  entry: EntryModel;
  document: DocumentModel;
  documentSelection: DocumentBucket;
  isFocused: boolean;
  onClick: (document: DocumentModel) => void;
}

const DocumentBox: FC<BoxProps> = observer((p) => {
  const keys = useKeyboardEvent();
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    /** On mount: scroll to focused document instantly */
    if (p.isFocused) {
      ref.current?.scrollIntoView({ block: "center", behavior: "auto" });
    }
  }, []);

  useEffect(() => {
    if (p.isFocused) {
      ref.current?.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, [p.isFocused]);

  const memoizedCallback = useCallback(
    () => p.onClick(p.document),
    [p.document]
  );

  const handleCheckMarkToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectDocument = e.target.checked;
    const lastSelected = p.documentSelection.lastSelected;
    const fromDocument =
      keys?.shiftKey && lastSelected ? lastSelected : p.document;

    if (selectDocument) {
      p.documentSelection.addRangeToSelection(
        fromDocument,
        p.document,
        p.entry.documents
      );
    } else {
      p.documentSelection.removeFromSelection(p.document);
    }
  };

  const isChecked = p.documentSelection.hasItem(p.document.uuid);
  const showCheckbox = p.documentSelection.canAddToSelection || isChecked;

  /**
   * Since we don't want the text to be selected when holding shift and
   * selecting multiple documents, we set the CSS property `user-select` to `none`
   * while the shift key is being pressed.
   */
  const userSelect = keys?.shiftKey ? "none" : "initial";
  const userSelectStyles = {
    userSelect,
    WebkitUserSelect: userSelect,
  } as CSSProperties;

  return (
    <_box
      ref={ref}
      highlight={p.isFocused}
      isChecked={isChecked}
      style={userSelectStyles}
    >
      <DocumentCard
        document={p.document}
        previewSize={PREVIEW_SIZE}
        onClick={memoizedCallback}
      />
      <_check>
        {showCheckbox && (
          <CheckMark isSelected={isChecked} onSelect={handleCheckMarkToggle} />
        )}
      </_check>
    </_box>
  );
});

const _box = styled.div<{ highlight?: boolean; isChecked?: boolean }>`
  border: 2px solid ${(p) => (p.highlight ? vars.primary : "transparent")};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  cursor: pointer;
`;

const _check = styled.div`
  position: relative;
  top: 0.15rem;
  right: 0.15rem;
  padding-left: 10px;
  min-width: 24px;
  min-height: 24px;

  /**
   * This funky piece dims the checkbox svg when its sibling input is not checked
   * and its parent box is not hovered.
   */
  ${_box}:not(:hover) & input:not(:checked) + svg {
    opacity: 0.2;
  }
`;

const AddDocumentBox = () => (
  <_box>
    <EmptyDocumentCard
      previewSize={PREVIEW_SIZE}
      alignContent="center"
      media={
        <Box width="25px" height="25px">
          <AddIcon />
        </Box>
      }
      title={
        <_addDocumentTitle>
          {isMedia("touch") ? (
            <FormattedMessage {...commonCardTexts.tapToAdd} />
          ) : (
            <FormattedMessage
              id="entry.grid.dragndroptoadd"
              defaultMessage="Drag-and drop or <em>browse</em> to add documents"
            />
          )}
        </_addDocumentTitle>
      }
    />
  </_box>
);

const _addDocumentTitle = styled.div`
  max-width: 10rem;
  color: ${vars.dark55};

  > em {
    color: ${vars.primary};
    font-style: inherit;
  }
`;
