import React, { FC, useState, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { PipelineCreateFields, PipelineModel } from "@web/models";
import { useStores } from "@web/stores/context";
import { EditPipeline } from "@web/components/Flow/Pipeline/EditPipeline";
import { PipelineList } from "@web/components/Flow/Pipeline/PipelineList";
import { CreateButton } from "@web/components/Flow/CreateButton";
import { ErrorBanner } from "@web/components/Error/ErrorBanner";
import { useConfig } from "@config/context";

export const ChecklistsContainer: FC = observer(() => {
  const intl = useIntl();

  const {
    flowStore,
    tagStore,
    sectionStore,
    classificationStore,
    recordStore,
  } = useStores();
  const { canManageFlows } = useConfig();
  const [addPipeline, setAddPipeline] = useState(false);
  const [selectedPipeline, selectPipeline] = useState<PipelineModel>();

  useEffect(() => {
    flowStore.loadChecklists();
    flowStore.loadEvents();
    recordStore.loadEntryStatuses();
  }, []);

  const handleCancel = () => {
    setAddPipeline(false);
    selectPipeline(undefined);
  };

  const handleAddPipeline = async (fields: PipelineCreateFields) => {
    const pipeline = await flowStore.addPipeline(fields);
    setAddPipeline(false);

    if (pipeline) {
      selectPipeline(pipeline);
    }
  };

  if (!canManageFlows) {
    return <ErrorBanner type="noAccess" />;
  }

  if (addPipeline || selectedPipeline) {
    return (
      <EditPipeline
        type="validation"
        pipeline={selectedPipeline}
        entryStatuses={recordStore.entryStatuses}
        isCreatingPipeline={flowStore.isCreatingPipeline}
        addPipeline={handleAddPipeline}
        onCancel={handleCancel}
        findTags={tagStore.findTags}
        findSections={sectionStore.findSections}
        findClassifications={classificationStore.findClassifications}
      />
    );
  }

  return (
    <_wrap>
      <_header>
        <h1>
          <FormattedMessage {...texts.title} />
        </h1>
        <CreateButton
          text={intl.formatMessage(texts.title)}
          onClick={() => setAddPipeline(true)}
        />
      </_header>

      <PipelineList
        pipelines={flowStore.pipelines.validation}
        onPipelineClick={selectPipeline}
      />
    </_wrap>
  );
});

const _wrap = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 0px 2rem;
  margin-top: 0px;
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    white-space: nowrap;
    margin-left: 25px;
  }
`;

const texts = defineMessages({
  title: {
    id: "flow.settings.checklist.title",
    defaultMessage: "Folder Checklists",
  },
  add: {
    id: "flow.settings.checklist.add",
    defaultMessage: "Add new checklist",
  },
});
