import styled from "styled-components";

interface IFileTypeProps {
  type: string;
}

export const FileType = styled.span.attrs<IFileTypeProps>((p) => ({
  children: p.type,
}))<IFileTypeProps>`
  color: ${(p) => COLORS[p.type] ?? "inherit"};
  font-size: 1em;
  font-weight: 600;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;

enum ColorType {
  image = "#FF8A29",
  document = "#3679C8",
  data = "#566B75",
  email = "#1BAF73",
  pdf = "#DD4624",
  default = "#344249",
}

const COLORS: Record<string, string> = {
  png: ColorType.image,
  jpg: ColorType.image,
  jpeg: ColorType.image,
  svg: ColorType.image,
  gif: ColorType.image,
  mp4: ColorType.image,
  heic: ColorType.image,
  webp: ColorType.image,
  avif: ColorType.image,
  doc: ColorType.document,
  docx: ColorType.document,
  pptx: ColorType.document,
  pdf: ColorType.pdf,
  eml: ColorType.email,
  txt: ColorType.data,
  json: ColorType.data,
  xml: ColorType.data,
  zip: ColorType.data,
  csv: ColorType.data,
  db: ColorType.data,
  dwg: ColorType.data,
  "???": ColorType.default,
};
