import { action, computed, observable } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import {
  IAccessGroupNode,
  IClassificationWithAccessControl,
  IFlowContainerWithAccessControl,
  IListContainerWithAccessControl,
  IObjectWithAccessControl,
  IPermissionAdd,
  IPermissionNode,
  ISectionWithAccessControl,
  ITagWithAccessControl,
} from "@web/api/Integration/types";
import {
  AccessGroupListModel,
  AccessGroupMatrixModel,
  AccessGroupModel,
  ALL_FLOW_PERMISSIONS,
  ALL_CLASSIFICATION_PERMISSIONS,
  ALL_SECTION_PERMISSIONS,
  ALL_TAG_PERMISSIONS,
  ALL_LIST_PERMISSIONS,
  NAMED_FLOW_PERMISSION_SETS,
  NAMED_OBJECT_PERMISSION_SETS,
  NAMED_TAG_PERMISSION_SETS,
  NAMED_LIST_PERMISSION_SETS,
  ObjectPermissionsMatrixModel,
  ObjectPermissionsModel,
  ObjectWithAccessControlModel,
  ObjectTypeString,
} from "@web/models/AccessControlModel";
import { ObjectPermissionListModel } from "@web/models/AccessControlModel/ObjectPermissionListModel";
import { commonTexts } from "@web/translations";
import commonStoreTexts from "./texts";
import { RootStore } from ".";

const MESSAGE_DELAY = 1000;

export class AccessControlStore {
  constructor(private api: Api, private rootStore: RootStore) {}

  @observable private objectPermissionCache = new Map<
    UUID, // object id
    ObjectPermissionsModel
  >();

  @observable private objectPermissionListCache = new Map<
    UUID, // object id
    ObjectPermissionListModel<IObjectWithAccessControl>
  >();

  @observable private objectWithAccessControlCache = new Map<
    UUID, // object id
    ObjectWithAccessControlModel<IObjectWithAccessControl>
  >();

  @observable private accessGroupCache = new Map<
    UUID, // access group id
    AccessGroupModel
  >();

  @observable private selectedClassificationId: UUID | undefined;

  @computed get selectedClassification() {
    if (!this.selectedClassificationId) {
      return undefined;
    }
    const classification = this.objectWithAccessControlCache.get(
      this.selectedClassificationId
    );
    return classification as ObjectWithAccessControlModel<IClassificationWithAccessControl>;
  }

  sectionsMatrix = new ObjectPermissionsMatrixModel<ISectionWithAccessControl>(
    this,
    "section",
    ALL_SECTION_PERMISSIONS,
    NAMED_OBJECT_PERMISSION_SETS,
    this.loadSections,
    new AccessGroupListModel(this),
    { showSearch: true }
  );

  classificationsMatrix =
    new ObjectPermissionsMatrixModel<IClassificationWithAccessControl>(
      this,
      "classification",
      ALL_CLASSIFICATION_PERMISSIONS,
      NAMED_OBJECT_PERMISSION_SETS,
      this.loadClassifications,
      new AccessGroupListModel(this),
      { showSearch: true }
    );

  tagsMatrix = new ObjectPermissionsMatrixModel<ITagWithAccessControl>(
    this,
    "tag",
    ALL_TAG_PERMISSIONS,
    NAMED_TAG_PERMISSION_SETS,
    this.loadTags,
    new AccessGroupListModel(this),
    { showSearch: false }
  );

  flowContainersMatrix =
    new ObjectPermissionsMatrixModel<IFlowContainerWithAccessControl>(
      this,
      "flowContainer",
      ALL_FLOW_PERMISSIONS,
      NAMED_FLOW_PERMISSION_SETS,
      this.loadFlowContainers,
      new AccessGroupListModel(this),
      { showSearch: false }
    );

  listContainersMatrix =
    new ObjectPermissionsMatrixModel<IListContainerWithAccessControl>(
      this,
      "listContainer",
      ALL_LIST_PERMISSIONS,
      NAMED_LIST_PERMISSION_SETS,
      this.loadListContainers,
      new AccessGroupListModel(this),
      { showSearch: false }
    );

  accessGroupsMatrix = new AccessGroupMatrixModel(
    this,
    new AccessGroupListModel(this)
  );

  permissionListForObject(objectId: UUID): ObjectPermissionListModel<any> {
    let permissionList = this.objectPermissionListCache.get(objectId);

    if (!permissionList) {
      const object = this.objectWithAccessControlCache.get(objectId);
      if (!object) {
        throw new Error(
          `The requested object is not in cache. Permission lists can (currently) only be created from objects already loaded.`
        );
      }
      permissionList = new ObjectPermissionListModel(
        this,
        object,
        new AccessGroupListModel(this)
      );
      this.objectPermissionListCache.set(objectId, permissionList);
    }

    return permissionList;
  }

  @action.bound
  private async loadSections(page: number, pageSize: number, search: string) {
    try {
      const { data } = await this.api.getSectionsWithAccessControl(
        page,
        pageSize,
        search
      );
      this.sectionsMatrix.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  private async loadClassifications(
    page: number,
    pageSize: number,
    search: string
  ) {
    try {
      const { data } = await this.api.getClassificationsWithAccessControl(
        page,
        pageSize,
        search
      );
      this.classificationsMatrix.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  private async loadTags(page: number, pageSize: number, search: string) {
    try {
      const { data } = await this.api.getTagsWithAccessControl(
        this.selectedClassificationId,
        page,
        pageSize,
        search
      );
      this.tagsMatrix.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  private async loadFlowContainers(
    page: number,
    pageSize: number,
    search: string
  ) {
    try {
      const { data } = await this.api.getFlowContainersWithAccessControl(
        page,
        pageSize,
        search
      );
      this.flowContainersMatrix.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  private async loadListContainers(
    page: number,
    pageSize: number,
    search: string
  ) {
    try {
      const { data } = await this.api.getListContainersWithAccessControl(
        page,
        pageSize,
        search
      );
      this.listContainersMatrix.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  async loadAccessGroups(
    list: AccessGroupListModel,
    page: number,
    pageSize: number,
    search: string
  ) {
    try {
      const { data } = await this.api.getAccessGroups(page, pageSize, search);
      list.updateFromJson(data);
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  async loadAllObjectPermissionsForObject(
    object: ObjectWithAccessControlModel<any>
  ) {
    try {
      const loadAllPagesRecursively = async (page = 1) => {
        const pageSize = 1000;
        const {
          data: { data, hasMore },
        } = await this.api.getObjectPermissionsForObject(
          object.uuid,
          object.objectType,
          page,
          pageSize
        );

        for (const json of data) {
          object.updatePermissionFromJson(json);
        }

        if (hasMore) {
          const nextPage = page + 1;
          loadAllPagesRecursively(nextPage);
        }
      };

      loadAllPagesRecursively();
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  @action.bound
  selectClassification(classificationId: UUID | undefined) {
    this.selectedClassificationId = classificationId;
    this.tagsMatrix.reload();
  }

  objectPermissionsFromJson(json: IPermissionNode): ObjectPermissionsModel {
    let objectPermissions = this.objectPermissionCache.get(json.id);
    if (objectPermissions) {
      objectPermissions.updateFromJson(json);
    } else {
      objectPermissions = new ObjectPermissionsModel(this, json);
      this.objectPermissionCache.set(json.id, objectPermissions);
    }
    return objectPermissions;
  }

  objectWithAccessControlFromJson(
    json: IObjectWithAccessControl,
    objectType: ObjectTypeString
  ): ObjectWithAccessControlModel<any> {
    let object = this.objectWithAccessControlCache.get(json.id);
    if (object) {
      object.updateFromJson(json);
    } else {
      object = new ObjectWithAccessControlModel<any>(this, objectType, json);
      this.objectWithAccessControlCache.set(json.id, object);
    }
    return object;
  }

  accessGroupFromJson(json: IAccessGroupNode): AccessGroupModel {
    let accessGroup = this.accessGroupCache.get(json.id);
    if (accessGroup) {
      accessGroup.updateFromJson(json);
    } else {
      accessGroup = new AccessGroupModel(this, json);
      this.accessGroupCache.set(json.id, accessGroup);
    }
    return accessGroup;
  }

  async deleteAccessGroup(accessGroup: AccessGroupModel) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: texts.deletingGroup,
        },
        MESSAGE_DELAY
      );

      await this.api.deleteAccessGroup(accessGroup.uuid);

      this.accessGroupCache.delete(accessGroup.uuid);
      this.resetAccessGroups();

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.deletedGroup,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async saveAccessGroup(
    accessGroup: AccessGroupModel,
    fields: Partial<IAccessGroupNode>
  ) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      const json = {
        ...accessGroup.toJson(),
        ...fields,
      };

      const { claims, ...otherFields } = json;

      const notEmptyClaims = claims.filter((c) => c.trim() !== "");

      const {
        data: { data: responseJson },
      } = await this.api.updateAccessGroup({
        ...otherFields,
        claims: notEmptyClaims,
      });

      this.accessGroupCache.get(responseJson.id)?.updateFromJson(responseJson, {
        markGlobalPermissionsRecentlyChanged:
          fields.globalPermissions !== undefined,
        markServicePermissionsRecentlyChanged:
          fields.servicePermissions !== undefined,
      });

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async saveObjectPermissions(
    objectPermissions: ObjectPermissionsModel,
    fields: Partial<IPermissionNode>
  ) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      const json = {
        ...objectPermissions.toJson(),
        ...fields,
      };

      const {
        data: { data: responseJson },
      } = await this.api.updateObjectPermission(json);
      this.objectPermissionCache
        .get(responseJson.id)
        ?.updateFromJson(responseJson, { markRecentlyChanged: true });

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async addObjectPermission(
    objectId: UUID,
    objectType: string,
    fields: IPermissionAdd
  ) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      const {
        data: { data: responseJson },
      } = await this.api.createObjectPermission(fields);
      const object = this.objectWithAccessControlCache.get(objectId);
      object?.updatePermissionFromJson(responseJson, {
        markRecentlyChanged: true,
      });

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async deletePermissions(permissionsId: UUID) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      await this.api.deletePermissions(permissionsId);
      this.objectPermissionCache.delete(permissionsId);
      for (const object of this.objectWithAccessControlCache.values()) {
        object.purgePermissionsById(permissionsId);
      }

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async setClassificationDeterminesAccessControl(
    classificationId: UUID,
    determinesAccessControl: boolean
  ) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      const {
        data: { data: responseJson },
      } = await this.api.updateClassificationWithAccessControl(
        classificationId,
        { determinesAccessControl }
      );

      const object = this.objectWithAccessControlCache.get(classificationId);
      object?.updateFromJson(responseJson);

      this.tagsMatrix.reload();

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  async createAccessGroup(
    fields: Pick<IAccessGroupNode, "name" | "claims" | "description">
  ) {
    try {
      const messageId = this.rootStore.messageStore.createMessage(
        {
          type: "updating",
          title: commonTexts.saving,
        },
        MESSAGE_DELAY
      );

      await this.api.createAccessGroup(fields);

      this.resetAccessGroups();

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.savedChanges,
      });
    } catch (err) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.somethingWentWrong,
      });
    }
  }

  private resetAccessGroups() {
    this.accessGroupsMatrix.reloadAccessGroups();
    this.sectionsMatrix.reloadAccessGroups();
    this.classificationsMatrix.reloadAccessGroups();
    this.tagsMatrix.reloadAccessGroups();
    this.listContainersMatrix.reloadAccessGroups();
    this.flowContainersMatrix.reloadAccessGroups();
  }
}

const texts = defineMessages({
  savedChanges: {
    id: "accessControl.message.savedChanges",
    defaultMessage: "Changes saved!",
  },
  deletingGroup: {
    id: "accessControl.message.deletingGroup",
    defaultMessage: "Deleting access group…",
  },
  deletedGroup: {
    id: "accessControl.message.deletedGroup",
    defaultMessage: "Access group deleted!",
  },
});
