import React from "react";
import styled from "styled-components";
import { Label } from "@web/elements";
import { StatusColor } from "@web/elements/StatusColor";

// This is _entries_ format which can be obtained from objects, enums or maps
type LabelAndColorCode = [string, string];

export const ColorSelect = (p: {
  options: LabelAndColorCode[];
  selectedColor: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <_wrap>
      {p.options.map(([label, color]) => (
        <_option onClick={(e) => e.stopPropagation()} key={color}>
          <input
            type="radio"
            name="color"
            value={label}
            checked={p.selectedColor === label}
            onChange={p.onSelect}
          />
          <Label toggled={p.selectedColor === label}>
            <StatusColor color={color} />
          </Label>
        </_option>
      ))}
    </_wrap>
  );
};

const _wrap = styled.div`
  --svg-fill: transparent;
  --svg-stroke: transparent;
  --svg-check: white;

  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
`;

const _option = styled.label`
  position: relative;
  cursor: pointer;

  input {
    appearance: none;
    position: absolute;
  }
`;

ColorSelect.displayName = "ColorSelect";
