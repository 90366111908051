import React, { useState } from "react";
import styled from "styled-components";
import { vars } from "@web/styles";

import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  ChangelogEventModel,
  isAddOrDelEvent,
  SingleEventModel,
} from "@web/models";

import { commonTexts } from "@web/translations";
import { Button } from "@web/elements/Button";
import { ConfirmDialog } from "@web/components/ConfirmDialog";

import {
  ChangeLogEvent,
  textForEvent,
} from "@web/components/Entry/HistoryPane";

export const CommentEvent: React.FC<{
  event: SingleEventModel;
  allEvents: ChangelogEventModel[];
  canUpdateEntry: boolean;
}> = ({ event, allEvents, canUpdateEntry }) => {
  useIntl();
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const text = textForEvent(event);
  const commentBody = event.fields?.text;

  if (
    event.eventType === "DEL" ||
    event.eventType === "MOD" ||
    hasRelatedDeleteEvent(event, allEvents)
  ) {
    return null;
  }

  const buttons =
    event.comment !== undefined && event.eventType === "ADD"
      ? event.comment.isCreatedByCurrentUser &&
        canUpdateEntry && (
          <_commentDelete>
            <Button
              text={commonTexts.delete}
              onClick={() => setDisplayDeleteDialog(true)}
            />
          </_commentDelete>
        )
      : [];

  return (
    <ChangeLogEvent
      authorId={event.userId}
      authorName={event.userName}
      date={event.eventTime}
      text={text}
    >
      {commentBody && (
        <>
          <_eventComment>{commentBody}</_eventComment>
          {buttons}
        </>
      )}

      {displayDeleteDialog && (
        <ConfirmDialog
          message={texts.deleteComment}
          confirmText={commonTexts.delete}
          onClose={() => setDisplayDeleteDialog(false)}
          onConfirm={() => event.comment?.delete()}
        />
      )}
    </ChangeLogEvent>
  );
};

function hasRelatedDeleteEvent(
  eventToMatch: SingleEventModel,
  events: ChangelogEventModel[]
): boolean {
  return events
    .filter(isAddOrDelEvent)
    .some(
      (event) =>
        event.type === eventToMatch.type &&
        event.systemId === eventToMatch.systemId &&
        event.eventType === "DEL"
    );
}

export const texts = defineMessages({
  changeLogCommentHeader: {
    id: "entry.historypane.event.comment.header",
    defaultMessage: "with comment:",
  },
  deleteCommentButton: {
    id: "entry.historypane.event.comment.delete.button",
    defaultMessage: "Delete comment",
  },
  deleteComment: {
    id: "entry.historypane.event.comment.delete.confirmation",
    defaultMessage: "Are you sure you want to delete the comment?",
  },
});

const _commentDelete = styled.div`
  button {
    color: ${vars.secondary};
  }
  button:hover {
    color: ${vars.primary};
  }
`;

const _eventComment = styled.blockquote`
  color: ${vars.greyLight10};
  word-break: break-word;
  margin: 0;
  padding-left: 0.5rem;
`;
