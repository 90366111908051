import { action, computed, observable, when } from "mobx";
import sortedIndexBy from "lodash/sortedIndexBy";
import pullAllBy from "lodash/pullAllBy";
import orderBy from "lodash/orderBy";
import unionBy from "lodash/unionBy";
import {
  AttributeValuesMapModel,
  ChangelogEventModel,
  DocumentModel,
  EMPTY_LOADING_STATUS,
  EntryStatusModel,
  EntryStatusName,
  INITIAL_LOADING_STATUS,
  PageLoadingModel,
  PermissionModel,
  ReminderModel,
  RemoteData,
  ResultHighlightPart,
  SectionModel,
  SelectedTagsMap,
  WritableFields,
} from "@web/models";
import { RecordStore } from "@web/stores";
import {
  IDocumentNode,
  IEntryNode,
  ILoadData,
  IPollableEntryNode,
  Permission,
} from "@web/api/Integration/types";
import {
  generateDownloadEntryDocumentsUrl,
  parseAndGroupTagResponse,
  parseEntrySearchHighlights,
} from "@web/stores/ResponseParser";

const SECOND = 1000;
const MAX_POLLER_DELAY = 30 * SECOND;

export const INITIAL_POLLER_DELAY = 2 * SECOND;
export const DRAFT_ENTRY_ID = -1337;

type EntryProcessingStatus = "processing" | "processed" | "restart";

export class EntryModel implements PermissionModel {
  @observable readonly id: number;
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable readonly createdBy: string;
  @observable readonly createdDate: string;
  @observable lastUpdated: string;
  @observable title?: string;
  @observable sectionId: number;
  @observable sectionUuid: UUID;
  @observable sectionTitle: string;
  @observable downloadDocumentsUrl?: string;
  @observable isSingleDocumentEntry?: boolean;
  @observable processingStatus?: EntryProcessingStatus;

  @observable tags: SelectedTagsMap;
  @observable attributeValues: AttributeValuesMapModel;
  @observable entryStatus: EntryStatusName = "NO-STATUS";
  @observable documents: DocumentModel[] = [];
  @observable documentsLoadingInfo: PageLoadingModel = EMPTY_LOADING_STATUS;
  @observable reminders: RemoteData<ReminderModel[]> = {
    status: "NOT_REQUESTED",
  };

  @observable changelog: RemoteData<ChangelogEventModel[]> = {
    status: "NOT_REQUESTED",
  };

  @observable fromLinks: RemoteData<IEntryNode[]> = {
    status: "NOT_REQUESTED",
  };

  @observable toLinks: RemoteData<IEntryNode[]> = {
    status: "NOT_REQUESTED",
  };

  // Fields only set when doing search
  @observable totalMatchingDocuments?: number; // Added by BFF
  @observable searchHighlights: {
    title: ResultHighlightPart[];
  };

  // Fields added for UI
  @observable hasUpdate: undefined | "documentCount";
  @observable focusedDocumentId?: number;

  private currentPollingSettings:
    | {
        lastDelayUsed: number;
        setTimeoutReference: number;
      }
    | undefined;

  constructor(private store: RecordStore, node: IEntryNode) {
    this.store = store; // Needs to be set for mocking to work
    const { attributeStore } = store.rootStore;

    this.id = node.internalIdentifier;
    this.uuid = node.id;
    this.permissions = new Set(node.effectivePermissions);
    this.createdBy = node.createdBy;
    this.createdDate = node.createdDate;
    this.lastUpdated = node.updatedDate;
    this.title = node.title;
    this.sectionId = node.section.internalIdentifier;
    this.sectionUuid = node.section.id;
    this.sectionTitle = node.section.title;
    this.entryStatus =
      (node.entryStatus?.name as EntryStatusName) || "NO-STATUS";
    this.attributeValues = attributeStore.valuesForEntry(this.uuid, [
      ...(node.attributeValues ?? []),
      ...(node.attributeListValues ?? []),
    ]);
    this.tags = new SelectedTagsMap(
      parseAndGroupTagResponse(node.tags, attributeStore)
    );
    this.searchHighlights = parseEntrySearchHighlights(node.highlights);
    this.downloadDocumentsUrl = generateDownloadEntryDocumentsUrl(node.id);
    this.isSingleDocumentEntry = node.isSingleDocumentEntry;
    this.updateFromJson(node);
    this.requiredTagsLoaded();
  }

  private async requiredTagsLoaded() {
    const { requiredTags } = this.store.rootStore.sectionStore;
    await when(() => requiredTags.get(this.sectionId) !== undefined);
    this.tags.requiredTags = requiredTags.get(this.sectionId) || [];
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get canAddDocument() {
    return this.permissions.has("CreateChildren");
  }

  @computed
  get canChangeTags() {
    return this.canUpdate && !this.tags.loadingInfo.hasMore;
  }

  @computed
  get isReadOnly() {
    return (
      !this.permissions.has("CreateChildren") &&
      !this.permissions.has("Update") &&
      !this.permissions.has("Delete")
    );
  }

  @computed
  get isDraft() {
    return this.id === DRAFT_ENTRY_ID;
  }

  @computed
  get isFromSearch() {
    return !!this.searchHighlights;
  }

  @computed
  get firstDocument() {
    return this.documents[0];
  }

  @computed
  get isProcessingDocuments() {
    if (!this.processingStatus) {
      return false;
    }
    return ["restart", "processing"].includes(this.processingStatus);
  }

  /** Flow checklist convenience accessors */
  @computed
  get checklists() {
    const { flowStore } = this.store.rootStore;
    return flowStore.getChecklistsForEntry(this);
  }

  @computed
  get checklistsAreValid() {
    return this.checklists
      ?.flatMap((ch) => ch.results.get(this.uuid) || [])
      ?.every((rule) => rule.isSuccessful);
  }

  @computed
  get requiredClassifications() {
    return this.checklists?.flatMap((pipeline) =>
      pipeline.requiredClassifications(this.uuid)
    );
  }

  @computed
  get requiredAttributes() {
    return this.checklists?.flatMap((pipeline) =>
      pipeline.requiredAttributes(this.uuid)
    );
  }

  /** --------------------------------------- */

  @computed
  get remindersCount() {
    if (this.reminders.status === "SUCCESS") {
      return this.reminders.result.length;
    }
    return 0;
  }

  @computed
  get currentChangelogEvents(): ChangelogEventModel[] {
    if (this.changelog.status === "SUCCESS") {
      return this.changelog.result;
    }
    return [];
  }

  @computed
  get currentReminders(): ReminderModel[] {
    if (this.reminders.status === "SUCCESS") {
      return this.reminders.result;
    }
    return [];
  }

  @computed
  get currentFromLinks(): IEntryNode[] {
    if (this.fromLinks.status === "SUCCESS") {
      return this.fromLinks.result;
    }
    return [];
  }

  @computed
  get currentToLinks(): IEntryNode[] {
    if (this.toLinks.status === "SUCCESS") {
      return this.toLinks.result;
    }
    return [];
  }

  @computed
  get hasOverdueReminders() {
    if (this.reminders.status !== "SUCCESS") {
      return false;
    }
    const now = new Date();
    return this.reminders.result.some((reminder) => now > reminder.date);
  }

  @computed
  get documentCount() {
    return this.documentsLoadingInfo.itemCount;
  }

  @computed
  get focusedDocument() {
    return this.documents.find((doc) => doc.id === this.focusedDocumentId);
  }

  @computed
  get nextDocument() {
    if (this.documents.length < 1) {
      return;
    }
    const docs = this.documents;
    const current = docs.findIndex((doc) => doc.id === this.focusedDocumentId);

    if (current === -1) {
      return docs[0];
    }

    if (current < docs.length - 1) {
      return docs[current + 1];
    }
  }

  @computed
  get prevDocument() {
    if (this.documents.length < 1) {
      return;
    }

    const docs = this.documents;
    const current = docs.findIndex((doc) => doc.id === this.focusedDocumentId);

    if (current === -1) {
      return docs[docs.length - 1];
    }

    if (current !== 0) {
      return docs[current - 1];
    }
  }

  @computed
  get showAsDocument() {
    return this.isSingleDocumentEntry === true;
  }

  @computed
  get section() {
    const { sectionStore } = this.store.rootStore;
    return sectionStore.sectionCache.get(this.sectionId);
  }

  @action.bound
  focusDocument(documentId: number) {
    this.focusedDocumentId = documentId;
  }

  @action.bound
  selectNextDocument() {
    if (this.nextDocument) {
      this.focusedDocumentId = this.nextDocument.id;
    }
  }

  @action.bound
  selectPreviousDocument() {
    if (this.prevDocument) {
      this.focusedDocumentId = this.prevDocument.id;
    }
  }

  /**
   * Update fields on this entry object.
   */
  @action.bound
  updateState(fields: Partial<EntryModel>) {
    Object.assign(this, fields);
  }

  /* -- Data polling/loading operations -- */

  /**
   * Reload this entry.
   */
  @action.bound
  reload() {
    this.hasUpdate = undefined;
    return this.store.reloadEntry(this);
  }

  /**
   * Lazy load more documents for this entry.
   */
  loadMoreDocuments = () => {
    if (
      !this.documentsLoadingInfo?.hasMore ||
      this.documentsLoadingInfo.pageLoading
    ) {
      return;
    }
    this.store.loadNextPageOfDocuments(this);
  };

  @action.bound
  reloadDocuments() {
    this.hasUpdate = undefined;
    this.documents = [];
    this.documentsLoadingInfo = INITIAL_LOADING_STATUS;

    return this.store.loadNextPageOfDocuments(this);
  }

  loadReminders = (force = false) => {
    if (this.reminders.status !== "NOT_REQUESTED" && !force) {
      return;
    }
    return this.store.loadReminders(this);
  };

  loadRemainingTags = () => {
    this.store.loadRemainingTags(this);
  };

  reloadChangelog = () => {
    return this.store.loadChangelogEvents(this);
  };

  reloadLinks = () => {
    return Promise.all([
      this.store.loadFromLinks(this),
      this.store.loadToLinks(this),
    ]);
  };

  /**
   * Start polling for updates for this entry.
   * Starts at a 2 second interval which increases to 10 seconds over time.
   */
  pollForUpdates = () => {
    const lastDelayUsed = this.currentPollingSettings?.lastDelayUsed ?? 0;
    const delay = Math.min(
      lastDelayUsed + INITIAL_POLLER_DELAY,
      MAX_POLLER_DELAY
    );

    // `setInterval()` is not used because we want the delay to increase over time
    const setTimeoutReference = window.setTimeout(async () => {
      await this.store.pollForEntryUpdates(this);
      if (this.currentPollingSettings) {
        this.pollForUpdates();
      }
    }, delay);

    this.currentPollingSettings = {
      lastDelayUsed: delay,
      setTimeoutReference,
    };
  };

  /**
   * Handy when wanting to make the currently active data poller request data more frequently
   * again, usually after the active poller has been running for so long that it rarely polls
   * for backend data.
   */
  resetDataPollerFrequency() {
    this.stopDataPoller();
    this.pollForUpdates();
  }

  stopDataPoller() {
    clearTimeout(this.currentPollingSettings?.setTimeoutReference);
    this.currentPollingSettings = undefined;
  }

  /**
   * Given incoming data from entry polling, check whether this entry
   * has changed on the server and make any necessary updates.
   */
  @action.bound
  handlePollingUpdate(json: IPollableEntryNode): Boolean {
    let entryHasChanged = false;

    if (this.processingStatus !== json.dataServicesStatus?.status) {
      if (json.dataServicesStatus?.status === "processed") {
        this.loadReminders(true);
      }
      this.processingStatus = json.dataServicesStatus?.status;
    }

    const updatedEntryStatus =
      (json.entryStatus?.name as EntryStatusName) || "NO-STATUS";
    if (updatedEntryStatus !== this.entryStatus) {
      this.entryStatus = updatedEntryStatus;
      entryHasChanged = true;
    }

    const loadingStatus = json.resources?.documents;
    const hasSomeoneElseUploadedOrDeletedDocuments =
      this.documentCount !== loadingStatus?.total;
    if (hasSomeoneElseUploadedOrDeletedDocuments) {
      this.hasUpdate = "documentCount";
      entryHasChanged = true;
    }

    if (json.updatedDate !== this.lastUpdated) {
      this.lastUpdated = json.updatedDate;
      entryHasChanged = true;
    }

    return entryHasChanged;
  }

  /**
   * Update fields with fresh data from the server.
   */
  @action.bound
  updateFromJson(json: IEntryNode) {
    this.lastUpdated = json.updatedDate;
    this.title = json.title;
    this.isSingleDocumentEntry = json.isSingleDocumentEntry;

    this.documents.forEach((doc) => {
      doc.entryTitle = json.title;
    });

    if (json.reminders) {
      const existing =
        this.reminders.status === "SUCCESS" ? this.reminders.result : [];

      const incoming = json.reminders.map((json) =>
        this.store.rootStore.reminderStore.reminderFromJson(json)
      );

      this.reminders = {
        status: "SUCCESS",
        result: orderBy(unionBy(existing, incoming, "uuid"), "dueDate", "asc"),
      };
    }

    const loadingStatus = json.resources?.documents;
    if (!loadingStatus) {
      return;
    }

    const documentData = {
      data: json.documents.map((doc) => ({
        ...doc,
        entry: {
          id: this.uuid,
          internalIdentifier: this.id,
          section: {
            internalIdentifier: this.sectionId,
          },
          title: this.title,
        },
      })),
      ...loadingStatus,
    };

    this.addDocumentsFromJson(documentData);
    if (loadingStatus.totalMatchingDocuments) {
      this.totalMatchingDocuments = loadingStatus.totalMatchingDocuments;
    }
  }

  /**
   * Update entry with lazy loaded documents from the server.
   */
  @action.bound
  addDocumentsFromJson(json: ILoadData<IDocumentNode>) {
    const { documentStore } = this.store.rootStore;

    this.documentsLoadingInfo.lastPageLoaded = json.page;
    this.documentsLoadingInfo.hasMore = json.hasMore;
    if (json.total) {
      this.documentsLoadingInfo.itemCount = json.total;
    }

    let nextDocuments = json.data.map((doc) =>
      documentStore.documentFromJson(doc)
    );

    /*
     * Documents might have be pre-populated into `entry.documents` already
     * when performing a search and/or opening an entry from the results.
     * Those documents will be sorted *first* in the list of documents,
     * ensure we filter out those documents before appending the newly fetched page of documents.
     *
     * `lodash.pullAllBy` helps us achieve this by removing any duplicate documents.
     */
    if (this.documents.length > 0 && (json.page === 1 || this.isFromSearch)) {
      pullAllBy(nextDocuments, this.documents, "id");
    }

    this.documents.push(...nextDocuments);
  }

  @action.bound
  addDocument(document: DocumentModel) {
    if (this.documents.some((doc) => doc.id === document.id)) {
      return;
    }

    if (this.isSingleDocumentEntry && this.documents.length === 1) {
      this.firstDocument.resetUiState();
      this.update({ isSingleDocumentEntry: false });
    }

    // The document might have been moved here
    document.entryUuid = this.uuid;
    document.entryId = this.id;
    document.entryTitle = this.title;

    // We keep documents sorted alphabetically
    const insertAt = sortedIndexBy(this.documents, document, (doc) =>
      doc.title.toLowerCase()
    );
    this.documents.splice(insertAt, 0, document);
    this.documentsLoadingInfo.itemCount++;
  }

  @action.bound
  removeDocument(documentUuid: UUID) {
    this.documents = this.documents.filter((d) => d.uuid !== documentUuid);
    this.documentsLoadingInfo.itemCount--;
  }

  @action.bound
  addReminder(reminder: ReminderModel) {
    if (this.reminders.status === "SUCCESS") {
      this.reminders.result.push(reminder);
    }
  }

  @action.bound
  removeReminder(reminderUuid: UUID) {
    if (this.reminders.status === "SUCCESS") {
      const allReminders = this.reminders.result;

      this.reminders.result = allReminders.filter(
        (reminder) => reminder.uuid !== reminderUuid
      );
    }
  }

  @action.bound
  createComment(comment: string) {
    this.store.rootStore.commentStore.create(comment, this.uuid);
  }

  /**
   * Clean up state before this object is deleted.
   */
  destroy() {
    this.stopDataPoller();
  }

  /**
   * Set up state when this entry is shown from the fullscreen UI.
   */
  prepareUiState() {
    if (this.isDraft) {
      return;
    }
    this.pollForUpdates();

    if (this.isSingleDocumentEntry) {
      this.firstDocument?.reload();
      this.firstDocument?.prepareUiState();
    }
  }

  /**
   * Clean up state when this entry is removed from the fullscreen UI.
   */
  resetUiState() {
    this.stopDataPoller();
    if (this.isSingleDocumentEntry) {
      this.firstDocument?.resetUiState();
    }
    this.focusedDocumentId = undefined;
  }

  /* -- CRUD operations -- */
  update = (fields: WritableFields<EntryModel>) => {
    return this.store.saveEntry(this, fields);
  };

  updateTags = (classificationId: UUID) => {
    return this.store.saveEntryTags(this, classificationId);
  };

  updateStatus = (status: EntryStatusModel | undefined, comment?: string) => {
    return this.store.saveEntryStatus(this, status, comment);
  };

  move = (newSection: SectionModel) => {
    return this.store.moveToSection(this, newSection);
  };

  delete = () => {
    return this.store.deleteEntry(this);
  };

  createReminder = (fields: Pick<ReminderModel, "date" | "title">) => {
    const { reminderStore } = this.store.rootStore;
    return reminderStore.createReminder(fields, this);
  };

  deleteReminder = (reminder: ReminderModel) => {
    return reminder.delete(this);
  };
}

export const createDraftEntry = (
  store: RecordStore,
  requiredFields: Pick<EntryModel, "sectionUuid" | "sectionId" | "tags">
): EntryModel => {
  const fakeData: IEntryNode = {
    internalIdentifier: DRAFT_ENTRY_ID,
    id: String(DRAFT_ENTRY_ID),
    tags: [],
    documents: [],
    createdDate: new Date().toISOString(),
    updatedDate: new Date().toISOString(),
    effectivePermissions: ["Update", "CreateChildren"],
    title: undefined,
    createdBy: "",
    section: {
      id: requiredFields.sectionUuid,
      internalIdentifier: requiredFields.sectionId,
      title: "",
    },
  };
  const draft = new EntryModel(store, fakeData);
  draft.tags = requiredFields.tags;
  return draft;
};
