import { defineMessages, MessageDescriptor } from "react-intl";
import { Permission, ServicePermission } from "@web/api/Integration/types";

export const permissionSetNames = defineMessages({
  custom: {
    id: "accessControl.namedPermissionSets.custom",
    defaultMessage: "Custom",
  },
  noAccess: {
    id: "accessControl.namedPermissionSets.noAccess",
    defaultMessage: "No access",
  },
  fullAccess: {
    id: "accessControl.namedPermissionSets.fullAccess",
    defaultMessage: "Full Access",
  },
  externalUser: {
    id: "accessControl.namedPermissionSets.externalUser",
    defaultMessage: "External User",
  },
  user: {
    id: "accessControl.namedPermissionSets.user",
    defaultMessage: "User",
  },
  readEverything: {
    id: "accessControl.namedPermissionSets.readEverything",
    defaultMessage: "Read Everything",
  },
  readOnly: {
    id: "accessControl.namedPermissionSets.readOnly",
    defaultMessage: "Read Only",
  },
  canEdit: {
    id: "accessControl.namedPermissionSets.canEdit",
    defaultMessage: "Can Edit",
  },
  canCreate: {
    id: "accessControl.namedPermissionSets.canCreate",
    defaultMessage: "Can Create",
  },
  canReadEntries: {
    id: "accessControl.namedPermissionSets.canReadEntries",
    defaultMessage: "Can Read Entries",
  },
});

export const ALL_SECTION_PERMISSIONS: Permission[] = [
  "CreateChildren",
  "DeleteChildren",
  "ReadChildren",
  "ReadObject",
  "ModifyChildrenPermissions",
  "UpdateChildren",
  "UpdateChildrenSystemFields",
];

export const ALL_CLASSIFICATION_PERMISSIONS: Permission[] = [
  "CreateChildren",
  "DeleteChildren",
  "ReadChildren",
  "ReadEntries",
  "ReadObject",
  "ModifyChildrenPermissions",
  "UpdateChildren",
  "UpdateChildrenSystemFields",
];

export const ALL_TAG_PERMISSIONS: Permission[] = [
  "ReadEntries",
  "ReadChildren",
  "ReadObject",
];

export const ALL_FLOW_PERMISSIONS: Permission[] = [
  "CreateChildren",
  "DeleteChildren",
  "ReadChildren",
  "ReadObject",
  "ModifyChildrenPermissions",
  "UpdateChildren",
  "UpdateChildrenSystemFields",
];

export const ALL_LIST_PERMISSIONS: Permission[] = [
  "CreateChildren",
  "DeleteChildren",
  "ReadChildren",
  "ReadObject",
  "ModifyChildrenPermissions",
  "UpdateChildren",
  "UpdateChildrenSystemFields",
];

export const ALL_GLOBAL_PERMISSION: Permission[] = [
  "CreateChildren",
  "DeleteChildren",
  "ReadChildren",
  "ReadEntries",
  "ReadObject",
  "ModifyChildrenPermissions",
  "UpdateChildren",
  "UpdateChildrenSystemFields",
];

export const ALL_SERVICE_PERMISSIONS: ServicePermission[] = [
  "UploadDocuments",
  "ChangeEntryStatus",
  "ManageFlows",
  "ManageAccessGroups",
];

export type PermissionType = Permission | ServicePermission;

export interface NamedPermissionSet<T extends PermissionType> {
  name: MessageDescriptor;
  permissions: Set<T>;
}

export function findMatchingPermissionSet<T extends PermissionType>(
  permissions: Set<T>,
  namedPermissionSets: NamedPermissionSet<T>[]
): NamedPermissionSet<T> | undefined {
  return namedPermissionSets.find((set) => {
    if (permissions.size !== set.permissions.size) {
      return false;
    }
    for (const permission of permissions) {
      if (!set.permissions.has(permission)) {
        return false;
      }
    }
    return true;
  });
}

export const NAMED_SERVICE_PERMISSION_SETS: NamedPermissionSet<ServicePermission>[] =
  [
    {
      name: permissionSetNames.noAccess,
      permissions: new Set<ServicePermission>(),
    },
    {
      name: permissionSetNames.externalUser,
      permissions: new Set<ServicePermission>(["UploadDocuments"]),
    },
    {
      name: permissionSetNames.user,
      permissions: new Set<ServicePermission>([
        "UploadDocuments",
        "ChangeEntryStatus",
      ]),
    },
    {
      name: permissionSetNames.fullAccess,
      permissions: new Set<ServicePermission>([
        "UploadDocuments",
        "ChangeEntryStatus",
        "ManageAccessGroups",
        "ManageFlows",
      ]),
    },
  ];

export const NAMED_GLOBAL_PERMISSION_SETS: NamedPermissionSet<Permission>[] = [
  {
    name: permissionSetNames.noAccess,
    permissions: new Set<Permission>(),
  },
  {
    name: permissionSetNames.user,
    permissions: new Set<Permission>(["ReadObject"]),
  },
  {
    name: permissionSetNames.readEverything,
    permissions: new Set<Permission>(["ReadObject", "ReadChildren"]),
  },
  {
    name: permissionSetNames.fullAccess,
    permissions: new Set<Permission>(ALL_GLOBAL_PERMISSION),
  },
];

export const NAMED_OBJECT_PERMISSION_SETS: NamedPermissionSet<Permission>[] = [
  {
    name: permissionSetNames.noAccess,
    permissions: new Set<Permission>(),
  },
  {
    name: permissionSetNames.readOnly,
    permissions: new Set<Permission>(["ReadObject", "ReadChildren"]),
  },
  {
    name: permissionSetNames.canEdit,
    permissions: new Set<Permission>([
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
  {
    name: permissionSetNames.canCreate,
    permissions: new Set<Permission>([
      "CreateChildren",
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
];

export const NAMED_TAG_PERMISSION_SETS: NamedPermissionSet<Permission>[] = [
  {
    name: permissionSetNames.noAccess,
    permissions: new Set<Permission>(),
  },
  {
    name: permissionSetNames.canReadEntries,
    permissions: new Set<Permission>([
      "ReadEntries",
      "ReadChildren",
      "ReadObject",
    ]),
  },
];

export const NAMED_FLOW_PERMISSION_SETS: NamedPermissionSet<Permission>[] = [
  {
    name: permissionSetNames.noAccess,
    permissions: new Set<Permission>(),
  },
  {
    name: permissionSetNames.readOnly,
    permissions: new Set<Permission>(["ReadObject", "ReadChildren"]),
  },
  {
    name: permissionSetNames.canEdit,
    permissions: new Set<Permission>([
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
  {
    name: permissionSetNames.canCreate,
    permissions: new Set<Permission>([
      "CreateChildren",
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
  {
    name: permissionSetNames.fullAccess,
    permissions: new Set<Permission>(ALL_FLOW_PERMISSIONS),
  },
];

export const NAMED_LIST_PERMISSION_SETS: NamedPermissionSet<Permission>[] = [
  {
    name: permissionSetNames.noAccess,
    permissions: new Set<Permission>(),
  },
  {
    name: permissionSetNames.readOnly,
    permissions: new Set<Permission>(["ReadObject", "ReadChildren"]),
  },
  {
    name: permissionSetNames.canEdit,
    permissions: new Set<Permission>([
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
  {
    name: permissionSetNames.canCreate,
    permissions: new Set<Permission>([
      "CreateChildren",
      "ReadObject",
      "ReadChildren",
      "UpdateChildren",
    ]),
  },
  {
    name: permissionSetNames.fullAccess,
    permissions: new Set<Permission>(ALL_LIST_PERMISSIONS),
  },
];
