import compact from "lodash/compact";
import mime from "mime-types";
import { DocumentModel, TagModel } from ".";

export const FREE_TEXT_FILE_TYPE_SUGGESTIONS = [
  "pdf",
  "doc",
  "image",
  "video",
  "email",
  "xls",
  "ppt",
] as const;

export type FreeTextSuggestionType =
  typeof FREE_TEXT_FILE_TYPE_SUGGESTIONS[number];

export type SearchSuggestionType =
  | AutoCompleteSuggestionModel
  | DocumentSuggestionModel
  | FilterSuggestionModel
  | FreetextSuggestionModel
  | HistorySuggestionModel;

export interface SearchSuggestionModel {
  history: HistorySuggestionModel[];
  documents: DocumentSuggestionModel[];
  filters: FilterSuggestionModel[];
  users: AutoCompleteSuggestionModel[];
}

export interface HistorySuggestionModel {
  type: "history";
  value: string;
}

export interface FreetextSuggestionModel {
  type: "freetext";
  name: string;
  value: FreeTextSuggestionType;
}

export interface FilterSuggestionModel {
  type: "filter";
  tag: TagModel;
  classification: {
    id: UUID;
    title: string;
  };
}

export interface AutoCompleteSuggestionModel {
  type: "autocomplete";
  // TypeScript way creating union type from array
  name: typeof SOLR_AUTOCOMPLETE_FILTERS[number];
  value: string;
}

export interface DocumentSuggestionModel {
  type: "document";
  document: DocumentModel;
  isSingleDocumentEntry?: boolean;
}

export interface ParsedQuery {
  search: string;
  filters: ParsedQueryFilter[];
}

export interface ParsedQueryFilter {
  query: string;
  parameter: { [name: string]: string[] };
}

export interface SearchDescriptionModel {
  query: string;
  words: string[];
  freetextFilters: string[];
}

// TODO: Change code to support translations of "type" and "user" if they are needed in different language than English and Norwegian
export const parseSearchQueryString = (input: string): ParsedQuery => {
  const matches = input.match(FREETEXT_REGEX);

  if (matches === null) {
    return {
      search: input.trim(),
      filters: [],
    };
  }
  return {
    search: input.replace(FREETEXT_REGEX, "").trim(),
    filters: compact(matches.map(mapFreetextFilterToQuery)),
  };
};

const mapFreetextFilterToQuery = (input: string): ParsedQueryFilter => {
  const [name, value] = input.split(":");
  let parameter: { [name: string]: string[] } = {};
  let query = "";

  switch (name) {
    case "user":
      query = "createdBy=@createdBy";
      parameter = { "@createdBy": [value.replace(/['"]+/g, "").trim()] };
      break;
    case "type": {
      const lowercasedValue = value.toLowerCase();
      const predefined = CONTENT_TYPE_MAP[lowercasedValue];

      parameter = {
        "@docType":
          predefined ||
          (mime.types[lowercasedValue]
            ? [mime.types[lowercasedValue]]
            : [lowercasedValue.trim()]),
      };
      query = "documents.documentVersions.contentType=@docType";
      break;
    }
  }

  return { query, parameter };
};

const SOLR_FREETEXT_FILTERS = ["type", "user"];
export const SOLR_AUTOCOMPLETE_FILTERS = ["user"];

export const FREETEXT_REGEX = new RegExp(
  `\\b(${SOLR_FREETEXT_FILTERS.join("|")}):("(.+?)"|(\\w+\\b))`,
  "g"
);

const CONTENT_TYPE_MAP: Record<string, string[]> = {
  image: [
    "image/png",
    "image/jpeg",
    "image/gif",
    "image/heic",
    "image/webp",
    "image/avif",
  ],
  video: ["video/mp4", "video/quicktime", "video/x-ms-wmv", "video/x-msvideo"],
  email: [
    "message/rfc822",
    "message/rfc6532",
    "application/vnd.ms-outlook",
    "application/vnd.ms-office",
  ],
  doc: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ],
  ppt: [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint",
  ],
  xls: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ],
};
