import React from "react";

export const DeleteLinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12V6H5.5V12H4.5ZM6.5 12V6H7.5V12H6.5ZM8.5 6V12H9.5V6H8.5Z"
      fill="var(--svg-fill, currentColor)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C5.44772 0 5 0.447715 5 1V2H0.5V4H13.5V2H9V1C9 0.447715 8.55228 0 8 0H6ZM1.39659 13.1664L1.00783 6.00002H2.9922L3.39351 13.0555C3.42295 13.5854 3.86124 14 4.39197 14H9.60803C10.1388 14 10.577 13.5854 10.6065 13.0555L10.9961 5.99611H12.9922L12.6034 13.1664C12.5151 14.7562 11.2002 16 9.60803 16H4.39197C2.79977 16 1.48491 14.7562 1.39659 13.1664Z"
      width="14"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
