import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { ColorStatusModel, TagModel, ClassModel, canUpdate } from "@web/models";
import { SetTagStatus } from "@web/components/TagStatus/SetTagStatus";
import { vars } from "@web/styles";
import { TagAttributeList } from "@web/components/Tag/TagAttributeList";
import { CloseButton } from "@web/elements/Button";

interface Props {
  highlightedTag?: {
    tag: TagModel;
    classification?: ClassModel;
  };
  onSaveStatus: (tag: TagModel, status: ColorStatusModel | undefined) => void;
  onCloseClick: () => void;
}

export const TagHeader: React.FC<Props> = observer((p) => {
  if (!p.highlightedTag?.classification?.hasAttributesDefined) {
    return null;
  }

  const { tag, classification } = p.highlightedTag;

  const handleSave = (status: ColorStatusModel | undefined) => {
    p.onSaveStatus(tag, status);
  };

  const hasTagStatuses = classification.tagStatuses.length > 0;

  return (
    <_wrap>
      <_header>
        <h2>
          {classification.title}: {tag.title}
        </h2>
        <CloseButton onClick={p.onCloseClick} />
      </_header>
      <_content>
        {hasTagStatuses && (
          <SetTagStatus
            status={tag.status}
            options={classification.tagStatuses}
            disableSave={!canUpdate(tag)}
            onSave={handleSave}
          />
        )}
        <TagAttributeList classification={classification} tag={tag} />
      </_content>
    </_wrap>
  );
});

const _wrap = styled.div`
  border-radius: 8px;
  background-color: ${vars.secondaryAltLight10};
  color: ${vars.secondaryAltFg};
  padding: 10px 10px 5px 10px;
  margin-top: 10px;
  h2 {
    margin: 0;
    padding: 0 0 2px 0;
    font-weight: 400;
    font-size: 15px;
  }
`;

const _header = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const _content = styled.div`
  display: flex;
  align-items: center;
  > button:first-of-type {
    margin-right: 10px;
  }
`;
