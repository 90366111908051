import React from "react";
import { observer } from "mobx-react";
import { IFilePreview } from ".";
import GatsbyImage from "gatsby-image";
import {
  getFluidImageProps,
  getToFormatFromExt,
} from "@web/utils/gatsby-helpers";

export const AudioPreview = observer((p: IFilePreview) => {
  const {
    documentPreviewImage,
    electronicDocumentId,
    contentType,
    fileExt,
    id,
  } = p.version;
  if (!documentPreviewImage) {
    return null;
  }
  const { width, height } = documentPreviewImage;

  const fluidProps = getFluidImageProps(
    {
      id: electronicDocumentId,
      extension: fileExt,
      args: {
        maxWidth: 500,
        toFormat: getToFormatFromExt(fileExt),
        sizes: "",
      },
    },
    documentPreviewImage
  );

  return (
    <div
      style={{
        margin: "0 auto",
        width: "100%",
        height: "100%",
        maxWidth: width + "px",
        maxHeight: height + "px",
      }}
    >
      <GatsbyImage fluid={fluidProps} />
      <audio
        key={`audio-${id}`}
        controls
        preload="none"
        style={{
          margin: "5px auto",
          width: "100%",
        }}
      >
        <source
          src={`/bff/file-preview/${electronicDocumentId}`}
          type={contentType}
        />
      </audio>
    </div>
  );
});
