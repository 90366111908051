import React from "react";

export const Logo = (p: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={p.width || "35"}
    height={p.height || "35"}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={p.className}
    style={p.style}
    data-testid="svg_logo"
  >
    <path
      d="M15.6018 32H6.53314C5.13484 32 4 30.8946 4 29.5325V4.46751C4 3.10544 5.13484 2 6.53314 2H15.3662C23.8167 2 30.869 8.53644 30.9982 16.7656C31.1299 25.1428 24.1714 32 15.6018 32Z"
      fill="currentColor"
    />
  </svg>
);
