import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { DMFormattedDate, ProfileImage } from "@web/elements";
import { vars } from "@web/styles";
import { texts } from "./texts";
import { DocumentEditSession } from "@web/api/BFF/types";

export const EditSession: React.FC<{
  editSession: DocumentEditSession;
}> = (p) => (
  <div>
    <_wrap>
      <_icon>
        <ProfileImage name={p.editSession.createdBy} size="small" />
      </_icon>
      <_user>
        <FormattedMessage
          {...texts.whoIsEditing}
          values={{
            user: p.editSession.createdBy,
          }}
        />
      </_user>
      <_info>
        {!p.editSession.lastSavedAt && (
          <FormattedMessage
            {...texts.editingSince}
            values={{
              formattedDate: (
                <DMFormattedDate value={p.editSession.createdAt} />
              ),
            }}
          />
        )}
        {p.editSession.lastSavedAt && (
          <FormattedMessage
            {...texts.editingLastSaved}
            values={{
              formattedDate: (
                <DMFormattedDate value={p.editSession.lastSavedAt} />
              ),
            }}
          />
        )}
      </_info>
    </_wrap>
  </div>
);

const _wrap = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-areas:
    "icon user"
    "icon info";
  grid-gap: 0.2rem 0.5rem;
  align-items: center;
`;

const _icon = styled.div`
  grid-area: icon;
  justify-self: center;
  align-self: normal;
  color: ${vars.contentFg};
`;

const _user = styled.div`
  grid-area: user;
  color: ${vars.contentFg};
  margin-top: 0.3rem;
`;

const _info = styled.div`
  grid-area: info;
  color: ${vars.dark55};
`;
