import React, { useContext, useState } from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { CaretRightIcon, CaretDownIcon } from "@web/elements/Icons";
import { commonTexts } from "@web/translations";

interface IProps {
  children: React.ReactNodeArray;
  defaultOpen?: number;
}

const AccordionContext = React.createContext({
  onClick: () => {},
  selected: false,
});

export const Accordion: React.FC<IProps> = (p) => {
  const [openSection, setOpenSection] = useState(p.defaultOpen ?? -1);

  return (
    <_wrap>
      {p.children.map((section, i) => (
        <_section key={i}>
          <AccordionContext.Provider
            value={{
              onClick: () => setOpenSection(i),
              selected: i === openSection,
            }}
          >
            {section}
          </AccordionContext.Provider>
        </_section>
      ))}
    </_wrap>
  );
};

interface AccordianSectionProps {
  title: string | MessageDescriptor;
  disabled?: boolean;
  loading?: boolean;
}

export const AccordionSection: React.FC<AccordianSectionProps> = (p) => {
  const intl = useIntl();
  const { onClick, selected } = useContext(AccordionContext);

  const title =
    typeof p.title === "string" ? p.title : intl.formatMessage(p.title);

  return (
    <>
      <_title
        onClick={p.disabled ? undefined : onClick}
        disabled={p.disabled}
        selected={selected}
      >
        {selected ? <CaretDownIcon /> : <CaretRightIcon />}
        <h2>{title}</h2>
        {p.loading && <div>{intl.formatMessage(commonTexts.saving)}</div>}
      </_title>
      {selected && <_content>{p.children}</_content>}
    </>
  );
};

const _wrap = styled.div`
  flex-grow: 1;
`;

const _section = styled.div``;

const _title = styled.div<{ disabled?: boolean; selected?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #e1e1e1;
  svg {
    margin-right: 15px;
  }
  div {
    margin-left: 20px;
  }
  ${(p) =>
    p.disabled &&
    `
    cursor: default;
    & * { 
      opacity: 0.5;
    }`}
  ${(p) =>
    p.selected &&
    `
    svg {
      margin-left: 5px;
    }
  `}
`;

const _content = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid #e1e1e1;
`;
