import { Permission, ServicePermission } from "@web/api//Integration/types";
import { SupportedLocale } from "@web/translations";
import { SimpleTheme } from "@web/styles/themes";

export enum BffPaths {
  filePreviewMeta = "file-preview/meta",
  filePreviewOcrCheck = "file-preview/ocr-check",
  documentEditSession = "edit/session",
  config = "config",
  metricsGui = "metrics/gui",
  startScriveSignature = "scrive",
}

export interface IConfig {
  // Fields fetched by the BFF from 'api/v1/user/me/info'
  instance: string;
  userId: UUID;
  userName: string;
  userGroups: string[];
  servicePermissions: ServicePermission[];
  globalPermissions: Permission[];
  serviceVersionFileLocation?: string;
  allowDuplication: boolean;

  // Fields added by the BFF
  locale: SupportedLocale;
  theme?: SimpleTheme;
  features?: {
    esignIntegration?: boolean;
  };
}

export type DocumentPreviewType =
  | "image"
  | "video"
  | "audio"
  | "document"
  | "text"
  | "unknown";

export type DocumentPreviewState =
  | "in-progress"
  | "error"
  | "complete"
  | "not-supported"
  | "locked"
  | "timeout";

export type DocumentPreviewOcrStatus =
  | "queued"
  | "textAvailable"
  | "textAndPreviewAvailable";

export type LoResPreviewImage = {
  pageNumber: number;
  width: number;
  height: number;
};

export interface DocumentPreviewImage {
  state: DocumentPreviewState;
  previewType: DocumentPreviewType;
  contentType: string;
  base64: string;
  width: number;
  height: number;
  createdAt?: string;
  loResImages?: LoResPreviewImage[];
  ocrStatus?: DocumentPreviewOcrStatus;
}

export interface DocumentEditSession {
  id: UUID;
  documentId: UUID;
  lockId: UUID;
  createdBy: string;
  createdAt: Date;
  lastSavedAt: Date | undefined;
}

export interface DocumentEditSessionRaw {
  id: UUID;
  documentId: UUID;
  lockId: UUID;
  createdBy: string;
  createdAt: string;
  lastSavedAt: string | null;
}

export interface DocumentEditSessionAsCreated extends DocumentEditSession {
  editUri: string;
}

export interface DocumentEditSessionAsCreatedRaw
  extends DocumentEditSessionRaw {
  editUri: string;
}

export type EntryPollingFlags = "includeDataServicesStatus";
