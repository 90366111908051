import React, { forwardRef } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { Tooltip } from "@web/elements/Tooltip";
import { commonTexts } from "@web/translations";
import {
  StyledButton,
  StyledIconButton,
  ButtonVariantType,
} from "@web/elements/Button/styles";
import * as icons from "@web/elements/Icons";

export type ButtonIconType = keyof typeof icons;

interface BaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | MessageDescriptor;
  size?: number;
  variant?: ButtonVariantType;
  icon?: ButtonIconType;
  iconRight?: ButtonIconType;
  children?: React.ReactNode;
  className?: string;
}

interface BtnProps extends BaseProps {
  hideText?: boolean;
}

interface IcnBtnProps extends BtnProps {
  icon: ButtonIconType;
  disableTooltip?: boolean;
}

const NullComponent = () => null;

export const Button = forwardRef<HTMLButtonElement, BtnProps>((p, ref) => {
  const intl = useIntl();
  const { text, icon, iconRight, variant, ...buttonProps } = p;
  const LeftIconComponent = (icon && icons[icon]) ?? NullComponent;
  const RightIconComponent = (iconRight && icons[iconRight]) ?? NullComponent;
  const stringOrFormattedText = text
    ? typeof text === "string"
      ? text
      : intl.formatMessage(text)
    : undefined;

  return (
    <StyledButton
      ref={ref}
      type="button"
      aria-label={stringOrFormattedText}
      {...buttonProps}
      size={p.size ?? 1}
      variant={variant ?? "default"}
      className={p.className}
    >
      <LeftIconComponent role="presentation" />
      {!p.hideText && stringOrFormattedText}
      {p.children}
      <RightIconComponent role="presentation" />
    </StyledButton>
  );
});

Button.displayName = "Button";

export const IconButton = forwardRef<HTMLButtonElement, IcnBtnProps>(
  (p, ref) => {
    const intl = useIntl();
    const { text, icon, iconRight, variant, ...buttonProps } = p;
    const LeftIconComponent = (icon && icons[icon]) ?? NullComponent;
    const RightIconComponent = (iconRight && icons[iconRight]) ?? NullComponent;
    const stringOrFormattedText =
      typeof text === "string" ? text : intl.formatMessage(text);

    return (
      <Tooltip
        textNode={stringOrFormattedText}
        disabled={
          (p.disabled || p.disableTooltip) && p.disableTooltip !== false
        }
      >
        <StyledIconButton
          ref={ref}
          type="button"
          aria-label={p.title ?? stringOrFormattedText}
          {...buttonProps}
          size={p.size ?? 1}
          variant={variant ?? "primaryInverted"}
        >
          <LeftIconComponent role="presentation" />
          <RightIconComponent role="presentation" />
        </StyledIconButton>
      </Tooltip>
    );
  }
);

IconButton.displayName = "IconButton";

export const BackButton = (p: Partial<IcnBtnProps>) => (
  <IconButton
    icon="CaretLeftIcon"
    disableTooltip
    text={commonTexts.back}
    {...p}
  />
);

export const CloseButton = (p: Partial<IcnBtnProps>) => (
  <IconButton icon="CrossIcon" disableTooltip text={commonTexts.close} {...p} />
);

export const MoreButton = forwardRef<HTMLButtonElement, Partial<IcnBtnProps>>(
  (p, ref) => (
    <IconButton
      icon="MoreIcon"
      text={commonTexts.moreOptions}
      ref={ref}
      {...p}
    />
  )
);

MoreButton.displayName = "MoreButton";
