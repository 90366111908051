import React from "react";

export const LightBulbIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.85714 14.3C5.85714 14.685 6.17857 15 6.57143 15H9.42857C9.82143 15 10.1429 14.685 10.1429 14.3V13.6H5.85714V14.3ZM8 1C5.23929 1 3 3.1945 3 5.9C3 7.566 3.85 9.0325 5.14286 9.918V11.5C5.14286 11.885 5.46429 12.2 5.85714 12.2H10.1429C10.5357 12.2 10.8571 11.885 10.8571 11.5V9.918C12.15 9.0325 13 7.566 13 5.9C13 3.1945 10.7607 1 8 1ZM10.0393 8.77L9.42857 9.1865V10.8H6.57143V9.19L5.96071 8.7735C5 8.1155 4.42857 7.0445 4.42857 5.9035C4.42857 3.975 6.03214 2.4035 8 2.4035C9.96786 2.4035 11.5714 3.975 11.5714 5.9035C11.5714 7.041 11 8.112 10.0393 8.77V8.77Z"
      fill="currentColor"
    />
  </svg>
);
