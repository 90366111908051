import { EntryUploadRequest, UploadJob, UploadJobStatus } from "@web/models";
import { UUID } from "@web/api/Integration/types";
import PubSub from "@web/stores/PubSub";
import { RecordStore } from "@web/stores";
import { EntryCreator } from "@web/components/Upload/creators/types";

export const createEntries = async (
  requestJobsMap: Map<EntryUploadRequest, UploadJob[]>,
  allJobs: UploadJob[],
  recordStore: RecordStore
) => {
  const entries: EntryCreator[] = [];

  requestJobsMap.forEach((jobs, request) => {
    const { sectionId, tags: tagIds, title, isSingleDocumentEntry } = request;

    const entry: EntryCreator = {
      section: {
        id: sectionId,
      },
      tags: tagIds.map((id) => ({ id })),
      ...(title && { title }),
      isSingleDocumentEntry,
    };

    jobs
      .filter((job) => [UploadJobStatus.uploading].includes(job.status))
      .forEach((job) => {
        job.startProgressUpdate((time) => {
          const completedPercent = Math.min(100, 90 + 10 * (time / 20000));
          return { completedPercent };
        });

        if (!job.result.fileId) {
          return;
        }

        if (!entry.documents) {
          entry.documents = [];
        }

        entry.documents.push({
          title: job.data.title,
          documentVersions: [{ electronicDocument: { id: job.result.fileId } }],
        });
      });

    entries.push(entry);
  });

  const models = await recordStore.createEntries(entries);

  const fileIdDocumentMap: Map<
    UUID,
    { docId: number; docUUID: UUID; entryUUID: UUID; entryId: number }
  > = new Map();

  models.forEach((entry) => {
    entry.documents.forEach((doc) => {
      PubSub.getInstance().notifyAdd({
        type: "Document",
        data: doc,
      });

      doc.versions.forEach((version) => {
        fileIdDocumentMap.set(version.electronicDocumentUuid, {
          docId: doc.id,
          docUUID: doc.uuid,
          entryUUID: entry.uuid,
          entryId: entry.id,
        });
      });
    });
  });

  allJobs.forEach((job) => {
    if (job.status !== UploadJobStatus.uploading) {
      return;
    }

    if (!job.result.fileId) {
      job.setStatus(UploadJobStatus.failed);
      return;
    }

    const doc = fileIdDocumentMap.get(job.result.fileId);
    if (doc) {
      job.setStatus(UploadJobStatus.completed);
      job.setResult({
        documentId: doc.docId,
        documentUuid: doc.docUUID,
        entryUuid: doc.entryUUID,
        entryId: doc.entryId,
      });
    } else {
      job.setStatus(UploadJobStatus.failed);
    }
  });
};
