import React, {
  createRef,
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { vars } from "@web/styles";
import {
  AttributeModel,
  AttributeValueModel,
  AttributeValuesMapModel,
} from "@web/models";
import { ActionMenu, Checkbox } from "@web/elements";
import { LazyList } from "@web/components";
import { useRowHeights } from "@web/components/LazyList/useRowHeights";

export interface SelectListAttributeProps {
  attribute: AttributeModel;
  attributeValues: AttributeValuesMapModel;
  disableComment?: boolean;
  onDone: () => void;
}

export const SelectListAttribute: FC<SelectListAttributeProps> = observer(
  ({ attribute, attributeValues, disableComment, onDone }) => {
    const intl = useIntl();
    const [comment, setComment] = useState("");
    const { getRowHeight, getRowHeightHandler, refreshRowSizes } =
      useRowHeights({
        spacing: 14,
        initialHeight: 30,
      });

    const handleApplyClick = async () => {
      attributeValues.saveChanges(comment);
      onDone();
    };

    const handleCancelClick = () => {
      attributeValues.clearChanges();
      onDone();
    };

    const options = attribute.optionList;

    const renderItem = (index: number) => (
      <Row
        value={options[index]}
        attributeValues={attributeValues}
        onHeight={getRowHeightHandler(index)}
      />
    );

    const canSave = attributeValues.hasChanges(attribute.uuid);

    const commentField =
      canSave && !disableComment ? (
        <_commentField
          value={comment}
          placeholder={intl.formatMessage(texts.addCommentPlaceholder)}
          onChange={(event) => setComment(event.target.value)}
        />
      ) : undefined;

    return (
      <_wrap>
        <_header>
          <h3>{attribute.name}</h3>
        </_header>
        <_list>
          <LazyList
            loadNextPage={attribute.loadOptions}
            loadingStatus={attribute.optionsLoadingStatus}
            renderItem={renderItem}
            itemSize={getRowHeight}
            refreshRowSizes={refreshRowSizes}
          />
        </_list>
        <_footer>
          {commentField}
          <ActionMenu
            direction="vertical"
            applyIsDisabled={!canSave}
            onApply={handleApplyClick}
            onCancel={handleCancelClick}
          />
        </_footer>
      </_wrap>
    );
  }
);

SelectListAttribute.displayName = "SelectListAttribute";

interface RowProps {
  attributeValues: AttributeValuesMapModel;
  value: AttributeValueModel;
  onHeight: (height: number) => void;
}

const Row: FC<RowProps> = observer(({ value, attributeValues, onHeight }) => {
  const intl = useIntl();
  const rowRef = createRef<HTMLDivElement>();
  const isSelected = attributeValues.hasValue({
    valueId: value.uuid,
    definitionId: value.definitionId,
    includeChanges: true,
  });

  const handleChange = useCallback(() => {
    attributeValues.setChange(value, !isSelected);
  }, [value.uuid, isSelected]);

  useLayoutEffect(() => {
    if (rowRef.current) {
      const { height } = rowRef.current.getBoundingClientRect();
      onHeight(height);
    }
  }, []);

  return (
    <_row>
      <Checkbox
        label={value.formattedValue(intl)}
        labelRef={rowRef}
        labelMultiline={true}
        checked={isSelected}
        onChange={handleChange}
      />
    </_row>
  );
});

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 0px;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  border-radius: 3px;
  min-width: 260px;
`;

const _list = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 11px 5px 11px;
  min-height: 220px;
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px 15px 5px;
  border-bottom: 1px solid ${vars.dark05};
  h3 {
    padding: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    flex: 1;
  }
`;

const _footer = styled.div`
  padding: 0 10px;
`;

const _row = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const _commentField = styled.input`
  padding: 8px;
  margin: 10px 0 10px 0;
  width: 100%;
  border-radius: 3px;
  color: ${vars.secondaryAltFg};
  border-color: ${vars.transparent};
  background-color: ${vars.secondaryAltLight20};
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${vars.dark55};
  }
`;

const texts = defineMessages({
  addCommentPlaceholder: {
    id: "entry.listAttributes.comment.placeholder",
    defaultMessage: "Add comment (optional)",
  },
});
