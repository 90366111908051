import React from "react";
import styled from "styled-components";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { ApiError, ErrorType } from "@web/api/APIError";
import spider from "@web/assets/spider-and-web.png";
import { vars, ZIndex } from "@web/styles";

interface IProps {
  error: Error;
}

export const ErrorPage: React.FC<IProps> = ({ error }) => {
  const intl = useIntl();

  return (
    <_wrap>
      <_content>
        <img src={spider} width={250} height={250} />
        {error instanceof ApiError ? (
          <>
            <h1>{intl.formatMessage(typedErrorTexts[error.type].title)}</h1>
            <p>{intl.formatMessage(typedErrorTexts[error.type].message)}</p>
          </>
        ) : (
          <>
            <h1>{intl.formatMessage(genericErrorTexts.title)}</h1>
            <p>{intl.formatMessage(genericErrorTexts.message)}</p>
          </>
        )}
        <a href="/">{intl.formatMessage(genericErrorTexts.link)} »</a>
      </_content>
    </_wrap>
  );
};

const _wrap = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  font-size: 1rem;
  background: ${vars.content};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndex.overlay};
`;

const _content = styled.div`
  text-align: center;
  h1 {
    font-size: 1.5em;
  }

  img {
    border-radius: 30px;
  }

  > * {
    margin-bottom: 30px;
  }
`;

const genericErrorTexts = defineMessages({
  title: {
    id: "error.default.title",
    defaultMessage: "Ooops, an unexpected error seems to have occured",
  },
  message: {
    id: "error.default.message",
    defaultMessage:
      "Why not try to refresh the page? Or you can contact us if the problem persists.",
  },
  link: {
    id: "error.homelink",
    defaultMessage: "Take me to the homepage",
  },
});

const typedErrorTexts: Record<ErrorType, Record<string, MessageDescriptor>> = {
  not_found: defineMessages({
    title: {
      id: "error.notfound.title",
      defaultMessage: "Ooops, the content you requested could not be found",
    },
    message: {
      id: "error.notfound.message",
      defaultMessage:
        "This might be because it has been deleted, or because you do not have permission to access it.",
    },
  }),
};
