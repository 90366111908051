import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { MessageDescriptor, useIntl, FormattedMessage } from "react-intl";
import { Portal } from "@web/components/Portal";
import { containInViewport } from "@web/utils/helpers";
import { vars, ZIndex } from "@web/styles";

type TooltipProps = TextTooltipProps | TextNodeTooltipProps;

type TooltipPropsExceptText = {
  children: React.ReactElement<any>;
  isOpen?: boolean;
  disabled?: boolean;
};

type TextTooltipProps = TooltipPropsExceptText & {
  text: MessageDescriptor;
};

type TextNodeTooltipProps = TooltipPropsExceptText & {
  textNode: React.ReactNode;
};

interface IPosState {
  top: number;
  left: number;
  anchorX: number;
  anchorY: number;
  isClamped: boolean;
}

export const Tooltip: React.FC<TooltipProps> = (p) => {
  if (p.disabled) {
    return p.children;
  }

  const wrapRef = useRef<HTMLDivElement>(null);
  const tipRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const [isVisible, setVisible] = useState(p.isOpen || false);
  const [pos, setPos] = useState({
    tipTop: 0,
    tipLeft: 0,
    arrTop: 0,
    arrLeft: 0,
  });

  const updatePosition = throttle(() => {
    if (isVisible && wrapRef.current && tipRef.current) {
      const wrapRect = wrapRef.current.getBoundingClientRect();
      const tipRect = tipRef.current.getBoundingClientRect();

      const { top, left } = containInViewport(
        {
          top: wrapRect.top,
          left: wrapRect.left + wrapRect.width / 2 - tipRect.width / 2,
          width: tipRect.width,
          height: tipRect.height,
        },
        8
      );

      setPos({
        tipTop: top + wrapRect.height,
        tipLeft: left,
        arrTop: top + wrapRect.height,
        arrLeft: wrapRect.left + wrapRect.width / 2,
      });
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    return () => {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  useLayoutEffect(() => {
    updatePosition();
  }, [isVisible]);

  const text = "text" in p ? <FormattedMessage {...p.text} /> : p.textNode;

  return (
    <_wrap
      ref={wrapRef}
      onMouseDown={() => setVisible(false)}
      onMouseOver={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
      onFocus={() => setVisible(true)}
      onBlur={() => setVisible(false)}
    >
      {p.children}
      {isVisible && (
        <Portal>
          <_tip ref={tipRef} top={pos.tipTop} left={pos.tipLeft}>
            {text}
          </_tip>
          <_arrow top={pos.arrTop} left={pos.arrLeft} />
        </Portal>
      )}
    </_wrap>
  );
};

const _wrap = styled.div`
  display: inline-block;
  pointer-events: all;
  vertical-align: middle;

  *:disabled {
    pointer-events: none;
  }
`;

type ITipProps = Pick<IPosState, "top" | "left">;
const _tip = styled.div.attrs<ITipProps>((p) => ({
  style: {
    top: p.top,
    left: p.left,
  },
}))<ITipProps>`
  background: ${vars.primaryDark20};
  border-radius: 0.25rem;
  font-size: 0.75rem;
  color: ${vars.primaryFg};
  min-width: 3rem;
  padding: 0.25rem;
  position: fixed;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
  z-index: ${ZIndex.tooltip};
`;

type IArrowProps = Pick<IPosState, "top" | "left">;
const _arrow = styled.div.attrs<IArrowProps>((p) => ({
  style: { top: p.top, left: p.left },
}))<IArrowProps>`
  border-color: transparent transparent ${vars.primaryDark20};
  border-style: solid;
  border-width: 0 0.25rem 0.25rem 0.25rem;
  pointer-events: none;
  position: fixed;
  transform: translate(-50%, -100%);
  z-index: ${ZIndex.tooltip};
`;
