import { observable, action, when, reaction, comparer, computed } from "mobx";
import groupBy from "lodash/groupBy";
import isSameWeek from "date-fns/isSameWeek";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import {
  DocumentModel,
  orderWithOptions,
  parseResultOrderOrDefault,
  ResultModel,
  ResultOrder,
  ResultOrderKey,
  ResultParamModel,
  EntryModel,
  customFilterValuesFromString,
} from "@web/models";
import { commonTexts } from "@web/translations";
import { isMedia } from "@web/styles/utils";
import { SearchParams } from "@web/pages/SearchPage/handler";
import PubSub, {
  AddEvent,
  DeleteEvent,
  Subscriber,
  UpdateEvent,
} from "./PubSub";
import { ILocalStorage } from "./types";
import { RootStore } from ".";

const LAST_USED_ORDER_STORAGE_KEY = "lastUsedOrderKey";
const VIEW_TYPE_STORAGE_KEY = "resultViewType";
const INITIAL_STATE = {
  isLoading: false,
  loadingStatus: {
    hasMore: true,
    pageSize: 0,
    page: 0,
    total: 0,
  },
};

export type ViewType = "grid" | "list";

export class ResultStore implements Subscriber {
  @observable entries: EntryModel[] = [];
  @observable recentEntries: EntryModel[] = [];

  @observable focusedEntryId?: number;
  @observable lastSearchTime = 0;

  @observable.shallow params: ResultParamModel = {
    section: undefined,
    tags: undefined,
    tagStatus: undefined,
    customFilters: undefined,
    attributes: undefined,
    query: "",
    order: this.defaultResultOrder,
  };

  @observable private data = INITIAL_STATE;
  private cancelToken?: CancelTokenSource;

  @observable viewType: ViewType = this.defaultViewType;

  constructor(
    private api: Api,
    private rootStore: RootStore,
    private localStorage: ILocalStorage
  ) {
    PubSub.getInstance().subscribe(this);

    /**
     * Use MobX to make sure new search results are requested just once.
     */
    reaction(
      () => this.params,
      () => {
        // Whenever the parameters have changed we are starting a
        // fresh search, reset the state before proceeding.
        this.resetSearch();
        this.loadEntryResults();
      },
      { equals: comparer.structural }
    );

    // When view type changes
    reaction(
      () => this.viewType,
      (newType) => {
        // Put view type in localStorage
        try {
          this.localStorage.setItem(VIEW_TYPE_STORAGE_KEY, newType);
        } catch (err) {
          // ignore
        }
        // Reload results to avoid missing attribute data
        // when switching from grid to list
        this.resetSearch();
        this.loadEntryResults();
      }
    );
  }

  get defaultResultOrder(): ResultOrderKey {
    let lastUsedOrder;
    try {
      lastUsedOrder = this.localStorage.getItem(LAST_USED_ORDER_STORAGE_KEY);
    } catch {
      console.error(
        "failed to read last used results order from local storage"
      );
    }

    return parseResultOrderOrDefault(lastUsedOrder || null);
  }

  /** Get viewType from localStorage or fall back to default "grid" */
  get defaultViewType(): ViewType {
    if (isMedia("compact")) {
      return "grid";
    }
    let viewType: ViewType = "grid";
    try {
      const stored = localStorage.getItem(VIEW_TYPE_STORAGE_KEY);
      if (stored === "grid" || stored === "list") {
        viewType = stored;
      }
    } catch (err) {
      // ignore
    }
    return viewType;
  }

  async resultsLoaded() {
    await when(() => !this.data.isLoading);
  }

  @computed
  get sortOptions(): ResultOrder {
    return orderWithOptions(this.params.order, this.params.query !== "");
  }

  @computed
  get resultState(): ResultModel {
    return {
      hasMore: this.data.loadingStatus.hasMore,
      isLoading: this.data.isLoading,
      count: this.entries.length,
      totalCount: this.data.loadingStatus.total,
      totalCountType: "entries", // Only entry search supported for now
      order: this.sortOptions,
    };
  }

  @computed
  get groupedEntries(): Record<string, EntryModel[]> {
    if (this.params.order.includes("relevance") || this.viewType === "list") {
      return { all: this.entries };
    }

    if (this.params.order.includes("alphabetical")) {
      return groupBy(
        this.entries,
        (entry) => entry.title?.charAt(0)?.toUpperCase() || "null"
      );
    }

    const now = new Date();

    const entriesByDate = groupBy(this.entries, (entry) => {
      const entryDate = new Date(entry.createdDate);
      if (isSameDay(entryDate, now)) {
        return "today";
      }

      if (isSameWeek(entryDate, now)) {
        return "thisWeek";
      }

      if (isSameMonth(entryDate, now)) {
        return "thisMonth";
      }

      return entry.createdDate.substr(0, 7);
    });

    // Merge "this week" and "this month" if "this week"
    // contains less than 4 entries.
    if (entriesByDate["thisWeek"]?.length < 4) {
      if (entriesByDate["thisMonth"]) {
        entriesByDate["thisMonth"].unshift(...entriesByDate["thisWeek"]);
        delete entriesByDate["thisWeek"];
      }
    }

    return entriesByDate;
  }

  get focusedEntry() {
    return this.focusedEntryId;
  }

  get focusedEntryItem() {
    const focusedEntry = this.focusedEntry;
    if (!focusedEntry) {
      return undefined;
    }
    return this.getEntryItem(focusedEntry);
  }

  /**
   * Initiate a new entry search.
   * @param params URL params for the new search
   */
  @action.bound
  startEntrySearch(params: SearchParams) {
    let section: number | undefined | "all" =
      parseInt(params.sectionId ?? "", 10) || undefined;

    if (params.sectionId === "all") {
      section = "all";
    }

    if (!section) {
      console.warn("Cannot start entry search without sectionId");
      return;
    }

    const query = params.query || "";
    const order = params.order || this.rootStore.searchStore.defaultResultOrder;
    const tagStatus = params.tagStatus
      ?.split(" ")
      .map((t) => Number(t))
      .filter(Boolean);
    const tags = params.tags?.split("_").map((p) =>
      p
        .split(" ")
        .map((t) => Number(t))
        .filter(Boolean)
    );
    const customFilters = customFilterValuesFromString(
      params.customFilters || ""
    );

    const attributes = this.rootStore.filterStore.attributeFilters.asAPIParams;

    this.params = {
      ...this.params,
      section,
      query,
      order,
      tagStatus,
      tags,
      customFilters,
      attributes,
    };
  }

  @action.bound
  clearRecentEntries() {
    this.recentEntries = [];
  }

  @action.bound
  resetSearch() {
    const { recordStore, documentStore, flowStore, multiSelectStore } =
      this.rootStore;
    this.focusedEntryId = undefined;
    if (this.entries.length > 0) {
      if (multiSelectStore.documents.entryUuid) {
        const entry = recordStore.entryCache.get(
          multiSelectStore.documents.entryUuid
        );
        if (entry) {
          recordStore.entryCache.replace({ [entry.uuid]: entry });
        } else {
          recordStore.entryCache.clear();
        }
      } else {
        recordStore.entryCache.clear();
      }

      documentStore.documentCache.clear();
      flowStore.clearChecklistResultsCache();
    }
    this.entries = [];
    this.recentEntries = [];
    this.data = INITIAL_STATE;
  }

  @action.bound
  focus(entryId?: number) {
    if (entryId) {
      this.focusedEntryId = entryId;
    }
  }

  focusNextEntry() {
    const currentItemIndex = this.entries.findIndex(
      (entry) => entry.id === this.focusedEntryId
    );

    if (currentItemIndex === -1) {
      // Currently nothing is focused. Navigate to first entry.
      if (this.entries.length > 0) {
        this.focus(this.entries[0].id);
      }
      return;
    }

    const currentEntry = this.entries[currentItemIndex];
    if (!currentEntry) {
      // This should only happen if there are no entries.
      return;
    }

    if (currentItemIndex < this.entries.length - 1) {
      this.focus(this.entries[currentItemIndex + 1].id);
    }
  }

  focusPreviousEntry() {
    const currentItemIndex = this.entries.findIndex(
      (entry) => entry.id === this.focusedEntryId
    );

    if (currentItemIndex < 1) {
      return;
    }

    const newEntry = this.entries[currentItemIndex - 1];
    if (newEntry) {
      this.focus(newEntry.id);
    }
  }

  getEntryItem = (entryId: number) =>
    this.entries.find((entry) => entry.id === entryId);

  @action.bound
  addEntryItem(entry: EntryModel) {
    this.entries.unshift(entry);
  }

  @action.bound
  updateEntryItem(entryId: UUID, updates: (entry: EntryModel) => void) {
    this.entries.some((entry) => {
      if (entry.uuid === entryId) {
        updates(entry);
        return true;
      }
      return false;
    });
  }

  @action
  removeEntryItem = (...entryIds: UUID[]): void => {
    this.entries = this.entries.filter((item) => !entryIds.includes(item.uuid));
  };

  @action.bound
  updateDocumentItem(
    documentId: UUID,
    updates: (document: DocumentModel) => void
  ) {
    this.entries.some((entry) => {
      const doc = entry.documents.find((d) => d.uuid === documentId);
      if (doc) {
        updates(doc);
        return true;
      }
      return false;
    });
  }

  @action.bound
  removeDocumentItem(entryId: number, documentId: UUID) {
    this.entries.some((entry) => {
      if (entry.id === entryId) {
        entry.documents = entry.documents.filter(
          (doc) => doc.uuid !== documentId
        );

        return true;
      }
      return false;
    });
  }

  /**
   * Load entry results. This is called by ResultStore when a new search is initiated
   * by changing the current search parameters. When called directly it will load more
   * results (a single page) for the current search.
   */
  @action.bound
  async loadEntryResults() {
    const { recordStore, messageStore } = this.rootStore;

    if (!this.data.loadingStatus.hasMore || this.data.isLoading) {
      return;
    }

    // Cancel any currently ongoing request
    this.cancelToken?.cancel();

    try {
      this.cancelToken = axios.CancelToken.source();
      this.data.isLoading = true;
      const page = this.data.loadingStatus.page + 1;
      if (page === 1) {
        this.lastSearchTime = Date.now();
      }

      const { data } = await this.api.findEntries(
        this.params,
        page,
        {
          includeAttributes: this.viewType === "list",
        },
        this.cancelToken?.token
      );

      const newEntries = data.data.map(recordStore.entryFromJson);

      this.entries.push(...newEntries);
      this.data.loadingStatus.hasMore = data.hasMore;
      this.data.loadingStatus.page = page;
      this.data.loadingStatus.total = data.total ?? 0;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      console.error("Failed to load results", e);
      messageStore.addMessage({
        type: "failure",
        title: texts.searchFailed,
      });
    } finally {
      this.data.isLoading = false;
    }
  }

  async downloadCSV() {
    const cancelToken = axios.CancelToken.source();
    const messageId = this.rootStore.messageStore.addMessage({
      type: "updating",
      title: texts.csvGeneratingTitle,
      text: texts.csvGeneratingText,
      action: {
        title: commonTexts.cancel,
        onClick: () => cancelToken.cancel(),
      },
    });
    try {
      const res = await this.api.getEntryReport(this.params, cancelToken.token);

      this.rootStore.messageStore.updateMessage(messageId, {
        type: "success",
        title: texts.csvDownloading,
        action: undefined,
      });

      downloadFile(res);
    } catch (e) {
      console.error("Failed to download CSV report", e);
      this.rootStore.messageStore.updateMessage(messageId, {
        type: "failure",
        title: axios.isCancel(e) ? texts.csvCancelled : texts.csvDownloadFailed,
        text: undefined,
        action: undefined,
      });
    }
  }

  /** -- Subscriber interface -- */
  onDataAdded(event: AddEvent) {
    switch (event.type) {
      case "Entry":
        this.addEntryItem(event.data);
        break;
    }
  }

  onDataDeleted(event: DeleteEvent) {
    switch (event.type) {
      case "Entry":
        this.removeEntryItem(event.uuid);
        break;
    }
  }

  onDataUpdated(event: UpdateEvent) {
    switch (event.type) {
      case "Entry": {
        const updatedFields = event.fields ?? [];
        const hasMovedToDifferentSection =
          updatedFields.includes("sectionId") &&
          event.data.sectionId !== this.params.section;

        if (hasMovedToDifferentSection) {
          this.removeEntryItem(event.data.uuid);
        }
        break;
      }
    }
  }
}

const texts = defineMessages({
  csvGeneratingTitle: {
    id: "searchpage.toolbar.message.generatingcsvtitle",
    defaultMessage: "Generating CSV",
  },
  csvGeneratingText: {
    id: "searchpage.toolbar.message.generatingcsvtext",
    defaultMessage: "Based on your current search.",
  },
  csvDownloading: {
    id: "searchpage.toolbar.message.downloadingcsv",
    defaultMessage: "Downloading CSV",
  },
  csvDownloadFailed: {
    id: "searchpage.toolbar.message.downloadingcsvfailed",
    defaultMessage: "Could not download CSV",
  },
  csvCancelled: {
    id: "searchpage.toolbar.message.csvcancelled",
    defaultMessage: "CSV report was cancelled",
  },
  searchFailed: {
    id: "searchpage.message.searchfailed",
    defaultMessage: "Failed to load results",
  },
});

const downloadFile = (response: AxiosResponse<Blob>) => {
  const fileName = getFileNameFromContentDisposition(
    response.headers["content-disposition"]
  );
  const url = window.URL.createObjectURL(response.data);

  // Use the download attribute on a temporary anchor to trigger the browser
  // download behavior.
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

function getFileNameFromContentDisposition(contentDisposition?: string) {
  const fallback = `documaster_${Date.now()}.csv`;
  if (!contentDisposition) return fallback;

  const match = contentDisposition.match(/filename="?([^"]+)"?/);

  return match ? match[1] : fallback;
}
