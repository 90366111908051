import React, {
  createRef,
  CSSProperties,
  FC,
  useState,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { defineMessages, FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { useStores } from "@web/stores/context";
import { DocumentModel, EntryModel } from "@web/models";
import { ResultsGrid, ResultsList } from "@web/components";
import { KeyboardNavigation } from "@web/components/KeyboardNavigation";
import { getScrollElement, getScrollTop } from "@web/utils/helpers";
import { Dialog } from "@web/components/Dialog";
import { Button } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { ResultViewProps } from "@web/components/Results/types";
import { NoResultsDescription } from "@web/components/Results/NoResultsDescription";
import { generateDocumentUrl, generateEntryUrl } from "@web/utils/URLHelpers";

const SCROLL_LOAD_THRESHOLD = 500;

type DialogType = "max-selection" | null;

const ResultsContainer: FC = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    resultStore,
    searchStore,
    recordStore,
    documentStore,
    multiSelectStore,
    keyboardStore,
    sectionStore,
    uploadStore,
  } = useStores();
  const [shiftKey, setShiftKey] = useState(false);
  const [dialog, setDialog] = useState<DialogType>(null);

  const contentRef = createRef<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener("keyup", handleShiftKey);
    window.addEventListener("keydown", handleShiftKey);
    window.addEventListener("scroll", handleWindowScroll);
    window.addEventListener("resize", loadIfMoreRoom);

    return () => {
      window.removeEventListener("keyup", handleShiftKey);
      window.removeEventListener("keydown", handleShiftKey);
      window.removeEventListener("scroll", handleWindowScroll);
      window.removeEventListener("resize", loadIfMoreRoom);
    };
  }, []);

  useEffect(() => {
    const scrollEl = getScrollElement();
    if (scrollEl && "scrollTo" in scrollEl) {
      scrollEl.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [resultStore.lastSearchTime]);

  const handleShiftKey = (e: KeyboardEvent): void => {
    setShiftKey(e.shiftKey);
  };

  const handleSearchQueryChange = (query: string) => {
    searchStore.setSearchQuery(query);
    setSearchParams({ query });
  };

  const handleEntryClick = (entry: EntryModel) => {
    resultStore.focus(entry.id);
    recordStore.setEntry(entry);
    entry.reload();
    navigate(generateEntryUrl(entry.id));
  };

  const handleDocumentClick = (document: DocumentModel, entry: EntryModel) => {
    resultStore.focus(entry.id);
    documentStore.setDocument(document);
    navigate(generateDocumentUrl(document.id));
  };

  /** KEYBOARD NAVIGATION */
  const handleArrowUp = (e: KeyboardEvent) => {
    // Don't navigate entries if we are viewing a document
    if (!documentStore.hasDocument) {
      resultStore.focusPreviousEntry();
    }
  };

  const handleArrowDown = (e: KeyboardEvent) => {
    // Don't navigate entries if we are viewing a document
    if (!documentStore.hasDocument) {
      resultStore.focusNextEntry();
    }
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (resultStore.focusedEntryItem) {
      handleEntryClick(resultStore.focusedEntryItem);
    }
  };

  const handleClearRecentEntries = () => {
    resultStore.resetSearch();
    resultStore.loadEntryResults();
    multiSelectStore.clearSelection();
  };

  const navigationIsEnabled = () => {
    return !documentStore.hasDocument && !recordStore.hasEntry;
  };

  const handleWindowScroll = () => {
    const { clientHeight, scrollHeight } = getScrollElement();
    const top = getScrollTop();
    const bottom = Math.max(0, scrollHeight - clientHeight - top);
    notifyScrollThrottled({ top, bottom });
  };

  const scrollToItem = (item: HTMLElement) => {
    const container = getScrollElement();
    const SCROLL_TOP_MARGIN = 142; // header height
    const SCROLL_BOTTOM_MARGIN = 20;

    if (container.scrollHeight > container.clientHeight) {
      // Scroll container is higher than what's visible on screen,
      // ie. there's scrolling. Must check if the item is fully visible.

      const containerBottom = container.clientHeight + container.scrollTop;
      const itemBottom = item.offsetTop + item.offsetHeight;
      if (itemBottom > containerBottom) {
        // Bottom of item is below what's visible - scroll down.
        container.scrollTop =
          itemBottom - container.clientHeight + SCROLL_BOTTOM_MARGIN;
      } else if (item.offsetTop < container.scrollTop) {
        // Top of item is above what's visible - scroll up.
        container.scrollTop = item.offsetTop - SCROLL_TOP_MARGIN;
      } else {
        // The whole item is visible – do nothing.
      }
    }
  };

  const notifyScrollThrottled = throttle(
    (scroll: { top: number; bottom: number }) => {
      if (scroll.bottom < SCROLL_LOAD_THRESHOLD) {
        resultStore.loadEntryResults();
      }
    },
    300,
    { leading: true, trailing: true }
  );

  const loadIfMoreRoom = debounce(() => {
    const { current: contentRef2 } = contentRef;

    if (!resultStore.resultState.hasMore) {
      return;
    }

    if (!contentRef2) {
      return;
    }

    if (contentRef2.clientHeight <= getScrollElement().clientHeight) {
      resultStore.loadEntryResults();
    }
  }, 300);

  const handleSelect = (entry: EntryModel, selectEntry: boolean): void => {
    const { entries } = multiSelectStore;
    const lastSelected = entries.lastSelected;
    const fromEntry = shiftKey && lastSelected ? lastSelected : entry;

    if (selectEntry) {
      const success = entries.addRangeToSelection(fromEntry, entry, [
        ...resultStore.entries,
        ...resultStore.recentEntries,
      ]);

      if (!success) {
        setDialog("max-selection");
      }
    } else {
      entries.removeFromSelection(entry);
    }
  };

  const handleBackgroundClick = (e: React.MouseEvent): void => {
    if (e.currentTarget === e.target && !shiftKey) {
      multiSelectStore.clearSelection();
    }
  };

  /**
   * Prevent text selecting when holding shift-key for multiselect
   * */
  const userSelect = shiftKey ? "none" : "initial";
  const userSelectStyles = {
    userSelect,
    WebkitUserSelect: userSelect,
  } as CSSProperties;

  const resultViewProps: ResultViewProps = {
    groupedEntries: resultStore.groupedEntries,
    resultState: resultStore.resultState,
    uploads: uploadStore.uploads,
    resultOrder: searchStore.order,
    focusedEntryId: resultStore.focusedEntryId,
    recentEntries: resultStore.recentEntries,
    onClearRecentEntries: handleClearRecentEntries,
    getSearchDescription: searchStore.getSearchQueryComponents,
    onEntryClick: handleEntryClick,
    onDocumentClick: handleDocumentClick,
    onBackgroundClick: handleBackgroundClick,
    onLoadComplete: loadIfMoreRoom,
    scrollToItem: scrollToItem,
    onSelect: handleSelect,
    selectedEntries: multiSelectStore.entries,
    isGlobalSearch: sectionStore.isGlobalSearch,
  };

  const { hasMore, count } = resultStore.resultState;

  return (
    <div ref={contentRef} style={userSelectStyles}>
      {!hasMore && count === 0 ? (
        <NoResultsDescription
          updateSearchQuery={handleSearchQueryChange}
          {...resultViewProps.getSearchDescription()}
        />
      ) : (
        <>
          {resultStore.viewType === "grid" && (
            <ResultsGrid {...resultViewProps} />
          )}
          {resultStore.viewType === "list" && (
            <ResultsList {...resultViewProps} />
          )}
        </>
      )}
      <KeyboardNavigation
        identifier="Results"
        keyboardStore={keyboardStore}
        enabled={navigationIsEnabled}
        onArrowUp={handleArrowUp}
        onArrowDown={handleArrowDown}
        onEnter={handleEnter}
        onSpacebar={handleEnter}
      />
      {dialog === "max-selection" && (
        <Dialog
          title={texts.maxSelectionTitle}
          onEscape={() => setDialog(null)}
          onClickOutside={() => setDialog(null)}
        >
          <p>
            <FormattedMessage
              {...texts.maxSelectionBody}
              values={{ limit: multiSelectStore.entries.options.maxSelection }}
            />
          </p>
          <Button
            text={commonTexts.ok}
            variant="primary"
            onClick={() => setDialog(null)}
          />
        </Dialog>
      )}
    </div>
  );
});

export default ResultsContainer;

const texts = defineMessages({
  maxSelectionTitle: {
    id: "searchpage.maxselectiondialog.title",
    defaultMessage: "Selection limit reached",
  },
  maxSelectionBody: {
    id: "searchpage.maxselectiondialog.body",
    defaultMessage: "You can't select more than {limit} folders at a time.",
  },
});
