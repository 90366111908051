import React from "react";
import { FormattedDate } from "react-intl";

export const DateTime: React.FC<{ datetime: string | Date }> = ({
  datetime,
}) => {
  const isoTime = datetime instanceof Date ? datetime.toISOString() : datetime;

  return (
    <time dateTime={isoTime}>
      <FormattedDate
        value={datetime}
        year="numeric"
        day="2-digit"
        month="long"
        hour="numeric"
        minute="numeric"
      />
    </time>
  );
};
