import React, { useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useIntl, defineMessages } from "react-intl";
import { ImagePreview } from "@web/components/FilePreview/ImagePreview";
import { IconPreview } from "@web/components/FilePreview/IconPreview";
import { PDFPreview } from "@web/components/FilePreview/PDFPreview";
import { TextPreview } from "@web/components/FilePreview/TextPreview";
import { DocumentVersionModel } from "@web/models";
import { GlobalConfig } from "@config/context";
import { AudioPreview } from "./AudioPreview";
import { VideoPreview } from "./VideoPreview";
import { PDFPreviewLowBandwidth } from "./PDFPreviewLowBandwidth";

export interface IFilePreview {
  version: DocumentVersionModel;
  maxWidth: number;
  maxHeight: number;
  searchQuery?: string;
  preload?: boolean;
}

export const FilePreview = observer((p: IFilePreview) => {
  const config = useContext(GlobalConfig);
  const intl = useIntl();

  const {
    fileExt,
    electronicDocumentId,
    text,
    documentPreviewImage,
    downloadUrl,
  } = p.version;

  if (p.preload) {
    if (documentPreviewImage?.previewType === "image") {
      return (
        <ImagePreview
          preload
          electronicDocumentId={electronicDocumentId}
          fileExt={fileExt}
          maxWidth={p.maxWidth}
          maxHeight={p.maxHeight}
          downloadUrl={downloadUrl}
          documentPreviewImage={documentPreviewImage}
        />
      );
    }
    return null;
  }

  if (p.version.isProcessingPreview) {
    return <_processing>{intl.formatMessage(texts.processing)}</_processing>;
  }

  if (documentPreviewImage?.state !== "complete") {
    return <IconPreview type={fileExt} />;
  }

  switch (documentPreviewImage.previewType) {
    case "document":
      if (
        config.hasFeature("lowBandwidth") &&
        documentPreviewImage.loResImages !== undefined
      ) {
        return (
          <PDFPreviewLowBandwidth
            electronicDocumentId={electronicDocumentId}
            loResImages={documentPreviewImage.loResImages}
          />
        );
      }

      return (
        <PDFPreview
          electronicDocumentId={electronicDocumentId}
          searchQuery={p.searchQuery}
          previewTimestamp={p.version.previewTimestamp}
        />
      );
    case "audio":
      return <AudioPreview {...p} />;
    case "video":
      return <VideoPreview {...p} />;
    case "text":
      return <TextPreview text={text} fileExt={fileExt} fullscreen />;
    case "image":
      return (
        <ImagePreview
          electronicDocumentId={electronicDocumentId}
          fileExt={fileExt}
          maxWidth={p.maxWidth}
          maxHeight={p.maxHeight}
          documentPreviewImage={documentPreviewImage}
          downloadUrl={downloadUrl}
        />
      );
    default:
      return <IconPreview type={fileExt} />;
  }
});

const _processing = styled.div`
  font-size: 2em;
`;

const texts = defineMessages({
  processing: {
    id: "filepreview.processing",
    defaultMessage: "Processing file…",
  },
});
