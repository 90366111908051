import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Search } from "@web/components/Search";
import { useStores } from "@web/stores/context";
import { generateDocumentUrl, generateEntryUrl } from "@web/utils/URLHelpers";
import {
  fallbackOrderFrom,
  relevanceOrderFrom,
  SearchSuggestionType,
} from "@web/models";

export const SearchContainer = observer(() => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { searchStore, sectionStore, filterStore, currentSearchURL } =
    useStores();

  const handleSubmit = () => {
    doSearch();
  };

  const handleSuggestionClick = (
    suggestion: SearchSuggestionType,
    closeCb: () => void
  ) => {
    switch (suggestion.type) {
      case "history":
        searchStore.setSearchQuery(suggestion.value);
        doSearch();
        closeCb();
        break;
      case "document":
        if (suggestion.isSingleDocumentEntry) {
          navigate(generateEntryUrl(suggestion.document.entryId));
        } else {
          navigate(generateDocumentUrl(suggestion.document.id));
        }
        break;
      case "filter":
        filterStore.setFilterValueSelected({
          classificationId: suggestion.classification.id,
          tag: suggestion.tag,
          selected: true,
        });
        searchStore.clearSearchQuery();
        navigate(currentSearchURL());
        break;
      case "autocomplete":
      case "freetext":
        searchStore.addFilterToQuery(suggestion);
        break;
    }
  };

  const handleChange = (value: string) => {
    searchStore.setSearchQuery(value);
  };

  const handleClear = () => {
    const previousOrder = searchStore.order;
    const fallbackOrder = fallbackOrderFrom(previousOrder);
    searchStore.setSearchQuery("");
    searchStore.setResultOrder(fallbackOrder);
    doSearch();
  };

  const handleGlobalToggle = (checked: boolean) => {
    sectionStore.isGlobalSearch = checked;

    if (sectionStore.isGlobalSearch) {
      navigate({ pathname: "/section/all", search: searchParams.toString() });
    } else {
      filterStore.resetSelectedFilterValues();
      doSearch();
    }
  };

  const doSearch = () => {
    const previousOrder = searchStore.order;
    const relevanceOrder = relevanceOrderFrom(previousOrder);
    const fallbackOrder = fallbackOrderFrom(previousOrder);

    if (searchStore.query !== "") {
      searchStore.addQueryToHistory();
      searchStore.setResultOrder(relevanceOrder);
    } else {
      searchStore.setResultOrder(fallbackOrder);
    }

    navigate(currentSearchURL());
  };

  return (
    <Search
      query={searchStore.query}
      suggestions={searchStore.suggestions}
      isGlobalSearch={sectionStore.isGlobalSearch}
      onSubmit={handleSubmit}
      onSuggestionClick={handleSuggestionClick}
      onChange={handleChange}
      onClear={handleClear}
      onGlobalToggle={handleGlobalToggle}
    />
  );
});
