import React from "react";

export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7297 3.74531L2.42815 18.8953C1.90315 19.8422 2.60159 21 3.69847 21H20.3063C21.3985 21 22.0969 19.8422 21.5766 18.8953L13.2703 3.74531C12.7219 2.75156 11.2782 2.75156 10.7297 3.74531ZM12.1693 8.38213C12.7595 8.38213 13.2213 8.89062 13.1647 9.47811L12.7193 14.0968C12.6835 14.4673 12.3722 14.75 12 14.75V14.75C11.6278 14.75 11.3165 14.4673 11.2808 14.0968L10.8354 9.47811C10.7788 8.89062 11.2406 8.38213 11.8308 8.38213H12.1693ZM12 17.8625C11.4985 17.8625 11.1047 17.4828 11.1047 17C11.1047 16.5172 11.4985 16.1375 12 16.1375C12.5016 16.1375 12.8953 16.5172 12.8953 17C12.8953 17.4828 12.5016 17.8625 12 17.8625Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
