import { observer } from "mobx-react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Layout } from "@web/components/Layout";
import { MessageList } from "@web/components/MessageList";
import { ThemePicker } from "@web/components/ThemePicker";
import { DocumentPage } from "@web/pages/DocumentPage";
import { EntryPage } from "@web/pages/EntryPage";
import { NotFoundPage } from "@web/pages/NotFoundPage";
import { SearchPage } from "@web/pages/SearchPage";
import SettingsPage from "@web/pages/SettingsPage";
import { RootStore } from "@web/stores";
import { RootStoreContext } from "@web/stores/context";
import { GlobalStyle } from "@web/styles";
import {
  RICH_TEXT_FORMATTERS,
  SupportedLocale,
  getTranslationsForLocale,
} from "@web/translations";
import { ConfirmationDialogProvider } from "@web/utils/hooks/useConfirmDialog";
import { AppConfig } from "@config/index";
import { GlobalConfig } from "@config/context";
import { ErrorBoundary } from "./ErrorBoundary";

interface Props {
  stores: RootStore;
  config: AppConfig;
}

@observer
class App extends Component<Props> {
  render() {
    const { stores, config } = this.props;
    const translations = getTranslationsForLocale(config.locale);

    return (
      <GlobalConfig.Provider value={config}>
        <RootStoreContext.Provider value={stores}>
          <IntlProvider
            locale={config.locale}
            defaultLocale={SupportedLocale.EN}
            messages={translations}
            defaultRichTextElements={RICH_TEXT_FORMATTERS}
          >
            <ErrorBoundary>
              {config.hasFeature("themePicker") && <ThemePicker />}
              <ConfirmationDialogProvider>
                <Helmet>
                  <title>{config.instance}</title>
                </Helmet>
                <GlobalStyle appTheme={config.theme} />
                <Router>
                  <Layout
                    fullName={config.loggedInUser.name}
                    showNavBar={config.hasFeature("navBar")}
                  >
                    <Routes>
                      <Route path="/" element={<SearchPage />}>
                        <Route
                          path="/document/:documentId"
                          element={<DocumentPage />}
                        />
                        <Route
                          path="/record/:entryId"
                          element={<EntryPage />}
                        />
                      </Route>
                      <Route
                        path="/section/:sectionId"
                        element={<SearchPage />}
                      />
                      <Route path="/settings/*" element={<SettingsPage />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </Layout>
                </Router>
                <MessageList
                  messages={stores.messageStore.messages}
                  hideMessage={stores.messageStore.hideMessage}
                  removeMessage={stores.messageStore.removeMessage}
                />
              </ConfirmationDialogProvider>
            </ErrorBoundary>
          </IntlProvider>
        </RootStoreContext.Provider>
      </GlobalConfig.Provider>
    );
  }
}

export default App;
