import React, { useEffect, useRef } from "react";
import { Portal } from "@web/components/Portal";
import styled from "styled-components";
import { vars, ZIndex } from "@web/styles";

interface Props {
  title: string;
  value: string;
  position: Position;
  onMouseOut: () => void;
}

export interface Position {
  x: number;
  y: number;
}

export const AttributeTooltip = ({
  title,
  value,
  position,
  onMouseOut,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseOver = (event: MouseEvent) => {
      const target = event.target instanceof Node ? event.target : undefined;
      if (target && !ref.current?.contains(target)) {
        onMouseOut();
      }
    };
    document.addEventListener("mouseover", handleMouseOver);
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  return (
    <Portal>
      <_wrap ref={ref} position={position}>
        <_inner onMouseLeave={onMouseOut}>
          <_title>{title}</_title>
          <span>{value}</span>
        </_inner>
      </_wrap>
    </Portal>
  );
};

const _wrap = styled.div<{ position: Position }>`
  position: absolute;
  top: ${(p) => p.position.y}px;
  left: ${(p) => p.position.x}px;
  z-index: ${ZIndex.tooltip};
  cursor: default;
`;

const _inner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: ${vars.light95};
  color: ${vars.secondary};
  border-radius: 0.7rem;
  padding: 0.1rem 0.6rem;
  font-size: 0.8125rem;
  box-shadow: ${vars.shadow.z2};
  > span {
    white-space: nowrap;
  }
`;

const _title = styled.span`
  font-weight: 500;
  margin-bottom: 4px;
`;
