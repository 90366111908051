import React, { useRef } from "react";
import styled from "styled-components";
import { FocusScope, useKeyboard } from "react-aria";
import { ClickOutside } from "@web/elements";
import { CrossIcon } from "@web/elements/Icons";
import { vars, ZIndex } from "@web/styles";
import { media } from "@web/styles/utils";
import { useScrollBlocker } from "@web/utils/hooks/useScrollBlocker";
import { Portal } from "./Portal";

export interface IOverlay {
  bg?: string;
  disableAutoFocus?: boolean;
  disableRestoreFocus?: boolean;
  onEscape?: () => void;
  onClickOutside?: () => void;
  onClickCloseButton?: () => void;
  children: React.ReactElement<React.ReactNode>;
}

export const Overlay: React.FC<IOverlay> = (p) => {
  useScrollBlocker();

  const wrapRef: React.RefObject<HTMLDivElement> = useRef(null);
  const { keyboardProps } = useKeyboard({
    onKeyUp: (e) => {
      if (e.key === "Escape") {
        p.onEscape?.();
      }
    },
  });

  function handleClickOutside() {
    p.onClickOutside?.();
  }

  function handleClickCloseButton() {
    p.onClickCloseButton?.();
  }

  function handleDragDrop(e: React.DragEvent) {
    /**
     * This ensures UploadOverlays higher up in the DOM hierarchy
     * isn't triggered unsolicitedly. Ref. AFE-207
     */
    e.stopPropagation();
  }

  const { children, bg, onClickCloseButton } = p;

  return (
    <Portal>
      <FocusScope
        autoFocus={!p.disableAutoFocus}
        restoreFocus={!p.disableRestoreFocus}
      >
        <Wrap
          bg={bg}
          ref={wrapRef}
          onDrop={handleDragDrop}
          onDragEnter={handleDragDrop}
          {...keyboardProps}
        >
          <ClickOutside onClick={handleClickOutside} />

          {onClickCloseButton && (
            <_closeButton onClick={handleClickCloseButton}>
              <CrossIcon />
            </_closeButton>
          )}

          {children}
        </Wrap>
      </FocusScope>
    </Portal>
  );
};

const Wrap = styled.div<{
  bg?: string;
}>`
  background: ${(p) => p.bg || vars.overlay};
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.overlay};
`;

const _closeButton = styled.button`
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  z-index: ${ZIndex.overlay};

  ${media("desktop")} {
    background: ${vars.overlay};
    color: ${vars.overlayFg};
    top: 1rem;
    left: 1rem;
    width: 2.5rem;
    height: 2.5rem;

    :hover {
      background: ${vars.overlay};
    }
  }

  ${media("compact")} {
    background: ${vars.light95};
    border-radius: 0 0 100% 0;
    color: ${vars.primary};
    width: 2rem;
    height: 2rem;
    top: 0;
    left: 0;

    svg {
      flex-shrink: 0;
    }
  }
`;
