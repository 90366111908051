import React, { Ref, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import ellipsis from "polished/lib/mixins/ellipsis";
import {
  AttributeModel,
  ChangelogAddOrDelModel,
  AttributeValuesMapModel,
} from "@web/models";
import { AsteriskIcon, CrossIcon } from "@web/elements/Icons";
import { vars } from "@web/styles";
import { CommentIcon } from "@web/elements/Icons/CommentIcon";
import { CommentPopover } from "./CommentPopover";

interface Events {
  onClick?: (e: React.MouseEvent) => void;
}

interface Props extends Events {
  canUpdate: boolean;
  attribute: AttributeModel;
  attributeValues: AttributeValuesMapModel;
  comment?: ChangelogAddOrDelModel;
  isRequired?: boolean;
}

export const AttributeLabel = observer(
  React.forwardRef((p: Props, ref: Ref<HTMLButtonElement>) => {
    const intl = useIntl();
    const values = p.attributeValues.getValueObjects({
      definitionId: p.attribute.uuid,
      includeChanges: false,
    });

    const [showCommentPopover, setShowComment] = useState(false);

    const handleClear = (e: React.MouseEvent) => {
      e.stopPropagation();
      p.attributeValues.clearValues(p.attribute.uuid);
    };

    const toggleComment = (e?: React.MouseEvent): void => {
      if (e) {
        e.stopPropagation();
      }
      setShowComment(!showCommentPopover);
    };

    const showClearCross =
      !p.attribute.isListAttribute && values.length > 0 && p.canUpdate;

    const isRecentlyUpdated = values.some((value) => value.isRecentlyUpdated());

    return (
      <>
        {p.isRequired && (
          <_required>
            <AsteriskIcon />
          </_required>
        )}
        <_wrap
          ref={ref}
          onClick={p.canUpdate ? p.onClick : undefined}
          isEmpty={values.length === 0}
          isEditable={p.canUpdate}
          isRecentlyUpdated={isRecentlyUpdated}
          hasComment={!!p.comment}
        >
          <_title>{p.attribute.name}</_title>
          {values.length > 0 && (
            <_value>
              {values.map((value) => value.formattedValue(intl)).join(", ")}
              {!!p.comment && (
                <CommentIcon
                  role="button"
                  aria-label={intl.formatMessage(texts.displayCommentButton, {
                    attributeName: p.attribute.name,
                  })}
                  onClick={toggleComment}
                  width="1em"
                  height="1em"
                />
              )}
            </_value>
          )}
          {showClearCross && (
            <CrossIcon
              aria-label={intl.formatMessage(texts.deleteAttributeValueButton, {
                attributeName: p.attribute.name,
              })}
              role="button"
              width={12}
              height={12}
              onClick={handleClear}
            />
          )}
        </_wrap>
        {showCommentPopover && (
          <CommentPopover
            ref={ref}
            comment={p.comment}
            onCancel={toggleComment}
          />
        )}
      </>
    );
  })
);

AttributeLabel.displayName = "AttributeLabel";

const texts = defineMessages({
  displayCommentButton: {
    id: "entry.attributes.values.comment.button",
    defaultMessage: "Display comment for {attributeName}",
  },
  deleteAttributeValueButton: {
    id: "entry.attributes.values.clear.button",
    defaultMessage: "Clear value for {attributeName}",
  },
});

const _wrap = styled.button<{
  isEmpty: boolean;
  isRecentlyUpdated: boolean;
  isEditable?: boolean;
  hasComment: boolean;
}>`
  font-weight: 500;
  border-radius: 2rem;
  color: ${vars.secondary};
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  min-width: 0;
  max-width: 100%;
  padding: 4px 8px;
  ${(p) => (p.hasComment ? "margin-left: 1.2rem;" : "")}
  position: relative;

  :focus {
    border: 1px solid ${vars.secondaryAltFg};
    outline: none;
  }

  ${(p) =>
    p.isEditable &&
    `
    cursor: pointer;
  `}

  ${(p) =>
    p.isEmpty &&
    `
    border: 1px solid ${vars.secondaryAltLight20};
    background: ${vars.light95};
    ::before {
      content: "+";
    }
    :hover {
      background: ${vars.secondaryAltLight30};
      border: 1px solid ${vars.secondaryAlt};
    }
  `}
  ${(p) =>
    !p.isEmpty &&
    `
    border: 1px solid ${vars.secondaryAltLight20};
    background: ${vars.secondaryAltLight20};
    :hover {
      background: ${vars.secondaryAltLight10};
    }
  `}

  ${(p) =>
    p.isRecentlyUpdated &&
    `
    border: 1px solid ${vars.note};
    background: ${vars.note};
  `}
  > svg {
    :hover {
      color: ${vars.danger};
    }
  }
`;

const _title = styled.div`
  flex-shrink: 0;

  :not(:only-child) {
    font-weight: bold;
  }
  :only-child {
    margin-left: 4px;
    font-weight: 400;
  }
`;

const _value = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  ${ellipsis()};

  svg {
    position: absolute;
    top: 0;
    left: -1.2rem;
    width: 16px;
    height: 16px;
  }
`;

const _required = styled.div`
  position: relative;
  top: -8px;
  right: -5px;
`;
