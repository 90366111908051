import React from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import { vars } from "@web/styles";

interface Props {
  title: MessageDescriptor;
}

export const Empty: React.FC<Props> = (p) => {
  const intl = useIntl();

  return <_wrap>{intl.formatMessage(p.title)}</_wrap>;
};

const _wrap = styled.span`
  display: flex;
  margin: auto;
  font-size: 1.1em;
  padding: 3px;
  background: ${vars.info};
  align-self: center;
  justify-content: center;
`;
