import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { observer } from "mobx-react";
import styled from "styled-components";
import sortBy from "lodash/sortBy";
import { EntryModel, ReminderModel, renderResult } from "@web/models";
import { vars } from "@web/styles";
import { isMedia, media } from "@web/styles/utils";
import { ReminderItem } from "@web/components/Entry/Reminders/ReminderItem";
import { StyledButton } from "@web/elements/Button/styles";

export const RemindersList: React.FC<{
  entry: EntryModel;
  onAddClick: () => void;
  onCloseClick?: () => void;
  onReminderClick: (reminder: ReminderModel) => void;
}> = observer(({ entry, onAddClick, onCloseClick, onReminderClick }) => {
  const intl = useIntl();

  return (
    <_wrap>
      {!isMedia("compact") && (
        <_header>
          <_title>
            <FormattedMessage {...texts.title} />
          </_title>
        </_header>
      )}

      <_content>
        {renderResult(entry.reminders, {
          NOT_REQUESTED: () => null,

          LOADING: () => (
            <FormattedMessage tagName="p" {...texts.loadingReminders} />
          ),

          SUCCESS: (reminders) =>
            sortBy(reminders, "date").map((reminder) => (
              <ReminderItem
                key={reminder.uuid}
                entry={entry}
                reminder={reminder}
                onClick={onReminderClick}
              />
            )),

          SUCCESS_EMPTY: () => (
            <FormattedMessage tagName="p" {...texts.noRemindersCreated} />
          ),

          ERROR: () => (
            <p>
              <FormattedMessage tagName="i" {...texts.loadRemindersFailed} />
            </p>
          ),
        })}
      </_content>

      {entry.canUpdate && (
        <_addButton onClick={onAddClick}>
          + {intl.formatMessage(texts.addReminder)}
        </_addButton>
      )}
    </_wrap>
  );
});

const _wrap = styled.div``;

const _header = styled.header`
  border-bottom: 1px solid ${vars.secondaryAltLight10};
  padding: 1rem 1.5rem;
`;

const _title = styled.h1`
  font-weight: 500;
  margin: 0;
`;

const _content = styled.div`
  ${media("desktop")} {
    max-height: 300px;
    overflow: auto;
  }

  > p {
    padding: 1rem 1.5rem;
  }
`;

const _addButton = styled(StyledButton).attrs({ variant: "blank" })`
  border-top: 1px solid ${vars.secondaryAltLight10};
  padding: 1rem;
  width: 100%;
  justify-content: left;
  font-weight: normal;
`;

const texts = defineMessages({
  title: {
    id: "entry.reminders.title",
    defaultMessage: "Reminders",
  },
  addReminder: {
    id: "entry.reminders.add",
    defaultMessage: "Add Reminder",
  },
  noRemindersCreated: {
    id: "entry.reminders.noreminders",
    defaultMessage: "No reminders has been added yet.",
  },
  loadingReminders: {
    id: "entry.reminders.loading",
    defaultMessage: "Loading reminders…",
  },
  loadRemindersFailed: {
    id: "entry.reminders.loading.failed",
    defaultMessage: "Could not retrieve reminders.",
  },
});
