import React from "react";
import styled from "styled-components";
import { DocumentSuggestionModel } from "@web/models";
import { vars } from "@web/styles";
import { DocumentThumbnail } from "@web/components/DocumentCard/DocumentThumbnail";
import { SearchSuggestionButton } from "./SearchSuggestionButton";

interface Props {
  data: DocumentSuggestionModel;
  onClick: () => void;
}

export const SuggestedDocument = (p: Props) => (
  <SearchSuggestionButton onClick={p.onClick}>
    <_thumb>
      <DocumentThumbnail
        documentVersion={p.data.document.newestVersion}
        size={36}
      />
    </_thumb>
    <_content>{p.data.document.title}</_content>
  </SearchSuggestionButton>
);

const _thumb = styled.div`
  flex: 0 0;

  img,
  svg {
    display: inline-block;
    border: 1px solid ${vars.secondaryAlt};
    border-radius: 4px;
    margin: 0;
    vertical-align: middle;
    width: 36px;
    height: 36px;
  }
`;

const _content = styled.div`
  display: flex;
  align-items: center;
`;
