import React, { ForwardedRef, forwardRef, useState } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { useFocus, useFocusManager, useKeyboard } from "react-aria";
import { SearchIcon } from "@web/elements/Icons";
import { useUniqueId } from "@web/utils/hooks/useUniqueId";
import { Checkbox } from "@web/elements";
import { CloseButton } from "@web/elements/Button";
import { SearchControlWrap } from "./common";

interface Props {
  value: string;
  isGlobalSearch: boolean;
  suggestionsRef: React.RefObject<HTMLElement>;
  onChange: (value: string) => void;
  onChangeGlobal: (checked: boolean) => void;
  onClickClear: () => void;
  onEscape: () => void;
}

export const SearchInput = forwardRef(
  (p: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const intl = useIntl();
    const inputId = useUniqueId("SearchInput");
    const [isFocus, setFocus] = useState(false);
    const focusManager = useFocusManager();
    const { focusProps } = useFocus({ onFocusChange: setFocus });
    const { keyboardProps } = useKeyboard({
      onKeyDown: (e) => {
        if (e.key === "Escape") {
          p.onEscape();
        }
        if (e.key === "ArrowDown" && p.suggestionsRef.current) {
          focusManager.focusNext({ from: p.suggestionsRef.current });
        }
      },
    });

    return (
      <_wrap data-focus={isFocus} {...keyboardProps}>
        <label htmlFor={inputId}>
          <SearchIcon />
        </label>

        <_input
          ref={ref}
          id={inputId}
          value={p.value}
          type="text"
          enterKeyHint="search"
          placeholder={intl.formatMessage(texts.searchPlaceholder)}
          autoCorrect="off"
          onChange={(e) => p.onChange(e.target.value)}
          {...focusProps}
        />

        {p.value.length > 0 && <_clear onClick={p.onClickClear} />}

        <_check>
          <Checkbox
            label={intl.formatMessage(texts.allSections)}
            checked={p.isGlobalSearch}
            onChange={(e) => p.onChangeGlobal(e.target.checked)}
          />
        </_check>
      </_wrap>
    );
  }
);

SearchInput.displayName = "SearchInput";

const _wrap = styled(SearchControlWrap)`
  border-color: transparent;

  :hover {
    border-color: transparent;
  }
`;

// use `withConfig` to make `enterKeyHint` work (might work with a newer version)
// https://github.com/styled-components/styled-components/issues/3417
const _input = styled.input.withConfig({ shouldForwardProp: () => true })`
  background: unset;
  border: unset;
  color: unset;
  font: unset;
  outline: unset;
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const _check = styled.div`
  flex-shrink: 0;
`;

const _clear = styled(CloseButton)`
  font-size: 0.75rem;
  flex-shrink: 0;
`;

const texts = defineMessages({
  allSections: {
    id: "searchinput.allsections",
    defaultMessage: "All sections",
  },
  searchPlaceholder: {
    id: "searchinput.placeholder",
    defaultMessage: "Start typing…",
  },
});
