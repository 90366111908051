import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
} from "mobx";
import { isFuture } from "date-fns";
import orderBy from "lodash/orderBy";
import {
  parseDocumentEditSession,
  parseDocumentSearchHighlights,
  parseLockNode,
} from "@web/stores/ResponseParser";
import { DocumentEditSession } from "@web/api/BFF/types";
import {
  IDocumentNode,
  IDocumentVersionNode,
  ILoadData,
  Permission,
} from "@web/api/Integration/types";
import { sleep } from "@web/utils/helpers";
import { DocumentStore } from "@web/stores";
import {
  DocumentVersionModel,
  INITIAL_LOADING_STATUS,
  LockModel,
  PageLoadingModel,
  PermissionModel,
  ResultHighlightPart,
  WritableFields,
} from "@web/models";

const SECOND = 1000;
const MAX_DOCUMENT_POLLER_DELAY = 10 * SECOND;
export const INITIAL_DOCUMENT_POLLER_DELAY = 2 * SECOND;

export class DocumentModel implements PermissionModel {
  @observable readonly id: number;
  @observable readonly uuid: UUID;
  @observable readonly permissions: Set<Permission>;
  @observable readonly createdBy: string;
  @observable readonly createdDate: string;
  @observable updatedDate: string;
  @observable sectionId: number;
  @observable entryId: number;
  @observable entryUuid: UUID;
  @observable entryTitle?: string;

  @observable title: string;
  @observable versions: DocumentVersionModel[] = [];
  @observable versionLoadingStatus: PageLoadingModel = INITIAL_LOADING_STATUS;
  @observable lock: LockModel | undefined;

  // Field only set when doing search
  @observable searchHighlights?: {
    title: ResultHighlightPart[];
    fileContent: ResultHighlightPart[];
  };

  // Added by BFF
  @observable editSession?: DocumentEditSession;

  // Fields added for UI
  @observable selectedVersion = 0; // index in versions
  @observable hasUpdate: undefined | "version" = undefined;
  @observable loading?: boolean = false;

  // Disposer of the side effect used to refresh preview data.
  private currentVersionReactionDisposer: IReactionDisposer;
  private currentPollingSettings:
    | {
        lastDelayUsed: number;
        setTimeoutReference: number;
      }
    | undefined;

  private isPollingForPreviewUpdates = false;

  constructor(
    private store: DocumentStore,
    node: IDocumentNode,
    private currentUserId?: UUID
  ) {
    this.store = store; // Needs to be set for mocking to work
    this.id = node.internalIdentifier;
    this.uuid = node.id;
    this.permissions = new Set(node.effectivePermissions);
    this.entryUuid = node.entry.id;
    this.entryId = node.entry.internalIdentifier;
    this.entryTitle = node.entry.title;
    this.sectionId = node.entry.section.internalIdentifier;
    this.createdBy = node.createdBy;
    this.createdDate = node.createdDate;
    this.updatedDate = node.updatedDate;
    this.title = node.title;
    this.searchHighlights = parseDocumentSearchHighlights(node);
    this.updateFromJson(node);

    /**
     * Whenever a different version is visible, call its prepareUiState()
     */
    this.currentVersionReactionDisposer = reaction(
      () => this.currentVersion,
      () => this.currentVersion?.prepareUiState()
    );
  }

  @computed
  get currentVersion() {
    return this.versions[this.selectedVersion];
  }

  @computed
  get newestVersion() {
    return this.versions[0];
  }

  @computed
  get canUpdate() {
    return this.permissions.has("Update");
  }

  @computed
  get canDelete() {
    return this.permissions.has("Delete");
  }

  @computed
  get canAddVersion() {
    return this.permissions.has("CreateChildren");
  }

  hasNewerSignedVersion = (version: DocumentVersionModel) => {
    const newerVersions = this.versions.slice(
      0,
      this.versions.indexOf(version)
    );
    return newerVersions.some((x) => x.isSigned);
  };

  @computed
  get canUnlock() {
    return !!this.lock && this.lock.permissions.has("Delete");
  }

  @computed
  get isLocked() {
    if (this.lock === undefined) {
      return false;
    }

    return isFuture(this.lock.expiryDate);
  }

  @computed
  get isLockedForCurrentUser() {
    if (!this.lock || this.lock.createdByCurrentUser) {
      return false;
    }

    return isFuture(this.lock.expiryDate);
  }

  @computed
  get mostRecentUpdatedDate() {
    const mostRecentUpdatedDateAsUnixtime = Math.max(
      new Date(this.updatedDate).getTime(),
      new Date(this.versions[0].updatedDate).getTime()
    );
    return new Date(mostRecentUpdatedDateAsUnixtime).toISOString();
  }

  @computed
  get isFromSearch() {
    return !!this.searchHighlights;
  }

  /**
   * Clean up state before this object is deleted.
   */
  destroy() {
    this.stopDataPoller();
    this.currentVersionReactionDisposer();
  }

  /**
   * Set up state when this document is shown from the fullscreen UI.
   */
  prepareUiState() {
    this.currentVersion.prepareUiState();
    this.pollForUpdates();
  }

  /**
   * Clean up state when this document is removed from the fullscreen UI.
   */
  resetUiState() {
    this.stopDataPoller();
    this.selectVersion(0);
  }

  download = () => {
    // NOTE: Can be improved. We open a new window to prevent browser from navigating away on mobile.
    // On desktop it opens a new window which closes as soon as the download starts.
    window.open(this.currentVersion.downloadUrl, "Download");
  };

  @action.bound
  selectVersion(index: number) {
    this.selectedVersion = index;
  }

  @action.bound
  addVersion(version: DocumentVersionModel) {
    if (this.versions.some((v) => v.id === version.id)) {
      return;
    }
    this.versions.unshift(version);
    this.selectVersion(0);
    this.versionLoadingStatus.itemCount++;
  }

  @action.bound
  removeVersion(versionId: UUID) {
    const index = this.versions.findIndex((v) => v.uuid === versionId);
    if (index === -1) return;

    if (index === 0) {
      // Remain at index 0
      this.versions.splice(index, 1);
    } else {
      // Move to the preceding version before removing.
      this.selectedVersion = index - 1;
      this.versions.splice(index, 1);
    }
  }

  /**
   * Update fields on this document object.
   */
  @action.bound
  updateState(fields: Partial<DocumentModel>) {
    Object.assign(this, fields);
  }

  /**
   * Update fields with fresh data from the server.
   */
  @action.bound
  updateFromJson(json: Partial<IDocumentNode>) {
    const modifiedFields: Partial<DocumentModel> = {
      ...(json.title && {
        title: json.title,
      }),
      ...(json.effectivePermissions && {
        permissions: new Set(json.effectivePermissions),
      }),
      ...(json.updatedDate && {
        updatedDate: json.updatedDate,
      }),
      ...("lock" in json && {
        lock: json.lock
          ? parseLockNode(json.lock, this.currentUserId)
          : undefined,
      }),
      ...("editSession" in json && {
        editSession: json.editSession
          ? parseDocumentEditSession(json.editSession)
          : undefined,
      }),
      ...(json.entry?.title && {
        entryTitle: json.entry.title,
      }),
    };

    if (json.documentVersions && json.resources?.documentVersions) {
      const data = {
        data: json.documentVersions,
        ...json.resources?.documentVersions,
      };
      this.addVersionsFromJson(data);
    }

    Object.assign(this, modifiedFields);
  }

  /**
   * Update document with lazy loaded versions from the server.
   */
  @action.bound
  addVersionsFromJson(json: ILoadData<IDocumentVersionNode>) {
    const { documentVersionStore } = this.store.rootStore;
    this.versionLoadingStatus.lastPageLoaded = json.page;
    this.versionLoadingStatus.hasMore = json.hasMore;

    const versionsByUuid = new Map<UUID, DocumentVersionModel>();
    // collect existing
    for (const existing of this.versions) {
      versionsByUuid.set(existing.uuid, existing);
    }
    // add incoming
    for (const incoming of json.data) {
      const version = documentVersionStore.versionFromJson(
        incoming,
        this.id,
        this.uuid
      );
      versionsByUuid.set(version.uuid, version);
    }

    this.versions = orderBy(
      Array.from(versionsByUuid.values()),
      (item: DocumentVersionModel) => item.versionNumber,
      "desc"
    );

    /*
    TODO: figure out a good solution for this
    ...(json.resources?.documentVersions && {
      versionLoadingStatus: {
        ...this.versionLoadingStatus,
        pageLoading: false,
        lastPageLoaded: json.resources.documentVersions.page,
        hasMore: json.resources.documentVersions.hasMore,
      },
    }),*/

    this.versionLoadingStatus.itemCount = this.versions.length;
  }

  /* -- Data polling/loading operations -- */

  /**
   * Reload this document.
   */
  @action.bound
  reload() {
    return this.store.reloadDocument(this);
  }

  /**
   * Start polling for updates for this document.
   * Starts at a 2 second interval which increases to 10 seconds over time.
   */
  pollForUpdates = () => {
    const lastDelayUsed = this.currentPollingSettings?.lastDelayUsed ?? 0;
    const delay = Math.min(
      lastDelayUsed + INITIAL_DOCUMENT_POLLER_DELAY,
      MAX_DOCUMENT_POLLER_DELAY
    );

    // `setInterval()` is not used because we want the delay to increase over time
    const setTimeoutReference = window.setTimeout(async () => {
      await this.store.pollForDocumentUpdates(this);
      if (this.currentPollingSettings) {
        this.pollForUpdates();
      }
    }, delay);

    this.currentPollingSettings = {
      lastDelayUsed: delay,
      setTimeoutReference,
    };
  };

  /**
   * Handy when wanting to make the currently active data poller request data more frequently
   * again, usually after the active poller has been running for so long that it rarely polls
   * for backend data.
   */
  resetDataPollerFrequency() {
    this.stopDataPoller();
    this.pollForUpdates();
  }

  stopDataPoller() {
    clearTimeout(this.currentPollingSettings?.setTimeoutReference);
    this.currentPollingSettings = undefined;
  }

  pollForPreviewUpdates = async () => {
    const version = this.currentVersion;

    if (!version) {
      return;
    }

    if (this.isPollingForPreviewUpdates || version.hasCompletedPreview) {
      return;
    }

    this.isPollingForPreviewUpdates = true;

    for (let attemptNumber = 1; attemptNumber <= 10; attemptNumber++) {
      await this.store.pollForPreviewUpdates(this);

      if (version.hasCompletedPreview) {
        break;
      }

      await sleep(attemptNumber * SECOND);
    }

    this.isPollingForPreviewUpdates = false;
  };

  /**
   * Lazy load more versions for this document.
   */
  @action.bound
  loadVersions() {
    if (
      !this.versionLoadingStatus.hasMore ||
      this.versionLoadingStatus.pageLoading
    ) {
      return;
    }
    return this.store.loadVersions(this);
  }

  /* -- CRUD operations -- */
  update = (fields: WritableFields<DocumentModel>) => {
    this.store.saveDocument(this, fields);
  };

  delete = () => {
    return this.store.deleteDocument(this);
  };

  createLock = () => {
    this.store.lockDocument(this);
  };

  deleteLock = () => {
    this.store.unlockDocument(this);
  };

  startEditSession = () => {
    this.store.startDocumentEdit(this);
  };
}
