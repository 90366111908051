import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { EntryStatusModel, OutcomeEventType, PipelineModel } from "@web/models";
import { OutcomeType } from "@web/api/Integration/types";
import { ActionMenu, Input, StatusColor, Checkbox, Label } from "@web/elements";
import { Select } from "@web/elements/Select";
import { getOutcomeOptions } from "@web/components/Flow/helpers";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";
import { flowEntityTexts } from "../texts";

interface IProps {
  pipeline: PipelineModel;
  entryStatuses: EntryStatusModel[];
  eventType: OutcomeEventType;
  onClose: () => void;
}

export const AddOutcome: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const OPTIONS = getOutcomeOptions(p.pipeline.pipelineType);
  const defaultOption = Object.keys(OPTIONS)[0] as OutcomeType;
  const [option, setOption] = useState<OutcomeType>(defaultOption);
  const [text, setText] = useState("");
  const [isSensitive, setSensitive] = useState(false);
  const [entryStatus, setEntryStatus] = useState<EntryStatusModel>(
    p.entryStatuses[0]
  );

  const handleOptionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setOption(e.target.value as OutcomeType);
  };

  const handleSaveOutcome = (evt: SyntheticEvent) => {
    evt.preventDefault();
    const name = `Success outcome: ${option} - ${Date.now()}`;
    switch (option) {
      case "AddEntryComment":
        p.pipeline.createOutcome("AddEntryComment", p.eventType, {
          name,
          entryComment: text,
        });
        break;
      case "ChangeEntryStatus":
        p.pipeline.createOutcome("ChangeEntryStatus", p.eventType, {
          name,
          entryStatus: entryStatus.uuid,
        });
        break;
      case "SendSlackNotification":
      case "SendTeamsNotification":
        p.pipeline.createOutcome(option, p.eventType, {
          name,
          webhookURL: text,
          isSensitive,
        });
    }
    p.onClose();
  };

  const canSave = () => {
    if (option === "AddEntryComment") {
      return text.length > 0;
    }

    if (["SendSlackNotification", "SendTeamsNotification"].includes(option)) {
      return text.match(/https?:\/\/.+\..+/);
    }

    return true;
  };

  return (
    <_wrap onSubmit={handleSaveOutcome}>
      <_edit>
        <Select value={option} onChange={handleOptionSelect}>
          {Object.entries(OPTIONS).map(([key, text]) => (
            <option key={key} value={key}>
              {intl.formatMessage(text)}
            </option>
          ))}
        </Select>

        {option === "AddEntryComment" && (
          <Input
            placeholder={intl.formatMessage(
              flowEntityTexts.outcomes.commentText
            )}
            value={text}
            onChange={(evt) => setText(evt.target.value)}
          />
        )}

        {option === "ChangeEntryStatus" && (
          <_statusWrap>
            {p.entryStatuses.map((status) => (
              <Label key={status.uuid} onClick={() => setEntryStatus(status)}>
                <StatusColor color={ENTRY_STATUS_COLORS[status.name]} />
              </Label>
            ))}
          </_statusWrap>
        )}

        {["SendTeamsNotification", "SendSlackNotification"].includes(
          option
        ) && (
          <>
            <Input
              placeholder={intl.formatMessage(texts.urlPlaceholder)}
              type="url"
              value={text}
              onChange={(evt) => setText(evt.target.value)}
            />
            <Checkbox
              label={intl.formatMessage(texts.isSensitiveLabel)}
              defaultChecked={isSensitive}
              onChange={(evt) => setSensitive(evt.target.checked)}
            />
          </>
        )}
      </_edit>

      <ActionMenu
        onApply={handleSaveOutcome}
        onCancel={p.onClose}
        applyIsDisabled={!canSave()}
        applyText={texts.add}
        direction="horizontal"
      />
    </_wrap>
  );
});

const _wrap = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f1f2f3;
  margin: 5px 0px;
  border-radius: 3px;
  background: #e5e8fd;

  > {
    margin-bottom: 10px;
  }
`;

const _edit = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 10px 5px;

  button {
    margin-left: 10px;
  }

  label {
    width: 100%;
    margin-left: 10px;
  }

  input {
    font-size: 13px;
  }

  --svg-fill: transparent;
  --svg-stroke: transparent;
  --svg-check: white;
`;

const _statusWrap = styled.div`
  display: flex;

  > div {
    margin-left: 15px;
  }
`;

const texts = defineMessages({
  add: {
    id: "flow.settings.outcome.add",
    defaultMessage: "Add outcome",
  },
  urlPlaceholder: {
    id: "flow.settings.outcome.url.placeholder",
    defaultMessage: "Webhook URL…",
  },
  isSensitiveLabel: {
    id: "flow.settings.outcome.is.sensitive",
    defaultMessage: "Is sensitive",
  },
});
