import React from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { FreetextSuggestionModel, FreeTextSuggestionType } from "@web/models";
import * as Icons from "@web/elements/Icons";
import { SearchSuggestionButton } from "./SearchSuggestionButton";

interface Props {
  data: FreetextSuggestionModel;
  onClick: () => void;
}

export const SuggestedType = (p: Props) => {
  const intl = useIntl();
  const { icon, title } = DOC_TYPES[p.data.value];

  return (
    <SearchSuggestionButton onClick={p.onClick}>
      {icon} {intl.formatMessage(title)}
    </SearchSuggestionButton>
  );
};

const texts = defineMessages({
  pdfs: {
    id: "searchpage.freetextsuggestions.pdfs",
    defaultMessage: "PDFs",
  },
  docs: {
    id: "searchpage.freetextsuggestions.docs",
    defaultMessage: "Documents",
  },
  images: {
    id: "searchpage.freetextsuggestions.images",
    defaultMessage: "Photos & Images",
  },
  videos: {
    id: "searchpage.freetextsuggestions.videos",
    defaultMessage: "Videos",
  },
  emails: {
    id: "searchpage.freetextsuggestions.emails",
    defaultMessage: "Emails",
  },
  xls: {
    id: "searchpage.freetextsuggestions.xls",
    defaultMessage: "Spreadsheets",
  },
  ppts: {
    id: "searchpage.freetextsuggestions.ppts",
    defaultMessage: "Presentations",
  },
});

const DOC_TYPES: Record<
  FreeTextSuggestionType,
  {
    title: MessageDescriptor;
    icon: JSX.Element;
  }
> = {
  pdf: { title: texts.pdfs, icon: <Icons.Pdf /> },
  doc: { title: texts.docs, icon: <Icons.Document /> },
  image: { title: texts.images, icon: <Icons.Image /> },
  video: { title: texts.videos, icon: <Icons.Video /> },
  email: { title: texts.emails, icon: <Icons.Email /> },
  xls: { title: texts.xls, icon: <Icons.Sheets /> },
  ppt: { title: texts.ppts, icon: <Icons.Presentations /> },
};
