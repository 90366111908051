import React, { useState, ChangeEvent } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { ClassModel, ColorStatusModel, TagStatusColor } from "@web/models";
import { ActionMenu, Checkbox } from "@web/elements";
import { commonTexts } from "@web/translations";
import { StatusColor } from "@web/elements/StatusColor";
import { ColorSelect } from "@web/components";
import { vars } from "@web/styles";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { Header } from "../Header";
import { AddLink } from "../AddLink";
import { Empty } from "../Empty";
import { SettingsBox } from "../Box";
import { TagStatusMenu } from "./Menu";

interface Events {
  onCloseClick: () => void;
}

interface Props extends Events {
  classification: ClassModel;
}

export const EditTagStatuses = observer((p: Props) => {
  const intl = useIntl();

  const [getConfirmation] = useConfirmationDialog();
  const [selectedStatus, selectStatus] = useState<ColorStatusModel | undefined>(
    undefined
  );
  const [addingNewStatus, addNewStatus] = useState(false);
  const [input, setInput] = useState("");
  const [color, setColor] = useState<keyof typeof TagStatusColor>("noColor");
  const [isArchived, setIsArchived] = useState(false);

  const { classification } = p;

  const title = selectedStatus
    ? intl.formatMessage(texts.editStatus)
    : addingNewStatus
    ? intl.formatMessage(texts.addStatus)
    : intl.formatMessage(texts.title, {
        classificationTitle: classification.title,
      });

  const handleStatusEdit = (status: ColorStatusModel) => {
    setInput(status.name);
    setColor(status.color);
    setIsArchived(status.isArchived);
    selectStatus(status);
  };

  const handleAddStatusClick = () => {
    setInput("");
    setColor("noColor");
    setIsArchived(false);
    addNewStatus(true);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleCancel = () => {
    selectStatus(undefined);
    addNewStatus(false);
  };

  const handleDelete = async (status: ColorStatusModel) => {
    const confirmed = await getConfirmation({
      title: texts.deleteStatusDialogTitle,
      message: texts.deleteStatusDialogQuestion,
      confirmText: commonTexts.delete,
      warningText: commonTexts.asyncDeleteWarning,
      values: {
        name: status.name,
        entity: "tagStatus",
      },
    });

    if (confirmed) {
      classification.deleteTagStatus(status.uuid);
    }
  };

  const handleSave = (e?: React.FormEvent) => {
    e?.preventDefault();
    const trimmedName = input.trim();

    if (selectedStatus) {
      const fields = {
        ...(trimmedName !== selectedStatus.name && { name: trimmedName }),
        ...(color !== selectedStatus.color && { color }),
        ...(isArchived !== selectedStatus.isArchived && { isArchived }),
      };

      classification.updateTagStatus(selectedStatus, fields);
      selectStatus(undefined);
    } else if (addingNewStatus) {
      classification.createTagStatus({
        name: trimmedName,
        color,
        isArchived,
      });
      addNewStatus(false);
    }
  };

  const canSave = () => {
    const trimmed = input.trim();
    if (selectedStatus) {
      return (
        (selectedStatus.name !== trimmed && trimmed.length > 0) ||
        selectedStatus.color !== color ||
        selectedStatus.isArchived !== isArchived
      );
    }
    return trimmed.length > 0;
  };

  const isEditing = selectedStatus || addingNewStatus;

  return (
    <SettingsBox>
      <Header
        title={title}
        onButtonClick={p.onCloseClick}
        showButton={!isEditing ? "close" : undefined}
        showSpinner={p.classification.saving}
      />

      {isEditing && (
        <_form onSubmit={handleSave}>
          <_formElements>
            <_input
              autoFocus
              type="text"
              value={input}
              placeholder={
                selectedStatus?.name || intl.formatMessage(texts.placeholder)
              }
              onChange={handleInputChange}
            />

            <ColorSelect
              options={Object.entries(TagStatusColor)}
              selectedColor={color}
              onSelect={(e) =>
                setColor(e.target.value as keyof typeof TagStatusColor)
              }
            />

            <Checkbox
              label={intl.formatMessage(texts.archivedLabel)}
              defaultChecked={isArchived}
              variant="form"
              onChange={() => setIsArchived(!isArchived)}
            />

            <div style={{ opacity: 0.7 }}>
              {intl.formatMessage(texts.archivedInfo)}
            </div>
          </_formElements>

          <ActionMenu
            direction="vertical"
            applyIsDisabled={!canSave()}
            applyText={addingNewStatus ? commonTexts.add : commonTexts.save}
            onApply={handleSave}
            onCancel={handleCancel}
          />
        </_form>
      )}

      {!isEditing && (
        <>
          {classification.tagStatuses.length === 0 && (
            <Empty title={texts.empty} />
          )}
          <_statusList>
            {classification.tagStatuses.map((st) => (
              <_statusWrap key={st.name}>
                <_status>
                  <StatusColor color={TagStatusColor[st.color]} />
                  <span>{st.name}</span>
                  <span>
                    {st.isArchived && (
                      <>{intl.formatMessage(texts.archivedLabel)}</>
                    )}
                  </span>
                  <TagStatusMenu
                    status={st}
                    onEdit={() => handleStatusEdit(st)}
                    onDelete={() => handleDelete(st)}
                  />
                </_status>
              </_statusWrap>
            ))}
          </_statusList>
          <AddLink title={texts.addStatus} onClick={handleAddStatusClick} />
        </>
      )}
    </SettingsBox>
  );
});

const _form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 15px;
  :last-child {
    margin-bottom: 10px;
  }
`;

const _formElements = styled.div`
  display: flex;
  flex-direction: column;
`;

const _input = styled.input`
  color: ${vars.secondaryAltFg};
  border-color: ${vars.transparent};
  background-color: ${vars.secondaryAltLight20};
  font: inherit;
  height: 2.25em;
  padding: 0 0.5em;
  outline: none;
  width: 100%;
  border-radius: 3px;
  ::placeholder {
    color: ${vars.dark55};
  }
`;

const _statusList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 15px;
  max-height: 380px;
  overflow-y: auto;
`;

const _statusWrap = styled.div.attrs({ role: "listitem" })`
  display: inline-flex;
  align-items: center;
  margin: 5px 0;
  svg {
    visibility: hidden;
  }
  button {
    :focus {
      svg {
        visibility: visible;
      }
    }
  }
  &:hover {
    svg {
      visibility: visible;
    }
  }
`;

const _status = styled.div`
  border: none;
  padding: 10px;
  border-radius: 5px;
  background: ${vars.info};
  display: flex;
  flex: 1;
  text-align: left;
  align-items: center;
  span:first-of-type {
    flex-grow: 1;
    padding-left: 10px;
  }
  span:last-of-type {
    opacity: 0.6;
    margin: 0 15px;
    flex-shrink: 0;
  }
  &:hover {
    background: ${vars.info};
  }
  svg {
    flex-shrink: 0;
  }
`;

const texts = defineMessages({
  title: {
    id: "settings.tagstatus.title",
    defaultMessage: "{classificationTitle}: statuses",
  },
  empty: {
    id: "settings.tagstatus.empty",
    defaultMessage: "No status values added",
  },
  addStatus: {
    id: "settings.tagstatus.add",
    defaultMessage: "Add status",
  },
  editStatus: {
    id: "settings.tagstatus.edit",
    defaultMessage: "Edit status",
  },
  placeholder: {
    id: "settings.tagstatus.placeholder",
    defaultMessage: "Status name",
  },
  archivedLabel: {
    id: "settings.tagstatus.archivedlabel",
    defaultMessage: "Hide from filter list",
  },
  archivedInfo: {
    id: "settings.tagstatus.archivedinfo",
    defaultMessage:
      "Tags that are given this status will not be shown in the filter list",
  },
  deleteStatusDialogTitle: {
    id: "settings.tagstatus.deletedialog.title",
    defaultMessage: "Delete status",
  },
  deleteStatusDialogQuestion: {
    id: "settings.tagstatus.deletedialog.question",
    defaultMessage:
      "This will permanently remove the status <em>{name}</em> from any tags where it has been applied. Are you sure you want to proceed?",
  },
});
