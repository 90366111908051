import React, { FC } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

interface Props {
  contentIsLoading?: boolean;
}

export const SettingsBox: FC<Props> = (p) => {
  const intl = useIntl();

  return (
    <_wrap>
      {p.contentIsLoading && (
        <_loading>{intl.formatMessage(commonTexts.loading)}</_loading>
      )}
      {p.children}
    </_wrap>
  );
};

const _wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px;
  background: ${vars.content};
  color: ${vars.contentFg};
  font-size: 13px;
  border-radius: 3px;
  max-width: 350px;
  min-width: 350px;
  height: 500px;
  margin: auto;
  overflow: hidden;
`;

const _loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
