import React, { useState, FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { SectionModel, TagSearchModel } from "@web/models";
import { Truncate } from "@web/elements";
import { SectionMenu } from "./Menu";
import { AddClassificationMenu } from "../Classification/Menus";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { vars } from "@web/styles";
import { media } from "@web/styles/utils";
import { commonTexts } from "@web/translations";
import { ClassificationButton } from "../Classification/ClassificationButton";
import { SelectClassificationBox } from "@web/components/Classification/SelectClassificationBox";
import { IClassificationNodeExtended } from "@web/api/Integration/types";
import { EditTitleDialog } from "@web/components/Dialogs/EditTitleDialog";

interface Events {
  onClassificationHover: (classificationId: UUID | undefined) => void;
  onRenameClick: (section: SectionModel) => void;
}

interface Props extends Events {
  section: SectionModel;
  tagSearch: TagSearchModel;
  highlightedClassification?: UUID;
}

export const Section: FC<Props> = observer((props) => {
  const [getConfirmation] = useConfirmationDialog();
  const [createClassification, setCreateClassification] = useState(false);
  const [linkClassification, setLinkClassification] = useState(false);

  const { section, highlightedClassification } = props;

  const handleDelete = async (section: SectionModel) => {
    const confirmed = await getConfirmation({
      title: texts.deleteSectionTitle,
      message: texts.deleteSectionQuestion,
      confirmText: commonTexts.delete,
      warningText: commonTexts.asyncDeleteWarning,
      confirmByInput: section.title,
      values: {
        title: section.title,
        entity: "section",
      },
    });

    if (confirmed) {
      section.delete();
    }
  };

  const handleAddClassificationClick = (linkExisting: boolean) => {
    if (linkExisting) {
      setLinkClassification(true);
    } else {
      setCreateClassification(true);
    }
  };

  const handleCreateClassification = (title: string) => {
    section.createClassification(title);
  };

  const handleLinkClassificationSave = (
    classification: IClassificationNodeExtended
  ) => {
    setLinkClassification(false);
    section.linkClassification(classification.id);
  };

  return (
    <>
      <_wrap>
        <_sectionTitle>
          <Truncate maxWidth="85%">{section.title}</Truncate>
          <SectionMenu
            section={section}
            onDelete={() => handleDelete(section)}
            onRename={() => props.onRenameClick(section)}
          />
        </_sectionTitle>
        <_classificationList>
          {section.classifications.map((cl) => (
            <ClassificationButton
              key={cl.id}
              section={section}
              classification={cl}
              highlighted={cl.uuid === highlightedClassification}
              tagSearch={props.tagSearch}
              onHover={props.onClassificationHover}
            />
          ))}
        </_classificationList>

        <AddClassificationMenu
          section={section}
          onAddClick={handleAddClassificationClick}
        />
      </_wrap>

      {/** Overlays for different edit operations */}
      {createClassification && (
        <EditTitleDialog
          titleText={texts.createClassificationTitle}
          saveText={commonTexts.add}
          onSave={handleCreateClassification}
          onClose={() => setCreateClassification(false)}
        />
      )}

      {linkClassification && (
        <SelectClassificationBox
          title={{
            ...texts.selectClassificationTitle,
            values: {
              sectionTitle: section.title,
            },
          }}
          findClassifications={(query) =>
            section.findLinkableClassifications(query)
          }
          onSelect={handleLinkClassificationSave}
          onCancel={() => setLinkClassification(false)}
        />
      )}
    </>
  );
});

const _wrap = styled.div`
  padding: 15px 0px;
  display: flex;
  align-items: center;

  ${media("compact")} {
    flex-wrap: wrap;
  }
`;

const _classificationList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  ${media("compact")} {
    flex-basis: 100%;
  }
`;

const _sectionTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${vars.primaryFg};
  background-color: ${vars.primary};
  padding: 0px 10px 0px 7px;
  margin: 6px 5px 6px 0px;
  border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;
  font-size: 1.1em;
  min-width: 220px;
  max-width: 280px;
  height: 34px;
  justify-content: space-between;
  ${media("compact")} {
    flex-basis: 100%;
  }
  svg {
    color: white;
    flex-shrink: 0;
  }
`;

const texts = defineMessages({
  createClassificationTitle: {
    id: "settings.section.createlist",
    defaultMessage: "Create new list",
  },
  deleteSectionTitle: {
    id: "settings.section.deletedialog.title",
    defaultMessage: "Delete section",
  },
  deleteSectionQuestion: {
    id: "settings.section.deletedialog.question",
    defaultMessage:
      'This will delete the section "{title}" and all its content. Are you sure you want to proceed?',
  },
  selectClassificationTitle: {
    id: "settings.section.addexistinglist.title",
    defaultMessage: "Add existing list to <em>{sectionTitle}</em>",
  },
});
