import React from "react";
import { useIntl, defineMessages } from "react-intl";
import { ColorStatusModel } from "@web/models";
import { commonTexts } from "@web/translations";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";

interface Props {
  status: ColorStatusModel;
  onDelete: () => void;
  onEdit: () => void;
}

export const TagStatusMenu: React.FC<Props> = ({
  status,
  onDelete,
  onEdit,
}) => {
  const intl = useIntl();

  return (
    <Dropdown variation="light">
      <Menu>
        <MenuItem onClick={onEdit}>{intl.formatMessage(texts.edit)}</MenuItem>
        <MenuItem danger onClick={onDelete}>
          {intl.formatMessage(commonTexts.delete)}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const texts = defineMessages({
  edit: {
    id: "settings.tagstatus.menu.edit",
    defaultMessage: "Edit status",
  },
});
