import React from "react";
import styled from "styled-components";
import { ellipsis } from "polished";
import { useIntl } from "react-intl";
import { SearchIcon } from "@web/elements/Icons";
import { useUniqueId } from "@web/utils/hooks/useUniqueId";
import { vars } from "@web/styles";
import { CloseButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { SearchControlWrap } from "./common";

interface Props {
  value: string;
  ariaExpanded: boolean;
  ariaControls: string;
  onClick: (e: React.MouseEvent) => void;
  onClear: () => void;
}

export const SearchButton = (p: Props) => {
  const intl = useIntl();
  const buttonId = useUniqueId("SearchButton");

  return (
    <_wrap onClick={p.onClick}>
      <SearchIcon />

      <_button
        id={buttonId}
        onClick={p.onClick}
        aria-controls={p.ariaControls}
        aria-expanded={p.ariaExpanded}
      >
        {p.value || intl.formatMessage(commonTexts.search)}
      </_button>

      {p.value.length > 0 && <_clear onClickCapture={p.onClear} />}
    </_wrap>
  );
};

const _wrap = styled(SearchControlWrap)`
  box-shadow: ${vars.shadow.z1};
  cursor: pointer;
  width: min-content;

  :focus-within {
    outline: 2px solid ${vars.secondary};
  }
`;

const _button = styled.button`
  ${ellipsis()};

  background: unset;
  border: unset;
  font: unset;
  outline: unset;
  cursor: unset;
  color: unset;
  flex: 1;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const _clear = styled(CloseButton)`
  font-size: 0.75rem;
  margin-right: -0.5rem;
`;
