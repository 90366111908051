import { APP_ENV } from "@web/utils/constants/appEnv";

const currentScript = (document.currentScript as HTMLScriptElement) ||
  document.scripts[document.scripts.length - 1] || { src: "" };

export const ROOT_URL = window.location.origin;

export const SCRIPT_URL = currentScript.src;

export const APP_URL = SCRIPT_URL.substring(0, SCRIPT_URL.lastIndexOf("/") + 1);

export const API_URL =
  (process.env.API_HOST || ROOT_URL) + (process.env.API_PATH ?? "/bff/");

export const PUBLIC_PATH = APP_URL.replace(ROOT_URL, "");

export const LOGOUT_PATH =
  (process.env.API_HOST || ROOT_URL) +
  (process.env.LOGOUT_PATH || "/bff/logout") +
  "?env=" +
  APP_ENV;
