import { useEffect, useState } from "react";

export const useKeyboardEvent = () => {
  const [keys, setKeys] = useState<KeyboardEvent>();

  useEffect(() => {
    const handle = (e: KeyboardEvent) => setKeys(e);

    window.addEventListener("keyup", handle);
    window.addEventListener("keydown", handle);
    return () => {
      window.removeEventListener("keyup", handle);
      window.removeEventListener("keydown", handle);
    };
  }, []);

  return keys;
};
