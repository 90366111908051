import React, { FC } from "react";
import { MessageDescriptor } from "react-intl";
import { Button, IconButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";

interface IProps {
  text?: string | MessageDescriptor;
  disabled?: boolean;
  onClick: () => void;
  children?: never;
}

export const CreateButton: FC<IProps> = (p) => {
  if (!p.text) {
    return (
      <IconButton
        icon="CreateIcon"
        text={commonTexts.create}
        disabled={p.disabled}
        onClick={p.onClick}
      />
    );
  }

  return (
    <Button
      icon="CreateIcon"
      text={p.text}
      onClick={p.onClick}
      disabled={p.disabled}
    />
  );
};
