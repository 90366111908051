import styled from "styled-components";

/**
 * Hides text visually while still making it available to screenreaders.
 * Source: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 */
export const A11yText = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
