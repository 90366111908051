import React from "react";
import styled, { css } from "styled-components";
import isEmpty from "lodash/isEmpty";
import { Box } from "@web/elements";
import { vars } from "@web/styles";
import { IconPreview } from "@web/components/FilePreview/IconPreview";

interface IProps {
  text?: string;
  fileExt: string;
  fullscreen?: boolean;
}

export const TextPreview = ({ text, fullscreen, fileExt }: IProps) => {
  if (isEmpty(text)) {
    return text === "" ? (
      <Box ma="auto">No content</Box>
    ) : (
      <IconPreview type={fileExt} />
    );
  }

  return <Wrap full={fullscreen}>{text}</Wrap>;
};

const Wrap = styled.div<{ full?: boolean }>`
  background: ${vars.content};
  color: ${vars.contentFg};
  padding: 1rem 1rem 65px 1rem;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word; // IE 11
  max-width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${(p) => p.full && "align-self: flex-start;"}
  ${(p) => !p.full && "height: 100%;"}
  ${(p) => !p.full && fadeoutCss}
`;

const fadeoutCss = css`
  ::after {
    background-image: linear-gradient(to bottom, transparent, ${vars.content});
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }
`;

TextPreview.displayName = "TextPreview";
