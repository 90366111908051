import React from "react";

export const CaretRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6756 12.3434C15.8577 12.1506 15.8577 11.8494 15.6756 11.6566L10.6954 6.38475C10.4981 6.17589 10.1658 6.17589 9.96852 6.38475L9.324 7.06702C9.1421 7.25957 9.14194 7.56058 9.32363 7.75333L13.0033 11.657C13.1849 11.8496 13.1849 12.1504 13.0033 12.343L9.32363 16.2467C9.14194 16.4394 9.1421 16.7404 9.324 16.933L9.96852 17.6152C10.1658 17.8241 10.4981 17.8241 10.6954 17.6152L15.6756 12.3434Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

export const CaretLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.32436 11.6566C8.14231 11.8494 8.14231 12.1506 8.32436 12.3434L13.3046 17.6152C13.5019 17.8241 13.8342 17.8241 14.0315 17.6152L14.676 16.933C14.8579 16.7404 14.8581 16.4394 14.6764 16.2467L10.9967 12.343C10.8151 12.1504 10.8151 11.8496 10.9967 11.657L14.6764 7.75333C14.8581 7.56058 14.8579 7.25957 14.676 7.06702L14.0315 6.38475C13.8342 6.17589 13.5019 6.17589 13.3046 6.38475L8.32436 11.6566Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

export const CaretUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3434 8.32436C12.1506 8.14231 11.8494 8.14231 11.6566 8.32436L6.38475 13.3046C6.17589 13.5019 6.17589 13.8342 6.38475 14.0315L7.06702 14.676C7.25957 14.8579 7.56058 14.8581 7.75333 14.6764L11.657 10.9967C11.8496 10.8151 12.1504 10.8151 12.343 10.9967L16.2467 14.6764C16.4394 14.8581 16.7404 14.8579 16.933 14.676L17.6152 14.0315C17.8241 13.8342 17.8241 13.5019 17.6152 13.3046L12.3434 8.32436Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

export const CaretDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6566 15.6756C11.8494 15.8577 12.1506 15.8577 12.3434 15.6756L17.6152 10.6954C17.8241 10.4981 17.8241 10.1658 17.6152 9.96852L16.933 9.324C16.7404 9.1421 16.4394 9.14194 16.2467 9.32363L12.343 13.0033C12.1504 13.1849 11.8496 13.1849 11.657 13.0033L7.75333 9.32363C7.56058 9.14194 7.25958 9.1421 7.06702 9.324L6.38475 9.96852C6.1759 10.1658 6.1759 10.4981 6.38475 10.6954L11.6566 15.6756Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

export const CalendarCaretLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.84531 1L1 5.42929L6 10" stroke="currentColor" />
  </svg>
);

export const CalendarCaretRightIcon = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.15469 10L6 5.57071L1 1" stroke="currentColor" />
  </svg>
);

CaretLeftIcon.displayName = "CaretLeftIcon";
