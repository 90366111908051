import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { ColorStatusModel, TagStatusColor } from "@web/models";
import { StatusColor, Checkbox, ScrollBox } from "@web/elements";
import { isMedia } from "@web/styles/utils";
import { CloseButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { commonComponentTexts } from "./texts";

interface Props {
  selectedStatusIds: Set<number>;
  options: ColorStatusModel[];
  onChange: (status: ColorStatusModel, selected: boolean) => void;
  onCloseClick: () => void;
}

export const TagStatusMultiSelect: React.FC<Props> = observer((p) => {
  const intl = useIntl();
  const handleChange = (status: ColorStatusModel) => {
    p.onChange(status, !p.selectedStatusIds.has(status.id));
  };

  return (
    <_wrap>
      <_header>
        {isMedia("compact") && <CloseButton onClick={p.onCloseClick} />}
        <h2>{intl.formatMessage(commonTexts.status)}</h2>
      </_header>
      <ScrollBox flex="1">
        {p.options.map((status) => (
          <_option key={status.uuid}>
            <Checkbox
              label={status.name}
              subtitle={
                status.isArchived
                  ? intl.formatMessage(commonComponentTexts.statusIsArchived)
                  : undefined
              }
              defaultChecked={p.selectedStatusIds.has(status.id)}
              onChange={() => handleChange(status)}
              iconBefore={
                status.color !== "noColor" ? (
                  <StatusColor color={TagStatusColor[status.color]} />
                ) : undefined
              }
            />
          </_option>
        ))}
      </ScrollBox>
    </_wrap>
  );
});

const _wrap = styled.div`
  min-width: 180px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 5px 15px;
`;

export const _header = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    padding: 0;
    flex: 1;
    font-weight: bold;
    :not(:only-child) {
      text-align: center;
    }
  }
`;

const _option = styled.div`
  font-size: 14px;
  display: flex;
  margin: 10px 0;
  min-height: 31px;
  align-items: center;
  flex-grow: 1;
`;
