import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelTokenStatic,
} from "axios";
import qs from "qs";
import { createRetryFn, StatusCodeRanges } from "@web/api/retry";
import { APP_ENV } from "@web/utils/constants/appEnv";
import { isInIframe } from "@web/utils/is-in-iframe";

export type ApiCanceler = Canceler;

export interface RetryConfig {
  maxRetries?: number;
  statusCodes?: StatusCodeRanges;
}

export type IApiResponse<T> = AxiosResponse<T | "">;

export default class Api {
  public instance: AxiosInstance;
  public CancelToken: CancelTokenStatic;
  public isCancel: (value: any) => boolean;
  private xDocumasterEnv: string = APP_ENV;
  private isRedirectingToLogin = false;

  constructor(private baseURL?: string, timeout?: number) {
    this.instance = axios.create({
      baseURL,
      timeout,
      withCredentials: true,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    this.instance.interceptors.request.use((config) => {
      if (config.headers) {
        config.headers["X-Documaster-Env"] = this.xDocumasterEnv;
        return config;
      }
    });

    this.instance.interceptors.response.use(
      (res) => res,
      (err) => {
        if (this.isCancel(err)) {
          throw err;
        }

        if (err.response) {
          if (err.response.status === 401) {
            // Guard against infinite retries if BFF responds with 401 w/retry-after header subsequently,
            // should never happen, but let's be honest and say it probably will some day..
            const hasAlreadyBeenRetried =
              "retry-count" in err.response.config.headers;

            if (err.response.headers["retry-after"] && !hasAlreadyBeenRetried) {
              return this.instance.request({
                ...err.response.config,
                headers: {
                  "retry-count": 1,
                },
              });
            }

            // If documaster is displayed in an iframe we will show a login link instead of redirecting,
            // so returning an error to be handled outside this
            if (isInIframe()) {
              return Promise.reject(err);
            }

            if ("redirect-to" in err.response.headers) {
              return new Promise(() => {
                this.startLoginProcedure(err.response.headers["redirect-to"]);
              });
            }

            return new Promise(() => {
              this.startLoginProcedure("/");
            });
          }

          if (
            err.response.status === 403 &&
            "redirect-to" in err.response.headers
          ) {
            return new Promise(() => {
              this.startLoginProcedure(err.response.headers["redirect-to"]);
            });
          }

          // Enable the stores to catch any errors from the request.
          // Needed to show a sensible message to the user.
          return Promise.reject(err);
        }

        throw new Error(err);
      }
    );

    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  setEnvHeader(env: string) {
    this.xDocumasterEnv = env;
  }

  /**
   * Retry the request up to 10 times.
   *
   * The number of retries can be configured in the `retryConfig`.
   *
   * By default only the following status codes are retried:
   * 429 Too Many Requests
   * 5xx Server errors
   * (https://en.wikipedia.org/wiki/List_of_HTTP_status_codes)
   *
   * @param retryConfig - The retry configuration. There are two options:
   *   maxRetries - the maximum number of retries
   *   statusCodes - the HTTP status codes that should be retried
   *
   * @returns A function that takes an AxiosResponse and returns either an
   * `AxiosResponse` or a `Promise<AxiosResponse>`.
   */
  retry = (retryConfig?: RetryConfig) => {
    const statusCodes =
      retryConfig && retryConfig.statusCodes
        ? retryConfig.statusCodes
        : ([
            [429, 429],
            [500, 599],
          ] as StatusCodeRanges);
    const maxRetries =
      retryConfig && retryConfig.maxRetries ? retryConfig.maxRetries : 10;

    return createRetryFn(this.instance, maxRetries, statusCodes);
  };

  get = (url: string, opts?: AxiosRequestConfig) =>
    this.instance.get(url, opts);

  post = (url: string, data: any, opts?: AxiosRequestConfig) =>
    this.instance.post(url, data, opts);

  startLoginProcedure = (toUrl: string) => {
    if (!this.isRedirectingToLogin) {
      const urlWithTimezone = new URL(toUrl, new URL(window.location.href));
      urlWithTimezone.searchParams.set(
        "timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );

      this._performRedirect(urlWithTimezone.toString());
      this.isRedirectingToLogin = true;
    }
  };

  // Made to make sure we can override this while testing or handle this authentication
  // redirects differently in the Office AddIn
  _performRedirect(toUrl: string) {
    location.href = toUrl;
  }
}
