import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";

export const NewBadge: React.FC = () => {
  const intl = useIntl();
  return <_wrap>{intl.formatMessage(commonTexts.new)}</_wrap>;
};

const _wrap = styled.div`
  display: inline-flex;
  color: ${vars.warning};
  text-transform: uppercase;
  font-weight: 800;
  padding: 0 5px;
`;
