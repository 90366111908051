import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { SectionModel } from "../../models/SectionsModel";
import { SectionSelectorBox } from "./SectionSelectorBox";
import { vars } from "@web/styles";
import { CaretDownIcon } from "@web/elements/Icons";
import ellipsis from "polished/lib/mixins/ellipsis";
import { DataLoadingModel } from "@web/models";
import { media } from "@web/styles/utils";

interface IEvents {
  onSelectSectionClick: (section: SectionModel) => void;
  onSectionPageNeeded: () => Promise<void>;
  onQueryChange?: (newQuery: string) => void;
}

interface IProps extends IEvents {
  sections: SectionModel[];
  loadingStatus: DataLoadingModel;
  selected?: SectionModel;
}

interface IState {
  open: boolean;
}

@observer
export class SectionSelector extends React.Component<IProps, IState> {
  private ref = React.createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);
    this.state = { open: false };
  }

  setOpen = (open: boolean) => {
    this.setState({ open });
  };

  handleSelectSectionClick = (section: SectionModel) => {
    this.setOpen(false);
    this.props.onSelectSectionClick(section);
  };

  render() {
    const { open } = this.state;
    const {
      sections,
      selected,
      onSectionPageNeeded,
      loadingStatus,
      onQueryChange,
    } = this.props;

    return (
      <_wrap ref={this.ref}>
        <_button onClick={() => this.setOpen(true)}>
          <_label>{selected ? selected.title : ""}</_label>
          <_icon />
        </_button>
        {open && (
          <SectionSelectorBox
            triggerRef={this.ref}
            sections={sections}
            selectedSection={selected}
            loadingStatus={loadingStatus}
            onSectionPageNeeded={onSectionPageNeeded}
            onSelectSectionClick={this.handleSelectSectionClick}
            onQueryChange={onQueryChange}
            onCloseClick={() => this.setOpen(false)}
          />
        )}
      </_wrap>
    );
  }
}

const _wrap = styled.div``;

const _button = styled.button`
  background: ${vars.primary};
  border: none;
  border-radius: 0.5rem 1.5rem 1.5rem 0.5rem;
  color: ${vars.primaryFg};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: inherit;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  max-width: 100%;

  ${media("compact")} {
    width: 100%;
  }
`;

const _label = styled.span`
  ${ellipsis("10em")};
`;

const _icon = styled(CaretDownIcon)`
  margin-left: 0.5rem;
  flex-shrink: 0;
`;
