import { AxiosInstance, AxiosResponse } from "axios";

/**
 * The backoff strategy:
 *   - first three retries happens after 1000 ms
 *   - then it's 2^(retryCount-2)
 *   - until the delay reaches maxDelay
 *
 * @param retryCount
 * @param maxDelay
 */
const backoff = (retryCount: number, maxDelay: number): number => {
  const backoffExp = retryCount < 3 ? 0 : retryCount - 2;
  const backoffMs = Math.pow(2, backoffExp) * 1000;
  return Math.min(backoffMs, maxDelay);
};

/**
 * Check if status code is included in the provided status code ranges.
 *
 * @param status
 * @param statusCodes
 */
const isInStatusCodeRanges = (
  status: number,
  statusCodes: StatusCodeRanges
): boolean =>
  statusCodes.some((range) => status >= range[0] && status <= range[1]);

export type StatusCodeRanges = Array<[number, number]>;

/**
 * Retry an Axios request.
 *
 * @param axios - The Axios instance that should retry the request
 * @param maxRetries - The maximum number of retries
 * @param statusCodeRanges - the HTTP status codes that should be retried
 *
 * @returns A function that takes an AxiosResponse and returns either an
 * `AxiosResponse` or a `Promise<AxiosResponse>`.
 */
export const createRetryFn =
  (
    axios: AxiosInstance,
    maxRetries: number,
    statusCodeRanges: StatusCodeRanges
  ) =>
  (response: AxiosResponse) => {
    const maxDelay = 10 * 1000; // 10 seconds
    let retryCount = 0;

    const innerRetry = (
      r: AxiosResponse
    ): Promise<AxiosResponse<any>> | AxiosResponse<any> => {
      // Return if status code is not in the ranges set in statusCodeRanges
      if (!isInStatusCodeRanges(r.status, statusCodeRanges)) {
        return r;
      }

      // Check if we've maxed out the total number of retries
      if (retryCount >= maxRetries) {
        // Return the response as a rejected promise
        return Promise.reject(r);
      }

      // Create new promise to handle delay
      const delay = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, backoff(retryCount, maxDelay));
      });

      // Increase the retry count
      retryCount += 1;

      return delay.then(() => axios.request(response.config)).then(innerRetry);
    };

    return innerRetry(response);
  };
