import React from "react";

export const FolderCreateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C0.447715 3 0 3.44772 0 4V20.24C0 20.7923 0.447716 21.24 1 21.24H23C23.5523 21.24 24 20.7923 24 20.24V5.71C24 5.15772 23.5523 4.71 23 4.71H8.72727V4C8.72727 3.44772 8.27956 3 7.72727 3H1ZM12.44 7.8C12.9923 7.8 13.44 8.24772 13.44 8.8V11.64H16.28C16.8323 11.64 17.28 12.0877 17.28 12.64V13.52C17.28 14.0723 16.8323 14.52 16.28 14.52H13.44V17.36C13.44 17.9123 12.9923 18.36 12.44 18.36H11.56C11.0077 18.36 10.56 17.9123 10.56 17.36V14.52H7.71997C7.16769 14.52 6.71997 14.0723 6.71997 13.52V12.64C6.71997 12.0877 7.16769 11.64 7.71997 11.64H10.56V8.8C10.56 8.24772 11.0077 7.8 11.56 7.8H12.44Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);
