import React from "react";
import { observer } from "mobx-react";
import { defineMessages, useIntl } from "react-intl";
import { ClassModel, SectionModel } from "@web/models";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";
import { commonTexts } from "@web/translations";
import { IconButton, MoreButton } from "@web/elements/Button";
import { useConfig } from "@config/context";

interface ClassificationMenuProps {
  section: SectionModel;
  classification: ClassModel;
  onDelete: () => void;
  onUnlink: () => void;
  onRename: () => void;
  onTagEdit: () => void;
  onStatusEdit: () => void;
  onAttributesEdit: () => void;
}

export const ClassificationMenu: React.FC<ClassificationMenuProps> = observer(
  ({
    section,
    classification,
    onDelete,
    onUnlink,
    onRename,
    onTagEdit,
    onStatusEdit,
    onAttributesEdit,
  }) => {
    const intl = useIntl();

    const sectionCount = classification.sectionCount || 0;

    return (
      <Dropdown
        variation="light"
        onOpen={() => {
          classification.loadSectionCount();
        }}
        toggle={(ref) => <MoreButton size={0.875} ref={ref} />}
      >
        <Menu>
          <MenuItem onClick={onTagEdit}>
            {intl.formatMessage(texts.editTags)}
          </MenuItem>

          {classification.canUpdate && (
            <>
              <MenuItem onClick={onStatusEdit}>
                {intl.formatMessage(texts.editStatuses)}
              </MenuItem>
              <MenuItem onClick={onAttributesEdit}>
                {intl.formatMessage(texts.editAttributes)}
              </MenuItem>
              <MenuItem onClick={onRename}>
                {intl.formatMessage(texts.editList)}
              </MenuItem>
            </>
          )}

          {section.canUpdate && (
            <MenuItem onClick={onUnlink} disabled={sectionCount < 2}>
              {intl.formatMessage(texts.unlink)}
            </MenuItem>
          )}

          {classification.canDelete && (
            <MenuItem danger onClick={onDelete}>
              {intl.formatMessage(commonTexts.delete)}
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }
);

interface AddClassificationProps {
  section: SectionModel;
  onAddClick: (linkExisting: boolean) => void;
}

export const AddClassificationMenu: React.FC<AddClassificationProps> = ({
  section,
  onAddClick,
}) => {
  const intl = useIntl();
  const { canCreateClassification } = useConfig();

  if (!section.canUpdate) {
    return <>{intl.formatMessage(texts.addClassificationMenuPermission)}</>;
  }

  return (
    <Dropdown
      toggle={(ref) => (
        <IconButton
          ref={ref}
          icon="AddIcon"
          text={texts.addClassificationMenuTitle}
        />
      )}
    >
      <Menu>
        {canCreateClassification && (
          <MenuItem onClick={() => onAddClick(false)}>
            {intl.formatMessage(texts.addNewClassification)}
          </MenuItem>
        )}
        <MenuItem onClick={() => onAddClick(true)}>
          {intl.formatMessage(texts.addExistingClassification)}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const texts = defineMessages({
  title: {
    id: "settings.classification.menu.title",
    defaultMessage: "Edit list",
  },
  editStatuses: {
    id: "settings.classification.menu.editstatuses",
    defaultMessage: "Edit statuses",
  },
  editAttributes: {
    id: "settings.classification.menu.editattributes",
    defaultMessage: "Edit properties",
  },
  editList: {
    id: "settings.classification.edit",
    defaultMessage: "Edit list",
  },
  editTags: {
    id: "settings.classification.menu.edittags",
    defaultMessage: "Edit tags",
  },
  unlink: {
    id: "settings.classification.menu.unlink",
    defaultMessage: "Remove from section",
  },
  addClassificationMenuTitle: {
    id: "settings.classification.addmenu.title",
    defaultMessage: "Add list to section",
  },
  addClassificationMenuPermission: {
    id: "settings.classification.addmenu.noaccess",
    defaultMessage: "You don't have permission to change this section.",
  },
  addNewClassification: {
    id: "settings.classification.addmenu.new",
    defaultMessage: "Add new list to section",
  },
  addExistingClassification: {
    id: "settings.classification.addmenu.link",
    defaultMessage: "Add existing list to section",
  },
});
